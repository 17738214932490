import { useSelector, useDispatch } from "react-redux";
import { State, AjaxRequestStatus, FinanceBaseYearsActionCreator } from '@liasincontrol/redux-service';

type BaseYearsState = {
    items: number[],
    status: AjaxRequestStatus,
};

export const useBaseYears = () => {
    const baseYears = useSelector<State, BaseYearsState>((state) => state.finance.baseYears);
    const dispatch = useDispatch();

    if (baseYears.status === AjaxRequestStatus.NotSet) {
        dispatch(FinanceBaseYearsActionCreator.set());
    }

    return baseYears;
}
