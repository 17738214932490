import React, { useMemo, useRef } from 'react';
import * as Domain from '@liasincontrol/domain';
import { createSource, FieldNameOf, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { ValidationUtils } from '@liasincontrol/core-service';
import { Finance, oDataResponse } from '@liasincontrol/data-service';
import { padStart } from 'lodash';
import { useDebounce } from '../../shared/hooks';

export type FilterModel = {
    baseYear?: number,
    journalElementKind?: Domain.Finance.JournalElementKind,
    code?: string,
    name?: string,
    budgetElementGroupTag?: any[],
};

type Props = {
    journalElementKinds: Domain.Finance.JournalElementKind[],
    pageSize?: number,
    filter?: FilterModel,

    filterCallback?: (item: any) => void,
    onError: (err: any) => void,
    onRowClick?: (journalElement: Domain.Finance.JournalElement) => void,
};

export const JournalElementsGrid: React.FC<Props> = (props) => {
    const debouncedExternalCode = useDebounce(500, props.filter.code);
    const debouncedName = useDebounce(500, props.filter.name);
    const availableColumns = useRef(baseColumns);

    const customDataSource = useMemo(() => {
        if (!props.journalElementKinds) {
            return;
        }

        const getColumnFilter = (column, filterValue) => {
            switch (column) {
                case 'budgetElementGroupTag':
                    return { [column]: { in: props.filterCallback ? filterValue?.map(props.filterCallback) : filterValue } };
                case 'journalElementKind':
                    return { "journalElementKindRK": filterValue.rk };
                case 'code':
                    if (debouncedExternalCode)
                        return { [column]: { contains: debouncedExternalCode } };
                    else
                        return;
                case 'name':
                    if (debouncedName)
                        return { [column]: { contains: debouncedName } };
                    else
                        return;
                default:
                    return { [column]: props.filter[column] };
            }
        };
        const thenCallBack = (data: oDataResponse<Domain.Finance.JournalElement[]>) => {

            const columns = [...baseColumns];
            for (let i = 1; i <= 10; i++) {
                const name = 'custom' + padStart(i.toString(), 2, '0') as FieldNameOf<Domain.Finance.JournalElement>;
                const columnExistsAlready = columns.some(c => c.name === name);
                if (columnExistsAlready) {
                    continue
                }
                const title = 'Extra' + padStart(i.toString(), 2, '0');
                const hidden = data.value.every(item => !item[name]);

                columns.push({
                    name,
                    title,
                    hidden
                });
            }

            availableColumns.current = columns;

            return data.value;
        };

        const cleanedFilter = Object.keys(props.filter)?.filter((column) => !ValidationUtils.isEmpty(props.filter[column]));
        const filterBy: { [x: string]: any; }[] = cleanedFilter?.map((column) => ({ ...getColumnFilter(column, props.filter[column]) }));
        return createSource({
            keyExpr: "rk",
            paginate: true,
            filter: filterBy,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            dataSourcePromise: Finance.JournalElementDataAccessor.get,
            thenCallBack
        });
    }, [props.journalElementKinds, props.filter, debouncedExternalCode, debouncedName]);

    return <LsGrid
        dataSource={customDataSource}
        columns={availableColumns.current}
        showRowLines={true}
        allowColumnResizing={true}
        enableColumnChooser={true}
        paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
        onDataError={props.onError}
    />;
};

const baseColumns: GridColumn<Domain.Finance.JournalElement>[] =
    [{
        name: 'code',
        title: 'Externe code',
        width: '15%',
        defaultSortOrder: 'asc',
    },
    {
        name: 'name',
        title: 'Naam',
    },
    {
        name: 'baseYear',
        title: 'Basisjaar',
        width: '10%',
        align: 'left',
    },
    {
        name: 'journalElementKindName',
        title: 'Elementsoort',
        width: '15%'
    },
    {
        name: 'isReserve',
        title: 'Reserve',
        width: '10%',
    },
    {
        name: 'isResult',
        title: 'Resultaat',
        width: '10%',
    },
    {
        name: 'budgetElementGroupTag',
        title: 'Groep tag',
    },
    {
        name: 'isBenefit',
        title: 'Lasten/Baten',
    },
    {
        name: 'isStructural',
        title: 'Structureel',
    },
    {
        name: 'isClosed',
        title: 'Afgesloten'
    },
    ];
