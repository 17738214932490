import React from 'react';
import { PrivateRoute } from '@liasincontrol/ui-basics';
import { Actions } from '@liasincontrol/userrights-service';
import { Administrator } from '../..';
import { Route } from 'react-router-dom';

const Contents = (
    <>
        {/* /admin/(user|role|usergroup|datastore|workflow|measuremoment) */}
        <Route path="user/list" element={<PrivateRoute permissions={Actions.CRUD_UsersAndGroups} component={Administrator.UsersList.index} />} />

        <Route path="role/list" element={<PrivateRoute permissions={Actions.CRUD_Roles} component={Administrator.RolesList.index} />} />
        <Route path="role/:id/information" element={<PrivateRoute permissions={Actions.CRUD_Roles} component={Administrator.RoleItem.index} />} />

        <Route path="usergroup/list" element={<PrivateRoute permissions={Actions.CRUD_UsersAndGroups} component={Administrator.UserGroupList.index} />} />
        <Route path="usergroup/:id/information" element={<PrivateRoute permissions={Actions.CRUD_UsersAndGroups} component={Administrator.UserGroupItem.index} />} />

        <Route path="datastore/list" element={<PrivateRoute permissions={Actions.CRUD_DataStores} component={Administrator.DataStoresList.index} />} />
        <Route path="datastore/:id/information" element={<PrivateRoute permissions={Actions.CRUD_DataStores} component={Administrator.DataStoreItem.index} />} />

        <Route path="workflow/list" element={<PrivateRoute permissions={Actions.CRUD_Workflows} component={Administrator.WorkflowTemplateList.index} />} />
        <Route path="workflow/:id/information" element={<PrivateRoute permissions={Actions.CRUD_Workflows} component={Administrator.WorkflowTemplateItem.index} />} />

        <Route path="measuremoment/list" element={<PrivateRoute permissions={Actions.CRUD_MeasureMoments} component={Administrator.MeasureMomentList.index} />} />
    </>);

export { Contents };