import React from 'react';
import { ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';

type Props = {
    onClose: () => void,
};

/**
 * Represents an UI component which is a dialog to inform the user that no workflow is set.
 */
export const MissingWorkflowDialog: React.FC<Props> = (props) => {

    const footerElement = (
        <ModalDialogFooter
            rightButtonText='Sluiten' onRightButtonClick={props.onClose}
        />
    );

    return (
        <ModalDialog id="missing-workflow-dialog"
            settings={{
                look: "message",
                title: "Ontbrekende workflow",
                footer: footerElement
            }}>
            <Text value="Er kan geen doelenboom worden aangemaakt omdat er nog geen standaard workflow aanwezig is in het systeem. Vraag aan de LIAS beheerder van uw organisatie om in de beheermodule de standaard workflow aan te maken." />
        </ModalDialog>
    );
};
