import styled from 'styled-components';
import { Button, px } from '@liasincontrol/ui-basics';

const VersionListWrapper = styled.div`
    margin-top: ${px(20)};
`;

const AccessSiteButton = styled(Button)`
    > span {
        height: ${px(16)} !important;
        width: ${px(16)} !important;
    }
` as typeof Button;

const styledComponents = { VersionListWrapper, AccessSiteButton };
export default styledComponents;
