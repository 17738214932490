import React from 'react';
import { AttachmentEditor } from './index.editor';
import { AttachmentProps } from './index.props';
import { AttachmentViewer } from './index.viewer';

/**
 * Represents a UI component that renders an attachment element.
 */
export const AttachmentElement: React.FC<AttachmentProps> = (props) => {
    return props.editorSettings && !props.editorSettings.disabled
        ? <AttachmentEditor {...props} />
        : props.value
            ? <AttachmentViewer {...props} />
            : <AttachmentEditor {...props} />;
};

