import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import * as Domain from '@liasincontrol/domain';
import { DropDownElement, SelectElement } from '@liasincontrol/ui-elements';
import { State, StructuresActionCreator, StructureNodeActionCreator, ActionSource } from '@liasincontrol/redux-service';
import { Button } from '@liasincontrol/ui-basics';
import Styled from './index.styled';

type LinkStructureNodeProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    baseYear: number,
    onSubmit: (structureCode: string, structureNodeCode: string) => void,
    onClose: () => void
}

const Component: React.FC<LinkStructureNodeProps> = (props) => {
    const [selectedStructure, setSelectedStructure] = useState<Domain.Finance.Structure>(null);
    const [selectedStructureNode, setSelectedStructureNode] = useState<Domain.Finance.StructureNode>(null);


    useEffect(() => {
        props.fetchStructures();
    }, []);

    useEffect(() => {
        if (!selectedStructure) return;

        props.fetchStructureNodes(selectedStructure.rk);
    }, [props.baseYear, selectedStructure]);

    const structures = props.structures?.items?.filter(structure => {
        // I can only select StructureNode from Structures that have the same baseyear as the baseyear of the moment of studio element
        return structure.baseYear === props.baseYear
    });

    const structureNodesOptions = useMemo(() => {
        if (!selectedStructure) return [];
        if (!props.structureNodes?.[selectedStructure.rk]?.items) return [];

        return props.structureNodes[selectedStructure.rk].items
            .map(item => item.parentRK === selectedStructure.rk ? { ...item, parentRK: null } : item);

    }, [selectedStructure, props.structureNodes]);
    return (
        <>
        <Styled.Column>
            <SelectElement<Domain.Finance.Structure>
                id='select-structure'
                label='Structuren'
                displayExpr='name'
                optionItems={structures}
                value={selectedStructure}
                clearable={true}
                searchable={false}
                editorSettings={{
                    disabled: false,
                    restrictions: { required: false },
                    validationErrors: [],
                    onChange: (item) => {
                        setSelectedStructure(item);
                        setSelectedStructureNode(null);
                    },
                }}
            />
            <DropDownElement<Domain.Finance.StructureNode>
                id='select-structurenode'
                label='Structuuronderdeel'
                displayExpr='name'
                valueExpr='rk'
                keyExpr="rk"
                parentIdExpr="parentRK"
                value={selectedStructureNode}
                editorSettings={{
                    disabled: !selectedStructure,
                    restrictions: { required: false },
                    validationErrors: [],
                    onChange: (item) => {
                        setSelectedStructureNode(item)
                    },
                }}
                optionItems={structureNodesOptions}
                openOnFieldClick={true}
                clearable={true}
                searchable={true}
            />
            </Styled.Column>
            <Styled.ModalFooter>
                <Button
                    btnbase='textbuttons'
                    btntype='medium_noicon'
                    onClick={props.onClose}
                >
                    Sluiten
                </Button>
            
                <Button
                    btnbase='primarybuttons'
                    btntype='medium_noicon'
                    disabled={!selectedStructure || !selectedStructureNode}
                    onClick={() => {
                        props.onSubmit(selectedStructure.code, selectedStructureNode.code);
                        setSelectedStructure(null);
                        setSelectedStructureNode(null);
                    }}
                >
                    Toevoegen
                </Button>
        </Styled.ModalFooter>
        </>
    );
};

const mapStateToProps = (state: State) => {
    return {
        structures: {
            items: state.finance.structures.items,
            status: state.finance.structures.status,
        },
        structureNodes: state.finance.structureNodes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchStructures: () => {
            dispatch(StructuresActionCreator.set());
        },
        fetchStructureNodes: (structureRK: string) => {
            dispatch(StructureNodeActionCreator.set({
                data: { structureRK: structureRK },
                source: ActionSource.Financial,
            }));
        },
    };
};

export const LinkStructureNodeToHierarchyItem = connect(mapStateToProps, mapDispatchToProps)(Component);
