import React from 'react';
import { TextViewer } from '../../shared/TextViewer';
import { MultiLineTextEditor } from './index.editor';
import { MultiLineTextProps } from './index.props';

/**
 * Represents a UI component that renders an multi line text element.
 */
export const MultiLineTextElement: React.FC<MultiLineTextProps> = (props) => {
    return props.editorSettings && !props.editorSettings.disabled
        ? <MultiLineTextEditor {...props} />
        : <TextViewer id={props.id} label={props.label} helpText={props.helpText} withoutFeedback={props.editorSettings?.withoutFeedback} value={props.value} />;
};