import React from 'react';
import { Bar } from '../bar';
import { Button } from '../button';
import { Text } from '../primitives/text';
import * as Styled from './index.styled';

type Props = {
    error: string | null | undefined,
    errorDetails?: any,
    onRetry?: () => void,
    onBack?: () => void,
    className?: string,
    children?: React.ReactNode
};

/**
 * Represents a component that can display information about any errors happening in the application pages.
 */
export const ErrorOverlay: React.FC<Props> = (props) => {

    const onFadedRef = (el: HTMLDivElement) => {
        if (el) {
            el.setAttribute(`inert`, `true`);
        }
    };

    return (
        <Styled.Container>
            {props.error
                ? (
                    <>
                        <Styled.Faded ref={onFadedRef}>
                            {props.children}
                        </Styled.Faded>
                        <Styled.Message>
                            <Styled.SubTitle>Oeps</Styled.SubTitle>
                            <Text value={props.error} />
                            <br />
                            <Bar>
                                <Bar start>
                                    {props.onBack
                                        ? <Button btnbase="textbuttons" btntype="medium_noicon" onClick={props.onBack}>
                                            Terug
                                        </Button>
                                        : null}
                                    {props.onRetry
                                        ? <Button btnbase="textbuttons" btntype="medium_noicon" onClick={props.onRetry}>
                                            Opnieuw proberen
                                        </Button>
                                        : null}
                                </Bar>
                            </Bar>
                        </Styled.Message>
                    </>
                ) : (props.children)}
        </Styled.Container>);
};

export default ErrorOverlay;