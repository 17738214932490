import React, { useState } from 'react';
import { Dictionary } from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { FormData, FormHelper, FormMode } from '@liasincontrol/core-service';
import { PageTitle, Heading1, Section, EditingToolbar, ModalDialog } from '@liasincontrol/ui-basics';
import { getPublicationFormValidators, initPublicationForm, measureMomentDefinitionId,  workflowTemplateFieldDefinitionId } from '../utils';
import { PublicationForm } from '../PublicationForm';

type Props = {
    measureMoments: Domain.Shared.FieldDefinitionOptionItem[],
    workflowTemplates: Domain.Shared.FieldDefinitionOptionItem[],
    siteDesigns: Domain.Publisher.SiteDesign[],
    fieldDefinitions: Domain.Shared.FieldDefinition[],
    onSave: (systemFields: Record<string, string>) => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders the create publication dialog.
 */
export const PublicationAdd: React.FC<Props> = (props) => {
    const fieldDefinitions = props.fieldDefinitions.reduce((collection, item) => ({ ...collection, [item.systemId]: item }), {}) as Dictionary<Domain.Shared.FieldDefinition>;

    const workflowTemplateDefinition = { systemId: workflowTemplateFieldDefinitionId, id: workflowTemplateFieldDefinitionId, name: 'Workflow', optionItems: props.workflowTemplates } as Domain.Shared.FieldDefinition;
    const measureMomentDefinition = { systemId: measureMomentDefinitionId, id: measureMomentDefinitionId, name: 'Moment', optionItems: props.measureMoments } as Domain.Shared.FieldDefinition;

    const validators = getPublicationFormValidators(fieldDefinitions, workflowTemplateDefinition, measureMomentDefinition);
    const [form, setForm] = useState<FormData<string>>(initPublicationForm(props.workflowTemplates));

    const storeFormValue = (value: string, systemId: keyof typeof validators) => {
        setForm((prevForm) => FormHelper.validateAndStoreFormValue<FormData<string>>(prevForm, value, validators, systemId));
    };

    return (
        <ModalDialog
            customPadding
            modalDialogStyle="custom"
            toolbars={<EditingToolbar
                id="popup-new-publication"
                look="default"
                isVisible={true}
                disabled={Object.keys(form.touched).length === 0}
                isValid={form.isValid}
                onSave={() => props.onSave(form.values)}
                onCancel={props.onCancel} />}>
            <PageTitle>
                <Heading1>Publicatie aanmaken</Heading1>
            </PageTitle>
            <Section look="white">
                <PublicationForm
                    form={form}
                    formMode={FormMode.AddNew}
                    validators={validators}
                    measureMoments={props.measureMoments}
                    workflowTemplates={props.workflowTemplates}
                    fieldDefinitions={props.fieldDefinitions}
                    siteDesigns={props.siteDesigns}
                    onFormValueChanged={storeFormValue}
                />
            </Section>
        </ModalDialog>
    );
};


