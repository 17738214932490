import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';
import { oDataResponse } from '../../Shared';

/**
 * Represents the lease data accessor.
 */
export class Comments {
    /**
     * Gets the collection of the existing comments.
     * @param publicationId Id of the Publication.
     * @param pageId The url parameters to get the journal combinations for.
     * @param controlId Id of the Control. (elementId)
     * @param query Additional odata query.
     */
    public static getAll = async (publicationId: string, pageId: string, controlId: string, query = ''): Promise<AxiosResponse<oDataResponse<Domain.Publisher.Comment[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<oDataResponse<Domain.Publisher.Comment[]>>(`/api/publication/${publicationId}/page/${pageId}/control/${controlId}/comment${query}`, config);
    };

    /**
     * Creates a comment.
     */
    public static create = async (publicationId: string, pageId: string, controlId: string, content: string): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<string>(`/api/publication/${publicationId}/page/${pageId}/control/${controlId}/comment`, { content: content }, config);
    };


    /**
    * Updates a comment.
    */
    public static update = async (commentId: string, content: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.put<void>(`/api/pagecomponentcomment/${commentId}`, { content: content }, config);
    };


    /**
    * Deletes a comment.
    */
    public static delete = async (commentId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.delete<void>(`/api/pagecomponentcomment/${commentId}`, config);
    };

}
