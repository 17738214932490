import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { ErrorOverlay, Heading2, PageTitle, Section, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { LayoutForm } from '@liasincontrol/ui-basics';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { FilterModel, ObligationsFilter } from './ObligationsFilter';
import { ObligationsGrid } from './ObligationsGrid';

const Obligations: React.FC = () => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>();
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [filter, setFilter] = useState<FilterModel>({});

    return <Wrapper>
        <WrapperContent>
            <PageTitle>
                <Heading2>Verplichtingen</Heading2>
            </PageTitle>
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                <Section look='white'>
                    <LayoutForm columns={8}>
                        <ObligationsFilter onFilter={setFilter} />
                    </LayoutForm>
                    <ObligationsGrid
                        onDataError={(exception) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception))}
                        lastRefresh={lastRefresh}
                        filter={filter}
                    />
                </Section>
            </ErrorOverlay>
        </WrapperContent>
    </Wrapper>
}
export const index = Obligations;