import React from 'react';
import { TextValidationRestriction, ValidationErrorData } from '@liasincontrol/core-service';

export const getValidationErrorsNode = (validationErrors?: ValidationErrorData[]): React.ReactNode => {
    return validationErrors && validationErrors.length !== 0 ? validationErrors.map((error, idx) => <React.Fragment key={idx}>{error.error}</React.Fragment>) : null;
};

export const getFeedback = (value: string, restrictions: TextValidationRestriction): React.ReactNode => {
    return restrictions && restrictions.maxLength && value ? `${value.length}/${restrictions.maxLength}` : null;
};
