import styled from 'styled-components';
import { Menu } from 'devextreme-react';

export const StyledMenu = styled(Menu)<{ activeStyle: React.CSSProperties}>`
    .dx-menu-item-selected {
        ${(m) => ({ ...m.activeStyle})}
    }
    
    &.dx-menu-base .dx-menu-items-container {
        display: flex;
        flex-wrap: wrap;
    }
`;
