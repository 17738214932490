import React, { useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { createSource, LsGrid, GridColumn } from '@liasincontrol/ui-devextreme';
import { Finance } from '@liasincontrol/data-service';
import { AppSettingsService } from '@liasincontrol/config-service';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { Button, ErrorOverlay, Heading2, PageTitle, Section, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';

export const UnlinkedJournalElements: React.FC = () => {
    const navigate = useNavigate();
    const { id: structureId } = useParams<{ id: string }>();
    const [structureInfo, setStructureInfo] = useState<Domain.Finance.Structure>();
    const [error, setError] = useState<Domain.Shared.ErrorInfo>();
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());

    useEffect(() => {
        const getData = async () => {
            try {
                const structureResponse = await Finance.StructuresDataAccessor.getById(structureId);
                setStructureInfo(structureResponse.data);
            } catch (error) {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error, true));
            }
        };
        getData();
    }, [lastRefresh]);

    const customDataSource = useMemo(() => {
        return createSource({
            keyExpr: "rk",
            paginate: true,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            dataSourcePromise: (query) => Finance.StructuresDataAccessor.getJournalElementsUnlinked(query, structureId),
        });
    }, [structureId, lastRefresh]);


    return <Wrapper>
        <WrapperContent>
            <PageTitle>
                <Heading2>
                    <Button btnbase="iconbuttons" btntype="medium_transparentmain" icon={<ArrowBack />} onClick={() => navigate(-1)}></Button>
                    {structureInfo?.name}, niet gekoppelde boekingselementen
                </Heading2>
            </PageTitle>
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                <Section look='white'>
                    <LsGrid
                        dataSource={customDataSource}
                        columns={columns}
                        showRowLines={true}
                        onDataError={(exception) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception))}
                        paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                    />
                </Section>
            </ErrorOverlay>
        </WrapperContent>
    </Wrapper>
}

const columns: GridColumn<Domain.Finance.StructureJournalElement>[] = [
    {
        name: 'code',
        title: 'Code',
        allowSorting: false,
        width: '25%',
    },
    {
        name: 'name',
        title: 'Naam',
        allowSorting: false,
        width: '50%',
    },
    {
        name: 'baseYear',
        title: 'Basisjaar',
        allowSorting: false,
        width: '25%',
    }
];