import { palette, px } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

const SideMenuList = styled.ul`
    list-style: none;
    padding: 0; 
    margin:0 ${px(24)} 0 0;
    background-color: ${palette.white};
    border-radius: ${px(5)};
    overflow: auto;
`;

const Wrapper = styled.div`
    max-height: 40vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: ${px(10)};
    }
    &::-webkit-scrollbar-track {
        width: ${px(10)};
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        width: ${px(10)};
        border-radius: ${px(10)};
        background-color: #ececf3;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #e3e3ec;
    }
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-corner {
        width: ${px(10)};
        height: ${px(1)};
        background-color: transparent;
    }
`;

const WarningText = styled.div`
    margin-bottom: ${px(12)};
`;

const styledComponents = { SideMenuList, Wrapper, WarningText };
export default styledComponents;