import React, { useEffect, useMemo, useRef } from 'react';
import ReactDomServer from 'react-dom/server';
import { MapContainer, TileLayer, MapContainerProps, Marker, Popup } from 'react-leaflet';
import styled from 'styled-components';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon, IconValue, IconSet, IconSize } from '../primitives';
import { px } from '../style';
import { HtmlViewer } from '../htmlViewer';


type MapProps = {
    id: string,
    size: number;
    markers?: MapMarkers[],
    interactive?: boolean,
    children?: React.ReactNode,
} & MapContainerProps;

export type MapMarkers = {
    coordinates: any,
    pinContent: string
}

const pinIcon = L.divIcon({
    html: ReactDomServer.renderToString(<Icon value={IconValue.Pin} size={IconSize.large} iconSet={IconSet.default} />),
    className: "svg-icon",
    iconAnchor: [11, 5]
});

export const Map: React.FC<MapProps> = (props) => {
    const mapRef = useRef(null);
    const [bounds, pinContent] = useMemo(() => splitBoundsAndPinContent(props.markers), [props.markers]);

    // Note for published website: if the markers never change and the map receives them from the very beginning, this useEffect routine won' be needed.
    // Only the markers need to be passed to the bounds and the centering + zooming will be performed by internally by leaflet.
    useEffect(() => {
        const map = mapRef.current;

        if (!map) {
            return;
        }

        map.invalidateSize();
        if (!bounds.length) {
            map.fitBounds(FALLBACK_VIEWPORT);
            return;
        }

        if (bounds.length === 1) {
            map.fitBounds([bounds[0], bounds[0]]);
            return
        }

            map.fitBounds(bounds);    
    }, [bounds, mapRef, props.size]);

    //inspired by: https://stackoverflow.com/questions/74909895/changing-react-leaflet-map-width-with-button-click
    return (
        <div style={{
            height: sizes[props.size],
            width: '100%',
        }}>
            <StyledMap
                ref={mapRef}
                bounds={bounds && bounds.length > 0 ? bounds : FALLBACK_VIEWPORT}
                attributionControl={false}
                style={defaultStyle}
                dragging={props.interactive}
                zoomControl={props.interactive}
                scrollWheelZoom={props.interactive}
                doubleClickZoom={props.interactive}
            >
                <TileLayer url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png" />
                {bounds.map((marker, index) => (
                    <Marker
                        interactive={props.interactive}
                        key={`${props.id}-marker-${index}`}
                        position={[marker[0], marker[1]]}
                        icon={pinIcon}>
                        {pinContent.length > 0 && pinContent[index] ?
                            <Popup key={`${props.id}-popup-${index}`}>
                                {/* Note: this code is the most optimal as long as pinContent and markers arrays are always synchronized (eg. do not sort one array without sorting the other) */}
                                <HtmlViewer id={`${props.id}-popup-content-${index}`} value={pinContent[index]} />
                            </Popup> : null}
                    </Marker>
                ))}
                {props.children}
            </StyledMap>
        </div>
    );
};

const splitBoundsAndPinContent = (markers?: MapMarkers[]) => markers?.reduce((markers: [any[], string[]], marker: MapMarkers) => [[...markers[0], marker.coordinates], [...markers[1], marker.pinContent]] as [any[], string[]], [[], []]) || [[], []];

const MockMapContainer = ({ children }) => (<>{children}</>);

const sizes = {
    0: '240px',
    1: '480px',
    2: '960px',
};

const defaultStyle = {
    width: '100%',
    height: '100%',
};

const StyledMap = styled(MapContainer || MockMapContainer)`
    .leaflet-popup-content {
        max-height: ${px(200)};
        overflow-y: auto;
    }
`;

const FALLBACK_VIEWPORT = [[50.5, 3], [54, 7.75]];
