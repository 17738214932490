import { Section, px } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

const PublicationSettingsSection = styled(Section)`
    width: clamp(30vw, ${px(775)}, 60vw); // empirical fine tuning
    margin: 24px;
`;

const styledComponents = { PublicationSettingsSection };

export default styledComponents;