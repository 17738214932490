import React from 'react';
import { ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import { FilterModel, JournalElementsGrid } from '../../../components/JournalElementsGrid';

type Props = {
  journalElementKinds: Domain.Finance.JournalElementKind[],
  filter: FilterModel,
  filterCallback?: (item: any) => void,
  onCancel: () => void,
  onError: (exception: any) => void,
};

export const JournalElementsResults: React.FC<Props> = (props) => {
  return (
    <ModalDialog
      settings={{
        width: 1300,
        look: 'interactive',
        title: 'Gekoppelde boekingselementen',
        footer: <ModalDialogFooter leftButtonText='Sluiten' onLeftButtonClick={props.onCancel} />,
      }}
    >
      {props.journalElementKinds &&
        <JournalElementsGrid
          journalElementKinds={props.journalElementKinds}
          pageSize={10}
          filter={props.filter}
          filterCallback={props.filterCallback}
          onError={props.onError}
        />}
    </ModalDialog>
  );
};
