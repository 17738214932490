import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Bar, Button, CardHeaderTab, ErrorOverlay, IconSize, Section, WarningWrapper, WarningLook, SVGIcon } from '@liasincontrol/ui-basics';
import { TextElement } from '@liasincontrol/ui-elements';
import { ActionSource, AjaxRequestStatus, ElementDefinitionsActionCreator, ModulesActionCreator, State, SvgIconActionCreator } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';
import { Shared } from '@liasincontrol/data-service';
import { AnyFormData, ValueType } from '@liasincontrol/core-service';
import Styled from './index.styled';
import DesignModal from './DesignModal';
import { ElementDetailsForm, StudioFormMode } from '../../../_shared/Renderer/ElementDetailsForm';
import { DetailCardLayout } from '../../../_shared/Renderer/ElementDetailsForm/utils';
import { createNewDetailCard } from '../..';

/**
 * Defines the props of the elements details page component.
 */
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

enum CardTabs {
    Details = "Details",
}

/**
 * Represents a UI component that renders the element definition details.
 */
const Index = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>((props) => {
    const { id: elementDefinitionId } = useParams<{ id: string }>();
    const [selectedCardTab, setSelectedCardTab] = useState<CardTabs>(CardTabs.Details);
    const [isDesignModalShown, setIsDesignModalShown] = useState<boolean>(false);
    const [error, setError] = useState<Domain.Shared.ErrorInfo>();
    const [form, setForm] = useState<AnyFormData>();//initFormData(props.elementDefinitions?.items[elementDefinitionId]?.fields));
    const [detailCard, setDetailCard] = useState<Domain.Shared.DetailCard>();

    const allRequiredFieldsUsed = useMemo(() => {
        if (!detailCard) {
            return;
        }
        const flatten = JSON.parse(detailCard.layout) as DetailCardLayout;
        const placedFieldIds = flatten.columns.flatMap(column => column.fieldIds);
        const requiredFieldIds = props.elementDefinitions?.items[elementDefinitionId].fields
            .filter(field => field.systemId === Domain.SystemFieldDefinitions.Studio.Dynamic && field.required).map(field => field.id);
        const found = requiredFieldIds.every(fieldId => placedFieldIds.includes(fieldId));
        return found;
    }, [detailCard]);

    const selectedElementDefinition = useMemo(() => { return props.elementDefinitions?.items[elementDefinitionId]; }, [props.elementDefinitions, elementDefinitionId]);
    const nameFieldDefinition = useMemo(() => { return selectedElementDefinition?.fields.find((fd) => fd.systemId === Domain.SystemFieldDefinitions.Studio.Name); }, [selectedElementDefinition]);
    const numberFieldDefinition = useMemo(() => { return selectedElementDefinition?.fields.find((fd) => fd.systemId === Domain.SystemFieldDefinitions.Studio.Number); }, [selectedElementDefinition]);

    useEffect(() => {
        if (!selectedElementDefinition) {
            return;
        }

        if (selectedElementDefinition.detailcards?.length === 0) {
            Shared.ElementDefinitions.getDetailCards(selectedElementDefinition.id).then(res=>{
                if(res.data.length === 0){
                    const defaultCard = createNewDetailCard("defaultCard", true, selectedElementDefinition.id);
                    Shared.ElementDefinitions.createDetailCard(defaultCard).then(() => {
                        props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
                        return;
                    });
                }else{
                    props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
                    return;
                }
            });
        }

        if (selectedElementDefinition.detailcards.length !== 0 && selectedElementDefinition.defaultDetailcardId) {
            setDetailCard(selectedElementDefinition.detailcards.find((card) => card.id === selectedElementDefinition.defaultDetailcardId));
        }

        setForm(initFormData(selectedElementDefinition.fields));
    }, [selectedElementDefinition]);

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
        return null;
    }

    //refetch if no detailcard
    if (props.elementDefinitions.status === AjaxRequestStatus.Done && !props.elementDefinitions.includeDetailCards) {
        props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
        return null;
    }

    if (!props.icons || props.icons.status === AjaxRequestStatus.NotSet) {
        props.fetchIcons();
    }

    if (!selectedElementDefinition) { return null; }

    //#region handlers
    const updateDetailCards = (detailCard: Domain.Shared.DetailCard) => {
        const updateDetailCard = new Domain.Dto.Shared.UpdateDetailCard();
        updateDetailCard.isDefault = false;
        updateDetailCard.layout = detailCard.layout;
        updateDetailCard.name = detailCard.name;

        Shared.ElementDefinitions.updateDetailCard(detailCard.id, updateDetailCard)
            .then(() => { props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]); })
            .catch((err) => setError(err))
            .finally(hideDesignModal);
    };

    const hideDesignModal = () => setIsDesignModalShown(false);
    //#endregion handlers

    const cardHeaderTabElements = Object.keys(CardTabs).map((cardTab) => {
        return (
            <CardHeaderTab
                id={`btn-nav-${cardTab}`}
                key={cardTab}
                active={selectedCardTab === CardTabs[cardTab]}
                onClick={() => setSelectedCardTab(CardTabs[cardTab])}
                title={CardTabs[cardTab]} />
        );
    });

    return (
        <>
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={null} onBack={error?.canGoBack ? () => setError(undefined) : undefined}>
                <Bar look='toolbar'>
                    <Bar start>
                        <Button
                            id='btn-add-new-elementkind'
                            btnbase='textbuttons'
                            btntype='medium_icon'
                            onClick={() => setIsDesignModalShown(true)}
                        >
                            Bewerken
                        </Button>
                    </Bar>
                </Bar>
                {detailCard && !allRequiredFieldsUsed && <WarningWrapper
                    look={WarningLook.dangerInverted}
                    icon={<WarningAmberIcon />}
                    className='mb-100 p-025'
                    messageText='Let op: Niet alle verplichte velden staan op de kaart.' />}
                <Section look='white' padding={false}>
                    <Styled.Grid>
                        <Styled.HeaderForm>
                            <SVGIcon value={props.icons.items[selectedElementDefinition.icon]?.svg} size={IconSize.medium} color={selectedElementDefinition.color} />
                            <Styled.StyledLabel>{selectedElementDefinition.name}</Styled.StyledLabel>
                        </Styled.HeaderForm>
                        <Styled.HeaderNumber>
                            <TextElement
                                id={`nummer-field-${numberFieldDefinition.id}`}
                                label={numberFieldDefinition.label ? numberFieldDefinition.label : numberFieldDefinition.name}
                                editorSettings={{
                                    disabled: true,
                                    validationErrors: undefined,
                                    restrictions: { required: true },
                                    onChange: noOp,
                                }}
                                value='123...'
                            /></Styled.HeaderNumber>
                        <Styled.HeaderName>
                            <TextElement
                                id={`naam-field-${nameFieldDefinition.id}`}
                                label={nameFieldDefinition.label ? nameFieldDefinition.label : nameFieldDefinition.name}
                                editorSettings={{
                                    disabled: true,
                                    validationErrors: undefined,
                                    restrictions: { required: true },
                                    onChange: noOp,
                                }}
                                value='abc...'
                            />
                        </Styled.HeaderName>
                        <Styled.ContentHeaderBar />
                        <Styled.ContentHeader>
                            {cardHeaderTabElements}
                        </Styled.ContentHeader>
                        {selectedCardTab === CardTabs.Details && detailCard && (
                            <ElementDetailsForm
                                elementDefinition={selectedElementDefinition}
                                detailLayout={JSON.parse(detailCard.layout) as DetailCardLayout}
                                mode={StudioFormMode.View}
                                viewData={{ form: form }}
                            />
                        )}
                    </Styled.Grid>
                </Section>
            </ErrorOverlay>
            {detailCard && isDesignModalShown &&
                <DesignModal
                    isOpen={isDesignModalShown}
                    detailsCard={detailCard}
                    elementDefinition={selectedElementDefinition}
                    allRequiredFieldsUsed={allRequiredFieldsUsed}
                    icons={props.icons?.items}
                    onCancel={hideDesignModal}
                    onSave={updateDetailCards}
                />}
        </>
    );
});

const initFormData = (fieldDefinitions?: Domain.Shared.FieldDefinition[]): AnyFormData => {
    const values: Record<string, ValueType> = fieldDefinitions?.reduce((collection, item) =>
        ({ ...collection, [`${item.id}`]: item.dataType === Domain.Shared.FieldDataType.String.toString() ? 'Lorem ipsum dolor sit amet.' : undefined }), {});
    return {
        values: values,
        touched: {},
        validationErrors: {},
        isValid: true,
    };
};

const noOp = () => { return; };

const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Studio],
        elementDefinitions: state.elementdefinitions[ActionSource.Studio],
        icons: state.icons,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Studio, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Studio, data: { moduleId: module.id, includeDetailCards: true, includeDeleted: true } }));
        },
        fetchIcons: () => {
            dispatch(SvgIconActionCreator.set());
        }
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
