import styled from 'styled-components';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { palette, px } from '@liasincontrol/ui-basics';

const Container = styled.div`
  display: grid;
  grid-template-columns: auto ${px(48)};
`;
const Center = styled.div`
  display: block;
  margin-right: ${px(16)};
`;
const Button = styled.button`
  position: relative;
  display: flex;
  top: ${px(25)};
  height: fit-content;
  overflow: hidden;
  background-color: transparent;
  border: 0 transparent;
  padding: 0;
  outline: 0;
  :focus {
    outline: none;
  }
`;
const ButtonInner = styled.span`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: center;
  width: ${px(48)};
  height: ${px(48)};
  line-height: 0;
  border-radius: ${px(6)};
  background-color: ${palette.primary4};
  border: ${px(2)} solid ${palette.primary4};
  padding: 0;
  outline: 0;
  cursor: pointer;
  color: ${palette.primary1b};
  transition: background-color 0.3s ease, border-color 0.3s ease,
    color 0.3s ease;
  :hover {
    background-color: ${palette.primary3};
    border-color: ${palette.primary3};
    color: #2934cc;
  }
  :focus {
    outline: none;
  }
  :hover:focus,
  :active {
    background-color: ${palette.primary3};
    border-color: ${palette.primary3};
    color: #2934cc;
  }
  ${Button}:disabled &,
  ${Button}:focus & :disabled {
    background-color: ${palette.grey4};
    border-color: ${palette.grey4};
    color: ${palette.grey3};
    cursor: default;
  }
  /* keyboard-only focus style */
  ${Button}:focus & {
    background-color: #fff;
    border-color: #2934cc;
    color: #2934cc;
  }
`;
const OpenIcon = styled(OpenInNewIcon as any /* children: never bug */)`
  fill: currentColor;
`;

const styledComponents = { OpenIcon, ButtonInner, Container, Center, Button };

export default styledComponents;
