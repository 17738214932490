import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { PrivateRoute } from '@liasincontrol/ui-basics';
import { Cockpit } from '..';

const Contents = (
    <>
        <Route index element={<PrivateRoute component={Cockpit.index} />} />
        <Route path='*' element={<Navigate to='/notfound' />} />
    </>
);

export { Contents };