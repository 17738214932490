import styled from 'styled-components';

const FlexBox = styled.div`
    display: flex;
    align-items: center;

    svg {
        margin-right: 0.5rem;
    }

    button {
        margin-left: 0.5rem;
    }
`;

export { FlexBox };