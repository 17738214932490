import { useSelector, useDispatch } from "react-redux";
import { State, AjaxRequestStatus, JournalElementKindActionCreator } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';

type JournalElementKindsState = {
    items: Domain.Finance.JournalElementKind[],
    status: AjaxRequestStatus,
};

export const useJournalElementKinds = () => {
    const journalElementKinds = useSelector<State, JournalElementKindsState>((state) => state.finance.journalElementKinds);
    const dispatch = useDispatch();

    if (journalElementKinds.status === AjaxRequestStatus.NotSet) {
        dispatch(JournalElementKindActionCreator.set());
    }

    return journalElementKinds;
}
