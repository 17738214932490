import React, { useState } from 'react';
import { ActionsWrap, DragWrap, Li, MainLineWrap, StyledIconDrag, TxtLabel, StyledItem, StyledInput } from '../index.styled';
import { SortableHandle } from 'react-sortable-hoc';
import { Button } from '@liasincontrol/ui-basics';
import { IndicatorSize, LoadIndicator } from '@liasincontrol/ui-devextreme';
import FolderIcon from '@mui/icons-material/Folder';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CreateIcon from '@mui/icons-material/Create';
import { SitemapNode } from '../../../..';

type Props = {
    readonly value: SitemapNode,
    readonly index: number,
    readonly disabled: boolean,
    readonly editing: boolean,
    readonly rootId: string,
    readonly isBusy: boolean,
    readonly maxValueLength: number,
    readonly onNameChanged: (id: string, changedName: string) => void,
    readonly onNavigate: (to: string) => void,
    readonly onDelete: (id: string) => void,
    readonly onClone: (id: string) => void,
    readonly onRestore: (id: string) => void,
}

/**
 * Represents a UI component that renders a page from a sitemap hierarchy.
 */
export const SitemapElement: React.FC<Props> = (props) => {
    const [pageName, setPageName] = useState<string>(props.value.elementName);
    const isHomePage = props.value.elementId === props.rootId;

    const DragHandle = SortableHandle(() => <StyledIconDrag disabled={props.disabled} />);

    return (
        <Li id={`btn-page-${props.value.elementId}`} role="button">
            <MainLineWrap>
                {!isHomePage && props.editing && !props.value.markCloned ? (
                    <StyledItem canRestore={props.value.markDelete}>
                        <StyledIconDrag id={`btn-drag-${props.value.elementId}`} />
                        <StyledInput
                            id={`input-${props.value.elementId}`}
                            value={pageName}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                if (event.currentTarget.value.length > props.maxValueLength) {
                                    const trimmedName = event.currentTarget.value.substring(0, props.maxValueLength)
                                    setPageName(trimmedName);
                                    props.onNameChanged(props.value.elementId, trimmedName);
                                    return;
                                }
                                setPageName(event.currentTarget.value);
                                props.onNameChanged(props.value.elementId, event.currentTarget.value);

                            }} />
                        <ActionsWrap>
                            <Button
                                id={`btn-clone-${props.value.elementId}`}
                                disabled={props.value.markDelete}
                                btnbase="iconbuttons"
                                btntype="medium_background"
                                icon={<ContentCopyIcon />}
                                onClick={() => props.onClone(props.value.elementId)}
                                aria-label={`${props.value.elementName} kopiëren`}
                                title={`${props.value.elementName} kopiëren`}
                            />
                            {!props.value.markDelete ?
                                <Button
                                    id={`btn-delete-${props.value.elementId}`}
                                    btnbase="iconbuttons"
                                    btntype="medium_background"
                                    icon={<DeleteIcon />}
                                    onClick={() => props.onDelete(props.value.elementId)}
                                    aria-label={`${props.value.elementName} verwijderen`}
                                    title={`${props.value.elementName} verwijderen`}
                                /> :
                                <Button
                                    id={`btn-restore-${props.value.elementId}`}
                                    btnbase="iconbuttons"
                                    btntype="medium_background"
                                    icon={<DeleteOutlineOutlinedIcon />}
                                    onClick={() => props.onRestore(props.value.elementId)}
                                    aria-label={`${props.value.elementName} herstellen`}
                                    title={`${props.value.elementName} herstellen`}
                                />
                            }
                        </ActionsWrap>
                    </StyledItem>
                ) : (
                    <StyledItem canRestore={props.value.markDelete}>
                        <DragWrap id={`btn-drag-${props.value.elementId}`} aria-label={`${props.value.elementName} verslepen`} title={`${props.value.elementName} verslepen`}>
                            {!isHomePage && !props.value.markCloned && <DragHandle />}
                        </DragWrap>
                        {!props.value.markCloned &&
                                <Button
                                    id={`btn-visit-${props.value.elementId}`}
                                    disabled={props.isBusy}
                                    btnbase="iconbuttons"
                                    btntype="medium_transparentalt"
                                    onClick={() => props.onNavigate(props.value.elementId)}
                                    icon={!props.value.markCloned ? props.rootId === props.value.elementId ? <HomeIcon /> :
                                        props.value.children && props.value.children.length > 0 ? <FolderIcon /> : <CreateIcon />
                                        : null}
                                />
                        }
                        <TxtLabel title={props.value.elementName}>{props.value.elementName}</TxtLabel>
                        {!isHomePage && !props.value.markCloned &&
                            <ActionsWrap>
                                {props.isBusy
                                    ? <LoadIndicator variant={IndicatorSize.default} />
                                    : <Button
                                        id={`btn-navigate-${props.value.elementId}`}
                                        btnbase="iconbuttons"
                                        btntype="medium_transparentalt"
                                        icon={<ChevronRightIcon />}
                                        onClick={() => props.onNavigate(props.value.elementId)}
                                        aria-label={`Ga naar ${props.value.elementName}`}
                                        title={`Ga naar ${props.value.elementName}`}
                                    />
                                }
                            </ActionsWrap>
                        }
                    </StyledItem>
                )}
            </MainLineWrap>
        </Li>
    );
};