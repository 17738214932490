import styled from 'styled-components';
import { palette, px, HtmlViewer as LsHtmlViewer } from '@liasincontrol/ui-basics';
import { RichTextEditor } from '@liasincontrol/ui-devextreme';


const RichEditor = styled(RichTextEditor)`
    overflow: auto;
    border: ${px(2)} solid ${palette.grey4};
    border-radius: ${px(5)};   
    background-color: ${palette.white};

    &:hover {
        box-shadow: none;
        border: ${px(2)} solid rgba(75, 85, 246, 0.15);
    }

    &:focus-within {
        box-shadow: none;
        border: ${px(2)} solid rgba(75, 85, 246, 1);
    }

    div > p {
        margin-top: 0;
        margin-bottom: 1rem;
    }
`;

const HtmlViewer = styled(LsHtmlViewer)`
    minHeight: ${px(48)};
    padding: ${px(12)} 0;
    borderBottom: ${px(2)} solid ${palette.grey4};
    whiteSpace: 'pre-wrap';
`;

const styledComponents = { RichEditor, HtmlViewer };

export default styledComponents;