import { createReducer } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { HierarchyDefinitionsActionCreator, TenantActionCreator } from '../../../../actions/creators';
import { AjaxRequestStatus } from '../../../state';

/**
 * Represents the Hierarchy Definitions reducer.
 */
const reducer = createReducer<{
    [service: string]: {
        status: AjaxRequestStatus,
        items: { [id: string]: Domain.Shared.HierarchyDefinition }
    }
}>
    ({}, (builder) => {
        return (
            builder.addCase(HierarchyDefinitionsActionCreator.set.fulfilled, (state, action) => {
                action.payload.data.forEach(hierarchyDefinition =>
                    state[action.payload.source].items[hierarchyDefinition.hierarchyDefinitionId] = hierarchyDefinition
                );
                state[action.payload.source].status = AjaxRequestStatus.Done;
                return state;
            }),
            builder.addCase(HierarchyDefinitionsActionCreator.set.pending, (state, action) => {
                state[action.meta.arg.source] = {
                    items: {},
                    status: AjaxRequestStatus.Pending,
                };
                return state;
            }),
            builder.addCase(HierarchyDefinitionsActionCreator.set.rejected, (state, action) => {
                state[action.meta.arg.source] = {
                    items: {},
                    status: AjaxRequestStatus.Failed,
                };
                return state;
            }),
            builder.addCase(TenantActionCreator.initTenant, (state, action) => {
                state = {};
                return state;
            })
        );
    });

export default reducer;