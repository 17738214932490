import React, { useMemo, useState } from 'react';
import { Dictionary } from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { Heading3, ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';
import { FormData, FormHelper, FormMode, ValidationUtils, ValueType } from '@liasincontrol/core-service';
import { CheckboxElement } from '@liasincontrol/ui-elements';
import { PublicationForm } from '../PublicationForm';
import {
    copyPublicationSettingsFieldDefinitionId, copyDataSourcesFieldDefinitonId, copyTemplatesFieldDefinitionId,
    copySitemapFieldDefinitionId, copyContentFieldDefinitionId, getPublicationFormValidators, initPublicationForm, measureMomentDefinitionId, copyUsersFieldDefinitionId
} from '../utils';
import Styled from './index.styled';

type Props = {
    publicationId: string,
    publicationName: string,
    publicationTitle: string,
    measureMoments: Domain.Shared.FieldDefinitionOptionItem[],
    fieldDefinitions: Domain.Shared.FieldDefinition[],
    saveButtonDisabled: boolean,
    onSave: (publicationId: string, systemFields: Record<string, ValueType>) => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders the clone publication dialog.
 */
export const PublicationClone: React.FC<Props> = (props) => {
    const fieldDefinitions = props.fieldDefinitions.reduce((collection, item) => ({ ...collection, [item.systemId]: item }), {}) as Dictionary<Domain.Shared.FieldDefinition>;

    const measureMomentDefinition = { systemId: measureMomentDefinitionId, id: measureMomentDefinitionId, name: 'Moment', optionItems: props.measureMoments } as Domain.Shared.FieldDefinition;
    const copySitemapFieldDefinition = { systemId: copySitemapFieldDefinitionId, id: copySitemapFieldDefinitionId, name: 'Publicatieindeling' } as Domain.Shared.FieldDefinition;
    const copyTemplatesFieldDefinition = { systemId: copyTemplatesFieldDefinitionId, id: copyTemplatesFieldDefinitionId, name: 'Sjablonen' } as Domain.Shared.FieldDefinition;
    const copyDataSourcesFieldDefiniton = { systemId: copyDataSourcesFieldDefinitonId, id: copyDataSourcesFieldDefinitonId, name: 'Databronnen' } as Domain.Shared.FieldDefinition;
    const copyPublicationSettingsFieldDefinition = { systemId: copyPublicationSettingsFieldDefinitionId, id: copyPublicationSettingsFieldDefinitionId, name: 'Publicatieinstellingen' } as Domain.Shared.FieldDefinition;
    const copyContentFieldDefinition = { systemId: copyContentFieldDefinitionId, id: copyContentFieldDefinitionId, name: 'Paginainhoud' } as Domain.Shared.FieldDefinition;
    const copyUsersFieldDefinition = { systemId: copyUsersFieldDefinitionId, id: copyUsersFieldDefinitionId, name: 'Gekoppelde gebruikers' } as Domain.Shared.FieldDefinition;
    const validators = getPublicationFormValidators(fieldDefinitions, undefined, measureMomentDefinition, copyPublicationSettingsFieldDefinition, copyDataSourcesFieldDefiniton, copyTemplatesFieldDefinition, copySitemapFieldDefinition, copyContentFieldDefinition, copyUsersFieldDefinition);
    const [form, setForm] = useState<FormData<ValueType>>(initPublicationForm(undefined, props.publicationName, props.publicationTitle, true, true, validators));

    const storeFormValue = (value: ValueType, systemId: keyof typeof validators) => {
        setForm((prevForm) => FormHelper.validateAndStoreFormValue<FormData<ValueType>>(prevForm, value, validators, systemId));
    };

    const copyContentValue = useMemo(() => !!form.values[copyContentFieldDefinitionId], [form.values[copyContentFieldDefinitionId]]);
    const copyUsersValue = !!form.values[copyUsersFieldDefinitionId];

    return (
        <ModalDialog settings={{
            look: 'interactive',
            title: 'Kopie maken',
            footer:
                <ModalDialogFooter
                    leftButtonText='Annuleren'
                    onLeftButtonClick={props.onCancel}
                    rightButtonText='Kopie maken'
                    onRightButtonClick={() => props.onSave(props.publicationId, form.values)}
                    rightButtonDisabled={props.saveButtonDisabled || Object.keys(form.touched).length === 0 || !form.isValid}
                />
        }}>
            <PublicationForm
                form={form}
                formMode={FormMode.Clone}
                validators={validators}
                measureMoments={props.measureMoments}
                workflowTemplates={[]}
                fieldDefinitions={props.fieldDefinitions}
                onFormValueChanged={storeFormValue}
            />
            <Heading3>Kopie maken van</Heading3>
            <Styled.OptionWrapper>
                <Styled.CloneOptionsWrapper>
                    <CheckboxElement
                        id={copyPublicationSettingsFieldDefinition.id}
                        altLabel={copyPublicationSettingsFieldDefinition.name}
                        value={!!form.values[copyPublicationSettingsFieldDefinitionId]}
                        editorSettings={ValidationUtils.getEditorSettings(true, false, validators, form, (val: boolean) => { storeFormValue(val, copyPublicationSettingsFieldDefinitionId) }, copyPublicationSettingsFieldDefinitionId)}
                    />
                </Styled.CloneOptionsWrapper>
                <Styled.CloneOptionsWrapper>
                    <CheckboxElement
                        id={copySitemapFieldDefinition.id}
                        altLabel={copySitemapFieldDefinition.name}
                        value={copyUsersValue || copyContentValue || !!form.values[copySitemapFieldDefinitionId]}
                        editorSettings={ValidationUtils.getEditorSettings(true, copyUsersValue || copyContentValue, validators, form, (val: boolean) => { storeFormValue(val, copySitemapFieldDefinitionId) }, copySitemapFieldDefinitionId)}
                    />
                </Styled.CloneOptionsWrapper>
                <Styled.CloneOptionsWrapper>
                    <CheckboxElement
                        id={copyTemplatesFieldDefinition.id}
                        altLabel={copyTemplatesFieldDefinition.name}
                        value={copyUsersValue || copyContentValue || !!form.values[copyTemplatesFieldDefinitionId]}
                        editorSettings={ValidationUtils.getEditorSettings(true, copyUsersValue || copyContentValue, validators, form, (val: boolean) => { storeFormValue(val, copyTemplatesFieldDefinitionId) }, copyTemplatesFieldDefinitionId)}
                    />
                </Styled.CloneOptionsWrapper>
                <Styled.CloneOptionsWrapper>
                    <CheckboxElement
                        id={copyDataSourcesFieldDefiniton.id}
                        altLabel={copyDataSourcesFieldDefiniton.name}
                        value={!!form.values[copyDataSourcesFieldDefinitonId]}
                        editorSettings={ValidationUtils.getEditorSettings(true, false, validators, form, (val: boolean) => { storeFormValue(val, copyDataSourcesFieldDefinitonId) }, copyDataSourcesFieldDefinitonId)}
                    />
                </Styled.CloneOptionsWrapper>
                <Styled.CloneOptionsWrapper>
                    <CheckboxElement
                        id={copyContentFieldDefinition.id}
                        altLabel={copyContentFieldDefinition.name}
                        value={copyContentValue}
                        editorSettings={ValidationUtils.getEditorSettings(true, false, validators, form, (val: boolean) => { storeFormValue(val, copyContentFieldDefinitionId) }, copyDataSourcesFieldDefinitonId)}
                    />
                </Styled.CloneOptionsWrapper>
                <Styled.CloneOptionsWrapper>
                    <CheckboxElement
                        id={copyUsersFieldDefinition.id}
                        altLabel={copyUsersFieldDefinition.name}
                        value={!!form.values[copyUsersFieldDefinitionId]}
                        editorSettings={ValidationUtils.getEditorSettings(true, false, validators, form, (val: boolean) => { storeFormValue(val, copyUsersFieldDefinitionId) }, copyUsersFieldDefinitionId)}
                    />
                </Styled.CloneOptionsWrapper>
            </Styled.OptionWrapper>
        </ModalDialog>
    );
};
