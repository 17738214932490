import { ILsBarChartProps } from "./";

export const defaultBarChartSettings: ILsBarChartProps = {
    data: [],
    redrawOnResize: true,
    pieChartData: {
        valueField: '',
        argumentField: '',
        label: {
            visible: false,
            connector: {
                visible: false,
            },
        },
    },
    legendData: {
        visible: false,
    },
    tooltipData: {
        enabled: false,
    },
    animationData: {
        enabled: false,
    },
    adaptiveLayout: {
        height: 80,
        width: 80
    },
    type: "bar",
};