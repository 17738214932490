import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Look, IconValue, NavGroup, SideNavigation } from '@liasincontrol/ui-basics';
import { useAnnouncement } from '@liasincontrol/redux-service';

/**
 * Represents a UI component that renders the Analytics menu.
 */
const Menu: React.FC = () => {
    const { pathname } = useLocation();

    const announcement = useAnnouncement();

    const menuItems: NavGroup[] = [
        {
            key: 'menu-analytics',
            title: 'LIAS Analytics',
            active: !!matchPath('/analytics', pathname),
            icon: IconValue.Datawarehouse,
            to: '/analytics',
            items: [],
        },
        {
            key: 'menu-analytics-publications-list',
            active: !!matchPath('/analytics/finance/*', pathname),
            title: 'Finance',
            to: '/analytics/finance',
            icon: IconValue.Coin,
            items: [
                {
                    key: 'menu-analytics-finance',
                    active: !!matchPath('/analytics/finance/financieel', pathname),
                    title: 'Financieel management',
                    alt: 'Open Analytics Financieel management',
                    to: '/analytics/finance/financieel',
                    icon: IconValue.Computer,
                },
                {
                    key: 'menu-analytics-mip-activa',
                    active: !!matchPath('/analytics/finance/mip-activa', pathname),
                    title: 'MIP Activa',
                    alt: 'Open MIP Activa',
                    to: '/analytics/finance/mip-activa',
                    icon: IconValue.Computer,
                },
                {
                    key: 'menu-analytics-mip-monitor',
                    active: !!matchPath('/analytics/finance/mip-monitor', pathname),
                    title: 'MIP Monitor',
                    alt: 'Open MIP Monitor',
                    to: '/analytics/finance/mip-monitor',
                    icon: IconValue.Computer,
                },
            ],
        },
        {
            key: 'menu-analytics-performance',
            active: !!matchPath('/analytics/performance/*', pathname),
            title: 'Performance',
            to: '/analytics/performance',
            icon: IconValue.Monitor,
            items: [
                {
                    key: 'menu-analytics-prestatiemanagement',
                    active: !!matchPath('/analytics/performance/prestatie-management', pathname),
                    title: 'Prestatiemanagement',
                    alt: 'Open prestatie management',
                    to: '/analytics/performance/prestatie-management',
                    icon: IconValue.Computer,
                },
                {
                    key: 'menu-analytics-prestatiemonitor',
                    active: !!matchPath('/analytics/performance/prestatie-monitor', pathname),
                    title: 'Prestatiemonitor',
                    alt: 'Open prestatie monitor',
                    to: '/analytics/performance/prestatie-monitor',
                    icon: IconValue.Computer,
                },
            ],
        },
        {
            key: 'menu-analytics-grc',
            active: !!matchPath('/analytics/grc/*', pathname),
            title: 'GRC',
            to: '/analytics/grc',
            icon: IconValue.Monitor,
            items: [
                {
                    key: 'menu-analytics-vic',
                    active: !!matchPath('/analytics/grc/vic', pathname),
                    title: 'VIC',
                    alt: 'Open VIC',
                    to: '/analytics/grc/vic',
                    icon: IconValue.Computer,
                },
            ],
        },
    ];

    return (
        <SideNavigation look={Look.analytics} alt='' menuItems={menuItems} announcement={announcement.items?.message} />
    );
};

export { Menu };
