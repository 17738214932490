import React from 'react';
import { NumberField } from '@liasincontrol/ui-devextreme';
import { IntegerProps } from './index.props';
import { getValidationErrorsNode } from '../../shared/validationHelper';

/**
 * Represents a UI component that renders an numeric/integer editor.
 */
export const IntegerEditor: React.FC<IntegerProps> = (props) => {
    return <NumberField
        id={props.id}
        value={props.value}
        key={props.id}
        label={props.label}
        min={props.min}
        max={props.max}
        step={props.updownAmount}
        showSpinButtons={props.controlButtons}
        showClearButton={false}
        startValue={props.startValue}
        disabled={props.editorSettings.disabled}
        mandatory={props.editorSettings.restrictions?.required}
        error={getValidationErrorsNode(props.editorSettings.validationErrors)}
        helpText={props.helpText}
        onChange={(val) => props.editorSettings?.onChange(val)}
    />;
}
