/**
 * Represents a datastore list element.
 */
export class DataStoreListItem {

    id: string;

    name: string;

    connectionData: string;

    dataPlatformView: string;

    hierarchyDefinitionName: string;

    kind: any;

    sourceFileName: string;

    financeTable: string;

    sourceFileUploadedOn: Date;
}