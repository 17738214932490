import React, { useMemo, useState } from 'react';
import Add from '@mui/icons-material/Add';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AjaxRequestStatus, FinanceBaseYearsActionCreator, State, WorkflowTemplateActionCreator } from '@liasincontrol/redux-service';
import { ApiErrorReportingHelper, FormMode } from '@liasincontrol/core-service';
import { Finance } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { Bar, Button, ErrorOverlay, Heading2, PageTitle, Section, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { ContextMenu, GridColumn, LsGrid, createSource } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import '../../assets/dxtreeview.style.less';
import { BudgetJournalKindForm } from './BudgetJournalKindForm';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

type BudgetJournalKindDialogType = {
    showDialog: boolean,
    isSaving: boolean,
    data: Domain.Finance.BudgetJournalKindItem,
    formMode: FormMode
};

/**
 * Represents a UI component that renders the page with the list of BudgetJournalKinds.
 */
const BudgetJournalKinds: React.FC<Props> = (props) => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [budgetJournalKindDialog, setBudgetJournalKindDialog] = useState<BudgetJournalKindDialogType>({ showDialog: false, isSaving: false, data: undefined, formMode: FormMode.View });

    const baseYears = useMemo(() => {
        return props.baseYears.items;
    }, [props.baseYears]);

    const navigate = useNavigate();

    const gotoItemDetails = (item: Domain.Finance.BudgetJournalKind) => {
        if (UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance, ActionType.Read))
            navigate(`/finance/admin/budgetjournalkind/${item.id}`);
    };

    // #region Grid state...
    const availableColumns = getColumnConfiguration(
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance, ActionType.Read)
            ? (item) => navigate(`/finance/admin/budgetjournalkind/${item.id}`)
            : null
    );

    // #endregion...

    const customDataSource = useMemo(() => {
        return createSource({
            paginate: true,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            selectedColumns: Object.keys(new Domain.Finance.BudgetJournalKind()),
            dataSourcePromise: Finance.BudgetJournalKindDataAccessor.getAll
        });
    }, [lastRefresh]);


    if (props.workflowTemplates.status === AjaxRequestStatus.NotSet) {
        props.fetchWorkflowTemplates();
        return null;
    }

    if (props.baseYears.status === AjaxRequestStatus.NotSet) {
        props.fetchBaseYears();
        return null;
    }

    const onSave = (budgetJournalKind: Domain.Finance.BudgetJournalKindItem) => {
        const promise = budgetJournalKind.id
            ? Finance.BudgetJournalKindDataAccessor.update(budgetJournalKind)
            : Finance.BudgetJournalKindDataAccessor.create(budgetJournalKind);

        promise.then(() => {
            setLastRefresh(Date.now());
        }).catch((exception) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(budgetJournalKind.id ? ApiErrorReportingHelper.GenericMessages.Saving : ApiErrorReportingHelper.GenericMessages.Adding, exception);
            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.CodeNotUniqueBaseYearKind)) {
                setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.CodeNotUniqueBaseYear] });
            } else {
                setError(errorInfo);
            }
        }).finally(() => {
            setBudgetJournalKindDialog({ showDialog: false, isSaving: false, data: undefined, formMode: FormMode.View });
        });
    };

    return (
        <>
            <Wrapper>
                <WrapperContent>
                    <PageTitle>
                        <Heading2>Journaalsoorten</Heading2>
                    </PageTitle>
                    <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        <Bar look='toolbar'>
                            <Bar start>
                                <Button
                                    id='btn-add-new-budgetjournalkind'
                                    btnbase='textbuttons'
                                    btntype='medium_icon'
                                    icon={<Add />}
                                    onClick={() => setBudgetJournalKindDialog({ showDialog: true, isSaving: false, data: undefined, formMode: FormMode.AddNew })}
                                >
                                    Nieuw
                                </Button>
                            </Bar>
                        </Bar>
                        <Section look='white'>
                            <LsGrid
                                columns={availableColumns}
                                dataSource={customDataSource}
                                showRowLines={true}
                                paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                                enableColumnChooser={false}
                                onClickRow={gotoItemDetails}
                                onDataError={(error) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))}
                            />
                        </Section>
                    </ErrorOverlay>
                </WrapperContent>
            </Wrapper>

            {budgetJournalKindDialog.showDialog && <BudgetJournalKindForm
                budgetJournalKind={budgetJournalKindDialog.data}
                baseYears={baseYears}
                workflowTemplates={props.workflowTemplates?.items}
                onSave={(budgetJournalKind) => onSave(budgetJournalKind)}
                onCancel={() => setBudgetJournalKindDialog({ showDialog: false, isSaving: false, data: undefined, formMode: FormMode.View })}
                formMode={budgetJournalKindDialog.formMode} />
            }
        </>
    );
};

const getColumnConfiguration = (onEdit?: (item: Domain.Finance.BudgetJournalKind) => void): GridColumn<Domain.Finance.BudgetJournalKind>[] => {
    return [{
        name: 'name',
        title: 'Naam'
    },
    {
        name: 'isActive',
        title: 'Actief',
        renderCustom: ({ data }) => <>{data.isActive ? 'Actief' : 'Inactief'}</>,
    },
    {
        name: 'baseYear',
        title: 'Basisjaar',
        align: 'left'
    },
    {
        name: 'code',
        title: 'Code'
    },
    {
        name: 'workflowDefinitionName',
        title: 'Workflow',
    },
    {
        name: 'id',
        title: '',
        align: 'right',
        renderCustom: ({ data }) => (onEdit &&
            <ContextMenu<Domain.Finance.BudgetJournalKind>
                keyExpr='id'
                item={data}
                actions={[
                    {
                        action: onEdit,
                        actionName: `edit-usergroup-${data.id}`,
                        displayName: 'Bewerken',
                        ariaLabel: `Bewerk ${data.name}`,
                        hidden: !onEdit
                    }
                ]} />)
    }];
};

const mapStateToProps = (state: State) => {
    return {
        workflowTemplates: state.workflowtemplates,
        baseYears: {
            items: state.finance.baseYears.items,
            status: state.finance.baseYears.status,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchWorkflowTemplates: () => {
            dispatch(WorkflowTemplateActionCreator.set());
        },
        fetchBaseYears: () => {
            dispatch(FinanceBaseYearsActionCreator.set())
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(BudgetJournalKinds);
export { Component as index };
