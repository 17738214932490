import { createAction } from '@reduxjs/toolkit';
import Payload from '../../../../Payload';

/**
 * Represents the data of the payload.
 */
type Data = {
    attachmentId: string,
    attachment: File
};

/**
 * Represents an action that triggers the store to set an attachment.
 */
const action = createAction<Payload<Data>>('/attachments/set');
export default action;
