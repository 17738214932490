import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents the WorkflowItem data accessor.
 */
export class WorkflowItem {
    /**
     * Gets a list of contributors for a control for all workflow states of a publication page.
     * 
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageId Defines the unique identifier of the page.
     * @param componentId Defines the unique identifier of the control.
     */
    // public static getComponentWorkflowItem = async (publicationId: string, pageId: string, componentId: string, includeComponentDetails: false, includeStateDetails: false, includeTaskDetails: false): Promise<AxiosResponse<Domain.Publisher.WorkflowTask[]>> => {
    //     const config = await DataAccessHostUtils.getPubRequestConfig();
    //     const extraQueryString = includeComponentDetails || includeStateDetails || includeTaskDetails
    //         ? `?includeComponentDetails=${includeComponentDetails}&includeStateDetails=${includeStateDetails}&includeTaskDetails=${includeTaskDetails}`
    //         : '';
    //     return HttpClient.get<Domain.Publisher.WorkflowTask[]>(`/api/publication/${publicationId}/page/${pageId}/component/${componentId}/workflowitem${extraQueryString}`, config);
    // };

    /**
     * Gets a list of contributors for all controls for all workflow states of a publication page.
     * 
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageId Defines the unique identifier of the page.
     */
    public static getPageWorkflowItem = async (publicationId: string, pageId: string, includeComponentDetails = false, includeStateDetails = false, includeTaskDetails = false): Promise<AxiosResponse<Domain.Publisher.WorkflowItem[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const extraQueryString = includeComponentDetails || includeStateDetails || includeTaskDetails
            ? `?includeComponentDetails=${includeComponentDetails}&includeStateDetails=${includeStateDetails}&includeTaskDetails=${includeTaskDetails}`
            : '';
        return HttpClient.get<Domain.Publisher.WorkflowItem[]>(`/api/publication/${publicationId}/page/${pageId}/workflowitem${extraQueryString}`, config);
    };

    /**
     * Gets a list of contributors for all controls for all workflow states for a publication page in a puiblication.
     * 
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageId Defines the unique identifier of the page.
     */
    // public static getPublicationtWorkflowItem = async (publicationId: string, includeComponentDetails: false, includeStateDetails: false, includeTaskDetails: false): Promise<AxiosResponse<Domain.Publisher.WorkflowTask[]>> => {
    //     const config = await DataAccessHostUtils.getPubRequestConfig();
    //     const extraQueryString = includeComponentDetails || includeStateDetails || includeTaskDetails
    //         ? `?includeComponentDetails=${includeComponentDetails}&includeStateDetails=${includeStateDetails}&includeTaskDetails=${includeTaskDetails}`
    //         : '';
    //     return HttpClient.get<Domain.Publisher.WorkflowTask[]>(`/api/publication/${publicationId}/workflowitem${extraQueryString}`, config);
    // };
}

export default WorkflowItem;