import React, { useState } from 'react';
import { IRadioGroupFieldItem, LayoutField, LayoutForm, ModalDialog, ModalDialogFooter, RadioGroupField } from '@liasincontrol/ui-basics';

type Props = {
    saveButtonDisabled: boolean,
    currentSiteAccess: boolean,
    onCloseDialog: () => void,
    onChangeSiteAccess: (isPublic: boolean) => void,
};

/**
 * Represents a UI component that renders the version site access configuration modal dialog.
 */
export const VersionSiteAccessConfiguration: React.FC<Props> = (props) => {
    const [isPublicWebsite, setIsPublicWebsite] = useState<boolean>(props.currentSiteAccess || false);

    return (
        <ModalDialog settings={{
            look: 'interactive',
            title: 'Toegang',
            footer:
                <ModalDialogFooter
                    leftButtonText='Annuleren'
                    onLeftButtonClick={props.onCloseDialog}
                    rightButtonText='Opslaan'
                    onRightButtonClick={() => props.onChangeSiteAccess(isPublicWebsite)}
                    rightButtonDisabled={props.saveButtonDisabled}
                />
        }}>
            <LayoutForm>
                <LayoutField left={1} top={1} width={7} height={1} key='siteAccess'>
                    <RadioGroupField
                        id='webVersion-siteAccess'
                        label='Toegang tot de publicatie'
                        alignment='vertical'
                        items={siteAccessItems}
                        value={isPublicWebsite.toString()}
                        onChange={(val: string) => setIsPublicWebsite(JSON.parse(val))}
                    />
                </LayoutField>
            </LayoutForm>
        </ModalDialog>
    );
};


const siteAccessItems: IRadioGroupFieldItem[] = [
    { value: 'false', label: 'Afgeschermd' },
    { value: 'true', label: 'Openbaar' },
];
