import React from 'react';
import * as Domain from '@liasincontrol/domain';

type Props = {
    publicationElement: Domain.Publisher.PublicationElement
};

/**
 * Represents a UI component that renders a document title control.
 */
const DocumentTitleControl: React.FC<Props> = (props) => {
    return (
        <div className="site-document-title document-content">
            {props.publicationElement?.title}
        </div>
    );
};

export default DocumentTitleControl;
