import { SystemFieldDefinitions } from '../../../Shared/FieldDefinition';
import { fieldDefinitionDecorator } from '../../../Shared/FieldDefinition/FieldDefinitionDecorator';

class PublicationElement {
    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Name)
    name: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Title)
    title: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.SecondaryColor)
    secondaryColor: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.SecondaryContrastColor)
    secondaryContrastColor: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PrimaryColor)
    primaryColor: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PrimaryContrastColor)
    primaryContrastColor: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.H1TextColor)
    h1FontColor: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.H1TextSize)
    h1FontSize: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.H2TextColor)
    h2FontColor: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.H2TextSize)
    h2FontSize: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.H3TextColor)
    h3FontColor: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.H3TextSize)
    h3FontSize: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TextColor)
    bodyFontColor: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TextSize)
    bodyFontSize: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Logo)
    logoAttachmentId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Favicon)
    faviconAttachmentId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.SiteDesignId)
    siteDesignId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PublicationWorkflowId)
    workflowId?: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PublicationMeasureMomentId)
    measureMomentId?: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.EndDate)
    endDate?: Date;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ReminderDate)
    reminderDate?: Date;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.AccessibilityStatement)
    accessibilityStatement?: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PrivacyStatement)
    privacyStatement?: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PortalUrl)
    portalUrl?: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.GoogleAnalyticsCode)
    googleAnalyticsCode?: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.IsClosedPublication)
    isClosed = false;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ColorPalette)
    colorPalette: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.OrganizationName)
    organizationName: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.CoverImage)
    coverImageAttachmentId: string;
}

export default PublicationElement;
