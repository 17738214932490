import React from 'react';
import { Actions } from './Actions';
import Styled from './index.styled';

type Props = {
    controlId: string,
    columnIndex: number,
    controlName: string,
    fieldIndex: number,
    selectedcontrolId?: string,
    canMoveUp: boolean,
    canMoveDown: boolean,
    onSelect: (columnIndex: number, fieldIndex: number) => void,
    onRemoveElement: (columnIndex: number, fieldIndex: number) => void,
    onMoveElement: (columnIndex: number, fieldIndex: number, moveUp: boolean) => void,
    children?: React.ReactNode,
};

/**
 * Represents a UI component that renders the wrapper over an element.
 */
export const DesignerWrapper: React.FC<Props> = (props) => {
    const isSelected = props.controlId === props.selectedcontrolId;

    return (
        <Styled.Wrapper
            role="button"
            key={`wrapper-design-${props.controlId}`}
            selected={isSelected}
            onClick={(event) => {
                event.stopPropagation();
                props.onSelect(props.columnIndex, props.fieldIndex);
            }}
        >
            {isSelected && (props.onMoveElement || props.onRemoveElement) &&
                <Actions
                    controlId={props.controlId}
                    controlName={props.controlName}
                    canMoveUp={props.canMoveUp}
                    canMoveDown={props.canMoveDown}
                    onMoveElement={(moveUp: boolean) => props.onMoveElement(props.columnIndex, props.fieldIndex, moveUp)}
                    onRemoveElement={() => props.onRemoveElement(props.columnIndex, props.fieldIndex)}
                />}
            <Styled.InnerWrapper>
                {props.children}
            </Styled.InnerWrapper>
        </Styled.Wrapper>
    );
};
