import React from 'react';
import { ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';

type Props = { 
    onLeftButtonClick: () => void,
    onRightButtonClick: () => void,
    disabled: boolean
};

/**
 * Confirm dialog shown when closing a publication
 */
export const PublicationCloseDialog: React.FC<Props> = (props) => {
    return (
        <ModalDialog settings={{
            look: 'interactive',
            title: 'Publicatie afsluiten',
            footer: <ModalDialogFooter 
                        leftButtonText='Annuleren'
                        rightButtonText='Afsluiten'
                        onLeftButtonClick={props.onLeftButtonClick}
                        onRightButtonClick={props.onRightButtonClick} 
                        leftButtonDisabled={props.disabled}
                        rightButtonDisabled={props.disabled}
                    />
        }}>
            <Text value='Weet u zeker dat u de publicatie wilt afsluiten? De publicatie kan daarna niet meer aangepast worden.' />
        </ModalDialog >
    );
};
