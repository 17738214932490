import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';

import { PageTitle, AutoFocus, Section, Heading1, EditingToolbar, ModalDialog } from '@liasincontrol/ui-basics';
import { FormInfo, FormMode } from '@liasincontrol/core-service';
import { DataStoreForm } from '../../DataStoreForm';

type Props = {
    fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>,
    hierarchyDefinitions: Domain.Shared.HierarchyDefinition[],
    onLoadAttachment: (attachmentId: string) => Promise<Blob>,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onSaveChanges: (formValues: Record<string, string>) => void,
    onCancelChanges: () => void,
};

/**
 * Represents a UI component that renders a dialog to add a new data store object.
 */
const DataStoreAdd: React.FC<Props> = (props) => {
    const [formData, setFormData] = useState<FormInfo<string>>({ values: undefined, isValid: true, isTouched: false });

    return (
        <ModalDialog customPadding modalDialogStyle="custom"
            toolbars={<EditingToolbar
                id="popup-new-datastore"
                look="default"
                isVisible={true}
                disabled={!formData?.isTouched}
                isValid={formData?.isValid}
                onSave={() => {
                    props.onSaveChanges(formData?.values);
                    setFormData(prev => ({ ...prev, isTouched: false }));
                }}
                onCancel={props.onCancelChanges} />}>
            <PageTitle>
                <Heading1>Gegevensverbinding aanmaken</Heading1>
            </PageTitle>
            <AutoFocus>
                <Section look="white">
                    <DataStoreForm
                        fieldDefinitions={props.fieldDefinitions}
                        hierarchyDefinitions={props.hierarchyDefinitions}
                        mode={FormMode.AddNew}
                        onLoadAttachment={props.onLoadAttachment}
                        onUploadAttachment={props.onUploadAttachment}
                        onFormDataChanged={setFormData}
                    />
                </Section>
            </AutoFocus>
        </ModalDialog>
    );
};

export { DataStoreAdd };
