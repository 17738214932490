import styled from 'styled-components';
import { cssBreakpoint } from '../responsive/css';
import { px } from '../style/pixel';

export const PageTitle = styled.hgroup`
    margin-bottom: ${px(24)};

    ${cssBreakpoint('xs', 's')`
        margin-bottom: ${px(16)};
    `};
`;
