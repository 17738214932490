/**
 * Represents a detail card (a way to display controls for the fields of an Element Definition).
 */
export class DetailCard {

    public readonly id: string;

    public readonly elementDefinitionId: string;

    public readonly elementDefinitionSystemId: string;

    public readonly name: string;

    public readonly layout: string;
}