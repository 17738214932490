import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';
import { oDataResponseStructured } from '../../Shared';

/**
 * Represents a data accessor of budget line items.
 */
export class BudgetLineDataAccessor {

    /**
     * Creates abudget line item.
     * @param budgetJournalId Defines the parent budget journal (journalentry).
     * @param budgetLine Defines the budget line that has to be created.
     */
    public static create = async (budgetJournalId: string, budgetLine: Domain.Finance.BudgetLine): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>(`/api/finance/budgetjournal/${budgetJournalId}/budgetline`, budgetLine, config);
    };

    /**
     *  Get a budget line of a budget journal.
     * @param budgetJournalId  Defines the budget journal id (journalentry).
     * @param budgetLineId  Defines the budget line id.
     * @returns 
     */
    public static get = async (budgetJournalId: string, budgetLineId: string): Promise<AxiosResponse<Domain.Finance.BudgetLine>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<Domain.Finance.BudgetLine>(`/api/finance/budgetjournal/${budgetJournalId}/budgetline/${budgetLineId}`, config);
    };

    /**
     *  Gets a collection of budget lines of a budget journal.
     * @param budgetJournalId  Defines the budget journal id (journalentry).
     * @param query 
     * @returns 
     */
    public static getAll = async (budgetJournalId: string, query = ''): Promise<AxiosResponse<oDataResponseStructured<Domain.Finance.BudgetLineListItem>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<oDataResponseStructured<Domain.Finance.BudgetLineListItem>>(`/api/finance/budgetjournal/${budgetJournalId}/budgetline${query}`, config);
    };


    /**
     * Deletes a budget line.
     * @param budgetJournalId  Defines the budget journal id (journalentry).
     * @param budgetLineId  Defines the budget line id.
     */
    public static delete = async (budgetJournalId: string, budgetLineId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.delete<void>(`/api/finance/budgetjournal/${budgetJournalId}/budgetline/${budgetLineId}`, config);
    };

    /**
     * Updates a budget journal item.
     * @param budgetJournalId  Defines the budget journal id (journalentry).
     * @param budgetLine  Defines the budget line.
     */
    public static update = async (budgetJournalId: string, budgetLine: Domain.Finance.BudgetLine): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const postedData = {
            shortDescription: budgetLine.shortDescription,
            longDescription: budgetLine.longDescription,
            isStructural: budgetLine.isStructural,
            amounts: budgetLine.amounts,
            elementRKs: budgetLine.elementRKs,
        };

        return HttpClient.put<string>(`/api/finance/budgetjournal/${budgetJournalId}/budgetline/${budgetLine.id}`, postedData, config);
    };

    /**
     * Imports a budget lines from a file.
     * @param budgetJournalId  Defines the budget journal id (journalentry).
     * @param importFileId  Defines the import file id.
     */
    public static importBudgetLine = async (budgetJournalId: string, importFileId: string): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<string>(`/api/finance/budgetjournal/${budgetJournalId}/budgetline/import/${importFileId}`, null, config);
    };

    /**
     * Validates the imported budget line.
     * @param budgetJournalId  Defines the budget journal id (journalentry).
     * @param importFileId  Defines the import file id.
     */
    public static validateImportedBudgetLine = async (budgetJournalId: string, importFileId: string): Promise<AxiosResponse<string[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<string[]>(`/api/finance/budgetjournal/${budgetJournalId}/budgetline/import/${importFileId}/validate`, null, config);
    };
}
