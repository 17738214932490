import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Dictionary } from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { FieldsHelper, OperationsHelper } from '@liasincontrol/core-service';
import AuthService, { UserIdentity } from '@liasincontrol/auth-service';
import { ThumbnailImg, Text, palette, IconSize, SVGIcon } from '@liasincontrol/ui-basics';
import { DxTabsIconPosition, LsTabPanel, TabPositions, Popover, PopoverPosition } from '@liasincontrol/ui-devextreme';
import { Placeholder, getPlaceholderContent, placeholderCategories } from '../../_shared/Placeholder';
import Styled from './index.styled';
import { ElementActions } from '../../../../ElementActions';

type ContainerItemElementExtended = Domain.Publisher.ContainerItemElement & { id?: number, icon?: string };
type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Dictionary<Domain.Publisher.Element>,
    publicationRoot: Domain.Publisher.ElementNode,
    publicationElement: Domain.Publisher.PublicationElement,
    selectedElementId: string,
    readonly: boolean,
    onTemplateChanged?: (operations: Domain.Publisher.Operation[], rePosition?: boolean, step?: number) => void,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onSelect: (elementId: string) => void,
    onRemoveElement?: (elementId: string, isControl: boolean) => void,
    onMoveElement?: (elementId: string, isControl: boolean, moveUp: boolean, steps?: number) => void,
    children?: React.ReactNode;
    icons?: Record<string, Domain.Shared.SvgIcon>;
};

const tabsPositions = {
    0: 'top',
    1: 'left',
    2: 'bottom',
    3: 'right',
};

const tabIconPositions = {
    0: 'top',
    1: 'start',
    2: 'end',
    3: 'bottom',
};

type DerivedState = {
    tabItemElements: ContainerItemElementExtended[],
    tabPosition: TabPositions,
    tabIconPosition: DxTabsIconPosition,
}
/**
 * Represents a UI component that renders a tab container control.
 */
const TabContainer: React.FC<Props> = (props) => {
    const [selectedItems, setSelectedItems] = useState<{ selected?: number, itemIndex?: number, itemId?: number }>({ selected: 0, itemIndex: undefined, itemId: undefined });
    const [userIdentity, setUserIdentity] = useState<UserIdentity>();
    const isDesigner = !!props.onTemplateChanged;

    const tabTitleRef = useRef(null);

    useEffect(() => {
        AuthService.getInstance()
            .then(async (auth) => await auth.getUser())
            .then((identity) => {
                if (identity) {
                    setUserIdentity(identity);
                }
            });
    }, []);

    useEffect(() => {
        if (!props.selectedElementId) {
            return;
        }
        const selectedIndex = props.element.children.findIndex((item) =>
            item.elementId === props.selectedElementId ||
            item.children.flatten(child => child.children).some(child => child.elementId === props.selectedElementId)
        );

        if (selectedIndex === -1) {
            setSelectedItems({ selected: 0, itemIndex: undefined, itemId: undefined });
            return;
        }
    }, [props.element.children, props.selectedElementId]);

    const onCreateTabItem = (toIndex?: number): void => {
        tabTitleRef.current = null;
        const ciElement = new Domain.Publisher.ContainerItemElement('Tab', '');
        ciElement.showWhitespaceBottom = false;
        ciElement.showWhitespaceLeft = false;
        ciElement.showWhitespaceRight = false;
        ciElement.showWhitespaceTop = false;
        const ciTabOperation = OperationsHelper.getOperation<Domain.Publisher.ContainerItemElement>(
            props.getElementDefinition(Domain.SystemElementDefinitions.Pub.ContainerItem),
            ciElement,
            props.element.elementId,
            Domain.Publisher.OperationKind.AddTo
        );

        const stackContainerDefinition = props.getElementDefinition(Domain.SystemElementDefinitions.Pub.StackContainer);
        const directionDefinition = stackContainerDefinition.fields.find((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.StackContainerDirection);
        const stackContainerOperation = OperationsHelper.getOperation<Domain.Publisher.StackContainerElement>(
            stackContainerDefinition,
            new Domain.Publisher.StackContainerElement(directionDefinition.optionItems.find((optionItem) => optionItem.value === Domain.Publisher.Direction.Vertical).id),
            ciTabOperation.element.elementId,
            Domain.Publisher.OperationKind.AddTo
        );

        props.onTemplateChanged([ciTabOperation, stackContainerOperation], true, tabItemElements.length - toIndex - 1);

    };

    const { tabItemElements, tabPosition, tabIconPosition } = useMemo<DerivedState>(() => {
        if (props.element?.children?.length !== 0) {
            const tabContainerElement = props.elementList[props.element.elementId];
            const tabPanelDef = props.getElementDefinition(tabContainerElement.elementDefinitionSystemId, props.elementList[props.element.children[0].elementId].elementDefinitionId);
            const tabsContainerDef = props.getElementDefinition(tabContainerElement.elementDefinitionSystemId, tabContainerElement.elementDefinitionId);

            const tabContainerModel = new Domain.Publisher.TabContainerElement();
            FieldsHelper.mapObject<Domain.Publisher.TabContainerElement>(tabContainerModel, tabsContainerDef.fields, tabContainerElement.fields);
            const directionValue = FieldsHelper.mapFieldOption<Domain.Publisher.TabContainerElement>(tabContainerModel, 'tabPosition', tabsContainerDef);
            const iconPositionValue = FieldsHelper.mapFieldOption<Domain.Publisher.TabContainerElement>(tabContainerModel, 'tabIconPosition', tabsContainerDef);

            const items: Domain.Publisher.ContainerItemElement[] = props.element.children.map((item, index) => {
                const tabItemSettings = new Domain.Publisher.ContainerItemElement();
                FieldsHelper.mapObject<Domain.Publisher.ContainerItemElement>(tabItemSettings, tabPanelDef.fields, props.elementList[item.elementId].fields);
                return {
                    ...tabItemSettings,
                    id: index,
                    icon: props.icons[tabItemSettings.tabIcon]?.svg
                };
            });
            return {
                tabItemElements: items,
                tabPosition: (tabsPositions[directionValue?.value] || 'top') as TabPositions,
                tabIconPosition: (tabIconPositions[iconPositionValue?.value] || 'top'),
            };
        } else {
            return {
                tabItemElements: [],
                tabPosition: 'top' as TabPositions,
                tabIconPosition: 'top',
            };
        }
    }, [props.icons, props.element, props.getElementDefinition, props.elementList,]);

    if (!tabItemElements) {
        return null;
    }

    const getContainerItemContent = (): JSX.Element => {
        // If a tab is deleted the first tab content will be displayed.
        if (props.element.children[selectedItems.selected]?.children?.length !== 0) {
            return props.children[selectedItems.selected];
        }

        return (
            <>
                <Styled.PlaceholderContent>
                    <ThumbnailImg variant='notes' />
                    <Text value='Voeg een container of element toe' />
                </Styled.PlaceholderContent>
                <Placeholder
                    wrapperId={props.element.children[selectedItems.selected].elementId}
                    direction={Domain.Publisher.Direction.Vertical}
                    items={getPlaceholderContent(userIdentity)}
                    groups={placeholderCategories}
                    title='Open element verticaal toevoegen aan Tab'
                    publicationElement={props.publicationElement}
                    onCreateOperation={(elementSystemId: Domain.SystemElementDefinitions.Pub) => {
                        const operations = OperationsHelper.createElement(elementSystemId, props.element.children[selectedItems.selected].elementId, Domain.Publisher.OperationKind.AddTo, props.getElementDefinition, false);
                        props.onTemplateChanged(operations);
                    }} />
            </>
        );
    };

    const onTitleClick = (index: number) => {
        const data = tabItemElements[index];
        setSelectedItems((prev) => ({ ...prev, selected: index, itemIndex: index, itemId: data.id }))
        if (isDesigner && !props.readonly && props.onSelect) {
            props.onSelect(props.element.children[index].elementId);
        }
    };

    const deleteButtonHandler = (index: number) => {
        tabTitleRef.current = null;
        const elId = props.element.children[index].elementId;
        props.onRemoveElement(elId, false);
        if (props.element.children.length > 1) {
            // Select only the first tab content, don't select the tab item.
            setSelectedItems((prev) => ({ ...prev, selected: 0, itemIndex: undefined, itemId: undefined }));
        }
    };

    const panelMove = (index: number, moveUp: boolean) => {
        tabTitleRef.current = null;
        const elId = props.element.children[index].elementId;
        props.onMoveElement(elId, false, moveUp, 1);
        //preserve selections
        const newIndex = index + (moveUp ? -1 : 1);
        setSelectedItems({ selected: newIndex, itemIndex: newIndex, itemId: tabItemElements[newIndex].id });
    };

    const renderTitle = (data) => {
        const index = +data.text;
        const orientation = ['top', 'bottom'].includes(tabPosition) ? Domain.Publisher.Direction.Horizontal : Domain.Publisher.Direction.Vertical;

        return <>
            <Styled.TabHeader className="tab-header-wrapper" onClick={(e) => {
                e.stopPropagation();
                tabTitleRef.current = e.currentTarget;
                onTitleClick(index);
            }}>
                <Styled.TabName className="tab-label" orientation={tabIconPosition}>
                    {data.icon && <SVGIcon displayMode='inline' value={data.icon} size={IconSize.medium} />}
                    <Styled.TabTitle title={data.title} orientation={tabIconPosition}>
                        {data.title}
                    </Styled.TabTitle>
                </Styled.TabName>
            </Styled.TabHeader>
            {props.onTemplateChanged && !props.readonly && tabTitleRef.current && selectedItems.itemId === index &&
                <Popover
                    target={tabTitleRef.current}
                    visible={selectedItems.itemId === index}
                    hideOnOutsideClick={true}
                    hideOnParentScroll={true}
                    wrapperAttr={{ class: 'lias-popover arrow-invisible background-transparent' }}
                    animation={null}
                    onShown={(e) => {
                        //Reposition tooltip when tab panel's content is rendered (async, datatable in tabpanel)                        
                        e.component?.instance()?.repaint();
                    }}
                    onHiding={() => { tabTitleRef.current = null; }}
                    container="#zion-body"
                >
                    <PopoverPosition
                        {...getPopoverPositionConfig(tabPosition)}
                        of={tabTitleRef.current}
                    />
                    <ElementActions
                        elementId={props.element.elementId}
                        elementName={data.title}
                        parentDirection={orientation}
                        rotateMoveIcons={false}
                        canMoveUp={selectedItems.itemIndex > 0}
                        canMoveDown={selectedItems.itemIndex < tabItemElements.length - 1}
                        canAdd={true}
                        onMoveElement={(moveUp: boolean) => panelMove(index, moveUp)}
                        onRemoveElement={() => deleteButtonHandler(index)}
                        onAddElement={() => onCreateTabItem(selectedItems.itemIndex)}
                        inPopup={true}
                    />
                </Popover>}
        </>;
    };

    return (
        <Styled.TabContainer
            id={`container-${props.element.elementId}`}
            primaryPublicationColor={props.publicationElement?.primaryColor ?? palette.primary1b}
            textColor={props.publicationElement.bodyFontColor}
            textFontSize={props.publicationElement.bodyFontSize}>
            <LsTabPanel
                id={props.element.elementId}
                data={tabItemElements}
                renderTitle={renderTitle}
                getContainerItemContent={getContainerItemContent}
                selectedTab={selectedItems.selected}
                tabsPosition={tabPosition}
                tabIconsPosition={tabIconPosition}
            />
        </Styled.TabContainer>
    );
};

export default TabContainer;

const getPopoverPositionConfig = (tabPosition: string) => {
    switch (tabPosition) {
        case 'left':
            return {
                my: { x: "top", y: "right", },
                at: { x: "top", y: "left", },
                offset: { x: 20, y: -20 },
            };
        case 'bottom':
            return {
                my: { x: "left", y: "top", },
                at: { x: "left", y: "bottom", },
                offset: { x: -20, y: -20 },
            };
        case 'right':
            return {
                my: { x: "top", y: "left", },
                at: { x: "top", y: "right", },
                offset: { x: -20, y: -20 },
            };
        case 'top':
        default:
            return {
                my: { x: "left", y: "bottom", },
                at: { x: "left", y: "top", },
                offset: { x: -20, y: 20 },
            };
    }
};