import styled from 'styled-components';
import Accordion from 'devextreme-react/accordion';

const BorderedAccordion = styled(Accordion)`
    border: 1px solid #F4F4F8;

    .dx-accordion-item-closed .dx-accordion-item-body {
        visibility: visible;
    }
`;

const AccordionField = styled.div<{ withBorder?: boolean }>`
    padding: 0.5rem 0;
    border-bottom: ${({ withBorder }) => withBorder ? '1px solid #F4F4F8' : 'none'};  
`;

const FieldLabel = styled.label<{ withMargin: boolean }>`
    display: block;
    margin-bottom: ${({ withMargin }) => withMargin ? '0.5rem' : '0'};
`;

export { BorderedAccordion, AccordionField, FieldLabel };
