import React from 'react';
import HtmlDiff from 'htmldiff-js';
import { StringUtils } from '@liasincontrol/core-service';
import * as Styled from './AuditTrailTabContent.styled';
import { Heading2 } from '@liasincontrol/ui-basics';

type Props = {
    newValue: string,
    oldValue?: string,
    mode: "split" | "inline" | "modified"
};

export const AuditTrailTabContent: React.FC<Props> = (props) => {
    if (!props.newValue) {
        return null;
    }

    if (props.mode === "modified") {
        return (
            <Styled.HtmlContentColumn>
                <Styled.HtmlContent dangerouslySetInnerHTML={StringUtils.toRawMarkup(props.newValue)} />
            </Styled.HtmlContentColumn>
        );
    }

    const diff = HtmlDiff.execute(props.oldValue || '', props.newValue);
    const htmlContent = StringUtils.toRawMarkup(diff);

    if (props.mode === "inline") {
        return (
            <Styled.HtmlContentColumn>
                <Styled.HtmlContent dangerouslySetInnerHTML={htmlContent} />
            </Styled.HtmlContentColumn>
        );
    }

    return (
        <Styled.SplitContainer>
            <Styled.RemovedContentColumn>
                <Heading2>Vorige versie</Heading2>
                <Styled.RemovedContent dangerouslySetInnerHTML={htmlContent} />
            </Styled.RemovedContentColumn>
            <Styled.VerticalDelimiterLine />
            <Styled.InsertedContentColumn>
                <Heading2>Nieuwe versie</Heading2>
                <Styled.InsertedContent dangerouslySetInnerHTML={htmlContent} />
            </Styled.InsertedContentColumn>
        </Styled.SplitContainer>
    );
}; 