import styled from 'styled-components';
import { px } from '@liasincontrol/ui-basics';


const SingleValueWrapper = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: clip;
`;

const HierarchyTypeWrapper = styled(SingleValueWrapper)`
    justify-content: flex-start;
    width: 100%;
`;

const SingleValueContent = styled.div`
    display: flex;
    width: ${px(175)};
`;

const SingleValueLabel = styled.div`
    margin-left: ${px(4)};
    text-overflow: ellipsis;
    max-width: 90%;
`;

const styledComponents = { SingleValueLabel, SingleValueContent, SingleValueWrapper, HierarchyTypeWrapper };

export default styledComponents;
