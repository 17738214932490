import styled, { css } from 'styled-components';
import { px } from '../../style';

// tslint:disable:no-magic-numbers

export const cssInternalButtonContent = css`
    align-items: center;
    justify-items: center;
    border: 0 solid transparent;
    display: grid;
    grid-auto-flow: column;
    grid-gap: ${px(8)};
    outline: 0;
    transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, border-width 0.2s ease, box-shadow 0.3s ease;
`;

export const InternalButtonContent = styled.span`
    ${cssInternalButtonContent}
`;
