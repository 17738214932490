import { css } from 'styled-components';
import { cssSmallText } from '../../primitives/typography';
import { px } from '../../style';
import { palette } from '../../styleguide';
import { base, whenDisabled } from './base';
import { iconbuttons } from './styleguide';

// tslint:disable:no-magic-numbers

const Tooltip = css`
    > span > span {
        box-shadow: 0 0 ${px(20)} rgba(75, 85, 246, 0.3);
        opacity: 0;
        pointer-events: none;
        color: #fff;
        position: absolute;
        top: calc(100% + 4px);
        background: ${palette.grey2};
        display: block;
        padding: 5px 16px;
        border-radius: 4px;
        left: 50%;
        transform: translateX(-50%) scale(0.95);
        z-index: 1;
        transition: opacity 0.2s, top 0.2s, transform 0.2s;
        text-align: center;
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -3px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            width: 6px;
            height: 6px;
            border: solid red;
            border-color: #414141 transparent transparent #414141;
        }
    }
    :hover > span > span {
        transition-delay: 0.5s;
        color: #fff;
        opacity: 1;
        pointer-events: all;
        top: calc(100% + 7px);
        transform: translateX(-50%) scale(1);
    }
`;

export const IconButtons: iconbuttons = {
    small_transparent: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 16px;
                height: 16px;
            }
            > span {
                height: ${px(24)};
                width: ${px(24)};
                border-radius: 50%;
                ${base.colors(palette.primary2, palette.transparent)};
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.primary2, palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.primary1b, `#F1F2FD`)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.primary1b, `#F1F2FD`)}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
    small_background: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 16px;
                height: 16px;
            }
            > span {
                height: ${px(24)};
                width: ${px(24)};
                border-radius: 50%;
                ${base.colors(palette.primary2, palette.primary4)};
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.primary2, palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.primary1b, `#F1F2FD`)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.primary1b, `#F1F2FD`)}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
    medium_transparentmain: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 18px;
                height: 18px;
            }
            > span {
                height: ${px(36)};
                width: ${px(36)};
                border-radius: 50%;
                ${base.colors(palette.primary2, palette.transparent)};
            }
            :focus > span {
                ${base.colors(palette.primary2, palette.white, palette.primary1b)}
            }
            :hover > span {
                ${base.colors(palette.primary1b, `#F1F2FD`)}
            }
            :active > span {
                ${base.colors(palette.primary1b, `#F1F2FD`)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
    medium_transparentalt: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 18px;
                height: 18px;
            }
            > span {
                height: ${px(36)};
                width: ${px(36)};
                border-radius: 50%;
                ${base.colors(palette.grey2, palette.transparent)};
            }
            :focus > span {
                ${base.colors(palette.grey2, palette.white, palette.primary1b)}
            }
            :hover > span {
                ${base.colors(palette.grey2, palette.grey4)}
            }
            :active > span {
                ${base.colors(palette.grey2, palette.grey4)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
    medium_background: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 18px;
                height: 18px;
            }
            > span {
                height: ${px(36)};
                width: ${px(36)};
                border-radius: 50%;
                ${base.colors(palette.primary2, palette.primary4)};
            }
            :focus > span {
                ${base.colors(palette.primary2, palette.white, palette.primary1b)}
            }
            :hover > span {
                ${base.colors(palette.primary1b, `#F1F2FD`)}
            }
            :active > span {
                ${base.colors(palette.primary1b, `#F1F2FD`)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
    medium_bluebackground: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            outline: 0;
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 18px;
                height: 18px;
            }
            > span {
                height: ${px(36)};
                width: ${px(36)};
                border-radius: 50%;
                border-width: 2px;
                ${base.colors(palette.white, palette.primary2)};
            }
            :focus > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            :active > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4, palette.grey4))}
            }
        `,
    },
    large_transparent: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 22px;
                height: 22px;
            }
            > span {
                height: ${px(44)};
                width: ${px(44)};
                border-radius: 50%;
                ${base.colors(palette.primary2, palette.transparent)};
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.primary2, palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.primary1b, `#F1F2FD`)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.primary1b, `#F1F2FD`)}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
    large_background: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 22px;
                height: 22px;
            }
            > span {
                height: ${px(44)};
                width: ${px(44)};
                border-radius: 50%;
                ${base.colors(palette.primary2, palette.primary4)};
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.primary2, palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.primary1b, `#F1F2FD`)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.primary1b, `#F1F2FD`)}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
    small_fabprimary: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            box-shadow: 0 0 ${px(20)} rgba(75, 85, 246, 0.3);
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 16px;
                height: 16px;
            }
            > span {
                height: ${px(24)};
                width: ${px(24)};
                border-radius: 50%;
                ${base.colors(palette.white, palette.primary2)};
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.primary2, palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.white, palette.primary1b)}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
    small_fabghost: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            box-shadow: 0 0 ${px(20)} rgba(75, 85, 246, 0.3);
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 16px;
                height: 16px;
            }
            > span {
                height: ${px(24)};
                width: ${px(24)};
                border-radius: 50%;
                ${base.colors(palette.primary2, palette.white)};
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.primary2, palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.primary1b, palette.white)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.primary1b, palette.white)}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
    medium_fabprimary: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            box-shadow: 0 0 ${px(20)} rgba(75, 85, 246, 0.3);
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 18px;
                height: 18px;
            }
            > span {
                height: ${px(36)};
                width: ${px(36)};
                border-radius: 50%;
                ${base.colors(palette.white, palette.primary2)};
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.primary2, palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.white, palette.primary1b)}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
    medium_fabghost: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            box-shadow: 0 0 ${px(20)} rgba(75, 85, 246, 0.3);
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 18px;
                height: 18px;
            }
            > span {
                height: ${px(36)};
                width: ${px(36)};
                border-radius: 50%;
                ${base.colors(palette.primary2, palette.white)};
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.primary2, palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.primary1b, palette.white)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.primary1b, palette.white)}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
    large_fabprimary: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            box-shadow: 0 0 ${px(20)} rgba(75, 85, 246, 0.12);
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 22px;
                height: 22px;
            }
            > span {
                height: ${px(48)};
                width: ${px(48)};
                border-radius: 50%;
                ${base.colors(palette.white, palette.primary2)};
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.primary2, palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.white, palette.primary1b)}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
    large_fabghost: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            box-shadow: 0 0 ${px(20)} rgba(75, 85, 246, 0.12);
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 22px;
                height: 22px;
            }
            > span {
                height: ${px(48)};
                width: ${px(48)};
                border-radius: 50%;
                ${base.colors(palette.primary2, palette.white)};
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.primary2, palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.primary1b, palette.white)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.primary1b, palette.white)}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
    small_admin: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 16px;
                height: 16px;
            }
            > span {
                height: ${px(24)};
                width: ${px(24)};
                border-radius: 50%;
                ${base.colors(palette.white, palette.transparent)};
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.primary2, palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.white, 'rgba(255, 255, 255, .2)')}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.white, 'rgba(255, 255, 255, .2)')}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
    medium_admin: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 18px;
                height: 18px;
            }
            > span {
                height: ${px(36)};
                width: ${px(36)};
                border-radius: 50%;
                ${base.colors(palette.white, palette.transparent)};
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.primary2, palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.white, 'rgba(255, 255, 255, .2)')}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.white, 'rgba(255, 255, 255, .2)')}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
    large_admin: {
        text: cssSmallText,
        container: css`
            border-radius: 50% !important;
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 22px;
                height: 22px;
            }
            > span {
                height: ${px(48)};
                width: ${px(48)};
                border-radius: 50%;
                ${base.colors(palette.white, palette.transparent)};
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.primary2, palette.white, palette.primary1b)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.white, 'rgba(255, 255, 255, .2)')}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.white, 'rgba(255, 255, 255, .2)')}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
        `,
    },
};
