import { createAction } from '@reduxjs/toolkit';
import Payload from '../../../../Payload';

/**
 * Represents the data of the payload.
 */
type Data = {

    /**
     * Defines the page id where the patches shall be cleared.
     */
    pageId: string,

}

/**
 * Represents an action that triggers the store to clear patches.
 */
const action = createAction<Payload<Data>>('/patches/clear');
export default action;
