import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { LayoutField, LayoutForm, ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';
import { SelectElement, TextElement } from '@liasincontrol/ui-elements';
import Styled from './index.styled';

type Props = {
    workflows: Domain.Shared.WorkflowTemplateWithStates[],
    currentName?: string,
    disabled?: boolean,
    onConfirm: (newWorkflowId: string, selectedName: string) => void,
    onCancel: () => void,
};

/** Represents a UI component that renders the dialog used to set/change the workflow for a budget journal kind. */
const SetWorkflowDialog: React.FC<Props> = (props) => {
    const [selectedWorkflow, setSelectedWorkflow] = useState<Domain.Shared.WorkflowTemplateWithStates>();
    const [selectedName, setSelectedName] = useState<string>();

    const dialogFooter: JSX.Element = (
        <ModalDialogFooter
            leftButtonText='Annuleren'
            leftButtonDisabled={props.disabled}
            onLeftButtonClick={props.onCancel}
            rightButtonText='OPSLAAN'
            onRightButtonClick={() => props.onConfirm(selectedWorkflow.id, selectedName)}
            rightButtonDisabled={!selectedWorkflow || !selectedName || selectedName.trim() !== props.currentName.trim() || props.disabled} />
    );

    return (
        <>
            <ModalDialog
                id="dialog-set-workflow"
                settings={{
                    look: 'interactive',
                    title: 'Workflow wijzigen',
                    footer: dialogFooter
                }}>
                <LayoutForm>
                    <LayoutField left={1} top={2} width={6} height={1}>
                        <Styled.TextWrapper>
                            <Text value='U staat op het punt om de workflow voor de journaalsoort te wijzigen. Alle eerder gekoppelde gebruikers worden verwijderd en alle journalen met deze journaalsoort die in de workflow staan, dienen opnieuw in de workflow gezet te worden.' />
                            <Text danger bold value='LET OP: U kunt deze actie niet ongedaan maken.' />
                        </Styled.TextWrapper>
                    </LayoutField>
                    <LayoutField left={1} top={3} width={6} height={1}>
                        <SelectElement<Domain.Shared.WorkflowTemplateWithStates>
                            id='select-new-workflow'
                            label='Workflow'
                            displayExpr='name'
                            optionItems={props.workflows}
                            value={selectedWorkflow}
                            clearable={false}
                            searchable={false}
                            editorSettings={{
                                disabled: false,
                                restrictions: { required: true },
                                validationErrors: [],
                                onChange: setSelectedWorkflow,
                            }}
                        />
                    </LayoutField>
                    <LayoutField left={1} top={4} width={6} height={1}>
                        <TextElement
                            id='name-field'
                            label='Voer als bevestiging de naam van de journaalsoort in:'
                            editorSettings={{
                                disabled: false,
                                validationErrors: [],
                                restrictions: { required: true, minLength: 2, maxLength: 100 },
                                onChange: setSelectedName
                            }}
                            value={selectedName}
                        />
                    </LayoutField>
                </LayoutForm>
            </ModalDialog>
        </>
    );
};

export { SetWorkflowDialog };