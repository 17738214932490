import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as Domain from '@liasincontrol/domain';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { Shared as DataAccess, Publisher as PubDataAccess } from '@liasincontrol/data-service';
import { ValueType, FormMode, FormInfo, ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { State, ActionSource, ModulesActionCreator, UserGroupsActionCreator, UsersActionCreator } from '@liasincontrol/redux-service';
import { WrapperContent, PageTitle, Heading1, Bar, Button, EditingToolbar, ErrorOverlay, ModalDialog } from '@liasincontrol/ui-basics';
import { IndicatorSize, LoadIndicator } from '@liasincontrol/ui-devextreme';
import ArrowBack from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';
import { UserGroupForm } from '../UserGroupForm';
import { GroupRolesForm } from '../GroupRolesForm';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity,
};

/**
 * Represents a UI component that renders the user group details page.
 */
const Index: React.FC<Props> = (props) => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [userGroup, setUserGroup] = useState<Domain.Shared.UserGroup>(undefined);
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [viewMode, setViewMode] = useState<FormMode>(FormMode.View);
    const [formInfo, setFormInfo] = useState<FormInfo<ValueType>>({ values: undefined, isValid: true, isTouched: false });
    const [rolesInfo, setRolesInfo] = useState<FormInfo<ValueType>>({ values: undefined, isValid: true, isTouched: false });
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [users, setUsers] = useState<Domain.Shared.User[]>([]);
    const [userGroupNames, setUserGroupNames] = useState<string[]>([]);
    const [groupRolesModal, setGroupRolesModal] = useState<{ visible: boolean, groupRoles: Domain.Shared.UserGroupRoles, canSave: boolean }>({ visible: false, groupRoles: undefined, canSave: false })
    const [roles, setRoles] = useState<Domain.Shared.UserRole[]>([]);

    useEffect(() => {
        PubDataAccess.RolesDataAccess.getAll().then((response) => setRoles(response.data));
    }, []);

    useEffect(() => {
        DataAccess.Users.get().then(res => setUsers(res.data));
        DataAccess.UserGroups.get().then((response) => {
            setUserGroupNames(response.data?.filter(item => item.id !== id)?.map(item => item.name));
            const group = response.data?.find(group => group.id === id);
            if (!group) return;
            setUserGroup(group);
        });
    }, [id, lastRefresh]);

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    const fetchGroupRoles = () => {
        DataAccess.UserGroups.getGroupRoles(id).then((response) => {
            setGroupRolesModal({ visible: true, groupRoles: response.data, canSave: false });
        });
    };

    const getUserGroupControl = (): JSX.Element => {
        if (!userGroup || !users) {
            return null;
        }

        return (
            <UserGroupForm
                userGroup={userGroup}
                mode={viewMode}
                userGroupNames={userGroupNames}
                users={users}
                onFormDataChanged={setFormInfo}
            />
        );
    };

    /**
     * Represents an event handler that triggers when saving the user group changes.
     */
    const saveChanges = () => {
        if (!formInfo.values) {
            setViewMode(FormMode.View);
            return;
        }

        const changedData: Domain.Dto.Shared.UpdateUserGroup = {
            name: formInfo.values['name'] as string,
            userIds: formInfo.values['userIds'] as string[],
        };

        DataAccess.UserGroups.update(id, changedData)
            .then(() => {
                props.fetchUserGroups();
                props.fetchUsers();
                setLastRefresh(Date.now());
                setViewMode(FormMode.View);
            }).catch((err) => {
                setViewMode(FormMode.View);
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err));
            });
    };

    /**
     * Represents an event handler that triggers when saving the user group roles changes.
     */
    const saveRoles = () => {
        if (!rolesInfo.values) {
            return;
        }

        DataAccess.UserGroups.setGroupRoles(id, rolesInfo.values['roles'])
            .catch((err) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err)))
            .finally(() => setGroupRolesModal({ visible: false, groupRoles: undefined, canSave: false }));
    };
    /**
     * Represents an event handler that triggers when discarding the user group changes.
     */
    const cancelChanges = () => {
        setViewMode(FormMode.View);
    };

    return (
        <WrapperContent>
            <PageTitle>
                <Heading1>
                    <Button
                        btnbase="iconbuttons"
                        btntype="medium_transparentmain"
                        icon={<ArrowBack />}
                        onClick={() => navigate('/admin/usergroup/list')}
                    />
                    {userGroup ? userGroup.name : !error ? <LoadIndicator variant={IndicatorSize.default} /> : `Gebruikersgroep`}
                </Heading1>
            </PageTitle>
            <Bar look="toolbar">
                <Bar start>
                    {!error && <>
                        <Button id={`btn-edit-${id}`} btnbase="textbuttons" btntype="medium_icon" disabled={!UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_UsersAndGroups, ActionType.Update)} onClick={() => setViewMode(FormMode.Edit)}>
                            Bewerken
                        </Button>
                        <Button id={`btn-roles-${id}`} btnbase="textbuttons" btntype="medium_icon" disabled={!UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_UsersAndGroups, ActionType.Update)} onClick={() => fetchGroupRoles()}>
                            Rollen
                        </Button>
                    </>}
                    <Button id={`btn-refresh-${id}`} btnbase="textbuttons" btntype="medium_icon" icon={<RefreshIcon />} onClick={() => setLastRefresh(Date.now())}>
                        Verversen
                    </Button>
                </Bar>
            </Bar>
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                {viewMode === FormMode.Edit ? (
                    <ModalDialog customPadding modalDialogStyle="custom"
                        toolbars={<EditingToolbar id={userGroup.id} look="default" isVisible={viewMode === FormMode.Edit}
                            isValid={formInfo?.isValid}
                            disabled={!formInfo?.isTouched}
                            onSave={saveChanges}
                            onCancel={cancelChanges}
                        />}>
                        {getUserGroupControl()}</ModalDialog>
                ) : (
                    getUserGroupControl()
                )}
                {groupRolesModal.visible && groupRolesModal.groupRoles && <ModalDialog customPadding modalDialogStyle="custom"
                    toolbars={<EditingToolbar id={userGroup.id} look="default" isVisible={true}
                        isValid={rolesInfo?.isValid}
                        disabled={!rolesInfo?.isTouched}
                        onSave={saveRoles}
                        onCancel={() => setGroupRolesModal({ visible: false, groupRoles: undefined, canSave: false })}
                    />}>
                    <GroupRolesForm
                        userGroupRoles={groupRolesModal.groupRoles}
                        roles={roles}
                        onFormDataChanged={setRolesInfo}
                    /></ModalDialog>}
            </ErrorOverlay>
        </WrapperContent >
    );
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Publication],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchUserGroups: () => {
            dispatch(UserGroupsActionCreator.set());
        },
        fetchUsers: () => {
            dispatch(UsersActionCreator.set());
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
