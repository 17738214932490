import { palette, px } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

const IconContainer = styled.div<{color?: string}>`
    svg {
        fill: ${({ color }) => color ? color : palette.grey2};
    }
`;

const StatusFilter = styled.div`
    margin-right: ${px(25)};
    display: flex;
    gap: ${px(10)};
`;

const styledComponents = { IconContainer, StatusFilter };

export default styledComponents;