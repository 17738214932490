import { useSelector, useDispatch } from 'react-redux';
import { State, AjaxRequestStatus, MeasureMomentsActionCreator } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';

type MeasureMomentsType = {
    items: Domain.Shared.MeasureMoment[],
    status: AjaxRequestStatus,
}

export const useMeasureMoments = () => {
    const templates = useSelector<State, MeasureMomentsType>((state) => state.measuremoments);
    const dispatch = useDispatch();

    if (templates.status === AjaxRequestStatus.NotSet) {
        dispatch(MeasureMomentsActionCreator.set());
    }

    return templates;
}