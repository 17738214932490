import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { cssFontNormal, px } from '../../style';
import { IPasswordProps } from './Props';

// tslint:disable:no-magic-numbers

const Container = styled.div`
    position: relative;
`;

interface IStyledInputProps {
    readonly hasPrefix: boolean;
    readonly hasPostfix: boolean;
    readonly hasError: boolean;
    readonly hasSuccess: boolean;
}

const StyledInput = styled.input`
    ${cssFontNormal(18, 20, 30)}

    width:100%;
    max-width:100%;
    padding:${px(12)} ${px(24)};
    margin:0;
    color:#414141;
    text-overflow:ellipsis;
    border-radius:4px;
    background:0 none;
    outline:0;
    transition:border-color 0.3s ease;
    background: #fff;
    white-space: nowrap;

    border:2px solid #f4f4f8;
    &:hover { border:2px solid rgba(75, 85, 246, 0.15); }
    &:focus { border:2px solid rgba(75, 85, 246, 1); } /* dit is #4b55f6 */

    ${({ hasPrefix }: IStyledInputProps) => (!hasPrefix ? null : css`padding-left: ${px(40)};`)}
    ${({ hasPostfix }: IStyledInputProps) => (!hasPostfix ? null : css`padding-right: ${px(40)};`)}

    ${({ hasSuccess }: IStyledInputProps) => (
        !hasSuccess ? null : css`
                & { border:2px solid rgba(139, 199, 112, 0.15); }
                &:hover { border:2px solid rgba(139, 199, 112, 0.3); }
                &:focus { border:2px solid rgba(139, 199, 112, 1); }
    `)}

    ${({ hasError }: IStyledInputProps) => (
        !hasError ? null : css`
                & { border:2px solid rgba(255,51,102, 0.15); }
                &:hover { border:2px solid rgba(255,51,102, 0.3); }
                &:focus { border:2px solid rgba(255,51,102, 1); }
    `)}
`;

const animationSlideFromLeft = keyframes`
    from { opacity: 0; transform: translate(-4px, 0); }
    to { opacity: 1; transform: translate(0, 0); }
`;
const animationSlideFromRight = keyframes`
    from { opacity: 0; transform: translate(4px, 0); }
    to { opacity: 1; transform: translate(0, 0); }
`;
const Prefix = styled.div`
    ${cssFontNormal(18, 20, 30)}
    position: absolute; left: 0; top: 0; bottom: 0; width: ${px(40)}; padding-left: ${px(8)};
    display: grid; align-items: center; justify-content: center;
    animation: ${animationSlideFromLeft} 0.3s;
`;
const Postfix = styled.div`
    ${cssFontNormal(18, 20, 30)}
    position: absolute; right: 0; top: 0; bottom: 0; width: ${px(40)}; padding-right: ${px(8)};
    display: grid; align-items: center; justify-content: center;
    animation: ${animationSlideFromRight} 0.3s;
`;

export const Password = ({ idInput, value, disabled, focused, error, success, prefix, postfix, onBlur, onFocus, onChange }: IPasswordProps) => (
    <Container>
        <StyledInput
            type="password"
            id={idInput}
            value={value}
            hasPrefix={!!prefix}
            hasPostfix={!!postfix}
            hasError={!!error}
            hasSuccess={!!success}
            onBlur={onBlur && (() => onBlur())}
            onFocus={onFocus && (() => onFocus())}
            onChange={onChange && ((e) => onChange(e.target.value))} />
        {prefix ? <Prefix>{prefix}</Prefix> : null}
        {postfix ? <Postfix>{postfix}</Postfix> : null}
    </Container>
);
