import { createAsyncThunk } from '@reduxjs/toolkit';
import { Shared as SharedDataAccess } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';

const action = createAsyncThunk<Domain.Shared.TextAssistantSkill[], void, {}>('/textassistantskills/set', async () => {
    return SharedDataAccess.TextAssistant.getSkills().then((response) => {
        return response.data['skills'];
    });

});

export default action;
