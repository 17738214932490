import { Label, px, palette } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

const modalPadding = 32;

const Grid = styled.div`
    display: grid;
    padding: ${px(modalPadding)};
    grid-template-columns: repeat(12, [col-start] 1fr);
    grid-auto-flow: row;
    grid-gap: ${px(8)} ${px(16)};
`;

const ContentGrid = styled.div`
    display: grid;
    grid-column: 1 / span 12;
    grid-row: auto / span 1;
    padding-top: 1rem;
    grid-template-columns: repeat(6, [col-content-start] 1fr);
    grid-gap: ${px(8)} ${px(20)};
    position: relative;
`;

const Content = styled.div`
    grid-column: 1 / span 12;
    grid-row: auto / span 1;
    padding-top: 1rem;
    position: relative;
`;

const Header = styled.div`
    grid-column: col-start / span 12;
    display: flex;
    align-items: center;
    min-height: ${px(32)};
`;

const DetailsField = styled.div`
    grid-column: auto / span 1;
    grid-row: auto / span 1;
`;

const StyledLabel = styled(Label)` 
    min-height: 0;
    margin-left: ${px(3)};
`;

const ContentHeader = styled.div`
    grid-column: 1 / span 12;
    grid-row: auto / span 1;
    display: flex;
    align-items: flex-end;

    margin-left: -${px(modalPadding)};
    margin-right: -${px(modalPadding)};
    padding-left: ${px(modalPadding)};
    background-color: ${palette.grey4};
    border-bottom: ${px(2)} solid ${palette.grey3b};
    .card-header-tab {
        margin-bottom: -${px(2)};
    }
`;

const TitleCellTemplate = styled.div<{ canClick?: boolean }>`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: ${({ canClick }) => (`${canClick ? 'pointer' : 'default'}`)};
`;

const styledComponents = {
    Grid, Header, StyledLabel, DetailsField, ContentHeader, ContentGrid, Content, TitleCellTemplate
};

export default styledComponents;
