import React from 'react';
import { DropDownProps } from './index.props';
import { TextViewer } from '../../shared/TextViewer';
import { DropDownEditor } from './index.editor';

/**
 * Represents a UI component that renders a DropDown element.
 */
const DropDownElement = <V,>(props: DropDownProps<V>) => {
    return (
        props.editorSettings && !props.editorSettings.disabled
            ? <DropDownEditor {...props} />
            : <TextViewer id={props.id} label={props.label} helpText={props.helpText} withoutFeedback={props.editorSettings?.withoutFeedback}
                value={getSelectedValue<V>(props.value, props.optionItems, props.displayExpr)} />

    );
};

const getSelectedValue = <V,>(value: V, optionItems: V[], displayExpr: any): string => {
    if (!value || optionItems?.length < 1) return '';

    const selectedItem = optionItems.find((optionItem) => optionItem === value);

    return selectedItem ? selectedItem[displayExpr] : ' - ';
};

export { DropDownElement }; 