import React from 'react';
import { ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';

type Props = {
    readonly entityId: string,
    readonly disableSubmitButton: boolean,
    readonly onDelete: (entityId: string) => void,
    readonly onCancel: () => void,
};

/**
 * Represents an UI component that opens a dialog that asks the user if they wants to delete an entity.
 */
export const HierarchyItemDeleteDialog: React.FC<Props> = (props) => {
    const footerElement = (
        <ModalDialogFooter
            leftButtonText='Annuleren' onLeftButtonClick={props.onCancel}
            rightButtonText='Verwijderen' onRightButtonClick={() => props.onDelete(props.entityId)}
            rightButtonDisabled={props.disableSubmitButton}
        />
    );

    return (
        <ModalDialog id="dialog"
            settings={{
                look: "message",
                title: "Entiteit verwijderen",
                footer: footerElement
            }}>
            <Text value="Weet u zeker dat u dit onderdeel wilt verwijderen? De actie kan niet ongedaan worden gemaakt." />
        </ModalDialog>
    );
};
