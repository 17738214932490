import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents a data accessor of user roles.
 */
export class RolesDataAccess {

    /**
     * Gets the list of user roles .
     */
    public static getAll = async (): Promise<AxiosResponse<Domain.Shared.UserRole[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Shared.UserRole[]>(`/api/roles`, config);
    };

    /**
     * Gets a specific role and its permissions.
     * 
     * @param roleId Defines the user role id.
     */
    public static get = async (roleId: string): Promise<AxiosResponse<Domain.Shared.UserRole>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Shared.UserRole>(`/api/role/${roleId}/permissions`, config)
            .then(res => {
                if (res.data?.permissions)
                {
                    //make sure the permissions are correctly converted to enums, not strings.
                    res.data = 
                    {
                        id: res.data.id,
                        name: res.data.name,
                        permissions: res.data.permissions.map(perm => Domain.Shared.UserPermissions[String(perm)]),
                    };
                }
                return res;
            });
    };

    /**
     * Creates a new role.
     * 
     * @param name Defines the new role object.
     */
    public static create = async (role: Domain.Dto.Shared.UserRole): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>('/api/role', role, config);
    };

    /**
     * Updates a new role.
     * 
     * @param roleId Defines the role id.
     * @param role Defines the updated role object.
     */
    public static save = async (roleId: string, role: Domain.Dto.Shared.UserRole): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<string>(`/api/role/${roleId}`, role, config);
    };

    /**
     * Removes role.
     * 
     * @param roleId Defines the user role id.
     */
    public static delete = async (roleId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.delete<void>(`/api/role/${roleId}`, config);
    };
}

export default RolesDataAccess;