/**
 * Represents the possible ways in which string controls should be displayed.
 */
enum StringDisplayType
{
    SingleLine = 0,
	MultiLine = 1,
	HTML = 2,
	HyperLink = 3,
}

/**
 * Represents the possible settings of the control associated with an element definition field.
 */
class FieldEditorControlSettings {   

	public stringDisplayFormat?: StringDisplayType;

	public numericFormatString?: string; 
}

export { StringDisplayType, FieldEditorControlSettings };