import styled from 'styled-components';
import Styled, { lineHeightCoefficient } from "../../SharedControlElements/index.styled"

const PivotTableWrapper = styled(Styled.GridWrapper) <{
    primaryColor: string,
    primaryTextColor: string,
    textColor: string,
    textFontSize?: string,
    editMode: boolean
}>`    

   .dx-pivotgrid {
        overflow: auto;
        color: ${({ textColor }) => textColor};
        font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
        line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px!important` : 'inherit')};
        
        .dx-word-wrap .dx-pivotgrid-area {
            color: ${({ textColor }) => textColor};
            font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
            line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px!important` : 'inherit')};
        }
        .dx-pivotgrid-area-data tbody td {
            color: inherit;
        }
    }
`;

const styledComponents = { PivotTableWrapper };
export default styledComponents;
