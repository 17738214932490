import { useSelector, useDispatch } from "react-redux";
import { State, AjaxRequestStatus, StructureNodeActionCreator, ActionSource } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';

type StructureNodesState = {
    [structureRK: string]: {
        items: Domain.Finance.StructureNode[],
        status: AjaxRequestStatus,
    },
};

//based on: https://www.phind.com/search?cache=ho59ccmwz0ordiq7vnbxftmf
export const useStructureNodes = () => {

    const structureNodes = useSelector<State, StructureNodesState>((state) => state.finance.structureNodes);

    const dispatch = useDispatch();

    const fetchStructureNodes = (structureRK: string) => {
        if (structureRK && !structureNodes[structureRK]) {
            dispatch(StructureNodeActionCreator.set({ source: ActionSource.Financial, data: { structureRK: structureRK } }));
        }
    }

    return { structureNodes, fetchStructureNodes };
}
