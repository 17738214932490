import styled from 'styled-components';
import { TextButtons } from '../button/internals';
import { px } from '../style';
import { palette } from '../styleguide';

interface IPresentationProps {
    readonly look?: 'default' | 'inverted';
}
interface IBadgeProps {
    readonly badge?: boolean;
}

const Wrap = styled.div`
    position: relative;
`;

const Button = styled.button`
    background-color: ${(p: IPresentationProps) => (p.look === 'inverted' ? palette.white : palette.primary2)};
    color: ${(p: IPresentationProps) => (p.look === 'inverted' ? palette.primary2 : palette.white)};
    border: 0 transparent;
    border-radius: 1.5rem;
    width: 2.1rem;
    height: 2.1rem;
    padding: 0;
    overflow: visible;
    font-size: 12px;
    letter-spacing: 0.15em;
    text-align: center;
    vertical-align: middle;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: ${(p: IPresentationProps) => (p.look === 'inverted' ? 'rgba(255,255,255, 0.4)' : '#0a16d1')};
        color: ${(p: IPresentationProps) => (p.look === 'inverted' ? '#0a16d1' : '#fff')};
        cursor: pointer;
    }
    &:active {
        border: none;
        outline: 0;
    }
`;
const Badge = styled.i`
    display: ${(p: IBadgeProps) => (p.badge && 'block') || 'none'};
    position: absolute;
    bottom: -2px;
    right: -2px;
    width: 12px;
    height: 12px;
    border-radius: 8px;
    border: 2px solid #fff;
    background-color: ${palette.danger2};
`;

const Dropdown = styled.div`
    position: absolute;
    top: calc(100% + 14px);
    left: 50%;
    transform: translateX(-50%);
    &:before {
        content: '';
        width: 8px;
        height: 8px;
        background: white;
        display: block;
        left: 50%;
        position: absolute;
        transform: translateX(-50%) rotate(45deg);
        top: -4px;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        border-radius: 4px;
        overflow: hidden;
        background: #fff;
        position: relative;
        li {
            button {
                ${TextButtons.medium_noicon.text}
                background: none;
                border: none;
                display: block;
                padding: ${px(8)} ${px(16)};
                letter-spacing: 0.05em;
                cursor: pointer;
                color: #333;
                transition: background-color 0.2s;
                white-space: nowrap;
                width: 120px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            &:hover button {
                background: rgba(0, 0, 0, 0.05);
            }
        }
        li + li {
            border-top: solid 1px rgba(0, 0, 0, 0.05);
        }
    }
`;

const styledComponents = { Wrap, Button, Badge, Dropdown };
export default styledComponents;