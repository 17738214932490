import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import { State, ActionSource, ModulesActionCreator } from '@liasincontrol/redux-service';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ContextMenu, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { Bar, Button, ErrorOverlay, Heading1, PageTitle, Section, WrapperContent } from '@liasincontrol/ui-basics';
import { AppSettingsService } from '@liasincontrol/config-service';
import { RoleAdd } from './RoleAdd';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the roles management screen.
 */
const Index: React.FC<Props> = (props) => {
    const [userRoles, setUserRoles] = useState<Domain.Shared.UserRole[]>([]);
    const [isRoleCreating, setIsRoleCreating] = useState<{ visible: boolean, permissions: any[] }>();
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [lastRefresh, setLastRefresh] = useState<number>();
    const navigate = useNavigate();

    useEffect(() => {
        DataAccess.RolesDataAccess.getAll()
            .then((response) => {
                setUserRoles(response.data);
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err));
            });
    }, [lastRefresh]);

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    /**
     * Represents an event handler that triggers when discarding the changes.
     */
    const cancelChanges = () => {
        setIsRoleCreating((prev) => ({ ...prev, visible: false }));
    };

    /**
     * Represents an event handler that triggers when creating role.
     */
    const saveChanges = (fields: Record<string, any>) => {
        if (!fields) {
            return;
        }

        const role: Domain.Dto.Shared.UserRole = {
            name: fields['name'],
            permissions: fields['permissions']
        };

        DataAccess.RolesDataAccess.create(role)
            .then(() => {
                setLastRefresh(Date.now());
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err));
            });
        cancelChanges();
    };

    const availableColumns: GridColumn<Domain.Shared.UserRole>[] = [
        { name: 'name', title: 'Naam' },
        {
            name: 'id',
            title: '',
            type: 'buttons',
            width: '5%',
            align: 'right',
            hideInColumnChooser: true,
            renderCustom: ({ data }) => {
                const action = (item) => {
                    navigate(`/admin/role/${item.id}/information`)
                };

                return (
                    <ContextMenu<Domain.Shared.UserRole>
                        keyExpr='id'
                        item={data}
                        actions={[
                            {
                                action,
                                displayName: 'Bewerken',
                                ariaLabel: `Bewerken ${data.name}`,
                                actionName: `edit-${data.id}`
                            }
                        ]}
                    />);
            },
        }];

    return (
        <>
            <WrapperContent>
                <PageTitle>
                    <Heading1>Rollen</Heading1>
                </PageTitle>
                <Bar look='toolbar'>
                    <Bar start>
                        <Button
                            id="btn-add-new-role"
                            btnbase="textbuttons"
                            btntype="medium_icon"
                            icon={<Add />}
                            disabled={false}
                            onClick={() => setIsRoleCreating({ visible: true, permissions: [] })}
                        >
                            Nieuw
                        </Button>
                    </Bar>

                </Bar>

                <Section look='white'>
                    <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        {userRoles && availableColumns && (
                            <LsGrid
                                keyExpr='id'
                                dataSource={userRoles}
                                columns={availableColumns}
                                enableColumnChooser={false}
                                searching={false}
                                paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                                showRowLines={true}
                                onClickRow={(item: Domain.Shared.UserRole) => {
                                    navigate(`/admin/role/${item.id}/information`);
                                }}
                            />
                        )}
                    </ErrorOverlay>
                </Section>
            </WrapperContent>

            {(isRoleCreating && isRoleCreating.visible) && <RoleAdd roleNames={userRoles.map(role => role.name)} licenses={props.userIdentity?.profile?.licenses || []} onSave={saveChanges} onCancel={cancelChanges} />}
        </>
    );
};


/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Publication],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
