import styled from 'styled-components';
import { px } from '@liasincontrol/ui-basics';

const OptionWrapper = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: ${px(220)};
`;

const CloneOptionsWrapper = styled.div`
  padding-top: ${px(16)};
`;

const styledComponents = { CloneOptionsWrapper, OptionWrapper };

export default styledComponents;
