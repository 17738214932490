import React from 'react';
import { Icon, IconSize, IconValue } from '@liasincontrol/ui-basics';
import { SelectElementTemplateProps } from '@liasincontrol/ui-elements';
import Styled from '../index.styled';
import TextBox from "devextreme-react/text-box";


export const momentInputCustomOptions = (inputProps: any, templateProps?: SelectElementTemplateProps) => {
    return (
        <Styled.SingleValueWrapper>
            {inputProps?.value && <Icon 
                value={inputProps.closed ? IconValue.Lock : IconValue.OpenLock} size={IconSize.small} />
            }
            {templateProps?.isFieldTemplate ?
                <TextBox stylingMode='outlined' value={inputProps?.label} placeholder={templateProps.placeholder}/>
                :
                <Styled.SingleValueLabel>
                    {inputProps?.label}
                </Styled.SingleValueLabel>
            }
        </Styled.SingleValueWrapper>
    )
};

