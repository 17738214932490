import React from 'react';
import { Look, IconValue, NavGroup, SideNavigation } from '@liasincontrol/ui-basics';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { RouteProps, useLocation, matchPath } from 'react-router-dom';
import { UserIdentity } from '@liasincontrol/auth-service';
import { useAnnouncement } from '@liasincontrol/redux-service';

/**
 * Represents a UI component that renders the Finance menu.
 */
const FinanceMenu: React.FC<RouteProps & { userIdentity: UserIdentity }> = (props) => {
    const { pathname } = useLocation();

    const announcement = useAnnouncement();
    const matchFinance = matchPath('/finance', pathname);
    const matchFinanceTasks = matchPath('/finance/tasks/*', pathname);

    const hasPermissionCOMPLEX_FinanceBudget = (props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_FinanceBudget, ActionType.Read));

    const menuItems: NavGroup[] = [
        {
            key: 'menu-finance',
            title: 'Finance',
            active: !!(matchFinance || matchFinanceTasks),
            icon: IconValue.Finance,
            to: '/finance',
            items: [
                {
                    key: 'menu-finance-tasks',
                    active: !!matchPath('/finance/tasks/overview', pathname),
                    title: 'Mijn taken',
                    alt: 'Mijn taken',
                    to: '/finance/tasks/overview',
                    icon: IconValue.CheckList2,
                },
            ],
        },
        {
            key: 'menu-finance-budgets',
            active: !!matchPath('/finance/budgets/*', pathname),
            title: 'Budgetten',
            to: hasPermissionCOMPLEX_FinanceBudget ? '/finance/budgets/overview' : '/finance/budgets/budgetjournals',
            icon: IconValue.Coin,
            items: [
                {
                    key: 'menu-budget-overview',
                    active: !!matchPath('/finance/budgets/overview', pathname),
                    title: 'Budgetoverzicht',
                    alt: 'Open budgetoverzicht',
                    to: '/finance/budgets/overview',
                    hidden: !hasPermissionCOMPLEX_FinanceBudget,
                    icon: IconValue.Computer,
                },
                {
                    key: 'menu-budget-budgetjournals',
                    active: !!matchPath('/finance/budgets/budgetjournals', pathname),
                    title: 'Begrotingswijzigingen',
                    alt: 'Open begrotingswijzigingen',
                    to: '/finance/budgets/budgetjournals',
                    icon: IconValue.Abacus,
                },
                {
                    key: 'menu-budget-development',
                    active: !!matchPath('/finance/budgets/budget-development', pathname),
                    title: 'Financiële ontwikkeling',
                    alt: 'Open Financiële ontwikkeling',
                    to: '/finance/budgets/budget-development',
                    hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_FinanceDevelopement, ActionType.Read)),
                    icon: IconValue.Coin2,
                },
            ],
        },
        {
            key: 'menu-finance-admin',
            active: !!matchPath('/finance/admin/*', pathname),
            title: 'Beheer',
            to: '/finance/admin/budgetjournalkinds',
            icon: IconValue.Settings,
            hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance, ActionType.Read)),
            items: [
                {
                    key: 'menu-admin-budgetjournalkinds',
                    active: !!matchPath('/finance/admin/budgetjournalkinds', pathname),
                    title: 'Journaalsoorten',
                    alt: 'Open Journaalsoorten',
                    to: '/finance/admin/budgetjournalkinds',
                    hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance, ActionType.Read)),
                    icon: IconValue.CheckList,
                },
                {
                    key: 'menu-admin-journalElementKinds',
                    active: !!matchPath('/finance/admin/journalelementkinds', pathname),
                    title: 'Elementsoorten',
                    alt: 'Open Elementsoorten',
                    to: '/finance/admin/journalelementkinds',
                    icon: IconValue.Layers,
                    hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance, ActionType.Read)),
                },
                {
                    key: 'menu-admin-journalElements',
                    active: !!matchPath('/finance/admin/journalelements', pathname),
                    title: 'Boekingselementen',
                    alt: 'Open Boekingselementen',
                    to: '/finance/admin/journalelements',
                    icon: IconValue.JournalElement,
                    hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance, ActionType.Read)),
                },
                {
                    key: 'menu-admin-budgetelementgroups',
                    active: !!matchPath('/finance/admin/budgetelementgroups', pathname),
                    title: 'Budgetelementgroepen',
                    alt: 'Open Budgetelementgroepen',
                    to: '/finance/admin/budgetelementgroups',
                    icon: IconValue.EuroTag,
                    hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance, ActionType.Read)),
                },
                {
                    key: 'menu-admin-obligations',
                    active: !!matchPath('/finance/admin/obligations', pathname),
                    title: 'Verplichtingen',
                    alt: 'Open Verplichtingen',
                    to: '/finance/admin/obligations',
                    icon: IconValue.Obligation,
                    hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance, ActionType.Read)),
                },
                {
                    key: 'menu-admin-budgetamounts',
                    active: !!matchPath('/finance/admin/budgetamounts', pathname),
                    title: 'Begroting',
                    alt: 'Open Begroting',
                    to: '/finance/admin/budgetamounts',
                    hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance, ActionType.Read)),
                    icon: IconValue.Budget,
                },
                {
                    key: 'menu-admin-realizationamounts',
                    active: !!matchPath('/finance/admin/realizationamounts', pathname),
                    title: 'Realisatie',
                    alt: 'Open Realisatie',
                    to: '/finance/admin/realizationamounts',
                    icon: IconValue.Realisation,
                    hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance, ActionType.Read)),
                },
                {
                    key: 'menu-admin-structures',
                    active: !!matchPath('/finance/admin/structures/*', pathname),
                    title: 'Structuren',
                    alt: 'Open Structuren',
                    to: '/finance/admin/structures',
                    icon: IconValue.Cost,
                    hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance, ActionType.Read)),
                },
                {
                    key: 'menu-admin-budgetjournalgroups',
                    active: !!matchPath('/finance/admin/budgetjournalgroups', pathname),
                    title: 'Journaalgroepen',
                    alt: 'Open Journaalgroepen',
                    to: '/finance/admin/budgetjournalgroups',
                    icon: IconValue.BudgetJournalGroup,
                    hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance, ActionType.Read)),
                }
            ],
        }
    ];

    return (
        <SideNavigation look={Look.finance} alt='' menuItems={menuItems} announcement={announcement.items?.message} />
    );
};

export { FinanceMenu as Menu };
