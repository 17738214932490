import { createReducer } from '@reduxjs/toolkit';
import { TenantActionCreator } from '../../../../actions/creators';

/**
 * Represents the Tenant reducer.
 */
const reducer = createReducer<string>(null, (builder) => {
    return (
        builder.addCase(TenantActionCreator.initTenant, (state, action) => {
            state = action.payload;
            return state;
        })
    );
});

export default reducer;
