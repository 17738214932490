import React, { useEffect, useMemo, useState } from "react";
import { range, padStart } from "lodash";
import { CardHeaderTab, EditingToolbar, ModalDialog, Icon, IconValue, IconSize, IconSet } from '@liasincontrol/ui-basics';
import { createSource, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { TextElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { Finance } from '@liasincontrol/data-service';
import Styled from './index.styled';

enum Tabs {
    Details = 1,
    Elements = 2,
}

type Props = {
    structureId: string;
    nodeId: string;
    onClose: () => void;
}

export const StructureNodeModal: React.FC<Props> = (props) => {
    const [activeTab, setActiveTab] = useState<Tabs>(Tabs.Details);
    const [nodeInfo, setNodeInfo] = useState<Domain.Finance.StructureNode>();

    useEffect(() => {
        if (!props.structureId || !props.nodeId) return;

        const getData = async () => {
            const nodeResponse = await Finance.StructuresDataAccessor.getNodeById(props.structureId, props.nodeId);
            setNodeInfo(nodeResponse.data);
        };
        getData();

    }, [props.structureId, props.nodeId]);

    const customDataSource = useMemo(() => {
        if (!props.structureId || !props.nodeId) return;

        return createSource({
            keyExpr: "rk",
            paginate: true,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            dataSourcePromise: (query) => Finance.StructuresDataAccessor.getJournalElements(query, props.structureId, props.nodeId),
        });
    }, [props.structureId, props.nodeId]);

    const extraFields = useMemo(() => {
        if (!nodeInfo) return [];
        const columns = range(1, 11).map(i => {
            const paddedIndex = padStart(i.toString(), 2, '0');
            const key = `input-extra-${paddedIndex}`;
            const label = `Extra ${paddedIndex}`;
            return (<Styled.DetailsField>
                <TextElement
                    id={key}
                    key={key}
                    label={label}
                    editorSettings={{
                        disabled: true,
                        restrictions: null,
                        validationErrors: null,
                    }}
                    value={nodeInfo[`custom${paddedIndex}`]}
                />
            </Styled.DetailsField>);
        });
        return columns;
    }, [nodeInfo]);

    return <ModalDialog
        toolbars={
            <EditingToolbar
                look="default"
                showSaveButton={false}
                isVisible={true}
                isValid={true}
                disabled={false}
                onCancel={props.onClose}
                onSave={null}
            />}
        customPadding
        modalDialogStyle="card"
    >
        <Styled.Grid>
            <Styled.Header>
                <Icon value={IconValue.Abacus} size={IconSize.medium} iconSet={IconSet.default} />
                <Styled.StyledLabel>{nodeInfo?.name}</Styled.StyledLabel>
            </Styled.Header>
            <Styled.ContentHeader>
                <CardHeaderTab
                    id='tab-details'
                    active={activeTab === Tabs.Details}
                    title='Details'
                    onClick={() => setActiveTab(Tabs.Details)}
                />
                <CardHeaderTab
                    id='tab-elements'
                    active={activeTab === Tabs.Elements}
                    title='Boekingselementen'
                    onClick={() => setActiveTab(Tabs.Elements)}
                />
            </Styled.ContentHeader>
            {activeTab === Tabs.Elements &&
                <Styled.Content>
                    <LsGrid
                        dataSource={customDataSource}
                        keyExpr='rk'
                        columns={columns}
                        showRowLines={true}
                        paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                    />
                </Styled.Content>
            }
            {activeTab === Tabs.Details &&
                <Styled.ContentGrid>
                    <Styled.DetailsField>
                        <TextElement
                            id={`input-name`}
                            label={'Naam'}
                            editorSettings={{
                                disabled: true,
                                restrictions: null,
                                validationErrors: null,
                            }}
                            value={nodeInfo?.name}
                        />
                    </Styled.DetailsField>
                    <Styled.DetailsField>
                        <TextElement
                            id={`input-code`}
                            label={'Code'}
                            editorSettings={{
                                disabled: true,
                                restrictions: null,
                                validationErrors: null,
                            }}
                            value={nodeInfo?.code}
                        />
                    </Styled.DetailsField>
                    {extraFields}
                </Styled.ContentGrid>
            }
        </Styled.Grid>
    </ModalDialog>
};

const columns: GridColumn<Domain.Finance.StructureJournalElement>[] = [
    {
        name: 'code',
        title: 'Code',
        allowSorting: false,
        width: '25%',
    },
    {
        name: 'name',
        title: 'Naam',
        allowSorting: false,
        width: '50%',
    },
    {
        name: 'baseYear',
        title: 'Basisjaar',
        allowSorting: false,
        width: '25%',
    }
];