import React from 'react';
import { NumberField } from '@liasincontrol/ui-devextreme';
import { DecimalProps } from './index.props';
import { getValidationErrorsNode } from '../../shared/validationHelper';

/**
 * Represents a UI component that renders a decimal editor.
 */
export const DecimalEditor: React.FC<DecimalProps> = (props) => {
    return <NumberField
        id={props.id}
        value={props.value}
        key={props.id}
        label={props.label}
        min={props.min}
        max={props.max}
        showSpinButtons={false}
        showClearButton={false}
        format={props.format || '#,##0.00'}
        disabled={props.editorSettings.disabled}
        mandatory={props.editorSettings.restrictions?.required}
        error={getValidationErrorsNode(props.editorSettings.validationErrors)}
        helpText={props.helpText}
        onChange={(val) => props.editorSettings?.onChange(val)}
    />;
};
