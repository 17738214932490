import styled from 'styled-components';

const StatusFilter = styled.div`
    width: 220px;

    .showdeleted-label {
        margin-right: 1rem;
    }

    .showdeleted-toggle {
        display: inline-block;
    }
    

`;

const styledComponents = { StatusFilter };
export default styledComponents;