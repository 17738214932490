import { IPointInfo, IBorder, IFont } from './common';
export interface IChartData {
    name?: string;
    valueField: string,
    argumentField: string,
    hoverMode?: 'none' | 'onlyPoint',
    selectionMode?: 'none' | 'onlyPoint',
    smallValuesGrouping?: {
        groupName: string,
        mode: 'none' | 'smallValueThreshold' | 'topN',
        threshold: number,
        topCount: number,
    },
    label?: {
        argumentFormat?: any,
        backgroundColor?: string,
        border?: IBorder,
        connector?: {
            color?: string,
            visible?: boolean,
            width?: number,
        },
        customizeText?: (pointInfo: IPointInfo) => string,
        font?: IFont,
        format?: any,
        position?: 'columns' | 'inside' | 'outside',
        radialOffset?: number,
        rotationAngle?: number,
        textOverflow?: 'ellipsis' | 'hide' | 'none',
        visible?: boolean,
        wordWrap?: 'normal' | 'breakWord' | 'none',
    },
    showInLegend?: boolean,
}

export enum ChartLabels {
    None = 0,
    Value = 1
}