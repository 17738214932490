import { opacify } from 'polished';
import { css } from 'styled-components';
import { px } from '../../style';

export const whenDisabled = (c: any) => (p: any) => p.disabled && css`:nth-child(n) { 
    ${c}
}`;

export const base = {
    button: css`
        background-color: transparent;
        border: 0 none transparent;
        box-sizing: border-box;
        cursor: pointer;
        color: inherit;
        font: inherit;
        display: inline-block;
        margin: 0;
        outline: 0 none transparent;
        padding: 0;
        position: relative;
        transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.3s ease;
        text-decoration: none;
        user-select: none;
        > span { text-transform: uppercase; }
        ${whenDisabled(css`cursor: default;`)}
    `,
    colors: (foreground: string, background: string, border = ``, pxBorderWidth = 2) => (
        css`
        background-color: ${background};
        border: ${background === (border || background)
                ? `${px(pxBorderWidth)} solid ${opacify(-1, background)}`
                : `${px(pxBorderWidth)} solid ${border || background}`};
        span, svg{
            color: ${foreground};
            line-height: 1;
        }
    `),
};
