import _ from 'lodash';
import * as Domain from '@liasincontrol/domain';

/**
 * Represents a helper that is responsible for returning any stat calculation based on performance hierarchy.
 */
export class StatisticsHelper {
    
    /**
     * The shown order based on the optionItem order value.
     */ 
    public static readonly progressOrder = [3, 2, 1, 4];

    /**
     * The performance item's statusField definition list.
     */ 
    public static readonly statusFieldDefinitionIds: string[] = [Domain.SystemFieldDefinitions.Performance.GoalStatus, Domain.SystemFieldDefinitions.Performance.AchievementStatus, Domain.SystemFieldDefinitions.Performance.ActivityStatus];

    /**
     * Available performance item types list.
     */
    public static readonly availableTypes = [Domain.SystemElementDefinitions.Performance.Goal, Domain.SystemElementDefinitions.Performance.Achievement, Domain.SystemElementDefinitions.Performance.Activity];
   
    /**
     * 
     * Represents a method that group hierarchy items by it's type and count them partioned by they progress status.
     * 
     * @param hierarchyItems Defines the performance hierarchy.
     * @param elementDefinitions Defines the performance element definitions.
     */
    public static getHierarchyItemsStatByTypeAndProgress = (hierarchyItems: Domain.Performance.HierarchyItem[], elementDefinitions: Record<string, Domain.Shared.ElementDefinition>) => {
        const grouped = _.groupBy(
            hierarchyItems.filter((item) => item.element.elementDefinitionSystemId !== Domain.SystemElementDefinitions.Performance.Policy),
            'element.elementDefinitionSystemId'
        );    
        
        const counted = Object.keys(grouped).reduce((collection, entity) => {
            const progresses = grouped[entity].map((item) => {
                const statusFieldDefinition = elementDefinitions[item.element.elementDefinitionId].fields.find((item) => StatisticsHelper.statusFieldDefinitionIds.indexOf(item.systemId) >= 0);
                const progressValue = statusFieldDefinition?.optionItems.find((option) => option.id === item.element.fields[statusFieldDefinition.id]);
                return progressValue;
            });
            return { ...collection, [entity]: _.countBy(progresses, 'name') };
        }, {});
        return counted;
    };  
}