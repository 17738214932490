import React from 'react';
import { connect } from 'react-redux';
import { Routes, useNavigate, useParams } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import * as Domain from '@liasincontrol/domain';
import { ActionSource, AjaxRequestStatus, ElementDefinitionsActionCreator, ModulesActionCreator, State } from '@liasincontrol/redux-service';
import { Button, Heading1, PageTitle, WrapperContent } from '@liasincontrol/ui-basics';
import NavBar from './NavBar';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & { components: React.ReactElement };

/**
 * Represents an UI element that renders the nested layout for a selected element definition, containing the pageheader, navigation and active content.
 */
const Index: React.FC<Props> = (props) => {
    const { id: elementDefinitionId } = useParams<{ id: string }>();
    const navigate = useNavigate();

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
        return null;
    }

    const selectedElementDefinition = Object.values(props.elementDefinitions?.items).find(item => item.id === elementDefinitionId);

    return (
        <WrapperContent>
            {elementDefinitionId &&
                <PageTitle>
                    <Heading1>
                        <Button btnbase="iconbuttons" btntype="medium_transparentmain" icon={<ArrowBack />} onClick={() => navigate('/studio/admin/elementdefinitions')}></Button>
                        {selectedElementDefinition?.name}
                    </Heading1>
                </PageTitle>
            }
            <NavBar />
            <Routes>
                {props.components.props?.children}
            </Routes>
        </WrapperContent>
    );
}

const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Studio],
        elementDefinitions: state.elementdefinitions[ActionSource.Studio],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Studio, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Studio, data: { moduleId: module?.id, includeDetailCards: true, includeDeleted: true } }));
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as DefinitionLayout };
