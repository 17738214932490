import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SortIcon from '@mui/icons-material/Sort';
import Ink from 'react-ink';
import { Link } from 'react-router-dom';
import { Button } from '../button';
import { Icon, IconSize, IconValue } from '../primitives';
import { Breakpoint } from '../responsive/breakpoints';
import { useResponsiveMode } from '../responsive/hooks';
import Styled, { Look } from './index.styled';
import Announcement from '../announcement';

type NavItem = {
    key: string,
    active: boolean,
    title: string,
    alt: string,
    to: string,
    description?: string,
    icon: IconValue,
    items?: NavItem[],
    hidden?: boolean,
};

type NavGroup = {
    key: string,
    title: string,
    active: boolean,
    icon: IconValue,
    to: string,
    items: NavItem[],
    hidden?: boolean,
};

type Props = {
    menuItems: NavGroup[],
    look: Look,
    alt?: string,
    isResponsive?: boolean,
    announcement?: string,
};

/**
 * Represents a UI component that renders a refactored navigation side menu wrapper.
 */
export const SideNavigation: React.FC<Props> = (props) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const { is } = useResponsiveMode();
    const mini = props.isResponsive && (is(Breakpoint.s) || is(Breakpoint.xs));
    const collapsed = !expanded && !!mini;

    const getGroupItemsElements = (menuItems: NavItem[]) =>
        menuItems.filter(menuItem => !menuItem.hidden).map((menuItem: NavItem, index: number) =>
            <Link id={`menu-item-${index}`} key={index} to={menuItem.to} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                {menuItem.description && <Styled.HiddenText id={`${index}-description`}>{menuItem.description}</Styled.HiddenText>}
                <Styled.MenuItemContainer
                    role='option'
                    look={props.look}
                    aria-label={menuItem.alt}
                    title={menuItem.description}
                    aria-current={menuItem.active ? 'page' : undefined}
                    aria-describedby={menuItem.description && `${index}-description`}
                    aria-selected={menuItem.active}
                    active={menuItem.active}>
                    <Styled.ButtonContent collapsed={collapsed!} >
                        {mini
                            ? <Icon value={menuItem.icon} size={IconSize.small} />
                            : <Icon value={menuItem.icon} size={IconSize.medium} />
                        }
                        {!collapsed && <Styled.ButtonText>{menuItem.title}</Styled.ButtonText>}
                        <Ink recenter={true} />
                    </Styled.ButtonContent>
                </Styled.MenuItemContainer>
            </Link>
        );

    const groupElements = props.menuItems.map((group: NavGroup, index: number) => {
        const groupItems = getGroupItemsElements(group.items);
        const floatingMenuElement = (
            <Styled.FloatingMenu collapsed={collapsed}>
                <span>{group.title}</span>
                {group.items.map((item, index) =>
                    !item.hidden ? (<Link id={`floating-menu-item-${index}`} key={index} to={item.to} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                        <Styled.FloatingMenuItem>
                            <Styled.ButtonContent collapsed={collapsed!}>
                                {mini
                                    ? <Icon value={item.icon} size={IconSize.small} />
                                    : <Icon value={item.icon} size={IconSize.medium} />}
                                <Styled.ButtonText>{item.title}</Styled.ButtonText>
                                <Ink recenter={true} />
                            </Styled.ButtonContent>
                        </Styled.FloatingMenuItem>
                    </Link>
                    ) : null)}
            </Styled.FloatingMenu>
        );

        return !group.hidden && (
            <Styled.GroupListbox key={index} role='listbox' aria-labelledby={group.title && `${index}`} active={group.active}>
                {group.title && (
                    <Link id={`btn-menu-${index}`} key={index} to={group.to} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                        <Styled.GroupItemContainer
                            active={group.active}
                            role='option'
                            aria-current={group.active ? 'page' : undefined}
                            aria-describedby={`${index}-description`}
                            aria-selected={group.active}
                        >
                            <Styled.ButtonContent collapsed={collapsed!} look={props.look} cockpitItem={index === 0}>
                                {mini
                                    ? <Icon value={group.icon} size={IconSize.small} />
                                    : <Icon value={group.icon} size={IconSize.default} />
                                }
                                {!collapsed && <Styled.ButtonText>{group.title}</Styled.ButtonText>}
                                <Ink recenter={true} />
                            </Styled.ButtonContent>
                        </Styled.GroupItemContainer>
                    </Link>
                )}
                {!group.active && group.items.length > 0 && group.items.filter(item => !item.hidden).length > 0 && floatingMenuElement}
                {group.active && groupItems}

            </Styled.GroupListbox>
        );
    });

    return (
        <Styled.Container
            role='navigation'
            aria-label={props.alt}
            collapsed={collapsed}
        >
            {mini && (
                <Button
                    id="btn-menu-toogle"
                    btnbase='iconbuttons'
                    btntype={props.look === Look.publisherAdmin ? 'medium_bluebackground' : 'medium_transparentmain'}
                    icon={expanded && mini ? <CloseIcon /> : <SortIcon />}
                    aria-label={expanded ? "Navigatie inklappen" : "Navigatie uitklappen"}
                    title={expanded ? "Navigatie inklappen" : "Navigatie uitklappen"}
                    onClick={() => setExpanded((x) => !x)}
                />
            )}
            {groupElements}
            {props.announcement && <Announcement title='Mededeling' announcement={props.announcement} />}
        </Styled.Container>
    );
};

export type { NavItem, NavGroup, Look };

SideNavigation.defaultProps = {
    isResponsive: true,
};
