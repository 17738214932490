import React from 'react';
import styled from 'styled-components';

type Props = {
    isEditing: boolean,
    children: React.ReactNode;
};

const Content = styled.div<{ isEditing: boolean }>`
    display: flex;
    flex-wrap: wrap;
    ${({ isEditing }) => isEditing && 'justify-content: center;'}
`;

/**
 * Represents a UI component that renders a section menu.
 */
export const PublicationSettingsWrapper: React.FC<Props> = (props) => {
    return (
        <Content isEditing={props.isEditing}>
           {props.children}
        </Content>
    );
};
