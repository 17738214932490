import { createAsyncThunk } from '@reduxjs/toolkit';
import { Shared as SharedDataAccess } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import Payload from '../../../../Payload';

const action = createAsyncThunk<Payload<Domain.Shared.HierarchyDefinition[]>, Payload<{ moduleId: string, includeLinkDefinitions?: boolean }>, {}>('/hierarchydefinitions/set', async (payload) => {
    return SharedDataAccess.HierarchyDefinitions.get(payload.data.moduleId, payload.data.includeLinkDefinitions ?? false)
        .then((response) => ({
                data: response.data,
                source: payload.source
            }));
});

export default action;
