import styled from 'styled-components';
import { cssFontNormal, palette, px } from '@liasincontrol/ui-basics';

const TemplateContainer = styled.div`
    background-color: ${palette.grey4}; 
    height: 100%;
    padding-top: 20%;
    text-align: center;
    `;

const TemplateSvg = styled.div`
    max-width: ${px(350)};
    max-height: ${px(250)};
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: relative;
    > div {
        width: 100%;
        height: 100%;
    }
    `;

const TemplateContent = styled.span`
    margin: ${px(32)} 0 ${px(16)} 0;
    ${cssFontNormal(18, 26, 30)};
    color: ${palette.grey2};
    display: block;
    `;

export { TemplateContainer, TemplateSvg, TemplateContent };