import React from 'react';
import { Routes as FinanceRoutes } from '@liasincontrol/finance';
import { SideMenuLayout } from '../Layouts';

/**
 * Represents an UI element that renders all Finance module sub-routes, in their specific Layouts.
 */
const FinanceModuleRoutes: React.FC = () => {
    return (
        <SideMenuLayout
            look="normal"
            sideMenu={FinanceRoutes.FinanceMenu}
            components={FinanceRoutes.Contents}
        />
    );
}

export default FinanceModuleRoutes;
