import { palette, px } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

const LabelContainer = styled.div`
    display: block;
`;

const IconValuesWrapper = styled.div`
    margin-bottom: ${px(16)};
`;

const IconContainer = styled.div<{ color?: string }>`
    svg {
        fill: ${({ color }) => color ? color : palette.grey2};
    }
`;

const ButtonContainer = styled.div`
    display: inline-block;
    padding: ${px(4)};
    margin: ${px(8)};
    border: 2px solid ${palette.grey3b};
    border-radius: 4px;
    &.selected {
        border: 2px solid ${palette.primary2};
    }
    &:focus-within {
        border: 2px solid ${palette.primary2};

        * {
            border: 0 none transparent !important;
        }
    }
`;


const CenteredDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const styledComponents = {
    ButtonContainer, IconValuesWrapper, IconContainer, LabelContainer,
    CenteredDiv
};
export default styledComponents;
