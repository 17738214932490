import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { AppSettingsService } from '@liasincontrol/config-service';
import * as Domain from '@liasincontrol/domain';
import { createSource, FieldNameOf, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { guidToODataFieldId, oDataResponse, Publisher as DataAccess } from '@liasincontrol/data-service';

import { ErrorOverlay, px } from '@liasincontrol/ui-basics';
import { ApiErrorReportingHelper, DefinitionsHelper, FieldsHelper } from '@liasincontrol/core-service';

const LayoutContainer = styled.div`
    margin-top:${px(-50)};
    margin-left: ${px(-15)};
    padding:0;
`;

type Props = {
    rootId: string,
    publicationId: string,
    elementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
    pageDesigns: Domain.Publisher.PageDesign[],
    selectedTemplateId: string,
    id: string,
    onChange: (value: Domain.Publisher.TemplateElement) => void,
    onCancel: () => void,
};

const fieldsToDisplay: FieldNameOf<Domain.Publisher.TemplateElement>[] = ['name', 'pageDesignId'];

/**
 * Represents a UI component that renders available templates to be selected in a grid.
 */
export const TemplateGridSelectEditor: React.FC<Props> = (props) => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const templateElementDefinition = useMemo(() => DefinitionsHelper.findElementDefinition(props.elementDefinitions, Domain.SystemElementDefinitions.Pub.PageTemplate), [props.elementDefinitions]);

    const customDataSource = useMemo(() => {
        if (!props.publicationId || !templateElementDefinition || !props.pageDesigns) {
            return;
        }

        const selectedColumns = DefinitionsHelper.getSelectableFieldsForAPI(new Domain.Publisher.TemplateElement(), fieldsToDisplay, templateElementDefinition);

        const thenCallBack = (data: oDataResponse<Domain.Publisher.AbstractElement[]>) => {
            const templates = data.value.map((rawElement) => {
                const templateElement = new Domain.Publisher.TemplateElement();
                // fill the settings with the data from the list of element fields based on field definitions:
                FieldsHelper.mapObject<Domain.Publisher.TemplateElement>(templateElement, templateElementDefinition.fields, rawElement);
                if (templateElement.pageDesignId) {
                    const pageDesignFieldDefinition = props.pageDesigns.find(page => page.id === templateElement.pageDesignId);
                    templateElement.pageDesignName = pageDesignFieldDefinition ? pageDesignFieldDefinition.name : '';
                }
                templateElement.id = rawElement.id;

                return templateElement;
            });

            return templates;
        };

        const mapToField = (columnName: keyof Domain.Publisher.TemplateElement & string) => {
            const fieldId = DefinitionsHelper.fieldNameToFieldId(new Domain.Publisher.TemplateElement(), columnName, templateElementDefinition);
            return guidToODataFieldId(fieldId || '');
        };

        return createSource<Domain.Publisher.AbstractElement>({
            paginate: true,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            selectedColumns,
            dataSourcePromise: (query) => DataAccess.Publications.getTemplates(props.publicationId, query),
            thenCallBack,
            columnMapper: mapToField,
        });
    }, [props.elementDefinitions, props.publicationId, templateElementDefinition, props.pageDesigns]);

    const availableColumns: GridColumn<Domain.Publisher.TemplateElement>[] = [
        {
            name: 'name',
            title: 'Naam',
            defaultSortOrder: 'asc',
        },
        {
            name: 'pageDesignId',
            title: 'Pagina ontwerp',
            renderCustom: (item) => <>{item.data.pageDesignName}</>
        }
    ];

    return (
        <LayoutContainer id={props.id} aria-labelledby={`${props.id}-label`}>
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                {availableColumns &&
                    <LsGrid
                        columns={availableColumns}
                        dataSource={customDataSource}
                        searching={true}
                        paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                        focusedRowKeys={[props.selectedTemplateId]}
                        onClickRow={(item) => props.onChange(item)}
                        showRowLines={true}
                        onDataError={(error) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))}
                    />}
            </ErrorOverlay>

        </LayoutContainer>
    );
};

