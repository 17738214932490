import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import { Heading3, ListItem, palette } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import * as Styled from './index.styled';

type Props = {
    users: Domain.Shared.RightUser[],
    disabled: boolean,
    isCurrentStep?: boolean,
    isLastStep?: boolean,
    actionsEnabled: boolean,
    showListHeading: boolean,
    error?: Domain.Shared.ErrorInfo,
    workflowTemplateStateName?: string,
    onRetryError?: () => void,
    onResetError?: () => void,
};

/**
 * Represents a UI component that renders a list of users with rights on an element/stateId or template page.
 */
export const UserRightsControl: React.FC<Props> = (props) => {

    return (
        <Styled.Wrapper isCurrentStep={props.isCurrentStep}>
            {props.workflowTemplateStateName &&
                <Styled.TitleItemContainer>
                    <Styled.TitleItem>
                        {getIcon(props.disabled, props.isLastStep, props.isCurrentStep)}
                    </Styled.TitleItem>
                    <Styled.TitleItem disabled={props.disabled}>{props.workflowTemplateStateName}</Styled.TitleItem>
                </Styled.TitleItemContainer>
            }
            {props.users.length ? (
                <Styled.SideMenuList>
                    {props.showListHeading ? <Heading3>Gekoppeld</Heading3> : null}

                    {props.users.map((user) => (
                        <ListItem
                            key={user.id}
                            id={user.id}
                            icon={user.frontIcon?.icon}
                            iconColor={{ default: user.frontIcon?.color }}
                            label={user.name}
                            canDelete={props.actionsEnabled}
                            disabled={props.disabled}
                            noBackground={true}
                            separator={false}
                        />
                    ))}
                </Styled.SideMenuList>
            ) : null}
        </Styled.Wrapper>
    );
};

const getIcon = (disabled: boolean, isLastStep: boolean, isCurrentStep: boolean) => {
    if (!disabled && isLastStep) /* if last is current, different behavior */
        return <CheckCircleIcon sx={{ color: palette.green }} />;
    else if (disabled) /* following */
        return <CircleOutlinedIcon sx={{ color: palette.grey3 }} />
    else if (isCurrentStep) /* current */
        return <CircleOutlinedIcon sx={{ color: palette.green }} />;
    else  /*passed */
        return <CheckCircleIcon sx={{ color: palette.green }} />;
};
