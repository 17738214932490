import React, { useState } from 'react';
import { AutoFocus, PageTitle, EditingToolbar, ModalDialog, Heading1 } from '@liasincontrol/ui-basics';
import { UserGroupForm } from '../../UserGroupForm';
import { ValueType, FormMode, FormInfo } from '@liasincontrol/core-service';

type Props = {
    userGroupNames?: string[],
    onSave: (name: string) => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders the user group create page.
 */
export const UserGroupAdd: React.FC<Props> = (props) => {
    const [formInfo, setFormInfo] = useState<FormInfo<ValueType>>({ values: undefined, isValid: true, isTouched: false });

    return (
        <ModalDialog
            customPadding
            modalDialogStyle='custom'
            toolbars={<EditingToolbar
                id='user-group-create-dialog'
                look='default'
                isVisible={true}
                disabled={!formInfo?.isTouched}
                isValid={formInfo.isValid}
                onSave={() => props.onSave(formInfo.values['name'])}
                onCancel={props.onCancel}
            />}>
            <PageTitle>
                <Heading1>Gebruikersgroep aanmaken</Heading1>
            </PageTitle>
            <AutoFocus>
                <UserGroupForm
                    mode={FormMode.AddNew}
                    userGroupNames={props.userGroupNames}
                    onFormDataChanged={setFormInfo}
                    users={[]}
                />
            </AutoFocus>
        </ModalDialog>

    );
};