import React, { useState, useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import _ from 'lodash';
import { SideNavigation, NavGroup, IconValue, Look } from '@liasincontrol/ui-basics';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import AuthService, { UserIdentity } from '@liasincontrol/auth-service';
import { useAnnouncement } from '@liasincontrol/redux-service';

/**
 * Represents a UI component that renders the publisher menu.
 */
const Menu: React.FC = () => {
    const [userIdentity, setUserIdentity] = useState<UserIdentity>();
    const { pathname } = useLocation();

    const announcement = useAnnouncement();

    useEffect(() => {
        AuthService.getInstance()
            .then(async (auth) => await auth.getUser())
            .then((identity) => {
                if (identity) {
                    setUserIdentity(identity);
                }
            });
    }, []);

    //https://github.com/remix-run/react-router/discussions/8132
    //Regex pattern isn't supported anymore
    const menuItems: NavGroup[] = [
        {
            key: 'menu-publisher',
            title: 'Publisher',
            active: !_.isNull(matchPath({ path: '/publisher', end: true, }, pathname)),
            icon: IconValue.Publisher,
            to: '/publisher',
            items: [],
        },
        {
            key: 'menu-publisher-publications',
            active: !_.isNull(matchPath('/publisher/publication/*', pathname)) || !_.isNull(matchPath('/publisher/admin/publication/*', pathname)),
            title: 'Publicaties',
            to: '/publisher/publication/list',
            icon: IconValue.Www,
            items: [
                {
                    key: 'menu-publisher-publications-list',
                    active: !_.isNull(matchPath('/publisher/publication/*', pathname)) || !_.isNull(matchPath('/publisher/admin/publication/*', pathname)),
                    title: 'Alle publicaties',
                    alt: 'Open lijst van alle publicaties',
                    to: '/publisher/publication/list',
                    icon: IconValue.Website,
                },
            ],
        },
        {
            key: 'menu-publisher-profile-list',
            active: !_.isNull(matchPath('/publisher/publishprofiles', pathname)) || !_.isNull(matchPath('/publisher/admin/publishprofiles', pathname)),
            title: 'Publicatie profielen',
            to: '/publisher/admin/publishprofiles',
            icon: IconValue.Context,
            items: [],
            hidden: !(userIdentity && UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_ManagePublishProfiles, ActionType.Read)),
        }
    ];

    return (
        <SideNavigation look={Look.publisherWriter} alt='' menuItems={menuItems} announcement={announcement.items?.message} />
    );
};

export { Menu };
