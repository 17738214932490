import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents a data accessor of performance hierarchy rights.
 */
export class HierarchyRightsAccessor {

    /**
     * Gets the rights of all users on the hierarchy tree and the fully computed rights of the current user.
     * 
     * @param measureMomentId Defines the measure moment unique identifier.
     */
    public static get = async (measureMomentId: string): Promise<AxiosResponse<Domain.Dto.Performance.HierarchyRights>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Dto.Performance.HierarchyRights>(`/api/performance/${measureMomentId}/rights`, config);
    };

    /**
     * Sets the contributors for a specified performance item.
     * 
     * @param measureMomentId Defines the measure moment unique identifier.
     * @param itemId Defines the performance item unique identifier.
     * @param users Defines the dictionary of user IDs to become contributors and if they each are inheritable by child items.
     */
     public static updateContributors = async (measureMomentId: string, itemId: string, users: Record<string, boolean>): Promise<AxiosResponse> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        const usersList = users ? Object.keys(users).map(userId => ({
            userId: userId,
            inheritable: users[userId],
        })) : [];

        return HttpClient.post(`/api/performance/${measureMomentId}/${itemId}/contributers`, usersList, config);
    };
}

export default HierarchyRightsAccessor;