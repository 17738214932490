import styled, { css } from 'styled-components';

const LabelContainer = styled.div`
    display: flex;
    justify-content: space-between;

    > label {
        flex-grow: 1;
    }
`;

const HoverContainer = styled.div<{ fullHeight?: boolean }>`
    
    ${({ fullHeight }) =>
        !fullHeight
            ? null
            : css`flex-grow: 1; display: flex; flex-direction: column;`}    

    .maximize-button {
        visibility: hidden;
    }

    &:hover .maximize-button {
        visibility: visible;
    }
`;

const  styledComponents = { LabelContainer, HoverContainer };

export default styledComponents;