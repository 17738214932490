import { AxiosResponse } from "axios";
import * as Domain from '@liasincontrol/domain';
import HttpClient from '@liasincontrol/http-service';
import { DataAccessHostUtils } from '../DataAccessHostUtils';

export class Announcements {
    /**
     * Gets the announcement message.
     */
    public static getAnnouncement = async (): Promise<AxiosResponse<Domain.Shared.Announcement>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Shared.Announcement>("/api/announcement", config);
    };
}
