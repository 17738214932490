import React from 'react';
import { connect } from 'react-redux';
import { Heading2, PageTitle, PanelGrid, TextImageWidget, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { AjaxRequestStatus, FinanceSettingsActionCreator, MeasureMomentsActionCreator, State } from '@liasincontrol/redux-service';


/**
 * Defines the props of the Cockpit view component.
 */
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

/**
 * Represents a UI component that renders the Finance cockpit view.
 */
const Cockpit: React.FC<Props> = (props) => {

    if (props.measureMoments.status === AjaxRequestStatus.NotSet) {
        props.fetchMeasureMoments();
        return null;
    }

    if (props.settings.status === AjaxRequestStatus.NotSet) {
        props.fetchFinanceSettings();
        return null;
    }

    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Cockpit Finance</Heading2>
                </PageTitle>
                <PanelGrid>
                    <TextImageWidget
                        title='LIAS Finance'
                        text='Raadpleeg snel de huidige budgetten en krijg inzicht in de ontwikkeling van de begroting en de budgetuitputting.
                            Stuur bij waar nodig op basis van de actuele financiële informatie.'
                    />
                </PanelGrid>
            </WrapperContent>
        </Wrapper>
    );
};

const mapStateToProps = (state: State) => {
    return {
        measureMoments: {
            items: state.measuremoments.items,
            status: state.measuremoments.status,
        },
        settings: {
            item: state.finance.settings.item,
            status: state.finance.settings.status,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchMeasureMoments: () => {
            dispatch(MeasureMomentsActionCreator.set());
        },
        fetchFinanceSettings: () => {
            dispatch(FinanceSettingsActionCreator.set());
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Cockpit);
export { Component as index };
