
/* localisation: messages for filter-builder component*/

export const Dictionary = {
    "nl": {
        "dxFilterBuilder-and": "En",
        "dxFilterBuilder-or": "Of",
        "dxFilterBuilder-notAnd": "En niet",
        "dxFilterBuilder-notOr": "Of niet",
        "dxFilterBuilder-addCondition": "Regel toevoegen",
        "dxFilterBuilder-addGroup": "Groep toevoegen",
        "dxFilterBuilder-enterValueText": "<vul waarde in>",
        "dxFilterBuilder-filterOperationEquals": "Gelijk aan",
        "dxFilterBuilder-filterOperationNotEquals": "Niet gelijk aan",
        "dxFilterBuilder-filterOperationLess": "Is kleiner dan",
        "dxFilterBuilder-filterOperationLessOrEquals": "Is kleiner dan of gelijk aan",
        "dxFilterBuilder-filterOperationGreater": "Is groter dan",
        "dxFilterBuilder-filterOperationGreaterOrEquals": "Is groter dan of gelijk aan",
        "dxFilterBuilder-filterOperationStartsWith": "Begint met",
        "dxFilterBuilder-filterOperationContains": "Bevat",
        "dxFilterBuilder-filterOperationNotContains": "Bevat niet",
        "dxFilterBuilder-filterOperationEndsWith": "Eindigt met",
        "dxFilterBuilder-filterOperationIsBlank": "Is leeg",
        "dxFilterBuilder-filterOperationIsNotBlank": "Is niet leeg",
        "dxFilterBuilder-filterOperationBetween": "Is tussen",
        "dxFilterBuilder-filterOperationAnyOf": "Is een van",
        "dxFilterBuilder-filterOperationNoneOf": "Is geen van",
    }
};