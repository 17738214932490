import { AxiosResponse } from 'axios';
import * as Domain from '@liasincontrol/domain';
import { oDataResponse } from '../../Shared/oData';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';
import HttpClient from '@liasincontrol/http-service';

/**
 * Represents the data accessor for the JournalCombinations.
 */
export class JournalCombinationDataAccessor {
    /**
     * Gets a list of JournalCombination for the given base year.
     * @param params The url parameters to get the journal combinations for.
     * @param query Additional odata query.
     */
    public static get = async (params: any, query = ''): Promise<AxiosResponse<oDataResponse<Domain.Finance.JournalCombination[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<oDataResponse<Domain.Finance.JournalCombination[]>>(`/api/finance/journalcombination${query}`, { ...config, params: params });
    };

    /**
     * Creates a new journal combination.
     * @param request The request for creating a new Journal Combination.
     */
    public static create = async (request: Domain.Finance.JournalCombinationRequestModel): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>('/api/finance/journalcombination', request, config);
    };
}
