import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { LayoutForm, LayoutField } from '@liasincontrol/ui-basics';
import { SelectElement, TextElement } from '@liasincontrol/ui-elements';

export type FilterModel = {
    baseYear?: number,
    budgetYear?: number,
    journalElementCombinationRK?: string;
    measureMomentTag?: string;
}

type Props = {
    baseYears?: number[],
    filter?: FilterModel;
    yearsBehind?: number;
    yearsAhead?: number;
    onFilterChanged: (filter: FilterModel) => void,
}

export const BudgetAmountsFilters: React.FC<Props> = (props) => {
    const [filter, setFilter] = useState<FilterModel>(props.filter);
    const budgetYearOptions = _.range(_.min(props.baseYears), +_.max(props.baseYears) + +(props.yearsAhead), 1);

    useEffect(() => {
        props.onFilterChanged(filter);
    }, [filter]);

    return (
        <LayoutForm columns={8}>
            {props.baseYears &&
                <LayoutField left={1} top={1} width={2} height={1}>
                    <SelectElement<number>
                        id='select-base-year'
                        label='Basisjaar'
                        optionItems={props.baseYears}
                        value={filter.baseYear}
                        clearable={true}
                        searchable={false}
                        editorSettings={{
                            disabled: false,
                            restrictions: { required: false },
                            validationErrors: [],
                            onChange: (item) => setFilter(prev => ({ ...prev, baseYear: item })),
                        }}
                    />
                </LayoutField>
            }
            {props.baseYears &&
                <LayoutField left={3} top={1} width={2} height={1}>
                    <SelectElement<number>
                        id='select-budget-year'
                        label='Jaarschijf'
                        optionItems={budgetYearOptions}
                        value={filter.budgetYear}
                        clearable={true}
                        searchable={false}
                        editorSettings={{
                            disabled: false,
                            restrictions: { required: false },
                            validationErrors: [],
                            onChange: (item) => setFilter(prev => ({ ...prev, budgetYear: item })),
                        }}
                    />
                </LayoutField>
            }
            <LayoutField left={5} top={1} width={2} height={1}>
                <TextElement id='search-journalCombination' value={filter.journalElementCombinationRK}
                    label='Combinatie'
                    editorSettings={{
                        disabled: false,
                        restrictions: { required: false },
                        validationErrors: [],
                        onChange: (value: string) => setFilter(prev => ({ ...prev, journalElementCombinationRK: value })),
                    }}
                />
            </LayoutField>
            <LayoutField left={7} top={1} width={2} height={1}>
                <TextElement id='search-measureMomentTag' value={filter.measureMomentTag}
                    label='Moment label'
                    editorSettings={{
                        disabled: false,
                        restrictions: { required: false },
                        validationErrors: [],
                        onChange: (value: string) => setFilter(prev => ({ ...prev, measureMomentTag: value })),
                    }}
                />
            </LayoutField>
        </LayoutForm>
    );
}