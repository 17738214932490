import React, { useCallback } from 'react';
import { withField } from '../field';
import { InputToggle } from '../primitives';

// tslint:disable:no-shadowed-variable

export const ToggleField = withField<{ textOn: string; textOff: string; checked: boolean; isSmall: boolean}>(
    ({
        onChange,
        value: { textOn, textOff, checked, isSmall },
        ...rest
    }) => (
            <InputToggle
                selected={checked}
                textOff={textOff}
                textOn={textOn}
                isSmall={isSmall}
                onChange={useCallback((checked: boolean) => onChange({ textOn, textOff, checked, isSmall }), [onChange, textOn, textOff, isSmall])}
                {...rest} />
        ));
