
export enum OperationKind {
    Undefined = 'Undefined',
    AddRoot = 'AddRoot',
    AddTo = 'AddTo',
    InsertAfter = 'InsertAfter',
    ReplaceWith = 'ReplaceWith',
    Patch = 'Patch'
}

export const OperationKinds = [OperationKind.Undefined, OperationKind.AddRoot, OperationKind.AddTo, OperationKind.InsertAfter, OperationKind.ReplaceWith, OperationKind.Patch];
