
/* localisation: messages for data-grid */

export const Dictionary = {
    "nl": {
        "dxDataGrid-emptyHeaderWithColumnChooserText": "Gebruik de {0} om kolommen zichtbaar te maken.",
        "dxDataGrid-emptyHeaderWithGroupPanelText": "Drag a column from the group panel here.",
        "dxDataGrid-emptyHeaderWithColumnChooserAndGroupPanelText": "Use {0} or drag a column from the group panel.",
        "dxDataGrid-emptyHeaderColumnChooserText": "kolomkiezer",
        "dxDataGrid-columnChooserTitle": "Kolom Kiezer",
        "dxDataGrid-columnChooserEmptyText": "Sleep hier een kolomkop om hem te verbergen",
        "dxDataGrid-groupContinuesMessage": "Gaat verder op de volgende pagina",
        "dxDataGrid-groupContinuedMessage": "Vervolg van de vorige pagina",
        "dxDataGrid-groupHeaderText": "Groepeer op deze kolom",
        "dxDataGrid-ungroupHeaderText": "Degroeperen",
        "dxDataGrid-ungroupAllText": "Alle Degroeperen",
        "dxDataGrid-editingEditRow": "Wijzigen",
        "dxDataGrid-editingSaveRowChanges": "Opslaan",
        "dxDataGrid-editingCancelRowChanges": "Annuleren",
        "dxDataGrid-editingDeleteRow": "Verwijderen",
        "dxDataGrid-editingUndeleteRow": "Ongedaan maken",
        "dxDataGrid-editingConfirmDeleteMessage": "Moet dit record verwijderd worden?",
        "dxDataGrid-validationCancelChanges": "Wijzigingen annuleren",
        "dxDataGrid-groupPanelEmptyText": "Sleep hier een kolomkop om er op te groeperen",
        "dxDataGrid-noDataText": "Geen gegevens",
        "dxDataGrid-searchPanelPlaceholder": "Zoeken...",
        "dxDataGrid-filterRowShowAllText": "(Alle)",
        "dxDataGrid-filterRowResetOperationText": "Reset",
        "dxDataGrid-filterRowOperationEquals": "Gelijk aan",
        "dxDataGrid-filterRowOperationNotEquals": "Niet gelijk aan",
        "dxDataGrid-filterRowOperationLess": "Kleiner dan",
        "dxDataGrid-filterRowOperationLessOrEquals": "Kleiner dan of gelijk aan",
        "dxDataGrid-filterRowOperationGreater": "Groter dan",
        "dxDataGrid-filterRowOperationGreaterOrEquals": "Groter dan of gelijk aan",
        "dxDataGrid-filterRowOperationStartsWith": "Begint met",
        "dxDataGrid-filterRowOperationContains": "Bevat",
        "dxDataGrid-filterRowOperationNotContains": "Bevat niet",
        "dxDataGrid-filterRowOperationEndsWith": "Eindigt met",
        "dxDataGrid-filterRowOperationBetween": "Tussen",
        "dxDataGrid-filterRowOperationBetweenStartText": "Begin",
        "dxDataGrid-filterRowOperationBetweenEndText": "Einde",
        "dxDataGrid-ariaSearchBox": "Search box",
        "dxDataGrid-applyFilterText": "Filter toepassen",
        "dxDataGrid-trueText": "waar",
        "dxDataGrid-falseText": "niet waar",
        "dxDataGrid-sortingAscendingText": "Sorteer Oplopend",
        "dxDataGrid-sortingDescendingText": "Sorteer Aflopend",
        "dxDataGrid-sortingClearText": "Sortering wissen",
        "dxDataGrid-editingSaveAllChanges": "Wijzigingen opslaan",
        "dxDataGrid-editingCancelAllChanges": "Wijzigingen annuleren",
        "dxDataGrid-editingAddRow": "Rij toevoegen",
        "dxDataGrid-summaryMin": "Min: {0}",
        "dxDataGrid-summaryMinOtherColumn": "Min van {1} is {0}",
        "dxDataGrid-summaryMax": "Max: {0}",
        "dxDataGrid-summaryMaxOtherColumn": "Max van {1} is {0}",
        "dxDataGrid-summaryAvg": "Gem: {0}",
        "dxDataGrid-summaryAvgOtherColumn": "Gem van {1} is {0}",
        "dxDataGrid-summarySum": "Som: {0}",
        "dxDataGrid-summarySumOtherColumn": "Som van {1} is {0}",
        "dxDataGrid-summaryCount": "Aantal: {0}",
        "dxDataGrid-columnFixingFix": "Blokkeren",
        "dxDataGrid-columnFixingUnfix": "Blokkering opheffen",
        "dxDataGrid-columnFixingLeftPosition": "Naar links",
        "dxDataGrid-columnFixingRightPosition": "Naar rechts",
        "dxDataGrid-exportTo": "Exporteren",
        "dxDataGrid-exportToExcel": "Exporteer naar Excel bestand",
        "dxDataGrid-exporting": "Exporteren...",
        "dxDataGrid-excelFormat": "Excel bestand",
        "dxDataGrid-selectedRows": "Geselecteerde rijen",
        "dxDataGrid-exportSelectedRows": "Exporteer geselecteerde rijen",
        "dxDataGrid-exportAll": "Exporteer alle gegevens",
        "dxDataGrid-headerFilterLabel": "Filter options",
        "dxDataGrid-headerFilterIndicatorLabel": "Show filter options for column '{0}'",
        "dxDataGrid-headerFilterEmptyValue": "(Leeg)",
        "dxDataGrid-headerFilterOK": "OK",
        "dxDataGrid-headerFilterCancel": "Annuleren",
        "dxDataGrid-ariaAdaptiveCollapse": "Hide additional data",
        "dxDataGrid-ariaAdaptiveExpand": "Display additional data",
        "dxDataGrid-ariaColumn": "Kolom",
        "dxDataGrid-ariaValue": "Waarde",
        "dxDataGrid-ariaFilterCell": "Filter cel",
        "dxDataGrid-ariaCollapse": "Inklappen",
        "dxDataGrid-ariaExpand": "Uitklappen",
        "dxDataGrid-ariaDataGrid": "Gegevenstabel",
        "dxDataGrid-ariaSearchInGrid": "Zoeken in gegevenstabel",
        "dxDataGrid-ariaSelectAll": "Selecteer alle",
        "dxDataGrid-ariaSelectRow": "Selecteer rij",
        "dxDataGrid-ariaToolbar": "Data grid toolbar",
        "dxDataGrid-filterBuilderPopupTitle": "Filterbouwer",
        "dxDataGrid-filterPanelCreateFilter": "Maak Filter",
        "dxDataGrid-filterPanelClearFilter": "Wissen",
        "dxDataGrid-filterPanelFilterEnabledHint": "Filter activeren",

        // "dxTreeList-ariaTreeList": "Boomstructuur",
        // "dxTreeList-ariaSearchInGrid": "Search in the tree list",
        // "dxTreeList-ariaToolbar": "Tree list toolbar",
        // "dxTreeList-editingAddRowToNode": "Toevoegen",

        "dxPager-infoText": "Pagina {0} van {1} ({2} items)",
        "dxPager-pagesCountText": "van",
        "dxPager-pageSize": "Items per page: {0}",
        "dxPager-pageSizesAllText": "Alle",
        "dxPager-page": "Page {0}",
        "dxPager-prevPage": "Previous Page",
        "dxPager-nextPage": "Next Page",
        "dxPager-ariaLabel": "Page Navigation",
        "dxPager-ariaPageSize": "Page size",
        "dxPager-ariaPageNumber": "Page number",
    }
};