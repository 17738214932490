import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { padStart } from 'lodash';
import ArrowBack from '@mui/icons-material/ArrowBack';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { DxTreeList, DxColumn, DxColumnChooser, DxColumnChooserSelection, DxPosition } from '@liasincontrol/ui-devextreme';
import { Finance } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { Bar, Button, ErrorOverlay, Heading2, PageTitle, Section, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import { StructureNodeModal } from './StructureNodeModal';

export const StructureDetails: React.FC = () => {
    const { id: structureId } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [error, setError] = useState<Domain.Shared.ErrorInfo>();
    const [data, setData] = useState<Domain.Finance.StructureNode[]>();
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [structureInfo, setStructureInfo] = useState<Domain.Finance.Structure>();
    const [journalElementsDialog, setJournalElementsDialog] = useState<{ visible: boolean, nodeId?: string }>({ visible: false });

    useEffect(() => {
        const getData = async () => {
            try {
                const response = await Finance.StructuresDataAccessor.getNodes(structureId);
                const structureResponse = await Finance.StructuresDataAccessor.getById(structureId);
                setStructureInfo(structureResponse.data);
                //remove parent id if the parentId is the current structure node (Otherwise the tree won't show anything because it cannot find that id)
                const data = response.data.map((item => item.parentRK === structureId ? { ...item, parentRK: null, } : item));
                setData(data);
            } catch (error) {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error, true));
            }
        };
        getData();
    }, [lastRefresh]);

    //Hide a column in the tree if none of the items has any value for that column
    const extraColumns = useMemo(() => {
        if (!data) return [];
        const cols: React.ReactNode[] = [];
        for (let i = 1; i <= 10; i++) {
            const key = 'custom' + padStart(i.toString(), 2, '0');
            const title = 'Extra' + padStart(i.toString(), 2, '0');
            const visible = data.some(item => item[key]);
            cols.push(<DxColumn key={key} caption={title} dataField={key} visible={visible} />)
        }
        return cols;
    }, [data]);

    const rowSelected = (e: any) => {
        const id = e.selectedRowKeys[0];
        const hasChildren = data.some(item => item.parentRK === id);
        if (!hasChildren) {
            setJournalElementsDialog(prev => ({
                ...prev,
                nodeId: id,
                visible: true,
            }));
        }
    };

    const closeModal = () => {
        setJournalElementsDialog(prev => ({ ...prev, visible: false, nodeId: null, }))
    };

    const onUnlinkedClick = () => {
        navigate(`/finance/admin/structures/${structureId}/unlinked`);
    };

    return (<Wrapper>
        <WrapperContent>
            <PageTitle>
                <Heading2>
                    <Button btnbase="iconbuttons" btntype="medium_transparentmain" icon={<ArrowBack />} onClick={() => navigate(-1)}></Button>
                    {structureInfo?.name}
                </Heading2>
            </PageTitle>
            <Bar look="toolbar">
                <Bar start>
                    <Button
                        id='btn-add-new-budgetjournalkind'
                        btnbase='textbuttons'
                        btntype='medium_icon'
                        icon={<LinkOffIcon />}
                        onClick={onUnlinkedClick}
                    >
                        Niet gekoppeld
                    </Button>
                </Bar>
            </Bar>
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                <Section look='white'>
                    <DxTreeList
                        className='selectable lias-treelist'
                        dataSource={data}
                        parentIdExpr="parentRK"
                        keyExpr="rk"
                        showRowLines={true}
                        showColumnLines={false}
                        selection={{ mode: 'single' }}
                        onSelectionChanged={rowSelected}
                        selectedRowKeys={journalElementsDialog.nodeId ? [journalElementsDialog.nodeId] : null}
                        scrolling={{ mode: 'standard' }}
                    >
                        <DxColumnChooser title="" enabled={true} mode='select' >
                            <DxPosition
                                my="right top"
                                at="left top"
                                of=".dx-treelist-column-chooser-button"
                                collision="fit" />
                            <DxColumnChooserSelection allowSelectAll={false} selectByClick={true} recursive={true} />
                        </DxColumnChooser>
                        <DxColumn
                            caption='Naam'
                            dataField='name'
                            width="15%"
                        />
                        <DxColumn
                            caption='Code'
                            dataField='code'
                            width="10%"
                        />
                        {extraColumns}
                    </DxTreeList>
                </Section>
                {journalElementsDialog.visible && <StructureNodeModal
                    structureId={structureId} nodeId={journalElementsDialog.nodeId} onClose={closeModal}
                />}
            </ErrorOverlay>
        </WrapperContent>
    </Wrapper>)
}