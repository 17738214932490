import React from 'react';
import { ContextComponents, ContextMenu, MenuAction } from '@liasincontrol/ui-basics';

type Props = {
    comment: any,
    onEdit?: (commentId: string) => void
    onDelete?: (commentId: string) => void
}

/**
 * Represents a UI component that renders the context menu used in the comments list.
 */
const CommentsContextMenu: React.FC<Props> = (props) => {
    return (
        <>
            <ContextComponents.Wrap>
                <ContextMenu look='default' icon={<ContextComponents.ContextMenuIcon />} contextItem={props.comment.name} menuAction={MenuAction.Context} position='left' highlightPopup={false}>
                    <ContextComponents.ListWrap withPadding>
                        {props.onEdit && <ContextComponents.LiBtn id={`btn-edit-comment-${props.comment.id}`} key={`edit-${props.comment.id}`} onClick={() => props.onEdit(props.comment.id)} aria-label={`Bewerk ${props.comment.name}`}>
                            <ContextComponents.ListActionButton>Bewerken</ContextComponents.ListActionButton>
                        </ContextComponents.LiBtn>}
                        {props.onDelete && <ContextComponents.LiBtn id={`btn-delete-comment-${props.comment.id}`} key={`delete-${props.comment.id}`} onClick={() => props.onDelete(props.comment.id)} aria-label={`Verwijderen ${props.comment.name}`}>
                            <ContextComponents.ListActionButton>Verwijderen</ContextComponents.ListActionButton>
                        </ContextComponents.LiBtn>}
                    </ContextComponents.ListWrap>
                </ContextMenu>
            </ContextComponents.Wrap>
        </>
    );
};

export { CommentsContextMenu };