import React from 'react';
import { SelectElement } from '@liasincontrol/ui-elements';
import { ValidationErrorData } from '@liasincontrol/core-service';
import { PieChartFormatEditorWrapper } from './index.styled';
import { IDataItemProps } from '@liasincontrol/ui-basics';

type FormatType = {
    type: string,
    precision: number,
};

type Props = {
    key: string,
    myKey?: string,
    id: string,
    disabled: boolean,
    required: boolean,
    validationErrors: ValidationErrorData[],
    value?: FormatType,
    onValueChange?: (value: FormatType) => void,
};

const formatTypeOptions = [
    {
        value: '',
        label: 'Geen',
    },
    {
        value: 'currency',
        label: 'Valuta',
    },
    {
        value: 'billions',
        label: 'Miljarden',
    },
    {
        value: 'millions',
        label: 'Miljoenen',
    },
    {
        value: 'thousands',
        label: 'Duizenden',
    },
    {
        value: 'percent',
        label: 'Percentage',
    },
];

const decimalOptions = [0, 1, 2, 3, 4];

const defaultFormat: FormatType = {
    type: '',
    precision: 0,
}

const PieChartValueFormatEditor: React.FC<Props> = (props) => {
    return (
        <PieChartFormatEditorWrapper>
            <div>
                <SelectElement<IDataItemProps<string>>
                    displayExpr='label'
                    key={`format-type-${props.myKey}`}
                    id={`format-type-${props.id}`}
                    label={`Type`}
                    searchable={false}
                    clearable={false}
                    optionItems={formatTypeOptions}
                    value={props.value?.type
                        ? formatTypeOptions.find(ft => ft.value === props.value.type)
                        : formatTypeOptions[0]
                    }
                    editorSettings={{
                        disabled: props.disabled,
                        restrictions: { required: props.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => {
                            props.onValueChange({
                                ...props.value ?? defaultFormat,
                                type: item.value,
                            });
                        },
                    }}         
                />
            </div>
            <div>
                <SelectElement<number>
                    key={`format-decimals-${props.myKey}`}
                    id={`format-decimals-${props.id}`}
                    label={`Decimalen`}
                    searchable={false}
                    clearable={false}
                    optionItems={decimalOptions}
                    value={props.value?.precision ?? defaultFormat.precision}
                    editorSettings={{
                        disabled: props.disabled,
                        restrictions: { required: props.required },
                        validationErrors: props.validationErrors,
                        onChange: (item) => {
                            props.onValueChange({
                                ...props.value ?? defaultFormat,
                                precision: item,
                            });
                        }
                    }}
                />
            </div>
        </PieChartFormatEditorWrapper>
    )
}

export { PieChartValueFormatEditor, FormatType };
