import { createReducer } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { ModulesActionCreator, TenantActionCreator } from '../../../../actions/creators';

/**
 * Represents the modules reducer.
 */
const reducer = createReducer<{
    [service: string]: {
        [moduleSystemId: string]: Domain.Shared.Module
    }
}>({}, (builder) => {
    return (
        builder.addCase(ModulesActionCreator.set.fulfilled, (state, action) => {
            state[action.payload.source] = action.payload.data.value.reduce(
                (collection, item) => ({ ...collection, [item.systemId]: item }),
                {}
            );

            return state;
        }),
        builder.addCase(ModulesActionCreator.set.pending, (state, action) => {
            // Loading in progress;
        }),
        builder.addCase(ModulesActionCreator.set.rejected, (state, action) => {
            state = {};
        }),
        builder.addCase(TenantActionCreator.initTenant, (state, action) => {
            state = {};
            return state;
        })
    );
});

export default reducer;
