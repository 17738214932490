import styled, { createGlobalStyle } from 'styled-components';

export const ResetStylesheet = createGlobalStyle`
        html {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            height: 100%;
        }
        body {
            height: 100%;
        }
        *, *::before, *::after{
            box-sizing: border-box;
        }
    `;

export const ResetZIndex = styled.div`
    position: relative;
    z-index: 0;
`;
