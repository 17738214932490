import React, { useCallback, useEffect, useRef } from 'react';
import FilterBuilder, { CustomOperation, ICustomOperationProps } from 'devextreme-react/filter-builder';
import { dxElement } from 'devextreme/core/element';
import dxFilterBuilder from 'devextreme/ui/filter_builder';
import { SelectBox } from 'devextreme-react';
import { CustomItemCreatingEvent, ValueChangedEvent } from 'devextreme/ui/select_box';
import { IDataItemProps } from '@liasincontrol/ui-basics';

export type FilterValueType = string | Array<any> | Function;

export type LsFilterBuilderField = {
    caption?: string,
    dataField?: string,
    dataType?: 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime',
    filterOperations?: Array<'=' | '<>' | '<' | '<=' | '>' | '>=' | 'contains' | 'endswith' | 'isblank' | 'isnotblank' | 'notcontains' | 'startswith' | 'between' | string>,
};

export type LsFilterBuilderOnChangeHandler = {
    component?: dxFilterBuilder,
    element?: dxElement,
    model?: any,
    value?: FilterValueType,
    previousValue?: FilterValueType,
};
export interface ILsFilterBuilderProps {
    /**
     * Defines the id of the field builder element.
     */
    readonly id: string;
    /**
     * Available columns for sort
     */
    readonly fields: LsFilterBuilderField[] | undefined;
    /**
     * the current filter value
     */
    readonly value: FilterValueType;
    /**
     * the current filter value
     */
    readonly disabled?: boolean;
    /**
     * the current locale(localization) identifier.
     */
    readonly locale?: string;
    /**
     * array of object values that can be used by the custom filter. 
     */
    readonly variables?: IDataItemProps<string>[];
    /**
     * on filter value change event
     */
    readonly onValueChanged: (object: LsFilterBuilderOnChangeHandler) => void;
}

export const LsFilterBuilder: React.FC<ILsFilterBuilderProps> = (props) => {
    const builder = useRef(null);

    useEffect(() => {
        try {
            builder.current.instance().option('value', props.value);
        } catch (error) {
            // TODO: as mentioned in item #10937 this behavior will be replaced by another PBI
            builder.current.instance().option('value', []);
        }
    }, [props.value]);

    const calculateFilterExpression: ICustomOperationProps['calculateFilterExpression'] = useCallback((filterValue, field) => {
        return (
            filterValue
            && filterValue.length
            && Array.prototype.concat
                .apply(
                    [],
                    filterValue.map((i) => [[field.dataField, '=', i]]),
                )
                .slice(0, -1)
        )
    }, []);

    return (
        <FilterBuilder
            ref={builder}
            id={props.id}
            fields={props.fields}
            onValueChanged={props.onValueChanged}
            disabled={props.disabled}
        >
            <CustomOperation
                name="="
                caption="Gelijk aan"
                dataTypes={["string"]}
                icon="filter"
                calculateFilterExpression={calculateFilterExpression}
                editorComponent={({ data }) =>
                    <EditorComponent
                        {...data}
                        dataSource={props.variables}
                    />
                }
            />
        </FilterBuilder>
    );
};

export const EditorComponent = (props) => {
    const onValueChanged = useCallback((e: ValueChangedEvent) => {
        props.setValue(e.value);
    }, [props.setValue]);

    const onCustomItemCreating = useCallback((e: CustomItemCreatingEvent) => {
        if (e.text) {
            e.customItem = { label: e.text, value: e.text };
            props.setValue(e.text)
        } else {
            e.customItem = {};
        }
    }, [props.setValue])

    return (
        <SelectBox
            acceptCustomValue={true}
            displayExpr="label"
            valueExpr="value"
            defaultValue={props.value}
            items={props.dataSource}
            onValueChanged={onValueChanged}
            onCustomItemCreating={onCustomItemCreating}
        />
    );
};
