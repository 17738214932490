import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { State, ActionSource, AttachmentsActionCreator, ModulesActionCreator, ElementDefinitionsActionCreator, UsersActionCreator, AjaxRequestStatus } from '@liasincontrol/redux-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { ErrorOverlay, ResetZIndex } from '@liasincontrol/ui-basics';
import { SystemElementDefinitions, SystemModuleDefinitions } from '@liasincontrol/domain';
import * as Domain from '@liasincontrol/domain';
import { WebsiteSection } from './PublishSection/WebsiteSection';
import { DocumentSection } from './PublishSection/DocumentSection';
import { usePublicationSettings } from '../../../../../helpers/PublicationContext';
import Helper from '../../../../_shared/PublicationItem/PublicationInformation/index.helper';
import { ErrorLogDialog } from './ErrorLogDialog/ErrorLogDialog';

export type ErrorLogDialogState = {
    errors?: Domain.Publisher.BuildError[]
};

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the publish page of a publication.
 */
const Index: React.FC<Props> = (props) => {
    const { id } = useParams<{ id: string }>();

    const versionElementDefinition = useMemo(() => Object.values(props.elementDefinitions.items).find((definition) => definition.systemId === SystemElementDefinitions.Pub.Version), [props.elementDefinitions]);
    const [users, setUsers] = useState<Domain.Shared.User[]>();
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const publication = usePublicationSettings();
    const [buildErrors, setBuildErrors] = useState<Domain.Publisher.BuildError[]>();

    useEffect(() => {
        if (!publication) return;
        const elemDef = Object.values(props.elementDefinitions.items).find(ed => ed.systemId === Domain.SystemElementDefinitions.Pub.Publication);
        const isClosed = Helper.getPublicationIsClosed(publication, elemDef);
        if (isClosed) {
            setError({ message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed] });
        }
    }, [props.elementDefinitions, publication]);

    useEffect(() => {
        if (props.users.status !== AjaxRequestStatus.Done)
            return;
        setUsers(props.users.items);
    }, [props.users]);

    if (props.users.status === AjaxRequestStatus.NotSet) {
        props.fetchUsers();
        return null;
    }

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Publisher]);
        return null;
    }

    return (
        <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
            <ResetZIndex>
                <WebsiteSection
                    publicationId={id}
                    userIdentity={props.userIdentity}
                    versionElementDefinition={versionElementDefinition}
                    users={users}
                    setErrors={setBuildErrors}
                />
                <DocumentSection
                    publicationId={id}
                    userIdentity={props.userIdentity}
                    attachments={props.attachments}
                    setAttachment={props.setAttachment}
                    setErrors={setBuildErrors}
                />
                <ErrorLogDialog isOpened={!!buildErrors?.length} errors={buildErrors} onClose={() => { setBuildErrors(null) }} />
            </ResetZIndex>
        </ErrorOverlay>
    );
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        attachments: state.attachments,
        modules: state.modules[ActionSource.Publication],
        elementDefinitions: state.elementdefinitions[ActionSource.Publication],
        users: state.users,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAttachment: (attachmentId: string, attachment: File) => {
            dispatch(AttachmentsActionCreator.set({ source: ActionSource.Publication, data: { attachmentId, attachment } }));
        },
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Publication, data: { moduleId: module?.id } }));
        },
        fetchUsers: () => {
            dispatch(UsersActionCreator.set());
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
