import styled from 'styled-components';
import { palette } from '@liasincontrol/ui-basics';

const ProgressBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ProgressBarInner = styled.div<{ fillWidth: number; fillColor?: string; backgroundColor?: string }>`
  height: 8px;
  width: 100%;
  background:${(props) => `linear-gradient(90deg, ${props.fillColor ?? palette.green} ${props.fillWidth}%, ${props.backgroundColor ?? palette.grey5} 0px);`} 
  border: 1px solid ${(props) => props.fillColor ?? palette.green};
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

const Label = styled.label`
  font-weight: 600;
  padding-right: 12px;
  padding-left: 12px;
`;

const styledComponents = { ProgressBar, ProgressBarInner, Label };
export default styledComponents;
