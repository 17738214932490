import React from 'react';
import { Button } from 'devextreme-react/button';
import { Bar } from '@liasincontrol/ui-basics';

export enum Views {
    Chart = "Chart",
    Table = "Table"
}

type Props = {
    view: Views,
    onSwitch: (value: Views) => void,
}

/**
 * Render a charts swithing panel component.
 */
export const ChartPanel = (props: Props) => {
    return (<Bar look='toolbar'>
        <Bar end>
            <Button
                icon="chart"
                id="button-chart"
                elementAttr={{
                    'aria-label': 'Weergeven als diagram',
                }}
                className='lias-toolbar-button dx-shape-standard'
                type={props.view === Views.Chart ? 'primary' : 'normal'}
                onClick={() => { props.onSwitch(Views.Chart); }} />
            <Button
                icon="detailslayout"
                id="button-table"
                elementAttr={{
                    'aria-label': 'Weergeven als tabel',
                }}
                className='lias-toolbar-button dx-shape-standard'
                type={props.view === Views.Table ? 'primary' : 'normal'}
                onClick={() => { props.onSwitch(Views.Table); }} />
        </Bar>
    </Bar>);
}
