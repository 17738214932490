import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../DataAccessHostUtils';
import { oDataResponse } from '../oData';
import buildQuery from 'odata-query';

const MaxTop = 100;

/**
 * Represents the measure moments data accesor.
 */
export class MeasureMomentsDataAccess {

    /**
     * Creates a measure moment.
     * @param measureMoment Element which has the measure moment field definitions
     */
    public static createMeasureMoment = async (measureMoment: Domain.Shared.MeasureMoment): Promise<AxiosResponse<Domain.Shared.MeasureMoment>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<Domain.Shared.MeasureMoment>(`/api/measuremoment`, measureMoment, config);
    };

    /**
      * Gets a list with all the measure moments that are set.
      */
    public static getAll = async (allowPaging = true): Promise<Domain.Shared.MeasureMoment[]> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        const measureMoments: Domain.Shared.MeasureMoment[] = [];

        const response = await MeasureMomentsDataAccess.getMeasureMomentsInner(0, MaxTop, measureMoments, config, true);
        const count = response.data['@count'];

        if (count > MaxTop && allowPaging) {
            for (let skip = MaxTop; skip < count; skip += MaxTop) {
                await MeasureMomentsDataAccess.getMeasureMomentsInner(skip, MaxTop, measureMoments, config, false);
            }
        }

        return measureMoments;
    };

    /**
     * Gets a measure moment.
     */
    public static get = async (id = ''): Promise<AxiosResponse<Domain.Shared.MeasureMoment>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Shared.MeasureMoment>(`/api/measuremoment/${id}`, config);
    };

    /**
     * Updates a measure moment.
     * @param measureMomentId The unique identifier of the measure moment
     * @param model The element which holds the changes to update the measure moment
     */
    public static updateMeasureMoment = async (measureMomentId: string, model: Domain.Shared.MeasureMoment): Promise<AxiosResponse<Domain.Shared.MeasureMoment>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.put<Domain.Shared.MeasureMoment>(`/api/measuremoment/${measureMomentId}`, model, config);
    };

    /**
     * Reorders the measure moments.
     * @param measureMomentId The unique identifier of the measure moment
     * @param moveBeforeMeasureMomentId The guid of the moment where you want it placed before.
     */
    public static updateMeasureMomentOrder = async (measureMomentId: string, moveBeforeMeasureMomentId: string): Promise<AxiosResponse<Domain.Shared.MeasureMoment>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.put<Domain.Shared.MeasureMoment>(`/api/measuremoment/order`, { measureMomentId: measureMomentId, moveBeforeMeasureMomentId: moveBeforeMeasureMomentId }, config);
    };

    /**
     * Sets the status of a measure moment.
     * @param measureMomentId The unique identifier of the measure moment
     * @param status The enum value of the measure moment's state (closed or open)
     * @param measureMomentToCloneId The unique identifier of a measure moment to clone the performance tree from
     * @param studioHierarchyDefinitionsToClone A dictionary with measure moments and the selected studio hierarchy definition id's to clone.
     */
    public static setMeasureMomentStatus = async (measureMomentId: string, status: number, measureMomentToCloneId: string, studioHierarchyDefinitionsToClone?: Record<string, string[]>): Promise<AxiosResponse<Domain.Shared.MeasureMoment>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.put<Domain.Shared.MeasureMoment>(`/api/measuremoment/status`, { measureMomentId: measureMomentId, status: status, measureMomentToCloneId: measureMomentToCloneId, studioHierarchyDefinitionsToClone: studioHierarchyDefinitionsToClone }, config);
    };

    /**
     * Sets a measure moment's status as deleted.
     * @param measureMomentId The unique identifier of the measure moment
     */
    public static deleteMeasureMoment = async (measureMomentId: string): Promise<AxiosResponse<Domain.Shared.MeasureMoment>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.delete<Domain.Shared.MeasureMoment>(`/api/measuremoment/${measureMomentId}`, config);
    };

    private static async getMeasureMomentsInner(skip: number, take: number, measureMoments: Domain.Shared.MeasureMoment[], config, count = false) {
        const query = buildQuery({
            top: take,
            skip: skip,
            count: count,
        });
        const response = await HttpClient.get<oDataResponse<Domain.Shared.MeasureMoment[]>>(`/api/measuremoment${query}`, config);
        measureMoments.push(...response.data.value);
        return response;
    }
}

export default MeasureMomentsDataAccess;
