
import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { oDataResponse } from '../../Shared/oData';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents the datastores data accessor.
 */
export class DataStores {
    /**
     * Get the list of datastores.
     * @param query Defines the query that can be used for filtering the data on the server side.
     */
    public static getDataStores = async (query = ''): Promise<AxiosResponse<oDataResponse<Domain.Publisher.DataStoreListItem[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<oDataResponse<Domain.Publisher.DataStoreListItem[]>>(`/api/datastore${query}`, config);
    };

    /**
     * Gets element definition of the datastore element.
     */
    public static getDataStoreElementDefinitions = async (): Promise<AxiosResponse<Domain.Shared.ElementDefinition>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Shared.ElementDefinition>(`/api/meta/datastore/elementdefinition`, config);
    };

    /**
     * Get a specific dataStore related to a publication based on it's unique identifier.
     * 
     * @param datastoreId Defines the unique identifier of the dataStore.
     */
    public static getDataStore = async (datastoreId: string): Promise<AxiosResponse<Domain.Publisher.Element>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.Element>(`/api/datastore/${datastoreId}`, config);
    };

    /**
     * Gets the datastore Data platform schema.
     * 
     * @param datastoreId Defines the unique identifier of the dataStore.
     */
    public static getDataPlatformSchema = async (datastoreId: string): Promise<AxiosResponse<Domain.Publisher.DataPlatformSchemaField[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<Domain.Publisher.DataPlatformSchemaField[]>(`/api/datastore/${datastoreId}/dataplatformcolumns`, config);
    };

    /**
     * Gets the datastore Data platform schema.
     * 
     * @param datastoreId Defines the unique identifier of the dataStore.
     */
    public static getDataPlatformViews = async (): Promise<AxiosResponse<Domain.Publisher.DataPlatformView[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<Domain.Publisher.DataPlatformView[]>(`/api/datastore/dataplatformviews`, config);
    };

    /**
     * Updates a specific dataStore.
     * 
     * @param datastoreId Defines the unique identifier of the dataStore.
     * @param attachments Array with changed attachments (that have been already uploaded).
     * @param fieldValues Field values of the dataStore object being updated.
     */
    public static saveDataStore = async (datastoreId: string, attachments: Domain.Shared.Attachment[], fieldValues: Record<string, string>): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`/api/datastore/${datastoreId}`, {
            attachments: attachments,
            complexFields: [],
            fields: fieldValues
        }, config);
    };

    /**
     * Delete a specific dataStore.
     * 
     * @param dataStoreId Defines the unique identifier of the dataStore.
     */
    public static deleteDataStore = async (dataStoreId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.delete<void>(`/api/datastore/${dataStoreId}`, config);
    };

    /**
     * Creates a new  dataStore.
     * 
     * @param elementDefinition DataStore element definition.
     * @param attachments Array with changed attachments (that have been already uploaded).
     * @param fieldValues Field values of the dataStore object being updated.
     */
    public static createDataStore = async (elementDefinition: Domain.Shared.ElementDefinition, attachments: Domain.Shared.Attachment[], fieldValues: Record<string, string>): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>(`/api/datastore/`, {
            elementDefinitionId: elementDefinition.id,
            attachments: attachments,
            complexFields: [],
            fields: fieldValues,
        }, config);
    };
}

export default DataStores;