import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';
import { oDataResponse } from '../../Shared/oData';

/**
 * Represents a data accessor of budget journal workflow.
 */
export class BudgetJournalWorkflowDataAccessor {
    /**
     * Creates a budget Journal item.
     * @param budgetJournalId Defines the budget Journal that has to be created.
     */
    public static start = async (budgetJournalId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<void>(`/api/finance/budgetjournal/${budgetJournalId}/workflow/start`, {}, config);
    };

    /**
     * Approves a budget journal task
     * @param budgetJournalId id of the budget journal
     * @param taskId id of the task
     * @param confirmedMoveToNextStep A boolean that indicates the next step movement
     */
    public static approve = async (budgetJournalId: string, taskId: string, confirmedMoveToNextStep = false): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<string>(`/api/finance/budgetjournal/${budgetJournalId}/workflow/task/${taskId}/complete?confirmedMoveToNextStep=${confirmedMoveToNextStep}`, null, config);
    };

    /**
     * Reopens an approved budget journal task
     * @param budgetJournalId id of the budget journal
     * @param taskId id of the task
     */
    public static reopen = async (budgetJournalId: string, taskId: string): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.put<string>(`/api/finance/budgetjournal/${budgetJournalId}/workflow/task/${taskId}/reopen`, null, config);
    };

    /**
     * Reject a budget journal task
     * @param budgetJournalId id of the budget journal
     * @param taskId id of the task
     * @param confirmedReject A boolean that indicates the prev step movement
     */
    public static reject = async (budgetJournalId: string, taskId: string, confirmedReject = true): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<string>(`/api/finance/budgetjournal/${budgetJournalId}/workflow/task/${taskId}/reject?confirmedReject=${confirmedReject}`, null, config);
    };

    /**
     * Gets the list of states for this BudgetJournalKind. These are the states that existed when the kind was linked
     * @param budgetJournalKindId id of the budget journal
     */
    public static getWorkflowSteps = async (budgetJournalKindId: string): Promise<AxiosResponse<oDataResponse<Domain.Finance.BudgetJournalKindWorkflowStep[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<oDataResponse<Domain.Finance.BudgetJournalKindWorkflowStep[]>>(`/api/finance/budgetjournalkind/${budgetJournalKindId}/workflow/states`, config);
    };

    /**
     * Move the workflow back to the preceding step or forward to the next step.
     * @param budgetJournalId id of the budget journal
     * @param direction Direction to move, back or forward
     */
         public static moveBudgetJournalForwardOrBackward = async (budgetJournalId: string, confirmedStateChange: boolean, direction: Domain.Finance.MoveBudgetJournalDirection): Promise<AxiosResponse<string>> => {
            const config = await DataAccessHostUtils.getPubRequestConfig();
            const data = {
                direction: direction,
            }
    
            return HttpClient.put<string>(`/api/finance/budgetjournal/${budgetJournalId}/workflow/move?confirmedStateChange=${confirmedStateChange}`, data, config);
        };
}
