import styled,  { keyframes } from 'styled-components';
import { cssBreakpoint } from '../responsive/css';
import { px } from '../style';
import { palette } from '../styleguide';
import { Internals } from '../button';
import { cssSmallText, cssElementLabel } from '../primitives/typography';
import { Look } from '../side-navigation/index.styled';

const Container = styled.nav<{ collapsed: boolean, look: Look }>`
    height: 100%;
    min-width: 0;
    background-color: ${({ look }) => (look === Look.publisherAdmin ? palette.darkBlue : palette.white)};
    padding: ${px(24)} ${px(32)};
    width: ${({ collapsed }) => (collapsed ? px(48) : px(280))};
    transition: width 0.3s ease;
    ${cssBreakpoint('s', 'xs')`
        padding: ${px(6)};
    `}
`;

//menugroup
const fadeIn = keyframes`
    0% {transform: translateY(${px(5)}); opacity: 0;} 
    50%{ transform: translateY(${px(5)}); opacity: 0; } 
    100% { transform: translateY(0%); opacity: 1; }
`;

const GroupLabel = styled.div`
    ${cssSmallText};
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: ${px(8)};
    color: ${palette.melrose};
`;

const GroupListbox = styled.div`
    display: flex;
    flex-flow: column;
    margin-bottom: ${px(12)};
    animation: ${fadeIn} 0.2s ease;
`;

// menuitem
const ButtonContainer = styled.span<{ collapsed: boolean; active: boolean; look: Look }>`
    text-decoration: none;
    position: relative;
    ${({ collapsed, look }) =>
        look === Look.publisherAdmin
            ? collapsed
                ? Internals.StyleGuideButtons.iconbuttons.medium_admin.container
                : Internals.StyleGuideButtons.bluebackgroundbuttons.medium_icon.container
        : look === Look.publisherWriter
            ? collapsed
                ? Internals.StyleGuideButtons.iconbuttons.medium_transparentalt.container
                : Internals.StyleGuideButtons.textbuttons.alt_icon.container
        : ``
        }
    overflow: hidden;
    text-overflow: ellipsis;
    > span {
        background-color: ${({ active, look }) => active && (look === Look.publisherAdmin ? `#3239B0` : `#E339FF`)};
        width: 100%;
    }
`;

const ButtonContent = styled.span<{ collapsed: boolean }>`
    padding: 0;
    ${Internals.cssInternalButtonContent}
    grid-template-columns: ${({ collapsed }) => (collapsed ? null : `min-content auto`)};
    justify-items: ${({ collapsed }) => (collapsed ? null : `start`)};
`;

const ButtonText = styled.span`
    ${cssElementLabel}
    white-space: nowrap;
`;

const HiddenText = styled.p`
    display: none;
`;

export { Look };

const styledComponents = { Container, GroupLabel, GroupListbox, ButtonContainer, ButtonContent, ButtonText, HiddenText };
export default styledComponents;
