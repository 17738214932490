import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { BasicValidator, FormHelper, FormMode, TextValidator, ValidationErrorData, ValueType } from '@liasincontrol/core-service';
import { SelectElement, TextElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { ListEditor } from '@liasincontrol/ui-elements';
import { Button, LayoutField, LayoutForm, ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';
import { Finance } from '@liasincontrol/data-service';
import { IndicatorSize, LoadIndicator } from '@liasincontrol/ui-devextreme';
import { JournalElementsResults } from '../JournalElementsResults';
import Styled from './index.styled';

type Props = {
    budgetElementGroup?: Domain.Finance.BudgetElementGroup,
    baseYears: number[];
    formMode: FormMode;
    isBusy: boolean;
    availableTags?: Domain.Finance.BudgetElementGroupTag[];
    onSave: (budgetJournalKind: Domain.Finance.BudgetElementGroup, formMode?: FormMode) => void,
    onCancel: () => void,
    onError: (err: any) => void,
};

/**
 * Represents a UI component that renders the modal for managing a budget element group item.
 */
export const BudgetElementGroupForm: React.FC<Props> = (props) => {
    const [form, setForm] = useState<{ formData: Domain.Finance.BudgetElementGroup, isTouched: boolean }>(initForm(props.budgetElementGroup));
    const [validationErrors, setValidationErrors] = useState<{
        errors: Record<string, ValidationErrorData[]>,
        hasErrors: boolean,
    }>({ errors: {}, hasErrors: false });

    const [journalElementDialogOpen, setJournalElementDialogOpen] = useState<boolean>(false);
    const [journalElementKinds, setJournalElementKinds] = useState<Domain.Finance.JournalElementKind[]>([]);

    const onChange = (value: string | number | string[], fieldName: string) => {
        const data: Domain.Finance.BudgetElementGroup = { ...form.formData };
        if (data[fieldName] === value) {
            return;
        }

        data[fieldName] = value;
        setForm({ formData: data, isTouched: true });

        const temporaryValidationError = _.cloneDeep(validationErrors);
        const validationResult = validate(data, validationErrors.errors);
        temporaryValidationError.errors[fieldName] = validationResult.errors[fieldName];
        temporaryValidationError.hasErrors = validationResult.hasErrors;
        setValidationErrors(temporaryValidationError);
    };

    useEffect(() => {
        Finance.JournalElementKindDataAccessor.getAll().then((journalElementKindsResult) => {
            setJournalElementKinds(journalElementKindsResult.data);
        }).catch((exception) => {
            props.onError(exception);
        });
    }, []);

    const footerElement = (
        <ModalDialogFooter
            leftButtonText='Annuleren'
            onLeftButtonClick={props.onCancel}
            rightButtonText='Opslaan'
            onRightButtonClick={() => props.onSave(form.formData, props.formMode)}
            rightButtonDisabled={props.isBusy || props.formMode === FormMode.View || validationErrors.hasErrors || !form.isTouched}
        />
    );

    const getTitle = (mode: FormMode) => {
        switch (mode) {
            case FormMode.AddNew:
                return 'Nieuwe Budgetelementgroep';
            case FormMode.Edit:
                return 'Budgetelementgroep bewerken';
            case FormMode.Clone:
                return 'Budgetelementgroep kopiëren';
            default:
                return 'Budgetelementgroep';
        }
    };

    const onSelectionChanged = ({ addedItems, removedItems }) => {
        const selected = (form?.formData?.selectedTags || []).concat(addedItems.map(i => i.tag))?.filter(item => !!!removedItems.find(a => a.tag === item));
        onChange(_.uniq(selected), 'selectedTags')
    };

    const renderItem = (item: Domain.Finance.BudgetElementGroupTag) => {
        return (
            <Styled.TagListItem>
                {item.tag}
                {!!item.groupCount &&
                    <div className='group-count'>
                        ({item.groupCount})
                    </div>
                }
            </Styled.TagListItem>
        )
    };

    return (
        <ModalDialog
            modalDialogStyle="custom"
            settings={{
                look: "interactive",
                title: getTitle(props.formMode),
                footer: footerElement,
            }}
        >
            <LayoutForm>
                <LayoutField left={1} top={2} width={6} height={1}>
                    <TextElement
                        id='name-field'
                        label='Naam'
                        editorSettings={{
                            disabled: props.formMode === FormMode.View,
                            validationErrors: validationErrors.errors['name'],
                            restrictions: { required: true, minLength: 2, maxLength: 100 },
                            onChange: (value: string) => onChange(value, 'name'),
                        }}
                        value={form?.formData?.name}
                    />
                </LayoutField>
                <LayoutField left={1} top={3} width={6} height={1}>
                    <TextElement
                        id='code-field'
                        label='Code'
                        editorSettings={{
                            disabled: props.formMode === FormMode.View,
                            validationErrors: validationErrors.errors['code'],
                            restrictions: { required: true, minLength: 2, maxLength: 50 },
                            onChange: (value: string) => onChange(value, 'code'),
                        }}
                        value={form?.formData?.code}
                    />
                </LayoutField>
                <LayoutField left={1} top={4} width={6} height={1}>
                    <SelectElement<number>
                        id='select-base-year'
                        label='Basisjaar'
                        optionItems={props.baseYears}
                        value={form.formData.baseYear}
                        clearable={false}
                        searchable={false}
                        editorSettings={{
                            disabled: props.formMode === FormMode.View || props.formMode === FormMode.Edit,
                            restrictions: { required: true },
                            validationErrors: validationErrors.errors['baseYear'],
                            onChange: (item) => onChange(item, 'baseYear'),
                        }}
                    />
                </LayoutField>
                {props.formMode === FormMode.Edit &&
                    <LayoutField left={1} top={5} width={6} height={2}>
                        {props.availableTags !== undefined
                            ? <ListEditor
                                id='tag-field'
                                key='tag-field'
                                label='Groeplabel'
                                value={props.availableTags.filter((t) => form?.formData?.selectedTags.some((st) => st === t.tag)) || []}
                                displayExpr={(item) => item.tag}
                                placeholder='Kies...'
                                items={props.availableTags}
                                itemKey='tag'
                                enableSearch={true}
                                isDisabled={false}
                                editorSettings={{
                                    disabled: false,
                                    restrictions: { required: false },
                                    validationErrors: [],
                                    onChange: (args) => onSelectionChanged(args),
                                }}
                                customHeaderComponent={(<Button
                                    id='btn-custom'
                                    disabled={!form?.formData?.selectedTags?.length}
                                    btnbase='textbuttons'
                                    btntype='medium_icon'
                                    onClick={() => setJournalElementDialogOpen(true)}
                                >
                                    Bekijk resultaat
                                </Button>)}
                                itemTemplate={renderItem}
                                searchExpr='tag'
                            />
                            : <LoadIndicator variant={IndicatorSize.extralarge} />
                        }
                    </LayoutField>
                }
                {journalElementDialogOpen && <JournalElementsResults
                    journalElementKinds={journalElementKinds}
                    filter={{ baseYear: form.formData?.baseYear, budgetElementGroupTag: form?.formData?.selectedTags || [] }}
                    onCancel={() => setJournalElementDialogOpen(false)}
                    onError={props.onError} />}
            </LayoutForm>
        </ModalDialog>
    );
};

const initForm = (form: Domain.Finance.BudgetElementGroup) => {
    return { formData: form || new Domain.Finance.BudgetElementGroup(), isTouched: false };
};

const validate = (form: Domain.Finance.BudgetElementGroup, errors: Record<string, ValidationErrorData[]>) => {
    const dictionary: Record<string, ValueType> = Object.keys(form).reduce((a, x) => ({ ...a, [x]: form[x] }), {});
    return FormHelper.validateForm(validators, dictionary, errors);
};

const validators = {
    'name': new TextValidator({ required: true, stringMaxLength: 100, stringType: Domain.Shared.StringType.SingleLine }),
    'baseYear': new BasicValidator({ required: true }),
    'code': new TextValidator({ required: true, requiredText: 'Het veld code is vereist.', stringMaxLength: 50, stringType: Domain.Shared.StringType.SingleLine }),
};
