import styled from 'styled-components';
import { ColorUtils } from '@liasincontrol/core-service';
import { palette } from '@liasincontrol/ui-basics';
import Styled, { lineHeightCoefficient } from "../../SharedControlElements/index.styled"

const DataTableWrapper = styled(Styled.GridWrapper) <{
    primaryColor: string,
    primaryTextColor: string,
    textColor: string,
    textFontSize?: string,
    h1FontColor: string,
    h1FontSize: number,
    h2FontColor: string,
    h2FontSize: number,
    h3FontColor: string,
    h3FontSize: number,
    editMode: boolean
}>`
   
   .dx-datagrid {
        color: inherit;
        font-size: inherit;
        line-height: inherit;    
        
        .dx-row > td, .dx-datagrid-group-panel {
            font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
            line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px!important` : 'inherit')};
        }

        .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
            font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
            line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px!important` : 'inherit')};
            color:inherit;

            .dx-sort,  .dx-sort-indicator, :hover .dx-datagrid-text-content {
                color:inherit;
            } 
        }
        
        .dx-command-expand.dx-datagrid-group-space {
            padding-top: ${(p) => (p.textFontSize ? `${+p.textFontSize * 0.5}px` : '25px')};
        }

        .dx-placeholder, .dx-datagrid-nodata, .dx-datagrid-group-panel .dx-group-panel-item  {
            font-size: ${(p) => (p.textFontSize ? `${+p.textFontSize * 0.8}px!important` : 'inherit')};
            line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * 0.8 * lineHeightCoefficient}px!important` : 'inherit')};
        }

        .dx-sort {
            font-size: inherit;
            line-height: inherit;
        }

        .dx-datagrid-group-opened, .dx-datagrid-group-closed {
            font: ${(p) => (p.textFontSize ? `${p.textFontSize}px/${p.textFontSize}px DXIcons` : '18px/18px DXIcons')};
            text-align: center;
            cursor: pointer;

            &::before {
                width: ${(p) => (p.textFontSize ? `${+p.textFontSize + 2}px` : '20px')};
            }
        }

        .dx-header-row {
            background-color: ${({ primaryColor }) => primaryColor};
            color: ${({ primaryTextColor }) => primaryTextColor};
            font-weight: 700;
            .cell-highlighted {
                background-color: ${({ primaryColor }) => ColorUtils.shadeColor(primaryColor, -8)};          
            }        
        }

        .dx-state-focused .dx-header-row td .dx-header-filter:focus,
        .dx-state-focused .dx-header-row td:focus {
            outline: ${palette.grey1} solid 2px;
            outline-offset: 0;
            box-shadow: 0 0 0 4px ${palette.grey4};
        }

        .dx-datagrid-drop-highlight {
            td {
                background-color: ${({ primaryColor }) => ColorUtils.shadeColor(primaryColor, -8)};
                color: ${({ primaryTextColor }) => primaryTextColor};
            }
        }       

        .dx-data-row {
            .cell-highlighted {
                background-color: #eaeaea;
            }       
        }

        .dx-group-row {
            .cell-highlighted {
                background-color: #eaeaea;
            }    
        }

        .dx-datagrid-total-footer {
            background-color: #ebebeb;
            font-weight: 600;

            .dx-datagrid-summary-item {
                color: inherit;
            }

            .dx-datagrid-content {
                padding: 0;

                .cell-highlighted {
                    background-color: #b8b8b8;
                    border-left: 1px solid #b8b8b8;
                    border-right: 1px solid #b8b8b8;
                }
            } 
        }

        .dx-datagrid-table .dx-row.dx-data-row td { 
            ol li {
                overflow: visible;
            }
            ul li {
                overflow: visible;
            }

        }

        .dx-datagrid-rowsview .dx-adaptive-detail-row {
            .dx-field-item-content {
                font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
                line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px!important` : 'inherit')};
            }

            .dx-item.dx-box-item label.dx-field-item-label {
                font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
                font-weight: 600;
            }
        }
    }
`;

const styledComponents = { DataTableWrapper };
export default styledComponents;
