import React from 'react';
import { PrivateRoute } from '@liasincontrol/ui-basics';
import { Actions, ActionType } from '@liasincontrol/userrights-service';
import { Shared, Administrator } from '../..';
import { Route } from 'react-router-dom';

const Contents = (
    <>
        {/* Admin routes */}

        <Route path='information' element={<PrivateRoute permissions={{ action: Actions.CRUD_Publications, actionType: ActionType.Update }} component={Shared.PublicationItem.Information.index} />} />
        <Route path='publish' element={<PrivateRoute permissions={Actions.COMPLEX_PublishPublication} component={Administrator.PublicationItem.Publish.index} />} />
        <Route path='sitemap' element={<PrivateRoute permissions={Actions.CRUD_Sitemap} component={Administrator.PublicationItem.Sitemap.index} />} />
        <Route path='sitemap/:pageid' element={<PrivateRoute permissions={Actions.CRUD_Sitemap} component={Administrator.PublicationItem.Sitemap.index} />} />

        <Route path="tasks-overview" element={<PrivateRoute permissions={Actions.COMPLEX_TasksOverview} component={Administrator.PublicationItem.TaskOverview.index} />} />
        <Route path="assignments" element={<PrivateRoute permissions={Actions.COMPLEX_ManageTasks} component={Administrator.PublicationItem.Assignments.index} />} />
        <Route path="templates" element={<PrivateRoute permissions={Actions.CRUD_Templates} component={Administrator.PublicationItem.Templates.index} />} />
        <Route path="datasource/list" element={<PrivateRoute permissions={{ action: Actions.CRUD_Datasources, actionType: ActionType.Read }} component={Administrator.PublicationItem.DataSources.DataSourcesList.index} />} />
    </>
);

export { Contents };