import React, { useEffect, useMemo, useState } from 'react';
import _, { Dictionary } from "lodash";
import format from 'number-format.js';
import CircleIcon from '@mui/icons-material/Circle';
import * as Domain from '@liasincontrol/domain';
import { BasicText, Button, IconSize, Label } from '@liasincontrol/ui-basics';
import { DateUtils, StringUtils } from '@liasincontrol/core-service';
import StyledOption from '../../_shared/OptionItem/index.styled';
import Styled from './index.styled';
import { getFieldEditorSettings } from '../../ElementDefinitions/ElementDefinitionSettings/FieldsDefinitions/utils';

type Props = {
    elementDefinition: Domain.Shared.ElementDefinition,
    auditTrail: Domain.Shared.AuditEvent[],
};

/**
 * Represents a UI component that renders an audit trail of a studio hierarchy item.
 */
const HierarchyItemAuditTrail: React.FC<Props> = (props) => {
    const [auditEvents, setAuditEvents] = useState<{ showFullHistory: boolean, count: number, events: Dictionary<Domain.Shared.AuditEvent[]> }>({ showFullHistory: false, count: 0, events: {} });
    const [selectedAuditEvent, setSelectedAuditEvent] = useState<{ newValue: Domain.Shared.AuditEvent[]; oldValue: Domain.Shared.AuditEvent[] }>({ newValue: undefined, oldValue: undefined });

    useEffect(() => {
        // Group events on command log, join the changes in one audit entry and remove the first commands entries
        // remove complex data from audit trail
        const grouped = _.groupBy(props.auditTrail, 'commandLog');
        setAuditEvents({ showFullHistory: false, count: Object.keys(grouped).length, events: grouped });
    }, [props.auditTrail]);

    const getFormattedText = (editorSettings: Domain.Studio.FieldEditorControlSettings, text: string) => {
        switch (editorSettings?.stringDisplayFormat) {
            case Domain.Studio.StringDisplayType.MultiLine:
                return (<span style={{ whiteSpace: 'pre-wrap' }}>{text}</span>);
            case Domain.Studio.StringDisplayType.HTML:
                return (<div dangerouslySetInnerHTML={StringUtils.toRawMarkup(text)} style={{ overflowY: 'auto', minHeight: '48px', maxHeight: '250px', boxSizing: 'border-box' }}></div>);
            case Domain.Studio.StringDisplayType.HyperLink:
                return (<a href={text} target="_blank" rel="noopener noreferrer">{text}</a>);
            default:
                return (<span>{text}</span>);
        }
    };

    const getFormattedDecimal = (editorSettings: Domain.Studio.FieldEditorControlSettings, text: string) => {
        if (editorSettings?.numericFormatString) {
            return (<span>{format(editorSettings.numericFormatString, Number(text))}</span>);
        }
        return (<span>{text}</span>);
    };

    const renderField = (fieldDefinition: Domain.Shared.FieldDefinition, fieldValue: string, color: string) => {
        switch (fieldDefinition?.dataType) {
            case Domain.Shared.FieldDataType.String.toString(): {
                const editorSettings: Domain.Studio.FieldEditorControlSettings = getFieldEditorSettings(fieldDefinition);
                return (<Styled.Text color={color}>{getFormattedText(editorSettings, fieldValue)}</Styled.Text>);
            }
            case Domain.Shared.FieldDataType.Option.toString():
                const optionItem = fieldDefinition?.optionItems.find((item) => item.id === fieldValue);
                const circlecolor = `#${optionItem.color?.toString(16)}`;
                return (<Styled.Text color={color}>
                    <StyledOption.SingleValueWrapper>
                        <CircleIcon sx={{ color: circlecolor, fontSize: IconSize.medium }} />
                        <StyledOption.SingleValueLabel>
                            {optionItem?.name}
                        </StyledOption.SingleValueLabel>
                    </StyledOption.SingleValueWrapper>
                </Styled.Text>);
            case Domain.Shared.FieldDataType.Decimal.toString(): {
                const editorSettings: Domain.Studio.FieldEditorControlSettings = getFieldEditorSettings(fieldDefinition);
                return (<Styled.Text withOverflow color={color}><span>{getFormattedDecimal(editorSettings, fieldValue)}</span></Styled.Text>);
            }
            case Domain.Shared.FieldDataType.Integer.toString():
                return (<Styled.Text withOverflow color={color}><span>{fieldValue}</span></Styled.Text>);
            case Domain.Shared.FieldDataType.DateTimeOffset.toString():
                return (<Styled.Text withOverflow color={color}><span>{DateUtils.formatDate(new Date(fieldValue))}</span></Styled.Text>);
            default:
                return null;
        }
    };

    const renderRow = (fieldId: string, fieldValueNew: string, fieldValuePrev: string, idx: number) => {
        const fieldDefinition = props.elementDefinition.fields.find((item) => item.id === fieldId);
        if (fieldDefinition) {
            return (
                <Styled.RowWrapper key={`row-${idx}`} rowStart={idx * 2}>
                    <Styled.ItemWrapper colStart={1} colEnd={3}>
                        <Label>{fieldDefinition?.name}</Label>
                    </Styled.ItemWrapper>
                    <Styled.ItemWrapper colStart={1}>
                        {fieldValuePrev && renderField(fieldDefinition, fieldValuePrev, 'default')}
                    </Styled.ItemWrapper>
                    <Styled.ItemWrapper colStart={2}>
                        {fieldValueNew && renderField(fieldDefinition, fieldValueNew, 'success')}
                    </Styled.ItemWrapper>
                </Styled.RowWrapper>
            );
        }
    };

    const renderViewer = (auditEvent: { newValue: Domain.Shared.AuditEvent[]; oldValue: Domain.Shared.AuditEvent[] }) => {
        return auditEvent.newValue
            // Keep entries where old value or currrent value exist
            .filter(e => !(_.isEmpty(e.fieldValue) && _.isEmpty(auditEvent.oldValue?.find(ae => ae?.fieldId === e?.fieldId)?.fieldValue)))
            .map((event, idx) =>
                renderRow(event?.fieldId, event?.fieldValue, auditEvent.oldValue?.find(ae => ae?.fieldId === event?.fieldId)?.fieldValue, idx + 2));
    };

    const renderAuditEvent = (auditEvent: Domain.Shared.AuditEvent[], key: string) => {
        const historyDate = DateUtils.formatDateTime(auditEvent[0].timestamp);
        const idCurrentEntry = props.auditTrail.findIndex(item => item.commandLog === key);
        const idPreviousEntry = _.slice(props.auditTrail, idCurrentEntry).findIndex(item => item.commandLog !== key);

        const getPreviousEvent = (idCurrentEntry: number, idPreviousEntry: number, auditEvent: Domain.Shared.AuditEvent[]) => {
            const previousEvents = _.slice(_.slice(props.auditTrail, idCurrentEntry), idPreviousEntry);
            return auditEvent.map((event) => previousEvents.find((item: Domain.Shared.AuditEvent) => item.fieldId === event.fieldId));
        };

        const previousAuditEvent = idPreviousEntry > 0 ? getPreviousEvent(idCurrentEntry, idPreviousEntry, auditEvent) : [];

        return (
            <React.Fragment key={`key-${key}`}>
                <Styled.AuditTrailItem
                    id={`btn-${key}`}
                    onClick={() => setSelectedAuditEvent({ newValue: auditEvent, oldValue: previousAuditEvent })}
                    isActive={selectedAuditEvent.newValue && auditEvent && selectedAuditEvent.newValue[0]?.commandLog === auditEvent[0]?.commandLog}
                >
                    <Styled.Item>{auditEvent[0]?.user?.name || 'Unknown'}</Styled.Item>
                    <Styled.Item>{historyDate}</Styled.Item>
                </Styled.AuditTrailItem>
            </React.Fragment>
        );
    };

    const visibleAuditEvents = useMemo(
        () =>
            Object.entries(auditEvents.events)
                .slice(0, !auditEvents.showFullHistory ? 10 : undefined)
                .map(([key, auditEvent]) => renderAuditEvent(auditEvent, key)),
        [auditEvents, selectedAuditEvent]
    );

    return (
        <Styled.Content>
            <Styled.ContentColumn1>
                <Styled.ColumnTitle>Historie</Styled.ColumnTitle>
                <Styled.Wrapper>
                    {auditEvents.count <= 0 && <BasicText>Geen gegevens beschikbaar</BasicText>}
                    {visibleAuditEvents}
                    {auditEvents.count > 10 && !auditEvents.showFullHistory && (
                        <Button id='btn-load-full-history' btnbase='textbuttons' btntype='medium_icon' onClick={() => setAuditEvents((prev) => ({ ...prev, showFullHistory: true }))}>
                            Laad alles...
                        </Button>
                    )}
                </Styled.Wrapper>
            </Styled.ContentColumn1>
            <Styled.ContentColumn2>
                <Styled.ColumnTitle>Details van wijziging</Styled.ColumnTitle>
                <Styled.RowWrapper rowStart={2}>
                    <Styled.ItemWrapper colStart={1}>
                        <Label>Oude versie</Label>
                    </Styled.ItemWrapper>
                    <Styled.ItemWrapper colStart={2}>
                        <Label>Nieuwe versie</Label>
                    </Styled.ItemWrapper>
                </Styled.RowWrapper>
                {selectedAuditEvent.newValue && renderViewer(selectedAuditEvent)}
            </Styled.ContentColumn2>
        </Styled.Content>
    );
}

export { HierarchyItemAuditTrail };
