import styled from 'styled-components';
import { lineHeightCoefficient } from '../../SharedControlElements/index.styled';

const Title = styled.h2<{
    h1FontColor: string,
    h1FontSize: number
}>`
    && {
        color: ${(props) => (props.h1FontColor && props.h1FontColor)};
        font-size: ${(props) => (props.h1FontSize && `${props.h1FontSize}px`)};
        line-height: ${(props) => (props.h1FontSize && `${props.h1FontSize * lineHeightCoefficient}px`)};
        margin-bottom: 0 !important;
    }
`;

const SubtitleLarge = styled.h3<{
    h2FontColor: string,
    h2FontSize: number
}>`
    && {
        color: ${(props) => (props.h2FontColor && props.h2FontColor)};
        font-size: ${(props) => (props.h2FontSize && `${props.h2FontSize}px`)};
        line-height: ${(props) => (props.h2FontSize && `${props.h2FontSize * lineHeightCoefficient}px`)};
        margin-bottom: 0 !important;
    }
`;

const SubtitleSmall = styled.h4<{
    h3FontColor: string,
    h3FontSize: number
}>`
    && {
        color: ${(props) => (props.h3FontColor && props.h3FontColor)};
        font-size: ${(props) => (props.h3FontSize && `${props.h3FontSize}px`)};
        line-height: ${(props) => (props.h3FontSize && `${props.h3FontSize * lineHeightCoefficient}px`)};
        margin-bottom: 0 !important;
    }
`;

const styledComponents = { Title, SubtitleLarge, SubtitleSmall };
export default styledComponents;
