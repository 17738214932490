import styled from 'styled-components';

const CenteredDiv = styled.div`
    width: 100%;
    text-align: center;
    text-align: -webkit-center;
    font-weight: 200;
`;

const styledComponents = { CenteredDiv };
export default styledComponents;