import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents the Site designs data accessor.
 */
export class SiteDesigns {
    /**
     * Gets the list of site designs related to a feature/microservice.
     */
    public static getSiteDesigns = async (): Promise<AxiosResponse<Domain.Publisher.SiteDesign[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.SiteDesign[]>(`/api/sitedesign`, config);
    };

    /**
     * Gets a site design based on its unique identifier.
     *
     * @param siteDesignId Defines the unique identifier of the site design.
     */
    public static getSiteDesign = async (siteDesignId: string): Promise<AxiosResponse<Domain.Publisher.Design>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.Design>(`/api/sitedesign/${siteDesignId}`, config);
    };

    /**
     * Gets a list of page designs based on the site design unique identifier.
     *
     * @param siteDesignId Defines the unique identifier of the site design.
     */
    public static getPageDesigns = async (siteDesignId: string): Promise<AxiosResponse<Domain.Publisher.PageDesign[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.PageDesign[]>(`api/sitedesign/${siteDesignId}/pagedesign`, config);
    };
}
