import * as Domain from '@liasincontrol/domain';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import _, { Dictionary } from 'lodash';

/**
 * Applies the patches over the existing elements.
 * 
 * @param elements Defines the raw elements.
 * @param patches Defines the patches for a page.
 * 
 * @returns the applied patches over the existing elements. 
 */
const getPatchedElements = (elements: Dictionary<Domain.Publisher.Element>, patches: {
    [elementId: string]: {
        fields: { [fieldId: string]: string },
        complexFields: { [complexFieldDefinitionId: string]: { [rowIndex: number]: Domain.Shared.ComplexField } },
    }
}): Dictionary<Domain.Publisher.Element> => {
    const patchedElements = _.cloneDeep(elements);
    if (patches) {
        Object.keys(patches).forEach((elementId) => {
            if (patchedElements[elementId]) {
                Object.keys(patches[elementId].fields).forEach((fieldId) => {
                    patchedElements[elementId].fields[fieldId] = patches[elementId].fields[fieldId];
                });
                Object.keys(patches[elementId].complexFields).forEach((complexFieldDefinitionId) => {
                    patchedElements[elementId].complexFields = patchedElements[elementId]?.complexFields.filter((complexField) => complexField.complexFieldDefinitionId !== complexFieldDefinitionId);

                    Object.keys(patches[elementId].complexFields[complexFieldDefinitionId]).forEach((rowIndex) => {
                        patchedElements[elementId].complexFields.push(patches[elementId].complexFields[complexFieldDefinitionId][rowIndex]);
                    });
                });
            }
        });
    }

    return patchedElements;
};

/**
 * Gets the publication name.
 * 
 * @param publication Defines the publication.
 * @param elementDefinition Defines an element definition.
 */
const getPublicationName = (publication: Domain.Publisher.Publication, elementDefinition: Domain.Shared.ElementDefinition): string => {
    if (!publication || !elementDefinition) {
        return null;
    }

    const publicationFieldDefinitions = elementDefinition.fields;
    const publicationNameTenantId = publicationFieldDefinitions.find(pf => pf.systemId === SystemFieldDefinitions.Pub.Name).id;

    return publication.publication.fields[publicationNameTenantId];
};

const getPublicationIsClosed = (publication: Domain.Publisher.Publication, elementDefinition: Domain.Shared.ElementDefinition): boolean => {
    if (!publication || !elementDefinition) {
        return null;
    }

    const publicationFieldDefinitions = elementDefinition.fields;
    const publicationClosedId = publicationFieldDefinitions.find(pf => pf.systemId === SystemFieldDefinitions.Pub.IsClosedPublication).id;
    const isClosed = publication.publication.fields[publicationClosedId];

    return isClosed?.toLowerCase() === "true" ? true : false;
};

const getColorPaletteName = (publication: Domain.Publisher.Publication, elementDefinition: Domain.Shared.ElementDefinition): string => {
    if (!publication || !elementDefinition) {
        return null;
    }

    const publicationFieldDefinitions = elementDefinition.fields;
    const publicationColorPaletteDefinition = publicationFieldDefinitions.find(pf => pf.systemId === SystemFieldDefinitions.Pub.ColorPalette);
    const usedColorPalette = publication.publication.fields[publicationColorPaletteDefinition.id];

    const usedColorPaletteName = publicationColorPaletteDefinition.optionItems?.find(cp => cp.id === usedColorPalette)?.name;
    return usedColorPaletteName;
};

const helpers = { getPatchedElements, getPublicationName, getPublicationIsClosed, getColorPaletteName };
export default helpers;