import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Domain from '@liasincontrol/domain';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { ActionSource, ElementDefinitionsActionCreator, ModulesActionCreator, State, AjaxRequestStatus } from '@liasincontrol/redux-service';
import { TaskTiles, Wrapper, WrapperContent, PageTitle, Heading2, PanelGrid, ErrorOverlay, TaskSummary, palette, TextImageWidget } from '@liasincontrol/ui-basics';

/**
 * Defines the props of the Cockpit view component.
 */
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the Publication cockpit view.
 */
const Cockpit: React.FC<Props> = (props) => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>();
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [taskSummary, setTaskSummary] = useState<TaskSummary[]>([]);

    useEffect(() => {
        DataAccess.WorkflowTask.getWorkflowTaskSummary().then((response) => {
            setTaskSummary(response.data.map((dto: Domain.Publisher.WorkflowTaskSummary) => ({
                closedTaskCount: dto.closedTaskCount,
                openActiveTaskCount: dto.openActiveTaskCount,
                openInactiveTaskCount: dto.openInactiveTaskCount,
                title: dto.publicationName,
                link: `/publisher/publication/${dto.publicationId}/writer/page/`,
                color: palette.primary2,
                key: `pub-${dto.publicationId}`
            })));
        }).catch((err) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
        });
    }, [lastRefresh]);

    const onRetry = (): void => {
        if (!error?.canRetry) {
            return;
        }
        setError(undefined);
        setLastRefresh(Date.now());
    };

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Publisher]);
        return null;
    }

    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Cockpit Publisher</Heading2>
                </PageTitle>
                <PanelGrid>
                    <TextImageWidget
                        title='LIAS Publisher'
                        text='Maak, beheer en controleer beleidsdocumenten gezamenlijk online. Stel doelen, analyseer resultaten
                            en maak de rapportage inzichtelijk voor iedereen – van beleidsmedewerkers tot raadsleden en burgers.'
                    />
                    {error?.message
                        ? <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={onRetry} onBack={error?.canGoBack ? () => setError(undefined) : undefined} />
                        : <TaskTiles title='Taken binnen Publisher' tasks={taskSummary} />
                    }

                </PanelGrid>
            </WrapperContent>
        </Wrapper>
    );
};

const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Publication],
        elementDefinitions: state.elementdefinitions[ActionSource.Publication],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Publication, data: { moduleId: module?.id } }));
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Cockpit);
export { Component as index };