import { ContentTypeUtils } from '@liasincontrol/core-service';
import { AxiosResponse, CancelToken } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import { DataAccessHostUtils } from '../DataAccessHostUtils';

/**
 * Represents the attachments data accessor.
 */
export class Attachments {
    /**
     * Gets the information of an attachment based on its unique identifier.
     * 
     * @param attachmentId Defines the attachment id.
     * @param cancelToken Defines the cancel token.
     * 
     * @returns a promise that contains a url to the attachment.
     */
    public static getAttachmentInfo = async (attachmentId: string, cancelToken?: CancelToken): Promise<AxiosResponse<{ urlWithSasToken: string }>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        config.cancelToken = cancelToken;

        return HttpClient.get<{ urlWithSasToken: string }>(`/api/storage/downloadlink/${attachmentId}`, config);
    };

    /**
     * Uploads an attachment.
     * 
     * @param file Defines the attachment file.
     * @param abortSignal Defines the abort signal.
     * 
     * @returns a promise that contains information about the attachment that has been uploaded.
     */
    public static uploadAttachment = async (file: File, abortSignal: AbortSignal): Promise<{ urlWithSasToken: string, blobId: string }> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const blobUploadInfo = await HttpClient.get<{ urlWithSasToken: string, blobId: string }>('/api/storage/uploadlink', config);

        const headers = new Headers({
            'content-type': ContentTypeUtils.translate(file.type, file.name),
            'x-ms-blob-type': 'BlockBlob',
        });

        return fetch(blobUploadInfo.data.urlWithSasToken!, {
            method: 'PUT',
            headers,
            body: file,
            signal: abortSignal
        }).then(() => {
            return blobUploadInfo.data;
        });
    };
}
