import React from 'react';
import Styled, { MonitorButton, TabletButton, PhoneButton, DevicePosition } from './index.styled';

type DeviceType = 'desktop' | 'tablet' | 'phone' | 'debug';

interface DeviceFrameProps {
    readonly device?: DeviceType;
    readonly onHome?: () => void;
    readonly position?: DevicePosition;

}

const DeviceFrame = ({ device, onHome, position }: DeviceFrameProps) => {
    return (
        <Styled.Frame position={position} className={device} initialPose="desktop" pose={device} withParent={false}>
            <Styled.DeviceFrameButton onClick={onHome} />
            <Styled.DeviceFrameCamera />
            <Styled.DeviceFrameSpeaker />
        </Styled.Frame>
    );
};

const getLayoutByDeviceElement = (device: DeviceType, children: React.ReactNode): JSX.Element => {
    switch (device) {
        case 'phone': return (<Styled.PhoneWrapper>{children}</Styled.PhoneWrapper>);
        case 'tablet': return (<Styled.ScaledTabletWrapper>{children}</Styled.ScaledTabletWrapper>);
        case 'desktop': return (<Styled.DesktopWrapper>{children}</Styled.DesktopWrapper>);
        default: return (<Styled.ScaledTabletWrapper>{children}</Styled.ScaledTabletWrapper>);
    }
};

const Device = ({ device, children, onHome }: { device: DeviceType; children: React.ReactNode; onHome?: () => void }) => {
    if (device === 'debug') {
        device = 'desktop';
    }

    return (
        <Styled.DeviceWrapper >
            <DeviceFrame device={device} onHome={onHome} />
            <Styled.DeviceContent id="device-content" className={device} pose={device} withParent={false}>
                {getLayoutByDeviceElement(device, children)}
            </Styled.DeviceContent>
        </Styled.DeviceWrapper>
    );
};

export { Device, DeviceFrame, DeviceType, DeviceFrameProps, MonitorButton, TabletButton, PhoneButton };
