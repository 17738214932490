import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import React from 'react';
import Styled from './index.styled';
import { Text } from '../primitives/text';

export const ProgressLoading = ({ hint, variant }: { readonly hint?: string; readonly variant?: 'icon' | 'default' | 'small' | 'large' | 'inline' }) =>
    hint ? (
        <>
            <Styled.LoaderIcon variant={variant}>
                <HourglassBottomIcon />
            </Styled.LoaderIcon>
            <br />
            <Text value={hint} />
        </>
    ) : (
        <Styled.LoaderIcon variant={variant}>
            <HourglassBottomIcon />
        </Styled.LoaderIcon>
    );

export default ProgressLoading;
