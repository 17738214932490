import * as Domain from '@liasincontrol/domain';
import { SystemElementDefinitions, SystemFieldDefinitions } from '@liasincontrol/domain';
import { guidToODataFieldId } from '@liasincontrol/data-service';
import { Dictionary } from 'lodash';
import 'reflect-metadata';
import { FieldsHelper } from './FieldsHelper';

/**
 * Represents a helper that offers common actions for FieldDefinitions or ElementDefinition objects.
 */
export class DefinitionsHelper {
    /**
     * Extract only a single Element definition from the full element definitions array.
     * @param allElementDefinitions Element definitions array specific to one source, as received from the Redux store.
     * @param systemId SystemId (not tennant specific Id) to extract from the array.
     */
    public static findElementDefinition = (allElementDefinitions: { [id: string]: Domain.Shared.ElementDefinition }, systemId: SystemElementDefinitions.Pub | SystemElementDefinitions.Performance): Domain.Shared.ElementDefinition => {
        if (!allElementDefinitions || Object.keys(allElementDefinitions).length === 0) {
            return null;
        }

        const filtered = Object.entries(allElementDefinitions)
            ?.filter(([id, definition], index, arr) => definition.systemId === systemId.toString())
            ?.map(([id, definition]) => definition);

        if (filtered.length <= 0) {
            //TODO: better way to report an error?
            console.error(`DefinitionsHelper.findElementDefinition - Cannot find systemID ${systemId} in elementDefinitions`);
        }

        return filtered.shift();
    }

    /**
     * Returns a dictionary of system field Id to field definition for all fields of a specific domain element, identified by it's system Id.
     * @param allElementDefinitions Element definitions array specific to one source, as received from the Redux store.
     * @param systemId SystemId (not tennant specific Id) to extract from the array.
     */
    public static findElementFieldsMap = (allElementDefinitions: { [id: string]: Domain.Shared.ElementDefinition }, systemId: SystemElementDefinitions.Pub | SystemElementDefinitions.Performance): Dictionary<Domain.Shared.FieldDefinition> => {
        const elementDefinition = DefinitionsHelper.findElementDefinition(allElementDefinitions, systemId);
        if (!elementDefinition || !elementDefinition.fields) {
            return undefined;
        }

        return elementDefinition.fields.reduce((collection, item) => ({ ...collection, [item.systemId]: item }), {});
    };

    /**
     * Translates a single domain object field name to it's unique Id (not System Id).
     * @param element a (sample) object of type T.
     * @param fieldName name of the T type object field to translate.
     * @param elementdefinition element definition object for the T type.
     * 
     * @template T object type returned by the API (Element-derived) and used as underlying storage in the list.
     */
    public static fieldNameToFieldId = <T>(element: T, fieldName: keyof T & string, elementdefinition: Domain.Shared.ElementDefinition): string => {

        const columnSystemId = Reflect.getMetadata(Domain.Shared.FieldDefinitionMetadataKey, element, fieldName);
        if (!columnSystemId) {
            return null;
        }

        const isComplex = Reflect.getMetadata(Domain.Shared.IsComplexFieldMetadataKey, element, fieldName);
        if (!isComplex) {
            return elementdefinition.fields.find((fieldDefinition) => fieldDefinition.systemId === columnSystemId)!.id;
        } else {
            // try and find the field systemId in any of the inner complex field definition arrays.
            return elementdefinition.complexFields.map((complexDefinition) => {
                return complexDefinition.fields?.find((fieldDefinition) => fieldDefinition.systemId === columnSystemId)!.id;
            }).filter(field => field).pop();
        }
    }

    /**
     * Returns a list of field identifiers that can be used in an OData select query statement.
     * @param newObj a newly created <T> object.
     * @param fieldNames array with the names of all fields to be selected.
     * @param elementdefinition element definition object for the T type.
     * 
     * @template T object type returned by the API (Element-derived) and used as underlying storage in the list.
     */

    public static getSelectableFieldsForAPI = <T>(newObj: T, fieldNames: (keyof T & string)[], elementdefinition: Domain.Shared.ElementDefinition): string[] => {

        if (!elementdefinition) {
            return null;
        }
        return fieldNames
            .map((fieldName) => DefinitionsHelper.fieldNameToFieldId(newObj, fieldName, elementdefinition))
            .filter((column) => column)
            .map(fieldId => guidToODataFieldId(fieldId));
    }

    /**
     * Get the element's direction field value if it contains this property.
     * 
     * @param element Defines the element.
     * @param elementDefinition Defines the element definition.
     */
    public static getElementDirection = (element: Domain.Publisher.Element, elementDefinition: Domain.Shared.ElementDefinition): Domain.Publisher.Direction => {
        const directionDefinition = elementDefinition.fields.find((field) => field.systemId === SystemFieldDefinitions.Pub.StackContainerDirection);
        if (directionDefinition) {
            const directionId = element.fields[directionDefinition.id];
            const directionValue = directionDefinition.optionItems.find((item) => item.id === directionId).value;
            if (directionValue) {
                return directionValue as Domain.Publisher.Direction;
            }
        }

        return Domain.Publisher.Direction.Vertical;
    };

    /**
     * Translates (map) an element to a control based on element definition.
     * @param element a (sample) object of type Element.
     * @param controlSettings an object of type T.
     * @param getElementDefinitionCallback the conversion rules.
     */

    public static getControlSettings = <T>(element: Domain.Publisher.Element, controlSettings: T, getElementDefinitionCallback: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition): T => {
        if (!element) return;
        const definition = getElementDefinitionCallback(element.elementDefinitionSystemId, element.elementDefinitionId);
        FieldsHelper.mapObject(controlSettings, definition.fields, element.fields);
        return controlSettings;
    };

    /**
     * Retrieves the variables related data for a specified element (page or template).
     * 
     * @param elementDefinitions - Record of element definitions
     * @param systemId - System ID of the element to find
     * @param complexFields - Array of complex fields
     * @returns The variables definition label and an array of complex field items representing variables
     */
    public static getVariablesDataForElement = (
        elementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
        systemId: SystemElementDefinitions.Pub.Page | SystemElementDefinitions.Pub.PageTemplate,
        complexFields: Domain.Shared.ComplexField[]
    ): { label: string, variables: Domain.Shared.ComplexFieldItem[] } => {
        const elementDefinition = DefinitionsHelper.findElementDefinition(elementDefinitions, systemId);
        if (!elementDefinition) return { label: '', variables: [] };
        const variablesComplexDefinition = elementDefinition.complexFields.find((complexField) =>
            complexField.systemId === Domain.SystemFieldDefinitions.Pub.VariablesComplex
        );
        const variableNameDefinition = variablesComplexDefinition.fields.find((item) =>
            item.systemId === Domain.SystemFieldDefinitions.Pub.VariableNameField
        );
        const variableValueDefinition = variablesComplexDefinition.fields.find((item) =>
            item.systemId === Domain.SystemFieldDefinitions.Pub.VariableValueField
        );

        const variables = complexFields.reduce((acc: Domain.Shared.ComplexFieldItem[], complexField: Domain.Shared.ComplexField) => {
            if (Object.keys(complexField.fields).length > 0 && complexField.complexFieldDefinitionId === variablesComplexDefinition.id) {
                acc.push({
                    rowIndex: complexField.rowIndex,
                    name: complexField.fields[variableNameDefinition.id],
                    value: complexField.fields[variableValueDefinition.id],
                });
            }
            return acc;
        }, []);

        return { label: variablesComplexDefinition.label, variables };
    }
}
