/**
 * Represents a task item for the task list view
 */
class Task {
    budgetJournalId: string;
    budgetJournalName: string;
    budgetJournalCode: string;
    currentStateId: string;
    taskStateId: string;
    userId: string;
    taskId: string;
    taskIsCompleted: boolean;
    taskIsActive: boolean;
    currentStateName?: string;
    taskStateName?: string;
    userName?: string;
    userEmail?: string;
}

export { Task }