import React from 'react';
//import { components } from 'react-select';
import { IconSize, SVGIcon } from '@liasincontrol/ui-basics';
import { SelectElementTemplateProps } from '@liasincontrol/ui-elements';
import Styled from '../index.styled';
import TextBox from "devextreme-react/text-box";

export const typeInputCustomItem = (customValueProps: any, templateProps?: SelectElementTemplateProps) => {
    return (
        <Styled.SingleValueWrapper>
            {customValueProps?.iconContent &&
                <SVGIcon value={customValueProps.iconContent} size={IconSize.medium} color={customValueProps.color} />
            }
            {templateProps?.isFieldTemplate ?
                <TextBox stylingMode='outlined' value={customValueProps?.name} placeholder={templateProps?.placeholder} />
                :
                <Styled.SingleValueLabel>{customValueProps.name}</Styled.SingleValueLabel>
            }
        </Styled.SingleValueWrapper>
    );
};