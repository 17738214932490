import React from 'react';
import styled, { keyframes } from 'styled-components';
import { px } from '../style';
import { cssBreakpoint } from '../responsive/css';

/**
 * Padding on by default
 */
export const WrapperContent = ({ children, navigation, padding = true, maxAvailable = false }: { children: React.ReactNode; navigation?: boolean; padding?: boolean, maxAvailable?: boolean }) => (
    <Main navigation={navigation} padding={padding} maxAvailable={maxAvailable}>
        {children}
    </Main>
);

// tslint:disable:no-magic-numbers

const Main = styled.main<{ navigation: boolean; padding: boolean, maxAvailable: boolean }>`
    position: relative;
    grid-row-start: 1;
    min-width: 0;
    grid-row-end: ${({ maxAvailable }) => (maxAvailable ? -1 : 'auto')};
    
    grid-column-start: ${({ navigation }) => (navigation ? 3 : 2)};
    grid-column-end: ${({ maxAvailable }) => (maxAvailable ? -1 : 'auto')};

    animation: ${keyframes`from { transform: translateY(${px(20)}); opacity: 0; } to { transform: translateY(0%); opacity: 1; }`} 0.2s ease;

    ${({ padding }) => padding && cssBreakpoint('xl', 'l', 'm')`
        padding: ${px(16)} ${px(64)}
    `};

    ${({ padding }) => padding && cssBreakpoint('s')`
        padding: ${px(16)} ${px(24)}
    `};
    
`;
