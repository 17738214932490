import React, { useState, useEffect, useRef } from 'react';
import _, { Dictionary } from 'lodash';
import { FieldsHelper } from '@liasincontrol/core-service';
import { constrain, scale, useComponentBounds } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import Styled from './index.styled';

/**
 * Represents the image format.
 */
enum ImageFormat {
    Small = 1,
    Medium = 2,
    Large = 3,
    FitToImage = 4
}

type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Dictionary<Domain.Publisher.Element>,
    publicationElement: Domain.Publisher.PublicationElement,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onLoadAttachment: (id: string) => Promise<Blob>,
};

/**
 * Represents a UI component that renders an image control.
 */
const ImageControl: React.FC<Props> = (props) => {
    const [currentElement, setCurrentElement] = useState<{
        data: Domain.Publisher.ImageControlElement,
        definition: Domain.Shared.ElementDefinition
    }>();
    const [imageUrl, setImageUrl] = useState<string>();
    const [imageDimensions, setImageDimensions] = useState<ImageDimensions>({ width: 0, height: 0 });

    const figureWrapperRef = useRef(null);
    const { width: parentWidth, height: parentHeight } = useComponentBounds(figureWrapperRef);

    useEffect(() => {
        const element = props.elementList[props.element.elementId];
        if (element) {
            const definition = props.getElementDefinition(element.elementDefinitionSystemId, element.elementDefinitionId);
            const data = new Domain.Publisher.ImageControlElement();
            FieldsHelper.mapObject<Domain.Publisher.ImageControlElement>(data, definition.fields, element.fields);
            setCurrentElement({ data, definition });
        }
    }, [props.elementList[props.element.elementId]]);

    useEffect(() => {
        if (currentElement?.data?.image) {
            props.onLoadAttachment(currentElement.data.image).then((response) => {
                setImageUrl(URL.createObjectURL(response));
            });
        } else {
            setImageUrl('');
        }
    }, [currentElement?.data?.image]);

    useEffect(() => {
        if (!imageUrl) {
            return;
        }

        const img = new Image();
        img.src = imageUrl;
        img.addEventListener('load', () => {
            setImageDimensions({ width: img.naturalWidth, height: img.naturalHeight });
        });

    }, [imageUrl]);

    if (!currentElement?.data) {
        return null;
    }

    const displayFormatValue = FieldsHelper.mapFieldOption<Domain.Publisher.ImageControlElement>(currentElement.data, 'format', currentElement.definition);
    const figureStyle = ImageSize(currentElement.data, imageDimensions, parentWidth).get(displayFormatValue?.value);

    if (!imageUrl) {
        return (
            <Styled.ImagePlaceholderWrapper>
                <Styled.ImagePlaceholder customStyle={figureStyle} fillOut={currentElement.data.fill}>
                    <Styled.ImagePlaceholderIcon sx={{ fontSize: 80 }} />
                </Styled.ImagePlaceholder>
            </Styled.ImagePlaceholderWrapper>
        );
    }

    const additionalStyles = ImageScaled(ImageFocusPoint(currentElement.data), [imageDimensions.width, imageDimensions.height], [parentWidth, parentHeight]);

    return (<Styled.FigureWrapper ref={figureWrapperRef}>
        <Styled.FigureImage
            src={imageUrl}
            alt={currentElement.data.altText}
            figureStyle={{ ...figureStyle, ...additionalStyles }}
            fillOut={currentElement.data.fill} />
        {currentElement.data.caption && <Styled.FigureCaption textFontSize={props.publicationElement.bodyFontSize} color={props.publicationElement.bodyFontColor}>{currentElement.data.caption}</Styled.FigureCaption>}
    </Styled.FigureWrapper>
    );
};

export default React.memo(ImageControl, (prevProps, nextProps) => {
    return _.isEqual(prevProps.elementList[prevProps.element.elementId], nextProps.elementList[nextProps.element.elementId]);
});

/**
 * Maps the calculated image size.
 * 
 * @param element Defines the image element.
 * @param imageDimensions Defines the image dimensions.
 * @param wrapperParentWidth Defines the width of the wrapper parent.
 */
const ImageSize = (element: Domain.Publisher.ImageControlElement, imageDimensions: ImageDimensions, wrapperParentWidth: number): Map<ImageFormat, React.CSSProperties> => {
    const getWidth = (fill: boolean, heightValue: number, dimensions: ImageDimensions) => {
        if (fill) {
            return '100%';
        } else {
            if (dimensions.width !== 0) {
                return (dimensions.width * heightValue) / dimensions.height;
            } else {
                return 'auto';
            }
        }
    };

    return new Map([
        [ImageFormat.Small, { height: 160, width: getWidth(element.fill, 160, imageDimensions) }],
        [ImageFormat.Medium, { height: 200, width: getWidth(element.fill, 200, imageDimensions) }],
        [ImageFormat.Large, { height: 240, width: getWidth(element.fill, 240, imageDimensions) }],
        [
            ImageFormat.FitToImage,
            {
                height: imageDimensions.width !== 0 ? (wrapperParentWidth * imageDimensions.height) / imageDimensions.width : 0,
                width: '100%'
            },
        ],
    ]);
};

/**
 * Gets the calculated focus point.
 * 
 * @param element Defines the image element.
 */
const ImageFocusPoint = (element: Domain.Publisher.ImageControlElement): [number, number, number] => {
    const coordinates = element.focusPoint ? element.focusPoint.split(',') : null;
    const focusPoint: [number, number, number] = coordinates
        ? [Number(coordinates[0]), Number(coordinates[1]), Number(coordinates[2])]
        : [0.5, 0.5, 1];

    return focusPoint;
};

const ImageScaled = (focusPoint: [number, number, number], imageDimensions: [number, number], wrapperDimensions: [number, number]): React.CSSProperties => {
    const userScale = constrain(focusPoint[2], 1, 50);
    const scaleToCover = scale({ image: imageDimensions, window: wrapperDimensions });

    const availableX = imageDimensions[0] * scaleToCover * userScale - wrapperDimensions[0];
    const availableY = imageDimensions[1] * scaleToCover * userScale - wrapperDimensions[1];

    const x = constrain(availableX * constrain(focusPoint[0], 0, 1), 0, imageDimensions[0] * scaleToCover * userScale);
    const y = constrain(availableY * constrain(focusPoint[1], 0, 1), 0, imageDimensions[1] * scaleToCover * userScale);

    const width = imageDimensions[0] * scaleToCover * userScale;

    return {
        width: `${width}px`,
        objectPosition: `${-x}px ${-y}px`,
    };
};

/**
 * Represents the dimenssions of an image.
 */
type ImageDimensions = {
    width: number;
    height: number;
};
