import { css, ThemedCssFunction } from 'styled-components';

export const mediaSizes = {
    giant: 1170,
    desktop: 992,
    tablet: 768,
    phablet: 572,
    phone: 376,
};

export type MediaQuery<T> = { [P in keyof T]: ThemedCssFunction<any> };
export const cssMedia = Object.entries(mediaSizes).reduce((state, [name, size]) => {
    state[name] = (...args: any[]) => css`
      @media (max-width: ${size}px) {
        ${(css as any)(...args)}
      }
    `;
    return state;
}, {} as any) as MediaQuery<typeof mediaSizes>;
