import { SystemFieldDefinitions } from '../FieldDefinition';
import { fieldDefinitionDecorator } from '../FieldDefinition/FieldDefinitionDecorator';

/**
 * Represents a workflow template state element object, as returned by the bs-dynamic api.
 */
export class WorkflowTemplateStateElement {
    
    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Name)
    readonly name: string;

    // Will contain the category option GUid, not a WorkflowCategoryType value.
    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.WorkflowStateCategory)
    readonly category: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.WorkflowStateParentWorkflowId)
    readonly workflowDefinitionId: string;
}

/**
 * Represents one of the possible workflow template state Category field option values.
 */
export enum WorkflowCategoryOptionValues {
    New = 1,
    InProgress = 2,
    Done = 3
}
