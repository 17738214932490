import React from 'react';
import { OptionsList as Presentation } from './Presentation';
import { IOptionsListProps } from './Props';

export class OptionsList extends React.Component<IOptionsListProps> {
    public shouldComponentUpdate(nextProps: IOptionsListProps) {
        return this.props.idSelected !== nextProps.idSelected
            || this.props.items !== nextProps.items
            || this.props.onAddNewItem !== nextProps.onAddNewItem
            || this.props.onItemChanged !== nextProps.onItemChanged
            || this.props.onItemSelected !== nextProps.onItemSelected;
    }

    public render() {
        const { listId, listLabel, items, idSelected, onItemChanged, onItemSelected } = this.props;
        return (
            <Presentation
                listId={listId}
                listLabel={listLabel}
                items={items}
                idSelected={idSelected}
                onAddNewItem={this.onAddNewItem}
                onItemChanged={onItemChanged}
                onItemSelected={onItemSelected} />
        );
    }

    private onAddNewItem = () => {
        const id = this.props.items.reduce((s, n) => n.id >= s ? n.id + 1 : s, 0);
        const label = `Optie ${this.props.items.length + 1}`;
        this.props.onAddNewItem({ id, label });
    }
}
