/**
 * Represents a datasource.
 */
export class DataSource {

    publicationId: string;

    dataSourceId: string;

    dataSourceName: string;

    dataConnectionId: string;

    dataConnectionName: string;

    lastRefreshedAt: Date;

    autoRefresh: boolean;

    refreshFailed: boolean;

}
