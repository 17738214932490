import React from 'react';
import { Feedback, HelpText, Label } from '@liasincontrol/ui-basics';
import { ElementProps } from '../..';
import { StaticAnchor } from './index.styled';

/**
 * Represents a UI component that renders a Hyperlink viewer.
 */
export const HyperlinkViewer: React.FC<ElementProps<string>> = (props) => {
    return (
        <>
            {props.label && <Label id={`${props.id}-label`} htmlFor={props.id} text={props.label} />}
            {props.helpText && props.helpText.text && <HelpText title={props.helpText.title}>{props.helpText.text}</HelpText>}
            <StaticAnchor
                id={`input-${props.id}`}
                key={props.id}
                href={props.value}
                target='_blank'
                rel='noopener noreferrer'>
                {props.value}
            </StaticAnchor>
            <Feedback id={`${props.id}-feedback`} />
        </>
    );
};
