import React from 'react';
import { Dictionary } from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { Feedback } from '@liasincontrol/ui-basics';
import { ActionSource } from '@liasincontrol/redux-service';
import { Features, License } from '@liasincontrol/userrights-service';
import ContainerItem from './Containers/_shared/ContainerItem';
import ContainerItemPlaceHolder from './Containers/_shared/ContainerItemPlaceholder';
import HtmlContainer from './Containers/SystemContainers/HtmlContainer';
import HeadContainer from './Containers/SystemContainers/HeadContainer';
import BodyContainer from './Containers/SystemContainers/BodyContainer';
import SectionContainer from './Containers/SystemContainers/SectionContainer';
import LogoControl from './Controls/SystemControls/LogoControl';
import DocumentTitleControl from './Controls/SystemControls/DocumentTitleControl';
import BreadcrumbsControl from './Controls/SystemControls/BreadcrumbsControl';
import PageTitleControl from './Controls/SystemControls/PageTitleControl';
import PrimaryMenuControl from './Controls/SystemControls/PrimaryMenuControl';
import TitleControl from './Controls/UserControls/TitleControl';
import TextControl from './Controls/UserControls/TextControl';
import MenuControl from './Controls/UserControls/MenuControl';
import TileMenuControl from './Controls/UserControls/TileMenuControl';
import ImageControl from './Controls/UserControls/ImageControl';
import PieChartControl from './Controls/UserControls/PieChartControl';
import BarChartControl from './Controls/UserControls/BarChartControl';
import DataTableControl from './Controls/UserControls/DataTableControl';
import ReferenceAttachmentsControl from './Controls/UserControls/ReferenceAttachmentsControl';
import DataSourceTextControl from './Controls/UserControls/DataSourceTextControl';
import PerformanceInformationControl from './Controls/UserControls/PerformanceInformationControl';
import AccordionDataControl from './Controls/UserControls/AccordionDataControl';
import StackContainer from './Containers/UserContainers/StackContainer';
import TabContainer from './Containers/UserContainers/TabContainer';
import HtmlElementControl from './Controls/UserControls/HtmlElementControl';
import FooterControl from './Controls/SystemControls/FooterControl';
import TreeViewControl from './Controls/UserControls/TreeViewControl';
import AccordionDsDataControl from './Controls/UserControls/AccordionDsDataControl';
import MapControl from './Controls/UserControls/MapControl';
import PivotTableControl from './Controls/UserControls/PivotTableControl';
import AccordionContainer from './Containers/UserContainers/AccordionContainer';

import ElementWrapper from '../ElementWrapper';
import { TreeUtils } from '../../../helpers/TreeUtils';
import TabDsControl from './Controls/UserControls/TabDsControl';

export type ElementProps = {
    readonly: boolean,
    element: Domain.Publisher.ElementNode,
    publicationRoot: Domain.Publisher.ElementNode,
    publicationId: string,
    elementList: Dictionary<Domain.Publisher.Element>,
    sitemap: Domain.Publisher.Sitemap,
    publicationElement: Domain.Publisher.PublicationElement,
    editableElementIds?: Record<string, { editable: boolean, isLockedByOther: boolean, lockedByUser?: any }>,
    pageElement?: Domain.Publisher.PageElement,
    selectedElementId: string,
    validElement?: { message: string, isValid: boolean },
    hierarchies: Domain.Shared.HierarchyMap,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onSelect: (elementId: string) => void,
    onLoadAttachment: (id: string, names?: Record<string, string>) => Promise<Blob>,
    onFieldInlineChanged: (elementId: string, fieldId: string, value: string) => void,
    onTemplateChanged?: (operations: Domain.Publisher.Operation[]) => void,
    onRemoveElement?: (elementId: string, isControl: boolean) => void,
    onMoveElement?: (elementId: string, isControl: boolean, moveUp: boolean, steps?: number) => void,
    loadPerformanceHierarchy?: (measureMomentId: string) => void,
    getDefinition: (id: string, source: ActionSource) => Domain.Shared.ElementDefinition,
    loadStudioHierarchy?: (hierarchyId: string, hierarchyDefinitionId: string, measureMomentId: string) => void,

    inValidElements?: { [key: string]: { message: string, isValid: boolean, keep?: boolean } },
    hasErrorInSettings?: (elementId: string, hasError: boolean, message?: string, keepError?: boolean) => void,
    featureIsAvailable?: (feature: Features) => boolean,
    hasLicense?: (license: License) => boolean,
    variables?: Domain.Shared.ComplexFieldItem[],
    icons?: Record<string, Domain.Shared.SvgIcon>,
};

/**
 * A generic UI component that renders an element.
 */
const Element: React.FC<ElementProps> = (props) => {
    if (!props?.element) {
        return null;
    }

    const isDesigner = !!props.onTemplateChanged;

    let children: JSX.Element[] = [];
    if (props.element.children) {
        children = props.element.children.map((child) => {
            const childElement = (
                <Element
                    key={`element-${child.elementId}`}
                    readonly={props.readonly}
                    element={child}
                    elementList={props.elementList}
                    publicationRoot={props.publicationRoot}
                    publicationId={props.publicationId}
                    editableElementIds={props.editableElementIds}
                    sitemap={props.sitemap}
                    pageElement={props.pageElement}
                    selectedElementId={props.selectedElementId}
                    publicationElement={props.publicationElement}
                    getElementDefinition={props.getElementDefinition}
                    onSelect={props.onSelect}
                    onLoadAttachment={props.onLoadAttachment}
                    onFieldInlineChanged={props.onFieldInlineChanged}
                    onTemplateChanged={props.onTemplateChanged}
                    onRemoveElement={props.onRemoveElement}
                    onMoveElement={props.onMoveElement}
                    validElement={props.validElement}
                    hierarchies={props.hierarchies}
                    loadPerformanceHierarchy={props.loadPerformanceHierarchy}
                    getDefinition={props.getDefinition}
                    loadStudioHierarchy={props.loadStudioHierarchy}
                    inValidElements={props.inValidElements}
                    hasErrorInSettings={props.hasErrorInSettings}
                    featureIsAvailable={props.featureIsAvailable}
                    hasLicense={props.hasLicense}
                    icons={props.icons}
                    variables={props.variables}
                />
            );

            if (!props.readonly && supportsWrapper(props.elementList[child.elementId].elementDefinitionSystemId, isDesigner)) {
                const isParentTabContainer = props.elementList[props.element.parentId].elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.TabContainer;
                const isParentAccordionContainer = props.elementList[props.element.parentId].elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.AccordionContainer;

                const { canMoveUp, canMoveDown } = !isParentTabContainer || !isParentAccordionContainer
                    ? TreeUtils.getMoveOptions(props.publicationRoot, props.element.elementId)
                    : { canMoveUp: false, canMoveDown: false };
                //If textcontrol:
                const showErrorMessage = props.validElement && !props.validElement?.isValid && child.elementId === props.selectedElementId && props.elementList[child.elementId].elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.TextControl;
                //If other controls
                const erronate = (Object.keys(props.inValidElements).length > 0 && child.elementId ? props.inValidElements[child.elementId] : { message: '', isValid: true });

                return (
                    <>
                        <ElementWrapper
                            key={`element-wrapper-${child.elementId}`}
                            isDesigner={isDesigner}
                            isValid={props.validElement?.isValid || erronate?.isValid}
                            element={props.elementList[child.elementId]}
                            parentElement={props.elementList[props.element.parentId]}
                            canEdit={!props.editableElementIds || !props.editableElementIds[child.elementId] || props.editableElementIds[child.elementId].editable}
                            canMoveUp={canMoveUp}
                            canMoveDown={canMoveDown}
                            selectedElementId={props.selectedElementId}
                            getElementDefinition={props.getElementDefinition}
                            onSelect={() => props.onSelect(child.elementId)}
                            onRemoveElement={props.onRemoveElement}
                            onMoveElement={props.onMoveElement}
                            isLocked={!props.editableElementIds || !props.editableElementIds[child.elementId] || props.editableElementIds[child.elementId].isLockedByOther}
                            lockedBy={props.editableElementIds?.[child.elementId]?.lockedByUser}
                        >
                            {childElement}
                        </ElementWrapper>

                        {(showErrorMessage || (erronate && !erronate.isValid)) &&
                            <Feedback
                                id={`${props.element.elementId}-feedback`}
                                error={!props.validElement?.isValid || !erronate?.isValid}
                                children={<p>{props.validElement?.message || erronate?.message}</p>}
                                withCounter={false} />}
                    </>
                );
            }

            return childElement;
        });
    }

    // Maps the React component type based on the element definition system id:
    switch (props.elementList[props.element.elementId].elementDefinitionSystemId) {
        case Domain.SystemElementDefinitions.Pub.ContainerItem:
            return <ContainerItem {...props}>{children}</ContainerItem>;
        case Domain.SystemElementDefinitions.Pub.ContainerItemPlaceHolder:
            return <ContainerItemPlaceHolder {...props}>{children}</ContainerItemPlaceHolder>;
        // Containers:
        case Domain.SystemElementDefinitions.Pub.HtmlContainer:
            return <HtmlContainer {...props}>{children}</HtmlContainer>;
        case Domain.SystemElementDefinitions.Pub.HeadContainer:
            return <HeadContainer {...props}>{children}</HeadContainer>;
        case Domain.SystemElementDefinitions.Pub.BodyContainer:
            return <BodyContainer {...props}>{children}</BodyContainer>;
        case Domain.SystemElementDefinitions.Pub.SectionContainer:
            return <SectionContainer {...props}>{children}</SectionContainer>;
        case Domain.SystemElementDefinitions.Pub.StackContainer:
            return <StackContainer {...props}>{children}</StackContainer>;
        case Domain.SystemElementDefinitions.Pub.TabContainer:
            return <TabContainer {...props}>{children}</TabContainer>;
        case Domain.SystemElementDefinitions.Pub.AccordionContainer:
            return <AccordionContainer {...props}>{children}</AccordionContainer>;
        // Controls:
        case Domain.SystemElementDefinitions.Pub.LogoControl:
            return <LogoControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.WrapperDivContainer:
            return <>{children}</>;
        case Domain.SystemElementDefinitions.Pub.DocumentTitleControl:
            return <DocumentTitleControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.BreadcrumbsControl:
            return <BreadcrumbsControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.PageTitleControl:
            return <PageTitleControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.PrimaryMenuControl:
            return <PrimaryMenuControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.TitleControl:
            return <TitleControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.TextControl:
            return <TextControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.MenuControl:
            return <MenuControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.TileMenuControl:
            return <TileMenuControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.ImageControl:
            return <ImageControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.PieChartControl:
            return <PieChartControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.BarChartControl:
            return <BarChartControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.DataTableControl:
            return <DataTableControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.ReferenceAttachments:
            return <ReferenceAttachmentsControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.DataSourceText:
            return <DataSourceTextControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.HtmlElementControl:
            return <HtmlElementControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.PerformanceInformationControl:
            return <PerformanceInformationControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.AccordionDataControl:
            return <AccordionDataControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.FooterControl:
            return <FooterControl />; //{...props}
        case Domain.SystemElementDefinitions.Pub.TreeViewControl:
            return <TreeViewControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.AccordionDsControl:
            return <AccordionDsDataControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.MapControl:
            return <MapControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.PivotTableControl:
            return <PivotTableControl {...props} />;
        case Domain.SystemElementDefinitions.Pub.TabDsControl:
            return <TabDsControl {...props} />;


        // Elements which don't visually render anything or have children:
        case Domain.SystemElementDefinitions.Pub.CustomJavascriptControl:
        case Domain.SystemElementDefinitions.Pub.NonVisualFaviconControl:
        case Domain.SystemElementDefinitions.Pub.NonVisualFontControl:
        case Domain.SystemElementDefinitions.Pub.NonVisualJavascriptControl:
        case Domain.SystemElementDefinitions.Pub.NonVisualLinkControl:
        case Domain.SystemElementDefinitions.Pub.NonVisualMetadataControl:
        case Domain.SystemElementDefinitions.Pub.NonVisualPageTitleControl:
        case Domain.SystemElementDefinitions.Pub.NonVisualStylesheetControl:
        case Domain.SystemElementDefinitions.Pub.OnDemandJavascriptControl:
        case Domain.SystemElementDefinitions.Pub.OnDemandStylesheetControl:
        case Domain.SystemElementDefinitions.Pub.CustomStyleControl:
        case Domain.SystemElementDefinitions.Pub.GoToTopButtonControl:
        case Domain.SystemElementDefinitions.Pub.TitleVersionControl:
        case Domain.SystemElementDefinitions.Pub.NonVisualNoteServiceJSControl:
        case Domain.SystemElementDefinitions.Pub.NonVisualNoteServiceMetadataControl:
        case Domain.SystemElementDefinitions.Pub.CookiesBannerControl:
        case Domain.SystemElementDefinitions.Pub.AnalyticsControl:
        case Domain.SystemElementDefinitions.Pub.SearchFormControl:
        case Domain.SystemElementDefinitions.Pub.SearchControl:
        case Domain.SystemElementDefinitions.Pub.QuickNavControl:
        case Domain.SystemElementDefinitions.Pub.SkipLinkControl:
            return null;

        default:
            console.warn(`Unknown element. Element system definition id: ${props.elementList[props.element.elementId].elementDefinitionSystemId}`);
            return null;
    }
}

/**
 * Determines if the element supports wrapper or not.
 * 
 * @param elementDefinitionSystemId Defines the element definition system id.
 */
const supportsWrapper = (elementDefinitionSystemId: string, isDesigner: boolean): boolean => {
    if (isDesigner) {
        switch (elementDefinitionSystemId) {
            case Domain.SystemElementDefinitions.Pub.StackContainer:
            case Domain.SystemElementDefinitions.Pub.TabContainer:
            case Domain.SystemElementDefinitions.Pub.AccordionContainer:
                return true;
        }
    }

    switch (elementDefinitionSystemId) {
        case Domain.SystemElementDefinitions.Pub.TitleControl:
        case Domain.SystemElementDefinitions.Pub.TextControl:
        case Domain.SystemElementDefinitions.Pub.MenuControl:
        case Domain.SystemElementDefinitions.Pub.TileMenuControl:
        case Domain.SystemElementDefinitions.Pub.ImageControl:
        case Domain.SystemElementDefinitions.Pub.PieChartControl:
        case Domain.SystemElementDefinitions.Pub.BarChartControl:
        case Domain.SystemElementDefinitions.Pub.DataTableControl:
        case Domain.SystemElementDefinitions.Pub.HtmlElementControl:
        case Domain.SystemElementDefinitions.Pub.ReferenceAttachments:
        case Domain.SystemElementDefinitions.Pub.DataSourceText:
        case Domain.SystemElementDefinitions.Pub.PerformanceInformationControl:
        case Domain.SystemElementDefinitions.Pub.AccordionDataControl:
        case Domain.SystemElementDefinitions.Pub.TreeViewControl:
        case Domain.SystemElementDefinitions.Pub.AccordionDsControl:
        case Domain.SystemElementDefinitions.Pub.MapControl:
        case Domain.SystemElementDefinitions.Pub.PivotTableControl:
        case Domain.SystemElementDefinitions.Pub.TabDsControl:
            return true;

        default:
            return false;
    }
};

export default Element;
