import { BaseValidationRestriction, BaseValidator, CustomContextValidator } from './BaseValidator';
import { ValidationErrorData, ValueType } from './Types';

/**
 * Defines the restrictions that can be placed on an attachment validator.
 */
export interface AttachmentValidationRestriction extends BaseValidationRestriction {
    maxFileSize?: number,
    allowedFileTypes?: string[],
}

type Restrictions = { required: boolean, attachmentMaxFileSize: number, attachmentAllowedFileTypes: string[] };

/**
 *  Defines a helper class that can be used to validate an attachment field.
 */
export class AttachmentValidator extends BaseValidator<string> {
    public constructor(restrictions: Restrictions, optionalOverrides?: AttachmentValidationRestriction, contextValidator?: CustomContextValidator<string>) {
        super(restrictions.required);
        this.restrictions = {
            maxFileSize: optionalOverrides && optionalOverrides.maxFileSize ? optionalOverrides.maxFileSize : restrictions.attachmentMaxFileSize,
            allowedFileTypes: optionalOverrides && optionalOverrides.allowedFileTypes ? optionalOverrides.allowedFileTypes : restrictions.attachmentAllowedFileTypes,
        };

        this.contextValidator = contextValidator;
    }

    private restrictions: AttachmentValidationRestriction = {};
    private contextValidator: CustomContextValidator<string>;

    public validate(value: string, formData?: Record<string, ValueType>): ValidationErrorData[] {
        const errors: ValidationErrorData[] = super.validate(value);
        if (errors.length > 0) {
            return errors;
        }

        if (this.contextValidator) {
            errors.push(...this.contextValidator(value, formData));
        }

        return errors;
    }

    /**
     * Gets the attachments restrictions.
     */
    public getRestrictions = (): AttachmentValidationRestriction => {
        return Object.assign({}, this.restrictions, this.baseRestrictions);
    };
}
