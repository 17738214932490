import { BaseValidationRestriction, BaseValidator, CustomContextValidator } from './BaseValidator';
import { ValidationErrorData, ValueType } from './Types';

/**
 *  Defines a helper class that can be used to validate basic properties on any field.
 */
export class BasicValidator<T extends ValueType> extends BaseValidator<T> {
    public constructor(restrictions: { required: boolean }, contextValidator?: CustomContextValidator<T>) {
        super(restrictions.required);
        this.contextValidator = contextValidator;
    }

    private contextValidator: CustomContextValidator<T>;

    public validate(value: T, formData?: Record<string, ValueType>): ValidationErrorData[] {
        const errors: ValidationErrorData[] = super.validate(value);
        if (errors.length > 0) {
            return errors;
        }

        if (this.contextValidator) {
            errors.push(...this.contextValidator(value, formData));
        }

        return errors;
    }

    /**
     * Gets the base restrictions.
     */
    public getRestrictions = (): BaseValidationRestriction => {
        return this.baseRestrictions;
    };
}
