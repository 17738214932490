import styled from 'styled-components';
import { px } from '../../style/pixel';
import siteDesignA from './imgs/siteDesignA.svg';
import homePage from './imgs/Template_no-page-title.svg';
import sidebar from './imgs/Template_page-title_sidebar-right.svg';
import wideContent from './imgs/Template_page-title_wide-content.svg';

// Known site design ids, hardcoded on BE
const designMap = {
    '74c70e27-4e68-4d06-a39b-d209fa2b741c': 'siteDesignA',
    '77ce5938-79d2-4afe-b4b7-b0fe8b72f943': 'homepage',
    '2dedfe05-5b56-4754-b67e-1c8bdb3475da': 'tilepage',
    '27e00626-7ce0-46ac-961c-d903aad4d3e4': 'layout_a',

    'fea2e7f5-d87c-408e-9473-57b5ef17bc94': 'pagedesign_01',
    'a56ff1b2-7799-4550-b90f-02b446a66a9f': 'pagedesign_02',
    '32b4df2c-9317-41c3-ab2a-e2bef3157ddd': 'pagedesign_03',
    
};

const svgImg: { readonly [key: string]: string; } = {
    siteDesignA: `transparent url( ${siteDesignA}) 0 0 no-repeat`,
    homePage: `transparent url( ${homePage}) 0 0 no-repeat`,
    sidebar: `transparent url( ${sidebar}) 0 0 no-repeat`,
    wideContent: `transparent url( ${wideContent}) 0 0 no-repeat`,
    pagedesign_01: `transparent url( ${homePage}) 0 0 no-repeat`,
    pagedesign_02: `transparent url( ${sidebar}) 0 0 no-repeat`,
    pagedesign_03: `transparent url( ${wideContent}) 0 0 no-repeat`,
};

interface ISvgProps {
    variant: string;
    size: 'large' | 'small';
}

const SvgWrap = styled.i<ISvgProps>`
    box-shadow: 0 2px 6px rgba(0,0,0,0.12);
    border: 1px solid rgba(0,0,0,0.12);
    border-radius: ${px(2)};
    display: block;
    width: ${(p: ISvgProps) => (p.size === 'large' && `${px(350)}`) || (p.size === 'small' && `${px(240)}`) || `${px(350)}`};
    height: ${(p: ISvgProps) => (p.size === 'large' && `${px(270)}`) || (p.size === 'small' && `${px(180)}`) || `${px(270)}`};
    background: ${(p: ISvgProps) =>
        svgImg[designMap[p.variant]] || svgImg.siteDesignA
    };
    background-size: cover;
`;

const styledComponents = { SvgWrap };
export default styledComponents;