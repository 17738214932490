import { css } from 'styled-components';
import { px } from '../../style';
import { palette } from '../../styleguide';
import { base, whenDisabled } from './base';
import { primarybuttons } from './styleguide';
import { cssSmallText, cssNormalText, cssLargeText } from '../../primitives/typography';

// tslint:disable:no-magic-numbers

export const PrimaryButtons: primarybuttons = {
    small_icon: {
        text: cssSmallText,
        container: css`
            border-radius: ${px(100)};
            > span {
                grid-gap: 9px;
                padding: ${px(5)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
                border-width: 1px;
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 12px;
                height: 12px;
            }
            :focus > span {
                box-shadow: 0 0 0 ${px(3)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4, palette.grey4))}
            }
        `,
    },
    small_noicon: {
        text: cssSmallText,
        container: css`
            border-radius: ${px(100)};
            > span {
                grid-gap: 9px;
                padding: ${px(5)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
                border-width: 1px;
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 12px;
                height: 12px;
            }
            :focus > span {
                box-shadow: 0 0 0 ${px(3)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
                border-width: 1px;
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
                border-width: 1px;
            }
            :active > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4, palette.grey4))}
            }
        `,
    },
    medium_noicon: {
        text: cssNormalText,
        container: css`
            border-radius: ${px(100)};
            > span {
                grid-gap: 9px;
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 14px;
                height: 14px;
            }
            :focus > span {
                box-shadow: 0 0 0 ${px(2)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            :active > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4, palette.grey4))}
            }
        `,
    },
    medium_icon: {
        text: cssNormalText,
        container: css`
            border-radius: ${px(100)};
            > span {
                grid-gap: 9px;
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 14px;
                height: 14px;
            }
            :focus > span {
                box-shadow: 0 0 0 ${px(2)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            :active > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4, palette.grey4))}
            }
        `,
    },
    medium_transparent: {
        text: cssNormalText,
        container: css`
            border-radius: ${px(100)};
            > span {
                grid-gap: 9px;
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.primary2, palette.transparent)};
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 14px;
                height: 14px;
            }
            :focus {
                box-shadow: 0 0 0 ${px(2)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
            }
            :focus > span {
                ${base.colors(palette.primary1, palette.white, palette.primary1)}
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            :active > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4, palette.grey4))}
            }
        `,
    },
    medium_icon_nofocus: {
        text: cssNormalText,
        container: css`
            border-radius: ${px(100)};
            > span {
                grid-gap: 9px;
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 14px;
                height: 14px;
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            :active > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4, palette.grey4))}
            }
        `,
    },
    large_noicon: {
        text: cssLargeText,
        container: css`
            border-radius: ${px(100)};
            box-shadow: 0 0 ${px(20)} rgba(75, 85, 246, 0.3);
            > span {
                grid-gap: 16px;
                padding: ${px(11)} ${px(24)};
                border-radius: ${px(100)};
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 18px;
                height: 18px;
            }
            :focus > span {
                box-shadow: 0 0 0 ${px(2)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            :active > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4, palette.grey4))}
            }
        `,
    },
    large_icon: {
        text: cssLargeText,
        container: css`
            border-radius: ${px(100)};
            box-shadow: 0 0 ${px(20)} rgba(75, 85, 246, 0.3);
            > span {
                grid-gap: 16px;
                padding: ${px(11)} ${px(24)};
                border-radius: ${px(100)};
                ${base.colors(palette.white, palette.primary2, palette.primary2)}
            }
            svg {
                color: inherit;
                fill: currentColor;
                width: 18px;
                height: 18px;
            }
            :focus > span {
                box-shadow: 0 0 0 ${px(2)} #fff, 0 0 0 ${px(4)} ${palette.primary1};
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            :hover > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            :active > span {
                ${base.colors(palette.white, palette.primary1, palette.primary1)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4, palette.grey4))}
            }
        `,
    },
};
