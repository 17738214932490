import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

export type WorkflowTaskQueryParam = {
    /**
     * If true, details (name) about the page are returned. Default value is false.
     */
    includePageDetails?: boolean,

    /**
     * If true, details (name) about the component are returned. Default value is false.
     */
    includeComponentDetails?: boolean,

    /**
     * If true, details (name) about the state of the workflow item and task are returned. Default value is false.
     */
    includeStateDetails?: boolean,

    /**
     * If true, details (name, email) about the user are returned. Default value is false.
     */
    includeUserDetails?: boolean,

    /**
     * If true, only active tasks are returned. Default value is true.
     */
    onlyActive?: boolean,

    /**
     * If true, only completed tasks are returned. Default value is true.
     */
    onlyNotCompleted?: boolean,

    /**
     * If true, only tasks for the signed-in user are returned. Default value is true.
     */
    onlyCurrentUser?: boolean,
};

/**
 * Represents the WorkflowTask data accessor.
 */
export class WorkflowTask {
    /**
     * Gets a list of tasks for a control for all workflow states of a publication page.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageId Defines the unique identifier of the page.
     * @param componentId Defines the unique identifier of the control.
     */
    public static getComponentWorkflowTask = async (
        publicationId: string,
        pageId: string,
        componentId: string,
        queryParams: WorkflowTaskQueryParam
    ): Promise<AxiosResponse<Domain.Publisher.WorkflowTask[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const extraQueryString = getWorkflowTaskQueryString(queryParams);
        return HttpClient.get<Domain.Publisher.WorkflowTask[]>(`/api/publication/${publicationId}/page/${pageId}/component/${componentId}/task${extraQueryString}`, config);
    };

    /**
     * Gets a list of tasks for all controls for all workflow states of a publication page.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageId Defines the unique identifier of the page.
     */
    public static getPageWorkflowTask = async (publicationId: string, pageId: string, queryParams: WorkflowTaskQueryParam): Promise<AxiosResponse<Domain.Publisher.WorkflowTask[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const extraQueryString = getWorkflowTaskQueryString(queryParams);
        return HttpClient.get<Domain.Publisher.WorkflowTask[]>(`/api/publication/${publicationId}/page/${pageId}/task${extraQueryString}`, config);
    };

    /**
     * Gets a list of tasks for all controls for all workflow states for a publication page in a puiblication.
     *
     * @param publicationId Defines the unique identifier of the publication.
     */
    // public static getPublicationtWorkflowTask = async (publicationId: string, includeComponentDetails: false, includeStateDetails: false, includeTaskDetails: false): Promise<AxiosResponse<Domain.Publisher.WorkflowTask[]>> => {
    //     const config = await DataAccessHostUtils.getPubRequestConfig();
    //     const extraQueryString = includeComponentDetails || includeStateDetails || includeTaskDetails
    //         ? `?includeComponentDetails=${includeComponentDetails}&includeStateDetails=${includeStateDetails}&includeTaskDetails=${includeTaskDetails}`
    //         : '';
    //     return HttpClient.get<Domain.Publisher.WorkflowTask[]>(`/api/publication/${publicationId}/task${extraQueryString}`, config);
    // };

    /**
     * Complete a workflow task.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param taskId Defines the unique identifier of the task.
     */
    public static CompleteWorkflowTask = async (publicationId: string, taskId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<void>(`/api/publication/${publicationId}/workflow/task/${taskId}/complete`, {}, config);
    };

    /**
     * Reopen a workflow task.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param taskId Defines the unique identifier of the task.
     */
    public static ReopenWorkflowTask = async (publicationId: string, taskId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<void>(`/api/publication/${publicationId}/workflow/task/${taskId}/reopen`, {}, config);
    };


    /**
     * Gets the tasks of the current user for the whole publication.
     * @param publicationId Defines the unique identifier of the publication.
     * @returns The list of tasks.
     */
    public static getCurrentUserActiveTasks = async (publicationId: string): Promise<AxiosResponse<Domain.Publisher.WorkflowTask[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        const activeCurrentParams: WorkflowTaskQueryParam = {
            includePageDetails: false,
            includeComponentDetails: true,
            includeStateDetails: true,
            includeUserDetails: false,
            onlyActive: true,
            onlyNotCompleted: true,
            onlyCurrentUser: true,
        };

        return HttpClient.get<Domain.Publisher.WorkflowTask[]>(`/api/publication/${publicationId}/task${getWorkflowTaskQueryString(activeCurrentParams)}`, config);
    };


    /**
     * Get the list of workflow item ids for a publication that have no open tasks in the current step.
     * @param publicationId The id of the publication.
     * @returns Returns a collection of ids.
     */
    public static getComponentsWithNoOpenTasks = async (publicationId: string): Promise<AxiosResponse<string[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<string[]>(`/api/publication/${publicationId}/workflow/blocked`, config);
    };

    /**
     * Gets a list of summary of the users workflow tasks.
     */
    public static getWorkflowTaskSummary = async (): Promise<AxiosResponse<Domain.Publisher.WorkflowTaskSummary[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.WorkflowTaskSummary[]>(`/api/publication/workflow/summary`, config);
    };
}

const getWorkflowTaskQueryString = (params: WorkflowTaskQueryParam): string => {
    return params
        ? `?${Object.keys(params)
            .map((key) => `${key}=${params[key]}`)
            .join('&')}`
        : '';
};
export default WorkflowTask;
