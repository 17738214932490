export const palette = {
    primary1: '#30379D',
    primary2: '#4B55F6',
    primary3: '#F1F2FD',
    primary4: '#F8F9FE',
    primary1b: '#2934CC',
    primary1c: '#311B92',
    gradient1: '#4BEFF6',
    validationNeg: '#FF3366',
    validationPos1: '#68A350',
    validationPos2: '#8BC770',
    grey1: '#000000',
    grey2: '#414141',
    grey3: '#8A8991',
    grey3b: '#BCBCC6',
    grey3c: '#BCBCC680',
    grey4: '#F4F4F8',
    grey5: '#DEDEDE',
    white: '#FFFFFF',
    danger1: '#DB1e4D',
    danger2: '#F36',
    darkBlue: '#15195F',
    melrose: '#A8ADFF',
    melrose2: '#B39DDB',
    transparent: 'transparent',
    primary2semitransparent: '#4B55F60D',
    blue: '#0D47A1',
    lightBlue: '#90CAF9',
    lightGreen: '#A5D6A7',
    darkGreen: '#1B5E20',
    green: '#2c9934',
    lightGreen2: '#34861A15',
    darkGreen2: '#34861A',
    lightRed: '#EF9A9A',
    red: '#B71C1C',
    yellow: '#B5962D',
    lightYellow: '#F7FFAA',
    darkBrown: '#3E2723',
    lightBrown: '#BCAAA4',
    darkOrange: '#BF360C',
    lightOrange: '#FFAB91',
    warningOrange: '#FFA500',
    darkPink: '#880E4F',
    lightPink: '#F48FB1',
    lightRed2: '#98424214',
    darkRed: '#984242',
    darkSeaGreen: '#21B6A8',
    lightSeaGreen: '#C5ECE8',

};
