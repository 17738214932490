import React, { ReactNode } from 'react';
import { BasicText, Heading3, Section } from '../primitives';
import Styled from './index.styled';

type Props = {
    readonly title: string,
    readonly text: string,
    readonly footer?: ReactNode,
}

/**
 * Text with image widget for cockpit
 */
export const TextImageWidget = ({ title, text, footer }: Props) => {

    return (
        <Section look='white' grid={true} colSpan={3} rowSpan={2} widget={true}>
            <Styled.Content>
                <Styled.ThumbnailWrap>
                    <Styled.Thumbnail />
                </Styled.ThumbnailWrap>
                <Styled.TextWrap>
                    <Heading3>{title}</Heading3>
                    <BasicText>{text}</BasicText>
                    {footer || (
                        <BasicText>
                            Lees meer over de volledige <a href='https://inergy.nl/voor-overheden/' target='_blank' rel='noopener noreferrer'>LIAS product portfolio</a>, 
                            of bekijk de <a href='https://inergy.nl/academy/' target='_blank' rel='noopener noreferrer'>trainingen van Inergy</a>.
                        </BasicText>
                    )}
                </Styled.TextWrap>
            </Styled.Content>
        </Section>
    );
};
