import React from 'react';
import styled, { css } from 'styled-components';
import { WrapperSubNav } from './subnav';
import { px } from '../style/pixel';
import { childrenWithProps } from '../react/childrenWithProps';

export interface WrapperProps {
    readonly navigation?: 'auto' | boolean;
    readonly children: React.ReactNode;
}

export const Wrapper = ({ children, navigation = `auto` }: WrapperProps) => {
    const types = React.Children.map(children, (x) => React.isValidElement(x) && x.type)!;
    const hasNavigation = navigation === `auto` ? types.includes(WrapperSubNav) : navigation;
    return <MainWrap navigation={hasNavigation}>{childrenWithProps(children, { navigation: hasNavigation })}</MainWrap>;
};

type RenderProps = {
    navigation: boolean;
};

const constructGridTemplate = (props: RenderProps) => {
    if (props.navigation) {
        return css`
            grid-template-rows: min-content min-content auto;
            grid-template-columns: minmax(0, auto) min-content minmax(auto, ${px(2560)}) minmax(0, auto);
        `;
    } else {
        return css`
            grid-template-rows: min-content min-content auto;
            grid-template-columns: minmax(0, auto) minmax(auto, ${px(2560)}) minmax(0, auto);
        `;
    }
};

const MainWrap = styled.div<RenderProps>`
    position: relative;
    display: grid;
    min-height: 100%;
    ${constructGridTemplate}
`;
