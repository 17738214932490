import { createStore, combineReducers, applyMiddleware } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

// import reducers:
import TenantReducer from './reducers/Shared/Tenant';
import AttachmentsReducer from './reducers/Shared/Attachments';
import ElementDefinitionsReducer from './reducers/Shared/ElementDefinitions';
import ModulesReducer from './reducers/Shared/Modules';
import WorkflowTemplatesReducer from './reducers/Shared/WorkflowTemplates';
import UsersReducer from './reducers/Shared/Users';
import MeasureMomentsReducer from './reducers/Shared/MeasureMoments';
import HiererchyDefinitionsReducer from './reducers/Shared/HierarchyDefinitions';
import UserGroupReducer from './reducers/Shared/UserGroups';
import PatchesReducer from './reducers/Publisher/Patches';
import SiteDesignReducer from './reducers/Publisher/SiteDesigns';
import FinanceSettingsReducer from './reducers/Finance/Settings';
import FinanceBaseYearReducer from './reducers/Finance/BaseYears';
import FinanceBudgetElementGroupTagReducer from './reducers/Finance/BudgetElementGroupTag';
import TextAssistantReducer from './reducers/Shared/TextAssistant';
import SvgIconReducer from './reducers/Shared/Icons';
import AnnouncementReducer from './reducers/Shared/Announcements';
import StructuresReducer from './reducers/Finance/Structures';
import BudgetJournalGroupsReducer from './reducers/Finance/BudgetJournalGroups';
import StructureNodeReducer from './reducers/Finance/StructureNode';
import JournalElementKindReducer from './reducers/Finance/JournalElementKind';

import State, { PublisherState } from './state';
import { FinanceState } from './state/FinanceState';
import { useDispatch } from 'react-redux';

const publisherReducer = combineReducers<PublisherState>({
    patches: PatchesReducer,
    siteDesigns: SiteDesignReducer
});

const performanceReducer = (state = null): any => state;
const studioReducer = (state = null): any => state;

const financeReducer = combineReducers<FinanceState>({
    settings: FinanceSettingsReducer,
    budgetElementGroupTags: FinanceBudgetElementGroupTagReducer,
    baseYears: FinanceBaseYearReducer,
    structures: StructuresReducer,
    budgetJournalGroups: BudgetJournalGroupsReducer,
    structureNodes: StructureNodeReducer,
    journalElementKinds: JournalElementKindReducer,
});

const combinedReducer = combineReducers<State>({
    announcements: AnnouncementReducer,
    tenant: TenantReducer,
    attachments: AttachmentsReducer,
    elementdefinitions: ElementDefinitionsReducer,
    modules: ModulesReducer,
    workflowtemplates: WorkflowTemplatesReducer,
    publisher: publisherReducer,
    performance: performanceReducer,
    studio: studioReducer,
    users: UsersReducer,
    measuremoments: MeasureMomentsReducer,
    hierarchydefinitions: HiererchyDefinitionsReducer,
    usergroups: UserGroupReducer,
    finance: financeReducer,
    textAssistantSkills: TextAssistantReducer,
    icons: SvgIconReducer
});

// DEBUGGING: to use Redux Devtools, use composeEnhancers(applyMiddleware(thunk)) instead of just applyMiddleware below
// const composeEnhancers = window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'];

const store = createStore(combinedReducer, undefined, applyMiddleware(thunk));
export { store };
export type AppDispatch = typeof store.dispatch;

//https://stackoverflow.com/a/73151014/286121
export const useAppDispatch = () => useDispatch<AppDispatch>();