import styled, { css } from "styled-components";

const gridHeightCss = (gridOffset?: number) => {
    return css`
        height: ${gridOffset ? `calc(65vh - ${gridOffset}px)` : '65vh'}; 
        max-height: ${gridOffset ? `calc(65vh - ${gridOffset}px)` : '65vh'}; 
        overflow: auto;
    `
};

export const StyledDraggableWrapper = styled.div<{ dragEnabled?: boolean, gridOffset?: number }>`
    ${(p) => p.dragEnabled && gridHeightCss(p.gridOffset)}    
`;
