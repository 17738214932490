import React, { useEffect, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { Dictionary } from 'lodash';
import { ResetZIndex, Section } from '@liasincontrol/ui-basics';
import * as Styled from './index.styled';
import { DxTemplate, DxTreeList, DxColumn, DxRemoteOperations } from '@liasincontrol/ui-devextreme';
import { SystemElementDefinitions } from '@liasincontrol/domain';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import { palette } from '@liasincontrol/ui-basics';
import { ToolboxIcon } from '../../../../../../components/Renderer/Element/Containers/_shared/Placeholder'
import { TreeUtils } from '../../../../../../helpers';

type ControlTaskItem = {
    id: string,
    elementId: string,
    elementName: string,
    parentId: string,
    hasChildren: boolean,
    elementDefinitionSystemId: Domain.SystemElementDefinitions.Pub,
    currentStateName?: string,
    pageId?: string,
    order?: number,
};

type Props = {
    tasks: Domain.Publisher.WorkflowTask[],
    publicationId: string,
    elementDefinitions: Dictionary<Domain.Shared.ElementDefinition>,
    sitemap?: Domain.Publisher.Sitemap,
    onRefresh: () => void,
    onTaskSelected: (pageId: string, controlId: string) => void,
    error?: Domain.Shared.ErrorInfo,
};

type PagePathPart = Domain.Publisher.SitemapNode & {
    parentId: string
};

/**
 * Represents a UI component that renders a list of tasks.
 */
export const Tasks: React.FC<Props> = (props) => {
    const [tasksTree, setTasksTree] = useState<ControlTaskItem[]>([]);

    const homepageId = props.sitemap?.node?.elementId;

    useEffect(() => {
        if (!props.sitemap || !props.tasks || !homepageId) {
            return;
        }

        const pagesWithTasks = new Set<string>();
        const controlTasks: ControlTaskItem[] = [];

        props.tasks.forEach(task => {
            controlTasks.push({
                id: task.taskId,
                elementId: task.componentId,
                elementDefinitionSystemId: task.componentElementDefinitionSystemId as Domain.SystemElementDefinitions.Pub,
                elementName: task.componentName,
                parentId: task.pageId,
                hasChildren: false,
                currentStateName: task.currentStateName,
                pageId: task.pageId,
                order: task.order,
            });

            pagesWithTasks.add(task.pageId);
        });

        const orderedPagesWithTasks = [props.sitemap.node, ...props.sitemap.node.children].flatten((item) => item.children)
            .sort(node => node.order)
            .filter(page => pagesWithTasks.has(page.elementId))
            .map(page => page.elementId);


        const pagesAddedToTree: string[] = [];
        for (const pageId of orderedPagesWithTasks) {
            if (!pagesAddedToTree.find(id => id === pageId)) {
                const pagePathParts = TreeUtils.findFullPathWithParentId<Domain.Publisher.SitemapNode, PagePathPart>(props.sitemap.node, pageId);
                pagePathParts.forEach(page => {
                    if (!pagesAddedToTree.find(id => id === page.elementId)) {
                        controlTasks.push({
                            id: page.elementId,
                            elementId: page.elementId,
                            elementDefinitionSystemId: Domain.SystemElementDefinitions.Pub.Page,
                            elementName: page.elementName,
                            hasChildren: true,
                            parentId: page.parentId,
                        });
                        pagesAddedToTree.push(page.elementId);
                    }
                });
            }
        }

        setTasksTree(controlTasks?.sort((a, b) => a.order - b.order));
    }, [props.sitemap, props.tasks]);

    const getComponentName = (systemId: string) => {
        const element = Object.values(props.elementDefinitions).find((ed: Domain.Shared.ElementDefinition) => ed.systemId === systemId);
        return element?.label || element?.name || 'N/A';
    };

    const getNameTemplate: React.FC<{ data: ControlTaskItem }> = ({ data }) => {
        let icon: JSX.Element = (<InsertDriveFileOutlinedIcon />);
        if (data.elementDefinitionSystemId !== SystemElementDefinitions.Pub.Page && data.elementDefinitionSystemId) {
            return (
                <Styled.Wrapper withBackGround={false} withPadding={true}>
                    <Styled.ColoredToolboxIconWrapper color={palette.primary2}><ToolboxIcon systemId={data.elementDefinitionSystemId} /></Styled.ColoredToolboxIconWrapper>
                    <Styled.Label>{getComponentName(data.elementDefinitionSystemId)} - {data.elementName || 'Geen naam'}</Styled.Label>
                </Styled.Wrapper>);
        } else if (data.elementId === homepageId) {
            icon = (<HomeOutlinedIcon />);
        } else if (data.hasChildren) {
            icon = (<FolderOutlinedIcon />);
        }

        return (
            <Styled.Wrapper withBackGround={false} withPadding={true}>
                <Styled.ColoredToolboxIconWrapper>{icon}</Styled.ColoredToolboxIconWrapper>
                <Styled.Label>{data.elementName}</Styled.Label>
            </Styled.Wrapper>);
    };

    const getStatusTemplate: React.FC<{ data: ControlTaskItem }> = ({ data }) => {
        return data.elementDefinitionSystemId && data.elementDefinitionSystemId === SystemElementDefinitions.Pub.Page ? null : <>{data.currentStateName}</>;
    };

    return (<ResetZIndex>
        <Section look='white'>
            {tasksTree && tasksTree.length
                ? <DxTreeList
                    id='task-list'
                    dataSource={tasksTree}
                    showBorders={false}
                    showColumnLines={false}
                    showRowLines={true}
                    columnAutoWidth={true}
                    keyExpr='id'
                    parentIdExpr='parentId'
                    hasItemsExpr='hasChildren'
                    noDataText='Geen gegevens beschikbaar'
                    loadPanel={{
                        text: 'Laden...'
                    }}
                    rootValue={'0'}
                    onRowClick={({ data }) => {
                        if (data && !data.hasChildren && data.pageId) {
                            props.onTaskSelected(data.pageId, data.elementId);
                        }
                    }}
                    defaultExpandedRowKeys={[props.sitemap.node.elementId]}
                    scrolling={{ mode: 'standard' }}
                >
                    <DxRemoteOperations filtering={true} />
                    <DxColumn
                        caption="Pagina's en pagina-onderdelen"
                        dataField='elementName'
                        allowSorting={false}
                        allowFiltering={false}
                        cellTemplate='nameTemplate'
                    />
                    <DxColumn
                        caption="Workflow stap"
                        allowSorting={false}
                        cellTemplate='statusTemplate'
                        width={'20%'}
                    />

                    <DxTemplate name='nameTemplate' render={getNameTemplate} />
                    <DxTemplate name='statusTemplate' render={getStatusTemplate} />
                </DxTreeList>
                : <></>}
        </Section>
    </ResetZIndex>);
};
