import { Element } from '../../Publisher/Element/index';
import { fieldDefinitionDecorator, LinkedStructure, SystemFieldDefinitions } from '../../Shared';

/**
 * Represents a studio hierarchy item.
 */
class HierarchyItem {

    public element: Element;

    public parentHierarchyItemId?: string;

    public order: number;

    public workflowStateId?: string;

    public assignedUserId?: string;

    public structureNodes?: LinkedStructure[];

}

/**
 * Represents a generic studio hierarchy item element.
 */
class HierarchyItemElement {

    id: string;

    parentId?: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Studio.Number)
    number: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Studio.Name)
    name: string;
}

export { HierarchyItem, HierarchyItemElement };