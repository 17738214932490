import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import Add from '@mui/icons-material/Add';
import { ApiErrorReportingHelper, FormMode } from '@liasincontrol/core-service';
import { Finance } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import { Bar, Button, ErrorOverlay, Heading2, PageTitle, Section, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { ContextMenu, createSource, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { AjaxRequestStatus, State, FinanceBudgetTagsActionCreator, ActionSource, FinanceBaseYearsActionCreator } from '@liasincontrol/redux-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, Actions } from '@liasincontrol/userrights-service';
import { ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';
import { BudgetElementGroupForm } from './BudgetElementGroupForm';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the page with the list of budget element groups.
 */
const BudgetElementGroups: React.FC<Props> = (props) => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [formDialog, setFormDialog] = useState<{
        isVisible: boolean,
        isSaving: boolean,
        formMode: FormMode,
        entity: Domain.Finance.BudgetElementGroup
    }>({ isVisible: false, isSaving: false, formMode: FormMode.View, entity: undefined });

    // #region Grid state...

    const getBudgetElementGroup = (id: string, formMode: FormMode) => {
        Finance.BudgetElementGroupDataAccessor.get(id)
            .then((response) => {
                setFormDialog({
                    ...formDialog,
                    isVisible: true,
                    formMode: formMode || FormMode.View,
                    entity: {
                        ...response.data,
                    }
                })
            });
    };

    const deleteBudgetElementGroup = (budgetElementGroup: Domain.Finance.BudgetElementGroup) => {
        if (confirmDeleteModal.inProgress) return;

        setConfirmDeleteModal({ visible: true, budgetElementGroup: budgetElementGroup, inProgress: true });
        Finance.BudgetElementGroupDataAccessor.delete(budgetElementGroup.id)
            .then(() => {
                setLastRefresh(Date.now());
                props.fetchAvailableTags(budgetElementGroup.baseYear);
            }).catch((exception) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, exception, true));
            }).finally(() => {
                setConfirmDeleteModal({ visible: false, budgetElementGroup: undefined, inProgress: false });
            });
    };

    const availableColumns: GridColumn<Domain.Finance.BudgetElementGroup>[] = getColumnConfiguration(
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance)
            ? (budgetElementGroup: Domain.Finance.BudgetElementGroup) => getBudgetElementGroup(budgetElementGroup.id, FormMode.Edit)
            : undefined,
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance)
            ? (budgetElementGroup: Domain.Finance.BudgetElementGroup) => getBudgetElementGroup(budgetElementGroup.id, FormMode.Clone)
            : undefined,
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance)
            ? (budgetElementGroup: Domain.Finance.BudgetElementGroup) => setConfirmDeleteModal({ visible: true, budgetElementGroup: budgetElementGroup, inProgress: false })
            : undefined
    );

    const customDataSource = useMemo(() => {
        return createSource({
            keyExpr: "id",
            paginate: true,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            dataSourcePromise: Finance.BudgetElementGroupDataAccessor.getAll
        });
    }, [lastRefresh]);

    const [confirmDeleteModal, setConfirmDeleteModal] = useState<{ visible: boolean, budgetElementGroup: Domain.Finance.BudgetElementGroup, inProgress: boolean }>({ visible: false, budgetElementGroup: undefined, inProgress: false });
    // #endregion...

    const baseYears = useMemo(() => {
        return props.baseYears.items;
    }, [props.baseYears]);

    useEffect(() => {
        if (formDialog.entity?.baseYear === undefined || formDialog.entity?.baseYear === null) {
            return;
        }

        if (!props.finance.availableTags[formDialog.entity?.baseYear] || props.finance.availableTags[formDialog.entity?.baseYear].status === AjaxRequestStatus.NotSet) {
            props.fetchAvailableTags(formDialog.entity?.baseYear);
            return;
        }
    }, [formDialog.entity?.baseYear]);

    if (props.baseYears.status === AjaxRequestStatus.NotSet) {
        props.fetchBaseYears();
        return null;
    }

    const onSave = (budgetElementGroup: Domain.Finance.BudgetElementGroup, mode: FormMode) => {
        setFormDialog({ ...formDialog, isSaving: true });
        const getPromise = (mode: FormMode) => {
            switch (mode) {
                case FormMode.AddNew:
                    return Finance.BudgetElementGroupDataAccessor.create(budgetElementGroup);
                case FormMode.Edit:
                    return Finance.BudgetElementGroupDataAccessor.update(budgetElementGroup)
                case FormMode.Clone:
                    return Finance.BudgetElementGroupDataAccessor.clone(budgetElementGroup);
                default:
                    return;
            }
        };

        getPromise(mode).then(() => {
            setLastRefresh(Date.now());
            props.fetchAvailableTags(budgetElementGroup.baseYear);
        }).catch((exception) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(budgetElementGroup.id ? ApiErrorReportingHelper.GenericMessages.Saving : ApiErrorReportingHelper.GenericMessages.Adding, exception);
            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.CodeNotUniqueBaseYear)) {
                setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.CodeNotUniqueBaseYear] });
            } else if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.SourceGroupNotFound)) {
                setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.SourceGroupNotFound] });
            } else {
                setError(errorInfo);
            }
        }).finally(() => {
            setFormDialog({ ...formDialog, isSaving: false, formMode: FormMode.View, isVisible: false });
        });
    };

    return (
        <>
            <Wrapper>
                <WrapperContent>
                    <PageTitle>
                        <Heading2>Budgetelementgroepen</Heading2>
                    </PageTitle>
                    <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        <Bar look='toolbar'>
                            <Bar start>
                                <Button
                                    id='btn-add-new-budgetElementGroup'
                                    btnbase='textbuttons'
                                    btntype='medium_icon'
                                    icon={<Add />}
                                    onClick={() => setFormDialog({
                                        ...formDialog,
                                        isVisible: true,
                                        formMode: FormMode.AddNew,
                                        entity: {
                                            ...new Domain.Finance.BudgetElementGroup(),
                                            name: '',
                                        }
                                    })}
                                >
                                    Nieuw
                                </Button>
                            </Bar>
                        </Bar>
                        <Section look='white'>
                            <LsGrid
                                allowColumnResizing={true}
                                showRowLines={true}
                                paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                                dataSource={customDataSource}
                                columns={availableColumns}
                                onDataError={(e) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, e))}
                                onClickRow={(item) => getBudgetElementGroup(item.id, FormMode.Edit)}
                            />
                        </Section>
                    </ErrorOverlay>
                </WrapperContent>
            </Wrapper>
            {formDialog.isVisible && <BudgetElementGroupForm
                budgetElementGroup={formDialog.entity}
                baseYears={baseYears}
                availableTags={props.finance.availableTags[formDialog.entity?.baseYear]?.items}
                isBusy={formDialog.isSaving}
                formMode={formDialog.formMode}
                onSave={(entity, mode) => {
                    onSave(entity, mode);
                }}
                onCancel={() => setFormDialog({ entity: undefined, isVisible: false, formMode: FormMode.View, isSaving: false })}
                onError={setError}
            />
            }
            {confirmDeleteModal.visible &&
                <ModalDialog
                    id='confirm-budget-element-group-delete-dialog'
                    settings={{
                        look: 'message',
                        title: 'Budgetelementgroep verwijderen',
                        footer: <ModalDialogFooter leftButtonText='Annuleren'
                            onLeftButtonClick={() => setConfirmDeleteModal({ visible: false, budgetElementGroup: undefined, inProgress: false })}
                            rightButtonText='Verwijderen'
                            onRightButtonClick={() => deleteBudgetElementGroup(confirmDeleteModal.budgetElementGroup)} />
                    }}
                >
                    <Text value='Weet u zeker dat u de groep wilt verwijderen?' />
                </ModalDialog>
            }

        </>
    );
};

const getColumnConfiguration = (
    onEdit?: (item: Domain.Finance.BudgetElementGroup) => void,
    onCopy?: (item: Domain.Finance.BudgetElementGroup) => void,
    onDelete?: (item: Domain.Finance.BudgetElementGroup) => void
): GridColumn<Domain.Finance.BudgetElementGroup>[] => {
    return [{
        name: 'name',
        title: 'Naam',
        width: '40%',
        align: 'left',
    }, {
        name: 'baseYear',
        title: 'Basisjaar',
        width: '25%',
        align: 'left',
    }, {
        name: 'code',
        title: 'Code',
        width: '25%',
        align: 'left',
    }, {
        name: 'id',
        title: '',
        type: 'buttons',
        width: '5%',
        align: 'right',
        hideInColumnChooser: true,
        renderCustom: ({ data }) => (
            (onEdit || onCopy || onDelete) &&
            <ContextMenu<Domain.Finance.BudgetElementGroup>
                item={data}
                keyExpr='id'
                actions={[
                    {
                        action: onEdit,
                        actionName: `edit-elementgroup-${data.id}`,
                        displayName: 'Bewerken',
                        ariaLabel: `Bewerk ${data.name}`,
                        hidden: !onEdit
                    },
                    {
                        action: onCopy,
                        actionName: `copy-elementgroup-${data.id}`,
                        displayName: 'Kopie maken',
                        ariaLabel: `Kopiëren ${data.name}`,
                        hidden: !onCopy
                    },
                    {
                        action: onDelete,
                        actionName: `delete-elementgroup-${data.id}`,
                        displayName: 'Verwijderen',
                        ariaLabel: `Verwijderen ${data.name}`,
                        hidden: !onDelete
                    }
                ]}
            />
        )
    }];
};

const mapStateToProps = (state: State) => {
    return {
        finance: {
            availableTags: state.finance.budgetElementGroupTags,
        },
        baseYears: {
            items: state.finance.baseYears.items,
            status: state.finance.baseYears.status,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchAvailableTags: (baseYear: number) => {
            dispatch(FinanceBudgetTagsActionCreator.set({ source: ActionSource.Financial, data: { baseYear: baseYear } }));
        },
        fetchBaseYears: () => {
            dispatch(FinanceBaseYearsActionCreator.set())
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(BudgetElementGroups);
export { Component as index };
