import React from 'react';
import { palette } from '../styleguide';

export const LayoutField = ({ left, top, width, height, textAlign = 'left', verticalAlign = 'top', borderBottom = false, borderRight = false,  fullHeight = false, 
    compactBorderBottom = false, className, children }: ILayoutFieldProps) => {
    return (
        <div
            className={className}
            style={{
                gridColumnStart: left,
                gridColumnEnd: left + width,
                gridRowStart: top,
                gridRowEnd: top + height,
                textAlign: textAlign,
                borderRight: borderRight ? `2px solid ${palette.grey4}` : `none`,
                height: fullHeight ? `100%` : `auto`,
                placeItems: verticalAlign === 'middle' ? `center` : verticalAlign === 'bottom' ? `end` : `start`,
                display: verticalAlign === 'middle' || verticalAlign === 'bottom' ? `flex` : `initial`,
            }}>
            {children}
            {borderBottom && <div style={{ height: compactBorderBottom ? '1rem' : '3rem', borderBottom: `2px solid ${palette.grey4}`, marginBottom: compactBorderBottom && '20px' }} />}
        </div>
    );
};

export interface ILayoutFieldProps {
    readonly left: number;
    readonly top: number;
    readonly width: number;
    readonly height: number;
    readonly borderBottom?: boolean;
    readonly compactBorderBottom?: boolean;
    readonly borderRight?: boolean;
    readonly textAlign?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent';
    readonly verticalAlign?: 'top' | 'bottom' | 'middle';
    readonly fullHeight?: boolean;
    readonly className?: string;
    readonly children: React.ReactNode;
}
