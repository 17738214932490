
/* localisation: messages for validator */

export const Dictionary = {
    "nl": {
        "validation-required": "Verplicht",
        "validation-required-formatted": "{0} is verplicht",
        "validation-numeric": "Waarde moet numeriek zijn",
        "validation-numeric-formatted": "{0} moet numeriek zijn",
        "validation-range": "Waarde is niet binnen het bereik",
        "validation-range-formatted": "{0} is niet binnen het bereik",
        "validation-stringLength": "De lengte van de waarde is niet correct",
        "validation-stringLength-formatted": "De lengte van {0}  is niet correct",
        "validation-custom": "Waarde is ongeldig",
        "validation-custom-formatted": "{0} is ongeldig",
        "validation-async": "Waarde is ongeldig",
        "validation-async-formatted": "{0} is ongeldig",
        "validation-compare": "Waardes komen niet overeen",
        "validation-compare-formatted": "{0} komen niet overeen",
        "validation-pattern": "Waarde komt niet overeen met het patroon",
        "validation-pattern-formatted": "{0} komt niet overeen met het patroon",
        "validation-email": "Email is niet geldig",
        "validation-email-formatted": "{0} is niet geldig",
        "validation-mask": "Waarde is niet geldig",

        "dxNumberBox-invalidValueMessage": "Waarde moet een nummer zijn",
        "dxNumberBox-noDataText": "Geen gegevens",
    }
};