import React from 'react';
import parse from 'html-react-parser';
import Styled from './index.styled';
import { Props } from './index.props';

/**
 * Represents a UI component that renders a HTML viewer.
 */
export const HtmlViewer: React.FC<Props> = (props) => {
    const isStringOrStringifiable = value =>  
        typeof value === 'string' || 
        (typeof value === 'number' && !isNaN(value)) ||
        (value != null && typeof value === 'object' && typeof value.toString === 'function');
    const htmlParsed = parse(isStringOrStringifiable(props?.value) ? props.value.toString() : '');
    return (
        <Styled.HtmlWrapper className={props.className} style={props.style}>
            {htmlParsed}
        </Styled.HtmlWrapper>
    );
};
