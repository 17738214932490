import React, { useEffect, useState } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { FormData, FormHelper, FormMode, ValidatorsDictionary, ValueType } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ModalDialog, ModalDialogFooter, palette, WarningLook, WarningWrapper } from '@liasincontrol/ui-basics';
import { ElementDefinitionForm } from '../ElementDefinitionForm';

type Props = {
    elementDefinition?: Domain.Shared.ElementDefinition,
    elementDefinitionNames: string[],
    disableSaveButton: boolean,
    moduleId: string,
    mode: FormMode,
    externalError?: { id: string, message: string },
    onSave: (elementDefinition: Domain.Dto.Shared.CreateElementDefinition, elementDefinitionId?: string) => void,
    onCancel: () => void,
    icons?: Record<string, Domain.Shared.SvgIcon>,
};

/**
 * Represents a UI component that renders the modal for creating or editing a element definition.
 */
export const ElementDefinitionModal: React.FC<Props> = (props) => {
    const [form, setForm] = useState<FormData<ValueType>>(initFormData(props.elementDefinition));
    const nameFieldDefinition = Domain.FieldDefinitions.Shared.nameFieldDefinition;
    const descriptionFieldDefinition = Domain.FieldDefinitions.Shared.descriptionFieldDefinition;
    const iconFieldDefinition = Domain.FieldDefinitions.Studio.ElementDefinition.iconFieldDefinition;
    const iconColorFieldDefinition = Domain.FieldDefinitions.Studio.ElementDefinition.iconColorFieldDefinition;

    useEffect(() => {
        if (!props.externalError) return;

        setForm((prevForm) => ({
            ...prevForm,
            isValid: false,
            validationErrors: {
                ...prevForm.validationErrors,
                [props.externalError.id]: [{ error: props.externalError.message, isExternal: true }]
            }
        }));
    }, [props.externalError]);

    const storeFormValue = (value: string, systemId: string, validators: ValidatorsDictionary, resetExternalErrorFieldSystemIds: string[] = []): void => {
        setForm((prevForm) => FormHelper.validateAndStoreFormValue<FormData<ValueType>>(prevForm, value, validators, systemId, resetExternalErrorFieldSystemIds));
    };

    const onSave = () => {
        const elementDefinition: Domain.Dto.Shared.CreateElementDefinition = {
            name: form.values[nameFieldDefinition.id] as string,
            description: form.values[descriptionFieldDefinition.id] as string,
            icon: form.values[iconFieldDefinition.id] as string,
            color: form.values[iconColorFieldDefinition.id] as string,
            moduleId: props.moduleId,
        }
        props.onSave(elementDefinition, props.elementDefinition?.id);
    }

    // #region JSX.Elements...
    const dialogFooter: JSX.Element = (
        <ModalDialogFooter
            leftButtonText='Annuleren'
            onLeftButtonClick={props.onCancel}
            rightButtonText='Opslaan'
            onRightButtonClick={onSave}
            rightButtonDisabled={!form.isValid || Object.keys(form.touched).length === 0 || props.disableSaveButton}
        />
    );
    // #endregion JSX.Elements...

    return (
        <ModalDialog
            settings={{
                look: 'interactive',
                title: props.mode === FormMode.AddNew ? 'Elementdefinitie aanmaken' : 'Elementdefinitie bewerken',
                width: 1024,
                footer: dialogFooter
            }}
        >
            {props.elementDefinition?.deleted &&
                <WarningWrapper
                    look={WarningLook.dangerInverted}
                    icon={<WarningAmberIcon />}
                    className='mb-100 p-025'
                    messageText='Elementdefinities is uitgeschakeld.' />
            }
            <ElementDefinitionForm
                elementDefinition={props.elementDefinition}
                elementDefinitionNames={props.elementDefinitionNames}
                mode={props.mode}
                onChange={storeFormValue}
                form={form}
                icons={props.icons}
            />
        </ModalDialog>
    );
};

const initFormData = (elementDefinition?: Domain.Shared.ElementDefinition): FormData<string> => {
    return {
        values: {
            [Domain.FieldDefinitions.Shared.nameFieldDefinition.id]: elementDefinition?.name || '',
            [Domain.FieldDefinitions.Shared.descriptionFieldDefinition.id]: elementDefinition?.description || '',
            [Domain.FieldDefinitions.Studio.ElementDefinition.iconFieldDefinition.id]: elementDefinition?.icon || Domain.Shared.DefaultIconId,
            [Domain.FieldDefinitions.Studio.ElementDefinition.iconColorFieldDefinition.id]: elementDefinition?.color || palette.grey2,
        },
        touched: {},
        validationErrors: {},
        isValid: true,
    };
};
