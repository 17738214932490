import { isEventKey } from 'keycode';
import React from 'react';
import { InputUnit as Presentation } from './Presentation';
import { IInputUnitProps } from './Props';

export interface IInputUnitState {
    readonly opened: boolean;
    readonly openedOnce: boolean;
}

export class InputUnit extends React.PureComponent<IInputUnitProps, IInputUnitState> {
    public state = {
        /**
         * Dropdown menu open.
         */
        opened: false,
        /**
         * Set to true once, after opening. Dropdown menu will remain rendered when true.
         */
        openedOnce: false,
    };

    public render() {
        const {
            error,
            success,
            disabled,
            focused,
            id,
            units,
            unit,
            value,
        } = this.props;
        const {
            opened,
            openedOnce,
        } = this.state;
        return (
            Presentation({
                error,
                success,
                disabled,
                focused,
                id,
                onBlur: this.onBlur,
                onChangeUnit: this.onChangeUnit,
                onChangeValue: this.onChangeValue,
                onFocusUnit: this.onFocusUnit,
                onFocusValue: this.onFocusValue,
                onToggle: this.toggleUnit,
                unit,
                value,
                units,
                onKeyPress: this.onKeyPress,
                opened,
                openedOnce,
            })
        );
    }

    private openUnit() {
        if (!this.state.opened) {
            this.setState({ opened: true, openedOnce: true }, () => {
                if (this.props.onOpened) {
                    this.props.onOpened();
                }
            });
        }
    }

    private closeUnit() {
        if (this.state.opened) {
            this.setState({ opened: false }, () => {
                if (this.props.onClosed) {
                    this.props.onClosed();
                }
            });
        }
    }

    private toggleUnit = () => {
        if (this.state.opened) {
            this.closeUnit();
        } else {
            this.openUnit();
        }
    }

    private onChangeUnit = (unit: string) => {
        this.closeUnit();
        if (this.props.onChange) {
            this.props.onChange(unit, this.props.value);
        }
    }

    private onChangeValue = (value: string) => {
        if (this.props.onChange) {
            this.props.onChange(this.props.unit, value);
        }
    }

    private onFocusUnit = () => {
        this.openUnit();
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    }

    private onFocusValue = () => {
        this.closeUnit();
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    }

    private onBlur = () => {
        this.closeUnit();
        if (this.props.onBlur) {
            this.props.onBlur();
        }
    }

    private onKeyPress = (e: React.KeyboardEvent<any>) => {
        //   Home: First item
        //    End: Last item
        //  Space: Open if closed
        //     Up: Previous or last item
        //   Down: Next or first item
        //  Enter: Close if open
        // Escape: Close if open
        if (isEventKey(e as any, 'home')) {
            const key = this.props.units[0].key;
            this.props.onChange(key, this.props.value);
            return e.preventDefault();
        }
        if (isEventKey(e as any, 'end')) {
            const key = this.props.units[this.props.units.length - 1].key;
            this.props.onChange(key, this.props.value);
            return e.preventDefault();
        }
        if (isEventKey(e as any, 'space')) {
            if (!this.state.opened) {
                this.openUnit();
                return e.preventDefault();
            }
        }
        if (isEventKey(e as any, 'up')) {
            if (this.state.opened) {
                const idx = this.props.units.findIndex((item) => item.key === this.props.unit);
                const idxNew = idx <= 0 ? this.props.units.length - 1 : idx - 1;
                const key = this.props.units[idxNew].key;
                this.props.onChange(key, this.props.value);
                return e.preventDefault();
            } else {
                this.openUnit();
                return e.preventDefault();
            }
        }
        if (isEventKey(e as any, 'down')) {
            if (this.state.opened) {
                const idx = this.props.units.findIndex((item) => item.key === this.props.unit);
                const idxNew = ((idx + 1) % this.props.units.length);
                const key = this.props.units[idxNew].key;
                this.props.onChange(key, this.props.value);
                return e.preventDefault();
            } else {
                this.openUnit();
                return e.preventDefault();
            }
        }
        if (isEventKey(e as any, 'enter')) {
            if (this.state.opened) {
                this.closeUnit();
                return e.preventDefault();
            }
        }
        if (isEventKey(e as any, 'escape')) {
            if (this.state.opened) {
                this.closeUnit();
                return e.preventDefault();
            }
        }
    }
}
