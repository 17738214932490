import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { EdgeToolbar, Bar, Button } from '@liasincontrol/ui-basics';

type Props = {
    look?: 'editor' | 'default',
    isVisible: boolean,
    onClose: () => void,
    onPreview: () => void,
    endActions: React.ReactNode
}

/**
 * Represents a UI component that renders the readonly toolbar of a publication page.
 */
export const ReadonlyToolbar: React.FC<Props> = (props) => {
    return (
        <EdgeToolbar edge="bottom" open={props.isVisible} look={props.look}>
            <Bar look="system">
                <Bar start>
                    <Button id="btn-cancel" btnbase="textbuttons" btntype="medium_icon" icon={<CloseIcon />} onClick={props.onClose}>
                        Sluiten
                    </Button>
                </Bar>
                <Bar end>
                    {props.endActions ? props.endActions : null}
                    <Button id="btn-preview" btnbase="iconbuttons" btntype="medium_background" icon={<VisibilityIcon />} onClick={props.onPreview} />
                </Bar>
            </Bar>
        </EdgeToolbar>
    );
};