import styled from 'styled-components';
import { px, cssHeading1, palette } from '@liasincontrol/ui-basics';

const Content = styled.div`
    grid-column: 2 / span 12;
    grid-row: 7 / -1;
    display: grid;
    grid-template-rows: ${px(20)} minmax(0, 60vh) ${px(20)};
    grid-gap: ${px(8)} ${px(20)};
    position: relative;
`;

const Title = styled.h2`
    ${cssHeading1}
    margin-bottom: 20px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${px(4)};
    height: 100%;
`;

const ContentAttachments = styled.div`
    padding: ${px(10)} ${px(5)};
    border: ${px(2)} solid ${palette.grey4};
    border-radius: ${px(6)};
    margin-top: ${px(10)};
    margin-bottom: ${px(5)};
`;

const styledComponents = { Content, Title, Wrapper, ContentAttachments };
export default styledComponents;