import { createAsyncThunk } from '@reduxjs/toolkit';
import { Shared as SharedDataAccess } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';

const action = createAsyncThunk<Domain.Shared.Announcement, void, {}>('/announcements/set', async () => {
    return SharedDataAccess.Announcements.getAnnouncement()
        .then((response) => response.data)
});

export default action;
