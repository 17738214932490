import React from 'react';
import { Label } from '@liasincontrol/ui-basics';
import { ColorPickerProps } from './index.props';
import { Dot } from './index.styled';

/**
 * Represents a UI component that renders a color picker viewer.
 */
export const ColorPickerViewer: React.FC<ColorPickerProps> = (props) => {
    return (
        <>
            {props.label && <Label id={`${props.id}-label`} htmlFor={props.id} text={props.label} />}
            <Dot id={props.id} backgroundColor={props.value ? props.value : props.defaultColor} />
        </>
    );
};