import * as Domain from '@liasincontrol/domain';

type DetailCardLayoutColumn = {
    width: number,
    fieldIds: string[],
};

type DetailCardLayout = {
    version: number,
    columns: DetailCardLayoutColumn[],
};

const defaultColumnWidths: number[] = [50, 25, 25];

const getDefaultDetailCard = (): Domain.Shared.DetailCard => {
    return { ...new Domain.Shared.DetailCard(), layout: buildDetailsCardLayoutString(3, [], defaultColumnWidths) };
};

const isTouched = (oldLayout: string, newLayout: DetailCardLayout) => {
    const oldLayoutDetailColumn = JSON.parse(oldLayout) as DetailCardLayout;

    for (let columnIndex = 0; columnIndex < oldLayoutDetailColumn.columns.length; columnIndex++) {
        if (newLayout.columns[columnIndex].fieldIds.length !== oldLayoutDetailColumn.columns[columnIndex].fieldIds.length) {
            return true;
        }
        for (let fieldIndex = 0; fieldIndex < oldLayoutDetailColumn.columns[columnIndex].fieldIds.length; fieldIndex++)
            if (newLayout.columns[columnIndex].fieldIds[fieldIndex] !== oldLayoutDetailColumn.columns[columnIndex].fieldIds[fieldIndex]) {
                return true;
            }
    }

    return false;
};

const buildDetailsCardLayoutString = (numberOfColumns: number, columnData: DetailCardLayoutColumn[], width = defaultColumnWidths, version = 1,): string => {
    const detailsCard: DetailCardLayout = {
        version: version,
        columns: []
    };
    for (let index = 0; index < numberOfColumns; index++) {
        detailsCard.columns.push({
            width: width && width.length === numberOfColumns ? width[index] : Math.round(1 / numberOfColumns),
            fieldIds: []
        });
    }

    if (columnData.length > 0) {
        for (let index = 0; index < columnData.length; index++) {
            detailsCard.columns[index].fieldIds = [...columnData[index].fieldIds];
        }
    }

    return JSON.stringify(detailsCard);
};

export { DetailCardLayout, DetailCardLayoutColumn, buildDetailsCardLayoutString, getDefaultDetailCard, isTouched };
