import { Button } from "@liasincontrol/ui-basics";
import React, { useState } from "react";
import Styled from './index.styled';

export type ExpandCollapseProps = {
    collapseAll: () => void;
    expandAll: () => void;
}

export const ExpandCollapse = (props: ExpandCollapseProps) => {
    const [expanded, setExpanded] = useState(false);

    const expandCollapse = () => {
        if (expanded)
            props.collapseAll()
        else
            props.expandAll()
        setExpanded(prev => !prev);
    }

    return <Styled.ExpandCollapseDiv>
        <Button
            btnbase="textbuttons"
            btntype="medium_expand_collapse"
            onClick={expandCollapse}
            className='collapse-expand-button'
        >
            {expanded ? 'Alles inklappen' : 'Alles uitklappen'}
        </Button>
    </Styled.ExpandCollapseDiv>
}