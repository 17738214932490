import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseContainerElement } from '../../BaseContainerElement';

class StackElement extends BaseContainerElement {

    constructor(direction?: string) {
        super();
        this.direction = direction;
    }

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.StackContainerDirection)
    direction: string;

}

export default StackElement;