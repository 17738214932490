import React, { useRef, useState } from "react";
import { useCallback } from "react";
import { withField1, WithFieldBaseType } from "@liasincontrol/ui-basics";
import Styled from './index.styled';

import { DropDownBoxTypes } from 'devextreme-react/drop-down-box';
import TreeView, { TreeViewTypes } from 'devextreme-react/tree-view';

export type DropDownFieldProps<V> = WithFieldBaseType<V> & {
    valueExpr?: keyof V,
    displayExpr?: keyof V,
    keyExpr?: keyof V,
    parentIdExpr?: keyof V,
    clearable?: boolean,
    searchable?: boolean,
    showDropDownButton?: boolean,
    openOnFieldClick?: boolean,
    placeholder?: string,
    optionItems: V[],
}

const ownerLabel = { 'aria-label': 'Struktur Node' };

export const DropDownField = <V,>(props: DropDownFieldProps<V>) => {
    const [treeBoxValue, setTreeBoxValue] = useState(props.keyExpr ? props.value?.[props.keyExpr] : props.value);
    const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(false);
    const treeViewRef = useRef(null);

    const treeViewItemSelectionChanged = useCallback((e) => {
        setTreeBoxValue(e.component.getSelectedNodeKeys());
        props.onChange?.(e.itemData);
    }, [props.onChange]);

    const treeViewOnContentReady = useCallback((e: TreeViewTypes.ContentReadyEvent) => e.component.selectItem(treeBoxValue), [treeBoxValue]);

    const onTreeItemClick = useCallback(() => setIsTreeBoxOpened(false), []);

    const syncTreeViewSelection = useCallback((e: DropDownBoxTypes.ValueChangedEvent) => {
        setTreeBoxValue(e.value);
        if (!treeViewRef.current) return;

        if (!e.value) {
            treeViewRef.current.instance().unselectAll();
            props.onChange?.(null);
        } else {
            treeViewRef.current.instance().selectItem(e.value);
        }
    }, []);

    const onTreeBoxOpened = useCallback((e: DropDownBoxTypes.OptionChangedEvent) => {
        if (e.name === 'opened') {
            setIsTreeBoxOpened(e.value);
        }
    }, []);

    const optionList = props.optionItems.map(i => ({ ...i, expanded: false, selected: false }));

    const HoC = withField1<V, DropDownFieldProps<V>>(
        () => <Styled.StyledDropDownBox
            value={treeBoxValue}
            opened={isTreeBoxOpened}
            displayExpr={!!props.displayExpr ? String(props.displayExpr) : undefined}
            valueExpr={!!props.valueExpr ? String(props.valueExpr) : undefined}
            inputAttr={ownerLabel}
            placeholder={props.placeholder}
            showClearButton={props.clearable}
            dataSource={optionList}
            onValueChanged={syncTreeViewSelection}
            onOptionChanged={onTreeBoxOpened}
            openOnFieldClick={props.openOnFieldClick}
        >
            <TreeView
                dataSource={optionList}
                ref={treeViewRef}
                dataStructure="plain"
                keyExpr={!!props.keyExpr ? String(props.keyExpr) : undefined}
                parentIdExpr={!!props.parentIdExpr ? String(props.parentIdExpr) : undefined}
                selectionMode="single"
                displayExpr={!!props.displayExpr ? String(props.displayExpr) : undefined}
                selectByClick={true}
                onItemClick={onTreeItemClick}
                onItemSelectionChanged={treeViewItemSelectionChanged}
                onContentReady={treeViewOnContentReady}
                searchEnabled={props.searchable}
            />
        </Styled.StyledDropDownBox>
    );

    return <HoC {...props} />
};
