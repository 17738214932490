import React from 'react';
import dxPieChart from 'devextreme/viz/pie_chart';
import { palette } from '@liasincontrol/ui-basics';

export const ChartCenterTemplate = (pieChart: dxPieChart, centerText?: string, textColor?: string, textFontSize?: string) => {
    const size = pieChart.getSize();
    const squaredLength = size && size.height! > size.width! ? size.width : size.height;

    const centerTextLinesCount = centerText && centerText.length ? centerText?.split("\n").length : 0;
    const centerX = squaredLength ? squaredLength / 2 : 0;
    const centerY = squaredLength ? squaredLength / 2 - ((centerTextLinesCount - 1) / 2) * 20 : 0;

    const generateLine = (text: string, index: number, size: string) => (
        <tspan key={index} x={centerX} dy={`${index ? +size + 2 : 0}px`} style={{ fontSize: +size }}>
            {text}
        </tspan>
    );

    return (
        <svg>
            <circle cx={centerX} cy={centerX} r={pieChart.getInnerRadius()} fill={palette.primary4}></circle>
            {centerText &&
                <text textAnchor="middle" x={centerX} y={centerY} style={{ fill: textColor ?? palette.grey2, overflow: 'hidden' }}>
                    {centerText.split("\n").map((line, index) => generateLine(line, index, textFontSize ?? '18'))}
                </text>
            }
        </svg>
    );
}
