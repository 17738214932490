import React, { useState, useEffect } from 'react';
import TextBox from "devextreme-react/text-box";
import { useNavigate } from 'react-router-dom';
import * as Domain from '@liasincontrol/domain';
import { FlexBox, IDataItemProps, Icon, IconSize, IconValue, SVGIcon } from '@liasincontrol/ui-basics';
import { Performance } from '@liasincontrol/data-service';
import { LsGrid, GridColumn } from '@liasincontrol/ui-devextreme';
import { FieldsHelper, IconHelper, isMomentOpen, useUserSettings } from '@liasincontrol/core-service';
import { IconHelper as PerformanceIconHelper } from '../../../helpers/IconHelper';
import Styled from './index.style';
import { SelectElement, SelectElementTemplateProps, } from '@liasincontrol/ui-elements';

const availableStatuses: string[] = [
    Domain.SystemFieldDefinitions.Performance.GoalStatus,
    Domain.SystemFieldDefinitions.Performance.AchievementStatus,
    Domain.SystemFieldDefinitions.Performance.ActivityStatus
];

/**
 * Defines the props of the performance task list component.
 */
type Props = {
    elementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
    workflowTemplates: Domain.Shared.WorkflowTemplateWithStates[],
    measureMoments: Domain.Shared.MeasureMoment[],
    icons?: Record<string, Domain.Shared.SvgIcon>,
};

type HierarchyTask = Domain.Performance.HierarchyItemElement & { progress: Domain.Shared.FieldDefinitionOptionItem, workflowState: Domain.Shared.WorkflowTemplateState };

/**
 * Represents a UI component that renders the users performance task list.
 */
export const PerformanceTaskList: React.FC<Props> = (props) => {
    const userSettingsContext = useUserSettings();
    const [hierarchyTasks, setHierarchyTasks] = useState<HierarchyTask[]>([]);
    const [measureMoments, setMeasureMoments] = useState<{
        values: Domain.Shared.MomentItem[],
        selectedMeasureMoment: Domain.Shared.MomentItem
    }>({ values: [], selectedMeasureMoment: undefined });
    const navigate = useNavigate();

    useEffect(() => {
        if (!props.measureMoments) {
            return;
        }

        const moments = props.measureMoments.map((moment) => ({
            value: moment.id,
            label: moment.name,
            closed: moment.status === Domain.Shared.MeasureMomentStatus.Closed
        }));

        // Get the context measure moment or the first opened measure moment as default.
        const defaultMoment: Domain.Shared.MeasureMoment = userSettingsContext.measureMomentId
            ? props.measureMoments.find((moment) => moment.id === userSettingsContext.measureMomentId)
            : props.measureMoments.filter((moment) => isMomentOpen(moment.status)).reduce((min, momentObj) => (min?.order < momentObj.order ? min : momentObj), undefined);

        setMeasureMoments({
            values: moments,
            selectedMeasureMoment: defaultMoment
                ? {
                    value: defaultMoment.id,
                    label: defaultMoment.name,
                    closed: !isMomentOpen(defaultMoment.status),
                }
                : undefined,
        });

    }, [props.measureMoments, userSettingsContext.measureMomentId]);

    useEffect(() => {
        if (!measureMoments || !measureMoments.selectedMeasureMoment || !measureMoments.selectedMeasureMoment.value || !props.elementDefinitions || !props.workflowTemplates) {
            return;
        }

        Performance.HierarchyDataAccessor.getAssignedTasks(measureMoments.selectedMeasureMoment.value).then((result) => {
            const tasklist = result.data?.tasklist?.map((item: Domain.Performance.HierarchyTask) => {
                const elementItem = new Domain.Performance.HierarchyItemElement();
                FieldsHelper.mapObject<Domain.Performance.HierarchyItemElement>(elementItem, props.elementDefinitions[item.element.elementDefinitionId].fields, item.element.fields);
                elementItem.id = item.element.elementId;
                elementItem.type = props.elementDefinitions[item.element.elementDefinitionId].systemId as Domain.Performance.HierarchyItemElementType;

                // Compute the progress value:
                const statusFieldDefinition = props.elementDefinitions[item.element.elementDefinitionId].fields.find(item => availableStatuses.indexOf(item.systemId) >= 0);
                const progress =
                    statusFieldDefinition?.optionItems.find((option: Domain.Shared.FieldDefinitionOptionItem) => option.id === item.element.fields[statusFieldDefinition.id]);
                // Compute the workflow value:                
                const workflowState: Domain.Shared.WorkflowTemplateState = result.data?.settings ?
                    props.workflowTemplates.find(workflow => workflow.id === getWorkflowIdByElementType(elementItem.type, result.data?.settings))?.workflowStates.find((state: Domain.Shared.WorkflowTemplateState) => state.id === item.workflowStateId) :
                    undefined;

                return { ...elementItem, progress: progress, workflowState: workflowState };
            });
            setHierarchyTasks(tasklist || []);

        });

    }, [measureMoments.selectedMeasureMoment, props.elementDefinitions, props.workflowTemplates]);

    const getWorkflowIdByElementType = (type: Domain.Performance.HierarchyItemElementType, settings: Domain.Performance.HierarchySettings) => {
        switch (type) {
            case Domain.SystemElementDefinitions.Performance.Policy: return settings.policyWorkflowProcessId;
            case Domain.SystemElementDefinitions.Performance.Goal: return settings.goalWorkflowProcessId;
            case Domain.SystemElementDefinitions.Performance.Achievement: return settings.achievementWorkflowProcessId;
            case Domain.SystemElementDefinitions.Performance.Activity: return settings.activityWorkflowProcessId;
            default: return undefined;
        }
    };

    const momentInputCustomOptions = (inputProps: IDataItemProps<string>, templateProps?: SelectElementTemplateProps) => {
        const moment = props.measureMoments.find(mm => inputProps?.value === mm.id);

        return (
            <Styled.SingleValueWrapper>
                {moment && <Icon value={isMomentOpen(moment.status) ? IconValue.OpenLock : IconValue.Lock} size={IconSize.small} />}
                {templateProps?.isFieldTemplate ?
                    <TextBox stylingMode='outlined' value={inputProps?.label} />
                    :
                    <Styled.SingleValueLabel>
                        {inputProps?.label}
                    </Styled.SingleValueLabel>
                }
            </Styled.SingleValueWrapper>
        );
    };

    const columns = getColumnConfiguration(props.icons, props.elementDefinitions);
    return (
        <>
            <Styled.TitleContainer>
                <Styled.Title>Toegewezen</Styled.Title>
                <Styled.RightBox>
                    <SelectElement<Domain.Shared.MomentItem>
                        id='performance-task-list-measure-moments'
                        optionItems={measureMoments.values}
                        placeholder='Kies...'
                        displayExpr='label'
                        value={measureMoments?.selectedMeasureMoment}
                        customOptions={momentInputCustomOptions}
                        customSingleValue={(item) => momentInputCustomOptions(item || measureMoments.selectedMeasureMoment, { isFieldTemplate: true, placeholder: 'Kies...' })}
                        editorSettings={{
                            disabled: false,
                            onChange: (selectedItem) => {
                                userSettingsContext.setMeasureMomentId(selectedItem ? selectedItem.value : undefined);
                            }
                        }}
                    />
                </Styled.RightBox>
            </Styled.TitleContainer>
            <Styled.GridOverflow>
                <LsGrid
                    columns={columns}
                    dataSource={hierarchyTasks}
                    allowColumnResizing={true}
                    keyExpr='id'
                    showRowLines={true}
                    onClickRow={(item: HierarchyTask) => {
                        navigate(`/performance/tree/overview/${item.type}/${measureMoments.selectedMeasureMoment.value}/${item.id}`);
                    }}
                />
            </Styled.GridOverflow>
        </>
    );
};

const getElementDefinition = (elementDefinitions: Record<string, Domain.Shared.ElementDefinition>, id: string) => {
    return Object.values(elementDefinitions).find(item => item.systemId === id);
};

const getColumnConfiguration = (icons: Record<string, Domain.Shared.SvgIcon>, elementDefinitions: Record<string, Domain.Shared.ElementDefinition>): GridColumn<HierarchyTask>[] => {
    return [
        {
            name: 'type',
            title: 'Titel',
            width: '50%',
            renderCustom: (item) => {
                const ed = getElementDefinition(elementDefinitions, item.data.type);
                return <FlexBox>
                    <SVGIcon value={icons[ed.icon]?.svg} size={IconSize.medium} color={ed.color} />
                    {item.data.name}
                </FlexBox>
            }
        },
        {
            name: 'number',
            title: 'Nummer',
            allowSorting: false,
            width: '10%',
        },
        {
            name: 'workflowState',
            title: 'Workflow status',
            width: '20%',
            renderCustom: ({ data }) => (
                <FlexBox>
                    {IconHelper.getWorkFlowStatusIcon(data.workflowState?.name, IconSize.small)}
                    {data.workflowState?.name || 'Nieuw'}
                </FlexBox>
            )
        },
        {
            name: 'progress',
            title: 'Voortgang',
            width: '20%',
            renderCustom: ({ data }) => (
                <FlexBox>
                    {PerformanceIconHelper.getPerformanceProgressIcon(data.progress?.name || 'Anders', IconSize.small)}
                    {data.progress?.name || 'Anders'}
                </FlexBox>
            )
        },
    ];
};