import styled from 'styled-components';
import { px } from '../style';
import { palette } from '../styleguide';

const CardHeaderTab = styled.button<{ isActive?: boolean }>`
    color: ${palette.grey2};
    background-color: transparent;
    border: none;
    border-bottom: ${(p) => p.isActive ? `2px solid ${palette.primary2}` : `2px solid rgba(1,1,1,0)`};
    color: ${(p) => p.isActive && palette.primary2};
    font-family: inherit;
    font-weight: 700;
    padding: ${px(16)} 0;    
    margin: 0 ${px(16)} 0 0;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    &:active {
        outline: 0;
    }
    &:focus {
        outline: 0;
    }
`;

const styledComponents = { CardHeaderTab };
export default styledComponents;