import * as Domain from '@liasincontrol/domain';

const isMomentOpen = (measureMomentStatus: Domain.Shared.MeasureMomentStatus) => {
    return measureMomentStatus?.toString() === Domain.Shared.MeasureMomentStatus[Domain.Shared.MeasureMomentStatus.Open];
};

const isMomentDeleted = (measureMomentStatus: Domain.Shared.MeasureMomentStatus) => {
    return measureMomentStatus?.toString() === Domain.Shared.MeasureMomentStatus[Domain.Shared.MeasureMomentStatus.Deleted];
};

export { isMomentOpen, isMomentDeleted };
