import React from 'react';
import { NIL as NIL_UUID } from 'uuid';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

import * as Domain from '@liasincontrol/domain';
import { UserIdentity } from '@liasincontrol/auth-service';
import { SystemElementDefinitions } from '@liasincontrol/domain';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { DxTemplate, DxTreeList, DxColumn, DxRemoteOperations, DxCustomStore, DxDataSource } from '@liasincontrol/ui-devextreme';
import { palette } from '@liasincontrol/ui-basics';
import { ToolboxIcon } from '../../../../../../components/Renderer/Element/Containers/_shared/Placeholder';
import * as Styled from '../../../../_shared/TreeList/index.styled';
import '../../../../_shared/TreeList/index.style.less';
import { taskListHiddenControlSystemIds } from '../../../../_shared/TreeList/constants';
import { AssignedPopover } from '../../shared/AssignedPopover';

type Props = {
    userIdentity: UserIdentity,
    publication: Domain.Publisher.Publication,
    elementDefinitions: { [id: string]: Domain.Shared.ElementDefinition; },
    extractHomePage?: boolean,
    defaultWorkflowState: Domain.Publisher.TaskManagerWorkflowState,
    workflowStates: Domain.Publisher.TaskManagerWorkflowState[],
    setError: (error: Domain.Shared.ErrorInfo) => void,
};

type TreeViewNode = {
    id: string,
    elementId: string,
    elementName: string,
    parentId: string,
    hasChildren: boolean,
    elementDefinitionSystemId: Domain.SystemElementDefinitions.Pub,
    currentStateName?: string,
    openTasksCount?: number,
    currentStateId?: string;
};

export const TreeList: React.FC<Props> = (props) => {

    const fetchNode = (pageId: string) => {
        return Promise.all([
            DataAccess.Publications.getPageSitemap(props.publication.publication.elementId, pageId, 1),
            DataAccess.WorkflowItem.getPageWorkflowItem(props.publication.publication.elementId, pageId, true, true, true)
        ]).then(([subpages, components]) => {
            const pageNode = subpages.data.node;
            let returnedArray: TreeViewNode[] = [];

            //Root page
            if (pageId === props.publication.rootPageId) {
                returnedArray = returnedArray.concat({
                    id: pageNode.elementId,
                    elementId: pageNode.elementId,
                    elementName: pageNode.elementName,
                    parentId: NIL_UUID,
                    elementDefinitionSystemId: SystemElementDefinitions.Pub.Page,
                    hasChildren: !!pageNode.children.length || !!components.data.length,
                    currentStateId: null,
                });
            }

            //components of the requested page
            const orderedComponents = components.data.sort((a, b) => a.order - b.order);
            returnedArray = returnedArray.concat(...orderedComponents
                ?.filter(component => !taskListHiddenControlSystemIds.includes(component.componentElementDefinitionSystemId as Domain.SystemElementDefinitions.Pub))
                ?.map((component) => ({
                    id: component.componentId,
                    elementId: component.elementId,
                    elementName: component.componentName,
                    parentId: pageId,
                    currentStateName: component.currentStateName || '',
                    elementDefinitionSystemId: component.componentElementDefinitionSystemId as Domain.SystemElementDefinitions.Pub,
                    hasChildren: false,
                    openTasksCount: component.openTaskCount,
                    currentStateId: component.currentStateId,
                })));

            //subpages of the requested page
            returnedArray = returnedArray.concat(...pageNode.children.map((page) => ({
                id: page.elementId,
                elementId: page.elementId,
                elementName: page.elementName,
                parentId: pageNode.elementId,
                elementDefinitionSystemId: SystemElementDefinitions.Pub.Page,
                hasChildren: true, //!!page.children.length, //!! subpage has component
                currentStateId: null,
            })));

            return {
                data: returnedArray,
            };

        }).catch((err) => {
            props.setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
        });
    };

    const fetchTaskList = (publicationId: string, pageId: string, controlId: string) => {
        const params: DataAccess.WorkflowTaskQueryParam = {
            includePageDetails: false,
            includeComponentDetails: false,
            includeStateDetails: true,
            includeUserDetails: true,
            onlyActive: false,
            onlyNotCompleted: false,
            onlyCurrentUser: false,
        };

        return DataAccess.WorkflowTask.getComponentWorkflowTask(publicationId, pageId, controlId, params)
            .then((response) => {
                return response.data;
            }).catch((err) => {
                return [];
            });
    }

    const getComponentName = (systemId: string) => {
        const element = Object.values(props.elementDefinitions).find((ed: Domain.Shared.ElementDefinition) => ed.systemId === systemId);
        return element?.label || element?.name || 'N/A';
    };

    const getNameTemplate: React.FC<{ data: TreeViewNode }> = ({ data }) => {
        let icon: JSX.Element = (<InsertDriveFileOutlinedIcon />);
        if (data.elementDefinitionSystemId !== SystemElementDefinitions.Pub.Page && data.elementDefinitionSystemId) {
            return (<Styled.Wrapper withBackGround={false} withPadding={true}>
                <Styled.ColoredToolboxIconWrapper color={palette.primary2}><ToolboxIcon systemId={data.elementDefinitionSystemId} /></Styled.ColoredToolboxIconWrapper>
                <Styled.Label>{getComponentName(data.elementDefinitionSystemId)} - {data.elementName || 'Geen naam'}</Styled.Label>
            </Styled.Wrapper>);
        } else if (data.elementId === props.publication.rootPageId) {
            icon = (<HomeOutlinedIcon />);
        } else if (data.hasChildren) {
            icon = (<FolderOpenIcon />);
        }

        return (<Styled.Wrapper withBackGround={false} withPadding={true}>
            <Styled.ColoredToolboxIconWrapper>{icon}</Styled.ColoredToolboxIconWrapper>
            <Styled.Label>{data.elementName}</Styled.Label>
        </Styled.Wrapper>);
    };

    const customDataSource = new DxDataSource({
        store: new DxCustomStore({
            load: (loadOptions) => {
                const parentIdsParam = loadOptions.parentIds;
                if (!parentIdsParam || !parentIdsParam[0] || parentIdsParam[0] === NIL_UUID) return fetchNode(props.publication.rootPageId);
                return fetchNode(parentIdsParam[0]);
            },
        }),
    });

    const getStatusTemplate: React.FC<{ column: any; data: TreeViewNode }> = ({ column, data }) => {
        return data.elementDefinitionSystemId && data.elementDefinitionSystemId === SystemElementDefinitions.Pub.Page ? null : <>{data.currentStateName}</>;
    };

    const getTasksTemplate: React.FC<{ column: any; data: TreeViewNode }> = ({ column, data }) => {
        return (data.elementDefinitionSystemId && data.elementDefinitionSystemId === SystemElementDefinitions.Pub.Page) || !data.openTasksCount ? null : <>{data.openTasksCount}</>;
    };

    const getWorkflowTaskTemplate: React.FC<{ column: any; data: TreeViewNode }> = ({ column, data }) => {
        return data.elementDefinitionSystemId !== SystemElementDefinitions.Pub.Page
            ? (
                <AssignedPopover
                    controlId={data.elementId}
                    pageId={data.parentId}
                    publicationId={props.publication.publication.elementId}
                    componentName={column.caption.name}
                    workflowStates={props.workflowStates}
                    currentStateId={data.currentStateId}
                    fetchTaskList={fetchTaskList}
                />
            ) : null;
    };

    return (<>
        {props.publication && <DxTreeList
            id='task-list'
            dataSource={customDataSource}
            showBorders={false}
            showColumnLines={false}
            showRowLines={true}
            columnAutoWidth={true}
            keyExpr='id'
            parentIdExpr='parentId'
            hasItemsExpr='hasChildren'
            loadPanel={{
                text: 'Laden...'
            }}
            rootValue={NIL_UUID}
            defaultExpandedRowKeys={[props.publication.rootPageId]}
            scrolling={{ mode: 'standard' }}
        >
            <DxRemoteOperations filtering={true} />
            <DxColumn
                caption="Pagina's en pagina-onderdelen"
                dataField='elementName'
                allowSorting={false}
                allowFiltering={false}
                cellTemplate='nameTemplate'
            />
            <DxColumn
                caption="Workflow stap"
                allowSorting={false}
                cellTemplate='statusTemplate'
                width={'20%'}
            />
            <DxColumn
                caption='Open taken'
                dataField='id'
                allowSorting={false}
                allowFiltering={false}
                cellTemplate='tasksTemplate'
                width='20%'
            />
            <DxColumn
                caption=""
                dataField='elementId'
                allowSorting={false}
                allowFiltering={false}
                cellTemplate='workflowTaskTemplate'
            />

            <DxTemplate name='nameTemplate' render={getNameTemplate} />
            <DxTemplate name='statusTemplate' render={getStatusTemplate} />
            <DxTemplate name='tasksTemplate' render={getTasksTemplate} />
            <DxTemplate name='workflowTaskTemplate' render={getWorkflowTaskTemplate} />
        </DxTreeList>}
    </>);
};
