import React from 'react';
import Styled from './index.styled';

export interface IEdgeTabProps {
    readonly children?: React.ReactNode;
    readonly title: React.ReactNode;
    readonly icon: React.ReactNode;
    readonly active: boolean;
    readonly tabCount: number;
    readonly disabled?: boolean;
    readonly onClick: () => void;
}

export const EdgeTab = ({ children, icon, title, active, tabCount, disabled, onClick: toggleTab }: IEdgeTabProps) => {
    return (
        <>
            {active && <Styled.TabContent tabCount={tabCount}>{children}</Styled.TabContent>}
            <Styled.Tab isActive={active} onClick={disabled ? undefined : toggleTab} data-title={title} isDisabled={disabled}>
                <Styled.TabLabel isDisabled={disabled}>{title}</Styled.TabLabel>
                {icon}
            </Styled.Tab>
        </>
    );
};

export { EdgeTabs } from './index.styled';
