import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class HtmlElement extends BaseControlElement {

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.HtmlContent)
    htmlPlainText: string;

}

export default HtmlElement;