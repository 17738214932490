import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { oDataResponse } from '../../Shared/oData';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents a data accessor of budget amount items.
 */
export class BudgetAmountDataAccessor {

    /**
     * Gets the collection of budget amount items.
     * @param query Defines the odata query.
     */
    public static getAll = async (query = ''): Promise<AxiosResponse<oDataResponse<Domain.Finance.BudgetAmount[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<oDataResponse<Domain.Finance.BudgetAmount[]>>(`/api/finance/imported-budget${query}`, config);
    };

}
