import Add from '@mui/icons-material/Add';
import React from 'react';
import styled from 'styled-components';

import { Field } from '../field';
import { Button } from '../button';
import { BasicText, InputRadio } from '../primitives';
import { cssFontNormal, px } from '../style';
import { IListItem } from './Props';

/* tslint:disable:no-magic-numbers */

const ListWrap = styled.ul`
    list-style: none;
    display:block;
    width: auto;
    max-width:350px;
    padding:0;
    margin: ${px(8)} 0 ${px(12)} 0;
`;
const Li = styled.li`
    display: inline-block;
    width: 100%;
    height: auto;
    max-width:350px;
    padding:0 0 8px 0;
    ${cssFontNormal(18, 28, 30)}
    text-align:left;

    > label {
        display:inline-block;
        width: 32px;
        height: 5px;
    }
    > div {
        display:inline-block;
        width: calc(100% - 42px);
    }
`;
const StyledInput = styled(BasicText.withComponent('input'))`
    display:inline-block;
    width: calc(100% - 42px);
    padding:${px(12)} ${px(16)};
    margin:0;
    color:#414141;
    text-overflow:ellipsis;
    border-radius:4px;
    outline:0;
    transition:border-color 0.3s ease;
    background: #fff;
    white-space: nowrap;
    ::-ms-clear {
        display: none;
    }

    border:2px solid #f4f4f8;
    &:hover { border:2px solid rgba(75, 85, 246, 0.15); }
    &:focus { border:2px solid rgba(75, 85, 246, 1); } /* dit is #4b55f6 */
`;

export const OptionsList = ({ listId, listLabel, items, idSelected, onAddNewItem, onItemChanged, onItemSelected }: {
    listId: string;
    listLabel: string;
    items: IListItem[];
    idSelected: number;
    onAddNewItem: () => void;
    onItemChanged: (args: { id: number, label: string }) => void;
    onItemSelected: (args: { id: number, label: string }) => void;
}) => (
    <Field
        id={listId}
        label={listLabel}
        value="">
        {({ onFocus, onBlur, onChange, value, success, error, focused }) => (
            <ListWrap id={listId}>
                {items.map(({ id, label }, index) => (
                    <Li key={index}>
                        <InputRadio
                            label=""
                            value={id}
                            selected={idSelected === id}
                            onClick={() => onItemSelected({ id, label })} />
                        <StyledInput
                            value={label}
                            onChange={(evt) => onItemChanged({ id, label: evt.target.value })} />
                    </Li>))}
                <Button btnbase="ghostbuttons" btntype="small_noicon" icon={<Add />} onClick={onAddNewItem}>Voeg toe</Button>
            </ListWrap>
        )}
    </Field>
);
