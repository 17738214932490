import React from 'react';
import { TextViewer } from '../../shared/TextViewer';
import { MultiSelectEditor } from '../MultiSelect/index.editor';
import { MultiSelectProps } from './index.props';
import { IDataItemProps } from '@liasincontrol/ui-basics';

/**
 * Represents a UI component that renders an Select element.
 */
export const MultiSelectElement: React.FC<MultiSelectProps> = (props) => {
    return (
        props.editorSettings && !props.editorSettings.disabled
            ? <MultiSelectEditor {...props} />
            : <TextViewer id={props.id} label={props.label} helpText={props.helpText} value={getSelectedValue(props.value, props.optionItems)} />
    );
};

const getSelectedValue = (value: IDataItemProps<any>[], optionItems: IDataItemProps<any>[]): string => {
    if (!value || optionItems?.length < 1) return '';

    const selectedItemIds = value.map(item => item.value);
    return optionItems.filter((optionItem) => selectedItemIds.includes(optionItem.value))?.map(e => e.label)?.join(',');
};