import { createReducer } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { TenantActionCreator, AnnouncementActionCreator } from '../../../../actions/creators';
import { AjaxRequestStatus } from '../../../state';

/**
 * Represents the announcement reducer.
 */
const reducer = createReducer<{ items: Domain.Shared.Announcement , status: AjaxRequestStatus }>({ items: undefined, status: AjaxRequestStatus.NotSet }, (builder) => {
    return (
        builder.addCase(AnnouncementActionCreator.set.fulfilled, (state, action) => {
            state = {
                items: action.payload,
                status: AjaxRequestStatus.Done,
            };
            return state;
        }),
        builder.addCase(AnnouncementActionCreator.set.pending, (state, action) => {
            // Loading in progress;
            state = {
                items: state.items,
                status: AjaxRequestStatus.Pending,
            };
            return state;
        }),
        builder.addCase(AnnouncementActionCreator.set.rejected, (state, action) => {
            state = {
                items: undefined,
                status: AjaxRequestStatus.Failed,
            };
            return state;
        }),
        builder.addCase(TenantActionCreator.initTenant, (state, action) => {
            state = {
                items: state.items,
                status: AjaxRequestStatus.NotSet,
            };
            return state;
        })
    );
});

export default reducer;
