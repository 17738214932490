import { UserManager, User as UserIdentity } from 'oidc-client';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Config as AppConfiguration, ConfigService, AppSettingsService } from '@liasincontrol/config-service';
import * as Domain from '@liasincontrol/domain';
import configuration from './config';

enum License {
    Publisher = 'pub',
    Performance = 'perf',
    Finance = 'fin',
    Studio = 'studio',
    Analytics = 'analytics',
    Map = 'map',
    AITextAssistant = 'aitextassistant'
}

/**
 * Represents a service that is responsible for managing the user authentication process.
 */
class AuthenticationService extends UserManager {

    /**
     * Constructs and registers an instance of this class.
     */
    private constructor(config: AppConfiguration) {
        super({
            authority: config.IDENTITY.IDENTITY_URL,
            metadataUrl: `${config.IDENTITY.IDENTITY_URL}/.well-known/openid-configuration`,
            automaticSilentRenew: true,

            client_id: 'omniajs',
            response_type: 'id_token token',
            scope: 'openid profile roles omnia.api.basic',

            post_logout_redirect_uri: `${window.location.origin}${configuration.redirectUriAfterLogoutPath}`,
            redirect_uri: `${window.location.origin}${configuration.redirectSigninPath}`,
            silent_redirect_uri: `${window.location.origin}${configuration.redirectSilentSigninPath}`,
        });
    }

    /**
     * Attempt to do a silent signin to refresh the access token. If that fails, do a full redirect to authenticate the user.
     * 
     * WARNING: although the method seems to always return a UserIndetity, when signinRedirect is called the brwoser will redirect to the 
     * login page and the JS code will not continue its execution.
     * @param normalLoginRedirectUri Url where to redirect in case a full login is required.
     */
    public async attemptSilentLogin(normalLoginRedirectUri: string): Promise<UserIdentity> {
        try {
            return await this.signinSilent()
        } catch (err) {
            // https://stackoverflow.com/questions/58942766/identity-server-4-silent-renew-errorresponse-login-required
            if (err?.error === 'login_required') {
                // Doesn't matter what we return here, since the browser will take over and redirect us anyway
                return this.signinRedirect({ state: { redirectUri: normalLoginRedirectUri } })
                    .then(this.getUser);
            }

            // some other error, leave the client to deal with it.
            throw err;
        }
    }

    /**
     * Gets an instance of the AuthenticationService class.
     *
     * @returns An instance of the AuthenticationService class.
     */
    public static async getInstance(): Promise<AuthenticationService> {
        if (AuthenticationService.instance === null || AuthenticationService.instance === undefined) {
            AuthenticationService.instance = new AuthenticationService(await ConfigService.getConfigurations());
        }

        return AuthenticationService.instance;
    }

    async storeUser(user: UserIdentity): Promise<void> {
        if (user) {
            let claims: Domain.Shared.UserClaims;
            let authenticationError = null;

            try {
                claims = (await this.getUserClaims(user)).data;
            } catch (claimsError) {
                if (claimsError?.status !== 403) {
                    // Receiving a Forbidden error code is actually the equivalent of missing the OmniaUser claim.
                    // So don't treat this as an authentication failure, just as 'not an Omnia user' identity.
                    authenticationError = claimsError;
                }
                console.error(`Cannot retrieve user claims. User will not be able to login`, claimsError);
            }
            const permissions = (claims?.permissions || []).map(p => Domain.Shared.UserPermissions[p]);
            const licenses: string[] = [];

            const isInControlUser = claims?.isInControlUser || false;
            const isTenantAdmin = claims?.isTenantAdmin || false;
            claims?.licenses.forEach(claim => licenses.push(claim));

            user.profile = {
                ...user.profile,
                permissions: permissions,
                licenses: licenses,
                isInControlUser: isInControlUser,
                isTenantAdmin: isTenantAdmin,
                error: authenticationError,
                featureFlags: claims.featureFlags,
            };
        }

        return super.storeUser(user);
    }

    private getUserClaims = async (identity: UserIdentity): Promise<AxiosResponse<Domain.Shared.UserClaims>> => {
        const config: AxiosRequestConfig = {
            baseURL: (await ConfigService.getConfigurations()).API.PUB_API_URL,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Authorization': `Bearer ${identity.access_token}`
            },
            timeout: AppSettingsService.getAppSettings().Api.Timeout
        };

        const HttpClient = axios.create(config);
        return HttpClient.get<Domain.Shared.UserClaims>('/api/user/claims')
            .catch(err => {
                // Axios HTTP response codes are contained in the err.response object.
                // But some errors (like service not available) are not raised as Axios errors, so just throw that instead.
                throw err.response ?? err;
            });
    };

    private static instance: AuthenticationService;
}

export { AuthenticationService, UserIdentity, License };
