import React from 'react';
import { DateUtils } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@liasincontrol/ui-basics';
import Styled from './index.styled';

type Props = {
    audit: Domain.Dto.Shared.AuditEvent[],
    attachment: Domain.Shared.Attachment,
    users: Domain.Shared.User[],
    disabled: boolean,
    onRemoveAttachment: (attachmentId: string) => void,
    onDownloadAttachment: (attachmentId: string) => void,
}

/**
 * Represents a UI component that renders a performance attachment item.
 */
const HierarchyItemAttachmentItem: React.FC<Props> = (props) => {
    const auditContentElement = () => {
        if (!props.audit || props.audit.length === 0) {
            return (
                <Styled.AttachmentAudit>
                    <Styled.AttachmentAuditItem>
                        Geen gegevens beschikbaar.
                    </Styled.AttachmentAuditItem>
                </Styled.AttachmentAudit>
            );
        }

        const auditItemElements = props.audit.map(auditItem => {
            const user = props.users.find(item => item.id === auditItem.userId)
            return <Styled.AttachmentAuditItem>
                {user ? user.name : 'Onbekende gebruiker'} - {DateUtils.formatDateTime(auditItem.timestamp)}
            </Styled.AttachmentAuditItem>;
        });

        return (
            <Styled.AttachmentAudit>
                {auditItemElements}
            </Styled.AttachmentAudit>
        );
    };

    return (
        <Styled.AttachmentItem>
            <AttachFileIcon sx={{ fontSize: 16 }} />
            <Styled.AttachmentLabel onClick={() => props.onDownloadAttachment(props.attachment.id)}>
                {`${props.attachment.name}.${props.attachment.fileExtension}`}
            </Styled.AttachmentLabel>

            <Tooltip content={auditContentElement()}>
                <InfoOutlinedIcon sx={{ fontSize: 18 }} />
            </Tooltip>

            {!props.disabled && <Styled.RemoveAttachmentIcon onClick={() => props.onRemoveAttachment && props.onRemoveAttachment(props.attachment.id)} />}
        </Styled.AttachmentItem>
    );
};

export { HierarchyItemAttachmentItem };
