import { createAsyncThunk } from '@reduxjs/toolkit';
import { oDataResponse, Shared as SharedDataAccess } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import Payload from '../../../../Payload';
import { actionSourceToEndpoint } from '../../../../Source';

const action = createAsyncThunk<Payload<oDataResponse<Domain.Shared.Module[]>>, Payload<{}>, {}>('/modules/set', async (payload) => {
    return SharedDataAccess.Modules.getInstance()
        .then(instance => instance.get(actionSourceToEndpoint(payload.source)))
        .then((response) => ({
                data: response.data,
                source: payload.source
            }));
});

export default action;