import React, { useRef, useState } from 'react';
import _ from 'lodash';
import ReactDOM from 'react-dom';
import { useUniqueId } from '../uniqueId';
import { ModalDialogProps } from './index.props';
import { WhenVisible, Underlay, MarginLayout, CenterLayout, Popup, ScrollContainer, Container, H1, Footer, ModalDialogCustomStyle } from './index.styled';

/** 
 * Represents a UI component that renders a modal dialog. 
 */

export const ScrollContext = React.createContext(null);

export const ScrollContainerWrapper: React.FC<any> = (props) => {
    const divRef = useRef(null);
    const [scrollPositionY, setScrollPositionY] = useState(0);
    const val: any = { scrollContainerRef: divRef, scrollPositionY: scrollPositionY };

    const onScrollDebounced = _.debounce((e) => {
        onScroll(e)
    }, 500);

    const onScroll = (event) => {
        setScrollPositionY(event.target.scrollTop);
    }

    return (
        <ScrollContext.Provider value={val}>
            <ScrollContainer ref={divRef} className="isolation-scroll-container" customPadding={props.customPadding} onScroll={onScrollDebounced}>
                {props.modalDialogStyle && props.modalDialogStyle === 'custom' ?
                    (
                        <ModalDialogCustomStyle children={props.children} />
                    ) :
                    props.children}
            </ScrollContainer>
        </ScrollContext.Provider>
    )
};

export const ModalDialog: React.FC<ModalDialogProps> = ({ edgeToolbar: EdgeToolbar, visible = true, ...props }) => {
    const uniqueId = useUniqueId('', props.id);
    const uniqueTitleId = useUniqueId('title', props.id);

    const content: JSX.Element = (
        <>
            <WhenVisible pose={visible ? 'opened' : 'closed'} initialPose="closed" withParent={false}>
                <Underlay pose={visible ? 'opened' : 'closed'} initialPose="closed" withParent={false}>
                    {!props.settings ?
                        (<MarginLayout>
                            <Container
                                id={uniqueId}
                                pose={visible ? 'opened' : 'closed'}
                                initialPose="closed"
                                cardLook={props.modalDialogStyle && props.modalDialogStyle === 'card'}
                                withParent={false}
                                onClick={props.onClick}
                                role="dialog"
                                aria-label={props.ariaLabel}
                                hidden={!visible}>
                                <PreventHtmlScroll />
                                <ScrollContainerWrapper {...props}></ScrollContainerWrapper>
                                {EdgeToolbar && (React.isValidElement<{ open: boolean, inModal: boolean }>(EdgeToolbar)
                                    ? React.cloneElement(EdgeToolbar, { open: visible && EdgeToolbar.props.open, inModal: true })
                                    : <EdgeToolbar open={visible} inModal />)}
                            </Container>
                        </MarginLayout>) :
                        (<CenterLayout role="presentation">
                            <Popup
                                id={uniqueId}
                                look={props.settings.look}
                                role="dialog"
                                hidden={!visible}
                                aria-labelledby={uniqueTitleId}
                                maxAllowed={props.settings.maxAllowed || false}
                                customWidth={props.settings.width || 800}
                                cardLook={props.modalDialogStyle && props.modalDialogStyle === 'card'}
                                customPadding={props.customPadding}

                            >

                                {props.settings.title && <H1 id={uniqueTitleId}>{props.settings.title}</H1>}
                                {props.children}
                                {props.settings.footer && <Footer>{props.settings.footer}</Footer>}

                            </Popup>
                        </CenterLayout>)
                    }
                </Underlay>
            </WhenVisible>
            {props.toolbars}
        </>);

    return ReactDOM.createPortal(content, document.body);
};
export { Underlay };

const PreventHtmlScroll = () => (
    React.createElement('style', { type: 'text/css' }, 'html { overflow: hidden }')
);

