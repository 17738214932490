import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React from 'react';
import posed from 'react-pose';
import styled from 'styled-components';
import { Button } from '../button';
import { px } from '../style';

export interface IPresentationProps {
    readonly editable: boolean;
    readonly active: boolean;
    readonly bottom: number;
    readonly right: number;
    readonly onBackToTop: () => void;
    readonly onEdit: () => void;
}

const BtnWrap = styled(posed.div({
    shown: { opacity: 1, x: `-100%`, y: `-100%`, scale: 1 },
    hidden: { opacity: 0, x: `100%`, y: `-100%`, scale: .8 },
}))`
    position: fixed;
    display: grid;
    grid-gap: ${px(16)};
    grid-auto-flow: row;
    grid-auto-rows: auto;
`;

const offsetX = 40;
const offsetY = 32;

export const FloaterButtons = React.forwardRef<any, IPresentationProps>(({ active, editable, onEdit, onBackToTop, bottom, right }, ref) => (
    <BtnWrap
        className="floater-btns"
        role="navigation"
        isActive={active}
        initialPose="hidden"
        pose={active ? `shown` : `hidden`}
        ref={ref}
        style={{ top: `${bottom - offsetY}px`, left: `${right - offsetX}px` }}>
        <Button btnbase="iconbuttons" btntype="large_fabghost" onClick={onBackToTop} aria-label="Naar boven" icon={<ArrowUpwardIcon />} />
        {editable
            ? <Button btnbase="iconbuttons" btntype="large_fabprimary" onClick={onEdit} aria-label="Bewerken" icon={<EditOutlinedIcon />} />
            : null}
    </BtnWrap>
));
