import React, { useState } from 'react';
import { AutoFocus, EditingToolbar, ModalDialog } from '@liasincontrol/ui-basics';
import { FormInfo, FormMode, ValueType } from '@liasincontrol/core-service';
import { WorkflowTemplateForm } from '../../WorkflowTemplateForm';

type Props = {
    workflowNames?: string[],
    onSaveChanges: (formValues: Record<string, ValueType>) => void,
    onCancelChanges: () => void,
};

/**
 * Represents a UI component that renders a dialog to add a new workflow template object.
 */
const WorkflowTemplateAdd: React.FC<Props> = (props) => {
    const [formData, setFormData] = useState<FormInfo<ValueType>>({ values: undefined, isValid: true, isTouched: false });

    return (<>
        <ModalDialog customPadding modalDialogStyle="custom"
            toolbars={<EditingToolbar
                id="popup-new-workflowtemplate"
                look="default"
                isVisible={true}
                disabled={!formData?.isTouched}
                isValid={formData?.isValid}
                onSave={() => props.onSaveChanges(formData?.values)}
                onCancel={() => props.onCancelChanges()} />}
        >
            <AutoFocus>
                <WorkflowTemplateForm
                    workflowNames={props.workflowNames}
                    mode={FormMode.AddNew}
                    isOrdering={true}
                    onFormDataChanged={setFormData}
                />
            </AutoFocus>
        </ModalDialog>
    </>);
};

export { WorkflowTemplateAdd };
