import React, { useState } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Popover } from '@liasincontrol/ui-devextreme';
import { palette } from '@liasincontrol/ui-basics';
import * as Styled from '../../../../_shared/TreeList/index.styled';

type Props = {
    pageComponentId: string
    visible: boolean
};


export const NoTasksWarningPopover: React.FC<Props> = (props) => {
    const [popoverVisible, setPopoverVisible] = useState(false);

    if (!props.visible) return null;

    return (<>
        <Styled.WarningContainer onClick={() => setPopoverVisible(true)} className='mr-050'>
            <WarningAmberIcon id={`no-tasks-warning-${props.pageComponentId}`} sx={{ height: '16px' }} htmlColor={palette.red} />
            {popoverVisible &&
                <Popover
                    target={`#no-tasks-warning-${props.pageComponentId}`}
                    position="left"
                    width={320}
                    visible={popoverVisible}
                    onHiding={() => setPopoverVisible(false)}
                    shading={true}
                    shadingColor="rgba(0, 0, 0, 0.2)"
                >
                    <div>
                        <b>Let op!</b><br />
                        Dit element bevindt zich in een workflowstap zonder openstaande taken. Zet dit element handmatig in een andere stap.
                    </div>
                </Popover>
            }
        </Styled.WarningContainer>
    </>);
}