import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { ErrorOverlay, FlexBox, Heading2, IconSize, PageTitle, Section, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { Finance } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import { createSource, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { ApiErrorReportingHelper, FormMode, IconHelper } from '@liasincontrol/core-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UsersActionCreator, State, MeasureMomentsActionCreator, FinanceSettingsActionCreator, FinanceBaseYearsActionCreator, BudgetJournalGroupActionCreator, AjaxRequestStatus } from '@liasincontrol/redux-service';
import { BudgetJournalForm } from '../BudgetJournals/BudgetJournalForm';

type TaskListPropsType = {
    userIdentity: UserIdentity
}

export const TaskList: React.FC<TaskListPropsType> = (props) => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());

    const measureMoments = useSelector((state: State) => state.measuremoments);
    const users = useSelector((state: State) => state.users);
    const settings = useSelector((state: State) => state.finance.settings);
    const baseYears = useSelector((state: State) => state.finance.baseYears);
    const budgetJournalGroups = useSelector((state: State) => state.finance.budgetJournalGroups);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(UsersActionCreator.set());
        dispatch(MeasureMomentsActionCreator.set());
        dispatch(FinanceSettingsActionCreator.set());
        dispatch(FinanceBaseYearsActionCreator.set());
        dispatch(BudgetJournalGroupActionCreator.set());
    }, []);

    const customDataSource = useMemo(() => {
        const params: Finance.GetTasksParams = {
            includeStateDetails: true,
        };

        return createSource({
            keyExpr: 'taskId',
            paginate: true,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            selectedColumns: Object.keys(new Domain.Finance.Task()),
            dataSourcePromise: (query) => Finance.TasksDataAccessor.getAll(params, query),
        });
    }, [lastRefresh]);

    const [formDialog, setFormDialog] = useState<{
        isVisible: boolean,
        isSaving: boolean,
        formMode: FormMode,
        entity?: Domain.Finance.BudgetJournalDetail
    }>({ isVisible: false, isSaving: false, formMode: FormMode.View });

    const handleRowClick = (item: Domain.Finance.Task) => {
        Finance.BudgetJournalDataAccessor.get(item.budgetJournalId).then((response) => {
            setError(undefined);
            setFormDialog({
                ...formDialog,
                isVisible: true,
                isSaving: false,
                entity: response.data,
                formMode: FormMode.Edit,
            });
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        });
    };

    const handleCancel = () => {
        setFormDialog({
            ...formDialog,
            isVisible: false,
        });
    };

    const onRefresh = () => {
        setLastRefresh(Date.now());
    }

    const handleSave = (budgetJournal: Domain.Finance.BudgetJournal, closeModal = true) => {
        setFormDialog({ ...formDialog, isSaving: true });
        return Finance.BudgetJournalDataAccessor.update(budgetJournal).then(() => {
            setLastRefresh(Date.now());
            setFormDialog({ ...formDialog, isSaving: false, isVisible: !closeModal, formMode: FormMode.View });
        }).catch((err) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err);
            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.EditOnBudgetJournalFinalStage)) {
                setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.EditOnBudgetJournalFinalStage] });
            } else {
                setError(errorInfo);
            }
            setFormDialog({ ...formDialog, isSaving: false, isVisible: false, formMode: FormMode.View });
            throw err;
        });
    };

    const isLoadingState = (): boolean => {
        return users.status !== AjaxRequestStatus.Done
            && measureMoments.status !== AjaxRequestStatus.Done
            && settings.status !== AjaxRequestStatus.Done
            && baseYears.status !== AjaxRequestStatus.Done
            && budgetJournalGroups.status !== AjaxRequestStatus.Done;
    };

    if (isLoadingState()) return <></>

    const availableColumns: GridColumn<Domain.Finance.Task>[] = [{
        name: 'budgetJournalCode',
        title: 'Code',
        width: '15%',
    },
    {
        name: 'budgetJournalName',
        title: 'Naam journal',
        width: '60%'
    },
    {
        name: 'currentStateName',
        title: 'Workflow stap',
        width: '25%',
        renderCustom: (item) => {
            return <FlexBox>
                {IconHelper.getWorkFlowStatusIcon(item.data.currentStateName, IconSize.small)}
                {item.data.currentStateName || 'Nieuw'}
            </FlexBox>
        }
    }];

    return (
        <>
            <Wrapper>
                <WrapperContent>
                    <ErrorOverlay error={error?.message} errorDetails={error?.details}
                        onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null}
                        onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        <PageTitle>
                            <Heading2>Mijn taken</Heading2>
                        </PageTitle>
                        <Section look='white'>
                            <LsGrid
                                dataSource={customDataSource}
                                columns={availableColumns}
                                enableColumnChooser={false}
                                paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                                showRowLines={true}
                                onClickRow={(item: Domain.Finance.Task) => handleRowClick(item)}
                                onDataError={(error) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))}
                            />
                        </Section>
                    </ErrorOverlay>
                </WrapperContent>
            </Wrapper>
            {formDialog.isVisible &&
                (<BudgetJournalForm
                    disableSaveButton={formDialog.isSaving}
                    formMode={formDialog.formMode}
                    isReadonly={!!formDialog.entity.authorId && props.userIdentity.profile.sub !== formDialog.entity.authorId}
                    userIdentity={props.userIdentity}
                    budgetJournal={formDialog.entity}
                    measureMoments={measureMoments.items}
                    users={users.items}
                    yearsAhead={settings.item.yearsAhead}
                    onSave={handleSave}
                    onCancel={handleCancel}
                    onRefresh={onRefresh}
                    budgetJournalGroups={budgetJournalGroups.items}
                    onError={(err) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err))} />
                )
            }
        </>
    );
};

export { TaskList as index }
