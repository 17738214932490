import { createGlobalStyle } from 'styled-components';
import { cssFontNormal } from '../../style';
import { palette } from '../../styleguide';

// tslint:disable:no-magic-numbers

export const ReactDatePickerStyled = createGlobalStyle`
    /* --- DatePicker --- */
    .react-date-picker {
        ${cssFontNormal(18, 20, 30)}
        position: relative;
        height:48px;

        abbr { text-decoration: none; font-family:'Nunito'; } ${``/* week days */}
    }
    .react-date-picker,
    .react-date-picker *,
    .react-date-picker *:before,
    .react-date-picker *:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
    .react-date-picker--disabled {
        background-color: ${palette.grey4};
        color: ${palette.grey3b};
    }
    .react-date-picker__wrapper {
        ${cssFontNormal(16, 20, 30)}
        display: grid;
        grid-template-columns: auto 44px 44px;
        height:48px;
        color: ${palette.grey2};
        border:2px solid #f4f4f8;
        border-radius:6px;
        background:0 none;
        outline:0;
        transition: border-color 0.2s ease;
        align-items: stretch;
        justify-items: stretch;
    }
    /* TODO: feedback op invoer ongeldige datum */
    .react-date-picker__wrapper:invalid { border:2px solid rgba(255, 51, 102, 1); }

    .react-date-picker--open .react-date-picker__wrapper {
        border:2px solid ${palette.primary2};
        border-radius:6px 6px 0 0;
    }

    .react-date-picker__inputGroup {
        align-self: center;
        padding: 0 0 0 24px;
    }
    .react-date-picker__inputGroup__divider {
        color:${palette.grey2};
        padding:0 3px;
    }
    .react-date-picker__inputGroup__input {
        position: relative;
        padding: 0;
        border: 0;
        background: none;
        font-family:'Nunito'; 
        color:${palette.grey2};
        appearance: textfield;
    }
    .react-date-picker__inputGroup__input:focus {
        outline:0;
    }
    .react-date-picker__inputGroup__input::-webkit-outer-spin-button,
    .react-date-picker__inputGroup__input::-webkit-inner-spin-button {
        -webkit-appearance: none;
                appearance: none;
        margin: 0;
    }
    .react-date-picker__inputGroup__input:invalid {
        background: rgba(255, 51, 102, 0.25);
    }
    .react-date-picker__inputGroup__input--hasLeadingZero {
        min-width: calc(4px +  1.08em);
        margin-left: -0.54em;
        padding-left: calc(1px +  0.54em);
    }

    .react-date-picker__calendar-button {
        border: 0;
        padding:0 6px;
        outline:0;
        background-color:${palette.primary4};
        border-radius:0 4px 4px 0;

        &:enabled {
            cursor: pointer;
        }
        &:enabled svg {
            fill:${palette.primary2};
        }
        &:enabled:hover svg {
            fill:#0a16d1;
        }
        &:enabled:focus svg {
            fill: ${palette.primary2};
        }
        &:disabled svg {
            fill: ${palette.grey3b};
        }
        &:focus {
            outline:0;
        }
        & svg {
            margin:0 auto;
            display: inherit;
        }
    }

    .react-date-picker__clear-button {
        border: 0;
        padding:0 6px;
        background: transparent;

        &:enabled {
            cursor: pointer;
        }
        &:enabled svg {
            fill:${palette.grey2};
        }
        &:enabled:hover svg {
            fill:#f36;
        }
        &:enabled:focus svg {
            fill: ${palette.primary2};
        }
        &:disabled svg {
            fill: ${palette.grey3b};
        }
        &:focus {
            outline:0;
        }
        & svg {
            margin:0 auto;
            display: inherit;
            width:16px; height:16px;
        }
    }

    /* calendar wrap */
    .react-date-picker__calendar {
        position: fixed;
        left: auto;
        top: auto;
        width: 515px;
        max-width: 100vw;
        z-index: 1;
    }
    .react-date-picker__calendar--closed {
        display: none;
    }

    /*
    disabled above-label placement, because probs:
        input element before it can't switch border-radius style,
        and above-label is also triggered when using a padding on calendar elements
        was: top: auto;
        */
    .react-date-picker__calendar--above-label {
        position:absolute;
        left:0; 
        top:0;
    }

    /* --- Calendar --- */
    .react-calendar {
        width:100%;
        max-width: 100%;
        margin-top:-1px;
        background: white;
        border:0 none;
        font-family:'Nunito'; 
        font-size:1.1rem; 
        font-weight:400; 
        color:${palette.grey2};
        line-height: 1.125em;
        border-radius:6px;
        box-shadow:0 5px 20px 0 rgba(75, 85, 246, 0.1);
    }
    .react-calendar,
    .react-calendar *,
    .react-calendar *:before,
    .react-calendar *:after {
        box-sizing: border-box;
    }
    .react-calendar button {
        margin: 0;
        border: 0;
        outline: none;
    }
    .react-calendar button:enabled:hover {
        cursor: pointer;
    }
    .react-calendar__navigation {
        height: 56px;
        padding:0 18px;
        border-radius: 0 6px 0 0;
        background-color:${palette.primary2};
        display: flex;
    }

    .react-calendar__navigation + div {
        padding:1em 16px;
    }
    .react-calendar__month-view__weekNumbers + div  {
        align-items:stretch;
    }

    .react-calendar__navigation button {
        min-width: 36px;
        height:56px;
        background-color:${palette.primary2};
        font-family:'Nunito'; 
        font-size:1.1rem; 
        color:#${palette.white};
        background: none;
    }
    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
        background-color:#0a16d1;
    }

    .react-calendar__navigation button.react-calendar__navigation__arrow  {
        width: 36px;
        height: 36px;
        border-radius: 36px;
        margin-top:10px;
        padding:0;
        background-color:${palette.primary2};
    }
    .react-calendar__navigation button.react-calendar__navigation__arrow:enabled:hover,
    .react-calendar__navigation button.react-calendar__navigation__arrow:enabled:focus {
        background-color:rgba(255,255,255,0.2);
    }
    .react-calendar__navigation .MuiSvgIcon-root   {
        fill:#fff;
    }

    .react-calendar__navigation button[disabled] {
        background-color:transparent
    }
    .react-calendar__navigation button[disabled] svg {
        fill:#9998a1;
    }

    .react-calendar__month-view__weekdays {
        text-align: center;
        text-transform: capitalize;
        font-weight: bold;
        font-size:18px;
    }
    .react-calendar__month-view__weekdays__weekday {
        padding: .5em;
    }
    .react-calendar__month-view__weekNumbers {
        font-weight: bold;
    }
    .react-calendar__month-view__weekNumbers .react-calendar__tile {
        font-family:'Nunito';
        display:block;
        width:46px; height:40px;
        border-radius:36px;
        text-align: center;
        padding:0 4px;
        margin:0 auto;
        font-size:16px;
        line-height:40px;
    }
    .react-calendar__month-view__days__day--weekend {
        color: ${palette.grey1};
    }
    .react-calendar__month-view__days__day--neighboringMonth {
        color: ${palette.grey3b};
    }
    .react-calendar__year-view .react-calendar__tile,
    .react-calendar__decade-view .react-calendar__tile,
    .react-calendar__century-view .react-calendar__tile {
        width:auto; 
        height:56px;
        padding:0 .5em;
    }
    .react-calendar__year-view .react-calendar__tile time,
    .react-calendar__decade-view .react-calendar__tile time,
    .react-calendar__century-view .react-calendar__tile time {
        display:block;
        width:auto; 
        height:56px;
        text-align: center;
        padding:0;
        margin:0 auto;
        font-family:'Nunito';
        font-size:16px;
        line-height:56px;
    }
    .react-calendar__tile {
        max-width: 100%;
        height:40px;
        text-align: center;
        padding:0;
        background: none;
    }
    button.react-calendar__tile:disabled {
        background-color: #f0f0f0;
    }

    button.react-calendar__tile:enabled:hover,
    button.react-calendar__tile:enabled:focus {
        background-color: ${palette.primary3};
    }

    button.react-calendar__tile--hasActive {
        background-color: rgba(75, 85, 246, 0.2);
    }
    button.react-calendar__tile--hasActive:enabled:hover,
    button.react-calendar__tile--hasActive:enabled:focus {
        background-color: rgba(75, 85, 246, 0.3);
    }
    button.react-calendar__tile--active {
        background-color: ${palette.primary2};
        color: ${palette.white};
    }
    button.react-calendar__tile--active:enabled:hover,
    button.react-calendar__tile--active:enabled:focus {
        background-color: #0a16d1;
    }
    .react-calendar--selectRange .react-calendar__tile--hover {
        background-color: #e6e6e6;
    }

    .react-calendar__month-view__days button.react-calendar__tile time {
        display:block;
        width:36px; height:36px;
        border-radius:36px;
        text-align: center;
        padding:0;
        margin:0 auto;
        font-family:'Nunito';
        font-size:16px;
        line-height:36px;
    }
    .react-calendar__month-view__days button.react-calendar__tile:enabled:hover time,
    .react-calendar__month-view__days button.react-calendar__tile:enabled:focus time {
        background-color: ${palette.primary3};
    }
    .react-calendar__month-view__days button.react-calendar__tile--active time {
        background-color: ${palette.primary2};
        color: ${palette.white};
    }
    .react-calendar__month-view__days button.react-calendar__tile--active:enabled:hover time,
    .react-calendar__month-view__days button.react-calendar__tile--active:enabled:focus time {
        background-color: #0a16d1;
    }

    .react-calendar__month-view__weekNumbers  {
        border-right:1px solid #707070;
    }
`;
