import * as Domain from '@liasincontrol/domain';
import { createReducer } from '@reduxjs/toolkit';
import { FinanceSettingsActionCreator, TenantActionCreator } from '../../../../actions/creators';
import { AjaxRequestStatus } from '../../../state';

/**
 * Represents the Finance settings reducer.
 */
const reducer = createReducer<{ item: Domain.Finance.Settings, status: AjaxRequestStatus }>({ item: undefined, status: AjaxRequestStatus.NotSet }, (builder) => {
    return (
        builder.addCase(FinanceSettingsActionCreator.set.fulfilled, (state, action) => {
            state = {
                item: action.payload,
                status: AjaxRequestStatus.Done,
            };
            return state;
        }),
        builder.addCase(FinanceSettingsActionCreator.set.pending, (state, action) => {
            // Loading in progress;
            state = {
                item: undefined,
                status: AjaxRequestStatus.Pending,
            };
            return state;
        }),
        builder.addCase(FinanceSettingsActionCreator.set.rejected, (state, action) => {
            state = {
                item: undefined,
                status: AjaxRequestStatus.Failed,
            };
        }),
        builder.addCase(TenantActionCreator.initTenant, (state, action) => {
            state = {
                item: undefined,
                status: AjaxRequestStatus.NotSet,
            };
            return state;
        })
    );
});

export default reducer;
