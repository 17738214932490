import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { loremIpsum } from 'lorem-ipsum';
import { FieldsHelper } from '@liasincontrol/core-service';
import { Features, License } from '@liasincontrol/userrights-service';
import * as Domain from '@liasincontrol/domain';
import { RichTextEditor } from '@liasincontrol/ui-devextreme';
import { HtmlViewer } from '@liasincontrol/ui-basics';
import { TextAssistantAI } from '@liasincontrol/ui-elements';
import { useTextAssistantSkills } from "@liasincontrol/redux-service";
import Styled from "./index.styled";

type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Record<string, Domain.Publisher.Element>,
    selectedElementId: string,
    publicationElement: Domain.Publisher.PublicationElement,
    editableElementIds?: Record<string, { editable: boolean, isLockedByOther: boolean, lockedByUser?: any }>,
    readonly: boolean,
    validElement?: { message: string, isValid: boolean },
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onFieldInlineChanged: (elementId: string, fieldId: string, value: string) => void,
    featureIsAvailable?: (feature: Features) => boolean,
    hasLicense?: (license: License) => boolean,
};

/**
 * Represents a UI component that renders a text control.
 */
const TextControl: React.FC<Props> = (props) => {
    const [currentElement, setCurrentElement] = useState<{
        data: Domain.Publisher.TextControlElement,
        definition: Domain.Shared.ElementDefinition
    }>();
    const [isEditable, setIsEditable] = useState<boolean>();
    const defaultText = useMemo(() => loremIpsum({ count: 30, units: 'words' }), []);
    const [text, setText] = useState<string>(defaultText);

    const [textAssistantAi, setTextAssistantAi] = useState<boolean>(false);
    const hasArtificialIntelligenceAvailable = props.hasLicense?.(License.AITextAssistant);
    const textAssistantSkills = useTextAssistantSkills(hasArtificialIntelligenceAvailable);

    useEffect(() => {
        const element = props.elementList[props.element.elementId];
        if (element) {
            const definition = props.getElementDefinition(element.elementDefinitionSystemId, element.elementDefinitionId);
            const elementSettings = new Domain.Publisher.TextControlElement();
            FieldsHelper.mapObject<Domain.Publisher.TextControlElement>(elementSettings, definition.fields, element.fields);

            setCurrentElement({
                data: elementSettings,
                definition
            });

            setText(elementSettings.text ? elementSettings.text : (elementSettings.allowPatchContent && isEditable ? '' : defaultText));

        }
    }, [props.element.elementId, props.elementList, isEditable]);

    useEffect(() => {
        setIsEditable(!props.readonly &&
            (props.element.elementId === props.selectedElementId) && props.editableElementIds
            && Object.keys(props.editableElementIds).some((key) => key === props.element.elementId && props.editableElementIds[key] && props.editableElementIds[key].editable)
        );

    }, [props.readonly, props.selectedElementId, props.editableElementIds, props.element.elementId]);

    const onChange = (currentValue: string) => {
        const fieldSystemId = Reflect.getMetadata(Domain.Shared.FieldDefinitionMetadataKey, currentElement.data, 'text' as keyof (Domain.Publisher.TextControlElement));
        const fieldDefinition = currentElement.definition.fields.find(item => item.systemId === fieldSystemId);
        const imageReg = /data:image\/([a-zA-Z]*);base64,([^"]*)"/g;
    
        const modifiedHTML = wrapTablesWithDiv(injectTargetBlank(currentValue));

        if (fieldDefinition && !imageReg.test(modifiedHTML)) {
            props.onFieldInlineChanged(props.element.elementId, fieldDefinition.id, modifiedHTML);
        }
    };

    const setTextAssistantCallback = useCallback(() => setTextAssistantAi(true), []);

    return (
        <Styled.TextControlWrapper
            primaryColor={props.publicationElement.primaryColor}
            primaryTextColor={props.publicationElement.primaryContrastColor}
            textColor={props.publicationElement.bodyFontColor}
            textFontSize={props.publicationElement.bodyFontSize}
            h1FontSize={+props.publicationElement?.h1FontSize} h1FontColor={props.publicationElement?.h1FontColor}
            h2FontSize={+props.publicationElement?.h2FontSize} h2FontColor={props.publicationElement?.h2FontColor}
            h3FontSize={+props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}
            editMode={false}
            className='dx-widget custom-text-input'>
            {(currentElement?.data?.allowPatchContent && isEditable)
                ? <RichTextEditor
                    id={currentElement?.definition?.id}
                    className='dx-item-content '
                    value={text}
                    onChange={(changedText) => {
                        setText(changedText);
                        if (!props.validElement.isValid) {
                            onChange(text);
                        }
                    }}
                    onFocusOut={() => {
                        onChange(text);
                    }}
                    withTextAssistant={hasArtificialIntelligenceAvailable}
                    onTextAssistant={setTextAssistantCallback}
                    onContentReady={(e) => {
                        e.component?.instance()?.focus();
                        // Set the cursor to the end of the text, when edit                        
                        //e.component?.instance()?.setSelection(text.length, 0);
                    }}
                />
                : <HtmlViewer id={currentElement?.definition?.id} className='dx-item-content richtext-viewer' value={text} />
            }
            {hasArtificialIntelligenceAvailable && textAssistantAi &&
                <TextAssistantAI
                    textAssistantSkills={textAssistantSkills?.items}
                    textContent={text}
                    onCancel={() => setTextAssistantAi(false)}
                />}
        </Styled.TextControlWrapper>
    );
};

const injectTargetBlank = (html: string): string => {
    const anchorRegex = /<a(\s[^>]*)?>/g;

    const replaceAnchor = (match: string) => {
        if (!match.includes('target="_blank"')) {
            return match.replace('<a', '<a target="_blank" ');
        }

        return match;
    };

    const modifiedHTML = html.replace(anchorRegex, replaceAnchor);

    return modifiedHTML;
};
  
const wrapTablesWithDiv = (html: string) => {
    return html.replace(/<table([^>]*)>/g, '<div class="table-wrapper"><table$1>').replace(/<\/table>/g, '</table></div>');
};


export default TextControl;
