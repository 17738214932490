import React, { useState } from 'react';
import { AutoFocus, ModalDialog, ModalDialogFooter, Text, WarningLook, WarningWrapper } from '@liasincontrol/ui-basics';
import Styled from './index.styled';

type Props = {
    readonly publicationId: string,
    readonly publicationName: string,
    readonly disableSaveButton: boolean,
    readonly onDelete: (publicationId: string) => void,
    readonly onCancel: () => void,
}

/**
 * Represents an UI component that opens a dialog and asks the user if he wants to delete a publication.
 */
export const PublicationDelete: React.FC<Props> = (props) => {
    const [valueOptions, setValueOptions] = useState<{ value: string, confirmed: boolean, touched: boolean }>({ value: '', confirmed: false, touched: false });

    const footer = (
        <ModalDialogFooter
            leftButtonText='Annuleren' onLeftButtonClick={props.onCancel}
            rightButtonText='Verwijderen' onRightButtonClick={() => props.onDelete(props.publicationId)}
            rightButtonDisabled={!valueOptions.confirmed || props.disableSaveButton}
        />
    );
    return (
        <ModalDialog id="dialog"
            settings={{
                look: "message", title: "Publicatie verwijderen", footer: footer
            }}>
            <Text value="U staat op het punt om een publicatie te verwijderen. Wanneer een publicatie wordt verwijderd zullen alle bijbehorende gegevens verloren gaan (gepubliceerde website, templates, alle content, etc)." />
            <WarningWrapper
                look={WarningLook.danger}
                className='mb-100'
                messageText='LET OP: U kunt deze stap niet ongedaan maken.' />

            <AutoFocus>
                <Styled.InputFieldCustom
                    id="inputRemovePublication"
                    key="inputRemovePublication"
                    label={<>Voer de naam van de publicatie <Styled.NameSpan>"{props.publicationName}"</Styled.NameSpan> in om het verwijderen te bevestigen (hoofdletter gevoelig)</>}
                    placeholder="Type hier…"
                    value={valueOptions.value}
                    onChange={(value: string) => {
                        setValueOptions({ value, confirmed: value === props.publicationName, touched: true })
                    }}
                    success={valueOptions.confirmed}
                    error={valueOptions.touched && !valueOptions.confirmed && 'De naam komt niet overeen'}
                />
            </AutoFocus>
        </ModalDialog>
    );
};