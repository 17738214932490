import { DateUtils } from '@liasincontrol/core-service';
import { formatNumber } from "devextreme/localization";

const integerFormatter = (value = 0, formatPattern = "#0,###") => {
    if (!formatPattern.trim())
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    return formatNumber(value, formatPattern);
};

const decimalFormatter = (value = 0, formatPattern = "#0,###.##") => {
    if (!formatPattern.trim())
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return formatNumber(value, formatPattern);
};

const dateFormatter = (value) => {
    if (!value) return null;

    let dateValue = value;
    if ((value instanceof Date) === false) {
        const str = value as string;
        dateValue = new Date(str);
    }
    const formattedNLDate = DateUtils.formatDate(dateValue);
    return formattedNLDate;
};

const dateTimeFormatter = (value, formatPattern = 'DD-MM-YYYY [om] HH:mm:ss') => {
    if (!value) return null;

    let dateTimeValue = value;
    if ((value instanceof Date) === false) {
        const str = value as string;
        dateTimeValue = new Date(str);
    }
    const formattedNLDateTime = DateUtils.formatDateTime(dateTimeValue, formatPattern);
    return (formattedNLDateTime);
};

export { integerFormatter, decimalFormatter, dateFormatter, dateTimeFormatter };
