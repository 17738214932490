import React, { useState, useMemo } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import TextBox from 'devextreme-react/text-box';
import Box, { Item } from 'devextreme-react/box';
import List from 'devextreme-react/list';
import { PageComponentSelectProps } from './index.props';
import Styled from './index.styled';

const CreateDataSource = (items: any, searchExpr: any, searchValue: string, selectedCategory?: number): DataSource => {
    const cleanedValue = searchValue.toLowerCase();
    const source = new DataSource({
        paginate: false,
        store: new CustomStore({
            load: () => items,
            loadMode: 'raw'
        }),
        searchExpr: searchExpr,
    });

    if (selectedCategory) {
        source.filter(['group', '=', selectedCategory]);
    }
    if (cleanedValue) {
        source.searchValue(cleanedValue);
    }
    return source;
};

/**
 * Represents a UI component that renders a scrollable page component list editor.
 */
const PageComponentSelect: React.FC<PageComponentSelectProps> = (props) => {
    const [searchValue, setSearchValue] = useState('');
    const [selectedCategory, setSelectedCategory] = useState<{ name?: string, id?: number }>(props.groups?.[0]);

    const customDataSource = useMemo(() => {
        return CreateDataSource(props.items, props.searchExpr, searchValue, selectedCategory.id);
    }, [props.items, props.searchExpr, searchValue, selectedCategory]);

    return (
        <Box direction="row">
            <Item ratio={1}>
                <TextBox
                    mode='search'
                    placeholder='Zoeken...'
                    elementAttr={{
                        id: `input-search-${props.id}`,
                        class: 'mb-050'
                    }}
                    valueChangeEvent="keyup"
                    onValueChanged={(e) => {
                        setSelectedCategory(props.groups?.[0]);
                        setSearchValue(e.value);
                    }}
                    value={searchValue}
                >
                </TextBox>

                <List
                    items={props.groups}
                    className='lias-list lias-buttonlist mt-050'
                    displayExpr="name"
                    selectionMode='single'
                    selectedItems={[selectedCategory]}
                    onItemClick={(item) => {
                        setSelectedCategory(item.itemData);
                    }}
                />
            </Item>
            <Item ratio={5}>
                <Styled.StyledTileView
                    dataSource={customDataSource}
                    itemRender={props.itemRender}
                    itemMargin={10}
                    direction='vertical'
                    baseItemHeight={props.baseHeight ? props.baseHeight + 40 : undefined}
                    baseItemWidth={props.baseWidth ? props.baseWidth + 40 : undefined}
                    height='inherit'
                    noDataText='Er komen geen iconen overeen met de zoekcriteria'
                    showScrollbar='never'
                    onItemClick={(item) => {
                        props.editorSettings?.onChange?.(item.itemData);
                    }}
                />
            </Item>
        </Box>

    );
};

export default PageComponentSelect;