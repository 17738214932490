import { px } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

export const lineHeightCoefficient = 1.5;

const Footer = styled.div`
    padding: ${px(16)} 0 ${px(8)} ${px(16)};
    text-align: center;
    font-style: italic;
`;

const Title = styled.h4<{
    h3FontColor: string,
    h3FontSize: string
}>`
    color: ${(props) => (props.h3FontColor && props.h3FontColor)};
    font-size: ${(props) => (props.h3FontSize && `${props.h3FontSize}px`)};
    line-height: ${(props) => (props.h3FontSize && `${+props.h3FontSize * lineHeightCoefficient}px`)};
    margin-bottom: 0 !important;
    word-wrap: break-word;
`;

const ThumbnailWrapper = styled.div`
    max-width: ${px(400)};
    height: ${px(240)};
    margin: 0 auto;
    text-align: center;
`;

const Description = styled.div<{
    textFontColor?: string,
    textFontSize?: string,
}>`
    padding: ${px(8)} 0;
    font-style: italic;
    
    color: ${(props) => (props.textFontColor && props.textFontColor)};
    font-size: ${(p) => (p.textFontSize ? `${+p.textFontSize * 0.8}px` : 'inherit')};
    line-height: ${(p) => (p.textFontSize && `${+p.textFontSize * 0.8 * lineHeightCoefficient}px`)};

`;

const GridWrapper = styled.div<{
    primaryColor: string,
    primaryTextColor: string,
    textColor: string,
    textFontSize?: string,
    h1FontColor?: string,
    h1FontSize?: number,
    h2FontColor?: string,
    h2FontSize?: number,
    h3FontColor?: string,
    h3FontSize?: number,
    editMode: boolean
}>`
    
    pointer-events: ${({ editMode }) => (editMode ? `none` : `auto`)};    
    font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
    line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px!important` : 'inherit')};
    color: ${({ textColor }) => `${textColor}!important`};

    &.custom-text-input {
        white-space: pre-wrap;
        overflow-x: auto;
    }

    .dx-widget {
        color: inherit;
        font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
        line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px!important` : 'inherit')};

        //fix toolbars fonts and colors
        .dx-toolbar .dx-toolbar-items-container {
            height: ${(p) => (p.textFontSize ? `${+p.textFontSize * 2}px` : '36px')};
        }
        
        .dx-htmleditor-toolbar .dx-toolbar-items-container {
            height: unset;
        }

        .dx-toolbar-item, .dx-datagrid-group-panel {
            font-size: ${(p) => (p.textFontSize ? `${+p.textFontSize * 0.8}px` : 'inherit')};
            line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * 0.8 * lineHeightCoefficient}px` : 'inherit')};
        }
        
        .dx-toolbar-menu-section .dx-toolbar-hidden-button .dx-button-normal {
            font-size: initial!important;
            line-height: initial!important;
        }

        .dx-header-row {
            background-color: ${({ primaryColor }) => primaryColor};
            color: ${({ primaryTextColor }) => primaryTextColor};
            font-weight: 700;
        }

        .dx-data-row {
            border-color: #cccccc;       
            color: ${({ textColor }) => textColor};     
        }       
    }

    .dx-item-content, .dx-data-row {   
        font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
        line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px!important` : 'inherit')};

        h2 {
            color: ${(props) => (props.h1FontColor && props.h1FontColor)};
            font-size: ${(props) => (props.h1FontSize && `${props.h1FontSize}px`)};
            line-height: ${(props) => (props.h1FontSize && `${props.h1FontSize * lineHeightCoefficient}px`)};
            margin-bottom: 0 !important;
        }

        h3 {
            color: ${(props) => (props.h2FontColor && props.h2FontColor)};
            font-size: ${(props) => (props.h2FontSize && `${props.h2FontSize}px`)};
            line-height: ${(props) => (props.h2FontSize && `${props.h2FontSize * lineHeightCoefficient}px`)};
            margin-bottom: 0 !important;
        }

        h4 {
            color: ${(props) => (props.h3FontColor && props.h3FontColor)};
            font-size: ${(props) => (props.h3FontSize && `${props.h3FontSize}px`)};
            line-height: ${(props) => (props.h3FontSize && `${props.h3FontSize * lineHeightCoefficient}px`)};
            margin-bottom: 0 !important;
        }
        
        label {
            display: block !important;
            font-weight: 600;         
        }

        table {
            border-collapse: collapse;
            width: 100%;
            table-layout: auto;

            thead > tr {
                background-color: ${(p) => (p.primaryColor && p.primaryColor)};
                font-weight: 700;
                border-bottom: 2px solid #cccccc;

                p {
                    color: ${(p) => (p.primaryTextColor && p.primaryTextColor)};
                }
            }

            td, th {
                outline: 0;
                padding: ${px(5)};
                border: 1px solid #cccccc;

                p {
                    margin-bottom: 0 !important;
                    padding: ${px(3)};
                }
            }
        }

        ul li, ol li {
            margin-bottom: ${(p) => (p.textFontSize && `${+p.textFontSize * 0.2}px`)};
        }        
    }
`;

const ExpandCollapseDiv = styled.div`
    text-align: right;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
`;

const styledComponents = { GridWrapper, Footer, Title, Description, ThumbnailWrapper, ExpandCollapseDiv };
export default styledComponents;
