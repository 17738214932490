import styled, { keyframes } from 'styled-components';
import { cssBreakpoint } from '../responsive/css';
import { px } from '../style';
import { palette } from '../styleguide';
import { Internals } from '../button';
import { cssElementLabel, cssHeading2 } from '../primitives/typography';
import { IconValue } from '../primitives';

const Container = styled.nav<{ collapsed: boolean }>`
    height: 100%;
    min-width: 0;
    background-color: ${palette.white};
    width: ${({ collapsed }) => (collapsed ? px(48) : px(280))};
    transition: width 0.3s ease;
    ${cssBreakpoint('s', 'xs')`
        padding: ${px(6)};
    `}
`;

//menugroup
const fadeIn = keyframes`
    0% {transform: translateY(${px(5)}); opacity: 0;} 
    50%{ transform: translateY(${px(5)}); opacity: 0; } 
    100% { transform: translateY(0%); opacity: 1; }
`;

const GroupLabel = styled.div`
    ${cssHeading2};
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: ${px(8)};
    color: ${palette.grey2};
`;

const GroupListbox = styled.div`
    display: flex;
    flex-flow: column;
    margin-bottom: ${px(12)};
    animation: ${fadeIn} 0.2s ease;
`;

// menuitem
const ButtonContainer = styled.span<{ collapsed: boolean; active: boolean }>`
    text-decoration: none;
    position: relative;
    
    ${({ collapsed }) => collapsed
        ? Internals.StyleGuideButtons.iconbuttons.medium_admin.container
        : Internals.StyleGuideButtons.textbuttons.large_icon.container
    }
    overflow: hidden;
    text-overflow: ellipsis;
    > span {
        background-color: ${({ active }) => active && palette.grey4};
        border: 0 !important;
    }
`;

const iconColor = (value: IconValue) => {
    switch (value) {
        case IconValue.Publisher: return `
            background-color: ${palette.primary1c};
            > svg {
                fill: ${palette.melrose2};
            }
        `;
        case IconValue.Performance: return `
            background-color: ${palette.blue};
            > svg {
                fill: ${palette.lightBlue};
            }
        `;
        case IconValue.Finance: return `
            background-color: ${palette.darkGreen};
            > svg {
                fill: ${palette.lightGreen};
            }
        `;
        case IconValue.Studio: return `
            background-color: ${palette.darkPink};
            > svg {
                fill: ${palette.lightPink};
            }
        `;
        case IconValue.Risk: return `
            background-color: ${palette.red};
            > svg {
                fill: ${palette.lightRed};
            }
        `;
        case IconValue.Datawarehouse: return `
            background-color: ${palette.yellow};
            > svg {
                fill: ${palette.lightYellow};
            }
        `;
        case IconValue.User: return `
            background-color: ${palette.darkBrown};
            > svg {
                fill: ${palette.lightBrown};
            }
        `;
        case IconValue.Growth: return `
            background-color: ${palette.darkOrange};
            > svg {
                fill: ${palette.lightOrange};
            }
        `;
        case IconValue.AI: return `
            background-color: ${palette.darkSeaGreen};
            > svg {
                fill: ${palette.lightSeaGreen}; 
                stroke: ${palette.lightSeaGreen}; 
            }
        `;
        default: return ``;
    }
};

const ButtonContent = styled.span<{ collapsed: boolean, value: IconValue }>`
    padding-left: 0 !important; 
    ${Internals.cssInternalButtonContent}
    grid-template-columns: ${({ collapsed }) => (collapsed ? null : `min-content auto`)};
    justify-items: ${({ collapsed }) => (collapsed ? null : `start`)};
    > div {
        ${({ value }) => iconColor(value)}
    }
`;

const ButtonText = styled.p`
    ${cssElementLabel}
    white-space: nowrap;
`;

const HiddenText = styled.p`
    display: none;
`;

const styledComponents = { Container, GroupLabel, GroupListbox, ButtonContainer, ButtonContent, ButtonText, HiddenText };
export default styledComponents;