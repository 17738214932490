import { BaseValidationRestriction, BaseValidator, CustomContextValidator } from './BaseValidator';
import { ValidationErrorData, ValueType } from './Types';

/**
 * Defines the restrictions that can be placed on a Date validator.
 */
export interface DateValidationRestriction extends BaseValidationRestriction {
    minDateValue?: string;
    maxDateValue?: string;
}

type Restrictions = { required: boolean, dateMinValue?: string, dateMaxValue?: string };

/**
 *  Defines a helper class that can be used to validate a Date field.
 */
export class DateValidator extends BaseValidator<Date> {
    public constructor(restrictions: Restrictions, contextValidator?: CustomContextValidator<Date>) {
        super(restrictions.required);
        this.restrictions = {
            minDateValue: restrictions.dateMinValue,
            maxDateValue: restrictions.dateMaxValue,
        };
        this.contextValidator = contextValidator;
    }

    private restrictions: DateValidationRestriction = {};
    private contextValidator: CustomContextValidator<Date>;

    public validate(value: Date, formData?: Record<string, ValueType>): ValidationErrorData[] {
        const errors: ValidationErrorData[] = super.validate(value);
        if (errors.length > 0) {
            return errors;
        }

        if (value && this.restrictions.minDateValue && value.getTime() < Date.parse(this.restrictions.minDateValue)) {
            errors.push({ error: 'Datum valt voor de startdatum.' });
        } else if (value && this.restrictions.maxDateValue && value.getTime() > Date.parse(this.restrictions.maxDateValue)) {
            errors.push({ error: 'Datum valt na de einddatum.' });
        }

        if (this.contextValidator) {
            errors.push(...this.contextValidator(value, formData));
        }

        return errors;
    }

    /**
     * Gets the date restrictions.
     */
    public getRestrictions = (): DateValidationRestriction => {
        return Object.assign({}, this.restrictions, this.baseRestrictions);
    };
}
