import React from 'react';
import { ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';
import { IndicatorSize, LoadIndicator } from '@liasincontrol/ui-devextreme';
import * as Domain from '@liasincontrol/domain';

type Props = {
    selectedWorkflowTemplate: Domain.Shared.WorkflowTemplateWithStates,
    isBusy: boolean,
    onConfirm: (selectedWorkflowId: string) => void,
    onCancel: () => void,
};

/**
 * Represents an UI component that opens a confirmation dialog to delete a workflow template.
 */
const WorkflowTemplateDeleteDialog: React.FC<Props> = (props) => {
    return (
        <ModalDialog
            id='dialog-delete-workflow-template'
            settings={{
                look: 'message',
                title: 'Verwijder workflow',
                footer: <ModalDialogFooter leftButtonText='Annuleren' onLeftButtonClick={props.onCancel} rightButtonText='Verwijder workflow' rightButtonDisabled={props.isBusy} onRightButtonClick={() => props.onConfirm(props.selectedWorkflowTemplate.id)} />
            }}
        >
            {props.isBusy
                ? <LoadIndicator variant={IndicatorSize.extralarge} />
                : <Text danger={true} value='Weet u zeker dat u het proces wilt verwijderen?' />
            }
        </ModalDialog>
    );
};

export { WorkflowTemplateDeleteDialog };