import React from 'react';
import Styled from './index.styled';
import { withField1, WithFieldBaseType } from "@liasincontrol/ui-basics";

export type TagBoxFieldProps = WithFieldBaseType<any[]> & {
    showClearButton?: boolean,
    showDropDownButton?: boolean,
    openOnFieldClick?: boolean,
    acceptCustomValue?: boolean,
    items?: any[],
    displayExpr: string | ((item) => string),

}

export const TagBoxField = withField1<any[], TagBoxFieldProps>(
    (props) => {
        return <Styled.StyledTagBox
            id={props.id}
            key={props.id}
            value={props.value}
            showClearButton={props.showClearButton}
            showDropDownButton={props.showDropDownButton}
            openOnFieldClick={props.openOnFieldClick}
            acceptCustomValue={props.acceptCustomValue}
            onValueChanged={(e) => props.onChange?.(e.value, e.previousValue)}
            onChange={undefined}
            placeholder="Kies..."
            items={props.items}
            displayExpr={props.displayExpr}
            inputAttr={{ 'aria-label': props.label, 'id': props.id }}
            hideSelectedItems={true}
        />;
    }
);