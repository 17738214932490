import React, { useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { cssFontNormal, px } from '../../style';
import { palette } from '../../styleguide';
import { BasicText } from '../typography';

export enum InputAlignment {
  Left = 'left',
  Right = 'right',
}

type Sub<O extends PropertyKey, D extends PropertyKey> = {
  [K in O]: (Record<D, never> & Record<PropertyKey, K>)[K]
}[O];
type Omit<O, D extends string> = Pick<O, Sub<keyof O, D>>;

export const InputText = React.memo<IInputProps & Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof IInputProps>>(
  ({
    as: As,
    align = InputAlignment.Left,
    error,
    focused,
    postfix,
    prefix,
    success,
    ...props
  }) => {
    const Input = useMemo(
      () => (As ? StyledInput.withComponent(As) : StyledInput),
      [As],
    );
    return (
      <Container>
        <Input
          type="text"
          align={align}
          focused={!!focused}
          hasPrefix={!!prefix}
          hasPostfix={!!postfix}
          {...props}
        />
        {prefix && <Prefix>{prefix}</Prefix>}
        {postfix && <Postfix>{postfix}</Postfix>}
      </Container>
    );
  },
);

// tslint:disable:no-magic-numbers

const Container = styled.div`
  position: relative;
`;

/* tslint:disable:no-magic-numbers */
const BasicTextInput = BasicText.withComponent(`input`);
const StyledInput = styled(BasicTextInput)`
    width:100%;
    max-width:100%;
    padding:${px(12)} ${px(16)};
    margin:0;
    color:${palette.grey2};
    text-overflow:ellipsis;
    border-radius:4px;
    background:0 none;
    outline:0;
    transition:border-color 0.3s ease;
    background: ${palette.white};
    white-space: nowrap;
    ::-ms-clear {
        display: none;
    }

    ${({ disabled }: IInputRenderProps) =>
    !disabled
      ? css`
            border: 2px solid #f4f4f8;
            &:hover {
              border: 2px solid rgba(75, 85, 246, 0.15);
            }
            &:focus {
              border: 2px solid rgba(75, 85, 246, 1);
            }
          `
      : css`
            border: 2px solid #f4f4f8;
            background-color: #f4f4f8;
          `}

    ${({ hasPrefix }: IInputRenderProps) =>
    !hasPrefix
      ? null
      : css`
            padding-left: ${px(40)};
          `}
    ${({ hasPostfix }: IInputRenderProps) =>
    !hasPostfix
      ? null
      : css`
            padding-right: ${px(40)};
          `}

    ${({ align }: IInputRenderProps) =>
    align === `right`
      ? css`
            text-align: right;
          `
      : css`
            text-align: left;
          `}
`;

const animationSlideFromLeft = keyframes`
    from { opacity: 0; transform: translate(-4px, 0); }
    to { opacity: 1; transform: translate(0, 0); }
`;

const animationSlideFromRight = keyframes`
    from { opacity: 0; transform: translate(4px, 0); }
    to { opacity: 1; transform: translate(0, 0); }
`;

const Prefix = styled.div`
  ${cssFontNormal(14, 20, 30)}
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${px(40)};
  padding-left: ${px(8)};
  display: grid;
  align-items: center;
  justify-content: center;
  animation: ${animationSlideFromLeft} 0.3s;
`;

const Postfix = styled.div`
  ${cssFontNormal(14, 20, 30)}
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: ${px(40)};
  padding-right: ${px(8)};
  display: grid;
  align-items: center;
  justify-content: center;
  animation: ${animationSlideFromRight} 0.3s;
`;

export interface IInputProps {
  readonly as?: React.ComponentType<IInputRenderProps>;
  readonly align?: InputAlignment;
  readonly id?: string;
  readonly success?: boolean;
  readonly error?: string | React.ReactNode;
  readonly disabled?: boolean;
  readonly focused?: boolean;
  readonly value: string;
  readonly prefix?: React.ReactNode;
  readonly postfix?: React.ReactNode;
}

export interface IInputRenderProps extends React.InputHTMLAttributes<HTMLInputElement> {
  readonly align: InputAlignment;
  readonly focused: boolean;
  readonly hasPrefix: boolean;
  readonly hasPostfix: boolean;
}
