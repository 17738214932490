import styled from 'styled-components';
import { base, TextButtons } from '../button/internals';
import { px } from '../style';
import { palette } from '../styleguide';

const Wrap = styled.div`
    position: relative;
    z-index: 300;
    button {
        background-color: ${palette.white};
    }
`;

const Dropdown = styled.div`
    position: absolute;    
    ul {
        list-style: none;
        padding: 0;
        margin: ${px(8)} 0;
        border-radius: ${px(4)};
        overflow: hidden;
        background-color: ${palette.white};    
        position: relative;
        box-shadow: 0 0 ${px(20)} rgba(75, 85, 246, 0.3);;
        li {
            button {
                ${TextButtons.medium_noicon.text}
                ${base.colors(palette.primary2, palette.white)}
                padding: 0 ${px(16)};
                width: 100%;
                white-space: nowrap;
                span {
                    align-items: left;
                    justify-items: left;
                }
            }
            &:hover button {
                background: rgba(0, 0, 0, 0.05);
            }
        }        
    }
`;

const styledComponents = { Wrap, Dropdown };
export default styledComponents;