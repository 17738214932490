import { createAction } from '@reduxjs/toolkit';
import Payload from '../../../../Payload';

/**
 * Represents the data of the payload.
 */
type Data = {
    attachmentId: string
};

/**
 * Represents an action that triggers the store to remove an attachment.
 */
const action = createAction<Payload<Data>>('/attachments/remove');
export default action;
