import styled from 'styled-components';
import { px } from '@liasincontrol/ui-basics';
import { lineHeightCoefficient } from '../../../Controls/SharedControlElements/index.styled';

const AccordionContainer = styled.div<{
    primaryPublicationColor?: string,
    textFontSize?: string,
    textColor?: string,
}>`
    display: block;
    position: relative;
    padding: 0 ${px(8)} ${px(8)} ${px(8)};
    
    font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px` : 'inherit')};
    line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px` : 'inherit')};

    .dx-widget {
        color: inherit!important;
        font-size: inherit!important;
        line-height: inherit!important;
    }
    .dx-accordion-item.dx-accordion-item-opened {
        overflow: visible;
    }
   
    .dx-accordion-item-title {
        color: inherit;
        font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
        line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px!important` : 'inherit')};
        font-weight: 500; 
        border-bottom: 1px solid #cccccc;
        overflow: hidden;       

        &::before{
            font-size: inherit;
            line-height: inherit;
            color: inherit; 
        }
        .dx-accordion-item-title-caption {
            font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
            line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px!important` : 'inherit')};
            font-weight: 500;
            color: ${({ textColor }) => textColor};

            .dx-icon {
                display: inline-block;
                margin-right: 0.5rem;
                font-size: ${(p) => (p.textFontSize ? `${+p.textFontSize * 0.8}px` : 'inherit')};
                line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * 0.8 * lineHeightCoefficient}px` : 'inherit')};
                color: ${({ textColor }) => textColor};
                width: ${(p) => (p.textFontSize ? `${Math.ceil(+p.textFontSize * 1.2)}px` : 'inherit')};
                height: ${(p) => (p.textFontSize ? `${Math.ceil(+p.textFontSize * 1.2)}px` : 'inherit')};
            }

            svg {
                fill: ${({ textColor }) => textColor};
                stroke: ${({ textColor }) => textColor};
                width: ${(p) => (p.textFontSize ? `${Math.ceil(+p.textFontSize * 1.2)}px` : 'inherit')};
                height: ${(p) => (p.textFontSize ? `${Math.ceil(+p.textFontSize * 1.2)}px` : 'inherit')};
                margin-right: 0.5rem;
            }
        }
    } 

    .dx-accordion-item-body {
        font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
        line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px!important` : 'inherit')};     
        position: relative;  
        overflow: visible;
    }

`;

const PlaceholderWrapper = styled.div`
    padding-top: 17px;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;  
`;

const PlaceholderContent = styled.div`
    max-width: ${px(350)};
    margin: 10px auto;
    text-align: center;
`;

const ItemHeader = styled.div`
    white-space: nowrap;
    padding: 6px 12px;
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center; 
    margin-right: 16px;  
    overflow: hidden;    
`;

const ItemName = styled.div`
    display: inline-flex;
    width: auto;
    align-items: center;
    flex-grow: 1;
    justify-content: start;
    flex-direction: 'row';
`;

const Overflow = styled.div`
    margin-top: 20px; 
    padding-right: ${px(20)};    
    max-height: calc(100% - 50px);
    overflow-y: auto;
`;

const styledComponents = {
    AccordionContainer, PlaceholderContent, PlaceholderWrapper, ItemHeader, ItemName, Overflow
};
export default styledComponents;