import { createAction } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import Payload from '../../../../Payload';

/**
 * Represents the data of the payload.
 */
type Data = {

    /**
     * Defines the page id where the patches shall be updated.
     */
    pageId: string,

    /**
     * Defines the list of new changes to apply.
     */
    set: {
        /**
         * Defines the element unique identifier.
         */
        elementId: string,

        /**
         * Defines the complex field definition unique identifier.
         */
        complexFieldDefinitionId: string,

        /**
         * Defines the complex field row index used as an unique identifier.
         */
        rowIndex: number,

        /**
         * Defines the complex field element.
         */
        complexField: Domain.Shared.ComplexField,

    }[];

    /**
     * Defines the list of new changes to remove.
     */
    remove: {
        /**
         * Defines the element unique identifier.
         */
        elementId: string,

        /**
         * Defines the complex field definition unique identifier.
         */
        complexFieldDefinitionId: string,

        /**
         * Defines the complex field row index used as an unique identifier.
         */
        rowIndex: number
    }[];

}

/**
 * Represents an action that triggers the store to update complex field patches.
 */
const action = createAction<Payload<Data>>('/patches/updateComplexField');
export default action;
