import { palette, px } from '@liasincontrol/ui-basics';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

const AttachmentItem = styled.div`
    display: flex;
    padding: ${px(2)} ${px(3)};
`;

const AttachmentLabel = styled.label`
    font-weight: 600;
    font-size: ${px(14)};
    cursor: pointer;
    flex: 1;

    &:hover {
        text-decoration: underline;
    };
`;

const RemoveAttachmentIcon = styled(CloseIcon)`
    fill: ${palette.validationNeg};
    width: ${px(16)};
    height: ${px(16)};
    margin: 0 ${px(5)};
    cursor: pointer;
`;

const AttachmentAudit = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${px(5)};
`;

const AttachmentAuditItem = styled.div`
    display: flex;
    padding: ${px(2)} ${px(3)};
`;

const styledComponents = {
    AttachmentItem, AttachmentLabel, RemoveAttachmentIcon, AttachmentAudit, AttachmentAuditItem
};

export default styledComponents;
