import { debounce } from 'lodash/fp';
import { useState, useEffect, useMemo } from 'react';

export function useDebounce<T>(timeout: number, input: T) {
    const [state, setState] = useState(input);

    const debouncedSetState = useMemo(() => debounce(timeout, setState), [
        timeout,
    ]);

    useEffect(() => {
        debouncedSetState(input);
    }, [debouncedSetState, input]);

    return state;
}