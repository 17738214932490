import styled, { keyframes } from 'styled-components';
import { px } from '../style';
import { palette } from '../styleguide';


const fadeIn = keyframes`
0% {
    opacity: 0.0;
    transform: translate(0, ${px(-4)});
}
30% {
    opacity: 0.0;
    transform: translate(0, ${px(-4)});
}
100% {
    opacity: 1.0;
    transform: translate(0, 0);
}
`;

const fadeOut = keyframes`
0% {
    opacity: 1.0;
    filter: blur(0) grayscale(0%);
}
30% {
    opacity: 0.5;
    filter: blur(0) grayscale(50%);
}
100% {
    opacity: 0.2;
    filter: blur(${px(6)}) grayscale(100%);
}
`;

const Container = styled.div`
    position: relative;
    height: 100%;
`;

const Message = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    text-shadow: 0 ${px(2)} ${px(6)} #fff;
    animation: ${fadeIn} .3s ease-in forwards;
`;

const Faded = styled.div`
    pointer-events: none;
    opacity: 0.1;
    animation: ${fadeOut} .3s ease-out forwards;
`;

const SubTitle = styled.div`
    text-align:left;
    grid-area: subtitle;
    color: ${(p: { look?: 'default' | 'inverted' }) => p.look === 'inverted' ? palette.white : palette.grey3};
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 0.875rem;
`;

export { Faded, Message, Container, SubTitle };