import { createAction } from '@reduxjs/toolkit';
import Payload from '../../../../Payload';

/**
 * Represents the data of the payload.
 */
type Data = {

    /**
     * Defines the page id where the patches shall be updated.
     */
    pageId: string,

    /**
     * Defines the list of new changes to apply.
     */
    set: {
        /**
         * Defines the element unique identifier.
         */
        elementId: string,

        /**
         * Defines the field unique identifier.
         */
        fieldId: string,

        /**
         * Defines the value.
         */
        value: string
    }[];

    /**
     * Defines the list of new changes to remove.
     */
    remove: {
        /**
         * Defines the element unique identifier.
         */
        elementId: string,

        /**
         * Defines the field unique identifier.
         */
        fieldId: string
    }[];

}

/**
 * Represents an action that triggers the store to update patches.
 */
const action = createAction<Payload<Data>>('/patches/updateField');
export default action;
