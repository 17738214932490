import styled from 'styled-components';

const TagListItem = styled.div`
  > .group-count {
    float: right;
  }
`;

const styledComponents = {
  TagListItem
} 

export default styledComponents;