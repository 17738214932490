import React, { useMemo, useLayoutEffect, useState } from 'react';
import _, { isUndefined } from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { DxTemplate, DxTreeList, DxColumn, DxSearchPanel, LiasContextMenu, StyledDraggableWrapper } from '@liasincontrol/ui-devextreme';
import { FieldsHelper, IconHelper } from '@liasincontrol/core-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { IconSize, SVGIcon } from '@liasincontrol/ui-basics';
import Styled from '../../_shared/OptionItem/index.styled';
import './index.style.less';
import { DeleteElementDialog } from './DeleteElementDialog';
import { HierarchyItemReparentDialog } from './HierarchyItemReparentDialog';

type Props = {
    userIdentity: UserIdentity,
    elementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
    hierarchyDefinition: Domain.Shared.HierarchyDefinition,
    hierarchyItems: Domain.Studio.HierarchyItem[],
    isMeasureMomentClosed: boolean,
    selectedType: Domain.Shared.ElementDefinition,
    availableTypes: Domain.Shared.ElementDefinition[],
    users: Domain.Shared.User[],
    workflowDictionary: Record<string, Domain.Shared.WorkflowTemplateWithStates>,

    expandedRowKeys?: string[],
    onRowExpanding?: (e: { key: string }) => void
    onRowCollapsing?: (e: { key: string }) => void

    onCreateEntity: (elementType: string, parentEntityId: string) => void,
    onEditEntity: (entityId: string) => void,
    onDeleteEntity: (entityId: string) => void,
    onReparentEntity: (entityId: string, parentEntityId: string) => void,
    onReorderEntities: (moveAfterItemId: string, movedItemId: string) => void,

    icons?: Record<string, Domain.Shared.SvgIcon>,
};

export type HierarchyItemElement = Domain.Studio.HierarchyItemElement & {
    type: string,
    icon: string,
    color: string,
    workflowState: Domain.Shared.WorkflowTemplateState,
    assignedUser?: Domain.Shared.User,
    order?: number
};

type CellProps = {
    data: HierarchyItemElement
};

/**
 * Represents a UI component that renders a tree of studio elements.
 */
export const HierarchyTree: React.FC<Props> = (props) => {
    const [treeData, setTreeData] = useState<HierarchyItemElement[]>([]);

    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<{ isVisible: boolean, isBusy: boolean, data?: HierarchyItemElement }>({ isVisible: false, isBusy: false });
    const [isReparentDialogVisible, setIsReparentDialogVisible] = useState<{ isVisible: boolean, isBusy: boolean, data?: HierarchyItemElement }>({ isVisible: false, isBusy: false });

    const rootNodes = useMemo(() => {
        const grouped = _.groupBy(props.hierarchyDefinition.items, "toElementDefinitionId");
        return Object.keys(grouped).filter(key => grouped[key].length === 1) || [];
    }, [props.hierarchyDefinition]);

    useLayoutEffect(() => {
        const filteredHierarchyItems = props.selectedType
            ? props.hierarchyItems.filter(
                (item) =>
                    props.availableTypes.findIndex(at => at.id === item.element.elementDefinitionId) === props.availableTypes.indexOf(props.selectedType) ||
                    (item.parentHierarchyItemId && props.availableTypes.findIndex(at => at.id === item.element.elementDefinitionId) > props.availableTypes.indexOf(props.selectedType)))
            : props.hierarchyItems;
        const data = mapHierarchyItemsToTreeData(filteredHierarchyItems, props.elementDefinitions, props.workflowDictionary, props.users, props.selectedType?.id, props.availableTypes);
        setTreeData(data);
        setIsDeleteDialogVisible(resetActionState);
        setIsReparentDialogVisible(resetActionState);
    }, [props.hierarchyItems, props.selectedType, props.elementDefinitions]);

    if (!props.hierarchyDefinition) {
        return;
    }

    const onReorderEntity = (treeListData) => {
        if (treeListData.fromIndex === treeListData.toIndex || props.isMeasureMomentClosed)
            return;

        const visibleTreeData: HierarchyItemElement[] = treeListData.component.getVisibleRows().map(row => treeData.find(item => row.node.data.id === item.id));
        const siblings = visibleTreeData.filter(item => item.parentId === treeListData.itemData.parentId && item.id !== treeListData.itemData.id);

        if (siblings.length === 0)
            return;

        const toIndex = treeListData.toIndex > treeListData.fromIndex ? treeListData.toIndex : treeListData.toIndex - 1;
        let previousItemId = null;

        if (toIndex >= 0) {
            if (visibleTreeData[toIndex].parentId !== treeListData.itemData.parentId) {
                if (visibleTreeData[toIndex].id !== treeListData.itemData.parentId) {
                    previousItemId = undefined;
                }
            }
            else {
                previousItemId = visibleTreeData[toIndex].id;
            }
        }

        const previousItemIndex = siblings.findIndex(item => item.id === previousItemId);
        if (previousItemId === null || (!isUndefined(previousItemId) && previousItemIndex !== -1 && (siblings[previousItemIndex].order || 0) + 1 !== treeListData.itemData.order)) {
            props.onReorderEntities(previousItemId, treeListData.itemData.id);
        }
    };

    // #region Cell templates...
    const TitleCellTemplate: React.FC<CellProps> = (cellProps) => (
        <div className="overflow-cell">
            <div className="overflow-cell-icon">
                <SVGIcon value={props.icons[cellProps.data.icon]?.svg} size={IconSize.medium} color={cellProps.data.color} />
            </div>
            <Styled.SingleValueWrapper>
                <label className="overflow-cell-label cursor-pointer" onClick={() => props.onEditEntity(cellProps.data.id)} title={cellProps.data.name}>{cellProps.data.name}</label>
            </Styled.SingleValueWrapper>
        </div>
    );

    const NumberCellTemplate: React.FC<CellProps> = (cellProps) => (
        <div className="overflow-cell">
            <Styled.SingleValueWrapper>
                <span className="overflow-cell-label">{cellProps.data.number}</span>
            </Styled.SingleValueWrapper>
        </div>
    );

    const ContextualMenuCellTemplate: React.FC<CellProps & { userIdentity: UserIdentity }> = (contextProps) => {
        if (props.isMeasureMomentClosed || !UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_GlobalStudioContributor, ActionType.Create)) {
            return null;
        }

        const allowedChildElementDefinitions = props.hierarchyDefinition.items
            ?.filter((hierarchyDefinitionLink) => hierarchyDefinitionLink.fromElementDefinitionId === contextProps.data.type)
            ?.map((linkDefinition) => props.elementDefinitions[linkDefinition.toElementDefinitionId])
            ?.filter((elementDefinition) => !elementDefinition.deleted) || [];

        return (
            <LiasContextMenu<HierarchyItemElement>
                item={contextProps.data}
                keyExpr='id'
                actions={[
                    {
                        text: 'Nieuw',
                        visible: !!allowedChildElementDefinitions && allowedChildElementDefinitions.length > 0,
                        items: allowedChildElementDefinitions.map(definition => ({
                            callBack: () => props.onCreateEntity(definition.id, contextProps.data.id),
                            text: definition.name
                        }))
                    },
                    {
                        callBack: () => props.onEditEntity(contextProps.data.id),
                        text: 'Bewerken'
                    },
                    {
                        callBack: () => setIsDeleteDialogVisible((prev) => ({ ...prev, isVisible: true, data: contextProps.data })),
                        text: 'Verwijderen',
                    },
                    {
                        callBack: () => setIsReparentDialogVisible((prev) => ({ ...prev, isVisible: true, data: contextProps.data })),
                        text: 'Verplaatsen',
                        visible: !!rootNodes && rootNodes.indexOf(contextProps.data.type) < 0,
                    },
                ]}
            />);
    };

    const WorkflowStateCellTemplate: React.FC<CellProps> = (cellProps) => {
        if (!cellProps.data.workflowState) {
            return null;
        }

        return (
            <div className="overflow-cell">
                <div className="overflow-cell-icon">
                    {IconHelper.getWorkFlowStatusIcon(cellProps.data.workflowState.name, IconSize.small)}
                </div>

                <Styled.SingleValueWrapper>
                    <span className="overflow-cell-label">{cellProps.data.workflowState.name}</span>
                </Styled.SingleValueWrapper>
            </div>
        );
    };

    const AssigendUserTemplate: React.FC<CellProps> = (cellProps) => {
        const assignedUser = cellProps.data.assignedUser ? cellProps.data.assignedUser.name : ``;
        return (
            <div className="overflow-cell">
                <Styled.SingleValueWrapper>
                    <span className="overflow-cell-label">{assignedUser}</span>
                </Styled.SingleValueWrapper>
            </div>
        );
    };
    // #endregion    

    /**
     * Gets the available parents that can be assigned to a hierarchy item.
     * 
     * @param entityType Defines the type of the hierarchy item that has to be assigned to another parent.
     * @param currentParentId Defines the unique identifier of the current parent of the hierarchy item.
     */
    const getAvailableParents = (entityTypeId: string, currentParentId: string): HierarchyItemElement[] => {
        const allowedParentElementDefinitions = props.hierarchyDefinition.items
            ?.filter((hierarchyDefinitionLink) => !!hierarchyDefinitionLink.fromElementDefinitionId && hierarchyDefinitionLink.toElementDefinitionId === entityTypeId)
            ?.map((linkDefinition) => props.elementDefinitions[linkDefinition.fromElementDefinitionId]) || [];

        const availableParents = mapHierarchyItemsToTreeData(props.hierarchyItems, props.elementDefinitions, props.workflowDictionary, props.users, props.selectedType?.id, props.availableTypes)
            ?.filter(item => allowedParentElementDefinitions.some(allowed => allowed && allowed.id === item.type) && item.id !== currentParentId);
        return availableParents;
    };

    return (<>
        <StyledDraggableWrapper dragEnabled={true}>
            <DxTreeList
                id='hierarchy-items-overview'
                dataSource={treeData}
                keyExpr='id'
                columnAutoWidth={false}
                wordWrapEnabled={true}
                noDataText={!props.hierarchyItems ? 'Geen gegevens beschikbaar' : ''}
                parentIdExpr='parentId'
                onRowExpanding={props.onRowExpanding}
                onRowCollapsing={props.onRowCollapsing}
                expandedRowKeys={props.expandedRowKeys}
                rowDragging={{
                    allowReordering: props.selectedType ? false : true && !props.isMeasureMomentClosed,
                    showDragIcons: props.selectedType ? false : true,
                    dragDirection: 'vertical',
                    dropFeedbackMode: 'push',
                    onReorder: onReorderEntity,
                }}
                scrolling={{ mode: 'standard' }}
            >
                <DxSearchPanel visible={true} width={250} searchVisibleColumnsOnly={true} placeholder='Zoeken...' />

                <DxColumn
                    caption='Titel'
                    dataField='name'
                    cellTemplate='TitleCellTemplate'
                    width='50%'
                    allowSorting={false}
                />

                <DxColumn
                    caption='Nummer'
                    dataField='number'
                    cellTemplate='NumberCellTemplate'
                    alignment='left'
                    allowSorting={false}
                    width='20%'
                />

                <DxColumn
                    caption='Workflow status'
                    dataField='workflowState'
                    cellTemplate='WorkflowStateCellTemplate'
                    alignment='left'
                    allowSorting={false}
                    width='13%'
                />

                <DxColumn
                    caption='Toegewezen aan'
                    dataField='assigendUser'
                    cellTemplate='AssigendUserTemplate'
                    alignment='left'
                    allowSorting={false}
                    width='15%'
                />

                <DxColumn
                    cellTemplate='ContextualMenuCellTemplate1'
                    allowSorting={false}
                    width='5%'
                />


                <DxTemplate name='TitleCellTemplate' render={TitleCellTemplate} />
                <DxTemplate name='NumberCellTemplate' render={NumberCellTemplate} />
                <DxTemplate name='WorkflowStateCellTemplate' render={WorkflowStateCellTemplate} />
                <DxTemplate name='AssigendUserTemplate' render={AssigendUserTemplate} />
                <DxTemplate name='ContextualMenuCellTemplate1' render={(templateProps) => <ContextualMenuCellTemplate {...templateProps} userIdentity={props.userIdentity} />} />
            </DxTreeList >
        </StyledDraggableWrapper>
        {isDeleteDialogVisible?.isVisible && (
            <DeleteElementDialog
                onConfirm={() => {
                    setIsDeleteDialogVisible((prev) => ({ ...prev, isBusy: true }));
                    props.onDeleteEntity(isDeleteDialogVisible?.data?.id);
                }}
                onCancel={() => setIsDeleteDialogVisible(resetActionState)}
            />
        )
        }

        {
            isReparentDialogVisible?.isVisible && (
                <HierarchyItemReparentDialog
                    entityId={isReparentDialogVisible?.data?.id}
                    disableSubmitButton={isReparentDialogVisible?.isBusy}
                    getAvailableParents={() => getAvailableParents(isReparentDialogVisible?.data?.type, isReparentDialogVisible?.data?.parentId)}
                    onReparent={(parentEntityId) => {
                        setIsReparentDialogVisible((prev) => ({ ...prev, isBusy: true }))
                        props.onReparentEntity(isReparentDialogVisible?.data?.id, parentEntityId);
                    }}
                    onCancel={() => setIsReparentDialogVisible(resetActionState)}
                />
            )
        }
    </>
    );
};

/**
 * Maps hierarchy items to tree data collection.
 * 
 * @param hierarchyItems Defines the hierarchy items.
 * @param elementDefinitions Defines the element definitions.
 * @param selectedTypeId Defines the currently selected type's id.
 * 
 * @returns the tree data collection.
 */
const mapHierarchyItemsToTreeData = (
    hierarchyItems: Domain.Studio.HierarchyItem[],
    elementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
    workflowDictionary: Record<string, Domain.Shared.WorkflowTemplateWithStates>,
    users: Domain.Shared.User[],
    selectedTypeId: string,
    availableTypes: Domain.Shared.ElementDefinition[]) => {

    const rootParentId = '0';
    return hierarchyItems?.map((item: Domain.Studio.HierarchyItem) => {
        const elementItem = new Domain.Studio.HierarchyItemElement();
        FieldsHelper.mapObject<Domain.Studio.HierarchyItemElement>(elementItem, elementDefinitions[item.element.elementDefinitionId].fields, item.element.fields);
        elementItem.id = item.element.elementId;

        if (availableTypes.findIndex((at) => at.id === (item.element.elementDefinitionId as string)) === availableTypes.findIndex((at) => at.id === selectedTypeId)) {
            elementItem.parentId = rootParentId;
        } else if (item.parentHierarchyItemId) {
            elementItem.parentId = item.parentHierarchyItemId
        } else {
            elementItem.parentId = rootParentId;
        }

        const workflowState: Domain.Shared.WorkflowTemplateState =
            workflowDictionary[item.element.elementDefinitionId]?.workflowStates.find((state: Domain.Shared.WorkflowTemplateState) => state.id === item.workflowStateId);

        const assignedUser = users.find(user => user.id === item.assignedUserId);

        return {
            ...elementItem,
            type: elementDefinitions[item.element.elementDefinitionId].id,
            icon: elementDefinitions[item.element.elementDefinitionId].icon,
            color: elementDefinitions[item.element.elementDefinitionId].color,
            order: item.order,
            workflowState: workflowState,
            assignedUser: assignedUser
        };
    }) || [];
};

const resetActionState = { isVisible: false, isBusy: false, data: undefined };
