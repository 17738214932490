import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React from 'react';
import posed from 'react-pose';
import styled from 'styled-components';
import { cssFontBold, px } from '../style';

export interface IEdgeToolbarProps {
    readonly children?: React.ReactNode;
    readonly edge: 'left' | 'top' | 'right' | 'bottom';
    readonly open?: boolean;
    readonly inModal?: boolean;
    readonly onToggle?: () => void;
    readonly look?: 'default' | 'editor';
}

export const EdgeToolbar = ({ edge, open = true, children, inModal, onToggle, look = 'default' }: IEdgeToolbarProps) => {
    const chevronToggleIcon = open ? <KeyboardArrowLeftIcon fontSize="small" /> : <KeyboardArrowRightIcon fontSize="small" />;
    const tab = onToggle && (
        <TabHolder>
            <MenuTab isOpen={open} onClick={onToggle} role="button">
                <span>Menu</span>
                {chevronToggleIcon}
            </MenuTab>
        </TabHolder>
    );
    return (
        (edge === `top` && (
            <TopEdge
                className="edge-toolbar"
                look={look}
                initialPose="closed"
                pose={open ? `opened` : `closed`}
                edge={edge}
                open={open}
                inModal={inModal}
                withParent={false}>
                {children}
            </TopEdge>
        ))
        || (edge === `right` && (
            <RightEdge
                className="edge-toolbar"
                look={look}
                initialPose="closed"
                pose={open ? `opened` : `closed`}
                edge={edge}
                open={open}
                inModal={inModal}
                withParent={false}>
                {children}
            </RightEdge>
        ))
        || (edge === `bottom` && (
            <BottomEdge
                className="edge-toolbar"
                look={look}
                initialPose="closed"
                pose={open ? `opened` : `closed`}
                edge={edge}
                open={open}
                inModal={inModal}
                withParent={false}>
                {children}
            </BottomEdge>
        ))
        || (edge === `left` && (
            <LeftEdge
                className="edge-toolbar"
                look={look}
                initialPose="closed"
                pose={open ? `opened` : `closed`}
                edge={edge}
                open={open}
                inModal={inModal}
                withParent={false}>
                {children}
                {tab}
            </LeftEdge>
        )) || null
    );
};

const transition = { ease: `easeOut`, duration: 300 };
const TopEdge = styled(posed.div({
    opened: { y: `0%`, opacity: 1, transition },
    closed: { y: `-100%`, opacity: 0, transition },
}))`
    position:${(p: IEdgeToolbarProps) => p.inModal ? 'absolute' : 'fixed'};
    background:${(p: IEdgeToolbarProps) => (p.look === 'default' && '#FFF') || (p.look === 'editor' && '#4B55F6')};
    box-shadow: 0 3px 20px 0 rgba(75, 85, 246, 0.1);
    left: 0; right: 0; top: 0; bottom: auto;
    padding: 16px 48px;
`;

const RightEdge = styled(posed.div({
    opened: { x: `0%`, opacity: 1, transition },
    closed: { x: `100%`, opacity: 0, transition },
}))`
    position:${(p: IEdgeToolbarProps) => p.inModal ? 'absolute' : 'fixed'};
    background:${(p: IEdgeToolbarProps) => (p.look === 'default' && '#FFF') || (p.look === 'editor' && '#4B55F6')};
    box-shadow: -5px 0 20px 0 rgba(75, 85, 246, 0.1);
    left: auto; right: 0; top: 0; bottom: 0;
`;

const BottomEdge = styled(posed.div({
    opened: { y: `0%`, opacity: 1, transition: { ease: `easeOut`, duration: 300, delay: 300 } },
    closed: { y: `100%`, opacity: 0, transition },
}))`
    position:${(p: IEdgeToolbarProps) => p.inModal ? 'absolute' : 'fixed'};
    background:${(p: IEdgeToolbarProps) => (p.look === 'default' && '#FFF') || (p.look === 'editor' && '#4B55F6')};
    box-shadow: 0 -3px 20px 0 rgba(75, 85, 246, 0.1);
    left: 0; right: 0; top: auto; bottom: 0;
    padding: 14px 48px;
`;

const LeftEdge = styled(posed.div({
    opened: { x: `0%`, applyAtStart: { boxShadow: `5px 0 20px 0 rgba(75, 85, 246, 0.1)` }, transition },
    closed: { x: `-100%`, applyAtEnd: { boxShadow: `none` }, transition },
}))`
    position:${(p: IEdgeToolbarProps) => p.inModal ? 'absolute' : 'fixed'};
    background:${(p: IEdgeToolbarProps) => (p.look === 'default' && '#FFF') || (p.look === 'editor' && '#4B55F6')};
    left: 0; right: auto; top: 0; bottom: 0;
`;

const TabHolder = styled.div`
    position:absolute;
    top:${px(20)};
    left:100%;
`;

const MenuTab = styled.div<{ isOpen: boolean; onClick: () => void; }>`
    position:relative;
    width:${(p) => p.isOpen ? '30px' : 'auto'};
    max-width:200px;
    padding:4px 15px;
    border-radius: 0 4px 4px 0;
    background-color: #4b55f6;
    transition:max-width 0.3s;
   //display: flex ;

    :hover { cursor:pointer; }

    > span {
        ${cssFontBold(11, 11, 100)}
        padding-right: 16px;
        display: inline-block;
        overflow: hidden;
        text-transform: uppercase;
        color:#fff;
        width:${(p) => p.isOpen ? '1px' : 'auto'};
        opacity:${(p) => p.isOpen ? '0' : '1'};
        transition:width 0.3s, opacity 0.3s;
    }
    > .MuiSvgIcon-root  {
        position: absolute;
        top: 3px; 
        right: 5px;
        width: 20px; 
        height: 20px;
        fill: #fff;
    }
`;
