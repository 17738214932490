import React from 'react';
import { RouteProps, useLocation, matchPath } from 'react-router-dom';
import _ from 'lodash';
import { IconValue, SideNavigation, NavGroup, NavItem } from '@liasincontrol/ui-basics';
import { UserIdentity } from '@liasincontrol/auth-service';
import { Look } from '@liasincontrol/ui-basics';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { useAnnouncement } from '@liasincontrol/redux-service';

/**
 * Represents a UI component that renders the base administrator menu page.
 */
const Menu: React.FC<RouteProps & { userIdentity: UserIdentity }> = (props) => {
    const { pathname } = useLocation();

    const announcement = useAnnouncement();

    const adminNavItems: NavItem[] = [
        {
            key: "menu-admin-users",
            active: !_.isNull(matchPath('/admin/user/*', pathname)),
            title: 'Gebruikersbeheer',
            alt: 'Open gebruikersbeheer',
            to: '/admin/user/list',
            icon: IconValue.Customer,
            hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_UsersAndGroups, ActionType.Read)),
        },
        {
            key: "menu-admin-roles",
            active: !_.isNull(matchPath('/admin/role/*', pathname)),
            title: 'Rollen',
            alt: 'Open rollen',
            to: '/admin/role/list',
            icon: IconValue.Roles,
            hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Roles, ActionType.Read)),
        },
        {
            key: "menu-admin-usergroups",
            active: !_.isNull(matchPath('/admin/usergroup/*', pathname)),
            title: 'Gebruikersgroepen',
            alt: 'Open gebruikersgroepen',
            to: '/admin/usergroup/list',
            icon: IconValue.Roles,
            hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_UsersAndGroups, ActionType.Read)),
        },
        {
            key: 'menu-admin-datastores',
            active: !_.isNull(matchPath('/admin/datastore/*', pathname)),
            title: 'Gegevensverbindingen',
            alt: 'Open gegevensverbindingen',
            to: '/admin/datastore/list',
            icon: IconValue.Database,
            hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_DataStores, ActionType.Read)),
        },
        {
            key: 'menu-admin-workflows',
            active: !_.isNull(matchPath('/admin/workflow/*', pathname)),
            title: 'Workflows',
            alt: 'Open workflows',
            to: '/admin/workflow/list',
            icon: IconValue.Hierarchy_empty,
            hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Workflows, ActionType.Read)),
        },
        {
            key: 'menu-admin-measuremoments',
            active: !_.isNull(matchPath('/admin/measuremoment/*', pathname)),
            title: 'Momenten',
            alt: 'Open momenten',
            to: '/admin/measuremoment/list',
            icon: IconValue.Moment,
            hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_MeasureMoments, ActionType.Read)),
        },
    ];

    const adminUrl = _.find(adminNavItems, (item) => !item.hidden)?.to;

    const menuItems: NavGroup[] = [
        {
            key: 'menu-admin',
            title: 'Beheer',
            active: !_.isNull(matchPath('/admin/*', pathname)),
            icon: IconValue.Settings,
            to: adminUrl,
            hidden: !adminUrl,
            items: adminNavItems,
        }
    ];

    return (
        <SideNavigation look={Look.publisherAdmin} alt='' menuItems={menuItems} announcement={announcement.items?.message} />
    );
};

export default Menu;
