import styled from 'styled-components';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { palette, px } from '@liasincontrol/ui-basics';
import { lineHeightCoefficient } from '../../SharedControlElements/index.styled';

const ImagePlaceholderIcon = styled(ImageOutlinedIcon)`
    display: inline-block;
    fill: ${palette.grey3};
`;

const ImagePlaceholderWrapper = styled.div`
    display: flex;
    justify-content: center;
    background-color: ${palette.grey4};
`;

const ImagePlaceholder = styled.div<{
    customStyle: React.CSSProperties,
    fillOut: boolean,
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${(p) => (p.customStyle?.height && p.customStyle.height !== '100%' ? `${p.customStyle.height}px` : `320px`)};
    width: ${(p) => (p.fillOut ? `100%` : `60%`)};
    background-image: radial-gradient(circle, ${palette.grey4} 0%, ${palette.grey3} 100%);
`;

const FigureWrapper = styled.figure`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
`;

const FigureImage = styled.img<{ figureStyle?: React.CSSProperties, imageStyle?: React.CSSProperties, fillOut: boolean, }>`
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: ${(p) => (p.figureStyle?.height ? `${p.figureStyle?.height}px` : `320px`)};
    object-fit: ${(p) => (p.fillOut ? 'cover' : 'contain')};
    object-position:${(p) => (p.fillOut && p.figureStyle?.objectPosition ? `${p.figureStyle.objectPosition}` : 'unset')};
    width: ${(p) => (p.fillOut && p.figureStyle?.width ? `${p.figureStyle?.width}` : '100%')};
`;

const FigureCaption = styled.figcaption<{ textFontSize?: string, color?: string }>`
    padding: ${px(8)} ${px(16)};
    background-color: white;
    color: ${(p) => (p.color || palette.grey2)};
    font-style: italic;

    font-size: ${(p) => (p.textFontSize ? `${+p.textFontSize * 0.7}px` : 'inherit')};
    line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * 0.7 * lineHeightCoefficient}px` : 'inherit')};

    display: block;
    text-align: center;
`;

const styledComponents = {
    ImagePlaceholder, ImagePlaceholderWrapper, ImagePlaceholderIcon,
    FigureWrapper, FigureImage, FigureCaption,

};
export default styledComponents;
