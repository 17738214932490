import * as Domain from '@liasincontrol/domain';
import { BaseValidationRestriction, BaseValidator, CustomContextValidator } from './BaseValidator';
import { ValidationErrorData, ValueType } from './Types';
import { ValidationUtils } from './ValidationUtils';

/**
 * Defines the restrictions that can be placed on a text edtior.
 */
export interface TextValidationRestriction extends BaseValidationRestriction {
    minLength?: number;
    maxLength?: number;
    stringType?: Domain.Shared.StringType;
}

type Restrictions = { required: boolean, stringMaxLength: number, stringMinLength?: number, stringType: Domain.Shared.StringType, requiredText?: string };

/**
 *  Defines a helper class that can be used to validate a text field.
 */
export class TextValidator extends BaseValidator<string> {
    public constructor(restrictions: Restrictions, contextValidator?: CustomContextValidator<string>) {
        super(restrictions.required, restrictions.requiredText);
        this.restrictions = {
            minLength: restrictions.stringMinLength,
            maxLength: restrictions.stringMaxLength,
            stringType: restrictions.stringType,
        };

        this.contextValidator = contextValidator;
    }

    private restrictions: TextValidationRestriction = {};
    private contextValidator: CustomContextValidator<string>;

    public validate(value: string, formData?: Record<string, ValueType>): ValidationErrorData[] {
        const errors: ValidationErrorData[] = super.validate(value);
        if (errors.length > 0) {
            return errors;
        }

        if (value && this.restrictions.minLength && value.length < this.restrictions.minLength) {
            errors.push({ error: `Minimaal ${this.restrictions.minLength} tekens.` });
        }

        if (value && this.restrictions.maxLength && value.length > this.restrictions.maxLength) {
            errors.push({ error: `Maximaal ${this.restrictions.maxLength} tekens.` });
        }

        if (this.restrictions.stringType === Domain.Shared.StringType.Html && !ValidationUtils.validateHtml(value, true).isValid) {
            errors.push({ error: 'Ongeldige HTML-waarde.' });
        }

        if (this.restrictions.stringType === Domain.Shared.StringType.Json && !ValidationUtils.validateJson(value).isValid) {
            errors.push({ error: 'Ongeldige JSON-waarde.' });
        }

        if (this.restrictions.stringType === Domain.Shared.StringType.Hyperlink && !ValidationUtils.validateHyperlink(value).isValid) {
            errors.push({ error: 'De opgegeven url is ongeldig. Zorg ervoor dat u de volledige url opgeeft, inclusief http:// of https://.' });
        }

        if (this.contextValidator) {
            errors.push(...this.contextValidator(value, formData));
        }

        return errors;
    }

    /**
     * Gets the text restrictions.
     */
    public getRestrictions = (): TextValidationRestriction => {
        return Object.assign({}, this.restrictions, this.baseRestrictions);
    };
}
