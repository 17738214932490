import styled from 'styled-components';
import { Button, palette, px } from '@liasincontrol/ui-basics';
import { cssBreakpoint } from '../responsive/css';

// tslint:disable:no-magic-numbers

const Wrap = styled.div<{ position: string; withPadding: boolean }>`
    position: relative;
    top: ${(p) => (p.withPadding ? px(-12) : 0)};
`;

const ButtonPlaceholder = styled(Button) <{ look: 'inverted' | 'default' }>`
    color: ${(p) => (p.look === 'inverted' ? '#fff !important' : palette.primary2)};   
`;

const ButtonPlaceholderWithMargin = styled.div`
    margin: ${px(11)} 0;
    ${cssBreakpoint('xs', 's')`
        margin: ${px(9)} 0;
    `};
    ${cssBreakpoint('m')`
        margin: ${px(9)} 0;
    `};
`;

const BasePopover = styled.div`
    background-color: #fff;
    border-radius: ${px(4)};
    box-shadow: 0 ${px(0)} ${px(8)} ${px(-4)} rgba(0, 0, 0, 0.2);
    height: auto;
    padding: 0;
    position: absolute;
    top: ${px(8)};
`;

const SidePopover = styled(BasePopover)`
    min-height: 100vh;
    overflow: hidden;    
    position: fixed;
    background-color: #ffffff;
    left: 0;
    top: 0;
    padding-left: 3rem;
`;

const BottomPopover = styled(BasePopover)`
    top: ${px(8)};
    left: 50%;
    min-width: ${px(220)};
    transform: translateX(-50%);

    /* pointer: */
    &:after {
        position: absolute;
        bottom: 100%; left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        margin-left: -8px;
        pointer-events: none;
        border-color: rgba(0, 0, 0, 0);
        border-bottom-color: #fff;
        border-width: 8px;
    }
`;

const LeftPopover = styled(BasePopover)`
    right: ${px(24)};
    top: ${px(-24)};
`;

const styledComponents = { BottomPopover, LeftPopover, SidePopover, Wrap, ButtonPlaceholder, ButtonPlaceholderWithMargin };
export default styledComponents;