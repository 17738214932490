import { palette, px } from '@liasincontrol/ui-basics';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import styled from 'styled-components';

interface IIconProps {
    readonly disabled?: boolean;
    readonly expanded?: boolean;
    readonly height?: number;
}

const LabelContainer = styled.div`
    display: block;
`;

const ButtonsWrapper = styled.div`
    margin-bottom: ${px(16)};
`;

const ButtonContainer = styled.div`
    display: inline-block;
    padding: ${px(4)};
    margin: ${px(8)};
    border: 2px solid ${palette.grey3b};
    border-radius: 4px;
    &.selected {
        border: 2px solid ${palette.primary2};
    }
    &:focus-within {
        border: 2px solid ${palette.primary2};

        * {
            border: 0 none transparent !important;
        }
    }
`;

const StyledIconDrag = styled(DragIndicatorIcon) <IIconProps>`
    color: ${(p: IIconProps) => p.disabled ? '#6E6E77' : palette.primary2};
    fill: currentColor;
    cursor: ${(p: IIconProps) => p.disabled ? 'default' : 'move'};
`;

const StyledInput = styled.input`
    border: 0;
    padding: 0;
    outline: 0;
`;

const StyledCaption = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    align-items: center;
`;

const StyledAddOptions = styled.div`
    margin: ${px(16)} 0;
 `;

const StyledAdd = styled.div`
    margin: 0 ${px(-8)};
`;

const StyledItem = styled.div`    
    display: grid;
    grid-auto-flow: column;
    grid-gap: ${px(8)};
    grid-template-columns: ${(p: { canRestore: boolean }) => `1.5rem 1fr auto auto`};
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipses;
    opacity: ${(p: { canRestore: boolean }) => (p.canRestore ? 0.5 : 1)};
`;

const StyledLabel = styled.span`
    text-overflow: ellipsis;
    overflow: hidden;
`;

const CenteredDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

const styledComponents = { ButtonContainer, ButtonsWrapper, LabelContainer, StyledAdd, StyledAddOptions, StyledCaption, StyledLabel, StyledIconDrag, StyledItem, StyledInput, CenteredDiv };

export default styledComponents;
