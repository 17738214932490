import styled from 'styled-components';
import { px } from '../style';

const Overflow = styled.div`
    margin-top: 20px; 
    padding-right: ${px(20)};    
    max-height: calc(100% - 50px);
    overflow-y: auto;
`;

const CenteredDiv = styled.div`
    width: 100%;
    height: 100%;
    padding: ${px(16)};
    text-align: center;
    text-align: -webkit-center;
    font-weight: 200;
`;

const styledComponents = { Overflow, CenteredDiv };

export default styledComponents;