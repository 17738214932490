/**
 * Represents a JournalCombination. 
 * OBS:
 * 1. The index behind each element name represents the order of the journal element kind that element is combined with.
 * 2. There are elements 1 to 8 because in the financial system there can be only up to 8 element kinds. 
 * Eg. elementName1 and elementExternalCode1 are associated with the journal element kind with order 1 and so on.
 * */
class JournalCombination {
    elementCombinationRK?:string;
    isReserve?: boolean;
    isClosed?: boolean;
    isBenefit?: boolean;
    isStructural?: boolean;
    baseYear?: number;

    element1RK?: string;
    element1Code?: string;
    element1Name?: string;
    element2RK?: string;
    element2Code?: string;
    element2Name?: string;
    element3RK?: string;
    element3Code?: string;
    element3Name?: string;
    element4RK?: string;
    element4Code?: string;
    element4Name?: string;
    element5RK?: string;
    element5Code?: string;
    element5Name?: string;
    element6RK?: string;
    element6Code?: string;
    element6Name?: string;
    element7RK?: string;
    element7Code?: string;
    element7Name?: string;
    element8RK?: string;
    element8Code?: string;
    element8Name?: string;
}

class JournalCombinationRequestModel {
    id?: string;
    baseYear: number;
    journalElementIds: string[];

    isReserve?: boolean;
    isClosed?: boolean;
    isBenefit?: boolean;
    isStructural?: boolean;

    custom01?: string;
    custom02?: string;
    custom03?: string;
    custom04?: string;
    custom05?: string;
    custom06?: string;
    custom07?: string;
    custom08?: string;
    custom09?: string;
    custom10?: string;
}

export { JournalCombination, JournalCombinationRequestModel };
