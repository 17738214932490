import { useSelector } from "react-redux";
import { State, AjaxRequestStatus, TextAssistantActionCreator, useAppDispatch } from '@liasincontrol/redux-service';

export const useTextAssistantSkills = (hasRights: boolean) => {

    const dispatch = useAppDispatch();
    const textAssistantSkills = useSelector((state: State) => state.textAssistantSkills);

    if (hasRights && (!textAssistantSkills?.status || textAssistantSkills?.status === AjaxRequestStatus.NotSet)) {
        //this warning will be fixed in another PBI (the refactor redux PBI)
        dispatch(TextAssistantActionCreator.set() as any);
    }

    return textAssistantSkills;
};