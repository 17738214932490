import { ComplexField } from '../../../Shared/ComplexField';
import { SystemFieldDefinitions } from '../../../Shared/FieldDefinition';
import { fieldDefinitionDecorator } from '../../../Shared/FieldDefinition/FieldDefinitionDecorator';

/**
 * Represents a server-side object describing a publication page.
 */
class PageElement {

    //A non-standard 'id' field is really returned by the API.
    id: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Name)
    name: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Title)
    title: string;

    complexFields: ComplexField[] = [];

}

export default PageElement;