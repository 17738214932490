import styled from 'styled-components';
import { px, palette } from '@liasincontrol/ui-basics';
import { NumberBox } from "devextreme-react/number-box";

const StyledNumberBox = styled(NumberBox)`
    width: 100%;
    max-width: 100%;
    margin: 0;
    color: ${palette.grey2};
    text-overflow: ellipsis;
    border-radius: 4px;
    background: 0 none;
    outline: 0;
    transition: border-color 0.3s ease;
    background-color: ${palette.white} !important;
    white-space: nowrap;
    letter-spacing: 0.06em;
    ::-ms-clear {
        display: none;
    }

    // Override the DevExtreme default properties.
    &.dx-editor-outlined {
      background: ${palette.white};
      border: 2px solid ${palette.grey4} !important;
      border-radius: 4px;
    }

    &.dx-state-hover {
      border: 2px solid  rgba(75, 85, 246, 0.15) !important;
    }

    &.dx-state-focused, &.dx-dropdowneditor-active  {
      border: 2px solid ${palette.primary1b} !important;
    }

    .dx-lookup-field {
        padding: ${px(12)} ${px(16)};
        min-height: ${px(20)};
    }
    
    .dx-texteditor-input {
        padding: ${px(12)} ${px(16)};
        min-height: ${px(20)}; 
    }

    &.dx-state-disabled {
        opacity: 1 ;
        background-color: ${palette.grey4};
        border: 2px solid ${palette.grey4};
        &:hover {
            border: 2px solid rgba(75, 85, 246, 0.15);
        }
        &:focus {
            border: 2px solid ${palette.primary1b};
        }
        .dx-lookup-arrow::before {
            color: ${palette.grey2} !important;
        }
    }

    &.dx-lookup-empty {
        padding: 0px ${px(12)};
        min-height: ${px(20)};
        color: #999999;
    }    
`;

const Wrapper = styled.div<{ showSpinButtons: boolean }>`
    display: grid;
    grid-template-columns: ${(p) => (p.showSpinButtons ? `${px(48)} 1fr ${px(48)}` : `1fr`)};
    grid-gap: ${px(16)};
    align-items: center;
    justify-content: center;

    .dx-button-mode-contained {
        cursor: pointer;
        border-radius: ${px(6)};
        background-color: ${palette.primary4};
        border: ${px(2)} solid ${palette.primary4};
        position: relative;
        overflow: hidden;
        vertical-align: middle;
        width: ${px(48)};
        height: ${px(48)};
        line-height: 0;    
        padding: 0;
        outline: 0; 
        transition: background-color 0.3s ease, border - color 0.3s ease, color 0.3s ease;

        .dx-icon {
            color: ${palette.primary1b};
            font-size: 1.5rem;
        }

        &.dx-state-focused {
            background-color: ${palette.white};
            border-color: ${palette.primary1b};
            color: ${palette.primary1b};
        }

        &.dx-state-hover {
            background-color: ${palette.primary3};
            border-color: ${palette.primary3};
            color: ${palette.primary1b};
        }

        :hover {
            background-color: ${palette.primary3};
            border-color: ${palette.primary3};
            color: ${palette.primary1b};
        }
        :focus {
            background-color: ${palette.white};
            border-color: ${palette.primary1b};
            color: ${palette.primary1b};
        }
        :hover :focus, :active {
            background-color: ${palette.primary3};
            border-color: ${palette.primary3};
            color: ${palette.primary1b};
        } 
    }   
`;

const styledComponents = { Wrapper, StyledNumberBox };

export default styledComponents;