import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';
import { SelectElement } from '@liasincontrol/ui-elements';
import Styled from './index.styled';

type Props = {
    workflowStates: Domain.Publisher.TaskManagerWorkflowState[],
    // if this is set then the dialog was activated for a specific row from the assignment tree list
    componentId?: string,
    onConfirm: (newState: string) => void,
    onCancel: () => void,
};

/** Represents a UI component that renders the dialog used to set the workflow state for all the controls in a publication. */
const SetWorkflowStateDialog: React.FC<Props> = (props) => {
    const [selectedState, setSelectedState] = useState<Domain.Publisher.TaskManagerWorkflowState>();
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

    const dialogFooter: JSX.Element = (
        <ModalDialogFooter
            leftButtonText='Annuleren'
            onLeftButtonClick={props.onCancel}
            rightButtonText='WIJZIGEN'
            onRightButtonClick={() => { 
                if (!props.componentId) setShowConfirmationDialog(true) 
                else props.onConfirm(selectedState.id);
            }}
            rightButtonDisabled={!selectedState} />
    );

    const changeStateConfirmDialog: JSX.Element = (<ModalDialog
        id='confirm-state-change-dialog'
        settings={{
            look: 'message',
            title: 'Nieuwe workflowstatus bevestigen',
            footer: <ModalDialogFooter leftButtonText='Annuleren'
                onLeftButtonClick={() => {
                    setShowConfirmationDialog(false);
                    props.onCancel();
                }}
                rightButtonText='BEVESTIGEN'
                onRightButtonClick={() => props.onConfirm(selectedState.id)} />
        }}
    >
        <Text value='Weet u zeker dat u de status van de workflow wilt veranderen? Schrijvers in deze workflowstatus krijgen een actieve taak.' />
    </ModalDialog>);

    return (
        <>
            <ModalDialog
                id="dialog-set-workflow-state"
                settings={{
                    look: 'interactive',
                    title: 'Workflowstatus wijzigen',
                    footer: dialogFooter
                }}>
                <Styled.TextWrapper>
                    {props.componentId 
                        ? <Text value='U staat op het punt om de workflowstatus voor dit element te wijzigen. Kies uit de onderstaande lijst de gewenste nieuwe workflowstatus.' />
                        : <Text value='U staat op het punt om de workflowstatus voor de gehele publicatie te wijzigen. Kies uit de onderstaande lijst de gewenste nieuwe workflowstatus.' />
                    }
                </Styled.TextWrapper>
                <SelectElement<Domain.Publisher.TaskManagerWorkflowState>
                    id='select-new-state'
                    label='Workflowstatus'
                    displayExpr='name'
                    optionItems={props.workflowStates}
                    value={selectedState}
                    clearable={false}
                    searchable={false}
                    editorSettings={{
                        disabled: false,
                        restrictions: { required: true },
                        validationErrors: [],
                        onChange:  setSelectedState,
                    }}
                />
            </ModalDialog>
            {showConfirmationDialog && changeStateConfirmDialog}
        </>
    );
};

export { SetWorkflowStateDialog };