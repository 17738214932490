import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation, matchPath } from 'react-router-dom';
import _ from 'lodash';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import AuthService, { UserIdentity } from '@liasincontrol/auth-service';
import { CardHeaderTab, MenuItem } from '@liasincontrol/ui-basics';
import * as Styled from './index.styled';

type Props = {
    isClosed: boolean;
};

/**
 * Represents a UI component that renders the publisher navigation menu.
 */
const PublicationNavBar: React.FC<Props> = (props) => {

    const { id: publicationId, pageid: pageId } = useParams();
    const [userIdentity, setUserIdentity] = useState<UserIdentity>();
    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        AuthService.getInstance()
            .then(async (auth) => await auth.getUser())
            .then((identity) => {
                if (identity) {
                    setUserIdentity(identity);
                }
            });
    }, []);

    const navItems: MenuItem[] = [
        {
            key: 'publication-write',
            title: 'Schrijven',
            active: !_.isNull(matchPath('/publisher/publication/:publicationid/writer/page/:pageid', pathname)),
            to: `/publisher/publication/${publicationId}/writer/page/${pageId || ''}`,
            alt: 'Schrijven',
        },
    ];

    const navTabs = navItems.map((navItem) =>
        <CardHeaderTab id={`btn-nav-${navItem.key}`} key={navItem.key} active={navItem.active} title={navItem.title} onClick={() => navigate(navItem.to)} />
    );

    const permissionedNavItems: MenuItem[] = [];

    // Menu items must all be defined outside of an if statement, otherwise eslint throws an error (React Hook "useRouteMatch" is called conditionally. React Hooks must be called in the exact same order in every component render  react-hooks/rules-of-hooks).
    const publicationSettingsNavItem: MenuItem = {
        key: 'publication-settings',
        title: 'Instellingen',
        active: !_.isNull(matchPath(`/publisher/admin/publication/${publicationId}/information`, pathname)),
        to: `/publisher/admin/publication/${publicationId}/information`,
        alt: 'Instellingen',
    };
    const sitemapNavItem: MenuItem = {
        key: 'publication-sitemap',
        title: 'Publicatie indelen',
        active: !_.isNull(matchPath(`/publisher/admin/publication/${publicationId}/sitemap/:pageid`, pathname)),
        to: `/publisher/admin/publication/${publicationId}/sitemap${pageId ? `/${pageId}` : ''}`,
        alt: 'Publicatie indelen',
    };
    const assignmentNavItem: MenuItem = {
        key: 'publication-assignments',
        title: 'Taakbeheer',
        active: !_.isNull(matchPath(`/publisher/admin/publication/${publicationId}/assignments`, pathname)),
        to: `/publisher/admin/publication/${publicationId}/assignments`,
        alt: 'Taakbeheer',
    };
    const templatesNavItem: MenuItem = {
        key: 'publication-templates',
        title: 'Sjablonen',
        active: !_.isNull(matchPath(`/publisher/admin/publication/${publicationId}/templates`, pathname)),
        to: `/publisher/admin/publication/${publicationId}/templates`,
        alt: 'Sjablonen',
    };
    const publishNavItem: MenuItem = {
        key: 'publication-publish',
        title: 'Publiceren',
        active: !_.isNull(matchPath(`/publisher/admin/publication/${publicationId}/publish`, pathname)),
        to: `/publisher/admin/publication/${publicationId}/publish`,
        alt: 'Publiceren',
    };
    const dataSourcesNavItem: MenuItem = {
        key: 'publication-datasources',
        title: 'Databronnen',
        active: !_.isNull(matchPath(`/publisher/admin/publication/${publicationId}/datasource/*`, pathname)),
        to: `/publisher/admin/publication/${publicationId}/datasource/list`,
        alt: 'Databronnen',
    };
    const tasksOverviewNavItem: MenuItem = {
        key: 'publication-tasks-overview',
        title: 'Voortgang',
        active: !_.isNull(matchPath(`/publisher/admin/publication/${publicationId}/tasks-overview`, pathname)),
        to: `/publisher/admin/publication/${publicationId}/tasks-overview`,
        alt: 'Voortgang',
    };

    if (!props.isClosed) {
        if (userIdentity && UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_Publications, ActionType.Update)) {
            permissionedNavItems.push(publicationSettingsNavItem);
        }

        if (userIdentity && UserRightsService.getInstance().canPerformAction(userIdentity, Actions.COMPLEX_PublishPublication, ActionType.Update)) {
            permissionedNavItems.push(publishNavItem);
        }

        if (userIdentity && UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_Sitemap, ActionType.Update)) {
            permissionedNavItems.push(sitemapNavItem);
        }

        if (userIdentity && UserRightsService.getInstance().canPerformAction(userIdentity, Actions.COMPLEX_TasksOverview, ActionType.Update)) {
            permissionedNavItems.push(tasksOverviewNavItem);
        }

        if (userIdentity && UserRightsService.getInstance().canPerformAction(userIdentity, Actions.COMPLEX_ManageTasks, ActionType.Update)) {
            permissionedNavItems.push(assignmentNavItem);
        }

        if (userIdentity && UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_Templates, ActionType.Update)) {
            permissionedNavItems.push(templatesNavItem);
        }

        if (userIdentity && UserRightsService.getInstance().canPerformAnyAction(userIdentity, [Actions.CRUD_Datasources, Actions.COMPLEX_RefreshDataSource], ActionType.Update)) {
            permissionedNavItems.push(dataSourcesNavItem);
        }
    }

    const navAdminTabs = permissionedNavItems.map((navItem) =>
        <CardHeaderTab id={`btn-nav-${navItem.key}`} key={navItem.key} active={navItem.active} title={navItem.title} onClick={() => navigate(navItem.to)} />
    );

    return (
        <Styled.Grid>
            <Styled.ContentHeaderBarLeft />
            <Styled.ContentHeaderLeft>
                {navTabs}
            </Styled.ContentHeaderLeft>
            {!props.isClosed && navAdminTabs.length > 0 &&
                <>
                    <Styled.ContentHeaderBarRight />
                    <Styled.ContentHeaderRight>
                        {navAdminTabs}
                    </Styled.ContentHeaderRight>
                </>
            }
        </Styled.Grid>
    );
};

export { PublicationNavBar };
