import DateBox from "devextreme-react/date-box";
import styled from "styled-components";
import { palette, px } from "@liasincontrol/ui-basics";

const StyledDateBox = styled(DateBox)`

    background-color: ${palette.white} !important;

    .dx-dropdowneditor-icon {
        color: ${palette.primary2};
    }

    .dx-texteditor-input {
        padding: ${px(12)} ${px(16)};
        min-height: ${px(20)}; 
    }    
`;

export { StyledDateBox };