import { WorkflowTemplate } from '../../../dto/Shared/Workflow/WorkflowTemplate';
import { WorkflowTemplateState as DtoWorkflowTemplateState } from '../../../dto/Shared/Workflow/WorkflowTemplateState';

/**
 * Represents a workflow template state extended with its id, stored in redux.
 */
export class WorkflowTemplateState extends DtoWorkflowTemplateState {

    // overwrite the base category of type string.
    readonly category: WorkflowCategoryType;
}

/**
 * Represents a workflow template extended with related statuses, stored in redux.
 */
export class WorkflowTemplateWithStates extends WorkflowTemplate {

    // The list of available states for the workflow.
    readonly workflowStates: WorkflowTemplateState[];
}

/**
 * Represents one of the possible workflow template state categories.
 */
export enum WorkflowCategoryType {
    New = 'New',
    InProgress = 'InProgress',
    Done = 'Done',
}