import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import * as Domain from '@liasincontrol/domain';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { WrapperContent, PageTitle, Heading1, Bar, Button, Section, ErrorOverlay } from '@liasincontrol/ui-basics';
import { ContextMenu, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { ActionSource, ModulesActionCreator, State, UserGroupsActionCreator, UsersActionCreator } from '@liasincontrol/redux-service';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { Shared as SharedDataAccess } from '@liasincontrol/data-service';
import { AppSettingsService } from '@liasincontrol/config-service';
import { UserGroupAdd } from './UserGroupAdd';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the usergroups list page.
 */
const Index: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const [userGroups, setUserGroups] = useState<Domain.Shared.UserGroup[]>([]);
    const [lastRefresh, setLastRefresh] = useState<number>();
    const availableColumns: GridColumn<Domain.Shared.UserGroup>[] = getColumnConfiguration(
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_UsersAndGroups, ActionType.Update)
            ? (item: Domain.Shared.UserGroup) => navigate(`/admin/usergroup/${item.id}/information`)
            : null,
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_UsersAndGroups, ActionType.Delete)
            ? (item: Domain.Shared.UserGroup) => onDelete(item.id)
            : null
    );

    const [userGroupAddDialog, setUserGroupAddDialog] = useState<{ showDialog: boolean, isSaving: boolean }>({ showDialog: false, isSaving: false });
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const userGroupNames = useMemo(() => userGroups?.map(item => item.name), [userGroups]);

    useEffect(() => {
        SharedDataAccess.UserGroups.get()
            .then((response) => {
                setError(undefined);
                setUserGroups(response.data);
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
            });
    }, [lastRefresh]);

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    const onCreate = (userGroupName: string) => {
        setUserGroupAddDialog({ showDialog: true, isSaving: true });
        SharedDataAccess.UserGroups.create(userGroupName)
            .then(() => {
                setLastRefresh(Date.now());
                props.fetchUserGroups();
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err));
            }).finally(() => {
                setUserGroupAddDialog({ showDialog: false, isSaving: false });
            });
    };

    const onDelete = (userGroupId: string) => {
        SharedDataAccess.UserGroups.delete(userGroupId)
            .then(() => {
                setLastRefresh(Date.now());
                props.fetchUserGroups();
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, err));
            });
    };

    return (
        <>
            <WrapperContent>
                <PageTitle>
                    <Heading1>Gebruikersgroepen</Heading1>
                </PageTitle>
                <Bar look='toolbar'>
                    <Bar start>
                        {!error && <Button
                            id='usergroup-create-btn'
                            btnbase='textbuttons'
                            btntype='medium_icon'
                            icon={<Add />}
                            disabled={!UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_UsersAndGroups, ActionType.Create)}
                            onClick={() => setUserGroupAddDialog({ showDialog: true, isSaving: false })}>
                            Nieuw
                        </Button>}
                    </Bar>
                </Bar>

                <Section look='white'>
                    <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        {userGroups && availableColumns &&
                            <LsGrid
                                dataSource={userGroups}
                                columns={availableColumns}
                                enableColumnChooser={true}
                                searching={false}
                                paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                                showRowLines={true}
                                onClickRow={(item: Domain.Shared.UserGroup) => {
                                    navigate(`/admin/usergroup/${item.id}/information`);
                                }}
                            />
                        }
                    </ErrorOverlay>
                </Section>
            </WrapperContent>
            {userGroupAddDialog.showDialog && <UserGroupAdd
                userGroupNames={userGroupNames}
                onSave={(name: string) => onCreate(name)}
                onCancel={() => setUserGroupAddDialog({ showDialog: false, isSaving: false })}
            />
            }
        </>
    );
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Publication],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchUserGroups: () => {
            dispatch(UserGroupsActionCreator.set());
        },
        fetchUsers: () => {
            dispatch(UsersActionCreator.set());
        },
    };
};

const getColumnConfiguration = (
    onEdit: (item: Domain.Shared.UserGroup) => void,
    onDelete: (item: Domain.Shared.UserGroup) => void
): GridColumn<Domain.Shared.UserGroup>[] => {
    return [
        {
            name: 'name',
            title: 'Naam',
            width: '50%',
        },
        {
            name: 'userIds',
            title: 'Leden',
            calculateDisplayValue: (item) => item.userIds.length,
        },
        {
            name: 'id',
            title: '',
            type: 'buttons',
            width: '5%',
            align: 'right',
            hideInColumnChooser: true,
            renderCustom: ({ data }) => ((onEdit || onDelete) && (
                <ContextMenu<Domain.Shared.UserGroup>
                    keyExpr='id'
                    item={data}
                    actions={[
                        {
                            action: onEdit,
                            actionName: `edit-${data.id}`,
                            displayName: 'Bewerken',
                            ariaLabel: `Bewerk ${data.name}`,
                            hidden: !onEdit
                        },
                        {
                            action: onDelete,
                            actionName: `delete-${data.id}`,
                            displayName: 'Verwijderen',
                            ariaLabel: `Verwijder ${data.name}`,
                            hidden: !onDelete
                        }
                    ]}
                />))
        }
    ];
}

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
