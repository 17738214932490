import React, { useMemo } from 'react';
import { createSource, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { Finance } from '@liasincontrol/data-service';
import { FilterModel } from './StructuresFilter';
import * as Domain from '@liasincontrol/domain';
import { ValidationUtils } from '@liasincontrol/core-service';
import { useNavigate } from 'react-router-dom';

type Props = {
    lastRefresh: number;
    filter: FilterModel;
    onDataError: (error: any) => void;
}

type FilterKey = keyof FilterModel;
//https://bobbyhadz.com/blog/typescript-index-signature-parameter-cannot-be-union-type
type FilterExpr = {
    [key in FilterKey]?: any;
}

export const StructuresGrid: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const customDataSource = useMemo(() => {
        const cleanedFilter = Object.keys(props.filter).filter((column) => !ValidationUtils.isEmpty(props.filter[column]));
        const filter = cleanedFilter.map((column) => getColumnFilter(column as FilterKey, props.filter[column]));

        return createSource({
            keyExpr: "rk",
            paginate: true,
            filter: filter,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            dataSourcePromise: Finance.StructuresDataAccessor.getAll
        });
    }, [props.lastRefresh, props.filter]);

    const rowClick = (item: Domain.Finance.Structure) => {
        const rowId = item.rk;
        navigate(`/finance/admin/structures/${rowId}`);
    }

    return <LsGrid
        dataSource={customDataSource}
        onClickRow={rowClick}
        columns={columns}
        showRowLines={true}
        onDataError={props.onDataError}
        paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
    />;
};

const columns: GridColumn<Domain.Finance.Structure>[] =
    [
        {
            name: 'code',
            title: 'Code',
            allowSorting: false,
            width: '25%',
        },
        {
            name: 'name',
            title: 'Naam',
            allowSorting: false,
            width: '35%',
        },
        {
            name: 'baseYear',
            title: 'Basisjaar',
            allowSorting: false,
            width: '15%',
        },
        {
            name: 'journalElementKindRK',
            title: 'Elementsoort',
            allowSorting: false,
            width: '25%',
        }
    ];

const getColumnFilter = (column: FilterKey, value: any): FilterExpr => {
    switch (column) {
        case 'baseYear':
            return { baseYear: value };
        default:
            return { [column]: { contains: value } };
    }
};