import React from 'react';
import styled from 'styled-components';
import { palette } from '../../styleguide';
import { BasicText } from '../typography';
import { ITextProps } from './Props';

const Container = styled(BasicText) <Partial<ITextProps>>`
    color: ${(p) => (p.danger ? palette.danger1 : p.active ? palette.primary2 : palette.grey2)};
    font-weight: ${(p) => (p.bold ? 700 : 'inherit')};
    word-break: break-word;
`;

export const Text = ({ active, value, danger, bold }: ITextProps) => (
    <Container active={active} danger={danger} bold={bold}>
        {value}
    </Container>
);