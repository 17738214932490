import React, { useState } from 'react';
import { Button, ElementLabel, LayoutForm, LayoutField, palette, IconSize } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import { ValidationUtils } from '@liasincontrol/core-service';
import LinkIcon from '@mui/icons-material/Link';
import { TemplateIcon, TemplateName, TemplateNameContainer } from '../../index.styled';
import { PageTemplateDialog } from '../../PageTemplateDialog';

type PageTemplateSettingsProps = {
    readonly rootId: string,
    readonly publicationId: string,
    readonly pageDesigns: Domain.Publisher.PageDesign[],
    readonly disabled: boolean,
    readonly sitemapNode: Domain.Publisher.SitemapNode,
    readonly selectedTemplateName: string,
    readonly elementdefinitions: Record<string, Domain.Shared.ElementDefinition>,
    readonly onChangeTemplate: (newTemplateId: string, newTemplateName: string, pageIds: string[], parentId?: string) => void;
}

export const PageTemplateSettings: React.FC<PageTemplateSettingsProps> = (props) => {
    const [choosingTemplate, setChoosingTemplate] = useState<boolean>(false);

    const onInformationTemplateChange = (templateId: string, templateName: string) => {
        setChoosingTemplate(false);
        props.onChangeTemplate(templateId, templateName, [props.sitemapNode.elementId], null);
    };
    const noTemplate = ValidationUtils.isEmptyGuid(props.sitemapNode.pageTemplateId);

    return (<>
        <LayoutForm align='center' noMinHeight>
            <LayoutField left={1} top={1} width={5} height={1}>
                <ElementLabel>Gekoppeld sjabloon</ElementLabel>
            </LayoutField >
            <LayoutField left={6} top={1} width={1} height={1}>
                <Button
                    id="btn-select-template"
                    btnbase={noTemplate ? 'primarybuttons' : 'ghostbuttons'}
                    btntype="small_noicon"
                    onClick={() => setChoosingTemplate(true)}
                    aria-label="Sjabloon selecteren"
                    disabled={props.disabled}
                >
                    {noTemplate ? 'Selecteren' : 'Bewerken'}
                </Button>
            </LayoutField>
            <LayoutField left={1} top={2} width={6} height={1} borderBottom compactBorderBottom>
                <TemplateName className="grid">
                    {noTemplate
                        ? <>
                            <TemplateIcon>
                                <LinkIcon sx={{ color: palette.grey3, fontSize: IconSize.medium }} />
                            </TemplateIcon>
                            <TemplateNameContainer>
                                <div className='icon-name'>
                                    Niet geselecteerd
                                </div>
                            </TemplateNameContainer>
                        </>
                        : <>
                            <TemplateIcon>
                                <Button
                                    id="btn-change-icon"
                                    icon={<LinkIcon />}
                                    btnbase="iconbuttons"
                                    btntype="medium_transparentmain"
                                    onClick={() => setChoosingTemplate(true)}
                                    disabled={props.disabled}
                                />
                            </TemplateIcon>
                            <TemplateNameContainer>
                                <div className='icon-name'>
                                    {props.selectedTemplateName}
                                </div>
                            </TemplateNameContainer>
                        </>
                    }
                </TemplateName>
            </LayoutField>
        </LayoutForm>
        {
            choosingTemplate && (
                <PageTemplateDialog
                    publicationId={props.publicationId}
                    pageDesigns={props.pageDesigns}
                    elementDefinitions={props.elementdefinitions}
                    id={props.rootId}
                    currentTemplateId={props.sitemapNode.pageTemplateId}
                    onChange={onInformationTemplateChange}
                    onCancel={() => setChoosingTemplate(false)}
                />
            )
        }
    </>);
};