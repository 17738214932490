import React, { useMemo, useState } from 'react';
import { ApiErrorReportingHelper, ValidationUtils } from '@liasincontrol/core-service';
import { Finance } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import { ErrorOverlay, Heading2, PageTitle, Section, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { GridColumn, LsGrid, createSource } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { RealizationAmountsFilters, FilterModel } from './Filter';
import { useBaseYears } from '../../shared/hooks';

/**
 * Represents a UI component that renders the page with the list of RealizationAmounts.
 */
const RealizationAmounts: React.FC = () => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [filter, setFilter] = useState<FilterModel>({});
    const baseYears = useBaseYears();

    const customDataSource = useMemo(() => {
        const filterBy: { [x in keyof FilterModel]: any; }[] = Object.keys(filter)
            ?.filter((column: keyof FilterModel) => !ValidationUtils.isEmpty(filter[column]))
            ?.map((column: keyof FilterModel) => getColumnFilter(column, filter[column]));

        return createSource({
            keyExpr: 'journalElementCombinationRK',
            paginate: true,
            filter: filterBy,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            selectedColumns: Object.keys(new Domain.Finance.RealizationAmount()),
            dataSourcePromise: Finance.RealizationAmountDataAccessor.getAll
        });
    }, [filter]);

    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Realisatie</Heading2>
                </PageTitle>
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <Section look='white'>
                        <RealizationAmountsFilters
                            baseYears={baseYears.items}
                            filter={filter}
                            onFilterChanged={setFilter}
                        />
                        <LsGrid
                            showRowLines={true}
                            columns={availableColumns}
                            dataSource={customDataSource}
                            paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                            onDataError={(exception) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception))}
                        />
                    </Section>
                </ErrorOverlay>
            </WrapperContent>
        </Wrapper>
    );
};

const availableColumns: GridColumn<Domain.Finance.RealizationAmount>[] =
    [{
        name: 'baseYear',
        title: 'Basisjaar',
        align: 'left',
        allowSorting: false,
    },
    {
        name: 'journalElementCombinationRK',
        title: 'Combinatie',
        allowSorting: false,
    },
    {
        name: 'measureMomentTag',
        title: 'Moment label',
        allowSorting: false,
    },
    {
        name: 'description',
        title: 'Omschrijving',
        allowSorting: false,
    },
    {
        name: 'amount',
        title: 'Bedrag',
        align: 'right',
        formatter: 'decimal',
        allowSorting: false,
    },
    {
        name: 'invoiceDate',
        title: 'Factuurdatum ',
        dataType: 'date',
        formatter: 'datetime',
        allowSorting: false,
    },
    {
        name: 'journalDate',
        title: 'Boekdatum ',
        dataType: 'date',
        formatter: 'datetime',
        allowSorting: false,
    },
    {
        name: 'paymentDate',
        title: 'Betaaldatum',
        dataType: 'date',
        formatter: 'datetime',
        allowSorting: false,
    },
    {
        name: 'invoiceNumber',
        title: 'Factuurnummer',
        allowSorting: false,
    },
    {
        name: 'voucherNumber',
        title: 'Vouchernummer',
        allowSorting: false,
    },
    {
        name: 'accountName',
        title: 'Accountnaam',
        allowSorting: false,
    },
    {
        name: 'accountNumber',
        title: 'Accountnummer',
        allowSorting: false,
    },
    {
        name: 'period',
        title: 'Periode',
        align: 'right',
        formatter: 'integer',
        width: '5%',
        allowSorting: false,
    }];

const getColumnFilter = (column: keyof FilterModel, value: any) => {
    switch (column) {
        case 'journalElementCombinationRK':
        case 'measureMomentTag':
            return { [column]: { contains: value } };
        default:
            return { [column]: value };
    }
};

export { RealizationAmounts as index };
