//import { AppSettings } from "./AppSettings";

interface AppSettings {
    General: {
        /**
         * Check list of default page sizes in packages\libs\ui\LiasInControl.UI.DevExtreme\src\components\datagrid\index.tsx.
         */

        PageSize: number,
        /**
         * The default currency symbol used in the application.
         */
        CurrencySymbol: string,
    },
    Publisher: {
        Controls: {
            DataTable: {
                /**
                 * Check list of default page sizes in packages\libs\ui\LiasInControl.UI.DevExtreme\src\components\datatable\datatable.tsx
                 */
                PageSize: number,

                /**
                 * Defines the default number of visible columns to display for a new datasource.
                 */
                NumberOfVisibleColumns: number,
            }
        },
        Screens: {
            Cockpit: {
                /**
                 * Defines the number of publications to load in the cockpit.
                 */
                PublicationsToLoad: number,
            }
        }
    },
    Api: {
        Odata: {
            /**
             * Value to force as top() for all OData requests that don't specify an explicit limit.
             */
            DefaultBackendLimitOverride: number,
        },
        /**
         * Timeout in ms for all API calls , or 0 to disable.
         */
        Timeout: number,
        LeaseTimeout: number,
    }
}


/**
 * Service that handles retrieving the static application-wide configuration object.
 */
class AppSettingsService {

    /**
     * Retrieves and caches the application configuration object.
     */
    public static getAppSettings = (): AppSettings => {
        return {
            General: {
                PageSize: 25,
                CurrencySymbol: '€',
            },
            Publisher: {
                Controls: {
                    DataTable: {
                        PageSize: 25,
                        NumberOfVisibleColumns: 6
                    }
                },
                Screens: {
                    Cockpit: {
                        PublicationsToLoad: 4
                    }
                }
            },
            Api: {
                Odata: {
                    DefaultBackendLimitOverride: 100
                },
                // 30 seconds
                Timeout: 30 * 1000,
                // 10 minutes = 600 seconds
                LeaseTimeout: 600 * 1000,
            }
        };
    };
}

export { AppSettingsService, AppSettings };