import React, { useState } from 'react';
import { AutoFocus, PageTitle, EditingToolbar, ModalDialog, Heading1 } from '@liasincontrol/ui-basics';
import { ValueType, FormMode, FormInfo } from '@liasincontrol/core-service';
import { License } from '@liasincontrol/auth-service';
import { RoleForm } from '../../RoleForm';

type Props = {
    licenses: License[],
    roleNames: string[],
    onSave: (fields: Record<string, ValueType>) => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders the user role create page.
 */
export const RoleAdd: React.FC<Props> = (props) => {
    const [formInfo, setFormInfo] = useState<FormInfo<ValueType>>({ values: undefined, isValid: true, isTouched: false });

    return (
        <ModalDialog
            customPadding
            modalDialogStyle="custom"
            toolbars={<EditingToolbar
                id="popup-new-role"
                look="default"
                isVisible={true}
                disabled={!formInfo?.isTouched}
                isValid={formInfo.isValid}
                onSave={() => props.onSave(formInfo.values)}
                onCancel={props.onCancel}
            />}>
            <PageTitle>
                <Heading1>Rol aanmaken</Heading1>
            </PageTitle>
            <AutoFocus>
                <RoleForm
                    mode={FormMode.AddNew}
                    roleNames={props.roleNames}
                    licenses={props.licenses}
                    onFormDataChanged={setFormInfo}
                />
            </AutoFocus>
        </ModalDialog>

    );
};