import { createReducer } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { PatchesActionCreator, TenantActionCreator } from '../../../../actions/creators';

/**
 * Represents the patches reducer.
 */
const reducer = createReducer<{
    [pageId: string]: {
        [elementId: string]: {
            fields: { [fieldId: string]: string },
            complexFields: { [complexFieldDefinitionId: string]: { [rowIndex: number]: Domain.Shared.ComplexField } },
        }
    }
}>({}, (builder) => {
    return (
        builder.addCase(PatchesActionCreator.updateField, (state, action) => {
            const { pageId, set, remove } = action.payload.data;

            if (set && set.length > 0) {
                if (!(pageId in state)) {
                    state[pageId] = {};
                }
                set.forEach(({ elementId, fieldId, value }) => {

                    if (!(elementId in state[pageId])) {
                        state[pageId][elementId] = { fields: {}, complexFields: {} };
                    }

                    state[pageId][elementId].fields[fieldId] = value;
                });
            }

            if (remove && remove.length > 0) {
                remove.forEach(({ elementId, fieldId }) => {

                    if (pageId in state && elementId in state[pageId] && fieldId in state[pageId][elementId].fields) {
                        delete state[pageId][elementId].fields[fieldId];

                        if (Object.keys(state[pageId][elementId].fields).length === 0) {
                            delete state[pageId][elementId].fields;

                            if (Object.keys(state[pageId][elementId].complexFields).length === 0) {
                                delete state[pageId][elementId].complexFields;
                            }

                            if (Object.keys(state[pageId][elementId]).length === 0) {
                                delete state[pageId][elementId];
                            }

                            if (Object.keys(state[pageId]).length === 0) {
                                delete state[pageId];
                            }
                        }
                    }
                });
            }

            return state;
        }),
        builder.addCase(PatchesActionCreator.updateComplexField, (state, action) => {
            const { pageId, set, remove } = action.payload.data;

            if (set && set.length > 0) {
                if (!(pageId in state)) {
                    state[pageId] = {};
                }
                set.forEach(({ elementId, complexFieldDefinitionId, rowIndex, complexField }) => {

                    if (!(elementId in state[pageId])) {
                        state[pageId][elementId] = { complexFields: {}, fields: {} };
                    }

                    state[pageId][elementId].complexFields[complexFieldDefinitionId] = state[pageId][elementId].complexFields[complexFieldDefinitionId] ? state[pageId][elementId].complexFields[complexFieldDefinitionId] : {};
                    state[pageId][elementId].complexFields[complexFieldDefinitionId][rowIndex] = complexField;
                });
            }

            if (remove && remove.length > 0) {
                remove.forEach(({ elementId, complexFieldDefinitionId, rowIndex }) => {

                    if (pageId in state && elementId in state[pageId]
                        && complexFieldDefinitionId in state[pageId][elementId].complexFields
                        && rowIndex in state[pageId][elementId].complexFields[complexFieldDefinitionId]) {
                        state[pageId][elementId].complexFields[complexFieldDefinitionId][rowIndex].fields = {};

                        if (Object.keys(state[pageId][elementId].complexFields).length === 0) {
                            delete state[pageId][elementId].complexFields;

                            if (Object.keys(state[pageId]).length === 0) {
                                delete state[pageId];
                            }
                        }
                    }
                });
            }

            return state;
        }),
        builder.addCase(PatchesActionCreator.clear, (state, action) => {
            const { pageId } = action.payload.data;

            if (pageId in state) {
                delete state[pageId];
            }

            return state;
        }),
        builder.addCase(TenantActionCreator.initTenant, (state, action) => {
            state = {};
            return state;
        })
    );
});

export default reducer;
