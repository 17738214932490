import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import { ConfigService, Config } from '@liasincontrol/config-service';
import * as Domain from '@liasincontrol/domain';
import { oDataResponse } from '../oData';
import { DataAccessHostUtils } from '../DataAccessHostUtils';

/**
 * Represents the modules data accessor.
 */
export class Modules {

    private _configuration: Config;

    /**
     * Constructs and registers an instance of this class.
     */
    private constructor(configuration) {
        // Prevents inheritance i.e. class cannot be inherited. 
        // It also prevents other classes to instantiate the class.
        this._configuration = configuration;
    }

    /**
     * Gets the list of modules related to a feature/microservice.
     * 
     * @param endpoint Defines the host that will be used to ask for the list of modules.
     */
    public get = (endpoint: Domain.Shared.ApiEndpoint, query = ''): Promise<AxiosResponse<oDataResponse<Domain.Shared.Module[]>>> => {
        const config = DataAccessHostUtils.getModuleRequestConfig(endpoint, this._configuration);

        return HttpClient.get<oDataResponse<Domain.Shared.Module[]>>(`/api/module${query}`, config);
    }

    /**
     * Gets an instance of the ModulesDataAccess class.
     *
     * @returns An instance of the ModulesDataAccess class.
     */
    public static async getInstance(): Promise<Modules> {
        if (Modules.instance === null || Modules.instance === undefined) {
            const config = await ConfigService.getConfigurations();
            Modules.instance = new Modules(config);
        }

        return Modules.instance;
    }

    private static instance: Modules;

}

