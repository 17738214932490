import styled from 'styled-components';
import { ElementLabel, px } from '@liasincontrol/ui-basics';

const Label = styled(ElementLabel)`
    padding-left: ${px(24)};
`;

const styledComponents = { Label };

export default styledComponents;
