import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import RefreshIcon from '@mui/icons-material/Refresh';
import DeleteIcon from '@mui/icons-material/Delete';
import ReorderIcon from '@mui/icons-material/Reorder';
import * as Domain from '@liasincontrol/domain';
import { Shared as SharedDataAccess } from '@liasincontrol/data-service';
import { ValueType, FormMode, FormInfo, ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { State, ActionSource, ModulesActionCreator, WorkflowTemplateActionCreator, AjaxRequestStatus } from '@liasincontrol/redux-service';
import { WrapperContent, PageTitle, Heading1, Bar, Button, EditingToolbar, ErrorOverlay, ModalDialog } from '@liasincontrol/ui-basics';
import { IndicatorSize, LoadIndicator } from '@liasincontrol/ui-devextreme';
import { WorkflowTemplateForm } from '../WorkflowTemplateForm';
import { workflowStatusListGuid, WorkflowStatusMode } from '../WorkflowTemplateList';
import { WorkflowTemplateDeleteDialog } from './WorkflowTemplateDeleteDialog';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

/**
 * Represents a UI component that renders the workflow details page.
 */
const Index: React.FC<Props> = (props) => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();

    const [workflowTemplate, setWorkflowTemplate] = useState<Domain.Shared.WorkflowTemplateWithStates>(undefined);
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [formInfo, setFormInfo] = useState<FormInfo<ValueType>>({ values: undefined, isValid: true, isTouched: false });
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [deleteDialog, setDeleteDialog] = useState<{ visible: boolean, isRemoving: boolean }>({ visible: false, isRemoving: false });
    const [editDialog, setEditDialog] = useState<{ viewMode: FormMode, isOrdering: boolean }>({ viewMode: FormMode.View, isOrdering: false });
    const workflowNames: string[] = useMemo(() => props.workflowTemplates?.items?.filter(item => item.id !== id)?.map(item => item.name), [props.workflowTemplates.items]);

    useEffect(() => {
        if (!id || (props.workflowTemplates.status === AjaxRequestStatus.NotSet)) return;
        const currentWf = props.workflowTemplates.items?.find(item => item.id === id);
        if (currentWf) {
            setWorkflowTemplate(currentWf);
        }
    }, [id, lastRefresh, props.workflowTemplates.items]);

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (props.workflowTemplates.status === AjaxRequestStatus.NotSet) {
        props.fetchWorkflowTemplates();
        return null;
    }

    const getWorkflowTemplateControl = (): JSX.Element => {
        if (!workflowTemplate) {
            return null;
        }

        return (
            <WorkflowTemplateForm
                workflowTemplate={workflowTemplate}
                workflowNames={workflowNames || []}
                mode={editDialog.viewMode}
                isOrdering={editDialog.isOrdering}
                onFormDataChanged={setFormInfo}
                onOrderState={updateStateOrder}
            />
        );
    };

    /**
     * Represents an event handler that triggers when discarding the workflow template changes.
     */
    const cancelChanges = () => {
        setEditDialog({ viewMode: FormMode.View, isOrdering: false });
    };

    /**
     * Represents an event handler that triggers when saving the workflow template changes.
     */
    const saveChanges = () => {
        if (!formInfo.values) {
            setEditDialog({ viewMode: FormMode.View, isOrdering: false });
            return;
        }
        const [existingStates, newStates] = formInfo.values[workflowStatusListGuid]
            ?.reduce(([es, ns], state) => {
                switch (state.status) {
                    case WorkflowStatusMode.Changed: return [[...es, { id: state.id, name: state.name, category: state.category, replaceWithStateName: state.replaceWithStateName }], ns];
                    case WorkflowStatusMode.New: return state.replaceWithStateName ? [es, ns] : [es, [...ns, { name: state.name, category: state.category }]];
                    default: return [es, ns];
                }
            }, [[], []]);

        const updatedWorkFlow: Domain.Dto.Shared.UpdateWorkflowWithState = {
            workflowName: formInfo.values[Domain.FieldDefinitions.Shared.nameFieldDefinition.systemId],
            existingStates: existingStates || [],
            newStates: newStates || [],
        };

        SharedDataAccess.WorkflowTemplate.update(id, updatedWorkFlow)
            .then(() => {
                props.fetchWorkflowTemplates();
            }).catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.WorkflowStateLimitReached)) {
                    setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.WorkflowStateLimitReached] });
                } else {
                    setError(errorInfo);
                }
            }).finally(() => {
                cancelChanges();
                setLastRefresh(Date.now());
            });
    };

    const deleteWorkflowTemplate = (workflowId: string) => {
        SharedDataAccess.WorkflowTemplate.delete(workflowId)
            .then(() => {
                navigate(`/admin/workflow/list`);
                props.fetchWorkflowTemplates();
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, err);

                if (errorInfo?.details?.type && errorInfo.details.type.includes(Domain.Shared.ApiKnownErrorTypes.WorkflowTemplateIsUsed)) {
                    setError({ ...errorInfo, message: `De workflow "${workflowTemplate.name}​​" is gebruikt en kan niet worden verwijderd.` });
                } else if (errorInfo?.details?.type && errorInfo.details.type.includes(Domain.Shared.ApiKnownErrorTypes.WorkflowTemplateIsUsedInJournalKind)) {
                    setError({ ...errorInfo, message: `De workflow "${workflowTemplate.name}​​" wordt gebruikt door een journaalsoort en kan niet worden verwijderd.` });
                } else {
                    setError(errorInfo);
                }
            }).finally(() => {
                setDeleteDialog({ visible: false, isRemoving: false });
            });
    };

    const setActiveStatusWorkflowTemplate = (workflowId: string, status: boolean) => {
        SharedDataAccess.WorkflowTemplate.setActiveStatus(workflowId, status)
            .then(() => {
                setLastRefresh(Date.now())
                props.fetchWorkflowTemplates();
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err);
                setError(errorInfo);
            });
    };

    const updateStateOrder = (stateId: string, newOrder: number) => {
        SharedDataAccess.WorkflowTemplate.updateWorkflowStateOrder(workflowTemplate.id, stateId, newOrder)
            .then(() => {
                setLastRefresh(Date.now())
                props.fetchWorkflowTemplates();
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err);
                setError(errorInfo);
            });
    };

    return (
        <WrapperContent>
            <PageTitle>
                <Heading1>
                    <Button
                        btnbase='iconbuttons'
                        btntype='medium_transparentmain'
                        icon={<ArrowBack />}
                        onClick={() => navigate('/admin/workflow/list')}
                    />
                    {workflowTemplate ? workflowTemplate.name : !error ? <LoadIndicator variant={IndicatorSize.default} /> : 'Workflow'}
                </Heading1>
            </PageTitle>
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                <Bar look='toolbar'>
                    <Bar start>
                        {!error && <Button id={`btn-edit-${id}`} btnbase='textbuttons' btntype='medium_icon' onClick={() => setEditDialog({ viewMode: FormMode.Edit, isOrdering: false })}>
                            Bewerken
                        </Button>}
                        <Button id={`btn-refresh-${id}`} btnbase='textbuttons' btntype='medium_icon' icon={<RefreshIcon />} onClick={() => setLastRefresh(Date.now())}>
                            Verversen
                        </Button>
                        {workflowTemplate &&
                            <Button id={`btn-${workflowTemplate.isActive ? 'deactivate' : 'activate'}-${id}`} btnbase='textbuttons' btntype='medium_icon' onClick={() => setActiveStatusWorkflowTemplate(workflowTemplate.id, workflowTemplate.isActive ? false : true)}>
                                {workflowTemplate.isActive ? 'Deactiveren' : 'Activeren'}
                            </Button>
                        }
                        {workflowTemplate &&
                            <Button id={`btn-reorder-${id}`} btnbase='textbuttons' btntype='medium_icon' icon={<ReorderIcon />} onClick={() => setEditDialog({ viewMode: FormMode.Edit, isOrdering: true })}>
                                Ordenen
                            </Button>
                        }
                        <Button id={`btn-delete-${id}`} btnbase='textbuttons' btntype='medium_icon' icon={<DeleteIcon />} onClick={() => setDeleteDialog({ visible: true, isRemoving: false })}>
                            Verwijder
                        </Button>
                    </Bar>
                </Bar>
                {editDialog.viewMode === FormMode.Edit ? (
                    <ModalDialog customPadding modalDialogStyle='custom'
                        toolbars={<EditingToolbar
                            id={workflowTemplate.id}
                            look='default'
                            isVisible={editDialog.viewMode === FormMode.Edit}
                            isValid={formInfo?.isValid}
                            disabled={!formInfo?.isTouched}
                            showSaveButton={!editDialog.isOrdering}
                            onSave={saveChanges}
                            onCancel={cancelChanges} />}>
                        {getWorkflowTemplateControl()}
                    </ModalDialog>
                ) : (
                    getWorkflowTemplateControl()
                )}
            </ErrorOverlay>
            {deleteDialog.visible ? (
                <WorkflowTemplateDeleteDialog
                    selectedWorkflowTemplate={workflowTemplate}
                    isBusy={deleteDialog.isRemoving}
                    onCancel={() => setDeleteDialog({ visible: false, isRemoving: false })}
                    onConfirm={() => {
                        setDeleteDialog((prev) => ({ ...prev, isRemoving: true }));
                        deleteWorkflowTemplate(workflowTemplate.id);
                    }} />
            ) : null}
        </WrapperContent >
    );

};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Publication],
        workflowTemplates: state.workflowtemplates,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchWorkflowTemplates: () => {
            dispatch(WorkflowTemplateActionCreator.set());
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
