import styled, { keyframes } from 'styled-components';
import { palette } from '@liasincontrol/ui-basics';

const paletteGradient = {
  darkRed: '#AC0000',
  red: '#C50000',
  orange: '#FF8800',
  green: '#02AC02',
  darkGreen: '#008100',
};

/* Keyframe animations - start */
const progress = (offset: number) => keyframes`
    0% {
        stroke-dashoffset: 1570;
    }
    100% {
        stroke-dashoffset: ${offset};
    }
`;

const toEndColor = keyframes`
  to {
    stroke: ${paletteGradient.darkRed};
  }
`;

const toEndColorReverse = keyframes`
  to {
    stroke: ${paletteGradient.darkGreen};
  }
`;
/* Keyframe animations - end */

const CircleBG = styled.path`
    fill: none;
    stroke: ${palette.primary3};
    stroke-width: 60;
`;

const CirclePath = styled.path<{offset: number}>`
    animation: ${(props) => progress(props.offset)} 1.5s ease 1s forwards;
`;

// It is not possible to pass a parameter to the second animation using Styled Components.
const CirclePathToEndColor = styled.path<{offset: number}>`
    animation: ${(props) => progress(props.offset)} 1.2s ease-in 1s forwards, ${toEndColor} 0.1s ease 2.15s forwards;
`;

const CirclePathToEndColorReverse = styled.path<{offset: number}>`
    animation: ${(props) => progress(props.offset)} 1.2s ease-in 1s forwards, ${toEndColorReverse} 0.1s ease 2.15s forwards;
`;

const styledComponents = { CircleBG, CirclePath, CirclePathToEndColor, CirclePathToEndColorReverse, paletteGradient };
export default styledComponents;
