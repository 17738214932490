import React from 'react';
import styled from 'styled-components';
import { px } from '../style/pixel';

const Container = styled.div<{ columns?: number, noMinHeight?: boolean, align?: string }>`
    display: grid;
    grid-template-columns: repeat(${({ columns }) => columns ? columns : 6},1fr);
    grid-auto-flow: row;
    grid-gap: ${px(4)} ${px(34)};
    min-height: ${({ noMinHeight }) => noMinHeight ? '0' : px(15 * 8)};
    align-self: flex-start;
    align-items: ${({ align }) => align ?? 'start'};
`;

export const LayoutForm = ({ columns, noMinHeight, align, children }: { columns?: number, noMinHeight?: boolean, align?: string, children?: React.ReactNode }) =>
    (<Container columns={columns} noMinHeight={noMinHeight} align={align}>{children}</Container>);

