import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import * as Domain from '@liasincontrol/domain';
import { Studio } from '@liasincontrol/data-service';
import { Bar, Button, Heading1 } from '@liasincontrol/ui-basics';
import { GridColumn, LsGrid, Popup } from '@liasincontrol/ui-devextreme';
import { LinkStructureNodeToHierarchyItem } from './LinkStructureNodeToHierarchyItem';
import Styled from './index.styled';
import { MomentType } from '../HierarchyList/HerarchyListItem';

type Props = {
    hierarchyItemId: string,
    linkedStructures: Domain.Shared.LinkedStructure[],
    measureMoment: MomentType,
    onRefresh: () => void,
};

export const HierarchyItemStructures: React.FC<Props> = (props) => {
    const { hierarchyId } = useParams();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const onSubmit = (structureCode: string, nodeCode: string) => {
        Studio.HierarchyDataAccessor.linkHierarchyItemToStructureNode(hierarchyId, props.hierarchyItemId, { structureCode, nodeCode })
            .then(() => {
                props.onRefresh();
                setIsModalVisible(false);
            });
    };

    return (
        <Styled.GridContainer>
            <Styled.Column>
                <Bar>
                    <Button
                        btnbase='primarybuttons'
                        btntype='small_noicon'
                        disabled={props.linkedStructures?.length > 0 || props.measureMoment.closed}
                        onClick={() => setIsModalVisible(true)}
                    >
                        Nieuw
                    </Button>
                </Bar>
                <Popup
                    visible={isModalVisible}
                    titleRender={() => <Heading1>Toevoegen Structuuronderdeel</Heading1>}
                    height='350px'
                    width='600px'
                >
                    <LinkStructureNodeToHierarchyItem
                        baseYear={props.measureMoment?.baseYear}
                        onSubmit={onSubmit}
                        onClose={() => setIsModalVisible(false)}
                    />
                </Popup>
                <LsGrid
                    dataSource={props.linkedStructures}
                    columns={getLinkedStructuresColumns(hierarchyId, props.hierarchyItemId, props.onRefresh)}
                />
            </Styled.Column>
        </Styled.GridContainer>
    );
};

const getLinkedStructuresColumns = (hierarchyId, hierarchyItemId, onDelete): GridColumn<Domain.Shared.LinkedStructure>[] => ([
    {
        name: 'structureNodeCode',
        title: 'Code',
    },
    {
        name: 'structureNodeName',
        title: 'Naam',
    },
    {
        type: 'buttons',
        width: '10%',
        renderCustom: () => (
            <Button
                btnbase='iconbuttons'
                btntype='small_fabprimary'
                icon={<DeleteOutlinedIcon />}
                onClick={() => {
                    Studio.HierarchyDataAccessor.unlinkStructureNodeFromHierarchyItem(hierarchyId, hierarchyItemId)
                        .then(onDelete);
                }}
            />
        )
    }]);
