import { createReducer } from '@reduxjs/toolkit';
import { FinanceBaseYearsActionCreator, TenantActionCreator } from '../../../../actions/creators';
import { AjaxRequestStatus } from '../../../state';

/**
 * Represents the Finance baseYears reducer.
 */
const reducer = createReducer<{ items: number[], status: AjaxRequestStatus }>({ items: undefined, status: AjaxRequestStatus.NotSet }, (builder) => {
    return (

        builder.addCase(FinanceBaseYearsActionCreator.set.fulfilled, (state, action) => {
            state = {
                items: action.payload.sort((a, b) => a - b),
                status: AjaxRequestStatus.Done,
            };
            return state;
        }),
        builder.addCase(FinanceBaseYearsActionCreator.set.pending, (state, action) => {
            // Loading in progress;
            state = {
                items: [],
                status: AjaxRequestStatus.Pending,
            };
            return state;
        }),
        builder.addCase(FinanceBaseYearsActionCreator.set.rejected, (state, action) => {
            state = {
                items: [],
                status: AjaxRequestStatus.Failed,
            };
        }),
        builder.addCase(TenantActionCreator.initTenant, (state, action) => {
            state = {
                items: [],
                status: AjaxRequestStatus.NotSet,
            };
            return state;
        })
    );
});

export default reducer;
