import React from 'react';
import { Routes as AIRoutes } from '@liasincontrol/ai';
import { SideMenuLayout } from '../Layouts';

/**
 * Represents an UI element that renders all AI module sub-routes, in their specific Layouts.
 */
const AIModuleRoutes: React.FC = () => {
    return (
        <SideMenuLayout
            look='normal'
            sideMenu={AIRoutes.AIMenu}
            components={AIRoutes.Contents}
        />
    );
}

export default AIModuleRoutes;
