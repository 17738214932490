import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { palette, px } from '@liasincontrol/ui-basics';
import { ColorUtils } from '@liasincontrol/core-service';
import { lineHeightCoefficient } from '../../SharedControlElements/index.styled';

const Wrapper = styled.div<{
  primaryColor: string,
  primaryTextColor: string,
  textColor: string,
  textFontSize?: string,
  editMode: boolean
}>`

  pointer-events: ${({ editMode }) => (editMode ? `none` : `auto`)};   
  font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px` : 'inherit')};
  line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px` : 'inherit')};
  color: ${({ textColor }) => textColor};
    
  padding: 0;  
  display: grid;
  grid-column-gap: ${px(30)};
  grid-row-gap: ${px(30)};
  grid-template-columns: repeat(auto-fill, minmax(${px(150)}, 1fr));
`;

const Card = styled.div<{ active: boolean; clickable: boolean, textColor: string, textFontSize?: string }>`
  background-color: ${(p) => (p.active ? palette.grey4 : `none`)};
  color: ${(p) => (p.clickable ? p.textColor : ColorUtils.shadeColor(p.textColor, -8))};
  user-select: ${(p) => (p.clickable ? `inherit` : `none`)};
  height: ${(p) => (p.textFontSize ? `calc(${px(260)} + ${px(+p.textFontSize * 2)})` : `${px(260)}`)};
  box-shadow: 0px 1px 2px ${palette.grey3b};
  border-radius: 5px;

  &:hover {
    background: #767676!important;
    color: ${palette.white}!important;
  }

  &:focus {
    /* inner indicator */
    outline: 2px #ffffff solid !important;
    outline-offset: 0;
    /* outer indicator */
    box-shadow: 0 0 0 4px #000000 !important;
    z-index: 1;
}
`;

const CardHeader = styled.div`
  width: 100%;
  height: ${px(160)}; 
  position: relative;
`;

const CardContent = styled.div`
  width: 100%;
  padding: ${px(24)};
`;

const ImagePlaceholderIcon = styled(ImageOutlinedIcon)`
  display: inline-block;
  fill: ${palette.grey3};
`;

const ImagePlaceholderWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${palette.grey4};
  height: ${px(160)};
`;

const ImagePlaceholder = styled.div<{ color?: string, }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  
  ${(p) => p.color
    ? css`background-color: ${p.color};`
    : css`background-image: radial-gradient(circle, ${palette.grey4} 0%, ${palette.grey3} 100%);`
  } 

  border-top-right-radius: ${px(5)};
  border-top-left-radius: ${px(5)};
`;


const ArrowIcon = styled.span<{ backgroundColor: string; iconColor: string }>`
  position: absolute;
  height: ${px(40)};
  width: ${px(40)};
  right: ${px(24)};
  bottom: ${px(-16)};
  border-radius: 100%;
  align-content: center;
  background-color: ${(p) => p.backgroundColor};
  color: ${(p) => p.iconColor};
  display: flex;
  justify-content: center;
  align-items: center;

  ::after {
    content: 'arrow_forward';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: ${px(20)};
  }
`;

const ImageWrapper = styled.div`
  background-color: ${palette.grey4};
  border-top-right-radius: ${px(5)};
  border-top-left-radius: ${px(5)};
`;

const ImagePositionWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const TileTitle = styled.span`  
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: ${px(2)};
`;

const TileDescription = styled.span`  
  font-style: italic;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;  
`;

const CustomLink = styled(Link)`
    color: inherit;
    text-decoration: 'none';
    line-height: inherit;

    &:link, &:visited, &:focus, &:hover, &:active {
        color: inherit!important;
        text-decoration: 'none';
        line-height: inherit;
        cursor: pointer;
    }

`;

const styledComponents = {
  Wrapper,
  Card,
  CardHeader,
  CardContent,
  ImagePlaceholderIcon,
  ImagePlaceholderWrapper,
  ImagePlaceholder,
  ArrowIcon,
  ImageWrapper,
  ImagePositionWrapper,
  TileTitle,
  TileDescription,
  CustomLink
};
export default styledComponents;
