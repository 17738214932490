import React from "react";
import { useState } from "react";
import { useBaseYears } from "../../shared/hooks";
import { LayoutField, LayoutForm } from '@liasincontrol/ui-basics';
import { SelectElement } from '@liasincontrol/ui-elements';

export type FilterModel = {
    baseYear?: number;
}

type Props = {
    onFilter: (filter: FilterModel) => void;
}

export const StructuresFilter: React.FC<Props> = (props) => {
    const [filter, setFilter] = useState<FilterModel>({});
    const baseYears = useBaseYears();

    const onFilterChanged = (key: keyof FilterModel, value: number) => {
        setFilter((prev) => {
            const newValue = { ...prev, [key]: value };
            props.onFilter(newValue);
            return newValue;
        });
    }

    return <LayoutForm columns={4}>
        <LayoutField left={1} top={1} width={1} height={1}>
            <SelectElement<number>
                id='filter-base-year'
                label='Basisjaar'
                optionItems={baseYears.items || []}
                value={filter.baseYear}
                clearable={true}
                searchable={false}
                editorSettings={{
                    disabled: false,
                    restrictions: { required: false },
                    validationErrors: [],
                    onChange: (item) => onFilterChanged('baseYear', item),
                }}
            />
        </LayoutField>
    </LayoutForm>
};