import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents the builds data accessor.
 */
export class Builds {
    /**
     * Get build information related to a website publication.
     *
     * @param publicationId Defines the unique identifier of the publication.
     */
    public static getPublicationWebsiteBuild = async (publicationId: string): Promise<AxiosResponse<Domain.Publisher.WebsiteBuild>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.WebsiteBuild>(`/api/build/${publicationId}/status/website`, config);
    };

    /**
     * Get build information related to a document publication.
     *
     * @param publicationId Defines the unique identifier of the publication.
     */
    public static getPublicationDocumentBuild = async (publicationId: string): Promise<AxiosResponse<Domain.Publisher.DocumentBuild>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.DocumentBuild>(`/api/build/${publicationId}/status/document`, config);
    };

    /**
     * Starts the publication generation to website.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param versionName Defines the name of the version.
     * @param isPublicWebsite Determines if a publication is public.
     * @param publishProfileId Defines the unique identifier of the publish profile.
     */
    public static generatePublicationWebsite = async (
        publicationId: string,
        versionName: string,
        isPublicWebsite: boolean,
        publishProfileId: string
    ): Promise<AxiosResponse<Domain.Publisher.WebsiteBuild>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        const body = { versionName: versionName, isPublic: isPublicWebsite, publishProfileId: publishProfileId };
        return HttpClient.put<Domain.Publisher.WebsiteBuild>(`/api/build/${publicationId}/site`, body, config);
    };

    /**
     * Starts the publication generation to document.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param publishProfileId Defines the unique identifier of the publish profile.
     */
    public static generatePublicationDocument = async (publicationId: string, publishProfileId: string): Promise<AxiosResponse<Domain.Publisher.DocumentBuild>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        const body = { publishProfileId: publishProfileId };
        return HttpClient.put<Domain.Publisher.DocumentBuild>(`/api/build/${publicationId}/document`, body, config);
    };

    /**
     * Gets a list with all the versions of a given publication.
     *
     * @param publicationId Defines the unique identifier of the publication.
     */
    public static getPublicationWebsiteVersions = async (publicationId: string): Promise<AxiosResponse<Domain.Publisher.PublicationWebsiteVersion[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.PublicationWebsiteVersion[]>(`/api/build/${publicationId}/versions`, config);
    };
}
