import { Button, Heading1, px } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

interface ILeftSideBarPanel {
    readonly hasPadding: boolean;
}

const LeftSideBarPanel = styled.div`
    height: calc(100% - 64px);
    overflow-y: auto;
    width: ${px(500)};
    padding: ${(p: ILeftSideBarPanel) => (p.hasPadding ? `${px(32)} ${px(40)} ${px(32)} ${px(40)}` : `0`)};

    ::-webkit-scrollbar {
        width: ${px(15)};
    }
    ::-webkit-scrollbar-track {
        background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #ececf3;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #e3e3ec;
    }
    ::-webkit-scrollbar-button,
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
`;

const EdgeToolbarContent = styled.div`
    margin: ${px(24)};
`;

const ToolbarTitle = styled(Heading1)`
    display: block;
    margin: 0 0 ${px(24)} 0;
`;

const TabsPlaceholder = styled.div`
    background-color: #fff;
    grid-column: 2;
    grid-row: 1 / -1;
`;

const EdgeToolbarButton = styled(Button)`
    display: block !important;
    margin-bottom: ${px(16)} !important;
`;
export { LeftSideBarPanel, EdgeToolbarContent, ToolbarTitle, EdgeToolbarButton, TabsPlaceholder };