import React, { useEffect, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { Button, InputCheckbox, MultiSelectItem } from '@liasincontrol/ui-basics';
import { ConnectingDialog } from '@liasincontrol/ui-elements';
import Styled from './index.styled';

type Props = {
    users: Domain.Shared.User[],
    disabled: boolean,
    hierarchyRights?: Domain.Performance.HierarchyItemRight[],
    onAssignContributors: (contributors: Record<string, boolean>) => void,
};

/**
 * Represents a UI component that renders the contributors editing section within a performance hierarchy form.
 */
const HierarchyItemContributors: React.FC<Props> = (props) => {
    const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);
    const [isInheritanceEnabled, setIsInheritanceEnabled] = useState<boolean>(false);

    useEffect(() => {
        if (!isDialogVisible) {
            setIsInheritanceEnabled(false);
        }
    }, [isDialogVisible]);

    const getContributorsDialogElement = () => {
        const list: MultiSelectItem[] =
            props.users
                .filter(user => !props.hierarchyRights?.map(contributor => contributor.userId).includes(user.id))
                .map(user => ({
                    label: user.name,
                    id: user.id,
                    value: isInheritanceEnabled,
                }));

        return (
            <ConnectingDialog
                disableSaveButton={false}
                mandatory={true}
                title='Bijdrager koppelen'
                listItems={list}
                onCancelled={() => setIsDialogVisible(false)}
                onConfirmed={(selectedUsers) => {
                    const contributors = [
                        ...(props.hierarchyRights ? props.hierarchyRights?.map((contributor) => ({ [contributor.userId]: contributor.inheritable })) : []),
                        ...selectedUsers?.map((contributer) => ({ [contributer.id]: isInheritanceEnabled }))
                    ].reduce(
                        (collection, item) => ({ ...collection, [Object.keys(item)[0]]: Object.values(item)[0] }),
                        {}
                    );

                    setIsDialogVisible(false);
                    props.onAssignContributors(contributors);
                }}
            >
                <Styled.InputCheckboxWrapper>
                    <InputCheckbox
                        label="Voeg de selectie van beheerders ook toe op de onderliggende items"
                        disabled={false}
                        ariaLabel="Voeg de selectie van beheerders ook toe op de onderliggende items"
                        selected={isInheritanceEnabled}
                        onChange={(value) => {
                            setIsInheritanceEnabled(value);
                        }} />
                </Styled.InputCheckboxWrapper>
            </ConnectingDialog>
        );
    };

    const getContributorElements = () => {
        const elements = props.hierarchyRights?.map((contributor) => {
            const user = props.users.find(user => user.id === contributor.userId);
            return user ? (
                <Styled.ContributorItem key={contributor.userId}>
                    {user.name}
                    {!props.disabled &&
                        <Styled.RemoveContributorIcon
                            onClick={() => {
                                const contributors = props.hierarchyRights.filter(item => item.userId !== contributor.userId)
                                    .map((item) => ({ [item.userId]: item.inheritable }))
                                    .reduce(
                                        (collection, item) => ({ ...collection, [Object.keys(item)[0]]: Object.values(item)[0] }),
                                        {}
                                    );
                                props.onAssignContributors(contributors);
                            }}
                        />}
                </Styled.ContributorItem>
            ) : null;
        });

        return elements;
    };

    return (
        <>
            <Styled.Content>
                <Styled.Wrapper>
                    <Styled.Title>Beheerder koppelen</Styled.Title>
                    <div>Een beheerder kan bijdragers koppelen aan de kaart.</div>
                    <Styled.ButtonWrapper>
                        <Button
                            id='btn-assign-contributors'
                            btnbase='textbuttons'
                            btntype='large_noicon'
                            disabled={props.disabled}
                            onClick={() => setIsDialogVisible(true)}>
                            Voeg Toe
                        </Button>
                    </Styled.ButtonWrapper>
                    {getContributorElements()}
                </Styled.Wrapper>
            </Styled.Content>
            {isDialogVisible && getContributorsDialogElement()}
        </>
    );
}

export { HierarchyItemContributors };
