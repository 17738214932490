import React from 'react';

type Props = {
    children?: React.ReactNode;
};
/**
 * Represents a UI component that renders the head container.
 */
const HeadContainer: React.FC<Props> = (props) => {
    return (<>{props.children}</>);
};

export default HeadContainer;
