import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class AccordionDataControl extends BaseControlElement {
    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Title)
    title: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.MeasureMomentId)
    measureMomentId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.EntityTypeId)
    entityTypeId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ParentEntityTypeId)
    parentEntityTypeId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ParentEntityId)
    parentEntityId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.HeaderFieldId)
    headerFieldId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.FieldsListJson)
    fieldsListJson: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.HierarchyDefinitionId)
    hierarchyDefinitionId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.IsStudioControl)
    isStudioControl = false;
}

export default AccordionDataControl;
