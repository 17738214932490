import React, { useState, useEffect } from 'react';
import { FieldsHelper } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import _, { Dictionary } from 'lodash';
import Styled from './index.styled';

/**
 * Represents the type of a title.
 */
enum TitleType {
    Title = 1,
    SubtitleLarge = 2,
    SubtitleSmall = 3
}

type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Dictionary<Domain.Publisher.Element>,
    publicationElement: Domain.Publisher.PublicationElement,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
}

// Placeholder for text if title-value is empty.
const PLACEHOLDER_TITLE = '[Geef titel in]';

/**
 * Represents a UI component that renders a title control.
 */
const TitleControl: React.FC<Props> = (props) => {
    const [currentElement, setCurrentElement] = useState<{
        data: Domain.Publisher.TitleControlElement,
        definition: Domain.Shared.ElementDefinition
    }>();

    useEffect(() => {
        const element = props.elementList[props.element.elementId];
        if (element) {
            const definition = props.getElementDefinition(element.elementDefinitionSystemId, element.elementDefinitionId);
            const data = new Domain.Publisher.TitleControlElement();
            FieldsHelper.mapObject<Domain.Publisher.TitleControlElement>(data, definition.fields, element.fields);

            setCurrentElement({ data, definition });
        }
    }, [props.elementList[props.element.elementId]]);

    if (!currentElement?.data) {
        return null;
    }

    const value: string = currentElement.data.text || PLACEHOLDER_TITLE;
    const stylingValue = FieldsHelper.mapFieldOption<Domain.Publisher.TitleControlElement>(currentElement.data, 'styling', currentElement.definition);
    switch (stylingValue?.value) {
        case TitleType.Title:
            return <Styled.Title h1FontSize={+props.publicationElement?.h1FontSize} h1FontColor={props.publicationElement?.h1FontColor}>{value}</Styled.Title>;
        case TitleType.SubtitleLarge:
            return <Styled.SubtitleLarge h2FontSize={+props.publicationElement?.h2FontSize} h2FontColor={props.publicationElement?.h2FontColor}>{value}</Styled.SubtitleLarge>;
        case TitleType.SubtitleSmall:
            return <Styled.SubtitleSmall h3FontSize={+props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}>{value}</Styled.SubtitleSmall>;
        default:
            return null;
    }
};

export default React.memo(TitleControl, (prevProps, nextProps) => {
    return _.isEqual(prevProps.elementList[prevProps.element.elementId], nextProps.elementList[nextProps.element.elementId]);
});
