import styled from 'styled-components';
import SharedStyled from "../../SharedControlElements/index.styled"

const HtmlElementWrapper = styled(SharedStyled.GridWrapper)`
    .dx-item-content, .dx-data-row {   

        table {
            border-collapse: collapse;
            width: 100%;
            table-layout: auto;
        }
    }
`

const styledComponents = { HtmlElementWrapper };
export default styledComponents;