import styled from 'styled-components';
import { px, palette } from '@liasincontrol/ui-basics';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: center;
`;

const Left = styled.div`
    grid-column-start: 1;
`;

const Right = styled.div`
    grid-column-start: 3;
`;

const Mid = styled.div`
    grid-column-start: 2;
    display: grid;
    justify-items: center;
    grid-row-gap: 1rem;
`;

// Arrow button styles

const Button = styled.button<{ look: 'default' | 'inverted' }>`
    position: relative;
    background-color: ${(p) => p.look === 'inverted' ? palette.primary4 : palette.primary2};
    color: ${(p) => p.look === 'inverted' ? palette.primary2 : palette.white};
    border: 0 transparent;
    border-radius: ${px(4)};
    width: ${px(41)};
    height: ${px(41)};
    padding: 0;
    overflow: visible;
    text-align: center;
    vertical-align: middle;
    transition: background-color 0.3s ease;
    user-select: none;
  
    &:hover {
      background-color: ${(p) => p.look === 'inverted' ? palette.primary3 : palette.primary1};
      color: ${(p) => p.look === 'inverted' ? palette.primary1b : palette.white};
      cursor: pointer;
    }
    &:active {
      border: none;
      outline: 0;
    }
    &:focus {
      background-color: ${palette.white};
      color: ${palette.primary2};
      border: ${px(1)} solid ${palette.primary1b};
      outline: 0;
    }
  `;

const Wrapper = styled.div`
    outline: 0;
  `;


export { Container, Left, Right, Mid, Button, Wrapper }