import { createAsyncThunk } from '@reduxjs/toolkit';
import { Shared as SharedDataAccess } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import Payload from '../../../../Payload';
import { actionSourceToEndpoint } from '../../../../Source';

const action = createAsyncThunk<Payload<Domain.Shared.ElementDefinition[]>, Payload<{ moduleId: string, includeDetailCards?: boolean, includeDeleted?: boolean }>, {}>('/elementdefinitions/set', async (payload) => {
    return SharedDataAccess.ElementDefinitions.getInstance()
        .then(instance => instance.get(actionSourceToEndpoint(payload.source), payload.data.moduleId, true, payload.data.includeDetailCards ?? false, payload.data.includeDeleted ?? false))
        .then((response) => ({
            data: response.data,
            source: payload.source,
            options: {
                includeFieldDefinitions: true,
                includeDetailCards: payload.data.includeDetailCards ?? false,
                includeDeleted: payload.data.includeDeleted ?? false
            }
        }));
});

export default action;