import { createReducer } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { isMomentDeleted } from '@liasincontrol/core-service';
import { TenantActionCreator, MeasureMomentsActionCreator } from '../../../../actions/creators';
import { AjaxRequestStatus } from '../../../state';

/**
 * Represents the measure moments reducer.
 */
const reducer = createReducer<{ items: Domain.Shared.MeasureMoment[], status: AjaxRequestStatus }>({ items: [], status: AjaxRequestStatus.NotSet }, (builder) => {
    return (
        builder.addCase(MeasureMomentsActionCreator.set.fulfilled, (state, action) => {
            state = {
                items: [...action.payload].filter((moment) => !isMomentDeleted(moment.status)).sort((a, b) => a.order - b.order) ?? [],
                status: AjaxRequestStatus.Done,
            };
            return state;
        }),
        builder.addCase(MeasureMomentsActionCreator.set.pending, (state, action) => {
            // Loading in progress;
            state = {
                items: [],
                status: AjaxRequestStatus.Pending,
            };
            return state;
        }),
        builder.addCase(MeasureMomentsActionCreator.set.rejected, (state, action) => {
            state = {
                items: [],
                status: AjaxRequestStatus.Failed,
            };
            return state;
        }),
        builder.addCase(TenantActionCreator.initTenant, (state, action) => {
            state = {
                items: [],
                status: AjaxRequestStatus.NotSet,
            };
            return state;
        })
    );
});

export default reducer;
