import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '@liasincontrol/ui-basics';
import { Actions } from '@liasincontrol/userrights-service';
import { License } from '@liasincontrol/auth-service';
import SignInCallback from './components/Authentication/Callback/SignIn';
import SilentRenewCallback from './components/Authentication/Callback/SilentRenew';
import Homepage from './components/Homepage';
import { BasicLayout } from './components/Layouts';
import AdminModuleRoutes from './components/ModuleRoutes/Admin';
import PublisherModuleRoutes from './components/ModuleRoutes/Publisher';
import PerformanceModuleRoutes from './components/ModuleRoutes/Performance';
import FinanceModuleRoutes from './components/ModuleRoutes/Finance';
import StudioModuleRoutes from './components/ModuleRoutes/Studio';
import AnalyticsModuleRoutes from './components/ModuleRoutes/Analytics';
import AIModuleRoutes from './components/ModuleRoutes/AI'
import useUserBackScript from './plugins/userback';
import { useDeveExtremeLocalization } from './plugins/devextreme';
import { useAnnouncementMessage } from './plugins/announcement';
import './App.less';

export const App = () => {
    useUserBackScript();
    useDeveExtremeLocalization();
    useAnnouncementMessage();
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/auth/signin/interactive/callback" element={<SignInCallback />} />
                <Route path="/auth/signin/silent/callback" element={<SilentRenewCallback />} />

                {/* Publisher Module */}
                <Route path='/publisher/*' element={<PrivateRoute license={License.Publisher} component={PublisherModuleRoutes} />} />

                {/* Admin routes - part of the publisher Module */}
                <Route path='/admin/*' element={<PrivateRoute fallback={{ url: '/ai', license: License.AITextAssistant }} permissions={[Actions.CRUD_UsersAndGroups, Actions.CRUD_Roles, Actions.CRUD_DataStores, Actions.CRUD_Workflows, Actions.CRUD_MeasureMoments]} component={AdminModuleRoutes} />} />

                {/* Performance Module */}
                <Route path='/performance/*' element={<PrivateRoute license={License.Performance} component={PerformanceModuleRoutes} />} />

                {/* Finance Module */}
                <Route path='/finance/*' element={<PrivateRoute license={License.Finance} component={FinanceModuleRoutes} />} />

                {/* Studio Module */}
                <Route path='/studio/*' element={<PrivateRoute license={License.Studio} component={StudioModuleRoutes} />} />

                {/* Analytics Module */}
                <Route path='/analytics/*' element={<PrivateRoute license={License.Analytics} component={AnalyticsModuleRoutes} />} />

                {/* AI Module */}
                <Route path='/ai/*' element={<PrivateRoute license={License.AITextAssistant} component={AIModuleRoutes} />} />

                {/* TODO: Is the path='*' ok? (was path='/') */}
                {/* Main application page & default not found fallback handler */}
                <Route path='*' element={<BasicLayout components={<PrivateRoute component={Homepage} />} />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
