import styled from 'styled-components';
import { px, palette } from '@liasincontrol/ui-basics';

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    justify-content: center;
    align-items: center;
`;

const ListWrapper = styled.div`
    grid-column-start: 1;
`;

const Border = styled.div`
    border: ${px(2)} solid ${palette.grey4};
    border-radius: ${px(4)};
    height: 30vh;
    text-align: left;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: ${px(10)};
    }
    &::-webkit-scrollbar-track {
        width: ${px(10)};
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        width: ${px(10)};
        border-radius: ${px(10)};
        background-color: #ececf3;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #e3e3ec;
    }
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-corner {
        width: ${px(10)};
        height: ${px(1)};
        background-color: transparent;
    }
`;

const List = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const Li = styled.li`
    padding: ${px(14)} ${px(16)} ${px(8)};
    position: relative;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const LiEmpty = styled(Li)`
    color: ${palette.grey3b};
`;

export { Container, ListWrapper, Border, List, Li, LiEmpty }