import { fieldDefinitionDecorator } from '../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../Shared/FieldDefinition';

export class BaseControlElement {

    constructor(name?: string, helpText?: string) {
        this.name = name;
        this.helpText = helpText;
    }

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.AllowPatchContent)
    allowPatchContent = true;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Name)
    name: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.HelpText)
    helpText: string;

}
