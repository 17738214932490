import React from 'react';
import Accordion from 'devextreme-react/accordion';
import styled from 'styled-components';
import { ILsAccordionProps } from './index.props';

const CustomAccordion = styled(Accordion)`
    border: 1px solid #F4F4F8;
`;

/**
 * Represents a control that uses the devextreme accordion component.
 */
export const LsAccordion: React.FC<ILsAccordionProps> = (props) => {
    return (
        <CustomAccordion
            id={props.id}
            items={props.data}
            collapsible={props.collapsible}
            multiple={props.multiple}
            disabled={props.disabled}
            animationDuration={300}
            selectedItems={props.selected}
            onSelectionChanged={props.onSelectionChanged}
            itemTitleRender={props.customTitleTemplate}
            itemRender={props.customItemTemplate}
            noDataText='Geen gegevens beschikbaar'
        />
    );
};


