import styled from 'styled-components';
import { palette } from '@liasincontrol/ui-basics';
import { InputField } from '@liasincontrol/ui-devextreme';

const InputFieldCustom = styled(InputField)`
    border: ${(p) => p.success ? `2px solid ${palette.validationPos1} !important` : null};
`;

const NameSpan = styled.span`
    white-space: pre;
`

const styledComponents = { InputFieldCustom, NameSpan };
export default styledComponents;