import React from 'react';
import { ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';

type Props = {
    message: string,
    onClose: () => void,
};

/**
 * Represents an UI component which is a dialog to inform the user that textassistant failed.
 */
export const TextAssistantErrorDialog: React.FC<Props> = (props) => {

    const footerElement = (
        <ModalDialogFooter
            rightButtonText='Sluiten' onRightButtonClick={props.onClose}
        />
    );

    return (
        <ModalDialog id="textassistant-error-dialog"
            settings={{
                look: "message",
                title: "Tekstassistent",
                footer: footerElement
            }}>
            <Text value={props.message} />
        </ModalDialog>
    );
};
