import { px, palette, Heading2, cssElementLabel } from '@liasincontrol/ui-basics';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Content = styled.div`
    background-color: ${palette.white};
    border-radius: ${px(6)};
    margin-bottom: ${px(16)};
    padding: ${px(16)};
`;

const StatusWrapper = styled.div`
    margin-top: ${px(4)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const StatusContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const LinkList = styled(Link)`
    text-decoration: none;
`;

const Title = styled(Heading2)`
    margin-bottom: ${px(8)};
    color: ${palette.grey2};
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: clip; 
`;

const Label = styled.span`
    ${cssElementLabel};
    color: ${palette.grey3};
`;

const styledComponents = {
    Content, StatusWrapper, StatusContent, LinkList, Title, Label
};

export default styledComponents;