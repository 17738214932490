import React, { useCallback } from 'react';
import { withField } from '../field';
import { InputUnit, IUnitItem } from '../inputunit';

// tslint:disable:no-shadowed-variable

export const InputUnitField = withField<{
  units: IUnitItem[];
  unit: string;
  value: string;
}>(
  ({
    error,
    onChange,
    success,
    value: { units, unit, value },
    ...rest
  }) => (
    <InputUnit
      units={units}
      unit={unit}
      value={value}
      onChange={
        onChange &&
        useCallback((unit, value) => onChange({ units, unit, value }), [
          onChange,
          units,
        ])
      }
      {...rest}
    />
  ),
);
