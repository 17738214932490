import React, { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ValidationErrorData, JsonUtils } from '@liasincontrol/core-service';
import { Button, Label } from '@liasincontrol/ui-basics';
import { AttachmentElement, TextElement, TextViewer } from '@liasincontrol/ui-elements';
import * as Styled from './index.styled';

type FileInfo = {
    rowIndex: number,
    source: string,
    name: string,
    size: string,
}

type Props = {
    uploaderId: string,
    uploaderLabel: string,
    listId: string,
    listLabel: string,
    disabled?: boolean,
    maxFileSize: number,
    allowedFileTypes?: string[],
    validationErrors: ValidationErrorData[],
    values: FileInfo[],
    onAttachmentFieldChanged: (rowIndex: number, fileSource: string, fileName: string, fileSize: string, deleted: boolean) => void,
    onLoadAttachment: (id: string) => Promise<Blob>,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onRemoveAttachment: (id: string) => void,
}

/**
 * Represents an UI component that renders an editor that incorporates a file uploader and a list of existing removable attachments.
 */
const ReferenceAttachmentsEditor: React.FC<Props> = (props) => {
    const removeAttachment = (file: FileInfo): void => {
        props.onRemoveAttachment(file.source);
        props.onAttachmentFieldChanged(file.rowIndex, file.source, file.name, file.size.toString(), true);
    };

    return (
        <>
            <AttachmentElement
                id={props.uploaderId}
                label={props.uploaderLabel}
                editorSettings={{
                    disabled: props.disabled,
                    restrictions: { required: false, maxFileSize: props.maxFileSize, allowedFileTypes: props.allowedFileTypes },
                    validationErrors: props.validationErrors,
                    onChange: () => { /* Do nothing because patching is handled on upload attachment. */ },
                }}
                allowMultiple={true}
                value={undefined} // the existing attachments shouldn't be loaded into the attachment editor.
                onLoadAttachment={props.onLoadAttachment}
                onUploadAttachment={(file: File, abortSignal: AbortSignal) => {
                    const blobPromise = props.onUploadAttachment(file, abortSignal);

                    blobPromise.then((blobId) => {
                        const maxRowIndex = Math.max(...props.values.map(v => v.rowIndex), -1) + 1;
                        props.onAttachmentFieldChanged(maxRowIndex, blobId, file.name, file.size.toString(), false);
                    });

                    return blobPromise;
                }}
            />
            {props.values && props.values.length > 0 &&
                <>
                    <Label id={`${props.listId}-label`} htmlFor={props.listId} text={props.listLabel} />
                    <Styled.SideMenuList>
                        {props.values?.map((file) => (
                            <AttachmentItem
                                key={file.source}
                                file={file}
                                disabled={props.disabled}
                                onRemoveAttachment={() => removeAttachment(file)}
                                onChange={(val) => {
                                    props.onAttachmentFieldChanged(file.rowIndex, file.source, val, file.size.toString(), false);
                                }}
                            />
                        ))}
                    </Styled.SideMenuList>
                </>
            }
        </>
    );
};

type AttachmentItemProps = {
    disabled?: boolean,
    file: FileInfo,
    onRemoveAttachment: () => void,
    onChange?: (fileName: string) => void,
}

const AttachmentItem: React.FC<AttachmentItemProps> = (props) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const onExtraDetailsClicked = (): void => {
        if (!props.disabled) {
            setExpanded((prev) => !prev);
        }
    };

    const getRemoveActionElement = (fileId: string): JSX.Element =>
        <Button
            disabled={props.disabled}
            btnbase="iconbuttons"
            btntype="medium_background"
            aria-label="Verwijder bijlage"
            icon={<DeleteIcon />}
            onClick={() => props.onRemoveAttachment()} />;

    const getExtraDetailsActionElement = (): JSX.Element =>
        <Button
            disabled={props.disabled}
            btnbase="iconbuttons"
            btntype="medium_background"
            aria-label="Geavanceerde instellingen"
            icon={expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            onClick={onExtraDetailsClicked} />;

    const getExtraLineElement = (file: FileInfo): JSX.Element | null => {
        if (!expanded) {
            return null;
        }

        const fileNameParts = file.name.split('.');
        const fileName = fileNameParts.slice(0, -1).join('.');
        const fileExtension = fileNameParts.slice(-1);

        return (
            <Styled.ExtraLineWrap>
                <Styled.PanelSection>
                    <TextElement
                        id='attachment-name'
                        label='Bestandsnaam'
                        editorSettings={{
                            disabled: props.disabled,
                            validationErrors: [], //validationErrors.errors['name'],
                            restrictions: { required: false, minLength: 2, maxLength: 100 },
                            onChange: (value: string) => {
                                const newName = `${value}.${fileExtension}`;
                                props.onChange(newName);
                            }
                        }}
                        value={fileName}
                    />
                </Styled.PanelSection>
                <Styled.PanelSection>
                    <TextViewer id="attachment-size" label="Bestandsgrootte" value={JsonUtils.formatBytes(+file.size)} />
                </Styled.PanelSection>
            </Styled.ExtraLineWrap>
        );
    };

    return (
        <Styled.Li disabled={props.disabled} expanded={expanded} role="button">
            <Styled.MainLineWrap>
                <Styled.LabelWrap>
                    {props.file.name}
                </Styled.LabelWrap>
                <Styled.ActionsWrap>
                    {getRemoveActionElement(props.file.source)}
                    {getExtraDetailsActionElement()}
                </Styled.ActionsWrap>
            </Styled.MainLineWrap>
            {getExtraLineElement(props.file)}
        </Styled.Li>
    );
};

export { ReferenceAttachmentsEditor };