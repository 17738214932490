import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { oDataResponse, oDataResponseStructured } from '../../Shared/oData';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents a data accessor of budget journal kinds.
 */
export class BudgetJournalKindDataAccessor {
    /**
     * Gets the collection of the existing budget journal kinds.
     */
    public static getAll = async (query = ''): Promise<AxiosResponse<oDataResponse<Domain.Finance.BudgetJournalKind[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<oDataResponse<Domain.Finance.BudgetJournalKind[]>>(`/api/finance/budgetjournalkind${query}`, config);
    };

    /**
     * Gets a budget journal kind.
     */
    public static get = async (id = ''): Promise<AxiosResponse<Domain.Finance.BudgetJournalKindItem>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Finance.BudgetJournalKindItem>(`/api/finance/budgetjournalkind/${id}`, config);
    };

    /**
     * Creates a budget journal kind.
     * @param budgetjournalkind Defines the budget journal kind that has to be created.
     */
    public static create = async (budgetJournalKind: Domain.Finance.BudgetJournalKindItem): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const data = {
            'name': budgetJournalKind.name,
            'isActive': budgetJournalKind.isActive,
            'baseYear': budgetJournalKind.baseYear,
            'code': budgetJournalKind.code,
            'workflowDefinitionId': budgetJournalKind.workflowDefinitionId,
        };
        return HttpClient.post<void>('/api/finance/budgetjournalkind', data, config);
    };

    /**
     * Updates a budget journal kind.
     * @param budgetJournalKind Defines the budget journal kind that has to be updated.
     */
    public static update = async (budgetJournalKind: Domain.Finance.BudgetJournalKindItem): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const data = {
            'name': budgetJournalKind.name,
            'isActive': budgetJournalKind.isActive,
            'baseYear': budgetJournalKind.baseYear,
            'code': budgetJournalKind.code,
            'workflowDefinitionId': budgetJournalKind.workflowDefinitionId,
        };

        return HttpClient.put<string>(`/api/finance/budgetjournalkind/${budgetJournalKind.id}`, data, config);
    };


    /**
     * Gets a budget journal kind's groups.
     * 
     * @param id Defines the budget journal kind.
     */
    public static getGroups = async (id = '', query = ''): Promise<AxiosResponse<oDataResponseStructured<Domain.Finance.BudgetJournalKindItemGroups>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<oDataResponseStructured<Domain.Finance.BudgetJournalKindItemGroups>>(`/api/finance/budgetjournalkind/${id}/workflow/info${query}`, config);
    };

    /**
     * Updates workflow definition on a budget journal kind.
     * @param budgetJournalKindId Budget journal kind Id
     * @param workflowDefinitionId Workflow definition id
     */
    public static updateWorkflowDefinitionId = async (budgetJournalKindId: string, workflowDefinitionId: string, currentName: string): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const data = {
            'workflowDefinitionId': workflowDefinitionId,
            'budgetJournalKindName': currentName,
        };

        return HttpClient.put<string>(`/api/finance/budgetjournalkind/${budgetJournalKindId}/workflowdefinition`, data, config);

    };

    /**
    * Returns a list with the id's of the assigned users for the combination of budgetElementGroup and workflowState.
    * 
    * @param id Defines the budget journal kind.
    * @param groupId Defines the budget element group.
    * @param stateId Defines the workflow state.
    */

    public static getAssignedUsers = async (id = '', groupId = '', stateId = ''): Promise<AxiosResponse<string[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const query = `?budgetElementGroupId=${groupId}&workflowStateId=${stateId}`;

        return HttpClient.get<string[]>(`/api/finance/budgetjournalkind/${id}/workflow/assignments${query}`, config);
    };

    /**
     * Sets the assignments for the combination of budgetElementGroup and workflowState.
     * @param id Defines the budget journal kind that has to be created.
     * @param groupId Defines the budget element group.
     * @param stateId Defines the workflow state.
     * @param assigments Defines the list of user guids.
     */
    public static setAssignedUsers = async (id: string, data: Domain.Dto.Finance.UpdateBudgetJournalKindTask): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<void>(`/api/finance/budgetjournalkind/${id}/workflow/assignments`, data, config);
    };

}
