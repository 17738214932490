import { generateColors, getPalette } from 'devextreme/viz/palette';

//default blue
export const chartScheme: string[] = [
    '#4B55F6'
    , '#6F77F7'
    , '#9399F9'
    , '#B7BBFB'
    , '#DBDDFD'
    , '#5724AD'
    , '#7556B9'
    , '#987FCA'
    , '#BBA9DB'
    , '#DCD4EE'
    , '#77668D'
    , '#9284A3'
    , '#ADA3BA'
    , '#C8C2D1'
    , '#E4E0E8'
    , '#CFC1A7'
    , '#D8CDB8'
    , '#E2D9CA'
    , '#EBE6DB'
    , '#F5F2ED'
    , '#3833BE'
    , '#5F5BCB'
    , '#8784D8'
    , '#AFADE5'
    , '#D7D6F2'
    , '#0E6079'
    , '#3E7F93'
    , '#6E9FAE'
    , '#9EBFC9'
    , '#CEDFE4'
    , '#414141'
    , '#676767'
    , '#8D8D8D'
    , '#B3B3B3'
    , '#D9D9D9'
];

const bluePalette = {
    simpleSet: chartScheme,//.filter((item, index) => index % 5 === 0),
    indicatingSet: ['#4B55F6', '#9284A3', '#6E9FAE'],
    gradientSet: ['#4B55F6', '#D9D9D9'],
    accentColor: '#4B55F6'
};

const dxPalette = {
    'Violet': 'dark violet',
    'Material': 'material',
    'Aquarel': 'carmine',
    'Purper': 'violet',
    'Oceaan': 'ocean',
    'Pastel': 'pastel',
};

const liasPalette = {
    'Nachtblauw': bluePalette,
    'Huisstijl': '',
};
export const DEFAULT_PALETTE = 'Nachtblauw';

export const availablePalette = { ...liasPalette, ...dxPalette };

export const getLiasPalette = (liasPaletteName: string, primaryColor?: string) => {
    //primary color
    if (liasPaletteName === 'Huisstijl' && primaryColor) {
        const primaryColors = generateColors([primaryColor], 8, { paletteExtensionMode: 'extrapolate' });
        return {
            simpleSet: primaryColors,
            indicatingSet: primaryColors.slice(0, 3),
            gradientSet: primaryColors.slice(0, 2),
            accentColor: primaryColor
        };
    }
    else if (liasPalette[liasPaletteName])
        return liasPalette[liasPaletteName];
    else
        return getPalette(dxPalette[liasPaletteName]);
};


