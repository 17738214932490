import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import * as Domain from '@liasincontrol/domain';
import { ModalDialog, ModalDialogFooter, Text, useIdleTimer, IdleTimer } from '../../index';

type Props = {
    leaseInfo: Domain.Shared.AcquireLease,

    //Extra action needed to run for specific timer events
    onActive?: () => void,
    onWarn?: () => void,
    onIdle?: () => void,
    onTick?: () => void,
    handleStillHere?: () => void,
    handleGone?: () => void,
    handleDeleteLease?: () => void,

    children?: React.ReactNode
};

/**
 * Represents a component that decorates the childrens with a timer.
 */
export const LeaseWrapper: React.FC<Props> = (props) => {    
    const [idleTimerData, setIdleTimerData] = useState({
        isActive: true,
        isAfterHalf: false,
        isAfterWarn: false,
        elapsed: 0,
        remaining: 0,
        isWarned: false,
        isPrompted: false,
    });

    const onActive = () => {
        setIdleTimerData((prev) => ({ ...prev, isWarned: false, isPrompted: false }));
        props.onActive?.();
    };

    const onIdle = () => {
        setIdleTimerData((prev) => ({ ...prev, isWarned: false, isPrompted: true }));
        props.onIdle?.();
    };

    const onWarn = () => {
        setIdleTimerData((prev) => ({ ...prev, isWarned: true, isPrompted: true }));
        props.onWarn?.();
    };

    const onTick = (timer: IdleTimer) => {
        if (idleTimerData.isActive && timer.isAfterWarn) {
            setIdleTimerData((prev) => ({
                ...prev,
                elapsed: timer.getElapsedTime(),
                remaining: timer.getRemainingTime(),
                isActive: timer.isActive,
                isAfterHalf: timer.isAfterHalf,
                isAfterWarn: timer.isAfterWarn
            }));
        }
        props.onTick?.();
    };

    const idleTimer = useIdleTimer(onActive, onWarn, onIdle, onTick);

    const start = (expiresOn: string) => {
        idleTimer?.start(expiresOn);
    };

    const stop = () => {
        idleTimer?.stop();
    };

    const reset = (expiresOn: string) => {
        idleTimer?.reset(expiresOn);
    };

    const handleStillHere = () => {
        setIdleTimerData((prev) => ({ ...prev, isWarned: false, isPrompted: false }));
        props.handleStillHere?.();
    };

    const handleGone = () => {
        setIdleTimerData((prev) => ({ ...prev, isWarned: false, isPrompted: false }));
        stop();
        props.handleGone?.();
    };

    const handleDeleteLeaseRef = useRef(props.handleDeleteLease);
    useEffect(() => {
        handleDeleteLeaseRef.current = props.handleDeleteLease;
    }, [props.handleDeleteLease]);

    const leaseDeleteStarted = useRef<boolean>(false);

    const tryDeleteLease = () => {
        if (!leaseDeleteStarted.current) handleDeleteLeaseRef.current?.();
        leaseDeleteStarted.current = true;
    }

    useEffect(() => {
        window.history.pushState('detailInformation', document.title, window.location.href);

        const onUnload = (e) => {
            if (handleDeleteLeaseRef.current) {
                tryDeleteLease();
                window.history.back();
                e.preventDefault();
                return e.returnValue = '';
            }
        };

        const onBackButton = (e) => {
            if (handleDeleteLeaseRef.current) {
                tryDeleteLease();
                window.history.back();
            }
        };

        window.addEventListener("beforeunload", onUnload, { once: true });
        window.addEventListener("popstate", onBackButton, { once: true });

        return () => {
            window.removeEventListener("beforeunload", onUnload);
            window.removeEventListener("popstate", onBackButton);
        }
    }, []);

    useEffect(() => {
        if (!idleTimer) return;

        if (!props.leaseInfo) {
            stop();
            return;
        }

        if (props.leaseInfo) {
            const expiresOn = moment(props.leaseInfo.expiresOn).subtract(1, "minutes").toISOString();

            if (props.leaseInfo.result === "Created") {
                start(expiresOn);
            } else if (props.leaseInfo.result === "Renewed") {
                reset(expiresOn);
            } else if (props.leaseInfo.result === "Denied") {
                stop();
            }
            return;
        }

        return () => {
            //On unmount, stop idletimer
            stop();
        }
    }, [props.leaseInfo, idleTimer]);

    return (<>
        {props.children}
        {idleTimerData.isActive && idleTimerData.isAfterWarn && idleTimerData.isWarned &&
            <ModalDialog
                id='prompt-user-still-here'
                settings={{
                    look: 'message',
                    title: 'Bent u nog actief?',
                    footer: <ModalDialogFooter
                        rightButtonText='Doorgaan met bewerken'
                        onRightButtonClick={handleStillHere} />
                }}
            >
                <Text value={`Omdat u inactief bent worden de wijzigingen over ${Math.ceil(idleTimerData.remaining / 1000)} seconden automatisch opgeslagen en wordt de bewerking afgesloten.`} />
            </ModalDialog>
        }
        {!idleTimerData.isActive && idleTimerData.isAfterWarn && idleTimerData.isPrompted &&
            <ModalDialog
                id='prompt-user-gone'
                settings={{
                    look: 'message',
                    title: 'Wijzigingen zijn opgeslagen',
                    footer: <ModalDialogFooter
                        rightButtonText='Sluiten'
                        onRightButtonClick={handleGone} />
                }}
            >
                <Text value={`Omdat u inactief was zijn de wijzigingen automatisch opgeslagen en is de bewerking afgesloten.`} />
            </ModalDialog>
        }
    </>);
};
