import React, { useEffect, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { AppSettingsService } from '@liasincontrol/config-service';
import { WrapperContent, PageTitle, Heading1, Section, ErrorOverlay } from '@liasincontrol/ui-basics';
import { GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { Shared as DataAccess } from '@liasincontrol/data-service';

/**
 * Represents a UI component that renders the users list page.
 */
const Index: React.FC = () => {
    const [users, setUsers] = useState<Domain.Shared.User[]>([]);
    const [lastRefresh, setLastRefresh] = useState<number>();
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);

    useEffect(() => {
        DataAccess.Users.get()
            .then((response) => {
                setError(undefined);
                const allEnabledUsers = response.data.filter(user => user.enabled);
                setUsers(allEnabledUsers);
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
            });
    }, [lastRefresh]);

    return (
        <WrapperContent>
            <PageTitle>
                <Heading1>Gebruikersbeheer</Heading1>
            </PageTitle>

            <Section look='white'>
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    {users && columnConfiguration &&
                            <LsGrid
                                dataSource={users}
                                columns={columnConfiguration}
                                enableColumnChooser={true}
                                searching={false}
                                paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                                showRowLines={true}
                            />
                    }
                </ErrorOverlay>
            </Section>
        </WrapperContent>
    );
};

const columnConfiguration: GridColumn<Domain.Shared.User>[] = [
    {
        name: 'name',
        title: 'Naam'
    },
    {
        name: 'email',
        title: 'E-mailadres',
    },
];

export { Index as index };
