import * as Domain from '@liasincontrol/domain';
import { ValidationUtils, ValueType } from '@liasincontrol/core-service';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import { IDataItemProps } from '@liasincontrol/ui-basics';

export const getType = (fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>, typeFieldValue: ValueType): number | undefined => {
    if (!fieldDefinitions || ValidationUtils.isEmpty(typeFieldValue)) {
        return undefined;
    }

    return fieldDefinitions[SystemFieldDefinitions.Pub.Type].optionItems.find(item => item.id === typeFieldValue).value;
};

export const isDataStoreType = (type: string, searchedType: Domain.Publisher.DataStoreTypes): boolean => {
    if (!type) return false;
    return Domain.Publisher.DataStoreTypesDictionary[type] === searchedType;
};

export const performanceElementDefinitionSystemIds = [
    Domain.SystemElementDefinitions.Performance.Policy,
    Domain.SystemElementDefinitions.Performance.Goal,
    Domain.SystemElementDefinitions.Performance.Achievement,
    Domain.SystemElementDefinitions.Performance.Activity
] as string[];

export const requiredFieldsChecked = [
    SystemFieldDefinitions.Performance.Name,
    SystemFieldDefinitions.Studio.Name,
] as string[];

export type DataPlatformItem = IDataItemProps<string> & { type: string };