import React, { useState, useEffect } from 'react';
import { Dictionary } from 'lodash';
import { FieldsHelper, OperationsHelper } from '@liasincontrol/core-service';
import AuthService, { UserIdentity } from '@liasincontrol/auth-service';
import * as Domain from '@liasincontrol/domain';
import Styled from './index.styled';
import { Placeholder, getPlaceholderContent, placeholderCategories } from '../../_shared/Placeholder';

type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Dictionary<Domain.Publisher.Element>,
    readonly: boolean,
    children: React.ReactNode,
    publicationElement: Domain.Publisher.PublicationElement,
    onTemplateChanged?: (operations: Domain.Publisher.Operation[]) => void,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
}

/**
 * Represents a UI component that renders a stack container.
 */
const StackContainer: React.FC<Props> = (props) => {
    const [currentElement, setCurrentElement] = useState<{
        data: Domain.Publisher.StackContainerElement,
        definition: Domain.Shared.ElementDefinition
    }>();
    const { element, elementList, readonly, publicationElement, onTemplateChanged, getElementDefinition } = props;
    const [userIdentity, setUserIdentity] = useState<UserIdentity>();

    useEffect(() => {
        AuthService.getInstance()
            .then(async (auth) => await auth.getUser())
            .then((identity) => {
                if (identity) {
                    setUserIdentity(identity);
                }
            });
    }, []);

    useEffect(() => {
        if (elementList[element.elementId]) {
            const definition = getElementDefinition(elementList[element.elementId].elementDefinitionSystemId, elementList[element.elementId].elementDefinitionId);
            const data = new Domain.Publisher.StackContainerElement();

            FieldsHelper.mapObject<Domain.Publisher.StackContainerElement>(data, definition.fields, elementList[element.elementId].fields);
            setCurrentElement({
                data,
                definition
            });
        }
    }, [element.elementId, elementList, getElementDefinition]);

    if (!currentElement?.data) {
        return null;
    }

    const directionValue = FieldsHelper.mapFieldOption<Domain.Publisher.StackContainerElement>(currentElement.data, 'direction', currentElement.definition);

    const getPlaceholderElement = (readonly: boolean
        , element: Domain.Publisher.ElementNode
        , previousSiblingName: string
        , publicationElement: Domain.Publisher.PublicationElement
        , getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition
        , onTemplateChanged?: (operations: Domain.Publisher.Operation[]) => void) => {
        if (readonly || !onTemplateChanged) return null;

        return (
            <Placeholder
                wrapperId={element.elementId}
                direction={Domain.Publisher.Direction.Vertical}
                items={getPlaceholderContent(userIdentity)}
                groups={placeholderCategories}
                title={`Open element verticaal toevoegen aan ${previousSiblingName}`}
                publicationElement={publicationElement}
                onCreateOperation={(elementSystemId: Domain.SystemElementDefinitions.Pub) => {
                    const operations = OperationsHelper.createElement(elementSystemId, element.elementId, Domain.Publisher.OperationKind.AddTo, getElementDefinition);
                    onTemplateChanged(operations);
                }} />
        );
    };


    return (
        <Styled.StackContainerWrapper direction={directionValue.value}>
            <Styled.StackContainerContent direction={directionValue.value}>
                {props.children}
            </Styled.StackContainerContent>
            {getPlaceholderElement(readonly, element, '-', publicationElement, getElementDefinition, onTemplateChanged)}
        </Styled.StackContainerWrapper>
    );
};

export default StackContainer;
