import React, { useState } from 'react';
import { EdgeTab, EdgeTabs, EdgeToolbar } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import * as Styled from './index.styled';
import { TableOfContents } from './TableOfContents';

enum Tabs { Sitemap = 1, Tasks = 2, }

type Props = {
    sitemap: Domain.Publisher.Sitemap,
    tasks: Domain.Publisher.WorkflowTask[],
    isVisible: boolean,
    onToggleVisibility: () => void,
};
/**
 * Represents a UI component that renders the publicationsitemap.
 */
export const Navigation: React.FC<Props> = (props) => {
    const [selectedTab, setSelectedTab] = useState<Tabs>(Tabs.Sitemap);
    const [tocItemsExpanded, setTocItemsExpanded] = useState<boolean>(false);
    const tabCount = 1;

    return (
        <EdgeToolbar edge="left" open={props.isVisible} inModal onToggle={props.onToggleVisibility}>
            <Styled.LeftSideBarPanel hasPadding={false} className="slide-panel-section">
                <EdgeTabs tabCount={tabCount}>
                    <EdgeTab title="Sitemap" icon={<AccountTreeIcon />} active={selectedTab === Tabs.Sitemap} onClick={() => (setSelectedTab(Tabs.Sitemap))} tabCount={tabCount}>
                        <Styled.EdgeToolbarContent>
                            <Styled.ToolbarTitle>Sitemap</Styled.ToolbarTitle>
                            <Styled.EdgeToolbarButton btnbase="ghostbuttons" btntype="medium_noicon"
                                onClick={() => (setTocItemsExpanded(prev => !prev))} >
                                {tocItemsExpanded ? 'Alles inklappen' : 'Alles uitklappen'}
                            </Styled.EdgeToolbarButton>
                            <TableOfContents
                                sitemap={props.sitemap}
                                tasks={props.tasks}
                                allItemsExpanded={tocItemsExpanded} />
                        </Styled.EdgeToolbarContent>
                    </EdgeTab>
                    {/* <EdgeTab title="Tasks" icon={<LowPriorityIcon />} active={selectedTab === Tabs.Tasks} onClick={() => (setSelectedTab(Tabs.Tasks))} >
                        <Styled.EdgeToolbarContent>

                        </Styled.EdgeToolbarContent>
                    </EdgeTab> */}

                </EdgeTabs>
            </Styled.LeftSideBarPanel>
        </EdgeToolbar>
    );
};
