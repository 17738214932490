
export enum LayerKind {
    Base = 'Base',
    SiteDesign = 'SiteDesign',
    PageDesign = 'PageDesign',
    PageTemplate = 'PageTemplate',
    PageCustomization = 'PageCustomization',
    PageContent = 'PageContent'
}

export const LayerKinds = [LayerKind.Base, LayerKind.SiteDesign, LayerKind.PageDesign, LayerKind.PageTemplate, LayerKind.PageCustomization, LayerKind.PageContent];
