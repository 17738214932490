import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Dictionary } from 'lodash';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { FieldsHelper } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { palette } from '@liasincontrol/ui-basics';
import Styled from './index.styled';
import SharedStyled from '../../SharedControlElements/index.styled';

enum MenuItemSelectionItem {
    ShowChildren = 1,
    ShowSiblings = 2,
}

type Props = {
    publicationElement: Domain.Publisher.PublicationElement,
    selectedElementId: string,
    element: Domain.Publisher.ElementNode,
    elementList: Dictionary<Domain.Publisher.Element>,
    sitemap: Domain.Publisher.Sitemap,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition
}

/**
 * Represents UI component that renders a menu control.
 */
const MenuControl: React.FC<Props> = (props) => {
    const { pathname } = useLocation();
    const [controlSettings, setControlSettings] = useState<Domain.Publisher.MenuControl>();
    const { pageid } = useParams<{ id: string, pageid?: string }>();
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const { selectedElementId, element, elementList, getElementDefinition } = props;

    useEffect(() => {
        if (element.elementId) {
            setIsSelected(element.elementId === selectedElementId);
        }
    }, [element.elementId, selectedElementId]);

    useEffect(() => {
        if (elementList[element.elementId]) {
            const definition = getElementDefinition(elementList[element.elementId].elementDefinitionSystemId, elementList[element.elementId].elementDefinitionId);
            const settings = new Domain.Publisher.MenuControl();
            FieldsHelper.mapObject<Domain.Publisher.MenuControl>(settings, definition.fields, elementList[element.elementId].fields);
            setControlSettings(settings);
        }
    }, [element.elementId, elementList, getElementDefinition]);

    const getActiveColor = props.publicationElement && props.element.elementId === props.selectedElementId ? palette.grey3 : props.publicationElement?.primaryColor;

    const getMenuItemElement = (text: string, index: number, active = false, to?: string): JSX.Element => {
        if (!to) {
            return (
                <Styled.SncNonLinkListItem key={index}>
                    <Styled.SncListItem active={active} color={getActiveColor}>
                        <span>{text}</span>
                        <ArrowForwardIcon />
                    </Styled.SncListItem>
                </Styled.SncNonLinkListItem>
            );
        }

        return (
            <Styled.SncListItem active={active} color={getActiveColor}>
                <Styled.CustomLink key={index} to={to} textColor={props.publicationElement.bodyFontColor}
                    textFontSize={props.publicationElement.bodyFontSize}>
                    <span>{text}</span>
                    <ArrowForwardIcon />
                </Styled.CustomLink>
            </Styled.SncListItem>
        );
    };

    const getMenuListItemsElements = (): JSX.Element | JSX.Element[] => {
        if (!props.sitemap) {
            return [
                getMenuItemElement('# LINK', 1),
                getMenuItemElement('# LINK', 2),
                getMenuItemElement('# LINK', 3)
            ];
        }

        const definition = props.getElementDefinition(props.elementList[props.element.elementId].elementDefinitionSystemId, props.elementList[props.element.elementId].elementDefinitionId);
        const menuType = controlSettings && FieldsHelper.mapFieldOption<Domain.Publisher.MenuControl>(controlSettings, 'menuType', definition);

        const flatArray = [props.sitemap.node, ...props.sitemap.node.children].flatten((item) => item.children);
        const pageNode = menuType && menuType.value === MenuItemSelectionItem.ShowSiblings
            ? flatArray.find((child) => child.children?.some(el => el.elementId === pageid))
            : flatArray.find((child) => child.elementId === pageid);


        return pageNode?.children && pageNode?.children?.length > 0
            ? pageNode.children.map((sitemapNode, index) => {
                const pageUrl = pathname.replace(pageid, sitemapNode.elementId);
                return getMenuItemElement(sitemapNode.elementName, index, sitemapNode.elementId === pageid, pageUrl);
            })
            : getMenuItemElement('Geen pagina\'s', 0);
    };

    return (
        <>
            {controlSettings && controlSettings.title && <SharedStyled.Title h3FontSize={props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}>{controlSettings.title}</SharedStyled.Title>}
            <Styled.Wrapper
                primaryColor={props.publicationElement.primaryColor}
                primaryTextColor={props.publicationElement.primaryContrastColor}
                textColor={props.publicationElement.bodyFontColor}
                textFontSize={props.publicationElement.bodyFontSize}
                editMode={isSelected}
            >
                {getMenuListItemsElements()}
            </Styled.Wrapper>
        </>
    );
}

export default MenuControl;