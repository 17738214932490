import styled from 'styled-components';
import { Label, palette, px } from '@liasincontrol/ui-basics';
import { StudioFormMode } from '.';

const Grid = styled.div`
    display: grid;
    grid-template-columns: ${px(20)} repeat(12, [col-start] 1fr) ${px(20)};
    grid-template-rows: ${px(20)} repeat(7, [row-start] auto);
    grid-auto-flow: row;
    grid-gap: ${px(8)} ${px(20)};
`;

const Content = styled.div`
    grid-column: 2 / span 12;
    grid-row: 7 / -3;
    display: grid;
    grid-template-columns: repeat(12, [col-content-start] 1fr);
    grid-template-rows: ${px(20)} repeat(4, [row-content-start] auto) ${px(20)};
    grid-gap: ${px(8)} ${px(20)};
    position: relative;
`;

const HeaderForm = styled.div`
    grid-column: col-start / span 12;
    grid-row: 2 / span 1;
    display: flex;
    align-items: center;
    min-height: ${px(32)};
`;

const StyledLabel = styled(Label)` 
    min-height: 0;
    margin-left: ${px(3)};
`;

const HeaderNumber = styled.div`
    grid-column: col-start / span 2;
    grid-row: 3 / span 1;
`;

const HeaderName = styled.div`
    grid-column: col-start 3 / span 10;
    grid-row: 3 / span 1;
`;

const ContentHeaderBar = styled.div`
    grid-column: 1 / span 14;
    grid-row: 4 / span 2;
    background-color: ${palette.grey4};
    border-bottom: ${px(2)} solid ${palette.grey3b};
    z-index: -1;
    padding: ${px(70)} 0 0 0;
`;

const ContentHeader = styled.div`
    grid-column: 2 / span 12;
    grid-row: 5 / span 1;
    min-height: ${px(32)};
    display: flex;
    align-items: flex-end;
`;

const ContentColumn = styled.div<{ colStart: number, colSpan: number, mode: StudioFormMode }>`
    grid-column: ${({ colStart, colSpan }) => (`col-content-start ${colStart} / span ${colSpan ? colSpan : colStart + 1}`)};
    grid-row: row-content-start 2 / -1;
    background-color: ${({ mode }) => mode === StudioFormMode.Design ? palette.grey4 : 'transparent'};
    border-radius: ${px(5)};
`;

const AddButtonContainer = styled.div`
    width: 100%;
    align-items: center;
    justify-items: center;
    text-align: center;
    padding: ${px(16)};
`;

const IconContainer = styled.div<{ color?: string }>`
    svg {
        fill: ${({ color }) => color ? color : palette.grey2};
    }
`;


const Text = styled.div<{ headerColor: string, headerBackgroundColor: string, textColor: string }>`
    overflow-x: auto;
    
    && p:not([id*="feedback"]), ol li, ul li {
        color: ${(p) => (p.textColor && p.textColor)};
    }

    table {
        width: 100%;
        border-collapse: collapse;        
        table-layout: auto;
        background-color: ${palette.white};        

        thead > tr {
            background-color: ${(p) => (p.headerColor && p.headerColor)};            
            font-weight: 700;
            border-bottom: 2px solid #cccccc;

            p {
                color: ${(p) => (p.headerBackgroundColor && p.headerBackgroundColor)};
            }
        }

        td, th {
            border: 1px solid #cccccc;            
            padding: 5px;

            p {
                font-size: 14px !important;
                margin-bottom: 0 !important;
                padding: 3px;
            }
        }
    }
`;

const styledComponents = {
    Grid, HeaderForm, StyledLabel, HeaderNumber, HeaderName, ContentHeaderBar, ContentHeader,
    Content, ContentColumn, IconContainer, AddButtonContainer,
    Text
};

export default styledComponents;