import config from 'devextreme/core/config';
import { locale, loadMessages } from 'devextreme/localization';
import { Dictionary as DataGridMessages } from './translations/data-grid';
import { Dictionary as FilterBuilderMessages } from './translations/filter-builder';
import { Dictionary as HtmlEditorMessages } from './translations/html-editor';
import { Dictionary as PivotGridMessages } from './translations/pivot-grid';
import { Dictionary as CalendarMessages } from './translations/calendar';
import { Dictionary as ValidatorMessages } from './translations/validator';
import { Dictionary as PopupMessages } from './translations/popup';

export const initDevExtremeLocalization = (licenseKey: string) => {
    locale('nl');
    config({
        licenseKey,
        defaultCurrency: 'EUR',
        editorStylingMode: 'outlined'
    });

    loadMessages(DataGridMessages);
    loadMessages(FilterBuilderMessages);
    loadMessages(HtmlEditorMessages);
    loadMessages(PivotGridMessages);
    loadMessages(CalendarMessages);
    loadMessages(ValidatorMessages);
    loadMessages(PopupMessages);
}
