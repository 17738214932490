import React from 'react';
import * as Domain from '@liasincontrol/domain';
import { LayoutField, LayoutForm } from '@liasincontrol/ui-basics';
import { AttachmentElement } from '@liasincontrol/ui-elements';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import _, { Dictionary } from 'lodash';
import { AnyFormData, ValidationUtils, ValidatorsDictionary } from '@liasincontrol/core-service';

type Props = {
    fieldDefinitions: Dictionary<Domain.Shared.FieldDefinition>,
    isEditing: boolean,
    form: AnyFormData,
    validators: ValidatorsDictionary,
    onLoadAttachment: (id: string) => Promise<Blob>,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onChange: (value: string, fieldDefinitionId: string, resetExternalErrorFieldSystemIds?: string[]) => void,
    onError?: (error: string, fieldSystemId: string, fieldValue?: string) => void,
}

/**
 * Represents a UI component that renders the Settings section form of the Publication information page.
 */
export const SettingsSection: React.FC<Props> = (props) => {
    if (_.isEmpty(props.form) || _.isEmpty(props.form.values)) {
        return null;
    }
    const coverImageDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.CoverImage];

    const onAttachmentChanged = (value: string, fieldDefinitionId: string) => {
        props.onChange(value, fieldDefinitionId, [fieldDefinitionId]);
    };

    return (
        <LayoutForm >
            <LayoutField key={coverImageDefinition.id} left={1} top={1} width={6} height={2} >
                <AttachmentElement
                    id='pub-coverImage'
                    label={coverImageDefinition.label ? coverImageDefinition.label : coverImageDefinition.name}
                    editorSettings={ValidationUtils.getEditorSettings(true, !props.isEditing, props.validators, props.form, onAttachmentChanged, SystemFieldDefinitions.Pub.CoverImage)}
                    maxPreviewHeight={256}
                    value={props.form.values[SystemFieldDefinitions.Pub.CoverImage] as string}
                    onLoadAttachment={props.onLoadAttachment}
                    onUploadAttachment={props.onUploadAttachment}
                    onAddFileError={(error) => {
                        props.onError(error, SystemFieldDefinitions.Pub.CoverImage);
                    }}
                />
            </LayoutField>
        </LayoutForm>
    );
};