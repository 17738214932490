import React from 'react';
import { ImageFocusPointEditor } from './index.editor';
import { ImageFocusPointProps } from './index.props';

/**
 * Represents a UI component that renders an image focus point element.
 */
export const ImageFocusPointElement: React.FC<ImageFocusPointProps> = (props) => {
    return props.editorSettings && !props.editorSettings.disabled ? <ImageFocusPointEditor {...props} /> : null;
};

export { ImageFocusPointEditor };
