import { useEffect } from 'react';
import { AnnouncementActionCreator, useAppDispatch } from '@liasincontrol/redux-service';

export const useAnnouncementMessage = () => {
    const EVERY_HOUR = 60 * 60 * 1000;
    const dispatch = useAppDispatch();

    useEffect(() => {
        const fetchAnnouncement = setInterval(
            () => dispatch(AnnouncementActionCreator.set() as any)
            , EVERY_HOUR);

        return () => clearInterval(fetchAnnouncement);
    }, []);
};
