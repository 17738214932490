import React from 'react';
import { RouteProps, useLocation, matchPath } from 'react-router-dom';
import { UserIdentity } from '@liasincontrol/auth-service';
import { Look, IconValue, NavGroup, SideNavigation } from '@liasincontrol/ui-basics';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { useAnnouncement } from '@liasincontrol/redux-service';

/**
 * Represents a UI component that renders the Studio menu.
 */
const Menu: React.FC<RouteProps & { userIdentity: UserIdentity }> = (props) => {
    const { pathname } = useLocation();
    const announcement = useAnnouncement();

    const menuItems: NavGroup[] = [
        {
            key: 'menu-studio',
            active: !!matchPath({ path: '/studio', end: true }, pathname),
            title: 'Studio',
            icon: IconValue.Studio,
            to: '/studio',
            items: [],
        },
        {
            key: 'menu-studio-hierarchies',
            active: !!matchPath('/studio/hierarchies', pathname),
            title: 'Hiërarchieën',
            to: '/studio/hierarchies',
            icon: IconValue.Hierarchy_filled,
            items: [],
        },
        {
            key: 'menu-studio-admin',
            active: !!matchPath('/studio/admin/*', pathname),
            title: 'Beheer',
            to: '/studio/admin/elementdefinitions',
            icon: IconValue.Settings,
            hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_ManageStudioElementDefinition, ActionType.Create)),
            items: [
                {
                    key: 'menu-studio-admin-element-definitions',
                    active: !!matchPath('/studio/admin/elementdefinitions', pathname),
                    title: 'Elementdefinities',
                    alt: 'Open elementdefinities',
                    to: '/studio/admin/elementdefinitions',
                    icon: IconValue.Book,
                },
                {
                    key: 'menu-studio-admin-hierarchydefinitions',
                    active: !!matchPath('/studio/admin/hierarchydefinitions', pathname),
                    title: 'Hiërarchiedefinities',
                    alt: 'Open hiërarchiedefinities',
                    to: '/studio/admin/hierarchydefinitions',
                    icon: IconValue.Hierarchy_empty,
                }
            ],
        }
    ];

    return (
        <SideNavigation look={Look.studio} alt='Zijnavigatiemenu' menuItems={menuItems} announcement={announcement.items?.message} />
    );
};

export { Menu };
