import { Element } from '../Element';

export class Publication {

    readonly publication: Element;

    readonly rootPageId: string;
}

export class PublicationV2 {
    publicationId: string;
    title: string;
    name: string;
    isClosed: boolean;
}