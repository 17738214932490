import { useSelector, useDispatch } from "react-redux";
import * as Domain from '@liasincontrol/domain';
import { State, AjaxRequestStatus, BudgetJournalGroupActionCreator } from '@liasincontrol/redux-service';

type BudgetJournalGroupState = {
    items: Domain.Finance.BudgetJournalGroup[],
    status: AjaxRequestStatus,
};

export const useBudgetJournalGroups = () => {
    const settings = useSelector<State, BudgetJournalGroupState>((state) => state.finance.budgetJournalGroups);
    const dispatch = useDispatch();
    if (settings.status === AjaxRequestStatus.NotSet) {
        dispatch(BudgetJournalGroupActionCreator.set());
    }

    return settings;
}
