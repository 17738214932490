import React from 'react';
import Styled from './index.styled';

type Props = {
    children?: React.ReactNode;
};
/**
 * Represents a UI component that renders the html container.
 */
const HtmlContainer: React.FC<Props> = (props) => {
    return (
        <Styled.Html>
            {props.children}
        </Styled.Html>
    );
};

export default HtmlContainer;
