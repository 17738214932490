import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';
import { oDataResponse } from '../../Shared';

/**
 * Represents a data accessor of budget element groups.
 */
export class BudgetElementGroupDataAccessor {
    /**
     * Gets the collection of the existing groups.
     */
    public static getAll = async (query = ''): Promise<AxiosResponse<oDataResponse<Domain.Finance.BudgetElementGroup[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<oDataResponse<Domain.Finance.BudgetElementGroup[]>>(`/api/finance/budgetelementgroup${query}`, config);
    };

    /**
     * Gets a budget element group based on id.
     */
    public static get = async (budgetElementGroupId: string): Promise<AxiosResponse<Domain.Finance.BudgetElementGroup>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Finance.BudgetElementGroup>(`/api/finance/budgetelementgroup/${budgetElementGroupId}`, config);
    };

    /**
     * Creates a budget element group.
     * @param budgetElementGroup Defines the budget element group that has to be created.
     */
    public static create = async (budgetElementGroup: Domain.Finance.BudgetElementGroup): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<void>('/api/finance/budgetelementgroup', budgetElementGroup, config);
    };

    /**
     * Updates a budget element group.
     * @param budgetElementGroup Defines the budget element group that has to be updated.
     */
    public static update = async (budgetElementGroup: Domain.Finance.BudgetElementGroup): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const data = {
            name: budgetElementGroup.name,
            code: budgetElementGroup.code,
            tags: budgetElementGroup.selectedTags,
        };

        return HttpClient.put<string>(`/api/finance/budgetelementgroup/${budgetElementGroup.id}`, data, config);
    };

    /**
     * Gets the collection of the existing tags based on year.
     */
    public static getAvailableTags = async (baseYear: number): Promise<AxiosResponse<Domain.Finance.BudgetElementGroupTag[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Finance.BudgetElementGroupTag[]>(`/api/finance/budgetelementgroup/available-tag/${baseYear}`, config);
    };

    /**
     * Copy a budget element group.
     * @param budgetElementGroup Defines the budget element group that has to be copied.
     */
    public static clone = async (budgetElementGroup: Domain.Finance.BudgetElementGroup): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const data = {
            name: budgetElementGroup.name,
            code: budgetElementGroup.code,
            baseYear: budgetElementGroup.baseYear,
        };
        return HttpClient.post<string>(`/api/finance/budgetelementgroup/${budgetElementGroup.id}/copy`, data, config);
    };

    /**
     * Deletes a budget element group
     * @param budgetElementGroupId The id of the budget element group to be deleted
     */
    public static delete = async (budgetElementGroupId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.delete<void>(`/api/finance/budgetelementgroup/${budgetElementGroupId}`, config);
    };

}
