import { createReducer } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { UsersActionCreator, TenantActionCreator } from '../../../../actions/creators';
import { AjaxRequestStatus } from '../../../state';

/**
 * Represents the users reducer.
 */
const reducer = createReducer<{
    items: Domain.Shared.User[],
    status: AjaxRequestStatus
}>({ items: [], status: AjaxRequestStatus.NotSet }, (builder) => {

    return (
        builder.addCase(UsersActionCreator.set.fulfilled, (state, action) => {
            state = {
                items: action.payload,
                status: AjaxRequestStatus.Done,
            };
            return state;
        }),
        builder.addCase(UsersActionCreator.set.pending, (state, action) => {
            // Loading in progress;
            state = {
                items: [],
                status: AjaxRequestStatus.Pending,
            };
            return state;
        }),
        builder.addCase(UsersActionCreator.set.rejected, (state, action) => {
            state = {
                items: [],
                status: AjaxRequestStatus.Failed,
            };
        }),
        builder.addCase(TenantActionCreator.initTenant, (state, action) => {
            state = {
                items: [],
                status: AjaxRequestStatus.NotSet,
            };
            return state;
        })
    );
});

export default reducer;
