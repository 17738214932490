/**
 * Represents a budget journal kind.
 */
class BudgetJournalKind {

    id: string;

    name: string;

    isActive: boolean;

    baseYear: number;

    code: string;

    workflowDefinitionName: string;

}

/**
 * Represents a row of budget journal kind list.
 */
class BudgetJournalKindItem {

    id?: string;

    name?: string;

    isActive?: boolean;

    baseYear?: number;

    code?: string;

    workflowDefinitionId?: string;

    workflowDefinitionName?: string;
}

export { BudgetJournalKind, BudgetJournalKindItem };
