/**
 * Represents a row of budget journal group.
 */
 class BudgetJournalGroup {

    id: string;
    
    name: string;
    
    orderIndex?: number;

}

export { BudgetJournalGroup };
