import React from 'react';
import { Cockpit, Shared } from '..';
import { Route } from 'react-router-dom';
import { PrivateRoute } from '@liasincontrol/ui-basics';
import { PublicationLayout } from '../views/_shared/LayoutComponents/PublicationLayout';
import * as PubRoutes from './Publisher/index.contents';
import * as PubAdminRoutes from './Publisher/admin.contents';
import * as PubSharedRoutes from './Publisher/shared.contents';
import { Actions } from '@liasincontrol/userrights-service';

/**
 * Reporesent a component for publisher routing with nested layouts.
 */
const Contents = (
    <>
        <Route index element={<PrivateRoute component={Cockpit.index} />} />
        <Route path="admin/publishprofiles/*" element={
            <PublicationLayout look="normal" modulenavigation={false} components={
                <>
                    <Route index element={
                        <PrivateRoute permissions={Actions.CRUD_ManagePublishProfiles} component={Shared.PublishProfiles.index} />
                    }></Route>
                </>}
            />}
        />
        <Route path="admin/publication/:id/*" element={<PublicationLayout look="normal" modulenavigation={true} components={PubAdminRoutes.Contents} />} />

        <Route path="publication/*">
            <Route path="list/*" element={<PublicationLayout look="normal" modulenavigation={false} components={PubSharedRoutes.Contents} />} />
            <Route path=":id/*" element={<PublicationLayout look="normal" modulenavigation={true} components={PubRoutes.Contents} />} />
        </Route>
    </>);

export { Contents as PublisherContents };