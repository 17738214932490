import styled from 'styled-components';
import { px, palette } from '@liasincontrol/ui-basics';
import { TileView } from 'devextreme-react/tile-view';

const StyledTileView = styled(TileView)`
    .dx-scrollview-content {        
        .dx-tile {
            background-color: ${palette.white};
            color: ${palette.grey1}; 
            border-radius: ${px(6)};
            border: ${px(2)} solid ${palette.grey3c};

            .dx-tile-content {
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }

            &.dx-state-hover {
                background-color: ${palette.white};
                border-color: ${palette.primary1b};
                color: ${palette.primary1b};
            }

            &.dx-state-focused {
                background-color: ${palette.white};
                border-color: ${palette.primary1b};
                color: ${palette.primary1b};
            }

            &.dx-state-disabled {
                background-color: ${palette.grey4};
            }
        }
    }
`;

const styledComponents = { StyledTileView };

export default styledComponents;
