import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class PerformanceInformationControl extends BaseControlElement {

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Title)
    title: string;
    
    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.MeasureMomentId)
    measureMomentId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.EntityTypeId)
    entityTypeId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.EntityId)
    entityId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.FieldId)
    fieldId: string;
}

export default PerformanceInformationControl;