import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import CustomStore from 'devextreme/data/custom_store';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { specialCharactersNormalizedArray } from '../../helper/datasource';
interface PivotDataSourceProps<T> {
    readonly data: T[];
    readonly fields: any[];
    readonly filterValue?: string | Array<any> | Function;
    readonly normalizeFilter: boolean;
}

export const CreatePivotDataSource = <T extends {}>(props: PivotDataSourceProps<T>): PivotGridDataSource => {
    const source = new PivotGridDataSource({
        fields: props.fields,
        store: props.data,
    });

    if (!_.isEmpty(props.filterValue)) {
        if (props.normalizeFilter) {
            source.filter(specialCharactersNormalizedArray(props.filterValue));
        } else {
            source.filter(props.filterValue);
        }
    }

    return source;
};

interface DataSourceProps<T> {
    readonly fields: any[];
    readonly dataSourcePromise: Promise<AxiosResponse<T[], any>>;
    readonly thenCallBack?: (data: T[]) => any;
}

const createStore = <T extends {}>(props: DataSourceProps<T>) => {
    return new CustomStore({
        load: (loadOptions) => {
            if (!loadOptions) {
                return Promise.resolve([]);
            }

            return props.dataSourcePromise
                .then((response: AxiosResponse<T[]>) => {
                    if (!response) return { data: [], totalCount: 0 }
                    if (props.thenCallBack) {
                        const newData = props.thenCallBack(response.data);
                        return { data: newData, totalCount: newData.length };
                    }
                    return { data: response.data, totalCount: response.data.length };
                });
        }
    });
};

export const CreateCustomPivotDataSource = <T extends {}>(props: DataSourceProps<T>): PivotGridDataSource => {
    const source = new PivotGridDataSource({
        fields: props.fields,
        store: createStore(props),
    });
    return source;
};
