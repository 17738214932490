import styled from 'styled-components';
import { Label, px, palette, Section, ISectionProps } from '@liasincontrol/ui-basics';

const CustomSection = styled(Section) <ISectionProps & { isToolbarExpanded: boolean }>`
    margin-left: ${({ isToolbarExpanded }) => isToolbarExpanded ? `${px(550)}` : '0'};
    width: -webkit-fill-available;

    @media (min--moz-device-pixel-ratio: 0) {
        width: -moz-available;
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: ${px(64)} repeat(12, [col-start] 1fr) ${px(64)};
    grid-template-rows: ${px(10)} repeat(7, [row-start] auto);
    grid-auto-flow: row;
    grid-gap: ${px(8)} ${px(20)};
`;

const HeaderForm = styled.div<{ toolbarOpen: boolean }>`
    grid-column: col-start / span 13;
    grid-row: 2 / span 1;
    display: flex;
    align-items: center;
    min-height: ${px(32)};
`;

const IconContainer = styled.div<{ color?: string }>`
    svg {
        fill: ${({ color }) => color ? color : palette.grey2};
    }
`;

const Warning = styled.div`
    grid-column: col-start / span 13;
    grid-row: 3 / span 1;
`;

const HeaderNumber = styled.div`
    grid-column: col-start / span 4;
    grid-row: 4 / span 1;
`;

const HeaderName = styled.div`
    grid-column: col-start 5 / span 8;
    grid-row: 4 / span 1;
`;

const ContentHeaderBar = styled.div`
    grid-column: 1 / span 15;
    grid-row: 5 / span 2;
    background-color: ${palette.grey4};
    border-bottom: ${px(2)} solid ${palette.grey3b};
    padding: ${px(70)} 0 0 0;
`;

const ContentHeader = styled.div`
    grid-column: 2 / span 14;
    grid-row: 6 / span 1;
    min-height: ${px(32)};
    display: flex;
    align-items: flex-end;
`;

const StyledLabel = styled(Label)` 
    min-height: 0;
    margin-left: ${px(3)};
`;

const styledComponents = {
    Grid, HeaderForm, HeaderNumber, HeaderName, StyledLabel, CustomSection, ContentHeader, ContentHeaderBar, Warning, IconContainer
};

export default styledComponents;
