export enum DataStoreTypes {
    None,
    CSV,
    DataPlatform,
    Studio,
    Performance,
    Finance
}

export const DataStoreTypesDictionary = {
    'Csv': DataStoreTypes.CSV,
    'DataPlatform': DataStoreTypes.DataPlatform,
    'Studio': DataStoreTypes.Studio,
    'Performance': DataStoreTypes.Performance,
    'Finance': DataStoreTypes.Finance,
}