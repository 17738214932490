import styled from 'styled-components';

const LayoutContainer = styled.ul`
    list-style: none;
    padding: 0;
    margin: 8px 0 0 0;
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(4, 1fr);
`;
const LayoutItem = styled.li`
    border: solid 2px #f4f4f8;
    padding: 24px;
    border-radius: 4px;
    &.active {
        border-color: #4651f8;
    }
`;
const LayoutPreview = styled.div`
    min-height: 150px;
`;
const LayoutInfo = styled.div`
    margin-top: 16px;
    button {
        display: block;
        margin: 0 auto 8px auto;
    }
`;

export { LayoutContainer, LayoutItem, LayoutPreview, LayoutInfo };