import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class BarChartControl extends BaseControlElement {

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Title)
    title: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.BarChartArgument)
    argument: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.BarChartValue)
    value: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.BarChartValueFormat)
    valueFormat: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Filter)
    filter: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSource)
    dataSourceId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.BarChartOrientation)
    orientation: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ChartLegend)
    legend: string;
}

export default BarChartControl;