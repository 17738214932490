import { createAsyncThunk } from '@reduxjs/toolkit';
import { Shared as SharedDataAccess } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';

const action = createAsyncThunk<Domain.Shared.UserGroup[], void, {}>('/usergroup/set', async () =>
    SharedDataAccess.UserGroups.get().then((response) => {
        return response.data;
    })
);

export default action;