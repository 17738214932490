import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../DataAccessHostUtils';

/**
 * Allows interaction with the HierarchyDefinitions-related backend API.
 */
export class HierarchyDefinitions {
    /**
     * Gets a list of user defined hierarchy definitions.
     * 
     * @param moduleId Defines the id of the module.
     * @param includeLinkDefinitions True if the hierarchy definitions should also be retrieved along with it's link definitions.
     */
    public static get = async (moduleId: string, includeLinkDefinitions = false): Promise<AxiosResponse<Domain.Shared.HierarchyDefinition[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const extraParams = includeLinkDefinitions ? `?includeLinkDefinitions=${includeLinkDefinitions}` : '';
        return HttpClient.get<Domain.Shared.HierarchyDefinition[]>(`/api/module/${moduleId}/hierarchydefinition${extraParams}`, config);
    };

    /**
     * Creates a hierarchy definition.
     * 
     * @param hierarchyDefinition Contains the created hierarchy definition.
     * @returns The guid of the new hierarchy definition.
     */
    public static create = async (hierarchyDefinition: Domain.Dto.Shared.CreateHierarchyDefinition): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<string>(`/api/hierarchydefinition`, hierarchyDefinition, config);
    };

    /**
     * Updates a hierarchy definition.
     * 
     * @param hierarchyDefinitionId Defines the id of the hierarchy definition.
     * @param hierarchyDefinition Contains the updated hierarchy definition.    
     */
    public static update = async (hierarchyDefinitionId: string, hierarchyDefinition: Domain.Dto.Shared.UpdateHierarchyDefinition): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.put<void>(`/api/hierarchydefinition/${hierarchyDefinitionId}`, hierarchyDefinition, config);
    };

    /**
     * Activates a hierarchy definition.
     * 
     * @param hierarchyDefinitionId the hierarchy definition id to activate.
     * @returns void.
     */
    public static activate = async (hierarchyDefinitionId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<void>(`/api/hierarchydefinition/${hierarchyDefinitionId}/activate`, null, config);
    };

    /**
    * Deactivates a hierarchy definition.
    * 
    * @param hierarchyDefinitionId the hierarchy definition id to deactivate.
    * @returns void.
    */
    public static deactivate = async (hierarchyDefinitionId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<void>(`/api/hierarchydefinition/${hierarchyDefinitionId}/deactivate`, null, config);
    };
}

export default HierarchyDefinitions;