import styled from 'styled-components';
import { palette, px } from '@liasincontrol/ui-basics';

const DescriptionAndTips = styled.div`
    margin-bottom: ${px(20)};
`;

const Tips = styled.ul``;

const Tip = styled.li`
    margin: 5px 0;
`;

const Description = styled.div``;

const Details = styled.div`
    border-radius: 5px;
    background-color: ${palette.grey5};
    padding: ${px(10)};
    margin-top: ${px(5)};
`;

export { Description, Details, Tip, Tips, DescriptionAndTips };