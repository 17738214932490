import { Button } from '../button';
import { px } from '../style';
import posed from 'react-pose';
import styled from 'styled-components';

export type DevicePosition = 'left' | 'center';

const Frame = styled(
    posed.div({
        desktop: {
            width: 1,
            height: 1,
            transition: {
                duration: 2000,
            },
        },
        tablet: {
            width: 1218,
            height: 854,
        },
        phone: {
            width: 417,
            height: 833,
        },
    })
)`
    grid-column: ${(props: { position: DevicePosition }) => (props.position === 'center' ? 2 : 1)};
    grid-row: 2;
    align-self: center;
    justify-self: center;
    background: #fff;
    border-radius: 52px;
    box-shadow: 0 23px 26px rgba(0, 0, 0, 0.16);
    position: relative;
`;
const DeviceFrameButton = styled(
    posed.div({
        desktop: {
            width: 0,
            height: 0,

            top: 0,
            left: 0,
        },
        tablet: {
            applyAtStart: {
                opacity: 0,
            },
            opacity: 1,
            width: 45,
            height: 45,

            top: 404,
            left: 1147,
        },
        phone: {
            applyAtStart: {
                opacity: 0,
            },
            opacity: 1,
            width: 57,
            height: 57,

            top: 764,
            left: 175,
        },
    })
)`
    background: #fff;
    border: solid 1px #5d5d5d;
    border-radius: 50%;
    position: absolute;
`;
const DeviceFrameCamera = styled(
    posed.div({
        desktop: {
            width: 0,
            height: 0,

            top: 0,
            left: 0,
        },
        tablet: {
            width: 15,
            height: 15,

            top: 419,
            left: 40,
        },
        phone: {
            width: 15,
            height: 15,

            top: 38,
            left: 155,
        },
    })
)`
    background: #fff;
    border: solid 1px #5d5d5d;
    border-radius: 50%;
    position: absolute;
`;
const DeviceFrameSpeaker = styled(
    posed.div({
        desktop: {
            width: 0,
            height: 0,

            top: 0,
            left: 0,
        },
        tablet: {
            opacity: 0,
            width: 15,
            height: 15,

            top: 419,
            left: 40,
        },
        phone: {
            opacity: 1,
            width: 58,
            height: 6,

            top: 42,
            left: 192,
        },
    })
)`
    background: #fff;
    border: solid 1px #5d5d5d;
    border-radius: 20px;
    position: absolute;
`;

export const MonitorButton = (styled(Button)`
    opacity: 0.5;
    transition: opacity 0.2s;
    &.active {
        opacity: 1;
    }
    > span {
        > svg {
            width: 20px;
            height: 20px;
        }
    }
` as any) as typeof Button; /* HACK: as any, TypeScript issue at resolving btntype */
export const TabletButton = (styled(Button)`
    opacity: 0.5;
    transition: opacity 0.2s;
    &.active {
        opacity: 1;
    }
    > span {
        > svg {
            width: 18px;
            height: 18px;
            transform: rotate(-90deg);
        }
    }
` as any) as typeof Button; /* HACK: as any, TypeScript issue at resolving btntype */
export const PhoneButton = (styled(Button)`
    opacity: 0.5;
    transition: opacity 0.2s;
    &.active {
        opacity: 1;
    }
    > span {
        > svg {
            width: 20px;
            height: 20px;
        }
    }
` as any) as typeof Button; /* HACK: as any, TypeScript issue at resolving btntype */

const DeviceWrapper = styled.div`
    zoom: 80%;
    -moz-transform: scale(0.8);
    -moz-transform-origin: 0 0;
    display: grid;
    grid-template-rows: ${px(16)} 1fr 80px;
    grid-template-columns: minmax(auto, ${px(1200)}) minmax(${px(80)}, auto);
`;

const DeviceContent = styled(
    posed.div({
        desktop: {
            width: '1200px',
            height: '100%',
            applyAtStart: {
                border: 'none',
                overflow: 'hidden',
            },
            applyAtEnd: {
                overflow: 'initial',
            },
        },
        tablet: {
            width: '1024px',
            height: '768px',
            applyAtStart: {
                border: 'solid 1px #d8d8d8',
                overflow: 'auto',
            },
        },
        phone: {
            width: '375px',
            height: '667px',
            applyAtStart: {
                border: 'solid 1px #d8d8d8',
                overflow: 'auto',
            },
        },
    })
)`
    grid-column: 1;
    grid-row: 2;
    align-self: center;
    justify-self: center;
    overflow: auto;
    position: relative;
    z-index: 0;
    transform: translate(0);
`;

const DesktopWrapper = styled.div`
    width: 1200px;
    height: 100wh;
`;

const PhoneWrapper = styled.div`
    width: 375px;
    height: 667px;
`;

const ScaledTabletWrapper = styled.div`
    zoom: 125%;
    -moz-transform: scale(1.25);
    -moz-transform-origin: 0 0;
    width: 819px;
    height: 614px;
`;

const styledComponents = {
    Frame,
    DeviceFrameButton,
    DeviceFrameCamera,
    DeviceFrameSpeaker,
    DeviceWrapper,
    DeviceContent,
    DesktopWrapper,
    ScaledTabletWrapper,
    PhoneWrapper,
};
export default styledComponents;
