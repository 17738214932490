import React from 'react';
import { SideMenuLayout } from '../Layouts';
import { Routes as PubRoutes } from '@liasincontrol/pub';

/**
 * Represents an UI element that renders all Publisher sub-routes, in their specific Layouts.
 * path='/publisher/*'
 */
const PublisherModuleRoutes: React.FC = () => {
    return (
        <SideMenuLayout look="normal" sideMenu={PubRoutes.PublisherMenu} components={PubRoutes.PublisherContents} />
    );
}

export default PublisherModuleRoutes;
