import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { PrivateRoute } from '@liasincontrol/ui-basics';
import { Actions } from '@liasincontrol/userrights-service';
import { Cockpit, Overview, Monitor, PerformanceElementDefinitions } from '..';


const Contents = (
    <>
        <Route index element={<PrivateRoute component={Cockpit.index} />} />
        <Route path="admin/entities" element={<PrivateRoute component={PerformanceElementDefinitions.index} permissions={Actions.COMPLEX_ManagePerformanceElementDefinition} />} />
        <Route path="tree/monitor" element={<PrivateRoute component={Monitor.index} />} />

        {/* React router 6 doesn't support optional parameters */}
        <Route path="tree/overview" element={<PrivateRoute component={Overview.index} />}>
            <Route path=":selectedtype" element={<PrivateRoute component={Overview.index} />} />
            <Route path=":selectedtype/:selectedmoment" element={<PrivateRoute component={Overview.index} />} />
            <Route path=":selectedtype/:selectedmoment/:selecteditem" element={<PrivateRoute component={Overview.index} />} />
        </Route>
        <Route path='*' element={<Navigate to='/notfound' />} />
    </>
);

export { Contents };