import styled from 'styled-components';
import { px } from '@liasincontrol/ui-basics';

const SubGroupLayoutForm = styled.div`
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-auto-flow: row;
    grid-gap: ${px(4)} ${px(34)};
    align-self: flex-start;
    align-items: start;
    margin-top: ${px(8)};
`;

const GridPlacement = styled.div<{ column: number }>`
    grid-column-start: ${(p) => `${p.column}`};
    grid-column-end: ${(p) => `${p.column + 1}`};
`;

export { SubGroupLayoutForm, GridPlacement };