import React from 'react';
import { TagBoxProps } from './index.props';
import { TagBoxEditor } from './index.editor';
import { TextViewer } from '../../shared/TextViewer';

/**
 * Represents a UI component that renders a TagBox element.
 */
export const TagBoxElement: React.FC<TagBoxProps> = (props) => {
    return props.editorSettings && !props.editorSettings.disabled
        ? <TagBoxEditor {...props} />
        : <TextViewer id={props.id} label={props.label} helpText={props.helpText} value={props.value.join(', ')} />;
};