import React from 'react';
import { Heading3, CardList, Section, Text } from '@liasincontrol/ui-basics';
import Styled from './index.styled';

/**
 * Represent a type of a task summary shown in the task tile .
 */
export type TaskSummary = {
    closedTaskCount: number,
    openActiveTaskCount: number,
    openInactiveTaskCount: number,
    title: string,
    link: string,
    color: string,
    key: string,
};

type Props = {
    tasks: TaskSummary[],
    title?: string,
    rowspan?: number,
};

/**
 * Represents a UI component that renders a list of task tiles.
 */
export const TaskTiles: React.FC<Props> = (props) => {
    return (
        <Section look="white" grid={true} colSpan={3} rowSpan={props.rowspan || 2} widget={true} padding={true}>
            {props.title && <Heading3>{props.title}</Heading3>}
            <Styled.Overflow>
                {props.tasks.length ?
                    props.tasks.map((task) => {
                        const items = [
                            { title: 'Open', value: task.openActiveTaskCount },
                            { title: 'Toekomstig', value: task.openInactiveTaskCount },
                            { title: 'Afgerond', value: task.closedTaskCount },
                        ];
                        return (<CardList id={task.key} key={`cardlist-${task.key}`} title={task.title} listItems={items} direction={'horizontal'} url={task.link} withMargin={true} />);
                    })
                    : <Styled.CenteredDiv><Text value='Geen taken gevonden' /></Styled.CenteredDiv>
                }
            </Styled.Overflow>
        </Section>
    );
};
