import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { oDataResponse } from '../../Shared/oData';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents a data accessor of realizations.
 */
export class RealizationAmountDataAccessor {

    /**
     * Gets the collection of realization amounts.
     * @param query Defines the odata query.
     */
    public static getAll = async (query = ''): Promise<AxiosResponse<oDataResponse<Domain.Finance.RealizationAmount[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<oDataResponse<Domain.Finance.RealizationAmount[]>>(`/api/finance/imported-realization${query}`, config);
    };

}
