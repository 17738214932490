import React from 'react';
import { Route, Navigate } from 'react-router-dom';
import { PrivateRoute } from '@liasincontrol/ui-basics';
import { Actions } from '@liasincontrol/userrights-service';
import { Cockpit, ElementDefinitions, HierarchyDefinitions, StudioHierarchyList, StudioHierarchyListItem } from '..';
import { DefinitionLayout } from '../views/_shared/DefinitionLayout';

import * as FieldDefinitions from '../views/ElementDefinitions/ElementDefinitionSettings/FieldsDefinitions';
import * as Information from '../views/ElementDefinitions/ElementDefinitionSettings/Information';
import * as DetailsLayout from '../views/ElementDefinitions/ElementDefinitionSettings/DetailsLayout';

const Contents = (
    <>
        <Route index element={<PrivateRoute component={Cockpit.index} />} />
        <Route path='hierarchies' element={<PrivateRoute component={StudioHierarchyList.index} />} />
        <Route path='hierarchy/:hierarchyDefinitionId/moment/:momentId/:hierarchyId' element={<PrivateRoute component={StudioHierarchyListItem.StudioHierarchyListItem} />} />

        <Route path='admin/elementdefinitions' element={<PrivateRoute permissions={Actions.COMPLEX_ManageStudioElementDefinition} component={ElementDefinitions.index} />} />
        <Route path='admin/hierarchydefinitions' element={<PrivateRoute permissions={Actions.COMPLEX_ManageStudioHierarchyDefinition} component={HierarchyDefinitions.index} />} />

        {/* TODO: can we refactor this to be easier to understand? */}
        <Route path='admin/elementdefinition/:id/settings/*' element={
            <DefinitionLayout components={
                <>
                    <Route path="fields" element={<PrivateRoute permissions={Actions.COMPLEX_ManageStudioElementDefinition} component={FieldDefinitions.index} />} />
                    <Route path="information" element={<PrivateRoute permissions={Actions.COMPLEX_ManageStudioElementDefinition} component={Information.index} />} />
                    <Route path="layout" element={<PrivateRoute permissions={Actions.COMPLEX_ManageStudioElementDefinition} component={DetailsLayout.index} />} />
                </>
            } />
        } />
        <Route path='*' element={<Navigate to='/notfound' />} />
    </>
);

export { Contents };
