import React, { useState } from 'react';
import { ButtonField, IDataItemProps, ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';
import { FilterValueType, LsFilterBuilder, LsFilterBuilderField, LsFilterBuilderOnChangeHandler } from '@liasincontrol/ui-devextreme';
import { FilterBuilderProps } from './index.props';
import { getValidationErrorsNode } from '../../shared/validationHelper';

/**
 * Represents a UI component that renders a filter builder editor. Only use if editorSettings
 */
export const FilterBuilderEditor: React.FC<FilterBuilderProps> = (props) => {
    const [dialogOpened, setDialogOpened] = useState<boolean>(false);
    const filterButtonName = props.editorSettings?.disabled ? 'Bekijken' : (hasFilterValue(props.value) ? 'Aanpassen' : 'Instellen');

    return (
        <>
            <ButtonField
                id={props.id}
                label={props.label}
                value={{ name: filterButtonName }}
                helpText={props.helpText}
                onChange={() => setDialogOpened(true)}
                error={getValidationErrorsNode(props.editorSettings!.validationErrors)}
            />
            {dialogOpened && (
                <EditorFilterDialog
                    id={props.id}
                    disabled={props.editorSettings!.disabled}
                    value={hasFilterValue(props.value) ? JSON.parse(props.value) : []}
                    fields={props.fields}
                    variables={props.variables}
                    onSaveChanges={(value: FilterValueType) => {
                        props.editorSettings?.onChange(JSON.stringify(value));
                        setDialogOpened(false);
                    }}
                    onCancelChanges={() => setDialogOpened(false)}
                />
            )}
        </>
    );
};

type DialogProps = {
    id: string,
    fields: LsFilterBuilderField[] | undefined;
    value: FilterValueType,
    variables: IDataItemProps<string>[],
    disabled?: boolean,
    onSaveChanges: (value: FilterValueType) => void,
    onCancelChanges: () => void
};

const EditorFilterDialog: React.FC<DialogProps> = (props) => {
    const [value, setValue] = useState<FilterValueType>(props.value);
    return (
        <ModalDialog settings={{
            look: "interactive",
            title: "Filter configureren",
            footer: (
                <ModalDialogFooter
                    leftButtonText='Annuleren'
                    onLeftButtonClick={props.onCancelChanges}
                    rightButtonText={!props.disabled ? 'Opslaan' : null}
                    onRightButtonClick={() => props.onSaveChanges(value || [])} />
            ),
        }}>
            {props.fields?.length > 0 &&
                <LsFilterBuilder
                    id={props.id}
                    fields={props.fields}
                    value={value}
                    onValueChanged={(e: LsFilterBuilderOnChangeHandler) => setValue(e.value)}
                    disabled={props.disabled}
                    variables={props.variables}
                />
            }
        </ModalDialog >
    );
};

const hasFilterValue = (filterValue: {}) => filterValue && filterValue !== 'null' && filterValue !== '[]' && filterValue !== '';
