import { isEventKey } from 'keycode';
import React from 'react';
import styled from 'styled-components';
import { BasicText } from '../typography';
import { IProps } from "./Props";
interface ICheckDotProps {
  readonly isSelected: boolean;
  readonly isFocused: boolean;
  readonly isDisabled: boolean;
}

// tslint:disable:no-magic-numbers
const defaultLook = {
  defaultColor: 'rgba(75, 85, 246, 0.1)',
  defaultColorDarker: 'rgba(75, 85, 246, 0.3)',
  primaryColor: '#4b55f6;',
  primaryColorDarker: '#2934CC',
  disabledLight: '#F4F4F8',
  disabledDark: '#8A8991',
  textColor: '#414141',
};

const Label = styled.label`
  position: relative;
  display: grid;
  grid-template-columns: 40px 1fr;
  &:focus {
    outline: none;
  }
`;

const CheckDot = styled.span`
  grid-column: 1;
  grid-row: 1;
  position: relative;
  &.middle{
    grid-row: 1 / 3;
  }
  cursor: ${(p: ICheckDotProps) => (p.isDisabled ? 'default' : 'pointer')};
  color: ${(p: ICheckDotProps) =>
    (p.isDisabled && defaultLook.disabledDark)
    || (p.isSelected && '#000')
    || defaultLook.textColor
  };
  font-weight: ${(p: ICheckDotProps) =>
    (p.isDisabled && '300')
    || (p.isSelected && '600')
    || '400'
  };

  &::before {
    content: '';
    position: absolute;
    top: 0; bottom: 0; left: 0;
    margin:auto;
    width: 24px; height: 24px;
    background-color: #fff;
    border:2px solid ${defaultLook.defaultColor};
    border-color: ${(p: ICheckDotProps) =>
    (p.isDisabled && defaultLook.disabledLight)
    || (p.isFocused && defaultLook.primaryColor)
    || defaultLook.defaultColor};
    border-radius: 24px;
    transition:border-color .2s;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0; bottom: 0; left: 6px;
    margin:auto;
    width: 12px; height: 12px;
    border-radius: 12px;
    background-color: ${(p: ICheckDotProps) =>
    (p.isDisabled && p.isSelected && defaultLook.disabledDark)
    || (p.isSelected && defaultLook.primaryColor)
    || '#fff'
  };
    transition:background-color .2s;
  }

  ${Label}:hover &::before {
    border-color: ${(p: ICheckDotProps) =>
    (p.isDisabled && defaultLook.disabledLight)
    || (p.isFocused && defaultLook.primaryColor)
    || defaultLook.defaultColorDarker};
  }
  ${Label}:focus &::before {
    border-color: ${(p: ICheckDotProps) =>
    (p.isDisabled && defaultLook.disabledLight)
    || (p.isFocused && defaultLook.primaryColor)
    || defaultLook.primaryColor};
  }
  ${Label}:active &::before {
    box-shadow: ${(p: ICheckDotProps) =>
    (p.isDisabled && '0')
    || '0 0 0 6px ' + defaultLook.defaultColor};
  }
`;

const CheckTitle = styled(BasicText)`
  display: block;
  grid-column: 2;
  grid-row: 1;
  white-space: normal;
`;

const CheckContent = styled(BasicText)`
  display: block;
  grid-column: 2;
  grid-row: 2;
  white-space: normal;
`;

export class InputRadio extends React.PureComponent<IProps> {
  public state = {
    focused: false,
  };

  public render() {
    const { id, label, selected = false, disabled = false, content, radioPosition } = this.props;

    return (
      <Label
        id={`radio-${id}`}
        role="radio"
        aria-checked={selected}
        tabIndex={disabled ? -1 : 0}
        onKeyPress={this.onKeyPress}
        onClick={this.onClick}
        onFocus={this.onFocusRadio}
        onBlur={this.onBlurRadio}>
        <CheckDot isSelected={selected} isFocused={this.state.focused} isDisabled={disabled} className={radioPosition}></CheckDot>
        <CheckTitle>{label}</CheckTitle>
        <CheckContent>{content}</CheckContent>
      </Label>
    );
  }

  private onKeyPress = (e: React.KeyboardEvent<any>) => {
    if (isEventKey(e as any, 'space')) {
      this.onClick();
      e.preventDefault();
    }
  }

  private onClick = () => {
    const { disabled, onClick } = this.props;
    if (!disabled && onClick) {
      onClick();
    }
  }

  private onFocusRadio = () => {
    this.setState({ focused: true });
  }

  private onBlurRadio = () => {
    this.setState({ focused: false });
  }
}
