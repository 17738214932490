import { createAsyncThunk } from '@reduxjs/toolkit';
import { Finance as FinanceDataAccess } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import Payload from '../../../../Payload';

const action = createAsyncThunk<Domain.Finance.BudgetElementGroupTag[], Payload<{ baseYear: number }>, {}>('/finance/budgetElementGroupTags/set', async (payload) => {
    return FinanceDataAccess.BudgetElementGroupDataAccessor.getAvailableTags(payload.data.baseYear)
        .then(response => response.data);
});

export default action;