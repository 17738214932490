import { IconSize } from '@liasincontrol/ui-basics';

export const getIconSize = (fontSize: number): IconSize => {
    switch (true) {
        case (fontSize <= 14): return IconSize.small;
        case (fontSize <= 20): return IconSize.medium;
        case (fontSize <= 24): return IconSize.default;
        case (fontSize <= 32): return IconSize.large;
        case (fontSize <= 48):
        default: return IconSize.extralarge;
    }
};
