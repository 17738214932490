import { FieldDataType, FieldDefinition } from "../../aggregates/Shared";

export const nameFieldDefinition: FieldDefinition = {
    dataType: FieldDataType.String,
    id: "24141554-1ac8-4ea1-ab3c-6c2e8606dec7",
    name: "Naam",
    required: true,
    stringMaxLength: 250,
    systemId: "24141554-1ac8-4ea1-ab3c-6c2e8606dec7",
};

export const descriptionFieldDefinition: FieldDefinition = {
    dataType: FieldDataType.String,
    id: "54c49504-2d35-4dbd-a6ac-c51cdfeba1cd",
    name: "Beschrijving",
    required: false,
    stringMaxLength: 250,
    systemId: "54c49504-2d35-4dbd-a6ac-c51cdfeba1cd",
};

export const optionFieldDefinition: FieldDefinition = {
    dataType: FieldDataType.Option,
    id: "ffd7d4db-8a96-4e1d-8fcb-b05a7f69066b",
    name: "Option",
    required: false,
    systemId: "ffd7d4db-8a96-4e1d-8fcb-b05a7f69066b",
    relationElementDefinitionId: "00000000-0000-0000-0000-000000000000",
};
