import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents the task management data accessor.
 */
export class TasksManagement {
    /**
     * Gets the tasks for all the controls in a page.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageId Defines the unique identifier of the page.
     * @param isPatchable Defines editable property.
     */
    public static getNodesForPage = async (publicationId: string, pageId: string, isPatchable = true): Promise<AxiosResponse<Domain.Publisher.AssignmentNode>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const extraParams = isPatchable ? `?isPatchable=${isPatchable}` : '';
        return HttpClient.get<Domain.Publisher.AssignmentNode>(`/api/publication/${publicationId}/tasks/${pageId}/management${extraParams}`, config);
    };

    /**
     * Gets the inprogress states for publication.
     *
     * @param publicationId Defines the unique identifier of the publication.
     */
    public static getWorkflowStatesForPublication = async (publicationId: string): Promise<AxiosResponse<Domain.Publisher.TaskManagerWorkflowState[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.TaskManagerWorkflowState[]>(`/api/publication/${publicationId}/workflow/states`, config);
    };

    /**
     * Gets the assigned users for a component for specified inprogress state for publication.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageId Defines the unique identifier of the page within a publication.
     * @param workflowStateId Defines the unique identifier of the workflow state.
     * @param componentId Defines the unique identifier of the component.
     */
    public static getPublicationTaskUsersByPageComponent = async (publicationId: string, pageId: string, workflowStateId: string, componentId: string): Promise<AxiosResponse<string[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<string[]>(`/api/publication/${publicationId}/page/${pageId}/component/${componentId}/state/${workflowStateId}/tasks/users`, config);
    };

    /**
     * Sets the assigned users for a page component.
     *
     * @param publicationId Defines the unique identifier of the publication.
     *
     */
    public static assignUserToPageComponent = async (publicationId: string, postedData: Domain.Dto.Publisher.UpdatePublicationTask): Promise<AxiosResponse<Domain.Publisher.Component>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<Domain.Publisher.Component>(`/api/publication/tasks/${publicationId}/set-user-assignments`, postedData, config);
    };

    /**
     * Sets the publication workflow state to a new forward value.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param workflowStateId Defines the unique identifier of the workflow state.
     *
     */
    public static setPublicationWorkflowState = async (publicationId: string, workflowStateId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<void>(`/api/publication/${publicationId}/workflow/move-forward-to`, { workflowStateId }, config);
    };

    /**
     * Sets the publication workflow to a new value.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param workflowDefinitionId Defines the unique identifier of the workflow definition.
     *
     */
    public static setPublicationWorkflow = async (publicationId: string, workflowDefinitionId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<void>(`/api/publication/${publicationId}/workflow`, { workflowDefinitionId: workflowDefinitionId }, config);
    };

    /**
     * Sets the component workflow state to a new value.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageId Defines the unique identifier of the page.
     * @param componentId Defines the unique identifier of the component.
     * @param workflowStateId Defines the unique identifier of the workflow state.
     * @returns
     */
    public static setComponentWorkflowState = async (publicationId: string, pageId: string, componentId: string, workflowStateId: string, userIds: string[], remark: string) => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        const body = {
            workflowStateId,
            userIds: userIds,
            comment: remark,
        };

        return HttpClient.post<void>(`/api/publication/${publicationId}/page/${pageId}/component/${componentId}/move-backward`, body, config);
    };

    /**
     * Sets the bulk assigned users for a list of page components on a list of selected states.
     *
     * @param publicationId Defines the unique identifier of the publication.
     *
     */
    public static addUserAssignmentsToPageComponents = async (publicationId: string, postedData: Domain.Dto.Publisher.UserAssignmentsToPageComponents): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<void>(`/api/publication/tasks/${publicationId}/add-user-assignments`, postedData, config);
    };

    /**
     * Remove user assignments from the specified components in the specified workflow states.
     *
     * @param publicationId Defines the unique identifier of the publication.
     *
     */
    public static removeUserAssignmentsFromPageComponents = async (publicationId: string, postedData: Domain.Dto.Publisher.UserAssignmentsToPageComponents): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<void>(`/api/publication/tasks/${publicationId}/remove-user-assignments`, postedData, config);
    };
    /**
     * Move the workflow items and tasks of one component on one page forward to the specified state.
     *
     * @param publicationId Id of the publication
     * @param pageId Id of the page
     * @param componentId Id of the component
     * @param stateId Id of the state to move to
     */
    public static moveComponentStatusForward = async (publicationId: string, pageId: string, componentId: string, stateId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<void>(`/api/publication/${publicationId}/page/${pageId}/component/${componentId}/move-forward-to`, { workflowStateId: stateId }, config);
    };
}
