import { cssFontBold, BasicText, Heading1, px, palette } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

interface ILeftSideBarPanel {
    readonly hasPadding: boolean;
}

const LeftSideBarPanel = styled.div`
    height: calc(100% - 64px);
    overflow-y: auto;
    width: ${px(500)};
    padding: ${(p: ILeftSideBarPanel) => p.hasPadding ? `${px(32)} ${px(40)} ${px(32)} ${px(40)}` : `0`};

    ::-webkit-scrollbar {
        width: ${px(15)};
    }
    ::-webkit-scrollbar-track {
        background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #ececf3;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #e3e3ec;
    }
    ::-webkit-scrollbar-button,
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
`;

const EdgeToolbarContent = styled.div`
    margin: ${px(24)} ${px(24)} 0 ${px(24)};
`;

const ToolbarTitle = styled(Heading1)`
    display: block;
    margin: 0 0 ${px(24)} 0;
`;

const SectionHeader = styled.h3`
    ${cssFontBold(20, 27, 30)}
    color: ${palette.grey2};
    margin: 0;
    padding-bottom: 16px;
    border-bottom: solid 2px ${palette.grey4};
    margin-bottom: 24px;
`;

const TemplateContainer = styled.div`
    margin: ${px(32)} 0 ${px(32)} 0;
`;

const TemplateIcon = styled.span`
    grid-column: 1;
`;

const TemplateName = styled(BasicText)`
    margin-top: 10px;
    &.grid {
        display: grid;
        grid-template-columns: 40px 1fr;
        align-items: center;
        grid-gap: 12px;
    }
`;

const TemplateNameContainer = styled.div`
    grid-column: 2;
    display: flex;
    flex-direction: row;
    button {
        margin-left: 16px;
    }
    .icon-name {
        align-self: center;
    }
`;

const LabelWrap = styled.label`
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${px(255)};
    flex-grow: 1;
`;

export { LeftSideBarPanel, EdgeToolbarContent, ToolbarTitle, SectionHeader, TemplateContainer, TemplateIcon, TemplateName, TemplateNameContainer, LabelWrap };

