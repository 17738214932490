import React from 'react';
import { WithFieldBaseType, withField1 } from '@liasincontrol/ui-basics';
import { StyledInput } from './index.styled';


type InputFieldProps = WithFieldBaseType<string> & {
  placeholder?: string;
  defaultValue?: string;
  maxLength?: string;
  readOnly?: boolean;
  showClearButton?: boolean;
  valueChangeEvent?: string;
  children?: React.ReactNode;
};

export const InputField = withField1<string, InputFieldProps>(
  (props) => (
    <StyledInput
      {...props}
      label=''
      onChange={undefined}
      onInput={(e) => props.onChange?.((e.event?.currentTarget as any)?.value)}
    >
      {props.children}
    </StyledInput>
  )
);

