import React, { useState } from 'react';
import { JsonUtils } from '@liasincontrol/core-service';
import { ButtonField, MultiSelectItem, ProgressLoading } from '@liasincontrol/ui-basics';
import { MultiOptionsListsProps } from './index.props';
import { getValidationErrorsNode } from '../../shared/validationHelper';
import { ConnectingDialog } from '../connecting-dialog';

/**
 * Represents a UI component that renders an editor with a dialog with 2 lists, allowing the selection of multiple items.
 */
export const MultiOptionsListsEditor: React.FC<MultiOptionsListsProps> = (props) => {
    const [dialogOpened, setDialogOpened] = useState<boolean>(false);
    const buttonName = props.editorSettings?.disabled ? 'Bekijken' : (props.value ? 'Aanpassen' : 'Instellen');

    if (!props.allItems) {
        return <ProgressLoading variant="small" />;
    }

    return (
        <>
            <ButtonField
                id={props.id}
                label={props.label}
                helpText={props.helpText}
                value={{ name: buttonName }}
                onChange={() => setDialogOpened(true)}
                disabled={props.editorSettings?.disabled}
                error={props.editorSettings && getValidationErrorsNode(props.editorSettings.validationErrors)}
            />

            {dialogOpened &&
                <ConnectingDialog
                    title={props.title}
                    disableSaveButton={props.editorSettings?.disabled}
                    mandatory={props.editorSettings?.restrictions?.required}
                    listItems={props.allItems}
                    selectedItems={JsonUtils.toJson<MultiSelectItem[]>(props.value, [])}
                    onCancelled={() => setDialogOpened(false)}
                    onConfirmed={(checkedItems: MultiSelectItem[]) => {
                        props.editorSettings?.onChange(checkedItems.length > 0 ? JSON.stringify(checkedItems) : '');
                        setDialogOpened(false);
                    }}
                />
            }
        </>
    );
};

