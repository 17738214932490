import React from 'react';
import * as Styled from './index.style';
import Ink from 'react-ink';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

type Props = {
    readonly id: string;
    readonly onClick: () => void;
    readonly look: 'default' | 'inverted';
    readonly arrowDirection: 'left' | 'right';
    readonly ariaLabel: string;
}

/**
 * Represents a UI component that displays a button with right/left arrows.
 */
export const ArrowButton: React.FC<Props> = ({ onClick, id, look, arrowDirection, ariaLabel }) => {
    return (
        <Styled.Button id={id} look={look} aria-label={ariaLabel} onClick={onClick}>
            <Styled.Wrapper tabIndex={-1}>
                {(look === 'default' && arrowDirection === 'left') && <KeyboardArrowLeftIcon />}
                {(look === 'default' && arrowDirection === 'right') && <KeyboardArrowRightIcon />}
                {(look === 'inverted' && arrowDirection === 'left') && <KeyboardDoubleArrowLeftIcon />}
                {(look === 'inverted' && arrowDirection === 'right') && <KeyboardDoubleArrowRightIcon />}
                <Ink recenter={true} />
            </Styled.Wrapper>
        </Styled.Button>
    );
};
