import { palette, px } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

const Dot = styled.div<{ backgroundColor: string }>`
    width: ${px(32)};
    height: ${px(32)};
    border-radius: ${px(16)};
    background-color: ${(p) => p.backgroundColor};
    border: 1px solid ${palette.grey3};
`;

export { Dot };