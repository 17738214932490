import React from 'react';
import { TagBoxField } from '@liasincontrol/ui-devextreme';
import { TagBoxProps } from './index.props';
import { getValidationErrorsNode } from '../../shared/validationHelper';

/**
 * Represents a UI component that renders a tagbox editor without predefined list or any datasource.
 */
export const TagBoxEditor: React.FC<TagBoxProps> = (props) => {
    return (
        <TagBoxField
            id={props.id}
            key={props.id}
            label={props.label}
            disabled={props.editorSettings?.disabled}
            value={props.value}
            onChange={props.editorSettings?.onChange}
            error={getValidationErrorsNode(props.editorSettings?.validationErrors || [])}
            helpText={{ text: props.helpText?.text, title: props.helpText?.title }}
            mandatory={props.editorSettings?.restrictions?.required}
            showClearButton={props.showClearButton}
            showDropDownButton={props.showDropDownButton}
            openOnFieldClick={props.openOnFieldClick}
            acceptCustomValue={props.acceptCustomValue}
            items={props.items}
            displayExpr={props.displayExpr}
        />
    );
};

