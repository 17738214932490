import React, { useState } from 'react';
import { Text, ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteIcon from '@mui/icons-material/Delete';
import Styled from './index.styled';

type Props = {
    controlId: string,
    controlName: string,
    canMoveUp: boolean,
    canMoveDown: boolean,
    onRemoveElement: (controlId: string) => void,
    onMoveElement: (moveUp: boolean) => void,
};

/**
 * Represents a UI component that renders the element actions near an element wrapper.
 */
export const Actions: React.FC<Props> = (props): JSX.Element => {
    const [showDeleteControl, setShowDeleteControl] = useState<boolean>(false);

    const getRemoveDialogElement = (onClose: () => void, onConfirm: () => void): JSX.Element => {
        return (
            <ModalDialog settings={{
                look: "interactive", title: "Element verwijderen", footer: (
                    <ModalDialogFooter
                        leftButtonText='Annuleren'
                        onLeftButtonClick={onClose}
                        rightButtonText='Verwijderen'
                        onRightButtonClick={onConfirm} />
                )
            }}>
                <Text value="U staat op het punt om een element te verwijderen. Als er content aan het element is toegevoegd zal deze verloren gaan. U kunt deze actie niet meer ongedaan maken."></Text>
            </ModalDialog>
        );
    };

    return (
        <>
            <Styled.ButtonContainerTopRight>
                <Styled.ButtonIcon
                    id="btn-remove"
                    btnbase="iconbuttons"
                    btntype="medium_fabprimary"
                    icon={<DeleteIcon />}
                    aria-label={`Verwijder element ${props.controlName}`}
                    onClick={(event) => {
                        event.stopPropagation();
                        setShowDeleteControl(true);
                    }}
                />
                <Styled.ButtonIcon
                    id="btn-move-up"
                    btnbase="iconbuttons"
                    btntype="medium_fabghost"
                    icon={<KeyboardArrowUpIcon />}
                    disabled={!props.canMoveUp}
                    aria-label={`Verplaats element ${props.controlName} omhoog`}
                    onClick={(event) => {
                        event.stopPropagation();
                        props.canMoveUp && props.onMoveElement(true);
                    }}
                />
                <Styled.ButtonIcon
                    id="btn-move-down"
                    btnbase="iconbuttons"
                    btntype="medium_fabghost"
                    icon={<KeyboardArrowDownIcon />}
                    disabled={!props.canMoveDown}
                    aria-label={`Verplaats element ${props.controlName} omlaag`}
                    onClick={(event) => {
                        event.stopPropagation();
                        props.canMoveDown && props.onMoveElement(false);
                    }}
                />
            </Styled.ButtonContainerTopRight>
            {showDeleteControl && getRemoveDialogElement(
                () => setShowDeleteControl(false),
                () => { setShowDeleteControl(false); props.onRemoveElement(props.controlId); })
            }
        </>
    );
};