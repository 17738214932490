import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { AutoFocus, EdgeToolbar, ToolbarNav } from '@liasincontrol/ui-basics';
import { TextElement } from '@liasincontrol/ui-elements';
import Styled from './index.styled';
import { formatOptionItems, getFieldDisplayType } from '../../../FieldsDefinitions/utils';

type Props = {
    isOpen: boolean,
    title: string,
    selectedFieldDefinition: Domain.Shared.FieldDefinition,
    onToggle: () => void,
};

const fieldDataDisplayOrder: string[] = [
    "dataType",
    "editorSettings",
    "optionItems",
    "stringMaxLength",
    "required"
];

const SideToolbar: React.FC<Props> = (props) => {
    const [showFieldDetails, setShowFieldDetails] = useState<boolean>(false);

    useEffect(() => {
        if (!props.selectedFieldDefinition)
            setShowFieldDetails(false);
        else
            setShowFieldDetails(true);
    }, [props.selectedFieldDefinition]);

    return (<EdgeToolbar edge='left' open={props.isOpen} inModal onToggle={props.onToggle}>
        <Styled.LeftSideBarPanel className="slide-panel-section">
            <Styled.TabsPlaceholder>
                <Styled.EdgeToolbarContent>
                    {showFieldDetails
                        ? <>
                            <ToolbarNav onClick={() => setShowFieldDetails(false)}>Terug</ToolbarNav>
                            <AutoFocus>
                                <Styled.ToolbarTitle>Veld: {props.selectedFieldDefinition?.name}</Styled.ToolbarTitle>
                            </AutoFocus>
                            {getFieldDefinitionDetails(props.selectedFieldDefinition, props.icons)}
                        </>
                        : <AutoFocus>
                            <Styled.ToolbarTitle>{props.title}</Styled.ToolbarTitle>
                        </AutoFocus>
                    }
                </Styled.EdgeToolbarContent>
            </Styled.TabsPlaceholder>
        </Styled.LeftSideBarPanel>
    </EdgeToolbar>);
};

const getFieldDefinitionDetails = (fieldDefinition: Domain.Shared.FieldDefinition, icons: Record<string, Domain.Shared.SvgIcon>): JSX.Element[] => {
    if (!fieldDefinition) {
        return null;
    }

    return fieldDataDisplayOrder.map((fieldProperty) => {
        if (_.isUndefined(fieldDefinition[fieldProperty]) || _.isNull(fieldDefinition[fieldProperty]))
            return null;

        switch (fieldProperty) {
            case 'required': return (<TextElement id={`field-property-${fieldProperty}`} value={fieldDefinition[fieldProperty] ? 'Waar' : 'Nee'} label='Verplicht' />);
            case 'dataType': return (<TextElement id={`field-property-${fieldProperty}`} value={getFieldDisplayType(fieldDefinition)} label='Soort veld' />);
            case 'stringMaxLength': return (<TextElement id={`field-property-${fieldProperty}`} value={fieldDefinition[fieldProperty].toString()} label='Maximaal aantal tekens' />);
            case 'editorSettings':
                const editorSettings = JSON.parse(fieldDefinition.editorSettings) as Domain.Studio.FieldEditorControlSettings;
                if (!editorSettings.numericFormatString)
                    return null;
                const formatOption = formatOptionItems.find((option) => option.value === editorSettings.numericFormatString);
                return (<TextElement id={`field-property-${fieldProperty}`} value={formatOption?.label.toString()} label='Format' />);
            default: return null;
        }
    });
}

export { SideToolbar };