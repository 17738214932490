import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { IconSize, SVGIcon, palette } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import Styled from './index.styled';

type Props = {
    pageElement?: Domain.Publisher.PageElement,
    publicationElement: Domain.Publisher.PublicationElement;
    sitemap: Domain.Publisher.Sitemap;
    icons?: Record<string, Domain.Shared.SvgIcon>;
}

/**
 * Represents a UI component that renders the page title control.
 */
const PageTitleControl: React.FC<Props> = (props) => {
    const { pageid } = useParams<{ pageid: string }>();

    const contrastColor = props.publicationElement?.secondaryContrastColor
        ? props.publicationElement.secondaryContrastColor
        : palette.grey1;

    const pageNode = useMemo(() => props.sitemap
        ? [props.sitemap.node, ...props.sitemap.node.children]
            .flatten(item => item.children)
            .find(child => child.elementId === pageid)
        : null, [props.sitemap]);

    const pageIcon = pageNode?.iconId ? props.icons[pageNode.iconId] : null;

    /**
     * Gets the title of the page from the sitemap based on the pageid, 
     * otherwise it will render the placeholder.
     */
    const getTitle = (): string => {
        if (!props.sitemap) {
            return '[ Paginatitel ]';
        }

        if (!pageNode) {
            console.warn(`Could not find a sitemap node with the element id: ${pageid}`);
            return null;
        }
        return pageNode.title || pageNode.elementName;
    };

    const getIcon = (): React.ReactNode => {
        if (!props.icons) {
            return null;
        }

        if (!pageNode) {
            console.warn(`Could not find a sitemap node with the element id: ${pageid}`);
            return null;
        }

        return <SVGIcon value={pageIcon.svg} size={IconSize.large} color={contrastColor} />;
    };

    return (
        <>
            {pageIcon && <div className="site-page-icon">{getIcon()}</div>}
            <header className={`site-page-header${pageIcon ? ' header-with-page-icon' : ''}`}>
                <Styled.PageTitle contrastColor={contrastColor}>
                    {getTitle()}
                </Styled.PageTitle>
            </header>
        </>
    );
};

export default PageTitleControl;
