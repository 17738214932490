import * as Domain from '@liasincontrol/domain';
import _ from 'lodash';
import 'reflect-metadata';

/**
 * Represents a helper that is responsible for managing the fields of an element.
 */
export class FieldsHelper {

    /**
     * Maps a couple of fields to an object based on field definitions.
     * 
     * @param obj Defines the object that is the target.
     * @param fieldDefinitions Defines the list of field definitions.
     * @param fields Defines the field values.
     * @param complexFieldDefinitions Defines the list of complex field definitions.
     * @param complexFields Defines the complex field values.
     */
    public static mapObject = <T>(obj: T, fieldDefinitions: Domain.Shared.FieldDefinition[], fields: Record<string, string>, complexFieldDefinitions?: Domain.Shared.ComplexFieldDefinition[], complexFields?: Domain.Shared.ComplexField[]): void => {
        Object.keys(obj).forEach(propertyKey => {
            const propSystemId = Reflect.getMetadata(Domain.Shared.FieldDefinitionMetadataKey, obj, propertyKey);
            if (!propSystemId) {
                return;
            }

            const isComplexField = Reflect.getMetadata(Domain.Shared.IsComplexFieldMetadataKey, obj, propertyKey);

            const type = typeof obj[propertyKey];
            let value: string = undefined;

            if (!isComplexField) {
                value = FieldsHelper.mapField(propSystemId, fieldDefinitions, fields);
            } else if (complexFields?.length > 0 && complexFieldDefinitions?.length > 0) {
                value = complexFields.map((complexFieldSet) => {
                    const complexDefinition = complexFieldDefinitions.find((definition) => definition.id === complexFieldSet.complexFieldDefinitionId);
                    return FieldsHelper.mapField(propSystemId, complexDefinition.fields, complexFieldSet.fields);
                }).filter(fieldValue => fieldValue).pop();
            }

            if (!value) {
                return;
            }

            switch (type) {
                case 'boolean':
                    obj[propertyKey] = value.toLowerCase() === 'true';
                    break;
                case 'number':
                    obj[propertyKey] = Number.parseInt(value, 10);
                    break;
                case 'object':
                    obj[propertyKey] = JSON.parse(value);
                    break;
                default:
                    obj[propertyKey] = value;
                    break;
            }
        });
    };

    /**
     * Maps the field value of a field using field definitions.
     * 
     * @param systemId Defines the system id of the field that has to be mapped.
     * @param definitions Defines the list of field definitions.
     * @param fields Defines the field values.
     */
    public static mapField = (systemId: string, definitions: Domain.Shared.FieldDefinition[], fields: Record<string, string>): string => {
        const fieldDefinition = definitions.find(item => item.systemId === systemId);
        if (!fieldDefinition) {
            return null;
        }

        return fields[fieldDefinition.id];
    };

    /**
     * Maps a field to an option item.
     * @param element Defines the element.
     * @param fieldName Defines the name of the field that has to be mapped.
     * @param elementDefinition Defines the element definition.
     * 
     * @template T Actual type of the element.
     * 
     * @returns the option item that has been mapped.
     */
    public static mapFieldOption = <T>(element: T, fieldName: Extract<keyof T, string>, elementDefinition: Domain.Shared.ElementDefinition) => {
        const fieldSystemId = Reflect.getMetadata(Domain.Shared.FieldDefinitionMetadataKey, element, fieldName);
        const fieldDefinition = elementDefinition.fields.find(item => item.systemId === fieldSystemId);
        const option = fieldDefinition.optionItems.find(option => option.id === _.toString(element[fieldName]));

        return option;
    };

    /**
     * Extracts a record of [fieldId]:value for all the mapped systemId fields.
     * @param obj Defines the object that will provide the values.
     * @param fieldDefinitions Defines the list of field definitions.
     * @param complexFieldDefinitions Defines the list of complex field definitions.
     */
    public static reverseMapObject = <T>(obj: T, fieldDefinitions: Domain.Shared.FieldDefinition[], complexFieldDefinitions?: Domain.Shared.ComplexFieldDefinition[]): Record<string, string> => {
        const fields: Record<string, string> = {};

        Object.keys(obj).forEach(propertyKey => {
            const propSystemId = Reflect.getMetadata(Domain.Shared.FieldDefinitionMetadataKey, obj, propertyKey);
            if (!propSystemId) {
                return;
            }
            const isComplexField = Reflect.getMetadata(Domain.Shared.IsComplexFieldMetadataKey, obj, propertyKey);

            if (!isComplexField) {
                const definition = fieldDefinitions.find(item => item.systemId === propSystemId);
                fields[definition.id] = obj[propertyKey];
            } else if (complexFieldDefinitions?.length > 0) {
                const definition = complexFieldDefinitions.find(item => item.systemId === propSystemId);
                fields[definition.id] = obj[propertyKey];
            }
        });

        return fields;
    };
}
