
/**
 * Helper class that offers content-type manipulation methods.
 */
export class ContentTypeUtils {

    /**
     * Make sure to correctly translate any corner-case content types.
     * @param contentType Content type to translate.
     * @param sourceName Filename of the source
     */
    public static translate = (contentType: string, sourceName: string): string => {
        //
        //  On a Windows machine with Office installed, the browser will supply the non-standard office content-type for csv files (istead of the widely-known text/csv).
        //       
        if (window.navigator.platform.indexOf('Win') > -1) {
            if (sourceName?.toUpperCase().endsWith('.CSV') && contentType === 'application/vnd.ms-excel') return 'text/csv';
        }

        //
        // If type is falsy, check the source-name for an extension and return the matching mimetype from the extensionsWithMimeType record.
        //
        if (!contentType) {
            for (const key in extensionsWithMimeType) {
                const extensionFound = extensionsWithMimeType[key].some((value) => {
                    const reg = new RegExp("^.*\\"+value+"$");
                    return reg.exec(sourceName);
                })
                if (extensionFound) {
                    contentType = key;
                }
            }
        }

        return contentType;
    }
}

/**
 * List of mimetypes with allowed extensions.
 */
 const extensionsWithMimeType: Record<string, string[]> = {
    'text/csv': ['.csv'],
};