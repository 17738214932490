import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents a data accessor of finance settings.
 */
export class SettingsDataAccessor {
    /**
     * Gets the finance basic settings.
     */
    public static get = async (): Promise<AxiosResponse<Domain.Finance.Settings>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<Domain.Finance.Settings>(`/api/finance/settings`, config);
    };

    /**
    * Gets the finance used baseyears.
    */
    public static getBaseYears = async (): Promise<AxiosResponse<number[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<number[]>(`/api/finance/baseyear`, config);
    };
}
