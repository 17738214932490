import React from 'react';
import Styled from './index.styled';
import CloseIcon from '@mui/icons-material/Close';

export type Props = {
    error?: boolean,
    withCounter?: boolean
    children?: React.ReactNode;
    counter?: React.ReactNode;
    id?: string;
}


export const Feedback: React.FC<Props> = (props) => {
    const prefix = props.error ? <CloseIcon sx={{ fontSize: '1.1rem', position: 'absolute', left: 0 }} /> : null;
    return prefix ? (
        <Styled.Container id={props.id} error={props.error}>
            {prefix} &nbsp;<Styled.LeftSpan>{props.children}</Styled.LeftSpan>{props.withCounter ? <Styled.RightSpan>{props.counter}</Styled.RightSpan> : null}
        </Styled.Container>
    ) : (
        <Styled.Container id={props.id} error={props.error}>
            {props.children}{props.withCounter ? <Styled.RightSpan>{props.counter}</Styled.RightSpan> : null}
        </Styled.Container>
    );
};
