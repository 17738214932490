import { SystemFieldDefinitions } from '../FieldDefinition';
import { fieldDefinitionDecorator } from '../FieldDefinition/FieldDefinitionDecorator';

/**
 * Represents a workflow template element object, as returned by the bs-dynamic api.
 */
export class WorkflowTemplateElement {

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Name)
    readonly name: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.WorkflowIsActive)
    readonly isActive: boolean = true;
}
