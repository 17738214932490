import React from 'react';
import * as Domain from '@liasincontrol/domain';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import Styled from './index.styled';

type Props = {
    audit: Domain.Dto.Shared.AuditEvent[],
    attachment: Domain.Shared.Attachment,
    users: Domain.Shared.User[],
    disabled: boolean,
    onRemoveAttachment: (attachmentId: string) => void,
    onDownloadAttachment: (attachmentId: string) => void,
}

/**
 * Represents a UI component that renders an attachment item of a studio element.
 */
const StudioElementAttachmentItem: React.FC<Props> = (props) => {
    return (
        <Styled.AttachmentItem>
            <AttachFileIcon sx={{ fontSize: 16 }} />
            <Styled.AttachmentLabel onClick={() => props.onDownloadAttachment(props.attachment.id)}>
                {`${props.attachment.name}.${props.attachment.fileExtension}`}
            </Styled.AttachmentLabel>

            {!props.disabled && <Styled.RemoveAttachmentIcon onClick={() => props.onRemoveAttachment && props.onRemoveAttachment(props.attachment.id)} />}
        </Styled.AttachmentItem>
    );
};

export { StudioElementAttachmentItem };
