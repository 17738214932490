import styled from 'styled-components';

const Wrap = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

const styledComponents = { Wrap };

export default styledComponents;
