import React from 'react';
import { Button as TextButton } from 'devextreme-react/text-box';
import { InputField } from '../_shared';

export const TextField = (props) => (
    <InputField
        {...props}
        id={`input-text-${props.id}`}
        readOnly={true}
        valueChangeEvent='click'
    >
        {props.value &&
            <TextButton
                name="clear-button"
                location="after"
                options={{
                    icon: 'remove',
                    stylingMode: 'text',
                    disabled: false,
                    elementAttr: {
                        class: 'clear-icon'
                    },

                    onClick: () => {
                        props.onChange?.('');
                    }
                }}
            />
        }

        <TextButton
            name="custom-callback-button"
            location="after"
            options={{
                icon: props?.icon || 'copy',
                stylingMode: 'text',
                disabled: false,
                elementAttr: {
                    class: 'custom-icon'
                },
                onClick: props.onButtonClick,
            }}
        />

    </InputField>
);
