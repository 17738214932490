import { AxiosResponse } from 'axios';
import buildQuery from 'odata-query';
import HttpClient from '@liasincontrol/http-service';
import { DefinitionsHelper } from '@liasincontrol/core-service';
import { AppSettingsService } from '@liasincontrol/config-service';
import * as Domain from '@liasincontrol/domain';
import { oDataResponse } from '../oData';
import { DataAccessHostUtils } from '../DataAccessHostUtils';

/**
 * Represents the workflow template data access.
 */
export class WorkflowTemplate {

    /**
     * Gets the list of workflow templates.
     * 
     * @param query Defines the query that can be used for filtering the data on the server side.
     */
    public static get = async (query = ''): Promise<AxiosResponse<oDataResponse<Domain.Publisher.Element[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<oDataResponse<Domain.Publisher.Element[]>>(`/api/workflowdefinition/${query}`, config);
    };

    /**
     * Gets the lists of workflow templates and workflow template states.
     */
    public static getWorkflowsAndStates = async (): Promise<AxiosResponse<{ workflows: Domain.Dto.Shared.WorkflowTemplate[], workflowStates: Domain.Dto.Shared.WorkflowTemplateState[] }>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<{ workflows: Domain.Dto.Shared.WorkflowTemplate[], workflowStates: Domain.Dto.Shared.WorkflowTemplateState[] }>(`/api/workflowdefinition/all`, config);
    };

    /**
     * Gets the default oData selector to retrieve all workflow tempaltes..
     */
    public static getDefaultOdataQuery = (elementDefinition: Domain.Shared.ElementDefinition, count = false, skip?: number): string => {
        const selectableColumnNames = DefinitionsHelper.getSelectableFieldsForAPI(new Domain.Shared.WorkflowTemplateElement(), ['name'], elementDefinition);
        const query = buildQuery({
            select: selectableColumnNames.join(','),
            top: AppSettingsService.getAppSettings().Api.Odata.DefaultBackendLimitOverride,
            count: count,
            skip: skip
        });
        return query;
    };

    /**
     * Gets the list of workflow definition states for a specific workflow.
     * @param workflowTemplateId Id of the workflow definition to load
     */
    public static getStates = async (workflowTemplateId: string): Promise<AxiosResponse<oDataResponse<Domain.Publisher.Element[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<oDataResponse<Domain.Publisher.Element[]>>(`/api/workflowdefinition/${workflowTemplateId}/states`, config);
    };

    /**
     * Creates a new workflow definition.
     * @param workflowWithStates The workflow definition object.
     */
    public static create = async (workflowWithStates: Domain.Dto.Shared.CreateWorkflowWithState): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>('/api/workflowdefinition', workflowWithStates, config);
    };

    /**
     * Update an existing workflow definition.
     * @param workflowId The guid of the updated workflow.
     * @param workflowWithStates The workflow definition object.
     */
    public static update = async (workflowId: string, workflowWithStates: Domain.Dto.Shared.UpdateWorkflowWithState): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`/api/workflowdefinition/${workflowId}`, workflowWithStates, config);
    };

    /**
     * Delete a specific workflow definition.
     *
     * @param workflowId Defines the unique identifier of the workflowtemplate.
     */
    public static delete = async (workflowId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.delete<void>(`/api/workflowdefinition/${workflowId}`, config);
    };

    /**
     * Set the workflow isActive status.
     *
     * @param workflowId Defines the unique identifier of the workflowtemplate.
     * @param status Defines the workflow's status.
     */
    public static setActiveStatus = async (workflowId: string, status: boolean): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`/api/workflowdefinition/${workflowId}/status/`, { IsActive: status }, config);
    };

    /**
     * Reorder states in a workflow template.
     *
     * @param workflowId Defines the unique identifier of the workflowtemplate.
     * @param stateId Defines the moved state.
     * @param order Defines the states new order.
     */
    public static updateWorkflowStateOrder = async (workflowId: string, stateId: string, order: number = null): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`api/workflowdefinition/${workflowId}/states/order/`, { stateId: stateId, order: order }, config);
    };
}