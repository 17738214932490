import React from 'react';
import { Routes as PerformanceRoutes } from '@liasincontrol/performance';
import { SideMenuLayout } from '../Layouts';

/**
 * Represents an UI element that renders all Performance module sub-routes, in their specific Layouts.
 */
const PerformanceModuleRoutes: React.FC = () => {
    return (
        <SideMenuLayout
            look="normal"
            sideMenu={PerformanceRoutes.PerformanceMenu}
            components={PerformanceRoutes.Contents}
        />
    );
}

export default PerformanceModuleRoutes;
