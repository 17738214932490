import styled, { css } from 'styled-components';
import { px, Button } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';

const horizontalButtons = (inPopup?: boolean) => css`
    grid-auto-flow: column;    
    ${(p) => (inPopup ? css`top: 0;` : css`top: -${px(36)};`)};
    ${(p) => (inPopup ? css`left: 0;` : css`right: 0;`)};
    padding: 0;
    padding-bottom: ${px(12)};  
`;

const verticalButtons = (verticalOffset: number, inPopup?: boolean) => css`
    grid-auto-flow: row;
    top: ${px(verticalOffset * 36)};
    right: ${inPopup ? 0 : `calc(-${px(36)} - 0.5rem)`};   
    padding: 0;
    padding-top: ${px(7)}; 
    padding-right: ${px(12)}; 
`;

const ButtonContainerTopRight = styled.div < { direction?: number, buttonCount: number, inPopup: boolean, verticalOffset: number, hotizontalOffset?: number } > `
    position: ${(p) => (p.inPopup ? 'relative' : 'absolute')};
    display: grid;
    z-index: 999;    
    grid-gap: ${px(8)};        
    ${(p) => (p.direction === Domain.Publisher.Direction.Vertical ? verticalButtons(p.verticalOffset, p.inPopup) : horizontalButtons(p.inPopup))};
`;

const ActionButton = (styled(Button)`
        > span {
            padding: 0;
            height: ${px(40)};
            width: ${px(40)};
        }` ) as typeof Button;

export { ButtonContainerTopRight, ActionButton }