import styled from 'styled-components';
import { px } from '../style';
import { palette } from '../styleguide';

const Container = styled.div<{ previewMax: { width: number, height: number } }>`
    display: grid;
    grid-template-rows: ${(p) => p.previewMax.height ? px(p.previewMax.height) : `1fr`};
    grid-template-columns: ${(p) => p.previewMax.width ? px(p.previewMax.width) : `1fr`};
`;

const Border = styled.div<{ withBorder: boolean }>`
    border: ${(p) => p.withBorder ? `${px(2)} solid ${palette.grey4}` : 'none'};
    border-radius: ${px(4)};
    height: 100%;
    text-align: left;
    width: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: ${px(10)};
    }
    &::-webkit-scrollbar-track {
        width: ${px(10)};
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        width: ${px(10)};
        border-radius: ${px(10)};
        background-color: #ececf3;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #e3e3ec;
    }
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-corner {
        width: ${px(10)};
        height: ${px(1)};
        background-color: transparent;
    }
`;

const CheckList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
`;

const Li = styled.li`
    padding: ${px(14)} ${px(16)} ${px(8)};
    position: relative;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export { Container, Border, CheckList, Li };