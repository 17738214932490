import React, { useMemo } from 'react';
import { useParams, useLocation, Link } from 'react-router-dom';
import * as Domain from '@liasincontrol/domain';
import { palette } from '@liasincontrol/ui-basics';

type Props = {
    publicationElement: Domain.Publisher.PublicationElement,
    sitemap: Domain.Publisher.Sitemap,
    selectedElementId?: string,
}

/**
 * Represents a UI component that renders the breadcrumb control.
 */
const BreadcrumbsControl: React.FC<Props> = (props) => {
    const { pageid } = useParams<{ pageid: string }>();
    const { pathname } = useLocation();

    const pageNode = useMemo(() => props.sitemap
        ? [props.sitemap.node, ...props.sitemap.node.children]
            .flatten(item => item.children)
            .find(child => child.elementId === pageid)
        : null, [props.sitemap]);

    if (!props.publicationElement) {
        return null;
    }

    const ancestors = props.sitemap ? findAncestors(props.sitemap.node, pageid).reverse() : getFakeAncestors();

    const itemStyle: React.CSSProperties = {
        color: props.publicationElement.secondaryContrastColor ? props.publicationElement.secondaryContrastColor : palette.grey1,
        cursor: props.sitemap ? 'pointer' : 'default',
        pointerEvents: !props.selectedElementId ? 'auto' : 'none'
    };

    const pageHasIcon = !!pageNode?.iconId;

    return (
        <nav className={`site-breadcrumbs has-icon${pageHasIcon ? ' breadcrumbs-with-page-icon' : ''}`}>
            <div className="menu-item-list-container level-1">
                <ul className="menu-item-list level-1">
                    {ancestors.map((item) =>
                        <li key={item.elementId} className="menu-item level-1">
                            {props.sitemap ?
                                <Link
                                    to={pathname.replace(pageid, item.elementId)}
                                    className="menu-item-link level-1"
                                    style={itemStyle}>
                                    {item.elementName}
                                </Link> :
                                <span className="menu-item-link level-1" style={itemStyle}>
                                    {item.elementName}
                                </span>
                            }
                        </li>
                    )}
                </ul>
            </div>
        </nav>
    );
};

const getFakeAncestors = (): Domain.Publisher.SitemapNode[] => {
    return [
        { elementId: '1', elementName: 'Home' },
        { elementId: '2', elementName: "Lorem ipsum" },
        { elementId: '3', elementName: "Dolor et aset" }
    ] as Domain.Publisher.SitemapNode[];
};

/**
 * Finds ancestors for the given sitemap node.
 * 
 * @param root Defines the sitemap root node.
 * @param elementId Defines the element id of the sitemap node for that we are looking for.
 * @param ancestors Defines the temporary list of ancestors.
 * 
 * @returns The complete list of ancestors.
 */
const findAncestors = (root: Domain.Publisher.SitemapNode, elementId: string, ancestors: Domain.Publisher.SitemapNode[] = []): Domain.Publisher.SitemapNode[] => {
    if (root.elementId === elementId) {
        ancestors.push(root);
        return ancestors;
    }

    if (root.children && root.children.length) {
        if (root.children.some(child => findAncestors(child, elementId, ancestors) !== null)) {
            ancestors.push(root);
            return ancestors;
        }

        return null;
    }

    return null;
}

export default BreadcrumbsControl;