import { useReducer, Reducer, useEffect, useCallback } from 'react';
import { Breakpoint } from './breakpoints';

export const useResponsiveMode = ({ reservedPixels = 0 }: { reservedPixels?: number } = {}) => {
    const [rawWidth] = useViewportSize();
    const width = rawWidth - reservedPixels;
    let mode: Breakpoint | undefined;
    for (let i = 0, all = Breakpoint.all; i < all.length; i++) {
        if (all[i].is(width)) {
            mode = all[i];
        }
    }
    const is = useCallback((b: Breakpoint) => b.is(width), [width]);
    return { is, mode };
};

const useViewportSize = () => {
    const [size, handler] = useReducer(viewportSizeEventReducer, unknownSize, getWindowSize);
    useEffect(() => {
        handler(undefined);
        window.addEventListener(`focus`, handler);
        window.addEventListener(`resize`, handler);
        window.addEventListener(`scroll`, handler);
        return () => {
            window.removeEventListener(`focus`, handler);
            window.removeEventListener(`resize`, handler);
            window.removeEventListener(`scroll`, handler);
        };
    }, []);
    return size;
};

const unknownSize = [800, 600];

const viewportSizeEventReducer: Reducer<typeof unknownSize, UIEvent | undefined> = (prev) => {
    const [width, height] = getWindowSize();
    if (prev[0] !== width || prev[1] !== height) {
        return [width, height];
    }
    return prev;
};

const getWindowSize = () => {
    return [window.innerWidth, window.innerHeight];
};
