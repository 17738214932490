import { px } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

const DialogContainer = styled.div`
    border-radius: 6px;
    overflow-y: auto;
    padding: ${px(8)};
    max-height: 60vh;
`;

const CenteredDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`;

export { DialogContainer, CenteredDiv }; 