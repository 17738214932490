import React from 'react';
import { ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import * as Styled from './index.styled';

type Props = {
    isOpened: boolean,
    errors: Domain.Publisher.BuildError[],
    onClose?: () => void,
};

/**
 * Modal dialog for that shows the build errors when a publication is published.
 */
export const ErrorLogDialog: React.FC<Props> = (props) => {
    if (!props.isOpened) {
        return null;
    }

    return (
        <ModalDialog
            settings={{
                look: "interactive",
                title: 'Foutenlog',
                footer: (
                    <ModalDialogFooter
                        rightButtonText="Sluiten"
                        onRightButtonClick={props.onClose} />
                ),
            }}>
            <Styled.ErrorMessages>{props.errors.map((err, i) => (
                <React.Fragment key={i}>{err.code}: {err.detail}<br /></React.Fragment>
            ))}</Styled.ErrorMessages>
        </ModalDialog>
    );
};