import { AxiosResponse } from 'axios';
import * as Domain from '@liasincontrol/domain';
import HttpClient from '@liasincontrol/http-service';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents the publish profiles data accessor.
 */
export class PublishProfiles {
  /**
   * Creates a new publish profile.
   * @param createElement Defines the publish profile element that needs to be added.
   */
  public static createPublishProfile = async (createElement: Domain.Publisher.Element): Promise<AxiosResponse<string>> => {
    const config = await DataAccessHostUtils.getPubRequestConfig();

    return HttpClient.post<string>(`/api/profile`, createElement, config);
  };

  /**
   * Deletes a publish profile.
   * @param profileId Defines the unique identifier of the publish profile.
   */
  public static deletePublishProfile = async (profileId: string): Promise<AxiosResponse<void>> => {
    const config = await DataAccessHostUtils.getPubRequestConfig();

    return HttpClient.delete<void>(`/api/profile/${profileId}`, config);
  };

  /**
   * Updates a publish profile.
   * @param profileId Defines the unique identifier of the publish profile.
   * @param updateElement Defines the publish profile element that needs to be updated.
   */
  public static updatePublishProfile = async (profileId: string, updateElement: Domain.Publisher.Element): Promise<AxiosResponse<void>> => {
    const config = await DataAccessHostUtils.getPubRequestConfig();

    return HttpClient.put<void>(`/api/profile/${profileId}`, updateElement, config);
  };
}
