import styled from 'styled-components';
import { SelectBox } from 'devextreme-react/select-box';
import { palette } from '@liasincontrol/ui-basics';

const StyledSelectBox = styled(SelectBox)`
    background-color: ${palette.white} !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
`;

const styledComponents = { StyledSelectBox };

export default styledComponents;
