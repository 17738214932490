import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { EdgeToolbar, Bar, Button } from '@liasincontrol/ui-basics';

type Props = {
    id?: string,
    look?: 'editor' | 'default',
    isVisible: boolean,
    isValid?: boolean,
    disabled?: boolean,
    middleNode?: React.ReactNode,
    endNode?: React.ReactNode
    showSaveButton?: boolean,
    readonly?: boolean,
    onSave: () => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders the editing toolbar of a publication page.
 */
export const EditingToolbar: React.FC<Props> = (props) => {
    const onClick = (): void => {
        if (!props.isValid) return;

        props.onSave();
    }

    const disabled = props.disabled || !props.isValid;
    return (
        <EdgeToolbar edge="bottom" open={props.isVisible} look={props.look}>
            <Bar look="system">
                {props.readonly ? (
                    <Bar start>
                        <Button id={`btn-cancel-${props.id || ''}`} btnbase="primarybuttons" btntype="medium_noicon" onClick={props.onCancel}>
                            Sluiten
                        </Button>
                    </Bar>
                ) : (
                    <>
                        <Bar start>
                            <Button id={`btn-cancel-${props.id || ''}`} btnbase={props.look === 'editor' ? 'primarybuttons' : 'textbuttons'} btntype="medium_noicon" icon={props.look === 'editor' ? undefined : <CloseIcon />} onClick={props.onCancel}>
                                {props.look === 'editor' ? 'Annuleren' : 'Sluiten'}
                            </Button>
                        </Bar>
                        <Bar middle>
                            {props.middleNode ? props.middleNode : null}
                        </Bar>
                        <Bar end>
                            {props.endNode ? props.endNode : null}
                            {props.showSaveButton &&
                                <Button id={`btn-save-${props.id || ''}`} btnbase={props.isValid ? 'primarybuttons' : 'ghostbuttons'} btntype="medium_noicon" onClick={onClick} disabled={disabled}>
                                    Opslaan
                                </Button>
                            }
                        </Bar>
                    </>
                )}
            </Bar>
        </EdgeToolbar>
    );
};

EditingToolbar.defaultProps = {
    showSaveButton: true,
    isValid: true,
};
