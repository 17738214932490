import { ValidationErrorData, ValueType } from './Types';
import { ValidationUtils } from './ValidationUtils';

/**
 * Type definition for external method that can be passed to a validator instance, to check for errors based on any form value.
 * @param value: Value being set.
 * @param formFields: Field values for all other form fields.
 */
export type CustomContextValidator<T> = (value: T, formFields: Record<string, ValueType>) => ValidationErrorData[];

/**
 * Defines the basic restrictions that can be placed on any validator.
 */
export interface BaseValidationRestriction {
    required?: boolean;

    requiredText?: string;
}

/**
 * Represents the base for all field validators.
 */
export abstract class BaseValidator<T extends ValueType> {
    protected constructor(required = false, requiredText = 'Veld vereist.') {
        this.baseRestrictions = {
            required,
            requiredText,
        };
    }

    protected baseRestrictions: BaseValidationRestriction = {};

    /**
     * Checks the provided value against all defined restrictions. Returns an empty array in case of success.
     *
     * @param value Value to check.
     */
    public validate(value: T): ValidationErrorData[] {
        const errors: ValidationErrorData[] = [];

        if (ValidationUtils.isEmpty(value) && this.baseRestrictions.required) {
            errors.push({ error: this.baseRestrictions.requiredText });
            return errors;
        }

        if (typeof value === 'string' && this.baseRestrictions.required && !/\S/.test(value)) {
            errors.push({ error: 'Niet toegestaan om alleen spaties te gebruiken.' });
        }

        return errors;
    }
}
