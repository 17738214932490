import styled from 'styled-components';
import { px } from '@liasincontrol/ui-basics';

const PublishContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: ${px(32)};
    > p {
        margin: 0 0 ${px(8)} 0;
        line-height: ${px(24)};
    }
`;


const styledComponents = { PublishContent };
export default styledComponents;
