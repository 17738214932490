/**
 * Represents a field definition.
 */
export class FieldDefinition {

    readonly dataType: FieldDataType;

    readonly id: string;

    readonly name: string;

    readonly required: boolean;

    readonly systemId: string;

    readonly attachmentAllowedFileTypes?: Array<string>;

    readonly attachmentMaxFileSize?: number;

    readonly booleanFalseLabel?: string;

    readonly booleanStartValue?: boolean;

    readonly booleanTrueLabel?: string;

    readonly dateMaxValue?: string;

    readonly dateMinValue?: string;

    readonly dateStartValue?: string;

    readonly decimalMaxValue?: number;

    readonly decimalMinValue?: number;

    readonly decimalStartValue?: number;

    readonly editorSettings?: string;

    readonly helpText?: string;

    readonly helpTextTitle?: string;

    readonly integerMaxValue?: number;

    readonly integerMinValue?: number;

    readonly integerStartValue?: number;

    readonly label?: string;

    readonly optionItems?: FieldDefinitionOptionItem[];

    readonly optionMaxSelectionCount?: number;

    readonly optionStartValue?: string;

    readonly relationElementDefinitionId?: string;

    readonly stringMaxLength?: number;

    readonly stringType?: StringType;

}

/**
 * Represents an option item object from the option items collection for a field definition 'Option' type.
 */
export class FieldDefinitionOptionItem {

    readonly id: string;

    name: string;

    order: number;

    value: number;

    color?: number;

    icon?: string;

}

export enum FieldDataType {
    String = "String",
    Integer = "Integer",
    Decimal = "Decimal",
    DateTimeOffset = "DateTimeOffset",
    Boolean = "Boolean",
    Option = "Option",
    Relation = "Relation",
    Attachment = "Attachment",
}

export enum StringType {
    None = "None",
    SingleLine = "SingleLine",
    MultiLine = "MultiLine",
    Html = "Html",
    Json = "Json",
    Hyperlink = "Hyperlink",
}