import React, { useEffect, useMemo, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { GridColumn, LsGrid, createSource } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { Finance } from '@liasincontrol/data-service';
import { useDebounce } from '../../shared/hooks/useDebounce';
import { FilterModel } from './Filters';

type Props = {
    journalElementKinds: Domain.Finance.JournalElementKind[],
    filter: FilterModel;
    pageSize?: number;

    onError: (err: any) => void,
    onRowClick?: (journalCombination: Domain.Finance.JournalCombination) => void,
};
//
const removeEmptyValues = (obj: any) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (!!value) {
            acc[key] = value;
        }
        return acc;
    }, {});
};

export const JournalCombinationsGrid: React.FC<Props> = (props) => {
    const [columns, setColumns] = useState<GridColumn<Domain.Finance.JournalCombination>[]>();
    const debouncedExternalCode = useDebounce(500, props.filter.elementCode);
    const debouncedName = useDebounce(500, props.filter.elementName);

    const customDataSource = useMemo(() => {
        if (props.filter.baseYear === null || props.filter.baseYear === undefined) {
            return;
        }

        const selectedColumns = Object.keys(new Domain.Finance.JournalCombination());
        const filterBy: { [x: string]: any; }[] = props.filter.benefitCaption ? [{ 'isBenefit': props.filter.benefitCaption === 'Benefit' }] : [];
        const params = removeEmptyValues(props.filter);

        return createSource({
            keyExpr: "elementCombinationRK",
            paginate: true,
            filter: filterBy,
            selectedColumns: selectedColumns,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            dataSourcePromise: (query) => Finance.JournalCombinationDataAccessor.get(params, query)
        });
    }, [props.journalElementKinds, props.filter, debouncedExternalCode, debouncedName]);

    useEffect(() => {
        if (!props.journalElementKinds) {
            return;
        }
        const elementKinds = props.journalElementKinds.sort((a, b) => a.orderIndex - b.orderIndex);
        let columns: GridColumn<Domain.Finance.JournalCombination>[] = [{
            name: 'isBenefit',
            title: 'Rubriek',
            width: 125,
            dataType: 'boolean',
            renderCustom: (item) => <>{item.data.isBenefit ? 'Baten' : 'Lasten'}</>,
        }];
        columns = columns.concat(
            elementKinds.map((kind, i) =>
            ({
                name: `element${kind.orderIndex}Name` as keyof Domain.Finance.JournalCombination,
                title: kind.name,
                dataType: 'string',
                allowSorting: true,
                defaultSortIndex: i,
                defaultSortOrder: "asc",
            } as GridColumn<Domain.Finance.JournalCombination>)
            )
        );

        columns = columns.concat([
            {
                name: 'baseYear',
                title: 'Basisjaar',
                width: 125,
            },
            {
                name: 'isReserve',
                title: 'Reserve',
                width: 125,
                dataType: 'boolean',
            },
            {
                name: 'isClosed',
                title: 'Afgesloten',
                width: 125,
                dataType: 'boolean',
            },
            {
                name: 'isStructural',
                title: 'Structureel',
                width: 125,
                dataType: 'boolean',
            }
        ]);
        setColumns(columns);
    }, [props.journalElementKinds]);

    return <LsGrid
        dataSource={customDataSource}
        columns={columns}
        onClickRow={props.onRowClick}
        paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
        enableColumnChooser={true}
    />;

};
