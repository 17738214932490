import React, { useMemo, useState } from 'react';
import { IconSize, ModalDialog, ModalDialogFooter, SVGIcon } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import { DialogContainer, CenteredDiv } from './index.styled';
import { IconSelectElement } from '@liasincontrol/ui-elements';

type Props = {
    icons: Record<string, Domain.Shared.SvgIcon>,
    currentIconId?: string,
    onChange: (iconId: string) => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders a dialog to choose a page icon from the available icons.
 */
export const PageIconDialog: React.FC<Props> = (props) => {
    const [iconId, setIconId] = useState<string>(props.currentIconId);

    const availableIcons = useMemo(() => {
        const icons = Object.values(props.icons);
        if (props.currentIconId) {
            const idx = icons.findIndex(i => i.id === props.currentIconId);
            return [icons[idx], ...icons.slice(0, idx), ...icons.slice(idx + 1)];
        }
        return icons;
    }, [props.icons, props.currentIconId]);

    return (
        <ModalDialog
            settings={{
                width: 768,
                look: "interactive",
                title: "Icoon op pagina",
                footer: (
                    <ModalDialogFooter
                        leftButtonText="Sluiten"
                        onLeftButtonClick={() => { setIconId(undefined); props.onCancel(); }}
                        rightButtonText='Opslaan'
                        onRightButtonClick={() => { props.onChange(iconId); }}
                    />
                )
            }}>
            <DialogContainer>
                <IconSelectElement
                    id='icon-field'
                    label='Selecteer icoon'
                    direction='horizontal'
                    items={availableIcons}
                    value={iconId}
                    height='300px'
                    baseWidth={IconSize.large}
                    baseHeight={IconSize.large}
                    keyExpr='id'
                    searchEnabled={true}
                    searchExpr='keywords'
                    itemRender={(item) => (<CenteredDiv>
                        <SVGIcon value={item.svg} size={IconSize.large} color={'#000000'} />
                    </CenteredDiv>
                    )}
                    editorSettings={{
                        disabled: false,
                        validationErrors: [],
                        restrictions: { required: false },
                        onChange: (item) => {
                            setIconId(item?.id);
                        }
                    }}
                />
            </DialogContainer>
        </ModalDialog>
    );
};
