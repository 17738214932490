
/* localisation: messages for scrollview + popup + list */

export const Dictionary = {
    "nl": {
        "dxList-pullingDownText": "Trek naar beneden om te vernieuwen...",
        "dxList-pulledDownText": "Laat los om te vernieuwen...",
        "dxList-refreshingText": "Vernieuwen...",
        "dxList-pageLoadingText": "Laden...",
        "dxList-nextButtonText": "Meer",
        "dxList-selectAll": "Selecteer Alles",
        "dxListEditDecorator-delete": "Verwijderen",
        "dxListEditDecorator-more": "Meer",

        "dxScrollView-pullingDownText": "Trek naar beneden om te vernieuwen...",
        "dxScrollView-pulledDownText": "Laat los om te vernieuwen...",
        "dxScrollView-refreshingText": "Vernieuwen...",
        "dxScrollView-reachBottomText": "Laden...",


        "Yes": "Ja",
        "No": "Nee",
        "Cancel": "Annuleren",
        "Close": "Sluiten",
        "Clear": "Wissen",
        "Done": "Klaar",
        "Loading": "Laden...",
        "Select": "Kiezen...",
        "Search": "Zoeken",
        "Back": "Terug",
        "OK": "OK",
    }
};