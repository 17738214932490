import React, { useEffect, useState } from 'react';
import { SelectElement } from '@liasincontrol/ui-elements';
import { IDataItemProps } from '@liasincontrol/ui-basics';
import { ILsColumnProps } from '@liasincontrol/ui-devextreme';
import { DataSourceControlsUtils } from '../../../../../../../../helpers';
import { ValidationErrorData } from '@liasincontrol/core-service';

type Props = {
    id: string,
    label: string,
    value: string,
    validationErrors?: ValidationErrorData[],
    disabled?: boolean,
    schemaFileId: string,
    schemaFilter?: (value: ILsColumnProps, index: number, array: ILsColumnProps[]) => boolean,
    onChange: (value: string) => void,
    onLoadAttachment: (id: string) => Promise<Blob>,
}

/**
 * Represents a UI component that renders an editor.
 */
const DataSourceEditor: React.FC<Props> = (props) => {
    const [optionItems, setOptionItems] = useState<IDataItemProps<string>[]>([]);
    const { schemaFileId, schemaFilter, onLoadAttachment } = props;

    useEffect(() => {
        if (schemaFileId) {
            onLoadAttachment(schemaFileId)
                .then((blob) => blob.text())
                .then((jsonText) => {
                    const schemaFields = DataSourceControlsUtils.mapsDataSourceColumnFields(JSON.parse(jsonText));
                    const options = schemaFields.filter(schemaFilter ? schemaFilter : () => true)
                        .map((field) => ({ value: field.dataField, label: field.dataField }));
                    setOptionItems(options);
                });
        } else {
            setOptionItems([]);
        }
    }, [onLoadAttachment, schemaFilter, schemaFileId]);

    return (
        <SelectElement<IDataItemProps<string>>
            displayExpr='label'
            id={`ds-editor-select-${props.id}`}
            label={props.label}
            searchable={false}
            clearable={true}
            optionItems={optionItems}
            editorSettings={{
                disabled: props.disabled,
                restrictions: {},
                validationErrors: props.validationErrors,
                onChange: (e) => props.onChange(e?.value)
            }}
            value={optionItems.find(i => i.value === props.value)}
        />
    );
}

export { DataSourceEditor };