import { px } from './pixel';

// tslint:disable:variable-name no-magic-numbers

const __cssFont = new Map<string, string>();

export const cssFontLight = (
  pxSize: number,
  pxLine: number,
  spacing: number
) => {
  // TODO: does caching the css instance make a difference?
  const existing = __cssFont.get(`300;${pxSize};${pxLine};${spacing}`);
  if (existing) {
    return existing;
  }
  const style = `font: 300 ${px(pxSize)}/${px(
    pxLine
  )} 'Nunito'; letter-spacing: ${spacing / 1000}em;`;
  __cssFont.set(`300;${pxSize};${pxLine};${spacing}`, style);
  return style;
};

export const cssFontNormal = (
  pxSize: number,
  pxLine: number,
  spacing: number
) => {
  // TODO: does caching the css instance make a difference?
  const existing = __cssFont.get(`400;${pxSize};${pxLine};${spacing}`);
  if (existing) {
    return existing;
  }
  const style = `font: 400 ${px(pxSize)}/${px(
    pxLine
  )} 'Nunito'; letter-spacing: ${spacing / 1000}em;`;
  __cssFont.set(`400;${pxSize};${pxLine};${spacing}`, style);
  return style;
};

export const cssFontSemiBold = (
  pxSize: number,
  pxLine: number,
  spacing: number
) => {
  // TODO: does caching the css instance make a difference?
  const existing = __cssFont.get(`600;${pxSize};${pxLine};${spacing}`);
  if (existing) {
    return existing;
  }
  const style = `font: 600 ${px(pxSize)}/${px(
    pxLine
  )} 'Nunito'; letter-spacing: ${spacing / 1000}em;`;
  __cssFont.set(`600;${pxSize};${pxLine};${spacing}`, style);
  return style;
};

export const cssFontBold = (
  pxSize: number,
  pxLine: number,
  spacing: number,
  fontFamily = 'Nunito',
) => {
  // TODO: does caching the css instance make a difference?
  const existing = __cssFont.get(`700;${pxSize};${pxLine};${spacing}`);
  if (existing) {
    return existing;
  }
  const style = `font: 700 ${px(pxSize)}/${px(
    pxLine
  )} '${fontFamily}'; letter-spacing: ${spacing / 1000}em;`;
  __cssFont.set(`700;${pxSize};${pxLine};${spacing}`, style);
  return style;
};

export const cssFontExtraBold = (
  pxSize: number,
  pxLine: number,
  spacing: number
) => {
  // TODO: does caching the css instance make a difference?
  const existing = __cssFont.get(`800;${pxSize};${pxLine};${spacing}`);
  if (existing) {
    return existing;
  }
  const style = `font: 800 ${px(pxSize)}/${px(
    pxLine
  )} 'Nunito'; letter-spacing: ${spacing / 1000}em;`;
  __cssFont.set(`800;${pxSize};${pxLine};${spacing}`, style);
  return style;
};
