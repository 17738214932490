
/* localisation: messages for calendar */

export const Dictionary = {
    "nl": {
        "dxCalendar-todayButtonText": "Vandaag",
        "dxCalendar-ariaWidgetName": "Kalender",
        "dxCalendar-previousMonthButtonLabel": "Vorige maand",
        "dxCalendar-previousYearButtonLabel": "Vorige jaar",
        "dxCalendar-previousDecadeButtonLabel": "Vorige decennium",
        "dxCalendar-previousCenturyButtonLabel": "Vorige eeuw",
        "dxCalendar-nextMonthButtonLabel": "Volgend maand",
        "dxCalendar-nextYearButtonLabel": "Volgend year",
        "dxCalendar-nextDecadeButtonLabel": "Volgend decennium",
        "dxCalendar-nextCenturyButtonLabel": "Volgend eeuw",
        "dxCalendar-captionMonthLabel": "Maand selectie",
        "dxCalendar-captionYearLabel": "Jaar selectie",
        "dxCalendar-captionDecadeLabel": "Decennium selectie",
        "dxCalendar-captionCenturyLabel": "Eeuw selectie",
    }
};