import styled from 'styled-components';
import { px, palette, ElementLabel } from '@liasincontrol/ui-basics';

const Wrapper = styled.div<{ withBackGround: boolean; withPadding: boolean }>`
  display: flex;
  align-items: center;
  padding: ${({ withPadding }) => (withPadding ? `0 ${px(16)}` : `0 0}`)};
  background-color: ${({ withBackGround }) => (withBackGround ? `${palette.grey4}` : 'unset')};
  position: relative;
  flex-basis: 100%;
`;

const Label = styled(ElementLabel)`
  margin-left: ${px(8)};
  flex: 1 0 auto;
  font-weight: 500;
`;

const Column = styled(ElementLabel)`
  overflow: hidden;
  flex: 0 0 auto;
  width: 15%;
  text-align: center;
  display: flex;
  flex-grow: 1;
  align-items: center;
`;

const IconText = styled.div`
  padding: 0 ${px(4)};
  white-space: pre-wrap;
`;

const ColoredToolboxIconWrapper = styled.div<{ color?: string }>`
  color: ${({ color }) => (color ? `${color}` : 'inherit')};
`;

export { Wrapper, Label, Column, ColoredToolboxIconWrapper, IconText };
