import styled from 'styled-components';
import { cssBreakpoint, px } from '@liasincontrol/ui-basics';

const OptionWrapper = styled.div`
  display: grid;
  ${cssBreakpoint('xs', 's')`
    grid-template-columns: repeat(2, 1fr);
  `};
  ${cssBreakpoint('m')`
    grid-template-columns: repeat(3, 1fr);
  `};
  ${cssBreakpoint('l', 'xl')`
    grid-template-columns: repeat(4, 1fr);
  `};
  column-gap: ${px(10)};
`;

const OptionsWrapper = styled.div`
  padding-top: ${px(16)};
`;

const styledComponents = { OptionsWrapper, OptionWrapper };

export default styledComponents;
