import React, { useMemo, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';
import { GridColumn, createSource, LsGrid } from '@liasincontrol/ui-devextreme';
import { Finance } from '@liasincontrol/data-service';
import { AppSettingsService } from '@liasincontrol/config-service';

type Props = {
  baseYear: number,
  onCancel: () => void,
  onError: (exception: any) => void,
  onKindSelected?: (budgetJournalKind: Domain.Finance.BudgetJournalKind) => void
};

export const SelectBudgetJournalKind: React.FC<Props> = (props) => {
  const [selectedKind, setSelectedKind] = useState<Domain.Finance.BudgetJournalKind>();
  const columns: GridColumn<Domain.Finance.BudgetJournalKind>[] = [{ name: 'name', title: 'Journaalsoort' }];

  const customDataSource = useMemo(() => {
    return createSource({
      paginate: true,
      pageSize: AppSettingsService.getAppSettings().General.PageSize,
      filter: { 'isActive': true, 'baseYear': props.baseYear },
      selectedColumns: ['id', 'name'],
      dataSourcePromise: Finance.BudgetJournalKindDataAccessor.getAll
    });
  }, []);

  return (
    <ModalDialog
      settings={{
        width: 1300,
        look: 'interactive',
        title: 'Kies een journaalsoort',
        footer: <ModalDialogFooter
          leftButtonText='Sluiten'
          onLeftButtonClick={props.onCancel}
          rightButtonDisabled={!selectedKind}
          rightButtonText='Opslaan'
          onRightButtonClick={() => props.onKindSelected(selectedKind)} />,
      }}
    >
      <LsGrid
        dataSource={customDataSource}
        columns={columns}
        enableColumnChooser={false}
        paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
        showRowLines={true}
        onClickRow={(item: Domain.Finance.BudgetJournalKind) => {
          setSelectedKind(item)
        }}
        focusedRowKeys={[selectedKind?.id]}
        onDataError={props.onError}
      />
    </ModalDialog>
  );
};
