import TextBox from "devextreme-react/text-box";
import { SelectElementTemplateProps } from '@liasincontrol/ui-elements';
import { IDataItemProps } from '@liasincontrol/ui-basics';
import React from 'react';
import Styled from '../index.styled';

export const typeInputCustomOptions = (customValueProps: IDataItemProps<string>, templateProps?: SelectElementTemplateProps) => {
    return (
        <Styled.SingleValueWrapper>
            {templateProps?.isFieldTemplate ?
                <TextBox stylingMode='outlined' value={customValueProps?.label} />
                :
                <>
                    <Styled.SingleValueLabel>{customValueProps?.label}</Styled.SingleValueLabel>
                    {(customValueProps as any)?.required && <Styled.MandatoryLabel>*</Styled.MandatoryLabel>}
                </>
            }
        </Styled.SingleValueWrapper>
    );
};
