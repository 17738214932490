import Toolbar, { Item } from 'devextreme-react/toolbar';
import React, { useMemo } from "react";
import { palette } from "@liasincontrol/ui-basics";
import { textAssistantSvgIconStringWithColor } from "./TextAssistantAI";

export interface MultilineToolbarProps {
    hidden: boolean;
    onClick: () => void;
}

export const MultilineToolbarContainer: React.FC<MultilineToolbarProps> = (props) => {
    const textAssistantButtonOptions = useMemo(() => ({
        onClick: props.onClick,
        icon: textAssistantSvgIconStringWithColor(palette.grey3),
    }), [props.onClick]);

    return (
        <div
            onMouseDown={(event) => event.preventDefault()}
            onPointerDown={(event) => event.preventDefault()}
            className={`toolbar--light ${props.hidden ? 'toolbar-hidden' : ''}`}
        >
            <Toolbar>
                <Item widget="dxButton" options={textAssistantButtonOptions} />
            </Toolbar>
        </div>
    )
}