import React from 'react';
import { IntegerEditor } from './index.editor';
import { IntegerProps } from './index.props';
import { TextViewer } from '../../shared/TextViewer';

/**
 * Represents a UI component that renders an Integer element.
 */
export const IntegerElement: React.FC<IntegerProps> = (props) => {
    return props.editorSettings && !props.editorSettings.disabled ? <IntegerEditor {...props} /> : <TextViewer id={props.id} label={props.label} helpText={props.helpText} value={props.value} />;
};