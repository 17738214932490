import { Element } from '../../Publisher/Element/index';
import { fieldDefinitionDecorator, SystemElementDefinitions, SystemFieldDefinitions } from '../../Shared';

/**
 * Represents a performance hierarchy item.
 */
class HierarchyItem {

    public element: Element;

    public parentHierarchyItemId?: string;

    public order: number;

    public workflowStateId?: string;

    public assignedUserId?: string;

}

/**
 * Represents the type of a performance hierarchy item.
 */
type HierarchyItemElementType =
    SystemElementDefinitions.Performance.MeasureMoment
    | SystemElementDefinitions.Performance.Policy
    | SystemElementDefinitions.Performance.Goal
    | SystemElementDefinitions.Performance.Achievement
    | SystemElementDefinitions.Performance.Activity
    | SystemElementDefinitions.Performance.Indicator
    | SystemElementDefinitions.Performance.PerformanceItemRight;

/**
 * Represents a performance hierarchy item element.
 */
class HierarchyItemElement {

    id: string;

    parentId?: string;

    type: HierarchyItemElementType;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Performance.Number)
    number: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Performance.Name)
    name: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Performance.Description)
    description: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Performance.Explanation)
    explanation: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Performance.Result)
    result: string;

}

export { HierarchyItem, HierarchyItemElement, HierarchyItemElementType };