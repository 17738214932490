import styled from 'styled-components';
import { px } from '@liasincontrol/ui-basics';

const Link = styled.a`
    text-decoration: none;
    height: inherit ;
`;

const Content = styled.div`    
    display: flex;
    align-items: center;
    align-content: center;
    height: calc(100% - ${px(24)});
    padding: ${px(8)} 0;
    text-align: center;
    text-align: -webkit-center;
    font-weight: 200;
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 1rem;
`;

const CardHeaderWrapper = styled.div<{ active: boolean }>`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: clip; 
    & > button {
        width: 100%;
        font-weight: 600;
        font-size: 1.1rem;
        color: black;
        ${(p) => p.active && 'border-bottom: 3px solid black;'}
    }
`;

const CenteredDiv = styled.div`
    width: 100%;
    height: 100%;
    padding: ${px(16)};
    text-align: center;
    text-align: -webkit-center;
    font-weight: 200;
`;

const styledComponents = { Link, Content, CardHeaderWrapper, Header, CenteredDiv };

export default styledComponents;