import React from 'react';
import { ToggleField } from '@liasincontrol/ui-basics';
import { ToggleProps } from './index.props';

/**
 * Represents a UI component that renders a toggle that is tied to a boolean value.
 */
export const ToggleEditor: React.FC<ToggleProps> = (props) => {
    return (
        <ToggleField
            id={props.id}
            key={props.id}
            label={props.label}
            disabled={props.editorSettings.disabled}
            success={undefined}
            error={undefined}
            value={{
                textOn: props.booleanTrueLabel,
                textOff: props.booleanFalseLabel,
                isSmall: props.isSmall,
                checked: props.value ? props.value : props.booleanStartValue
            }}
            onChange={(value) => props.editorSettings?.onChange(value.checked)}
        />
    );
};