import React from 'react';
import { EdgeToolbar, Bar, Button, DeviceType, MonitorButton, TabletButton, PhoneButton } from '@liasincontrol/ui-basics';
import CloseIcon from '@mui/icons-material/Close';
import SmartphoneIcon from '@mui/icons-material/Smartphone';
import MonitorIcon from '@mui/icons-material/Monitor';
import TabletIcon from '@mui/icons-material/Tablet';

type Props = {
    look?: 'editor' | 'default',
    device: DeviceType,
    isVisible: boolean,
    onDeviceChanged: (device: DeviceType) => void,
    onCancel: () => void,
}

/**
 * Represents a UI component that renders the preview toolbar of a publication page.
 */
export const PreviewToolbar: React.FC<Props> = (props: Props) => {
    return (
        <EdgeToolbar edge="bottom" open={props.isVisible} look={props.look}>
            <Bar>
                <Bar start>
                    <Button id="btn-cancel" btnbase="primarybuttons" btntype="medium_icon" icon={<CloseIcon />} onClick={props.onCancel}>
                        Sluiten
                    </Button>
                </Bar>
                <Bar middle>
                    <MonitorButton
                        id="btn-monitor"
                        btnbase="iconbuttons"
                        btntype="medium_admin"
                        icon={<MonitorIcon />}
                        onClick={() => {
                            props.onDeviceChanged('desktop');
                        }}
                        className={props.device === 'desktop' ? 'active' : ''}
                    />
                    <TabletButton
                        id="btn-tablet"
                        btnbase="iconbuttons"
                        btntype="medium_admin"
                        icon={<TabletIcon />}
                        onClick={() => {
                            props.onDeviceChanged('tablet');
                        }}
                        className={props.device === 'tablet' ? 'active' : ''}
                    />
                    <PhoneButton
                        id="btn-phone"
                        btnbase="iconbuttons"
                        btntype="medium_admin"
                        icon={<SmartphoneIcon />}
                        onClick={() => {
                            props.onDeviceChanged('phone');
                        }}
                        className={props.device === 'phone' ? 'active' : ''}
                    />
                </Bar>
            </Bar>
        </EdgeToolbar>
    );
};
