import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { Heading1, LayoutField, LayoutForm, PageTitle, ResetZIndex, Section, Text } from '@liasincontrol/ui-basics';
import { AnyFormData, TextValidator, ValidatorsDictionary, ValueType, FormMode, FormInfo, FormHelper, ValidationErrorData, BasicValidator } from '@liasincontrol/core-service';
import { TextElement } from '@liasincontrol/ui-elements';
import { DxColumn, DxTreeList } from '@liasincontrol/ui-devextreme';
import { License } from '@liasincontrol/auth-service';

const PermissionCategories = {
    1: 'ApplicatieBeheer',
    2: 'Publisher',
    3: 'Performance',
    4: 'Studio',
    5: 'Finance',
};

const PermissionLicenses = {
    2: License.Publisher,
    3: License.Performance,
    4: License.Studio,
    5: License.Finance,
};

const Permissions: { key: Domain.Shared.UserPermissions, label: string, category: number }[] = [
    { key: Domain.Shared.UserPermissions.ManageUsers, label: 'Beheren gebruikersgroepen', category: 1 },
    { key: Domain.Shared.UserPermissions.ManageRoles, label: 'Beheren rollen', category: 1 },
    { key: Domain.Shared.UserPermissions.ManageDataStores, label: 'Beheren gegevensverbindingen', category: 1 },
    { key: Domain.Shared.UserPermissions.ManageWorkflows, label: 'Beheren workflow', category: 1 },
    { key: Domain.Shared.UserPermissions.ManageMeasureMoments, label: 'Beheren momenten', category: 1 },

    { key: Domain.Shared.UserPermissions.ManagePublication, label: 'Beheren publicaties', category: 2 },
    { key: Domain.Shared.UserPermissions.PublishPublication, label: 'Publiceren', category: 2 },
    { key: Domain.Shared.UserPermissions.ManagePublicationSitemap, label: 'Beheren site indeling', category: 2 },
    { key: Domain.Shared.UserPermissions.ManagePublicationTemplates, label: 'Beheren sjablonen', category: 2 },
    { key: Domain.Shared.UserPermissions.ManagePublicationDataSources, label: 'Beheren databronnen', category: 2 },
    { key: Domain.Shared.UserPermissions.RefreshPublicationDataSources, label: 'Verversen databronnen', category: 2 },
    { key: Domain.Shared.UserPermissions.ManagePublicationProfiles, label: 'Beheren profielen', category: 2 },
    { key: Domain.Shared.UserPermissions.ApplyPublicationProfiles, label: 'Instellen profiel op pagina', category: 2 },
    { key: Domain.Shared.UserPermissions.ManageTasks, label: 'Beheren taak', category: 2 },
    { key: Domain.Shared.UserPermissions.TasksOverview, label: 'Raadplegen voortgang', category: 2 },

    { key: Domain.Shared.UserPermissions.ManagePerformanceElementDefinitions, label: 'Beheren entiteiten', category: 3 },
    { key: Domain.Shared.UserPermissions.GlobalPerformanceElementsContributer, label: 'Beheren elementen', category: 3 },

    { key: Domain.Shared.UserPermissions.ManageStudioElementDefinitions, label: 'Beheren elementdefinities', category: 4 },
    { key: Domain.Shared.UserPermissions.ManageStudioHierarchyDefinitions, label: 'Beheren hiërarchiedefinities', category: 4 },
    { key: Domain.Shared.UserPermissions.ManageStudioHierarchies, label: 'Beheren hiërarchie', category: 4 },
    { key: Domain.Shared.UserPermissions.GlobalStudioElementsContributer, label: 'Beheren elementen', category: 4 },

    { key: Domain.Shared.UserPermissions.ViewFinanceBudgetOverview, label: 'Raadplegen budgetoverzicht', category: 5 },
    { key: Domain.Shared.UserPermissions.ManageFinance, label: 'Beheren Finance', category: 5 },
    { key: Domain.Shared.UserPermissions.ChangeBudgetJournalWorkflowState, label: 'Handmatig aanpassen workflowstatus', category: 5 },
    { key: Domain.Shared.UserPermissions.ViewFinanceBudgetDevelopment, label: 'Raadplegen financiële ontwikkeling', category: 5 },

];

type Props = {
    role?: Domain.Shared.UserRole,
    roleNames: string[],
    mode: FormMode,
    licenses: License[],
    onFormDataChanged: (formInfo: FormInfo<ValueType>) => void,
};

/**
 * Represents a UI component that renders the role create page.
 */
export const RoleForm: React.FC<Props> = (props) => {
    const validators = getValidators(props.roleNames);
    const [form, setForm] = useState<AnyFormData>(initFormData(props.role));

    const availableCategories = useMemo(
        () => Object.keys(PermissionCategories)
            .filter((categoryId) => !!!PermissionLicenses[categoryId] || props.licenses.includes(PermissionLicenses[categoryId]))
            .map(categoryId => ({ categoryId: Number(categoryId), name: PermissionCategories[categoryId] }))
        , [props.licenses]);

    const availablePermissions = useMemo(
        () => Permissions.filter((permission) => availableCategories.find(lic => lic.categoryId === permission.category))
        , [availableCategories]);

    const visiblePermisionsList = useMemo(
        () => {
            const list = props.mode === FormMode.View ? availablePermissions.filter((permission) => props.role.permissions.includes(permission.key)) : availablePermissions;
            //group to hierarhical structure
            const result = _(list)
                .groupBy(x => x.category)
                .map((value, key) => ({
                    key: `cat-${key}`,
                    label: `Rechten <b>${availableCategories.find(a => a.categoryId === Number(key))?.name}</b>`,
                    items: value
                }))
                .value();
            return result;
        }, [availablePermissions, props.role, props.mode]);

    useEffect(() => {
        if (!props.role) {
            return;
        }
        setForm(initFormData(props.role));
    }, [props.role]);

    const storeFormValue = (value: ValueType, systemId: keyof typeof validators) => {
        const newForm = FormHelper.validateAndStoreFormValue<AnyFormData>(form, value, validators, systemId);
        setForm(newForm);
        props.onFormDataChanged({ values: newForm.values, isValid: newForm.isValid, isTouched: Object.keys(newForm.touched).length > 0 });
    };

    return (
        <>
            {props.mode === FormMode.Edit && (
                <PageTitle>
                    <Heading1>Wijzig algemene instellingen</Heading1>
                </PageTitle>
            )}
            <Section look='white'>
                <LayoutForm>
                    <LayoutField key='name' left={1} top={1} width={6} height={1}>
                        <TextElement
                            id='name-role'
                            label='Naam'
                            editorSettings={{
                                disabled: props.mode === FormMode.View,
                                restrictions: { required: true, minLength: 2, maxLength: 50 },
                                validationErrors: form.validationErrors['name'],
                                onChange: (val: string) => storeFormValue(val, 'name'),
                            }}
                            value={form.values['name'] as string}
                        />
                    </LayoutField>
                    <LayoutField key='permission-list' left={1} top={3} width={6} height={1}>
                        {form.validationErrors['permissions'] && <Text danger value={form.validationErrors['permissions'][0]?.error} />}
                        <ResetZIndex>
                            <DxTreeList
                                id='role-permission-overview'
                                className='lias-treelist'
                                dataSource={visiblePermisionsList}
                                dataStructure="tree"
                                itemsExpr="items"
                                keyExpr="key"
                                showRowLines={true}
                                showBorders={false}
                                columnAutoWidth={false}
                                showColumnHeaders={false}
                                autoExpandAll={true}
                                selection={{
                                    mode: props.mode === FormMode.View ? 'none' : 'multiple',
                                    recursive: true,
                                }}
                                scrolling={{
                                    mode: 'standard',
                                }}
                                selectedRowKeys={form.values['permissions']}
                                onSelectionChanged={(e) => {
                                    const selectedData = e.component.getSelectedRowsData('leavesOnly');
                                    const newList = selectedData.reduce((acc, i) => [...acc, i['key']], []);
                                    storeFormValue(newList, 'permissions');
                                }}
                            >
                                <DxColumn caption='Naam' dataField='label' encodeHtml={false} />
                            </DxTreeList>
                        </ResetZIndex>
                    </LayoutField>
                </LayoutForm>
            </Section>
        </>);
};

/**
 * Initialises the validators for the form.
 */
const getValidators = (names: string[] = []): ValidatorsDictionary => {
    return {
        'name': new TextValidator({
            required: true,
            stringMaxLength: 50,
            stringMinLength: 2,
            stringType: Domain.Shared.StringType.SingleLine
        }, (value: string): ValidationErrorData[] => {
            const lowerCaseNamelist = names.map(name => name.toLocaleLowerCase());
            if (lowerCaseNamelist.includes(value.toLocaleLowerCase())) {
                return [{ error: 'Naam is niet uniek.' }];
            }
            return [];
        }),
        'permissions': new BasicValidator<any>({ required: false },
            (value: string[]): ValidationErrorData[] => {
                if (value?.length <= 0) {
                    return [{ error: 'Minimaal één permissie verplicht.' }];
                }
                return [];
            }),
    };
};

/**
 * Initialises the form data with role data.
 */
const initFormData = (role: Domain.Shared.UserRole): AnyFormData => {
    return {
        values: {
            'name': role?.name || '',
            'permissions': role?.permissions || [],
        },
        touched: {},
        validationErrors: {},
        isValid: false,
    };
};
