import { createReducer } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { FinanceBudgetTagsActionCreator, TenantActionCreator } from '../../../../actions/creators';
import { AjaxRequestStatus } from '../../../state';

/**
 * Represents the Finance budget group tags reducer.
 */
const reducer = createReducer<{ [baseYear: number]: { items: Domain.Finance.BudgetElementGroupTag[], status: AjaxRequestStatus } }>
    ({}, (builder) => {
        return (
            builder.addCase(FinanceBudgetTagsActionCreator.set.fulfilled, (state, action) => {
                const baseYear = action.meta.arg.data.baseYear;
                state[baseYear] = {
                    items: action.payload,
                    status: AjaxRequestStatus.Done,
                };
                return state;
            }),
            builder.addCase(FinanceBudgetTagsActionCreator.set.pending, (state, action) => {
                // Loading in progress;
                const baseYear = action.meta.arg.data.baseYear;
                state[baseYear] = {
                    items: undefined,
                    status: AjaxRequestStatus.Pending,
                };
                return state;
            }),
            builder.addCase(FinanceBudgetTagsActionCreator.set.rejected, (state, action) => {
                const baseYear = action.meta.arg.data.baseYear;
                state[baseYear] = {
                    items: [],
                    status: AjaxRequestStatus.Failed,
                };
            }),
            builder.addCase(TenantActionCreator.initTenant, (state, action) => {
                state = {};
                return state;
            })
        );
    });

export default reducer;
