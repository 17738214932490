/**
 * Helper class that offers JSON helper tools.
 */
export class JsonUtils {

    /**
     * Converts a text to JSON, with a default value for invalid json objects (ex: empty string or undefined).
     * @param text Text to convert to JSON.
     * 
     * @template T object type to convert to.
     */
    public static toJson = <T>(text: string, defaultValue: T = null): T => {
        try {
            if (text && text.length > 0) {
                return JSON.parse(text) as T;
            }
        } catch (e) { }

        return defaultValue;
    };

    /**
     * Determines if text is a valid JSON.
     * @param text Text to check if it's a valid JSON object.
     */
    public static isJSON = (text: string): boolean => {
        try {
            return (JSON.parse(text) && !!text);
        } catch (e) {
            return false;
        }
    };

    /**
     * Determines if a text is a valid JSON array.
     * @param text Text to check if it's a valid JSON array.
     */
    public static checkJSONArray = (text: string): boolean =>
        !!(text && text !== `[]` && text !== `"[]"` && text !== `null` && text !== `` && JsonUtils.isJSON(text as string));

    /**
     * Formats a number to a byte size string.
     * 
     * @param bytes The number of bytes.
     * @param decimals The number of decimals to include in the formatting.
     */
    public static formatBytes = (bytes: number, decimals = 1): string => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const exp = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, exp)).toFixed(decimals < 0 ? 0 : decimals)).toString().replace(".", ",")} ${sizes[exp]}`;
    };

    /**
     * Performs a comparison between two dictionaries to determine if they are equivalent.
     * 
     * @param dictionaryA Defines the first dictionary.
     * @param dictionaryB Defines the first dictionary.
     */
    public static dictionariesAreEquivalent = (dictionaryA: Record<string, string>, dictionaryB: Record<string, string>): boolean => {
        if (!dictionaryA || !dictionaryB) {
            return false;
        }

        const aProperties = Object.getOwnPropertyNames(dictionaryA);
        const bProperties = Object.getOwnPropertyNames(dictionaryB);

        if (aProperties.length !== bProperties.length) {
            return false;
        }

        for (let aIndex = 0; aIndex < aProperties.length; aIndex++) {
            const propName = aProperties[aIndex];

            if (dictionaryA[propName] !== dictionaryB[propName]) {
                return false;
            }
        }

        return true;
    };
}