import React from 'react';
import { ModalDialogFooterProps } from './index.props';
import { Bar, Button, AutoFocus } from '@liasincontrol/ui-basics';

/**
 * Represents a UI component that renders the footer of a modal dialog
 */
export const ModalDialogFooter: React.FC<ModalDialogFooterProps> = (props) => {
    return (
        <Bar>
            {props.leftButtonText && props.onLeftButtonClick && (
                <Bar start>
                    <Button
                        id="btn-modal-cancel"
                        btnbase="textbuttons"
                        btntype="medium_noicon"
                        onClick={(event: React.MouseEvent) => { event.stopPropagation(); props.onLeftButtonClick(); }}
                        aria-label={props.leftButtonText}
                        disabled={props.leftButtonDisabled}>
                        {props.leftButtonText}
                    </Button>
                </Bar>
            )}
            {props.rightButtonText && props.onRightButtonClick && (
                <Bar end>
                    <AutoFocus>
                        <Button
                            id="btn-modal-confirm"
                            btnbase="primarybuttons"
                            btntype="medium_icon_nofocus"
                            onClick={(event: React.MouseEvent) => { event.stopPropagation(); props.onRightButtonClick(); }}
                            aria-label={props.rightButtonText}
                            disabled={props.rightButtonDisabled}>
                            {props.rightButtonText}
                        </Button>
                    </AutoFocus>
                </Bar>
            )}
        </Bar>

    );
};
