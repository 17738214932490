import { palette, px } from "@liasincontrol/ui-basics";
import styled from "styled-components";

const Grid = styled.div`
    display: grid;
    grid-template-columns: ${px(20)} minmax(${px(57)}, auto) ${px(20)} 1fr ${px(20)};
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-gap: ${px(8)} ${px(19)};
    padding-bottom: 1rem;
`;

const ContentHeaderBarLeft = styled.div`
    grid-column: 2 / 2;
    grid-row: 1 / span 1;
    border-bottom: 2px solid ${palette.grey3b};
`;

const ContentHeaderLeft = styled.div`
    grid-column: 2 / -1;
    grid-row: 1 / span 1;
    min-height: ${px(16)};
    display: flex;
    align-items: flex-end;
`;
const ContentHeaderBarRight = styled.div`
    grid-column: 4 / span 1;
    grid-row: 1 / span 1;
    border-bottom: 2px solid ${palette.grey3b};
`;
const ContentHeaderRight = styled.div`
    grid-column: 4 / -1;
    grid-row: 1 / span 1;
    min-height: ${px(16)};
    > * { 
      &:not(:only-child):not(:last-child) {
        margin-right: ${px(30)};
      }
    }
`;

export { ContentHeaderRight, ContentHeaderBarRight, ContentHeaderLeft, ContentHeaderBarLeft, Grid };