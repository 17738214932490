import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';
import { SelectElement } from '@liasincontrol/ui-elements';
import Styled from './index.styled';

type Props = {
    workflows: Domain.Shared.WorkflowTemplateWithStates[],
    onConfirm: (newWorkflowId: string) => void,
    onCancel: () => void,
};

/** Represents a UI component that renders the dialog used to set/change the workflow for a publication. */
const SetWorkflowDialog: React.FC<Props> = (props) => {
    const [selectedWorkflow, setSelectedWorkflow] = useState<Domain.Shared.WorkflowTemplateWithStates>();

    const dialogFooter: JSX.Element = (
        <ModalDialogFooter
            leftButtonText='Annuleren'
            onLeftButtonClick={props.onCancel}
            rightButtonText='WIJZIGEN'
            onRightButtonClick={() => props.onConfirm(selectedWorkflow.id)}
            rightButtonDisabled={!selectedWorkflow} />
    );

    return (
        <>
            <ModalDialog
                id="dialog-set-workflow"
                settings={{
                    look: 'interactive',
                    title: 'Workflow wijzigen',
                    footer: dialogFooter
                }}>
                <Styled.TextWrapper>
                    <Text value='U staat op het punt om de workflow voor de gehele publicatie te wijzigen.' />
                    <Text value='Alle eerder gekoppelde gebruikers worden verwijderd.' />
                    <Text danger bold value='LET OP: U kunt deze actie niet ongedaan maken.' />
                </Styled.TextWrapper>
                <SelectElement<Domain.Shared.WorkflowTemplateWithStates>
                    id='select-new-workflow'
                    label='Workflow'
                    displayExpr='name'
                    optionItems={props.workflows}
                    value={selectedWorkflow}
                    clearable={false}
                    searchable={false}
                    editorSettings={{
                        disabled: false,
                        restrictions: { required: true },
                        validationErrors: [],
                        onChange: setSelectedWorkflow,
                    }}
                />
            </ModalDialog>
        </>
    );
};

export { SetWorkflowDialog };