import React from 'react';
import { IconSize, palette, SVGIcon } from '@liasincontrol/ui-basics';
import { SelectElementTemplateProps } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import TextBox from "devextreme-react/text-box";
import Styled from '../../../OptionItem/index.styled';

export const typeInputCustomOptions = (customValueProps: Domain.Shared.FieldDefinitionOptionItem & { icon: string }, templateProps?: SelectElementTemplateProps) => {
    const hexColor = customValueProps?.color !== undefined ? `#${customValueProps?.color.toString(16).padStart(6, '0')}` : palette.grey1;

    return (
        <Styled.SingleValueWrapper>
            {customValueProps?.icon &&
                <SVGIcon value={customValueProps.icon} size={IconSize.medium} color={hexColor} />
            }

            {templateProps?.isFieldTemplate
                ? <TextBox stylingMode='outlined' value={customValueProps?.name} placeholder={templateProps.placeholder} />
                : <Styled.SingleValueLabel>{customValueProps?.name}</Styled.SingleValueLabel>
            }
        </Styled.SingleValueWrapper>
    );
};

