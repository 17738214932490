import React, { useState, useEffect } from 'react';
import { Dictionary } from 'lodash';
import { FieldsHelper } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';

import Styled from './index.styled';

type Props = {
    element: Domain.Publisher.ElementNode;
    elementList: Dictionary<Domain.Publisher.Element>;
    readonly: boolean,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition;
    children?: React.ReactNode;
};

/**
 * Represents a UI component that renders a container item.
 */
const ContainerItem: React.FC<Props> = (props) => {
    const [currentElement, setcurrentElement] = useState<Domain.Publisher.ContainerItemElement>();
    const { element, elementList, readonly, getElementDefinition } = props;

    useEffect(() => {
        if (elementList[element.elementId]) {
            const elementSettings = new Domain.Publisher.ContainerItemElement();
            const elementDefinition = getElementDefinition(elementList[element.elementId].elementDefinitionSystemId, elementList[element.elementId].elementDefinitionId);
            FieldsHelper.mapObject<Domain.Publisher.ContainerItemElement>(elementSettings, elementDefinition.fields, elementList[element.elementId].fields);
            setcurrentElement(elementSettings);
        }
    }, [element.elementId, elementList, getElementDefinition]);

    if (!currentElement) {
        return null;
    }

    const needsContainerWrapper = props.element.children.some(child => needsWrapper(props.elementList[child.elementId].elementDefinitionSystemId));
    if (!needsContainerWrapper) {
        return (<>{props.children}</>);
    }

    return (
        <Styled.ContainerWrapper
            showBackground={currentElement.showBackground}
            showWhitespaceTop={currentElement.showWhitespaceTop}
            showWhitespaceRight={currentElement.showWhitespaceRight}
            showWhitespaceBottom={currentElement.showWhitespaceBottom}
            showWhitespaceLeft={currentElement.showWhitespaceLeft}
            showShadow={currentElement.showShadow}
            preview={readonly}>
            {props.children}
        </Styled.ContainerWrapper>
    );
};

export default ContainerItem;

/**
 * Determines if the container wrapper has to be applied.
 * 
 * @param elementDefinitionSystemId Defines the element definition system id.
 */
const needsWrapper = (elementDefinitionSystemId: string): boolean => {
    switch (elementDefinitionSystemId) {
        case Domain.SystemElementDefinitions.Pub.HtmlElementControl:
        case Domain.SystemElementDefinitions.Pub.ImageControl:
        case Domain.SystemElementDefinitions.Pub.TitleControl:
        case Domain.SystemElementDefinitions.Pub.TextControl:
        case Domain.SystemElementDefinitions.Pub.PieChartControl:
        case Domain.SystemElementDefinitions.Pub.BarChartControl:
        case Domain.SystemElementDefinitions.Pub.StackContainer:
        case Domain.SystemElementDefinitions.Pub.TabContainer:
        case Domain.SystemElementDefinitions.Pub.AccordionContainer:
        case Domain.SystemElementDefinitions.Pub.DataTableControl:
        case Domain.SystemElementDefinitions.Pub.MenuControl:
        case Domain.SystemElementDefinitions.Pub.TileMenuControl:
        case Domain.SystemElementDefinitions.Pub.ReferenceAttachments:
        case Domain.SystemElementDefinitions.Pub.DataSourceText:
        case Domain.SystemElementDefinitions.Pub.PerformanceInformationControl:
        case Domain.SystemElementDefinitions.Pub.AccordionDataControl:
        case Domain.SystemElementDefinitions.Pub.TreeViewControl:
        case Domain.SystemElementDefinitions.Pub.AccordionDsControl:
        case Domain.SystemElementDefinitions.Pub.MapControl:
        case Domain.SystemElementDefinitions.Pub.PivotTableControl:
        case Domain.SystemElementDefinitions.Pub.TabDsControl:
            return true;

        default:
            return false;
    }
};
