import React from 'react';
import styled from 'styled-components';
import { palette } from '../styleguide';

export const TagToolbar = ({ children }: { readonly children?: React.ReactNode }) => (
    <>
        <Container>
            <Text>{children}</Text>
        </Container>
    </>
);

export default TagToolbar;

const Container = styled.span`
    position: relative;
    display: inline-block;
    vertical-align: middle;
    width: auto;
    height: 38px;
    padding: 0.1rem 0.4rem 0.1rem 1.2rem;
    margin: 1px 24px 1px 0;
    border-radius: 6px 0 0 6px;
    background-color: ${palette.white};
    color: ${palette.grey2};

    /* arrow to right */
    ::after {
        content: ' ';
        position: absolute;
        left: 100%;
        top: 50%;
        width: 0;
        height: 0;
        border: solid transparent;
        border-color: rgba(255, 255, 255, 0);
        border-left-color: #fff;
        border-width: 19px;
        margin-top: -19px;
        pointer-events: none;
    }
`;

const Text = styled.span`
    font-weight: 800;
    font-size: 0.8rem;
    line-height: 36px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
`;
