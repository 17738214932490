import styled from 'styled-components';
import { InputField } from '@liasincontrol/ui-devextreme';
import { palette } from '@liasincontrol/ui-basics';

const InputFieldCustom = styled(InputField)`
    border: ${(p) => (p.success ? `2px solid ${palette.validationPos1} !important` : null)};
`;

const styledComponents = { InputFieldCustom };
export default styledComponents;
