import React from 'react';
import { MultiOptionsListsEditor } from './index.editor';
import { MultiOptionsListsProps } from './index.props';
import { MultiOptionsListsViewer } from './index.viewer';

/**
 * Represents a UI component that renders a dialog with 2 lists, allowing the selection of multiple items.
 */
export const MultiOptionsListsElement: React.FC<MultiOptionsListsProps> = (props) => {
    return props.editorSettings && !props.editorSettings.disabled ? <MultiOptionsListsEditor {...props} /> : <MultiOptionsListsViewer {...props} />;
};