import React from 'react';
import { RichTextEditor } from './index.editor';
import { RichTextViewer } from './index.viewer';
import { RichTextProps } from './index.props';

/**
 * Represents a UI component that renders a rich text element.
 */
export const RichTextElement: React.FC<RichTextProps> = (props) => {
    return props.editorSettings && !props.editorSettings.disabled ? <RichTextEditor {...props} /> : <RichTextViewer {...props} />;
};
