import styled, { css } from 'styled-components';
import { Button, Label, palette, px } from '@liasincontrol/ui-basics';

const AlignedCss = {
    'top': css`
        align-self: flex-start;
    `,
    'bottom': css`
        align-self: flex-end;
    `,
    'middle': css`
        align-self: center;
    `,
    'left': css`
        text-align: start;
    `,
    'center': css`
        text-align: center;
    `,
    'right': css`
        text-align: end;
    `,
};

const Grid = styled.div`
    display: grid;
    grid-template-columns: ${px(20)} repeat(12, [col-start] 1fr) ${px(20)};
    grid-template-rows: ${px(20)} repeat(2, auto) repeat(2, minmax(${px(32)}, auto)) repeat(3, auto);
    grid-auto-flow: row;
    grid-gap: ${px(8)} ${px(20)};
`;

const HeaderForm = styled.div`
    grid-column: col-start / span 12;
    grid-row: 2 / span 1;
    display: flex;
    align-items: center;
    min-height: ${px(32)};
`;

const StyledLabel = styled(Label)` 
    min-height: 0;
    margin-left: ${px(3)};
`;

const HeaderNumber = styled.div`
    grid-column: col-start / span 2;
    grid-row: 3 / span 1;
`;

const HeaderName = styled.div`
    grid-column: col-start 3 / span 10;
    grid-row: 3 / span 1;
`;

const ContentHeaderBar = styled.div`
    grid-column: 1 / span 14;
    grid-row: 4 / span 2;
    background-color: ${palette.grey4};
    border-bottom: ${px(2)} solid ${palette.grey3b};
    z-index: -1;
    padding: ${px(50)} 0 0 0;
`;

const ContentHeaderColumn = styled.div<{
    colStart: number, colSpan?: number,
    rowStart: number, rowSpan?: number,
    halign?: string, valign?: string,
}>`
    grid-column: ${({ colStart, colSpan }) => (`${colStart} / span ${colSpan ? colSpan : 1}`)};
    grid-row: ${({ rowStart, rowSpan }) => (`${rowStart} / span ${rowSpan ? rowSpan : 1}`)};
    min-height: ${px(22)};
    align-self: flex-start; 
    text-decoration: none;

    &:active {
        outline: 0;
    }
    &:focus {
        outline: 0;
    }
    ${(p) => AlignedCss[p.halign]}
    ${(p) => AlignedCss[p.valign]}
`;

const IconContainer = styled.div<{ color?: string }>`
    svg {
        fill: ${({ color }) => color ? color : palette.grey2};
    }
`;

const HeaderWorkflowState = styled.div`
    grid-column: col-start / span 2;
    grid-row: 4 / span 1;
    padding: ${px(20)} 0 0 0;
`;

const HeaderAssignedUser = styled.div`
    grid-column: col-start 3 / span 2;
    grid-row: 4 / span 1;
    padding: ${px(20)} 0 0 0;
`;

const Content = styled.div`
    grid-column: 2 / span 12;
    grid-row: 6 / -3;
    display: grid;
    grid-template-columns: repeat(12, [col-content-start] 1fr);
    grid-template-rows: ${px(20)} repeat(4, [row-content-start] auto) ${px(20)};
    grid-gap: ${px(8)} ${px(20)};
    position: relative;
`;

const ContentColumn = styled.div<{
    colStart: number, colSpan?: number,
    rowStart: number, rowSpan?: number,
}>`
    grid-column: ${({ colStart, colSpan }) => (`col-content-start ${colStart} / span ${colSpan ? colSpan : 12}`)};
    grid-row: ${({ rowStart, rowSpan }) => (`row-content-start ${rowStart} / span ${rowSpan ? rowSpan : -1}`)};   
`;

const Wrapper = styled.div<{ textWithButton: boolean }>` 
    margin-bottom: ${px(4)};
    padding: ${px(12)} 0;
    clear: both;
    border-bottom: ${({ textWithButton }) => textWithButton ? `${px(2)} solid ${palette.grey4}` : 'none'};
    > button {         
        margin-top: ${({ textWithButton }) => textWithButton ? `${px(8)}` : 'none'};   
    }    
`;

const ButtonContainer = styled.div`    
    display: grid;
    gap: ${px(12)} ;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
`;

const TextContainer = styled.div`    
    text-overflow: ellipsis;
    white-space: normal;
    overflow: hidden;
    word-break: keep-all;
`;

const ChoicesContainer = styled.div`    
    margin-top: 1rem;
`;

const ButtonRemove = (styled(Button)`
        > span {
            padding: 0;
            height: ${px(40)};
            width: ${px(40)};
        }` ) as typeof Button;

const styledComponents = {
    Grid, HeaderForm, StyledLabel, HeaderNumber, HeaderName, ContentHeaderBar, IconContainer,
    HeaderWorkflowState, HeaderAssignedUser, Content,
    ContentColumn, Wrapper, ButtonRemove, ButtonContainer, TextContainer, ContentHeaderColumn, ChoicesContainer
};

export default styledComponents;
