import React from 'react';
import { Routes as PubRoutes } from '@liasincontrol/pub';
import { SideMenuLayout } from '../Layouts';

/**
 * Represents an UI element that renders all general administrative sub-routes, in their specific Layouts.
 * path='/admin/*'
 */
const AdminModuleRoutes: React.FC = () => {
    //TODO: regex for path:  https://github.com/remix-run/react-router/discussions/8132
    // <Route path='/admin/(user|role|usergroup|datastore|workflow|measuremoment)'>
    return (
        <SideMenuLayout look="admin" sideMenu={PubRoutes.Admin.Menu} components={PubRoutes.Admin.Contents} />
    );
}

export default AdminModuleRoutes;
