import styled from 'styled-components';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { cssFontSemiBold, palette, px } from '@liasincontrol/ui-basics';

const SideMenuList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #fff;
    border-radius: 5px;
`;

interface IIconProps {
    readonly disabled?: boolean;
    readonly expanded?: boolean;
    readonly height?: number;
}

const MainLineWrap = styled.div``;

const ActionsWrap = styled.div`
    float: right;    
    > button {
        vertical-align: middle;
        margin-left: ${px(4)}; 
    }
`;

const DragWrap = styled.span`
    padding: 0;
    min-width: 1.5rem;
`;

const TxtLabel = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
`;

const Li = styled.li<IIconProps>`
    position: relative;
    padding: ${px(6)} ${px(8)};
    margin-bottom: ${px(2)};
    text-align: left;
    white-space: nowrap; 
    text-overflow: ellipsis;
    list-style-type: none;
    transition: background-color 0.4s, padding-right 0.4s ease;
    border-radius: ${px(8)};
    color: ${(p: IIconProps) => p.disabled ? palette.grey3 : palette.grey2};
    background-color: ${(p: IIconProps) => p.expanded ? palette.primary3 : palette.white};
    cursor: ${(p: IIconProps) => (p.disabled ? 'default' : 'pointer')};

    &:hover {
        color: ${(p: IIconProps) => p.disabled ? palette.grey3 : palette.grey2};
        background-color: ${palette.primary4};
    };
`;

const StyledIconDrag = styled(DragIndicatorIcon) <IIconProps>`
    color: ${(p: IIconProps) => p.disabled ? '#6E6E77' : palette.primary2};
    fill: currentColor;
    cursor: ${(p: IIconProps) => p.disabled ? 'default' : 'move'};
    vertical-align: middle;
`;

const StyledInput = styled.input`
    ${cssFontSemiBold(16, 32, 30)}
    border: 0;
    padding: 0;
    outline: 0;
    flex-grow: 1;
    margin-right: 1rem;
    border-bottom: 1px solid ${palette.grey2};
`;

const StyledCaption = styled.div`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    align-items: center;
`;

const StyledAddOptions = styled.div`
    padding: 8px;
`;

const StyledAdd = styled.div`
    margin-bottom: 1rem;
`;

const StyledItem = styled.div`
    ${cssFontSemiBold(16, 48, 30)}
    gap: ${px(4)};
    display: flex;
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipses;
    opacity: ${(p: { canRestore: boolean }) => (p.canRestore ? 0.5 : 1)};
    
    &.add {
        padding-top: 8px;
        padding-bottom: 8px;
    }
`;

export { SideMenuList, StyledAdd, MainLineWrap, ActionsWrap, DragWrap, TxtLabel, Li, StyledIconDrag, StyledCaption, StyledAddOptions, StyledInput, StyledItem };
