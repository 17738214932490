import styled from 'styled-components';
import Styled, { lineHeightCoefficient } from "../../SharedControlElements/index.styled";

const AccordionWrapper = styled(Styled.GridWrapper) <{
    primaryColor: string,
    primaryTextColor: string,
    textColor: string,
    textFontSize?: string,
    editMode: boolean
}>`    
   
   .dx-accordion-item-title {
        color: inherit;
        font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
        line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px!important` : 'inherit')};
        font-weight: 700;
        border-bottom: 1px solid #cccccc;

        &::before{
            font-size: inherit;
            line-height: inherit;
            color: inherit; 
        }

    } 

    .dx-accordion-item-body {
        font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
        line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px!important` : 'inherit')};
    }
`;

const styledComponents = { AccordionWrapper };
export default styledComponents;
