import React, { useEffect, useMemo, useState } from 'react';
import { InputHyperLink } from '@liasincontrol/ui-devextreme';
import _ from 'lodash';
import { getFeedback, getValidationErrorsNode } from '../../shared/validationHelper';
import { ElementProps } from '../..';

/**
 * Represents a UI component that renders a Hyperlink editor.
 */
export const HyperlinkEditor: React.FC<ElementProps<string>> = (props) => {
    const [value, setValue] = useState<string>(props.value ? props.value : '');
    const onChangedDebounced = useMemo(() => _.debounce(props.editorSettings?.onChange, 300), [props.editorSettings?.onChange]);

    useEffect(() => {
        if ((props.value || '') === value) {
            return onChangedDebounced.cancel;
        }

        onChangedDebounced(value);
        return onChangedDebounced.cancel;
    }, [value, onChangedDebounced, props.value]);

    useEffect(() => setValue(props.value ? props.value : ''), [props.value]);

    return (
        <InputHyperLink
            id={`input-${props.id}`}
            key={props.id}
            label={props.label}
            disabled={props.editorSettings.disabled}
            error={getValidationErrorsNode(props.editorSettings?.validationErrors)}
            feedback={getFeedback(value, props.editorSettings?.restrictions)}
            value={value}
            helpText={props.helpText}
            mandatory={props.editorSettings?.restrictions?.required}
            onChange={setValue}
        />
    );
};
