import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import Add from '@mui/icons-material/Add';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import * as Domain from '@liasincontrol/domain';
import { UserIdentity } from '@liasincontrol/auth-service';
import { Actions, ActionType, UserRightsService } from '@liasincontrol/userrights-service';
import { SystemElementDefinitions, SystemFieldDefinitions, SystemModuleDefinitions } from '@liasincontrol/domain';
import { ContextMenu, createSource, GridColumn, LoadPanel, LsGrid } from '@liasincontrol/ui-devextreme';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { AppSettingsService } from '@liasincontrol/config-service';
import { DefinitionsHelper, ApiErrorReportingHelper, ValueType, FormMode, AttachmentsHelper } from '@liasincontrol/core-service';
import { ActionSource, AjaxRequestStatus, AttachmentsActionCreator, ElementDefinitionsActionCreator, HierarchyDefinitionsActionCreator, MeasureMomentsActionCreator, ModulesActionCreator, State, StructuresActionCreator } from '@liasincontrol/redux-service';
import { Bar, Button, Section, ErrorOverlay, ModalDialog, ModalDialogFooter, WarningWrapper, WarningLook } from '@liasincontrol/ui-basics';
import { DataSourceAdd } from './DataSourceAdd';
import { PublicationContext, DataSourceControlsUtils } from '../../../../../../helpers';
import Helper from '../../../../../_shared/PublicationItem/PublicationInformation/index.helper';
import { isDataStoreType } from './index.helper';
import { IconPopover } from '../../../../_shared/IconPopover/IconPopover';
import { ConfirmDialog } from '../../../../_shared/ConfirmDialog';
import EditDataSourceItem from '../DataSourceItem';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the list of publication data sources page.
 */
const Index: React.FC<Props> = (props) => {
    const { id: publicationId } = useParams<{ id: string }>();
    const [lastRefresh, setLastRefresh] = useState<number>();

    const availableColumns = getColumnConfiguration(
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Datasources, ActionType.Read)
            ? (item: Domain.Publisher.DataSource) => {
                setSelectedDataSourceId(item.dataSourceId);
            }
            : undefined,
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Datasources, ActionType.Delete)
            ? (item: Domain.Publisher.DataSource) => {
                setDeleteDialog({ visible: true, id: item.dataSourceId, name: item.dataSourceName });
            }
            : undefined,
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Datasources)
            ? (item: Domain.Publisher.DataSource) => {
                setRefreshData({ visible: true, id: item.dataSourceId, text: 'U staat op het punt de databron bij te werken. Weet u zeker dat u dit wilt doen?', allowRefresh: true });
            } : undefined,
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Datasources)
            ? (item: Domain.Publisher.DataSource) => {
                previewDataSource(item);
            } : undefined,
    );

    const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
    const [deleteDialog, setDeleteDialog] = useState<{ visible: boolean, id: string, name: string }>(null);
    const [refreshData, setRefreshData] = useState<{ visible: boolean, id: string, text: string, allowRefresh: boolean }>(null);
    const [previewData, setPreviewData] = useState<{ visible: boolean, item: Domain.Publisher.DataSource, data: any[], columns: any[], error?: string }>({ visible: false, item: undefined, data: [], columns: [] });
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [selectedDataSourceId, setSelectedDataSourceId] = useState<string>();
    const pubContext = useContext(PublicationContext);

    useEffect(() => {
        if (!pubContext.settings || !props.elementdefinitions) return;
        const isClosed = Helper.getPublicationIsClosed(pubContext.settings, props.elementdefinitions[SystemElementDefinitions.Pub.Publication]);
        if (isClosed) {
            setError({ message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed] });
        }
    }, [pubContext.settings, props.elementdefinitions]);

    const customDataSource = useMemo(() => {
        if (!publicationId) {
            return;
        }

        return createSource({
            keyExpr: 'dataSourceId',
            paginate: true,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            selectedColumns: Object.keys(new Domain.Publisher.DataSource()),
            dataSourcePromise: (query) => DataAccess.DataSources.getDataSourcesV2(publicationId, query)
        });
    }, [lastRefresh, publicationId]);

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (props.measureMoments.status === AjaxRequestStatus.NotSet) {
        props.fetchMeasureMoments();
        return null;
    }

    if (!props.elementdefinitionsStatus || props.elementdefinitionsStatus === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Publisher], ActionSource.Publication);
        return null;
    }

    if ((!props.studioElementDefinitions || props.studioElementDefinitions.status === AjaxRequestStatus.NotSet)) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Studio], ActionSource.Studio);
        return null;
    }

    if ((!props.performanceElementDefinitions || props.performanceElementDefinitions.status === AjaxRequestStatus.NotSet)) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Performance], ActionSource.Performance);
        return null;
    }

    if ((!props.hierarchyDefinitions || props.hierarchyDefinitions.status === AjaxRequestStatus.NotSet)) {
        props.fetchHierarchyDefinitions(ActionSource.Studio, props.modules[Domain.SystemModuleDefinitions.Studio], true);
        return null;
    }

    if ((!props.structures || props.structures.status === AjaxRequestStatus.NotSet)) {
        props.fetchStructures();
        return null;
    }

    const saveNewDataSource = (formValues: Record<string, ValueType>, type: string) => {

        const autoRefreshValue = formValues[SystemFieldDefinitions.Pub.DataSourceAutoRefresh];
        const autoRefreshValString = (String(autoRefreshValue).charAt(0).toUpperCase() + String(autoRefreshValue).slice(1));

        const dataSourceDefinition = props.elementdefinitions[SystemElementDefinitions.Pub.DataSource];
        const nameDefinition = dataSourceDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.Name);
        const complexFieldDefinition = dataSourceDefinition.complexFields[0];
        const dataStoreDefinition = complexFieldDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.Datastore);

        const columnsDefinition = complexFieldDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.DataSourceColumns);
        const filterDefinition = complexFieldDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.Filter);
        const autoRefreshDefinition = dataSourceDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.DataSourceAutoRefresh);

        //hierarchy
        const mmDefinition = complexFieldDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.DataSourceMeasureMomentId);
        const edDefinition = complexFieldDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.DataSourceElementDefinitionId);
        const structureDefinition = complexFieldDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.DataSourceStructure);
        const measureMoments = complexFieldDefinition.fields.find(field => field.systemId === SystemFieldDefinitions.Pub.DataSourceMeasureMomentIds);

        const fields = {
            [nameDefinition.id]: formValues[SystemFieldDefinitions.Pub.Name],
            [autoRefreshDefinition.id]: autoRefreshValString,
        };

        const complexFields = {
            [dataStoreDefinition.id]: formValues[SystemFieldDefinitions.Pub.Datastore],
        };

        let extraComplexFields: Record<string, string> = {};
        if (isDataStoreType(type, Domain.Publisher.DataStoreTypes.DataPlatform)) {
            extraComplexFields = {
                [columnsDefinition.id]: formValues[SystemFieldDefinitions.Pub.DataSourceColumns],
                [filterDefinition.id]: formValues[SystemFieldDefinitions.Pub.Filter],
            }
        }

        if (isDataStoreType(type, Domain.Publisher.DataStoreTypes.Performance) || isDataStoreType(type, Domain.Publisher.DataStoreTypes.Studio)) {
            extraComplexFields = {
                [mmDefinition.id]: formValues[SystemFieldDefinitions.Pub.DataSourceMeasureMomentId],
                [edDefinition.id]: formValues[SystemFieldDefinitions.Pub.DataSourceElementDefinitionId],
                [columnsDefinition.id]: formValues[SystemFieldDefinitions.Pub.DataSourceColumns],
            }
        }

        if (isDataStoreType(type, Domain.Publisher.DataStoreTypes.Finance)) {
            extraComplexFields = {
                [structureDefinition.id]: formValues[SystemFieldDefinitions.Pub.DataSourceStructure],
                [measureMoments.id]: formValues[SystemFieldDefinitions.Pub.DataSourceMeasureMomentIds],
            }
        }

        const createComplexField = { complexFieldDefinitionId: complexFieldDefinition.id, rowIndex: 1, fields: { ...complexFields, ...extraComplexFields } } as Domain.Shared.ComplexField;

        const createElement = { elementDefinitionId: dataSourceDefinition.id, fields: fields, complexFields: [createComplexField] } as Domain.Publisher.Element;

        setIsProcessing(true);
        DataAccess.DataSources.createDataSource(publicationId, createElement)
            .then(() => {
                setLastRefresh(Date.now());
                setIsProcessing(false);
                setShowAddDialog(false);
            }).catch(() => {
                setShowAddDialog(false);
            });
    };

    const deleteDataSource = (datasourceId: string) => {
        setIsProcessing(true);
        DataAccess.DataSources.deleteDataSource(publicationId, datasourceId)
            .then(() => {
                setLastRefresh(Date.now());
                setIsProcessing(false);
                setDeleteDialog(null);
            });
    };

    const refreshDataSource = (datasourceId: string) => {
        setIsProcessing(true);
        DataAccess.DataSources.refreshDataSource(publicationId, datasourceId, true)
            .then(() => {
                setLastRefresh(Date.now());
                setIsProcessing(false);
                setRefreshData(null);
            }).catch((err) => {
                setIsProcessing(false);
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.FileSizeTooLarge)) {
                    setRefreshData((prev) => ({ ...prev, allowRefresh: false, text: 'Bestand is te groot, verminder het aantal rijen en/of kolommen' }));
                } else if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.SchemaFileTooLarge)) {
                    setRefreshData((prev) => ({ ...prev, allowRefresh: false, text: 'Schema bestand is te groot' }));
                } else {
                    setError(errorInfo);
                    setRefreshData(null);
                }
            });
    };

    const previewDataSource = (dataSource: Domain.Publisher.DataSource) => {
        setIsProcessing(true);
        setPreviewData({ item: dataSource, visible: true, data: [], columns: [] });
        const handleLoadDataSource = async () => {
            try {
                const dsElement = await pubContext.loadDataSourceElement(dataSource.dataSourceId);
                const responses = await Promise.all([
                    AttachmentsHelper.loadExistingAttachment(dsElement.dataFileId, props.attachments, props.setAttachment),
                    AttachmentsHelper.loadExistingAttachment(dsElement.schemaFileId, props.attachments, props.setAttachment)
                ]);
                const blobs = await Promise.all([
                    responses[0].text(),
                    responses[1].text()
                ]);
                const rows = JSON.parse(blobs[0]);
                const cols = DataSourceControlsUtils.mapsDataSourceColumnFields(JSON.parse(blobs[1]), 10, false, false, false)
                    .map(({ dataField, caption, visible, format, ...rest }) => ({ name: dataField, title: caption, hidden: !!!visible, format: format, encodeHtml: format === 'html', ...rest }));

                setPreviewData((prev) => ({
                    ...prev,
                    data: rows,
                    columns: cols,
                }));
            } catch (error) {
                setPreviewData((prev) => ({ ...prev, error: 'Error loading data source or attachment' }));
            }
        };
        handleLoadDataSource();
        setIsProcessing(false);
    };

    const canView = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Datasources, ActionType.Read);
    const canEdit = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Datasources, ActionType.Update);

    return (
        <>
            {!error && <Bar look="toolbar">
                <Bar start>
                    <Button
                        id="btn-create"
                        btnbase="primarybuttons"
                        btntype="medium_transparent"
                        icon={<Add />}
                        disabled={!UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Datasources, ActionType.Create)}
                        onClick={() => setShowAddDialog(true)}>
                        Nieuw
                    </Button>
                </Bar>
            </Bar>}

            <Section look="white">
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <LsGrid
                        id='datasource-list'
                        buttonColumnId='dataSourceId'
                        columns={availableColumns}
                        dataSource={customDataSource}
                        searching={true}
                        paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                        showRowLines={true}
                        enableColumnChooser={true}
                        onClickRow={(item) => {
                            if (!canView) {
                                return;
                            }
                            setSelectedDataSourceId(item.dataSourceId);
                        }}
                        onDataError={(error) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))}
                    />
                </ErrorOverlay>
            </Section>
            {selectedDataSourceId && props.elementdefinitions &&
                <EditDataSourceItem
                    publicationId={publicationId}
                    dataSourceId={selectedDataSourceId}
                    elementDefinitions={props.elementdefinitions}
                    studioElementDefinitions={props.studioElementDefinitions.items}
                    performanceElementDefinitions={props.performanceElementDefinitions.items}
                    hierarchyDefinitions={props.hierarchyDefinitions.items}
                    measureMoments={props.measureMoments.items}
                    structures={props.structures.items}
                    mode={canEdit ? FormMode.Edit : FormMode.View}
                    onCancel={() => setSelectedDataSourceId(null)}
                    onSubmit={(id) => {
                        setSelectedDataSourceId(null);
                        refreshDataSource(id)
                    }}
                />}
            {showAddDialog && props.elementdefinitions &&
                <DataSourceAdd
                    elementdefinitions={props.elementdefinitions}
                    measureMoments={props.measureMoments.items}
                    hierarchyDefinitions={Object.values(props.hierarchyDefinitions.items)}
                    studioElementDefinitions={props.studioElementDefinitions.items}
                    performanceElementDefinitions={props.performanceElementDefinitions.items}
                    structures={props.structures.items}
                    onSaveChanges={saveNewDataSource}
                    onCancelChanges={() => setShowAddDialog(false)}
                />}
            {deleteDialog?.visible &&
                <ConfirmDialog
                    look='message'
                    title='Databron verwijderen'
                    disableConfirmation={isProcessing}
                    text={`U staat op het punt om databron ${deleteDialog.name} te verwijderen. Deze actie kunt u niet meer ongedaan maken. Weet u dit zeker?`}
                    confirmButtonText='Verwijderen'
                    onCancel={() => {
                        setIsProcessing(false);
                        setDeleteDialog({ visible: false, id: null, name: null });
                    }}
                    onConfirm={() => deleteDataSource(deleteDialog.id)}
                />
            }
            {refreshData &&
                <ConfirmDialog
                    look='message'
                    disableConfirmation={isProcessing}
                    title='Bijwerken databron'
                    text={refreshData.text}
                    confirmButtonText='Bijwerken'
                    allowOperation={refreshData.allowRefresh}
                    onCancel={() => {
                        setIsProcessing(false);
                        setRefreshData(null);
                    }}
                    onConfirm={() => refreshDataSource(refreshData.id)}
                />
            }
            {previewData?.visible &&
                <ModalDialog
                    id={`preview-modal-${previewData.item?.dataSourceId}`}
                    modalDialogStyle='custom'
                    settings={{
                        look: 'interactive',
                        width: 1300,
                        title: 'Gegevens bekijken',
                        footer: <ModalDialogFooter
                            rightButtonText='Sluiten'
                            onRightButtonClick={() => { setPreviewData({ visible: false, item: undefined, data: [], columns: [] }); }} />
                    }}
                >
                    {!!previewData.error && <WarningWrapper
                        look={WarningLook.dangerInverted}
                        icon={<WarningAmberOutlinedIcon />}
                        className='p-025'
                        messageText={previewData.error}
                    />}
                    {previewData.data.length > 0 && previewData.columns.length > 0 &&
                        <LsGrid
                            id={`data-list-${previewData.item?.dataSourceId}`}
                            dataSource={previewData.data}
                            columns={previewData.columns}
                            searching={true}
                            paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                            showRowLines={true}
                            enableColumnChooser={true}
                            onDataError={(error) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))}
                        />
                    }
                </ModalDialog>
            }
            <LoadPanel visible={isProcessing} />
        </>
    );
};

const getColumnConfiguration = (
    onEdit?: (item: Domain.Publisher.DataSource) => void,
    onDelete?: (item: Domain.Publisher.DataSource) => void,
    onRefresh?: (item: Domain.Publisher.DataSource) => void,
    onPreview?: (item: Domain.Publisher.DataSource) => void
): GridColumn<Domain.Publisher.DataSource>[] => {
    return [
        {
            name: 'dataSourceName',
            title: 'Naam',
        },
        {
            name: 'dataConnectionId',
            renderCustom: (row) => row.data.dataConnectionName,
            title: 'Gegevensverbinding',
        },
        {
            name: 'lastRefreshedAt',
            title: 'Laatst ververst',
            dataType: 'date',
            formatter: 'datetime',
        },
        {
            name: 'autoRefresh',
            title: 'Automatisch bijwerken',
            width: '20%',
            renderCustom: (row) => (
                <>
                    {row?.data.autoRefresh ? 'Ja' : 'Nee'}
                    {row?.data.refreshFailed && <IconPopover
                        componentId={row.data.dataSourceId}
                        message='De databron kon niet automatisch bijgewerkt worden. Het automatisch verversen zal hervat worden nadat de databron succesvol handmatig ververst.' />
                    }
                </>
            ),
        },
        {
            type: 'buttons',
            title: 'Bijwerken',
            hideInColumnChooser: true,
            renderCustom: (row) => (
                <Button
                    btnbase='ghostbuttons'
                    btntype='small_icon'
                    icon={<RefreshOutlinedIcon />}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onRefresh(row.data);
                    }}
                />
            ),
        },
        {
            type: 'buttons',
            title: 'Preview',
            hideInColumnChooser: true,
            renderCustom: (row) => (
                <Button
                    btnbase='ghostbuttons'
                    btntype='small_icon'
                    disabled={row?.data.refreshFailed || !!!row?.data.lastRefreshedAt}
                    icon={<PreviewOutlinedIcon />}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        onPreview(row.data);
                    }}
                />
            ),
        },
        {
            name: 'dataSourceId',
            title: '',
            align: 'right',
            type: 'buttons',
            width: '5%',
            hideInColumnChooser: true,
            renderCustom: ({ data }) => (
                <ContextMenu<Domain.Publisher.DataSource>
                    keyExpr='dataSourceId'
                    item={data}
                    actions={[
                        {
                            action: onEdit,
                            displayName: 'Bewerken',
                            ariaLabel: `Bewerk ${data.dataSourceName}`,
                            actionName: `edit-datasource-${data.dataSourceId}`
                        },
                        {
                            action: onDelete,
                            displayName: "Verwijder",
                            ariaLabel: `Verwijder ${data.dataSourceName}`,
                            actionName: `delete-datasource-${data.dataSourceId}`
                        },
                        {
                            action: onRefresh,
                            displayName: "Bijwerken",
                            ariaLabel: `Bijwerken ${data.dataSourceName}`,
                            actionName: `refresh-datasource-${data.dataSourceId}`
                        },
                        {
                            action: onPreview,
                            hidden: data.refreshFailed || !!!data.lastRefreshedAt,
                            displayName: "Bekijken",
                            ariaLabel: `Bekijken ${data.dataSourceName}`,
                            actionName: `preview-datasource-${data.dataSourceId}`
                        }
                    ]}
                />
            )
        }
    ]
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    const hasElementDefinitions = !_.isEmpty(state.elementdefinitions?.[ActionSource.Publication]);
    return {
        modules: state.modules[ActionSource.Publication],
        elementdefinitions: hasElementDefinitions ? {
            [SystemElementDefinitions.Pub.DataSource]: DefinitionsHelper.findElementDefinition(
                state.elementdefinitions[ActionSource.Publication].items,
                SystemElementDefinitions.Pub.DataSource
            ),
            [SystemElementDefinitions.Pub.DataStore]: DefinitionsHelper.findElementDefinition(
                state.elementdefinitions[ActionSource.Publication].items,
                SystemElementDefinitions.Pub.DataStore
            ),
            [SystemElementDefinitions.Pub.Publication]: DefinitionsHelper.findElementDefinition(
                state.elementdefinitions[ActionSource.Publication].items,
                SystemElementDefinitions.Pub.Publication
            ),
        } : undefined,
        elementdefinitionsStatus: hasElementDefinitions ? state.elementdefinitions[ActionSource.Publication].status : AjaxRequestStatus.NotSet,
        studioElementDefinitions: state.elementdefinitions[ActionSource.Studio],
        performanceElementDefinitions: state.elementdefinitions[ActionSource.Performance],
        measureMoments: state.measuremoments,
        hierarchyDefinitions: state.hierarchydefinitions[ActionSource.Studio],
        structures: state.finance.structures,
        attachments: state.attachments,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module, actionSource: ActionSource) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: actionSource, data: { moduleId: module?.id } }));
        },
        fetchMeasureMoments: () => {
            dispatch(MeasureMomentsActionCreator.set());
        },
        fetchHierarchyDefinitions: (source: ActionSource, module: Domain.Shared.Module, includeLinkDefinitions: boolean) => {
            dispatch(HierarchyDefinitionsActionCreator.set({ source: source, data: { moduleId: module.id, includeLinkDefinitions: includeLinkDefinitions } }));
        },
        fetchStructures: () => {
            dispatch(StructuresActionCreator.set());
        },
        setAttachment: (attachmentId: string, attachment: File) => {
            dispatch(AttachmentsActionCreator.set({ source: ActionSource.Publication, data: { attachmentId, attachment } }));
        },
        removeAttachment: (attachmentId: string) => {
            dispatch(AttachmentsActionCreator.remove({ source: ActionSource.Publication, data: { attachmentId } }));
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
