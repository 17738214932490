import { Shared as SharedDataAccess } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';

/**
 * Helper class that orchestrates the most common attachment manipulation actions.
 */
export class AttachmentsHelper {
    /**
     * Represents an event handler that triggers the uploading process of an attachment.
     * 
     * @param file Defines the file.
     * @param abortSignal Defines the cancel token.
     * @param setAttachmentCallback Method used to store the loaded attachment in persistent (Redux) storage.
     */
    public static uploadAttachment = async (file: File, abortSignal: AbortSignal, setAttachmentCallback: (attachmentId: string, attachment: File) => void) => {
        const { blobId } = await SharedDataAccess.Attachments.uploadAttachment(file, abortSignal);
        setAttachmentCallback(blobId, file);
        return blobId;
    };

    /**
     * Helper that returns a cached attachment (if available) or downloads and caches the requested attachment.
     * 
     * @param attachmentId Defines the attachment id.
     * @param attachmentsStorage Defines a dictionary of existing attachments.
     * @param setAttachmentCallback Method used to store the loaded attachment in persistent (Redux) storage.
     * @param attachmentNames Optional dictionary of attachmentId to file name to be used on the created blob instance.
     */
    public static loadExistingAttachment = async (attachmentId: string, attachmentsStorage: { [attachmentId: string]: File },
        setAttachmentCallback: (attachmentId: string, attachment: File) => void, attachmentNames?: Record<string, string>): Promise<Blob> => {
            
        if (!attachmentId) {
            // Maybe throw an error instead.
            return Promise.resolve(null);
        }
        const attachment = attachmentsStorage && attachmentsStorage[attachmentId];
        if (attachment) {
            return Promise.resolve(attachment);
        }

        return SharedDataAccess.Attachments.getAttachmentInfo(attachmentId)
            .then(async (response) => {
                const fileResponse = await fetch(response.data.urlWithSasToken, { method: 'GET' });
                const blob = await fileResponse.blob();
                const fileName = attachmentNames ? attachmentNames[attachmentId] : '';

                const file = new File([blob], fileName, { type: blob.type });
                setAttachmentCallback(attachmentId, file);
                return file;
            });
    };

    /**
     * Generates an attachment object that can be sent to an API call, based on an uploaded File object.
     * @param attachment File object describing the uploaded file.
     * @param attachmentId Id assigned to the uploaded file.
     * @param isFieldAttachment True if the attachment is related to a field.
     */
    public static mapFileToAttachment = (attachment: File, attachmentId: string, isFieldAttachment?: boolean, deleted?: boolean): Domain.Shared.Attachment => {
        const fileName = attachment.name;
        const attachmentExtension = fileName.split('.').reverse()[0];
        return {
            id: attachmentId,
            blobId: attachmentId,
            name: fileName.replace(/\.[^/.]+$/, ""),
            fileExtension: attachmentExtension,
            isFieldAttachment: isFieldAttachment,
            deleted: deleted,
        };
    };

    /**
     * Translates a list of attachments to a dictionary that can be used in the loadExistingAttachment to set the proper attachment names.
     * @param attachments List of possible attachments.
     */
    public static getAttachmentNamesDictionary = (attachments: Domain.Shared.Attachment[]): Record<string, string> => {
        if (attachments) {
            return attachments.reduce(
                (collection, item) => ({ ...collection, [item.id]: item.name }),
                {});
        }
        return {};
    };
}