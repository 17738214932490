import React from 'react';
import Select, { components, ClearIndicatorProps, ControlProps, DropdownIndicatorProps, MenuListProps, MenuProps, MultiValueRemoveProps, ValueContainerProps } from 'react-select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import styled, { css } from 'styled-components';
import { IDataItemProps, IInputSelectProps } from './Props';

// tslint:disable:no-submodule-imports
import { BasicText } from '../typography';
// tslint:enable:no-submodule-imports

/* tslint:disable:no-magic-numbers */

export const InputSelect = <TValue extends string | number | boolean>({
  id,
  name,
  options,
  selectedOption,
  placeholder,
  clearable,
  searchable,
  onChange,
  isMultiSelector,
  maxTagsCount,
  disabled,
  customOptions: CustomOption,
  customSingleValue: CustomSingleValue,
}: IInputSelectProps<TValue>) => {
  const { Option, SingleValue } = components;

  const customOptions = (props) => {
    return CustomOption ? (<CustomOption {...props} />) : (<Option className='option-item' {...props} />)
  }

  const customSingleValue = (props) => {
    return CustomSingleValue ? (<CustomSingleValue {...props} />) : (<SingleValue {...props} />)
  }

  return (
    <Select
      name={name}
      options={options}
      closeMenuOnSelect={!isMultiSelector}
      value={selectedOption}
      placeholder={placeholder}
      isClearable={clearable}
      isSearchable={searchable}
      onChange={onChange}
      isDisabled={disabled}
      inputId={id}
      components={{
        ClearIndicator,
        Control,
        DropdownIndicator,
        IndicatorSeparator: null,
        Menu,
        MenuList,
        MultiValueContainer,
        MultiValueRemove,
        NoOptionsMessage,
        Option: customOptions,
        ValueContainer,
        SingleValue: customSingleValue,
      }}
      isMulti={isMultiSelector}
      isOptionDisabled={() => isMultiSelector && (selectedOption as IDataItemProps<TValue>[]).length >= maxTagsCount}
      styles={{
        multiValueRemove: (base) => ({
          ...base,
          "&:hover": {
            backgroundColor: '#d8eafd',
            color: '#0071e6',
          }
        }),
      }}
    />
  );
};

/**
 * <Control />
 *   <ValueContainer />
 *     <MultiValueContainer />
 *       <MultiValueRemove />
 *   <DropdownIndicator />
 * <Menu />
 *   <MenuList />
 *     <Option />
 */

const StyledControl = styled(BasicText.withComponent('div'))`
 border: 2px solid rgb(244, 244, 248);
 border-radius: 6px;
 transition: border-color 0.3s ease, border-radius 0.3s ease, box-shadow 0.3s ease;
 background-color: rgb(255, 255, 255);
 border-spacing: 0px;
 padding: 6px 16px;
 text-align: left;
 vertical-align: bottom;
 border-collapse: separate;
 cursor: default;
 width: 100%;
 max-width: 100%;
 position: relative;
 display: flex;
 flex-wrap: nowrap;
 justify-content: space-between;
 align-items: center;
 white-space: nowrap;
 position: relative;
 margin: 0px;

 :hover {
   box-shadow: none;
   border: 2px solid rgba(75, 85, 246, 0.15);
 }

 ${(props: { isFocused: boolean; isMenuOpen: boolean }) =>
    props.isFocused
      ? css`
         &,
         &:hover {
           box-shadow: none;
           border: 2px solid rgba(75, 85, 246, 1);
         }
       `
      : null}
 ${(props: { isFocused: boolean; isMenuOpen: boolean }) =>
    props.isMenuOpen
      ? css`
       &,
       &:hover {
         transition: border-color 0.1s ease, border-radius 0.1s ease,
           box-shadow 0.1s ease;
         box-shadow: 0px 5px 20px 0px rgba(75, 85, 246, 0.2);
         border: 2px solid rgba(75, 85, 246, 0);
         border-radius: 6px 6px 0 0;
       }
     `
      : null}

`;
const Control = ({
  innerProps,
  innerRef,
  children,
  selectProps,
  isFocused,
}: ControlProps<IDataItemProps<any>>) => (
  <StyledControl
    {...innerProps}
    id={selectProps.id}
    ref={innerRef}
    isFocused={isFocused}
    isMenuOpen={selectProps.menuIsOpen || false}
  >
    {children}
  </StyledControl>
);

const StyledValueContainer = styled.div`
  flex-wrap: nowrap;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
  white-space: nowrap;
`;
const ValueContainer = ({
  children,
}: ValueContainerProps<IDataItemProps<any>>) => (
  <StyledValueContainer>{children}</StyledValueContainer>
);

const StyledClearIndicatorContainer = styled.div`
  cursor: pointer;
  color: #bbbbbb;
  line-height: 1;
  :hover {
    color: #666666;
    }
`;
const IconClear = styled(CloseIcon)`
  fill: currentColor;
`;
const ClearIndicator = ({ innerProps }: ClearIndicatorProps<IDataItemProps<any>>) => {
  const { ref, ...restInnerProps } = innerProps;
  return (
    <StyledClearIndicatorContainer {...restInnerProps}>
      <IconClear sx={{ fontSize: '1.1rem' }} />
    </StyledClearIndicatorContainer>
  );
};

const StyledMultiValueContainer = styled.div`
  background-color: rgb(248, 249, 254);
  border-color: 1px solid rgb(248, 249, 254);
  border-radius: 4px;
  color: rgb(65, 65, 65);
  display: flex;
  flex-wrap: nowrap;
  font-size: 14px;
  line-height: 1.4;
  margin-right: 5px;
  padding: 3px;
  vertical-align: top;
`;
const MultiValueContainer = ({ children }: any) => (
  <StyledMultiValueContainer>{children}</StyledMultiValueContainer>
);

const StyledMultiValueRemove = styled.button`
  background-color: transparent;
  float: right;
  display: block;
  width: 24px;
  height: 24px;
  padding: 0 5px;
  text-align: center;
  border-radius: 12px;
  border: 0 none;
  color: #4b55f6;
  cursor: pointer;
  :hover,
  :focus {
    background-color: #d8eafd;
    /* Fallback color for IE 8 */
    background-color: rgba(0, 113, 230, 0.08);
    color: #0071e6;
  }
  :active {
    background-color: #c2e0ff;
    /* Fallback color for IE 8 */
    background-color: rgba(0, 126, 255, 0.24);
  }
  .hover {
    background-color: #d8eafd;
    /* Fallback color for IE 8 */
    background-color: rgba(0, 113, 230, 0.08);
    color: #0071e6;
  }
`;

const MultiValueRemove = (props: MultiValueRemoveProps<IDataItemProps<any>>) => {
  return (
    <StyledMultiValueRemove
      title="verwijder selectie"
      aria-label="verwijder selectie"
    >

      <components.MultiValueRemove {...props}>
        <CloseIcon sx={{ fontSize: '1.2rem', width: '.75rem', height: '.75rem' }} />
      </components.MultiValueRemove>

    </StyledMultiValueRemove>
  );
};

const StyledDropdownIndicatorContainer = styled.div`
  cursor: pointer;
  color: #4b55f6;
  line-height: 1;
`;
const IconDown = styled(KeyboardArrowDownIcon)`
  fill: currentColor;
`;
const DropdownIndicator = ({ innerProps }: DropdownIndicatorProps<IDataItemProps<any>>) => {
  const { ref, ...restInnerProps } = innerProps;
  return (
    <StyledDropdownIndicatorContainer {...restInnerProps}>
      <IconDown sx={{ fontSize: '1.2rem' }} />
    </StyledDropdownIndicatorContainer>
  );
};

const StyledMenu = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  z-index: 1;
  width: 100%;
  max-height: 267px;
  margin-top: -2px;
  padding: 0 0 6px 0;
  background-color: #fff;
  border: 0 none;
  box-shadow: 0px 5px 20px 0px rgba(75, 85, 246, 0.1);
  box-sizing: border-box;
  border-radius: 0 0 6px 6px;
`;

const Menu = ({ children, innerProps }: MenuProps<IDataItemProps<any>>) => {
  const { ref, ...restInnerProps } = innerProps;
  return (
    <StyledMenu {...restInnerProps}>{children}</StyledMenu>
  );
}

const StyledMenuList = styled.div`
  padding: 0 0 6px 0;
  max-height: 265px;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    width: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    width: 10px;
    border-radius: 10px;
    background-color: #ececf3;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #e3e3ec;
  }
  &::-webkit-scrollbar-button,
  &::-webkit-scrollbar-corner {
    width: 10px;
    height: 1px;
    background-color: transparent;
  }

  .option-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
const MenuList = ({ children, innerRef }: MenuListProps<IDataItemProps<any>>) => (
  <StyledMenuList role="listbox" ref={innerRef}>
    {children}
  </StyledMenuList>
);

const StyledNoOptionsMessage = styled.div`
  padding: 1rem;
  text-align: center;
  color: #666;
`;
const NoOptionsMessage = () => {
  return <StyledNoOptionsMessage>Niets gevonden</StyledNoOptionsMessage>;
};
