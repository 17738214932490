/**
 * Enum with all known Module unique identifiers (System IDs).
 */
 export enum Modules {
    // Shared element definition module, containing the Workflow, Tasks and Audit trail definitions
    Shared = '8d273430-0e4f-4a2b-80e1-8e570e997bfe',
    Publisher = '98c38fc6-b417-4654-8593-c931c7b3c604',
    Core = '17817e12-8ac9-42ad-83bc-fe4a6ad25e8a',
    Performance = '8237ece9-da41-4de8-a913-079b47b69247',
    Studio = '29319eb6-061f-4160-9b40-441c07694fc2',
}