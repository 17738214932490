/**
 * Represents a class that offer tree navigation methods.
 */
class TreeUtils {
  /**
   * Gets a parent node based on its id in the tree.
   *
   * @param rootNode Defines the root node of the tree.
   * @param searchedId Defines the id of searched node.
   */
  public static findParent = <T extends { elementId: string, children: T[] }>(rootNode: T, searchedId: string): T => {
    if (searchedId === rootNode.elementId) {
      return null;
    }

    const stack = [rootNode];
    while (stack.length > 0) {
      const node = stack.pop();
      for (let ii = 0; ii < node.children?.length; ii++) {
        if (node.children[ii]?.elementId === searchedId) {
          return node;
        }
        stack.push(node.children[ii]);
      }
    }
    // Didn't find it. Return null.
    return null;
  };

  /**
   * Gets the full path of a node based on its id in the tree and include the parent id for each page in the path.
   *
   * @param rootNode Defines the root node of the tree.
   * @param searchedId Defines the id of searched node.
   * @param path Defines the path from the rootNode up to the searched node.
   */
  public static findFullPathWithParentId = <T extends { elementId: string, children: T[] }, V extends T & { elementId: string, children: T[], parentId: string }>(
    rootNode: T,
    searchedId: string,
    path: V[] = [],
    parentId = '0'
  ): V[] => {
    const pathCopy = path.slice(0);
    let fullPath: V[];
    const pathPart = rootNode as V;
    pathPart.parentId = parentId;
    pathCopy.unshift(pathPart);

    if (searchedId === rootNode.elementId) {
      return pathCopy;
    }

    for (let ii = 0; ii < rootNode.children?.length; ii++) {
      fullPath = TreeUtils.findFullPathWithParentId<T, V>(rootNode.children[ii], searchedId, pathCopy, rootNode.elementId);
      if (fullPath) {
        return fullPath;
      }
    }

    return null;
  };

  /**
   * Gets the full path of a node based on its id in the tree.
   *
   * @param rootNode Defines the root node of the tree.
   * @param searchedId Defines the id of searched node.
   * @param path Defines the path from the rootNode up to the searched node.
   */
  public static findFullPath = <T extends { elementId: string, children: T[] }>(rootNode: T, searchedId: string, path: T[] = []): T[] => {
    const pathCopy = path.slice(0);
    let fullPath: T[];
    pathCopy.unshift(rootNode);

    if (searchedId === rootNode.elementId) {
      return pathCopy;
    }

    for (let ii = 0; ii < rootNode.children?.length; ii++) {
      fullPath = TreeUtils.findFullPath<T>(rootNode.children[ii], searchedId, pathCopy);
      if (fullPath) return fullPath;
    }

    return null;
  };

  /**
   * Gets a child node based on its id in the tree.
   *
   * @param rootNode Defines the root node of the tree.
   * @param searchedId Defines the id of searched node.
   */
  public static findChildren = <T extends { elementId: string, children: T[] }>(rootNode: T, searchedId: string): T => {
    const stack = [rootNode];
    while (stack.length > 0) {
      const node = stack.pop();
      for (let ii = 0; ii < node.children?.length; ii++) {
        if (node.children[ii]?.elementId === searchedId) {
          return node.children[ii];
        }
        stack.push(node.children[ii]);
      }
    }
    // Didn't find it. Return null.
    return null;
  };

  /**
   * Gets the move options of an container item element.
   *
   * @param rootNode Defines the root node of the tree.
   * @param ciSearchedId  Defines the id of container item searched node.
   */
  public static getMoveOptions = <T extends { elementId: string, children: T[] }>(rootNode: T, ciSearchedId: string): { canMoveUp: boolean, canMoveDown: boolean } => {
    if (!ciSearchedId) return { canMoveUp: false, canMoveDown: false };

    const parentElement = TreeUtils.findParent(rootNode, ciSearchedId);

    if (!parentElement) return { canMoveUp: false, canMoveDown: false };

    const currentIndex = parentElement.children.findIndex((child) => child.elementId === ciSearchedId);

    return { canMoveUp: currentIndex > 0, canMoveDown: currentIndex < parentElement.children.length - 1 };
  };


  /**
   * Gets the depth as number of a node in a tree.
   *
   * @param rootNode Defines the root node of the tree.
   * @param searchedId Defines the id of searched node.
   */
  public static findDepth = <T extends { elementId: string, children: T[] }>(rootNode: T, searchedId: string, counter: number): number => {
    if (searchedId === rootNode.elementId) {
      return counter;
    }

    for (let ii = 0; ii < rootNode.children?.length; ii++) {
      const aux = TreeUtils.findDepth(rootNode.children[ii], searchedId, counter + 1);
      if (aux) {
        return aux;
      }
    }
    return;
  };

}

export { TreeUtils };
