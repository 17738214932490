import styled from 'styled-components';

const Main = styled.div`
    display: grid;
    position: relative;
    height: 100%;
    grid-row: 2 / -1;
    grid-column: 3 / auto;
    grid-template-rows: min-content min-content auto;
    grid-template-columns: auto;
`;

const Component = styled.div`
    position: relative;
    margin-bottom: 4.5rem;
    grid-row: 2 / -1;
    grid-column: 1 / 1;
    animation: 0.2s ease 0s 1 normal none running fQywVe;
`;

const styledComponents = { Component, Main };

export default styledComponents;
