import { GridColumn } from '@liasincontrol/ui-devextreme';
import * as Domain from '@liasincontrol/domain';
import { guidToODataFieldId } from '@liasincontrol/data-service';
import { DefinitionsHelper } from '@liasincontrol/core-service';

/**
 * Represents an utils helper class with miscellaneous List-related methods.
 */
class ListViewUtils {
    /**
     * Returns a list of strings with sorting information, that can be used in an OData sort query statement.
     * @param newObj a newly created <T> object.
     * @param allColumns array with all the columns that can be displayed.
     * @param elementdefinition element definition object for the T type.
     * @param sorting sorting information, as returned by the ListView component.
     * 
     * @template T object type returned by the API (Element-derived) and used as underlying storage in the list.
     * @template TDisplay object type to be displayed in the list. Can be the same as T, or a T-derived class (Like a UI-bound class extended from a domain class).
     */
    public static getSortableColumnsForAPI = <T, TDisplay extends T>(newObj: T, allColumns: GridColumn<TDisplay>[], elementdefinition: Domain.Shared.ElementDefinition, sorting: Sorting<TDisplay>[]): string[] => {
        //Needed to allow list screens to pass TDisplay objects directly, even if we can only work on T (Domain) objects.
        const convertedColumns = allColumns.map(x => x as GridColumn<T>);

        return convertedColumns.map((column) => {
            const sortingColumn = sorting.find((sorted) => sorted.columnName === column.name);
            if (!sortingColumn) {
                return null;
            }

            const fieldId = DefinitionsHelper.fieldNameToFieldId(newObj, column.name, elementdefinition);
            return {
                column: guidToODataFieldId(fieldId || ''),
                direction: sortingColumn.direction
            };
        })
            .filter((column) => column)
            .map((column) => (`${column.column} ${column.direction}`));
    }

    /**
     * Returns a list of field identifiers that can be used in an OData select query statement.
     * @param newObj a newly created <T> object.
     * @param allColumns array with all the columns that can be displayed.
     * @param elementdefinition element definition object for the T type.
     * 
     * @template T object type returned by the API (Element-derived) and used as underlying storage in the list.
     * @template TDisplay object type to be displayed in the list. Can be the same as T, or a T-derived class (Like a UI-bound class extended from a domain class).
     */
    public static getSelectableColumnsForAPI = <T, TDisplay extends T>(newObj: T, allColumns: GridColumn<TDisplay>[], elementdefinition: Domain.Shared.ElementDefinition): string[] => {
        return DefinitionsHelper.getSelectableFieldsForAPI(newObj, allColumns.map((column: GridColumn<T>) => column.name), elementdefinition);
    }

}

export { ListViewUtils };