import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../DataAccessHostUtils';

/**
 * Represents the user groups data accessor.
 */
export class UserGroups {

    /**
     * Gets a list with all the user groups and members.
     */
    public static get = async (): Promise<AxiosResponse<Domain.Shared.UserGroup[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<Domain.Shared.UserGroup[]>(`/api/group`, config);
    };

    /**
     * Creates an user group.
     * @param name The user group's name.
     */
    public static create = async (name: string): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<string>(`/api/group`, { name: name }, config);
    };

    /**
     * Deletes an user group
     * @param userGroupId The unique identifier of the group.
     */
    public static delete = async (userGroupId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.delete<void>(`/api/group/${userGroupId}`, config);
    };

    /**
     * Updates an user group.
     * @param userGroupId The unique identifier of the group.
     * @param userGroup The element which holds the changes to update the grou.
     */
    public static update = async (userGroupId: string, userGroup: Domain.Dto.Shared.UpdateUserGroup): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.put<void>(`/api/group/${userGroupId}`, userGroup, config);
    };

    /**
     * Get the list of roles for a group.
     * @param userGroupId The unique identifier of the group.
     */
    public static getGroupRoles = async (userGroupId: string): Promise<AxiosResponse<Domain.Shared.UserGroupRoles>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<Domain.Shared.UserGroupRoles>(`/api/group/${userGroupId}/roles`, config);
    };

    /**
    * Set a list of roles for a group.
    * @param userGroupId The unique identifier of the group.
    * @param roleIds The role ids.
    */
    public static setGroupRoles = async (userGroupId: string, roleIds: string[]): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.put<void>(`/api/group/${userGroupId}/roles`, { roleIds: roleIds }, config);
    };

}
