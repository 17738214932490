import { Dictionary } from 'lodash';
/**
 * List of known backend error types.
 */
export enum ApiKnownErrorTypes {
    PageTemplateIsUsed = '7f8f8543',
    PageNotLinkedToTemplate = 'f3479420',
    FileSizeTooLarge = 'caa79227',
    SchemaFileTooLarge = '5594cbb6',
    DataSourceTooLarge = '73c358fa',
    AxiosTimeout = '__axios_timeout__',
    ClientSideError = '__clienside_error__',
    InvalidVersion = 'c2cf9b24',
    DefinitionUsed = '881add67',
    PublishProfileIsUsed = '688405d1',
    WorkflowTemplateIsUsed = '80b68643',
    WorkflowTemplateIsUsedInJournalKind = 'd3f5a5a4',
    RoleNameNotUnique = '1e95197c',
    DublicateCombination = '62876670',
    CodeNotUniqueBaseYear = 'e51c9541',
    CodeNotUniqueBaseYearKind = '6fc96a9f',
    InvalidBaseYearCombination = 'fe21e2be',
    SourceGroupNotFound = '84451690',
    BudgetJournalNameNotMatch = '2bfa4c27',
    WorkflowStateLimitReached = 'eb92eae3',
    //
    DeleteOnBudgetJournalFinalStage = 'fa1fbd65',
    EditOnBudgetJournalFinalStage = '8f5de853',
    EditLineOnBudgetJournalFinalStage = '94600a1c',
    DeleteLineOnBudgetJournalFinalStage = 'fbe5a12a',
    CreateLineOnBudgetJournalFinalStage = '41f4ef56',
    PublicationIsClosed = 'd2c37252',
    NoValidLease = '7ba7e168',
    StudioFieldNotUnique = '832f8f1b',
    ElementDefinitionNameNotUniqueAdd = 'c96f6bb6',
    ElementDefinitionNameNotUniqueEdit = '9e603e5',
    PerformanceElementDefinitionNameNotUnique = '39feecb7',

    DataPlatformDataSourceError = 'fb87392d',
    DataPlatformColumnsError = 'bc648493',

    //TextAssistant
    InputTextTooLong = '1b75cf93',
    PromptTextTooLong = '6a39d1a',
    ResultTextIsTooLong = '76cfb671',

    //Performance:
    PerformanceNoValidLeaseForSave = 'c3bcb8f8',
    PerformanceCreateItemLeaseInvalid = '64b34646',
    PerformanceDeleteItemLeaseInvalid = '8d191307',
    PerformanceReorderItemLeaseInvalid = '9e7ff5fb',
    PerformanceItemIsLockedByAnotherUser = 'c5e6457',
    PerformanceMoveItemError = '7470b83e',

    //Studio:
    StudioNoValidLeaseOnDelete = '7fd89279',
    StudioNoValidLeaseForSave = '677904f',
    StudioCreateLeaseInvalid = 'da51abf1',
    StudioDeleteItemLeaseInvalid = 'd19684b2',
    StudioReorderItemLeaseInvalid = 'db671ab5',
    StudioMoveItemError = '72fa178d',

    MeasureMomentDuplicateTag = '706c8572',

    //Publication
    PublicationNameDublicate = 'c19ee09a',
}

export const hierarchyConcurrencyErrorMessage = 'De hiërarchie wordt op dit moment door iemand anders bewerkt. Wacht een ogenblik en probeer het opnieuw.';

export const ApiKnownErrorTypesMessages: Dictionary<string> = {
    [ApiKnownErrorTypes.DataSourceTooLarge]: 'Maximaal aantal rijen: 500. Pas het filter aan.',
    [ApiKnownErrorTypes.InvalidVersion]: 'Doordat dit niet de laatste versie is en de naam al bestaat, kan de versie niet worden bijgewerkt.',
    [ApiKnownErrorTypes.DefinitionUsed]: 'Het wijzigen van de hiërarchie is niet mogelijk omdat er reeds elementen in de hiërarchie aanwezig zijn.',
    [ApiKnownErrorTypes.RoleNameNotUnique]: 'Naam is niet uniek.',
    [ApiKnownErrorTypes.CodeNotUniqueBaseYear]: 'Het veld code dient uniek te zijn binnen een jaar.',
    [ApiKnownErrorTypes.SourceGroupNotFound]: 'Te kopiëren groep niet gevonden.',
    [ApiKnownErrorTypes.BudgetJournalNameNotMatch]: 'De naam komt niet overeen.',
    [ApiKnownErrorTypes.WorkflowStateLimitReached]: 'Maximaal statussen toegestaan.',

    [ApiKnownErrorTypes.DeleteOnBudgetJournalFinalStage]: 'Het journaal is definitief en kan niet meer verwijderd worden.',
    [ApiKnownErrorTypes.EditOnBudgetJournalFinalStage]: 'Het journaal is definitief en kan niet meer bewerkt worden.',
    [ApiKnownErrorTypes.EditLineOnBudgetJournalFinalStage]: 'Het journaal is definitief en kan niet meer bewerkt worden.',
    [ApiKnownErrorTypes.DeleteLineOnBudgetJournalFinalStage]: 'Het journaal is definitief en kan niet meer verwijderd worden.',
    [ApiKnownErrorTypes.CreateLineOnBudgetJournalFinalStage]: 'Het journaal is definitief en kan niet meer bewerkt worden.',
    [ApiKnownErrorTypes.PublicationIsClosed]: 'Deze publicatie kan niet aangepast worden. Mogelijk is deze afgesloten.',
    [ApiKnownErrorTypes.StudioFieldNotUnique]: 'Naam is niet uniek.',
    [ApiKnownErrorTypes.ElementDefinitionNameNotUniqueAdd]: 'De naam is al in gebruik binnen de applicatie of is een gereserveerd woord.',
    [ApiKnownErrorTypes.PerformanceElementDefinitionNameNotUnique]: 'De naam is al in gebruik binnen de applicatie of is een gereserveerd woord.',
    [ApiKnownErrorTypes.DataPlatformDataSourceError]: 'De databron kan niet ververst worden omdat er geen verbinding gemaakt kan worden met het dataplatform. Probeer het later opnieuw.',
    [ApiKnownErrorTypes.DataPlatformColumnsError]: 'De databron kan niet ververst worden omdat er geen verbinding gemaakt kan worden met het dataplatform. Probeer het later opnieuw.',

    [ApiKnownErrorTypes.PerformanceNoValidLeaseForSave]: hierarchyConcurrencyErrorMessage,
    [ApiKnownErrorTypes.PerformanceCreateItemLeaseInvalid]: hierarchyConcurrencyErrorMessage,
    [ApiKnownErrorTypes.PerformanceDeleteItemLeaseInvalid]: hierarchyConcurrencyErrorMessage,
    [ApiKnownErrorTypes.PerformanceReorderItemLeaseInvalid]: hierarchyConcurrencyErrorMessage,
    [ApiKnownErrorTypes.PerformanceItemIsLockedByAnotherUser]: hierarchyConcurrencyErrorMessage,

    [ApiKnownErrorTypes.StudioNoValidLeaseOnDelete]: hierarchyConcurrencyErrorMessage,
    [ApiKnownErrorTypes.StudioNoValidLeaseForSave]: hierarchyConcurrencyErrorMessage,
    [ApiKnownErrorTypes.StudioCreateLeaseInvalid]: hierarchyConcurrencyErrorMessage,
    [ApiKnownErrorTypes.StudioDeleteItemLeaseInvalid]: hierarchyConcurrencyErrorMessage,
    [ApiKnownErrorTypes.StudioReorderItemLeaseInvalid]: hierarchyConcurrencyErrorMessage,
    [ApiKnownErrorTypes.StudioMoveItemError]: hierarchyConcurrencyErrorMessage,
    [ApiKnownErrorTypes.PerformanceMoveItemError]: hierarchyConcurrencyErrorMessage,

    //TextAssistant
    [ApiKnownErrorTypes.InputTextTooLong]: 'De input tekst is te groot voor de tekstassistent om te verwerken. Maak de tekst korter en probeer het opnieuw.',
    [ApiKnownErrorTypes.PromptTextTooLong]: 'De input tekst is te groot voor de tekstassistent om te verwerken. Maak de tekst korter en probeer het opnieuw.',
    [ApiKnownErrorTypes.ResultTextIsTooLong]: 'De input tekst is te groot voor de tekstassistent om te verwerken. Maak de tekst korter en probeer het opnieuw',


    [ApiKnownErrorTypes.MeasureMomentDuplicateTag]: 'Label is niet uniek per moment.',
    [ApiKnownErrorTypes.PublicationNameDublicate]: 'Publicatie naam is niet uniek.',
};
