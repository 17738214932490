import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';
import { oDataResponse } from '../../Shared/oData';

export type GetTasksParams = {
    onlyActive?: boolean;
    onlyNotCompleted?: boolean;
    onlyCurrentUser?: boolean;
    includeStateDetails?: boolean;
    includeUserDetails?: boolean;
    budgetJournalId?: string;
}

/**
 * Represents a data accessor of tasks.
 */
export class TasksDataAccessor {
    /**
     * Gets the collection of the existing tasks.
     */
    public static getAll = async (params: GetTasksParams, odataQuery = ''): Promise<AxiosResponse<oDataResponse<Domain.Finance.Task[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        
        let completeQuery = '';
        if (params && Object.keys(params).length) {
            //convert all object values of {params} to string
            const stringEntries = Object.entries(params).map(([k, v]) => [k, v.toString()]);
            completeQuery = '?' + new URLSearchParams(Object.fromEntries(stringEntries)).toString();
        }
        if (odataQuery) {
            completeQuery += completeQuery ? '&' : '?';
            completeQuery += odataQuery.substring(1);
        }

        return HttpClient.get<oDataResponse<Domain.Finance.Task[]>>(`/api/finance/task${completeQuery}`, config);
    };

}
