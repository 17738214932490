import { createAsyncThunk } from '@reduxjs/toolkit';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import Payload from '../../../../Payload';

const action = createAsyncThunk<Payload<Domain.Publisher.SiteDesign[]>, Payload<{}>, {}>('/sitedesigns/set', async (payload) => {
    return DataAccess.SiteDesigns.getSiteDesigns()
        .then((response) => ({
                data: response.data,
                source: payload.source
            }));
});

export default action;