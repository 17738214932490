import React, { useState } from 'react';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ErrorOverlay, Heading2, PageTitle, Section, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { FilterModel, StructuresFilter } from './StructuresFilter';
import { StructuresGrid } from './StructuresGrid';

export const Overview: React.FC = () => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>();
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [filter, setFilter] = useState<FilterModel>({});

    const onFilter = (filter: FilterModel) => {
        setFilter(filter);
    }

    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Structuren</Heading2>
                </PageTitle>
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <Section look='white'>
                        <StructuresFilter onFilter={onFilter} />
                        <StructuresGrid
                            onDataError={(exception) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception))}
                            lastRefresh={lastRefresh} filter={filter} 
                        />
                    </Section>
                </ErrorOverlay>
            </WrapperContent>
        </Wrapper>
    )
}