import { px, palette } from '@liasincontrol/ui-basics';
import styled from "styled-components";

const ErrorMessages = styled.div`
    border-radius: 5px;
    background-color: ${palette.grey5};
    padding: ${px(10)};
    line-height: 1.5;
    max-height: 560px;
    overflow-y: auto;
`;

export { ErrorMessages };