/**
 * Represents a BudgetAmount
 */

class BudgetAmount {

    baseYear?: number;

    budgetYear?: number;

    journalElementCombinationRK?: string;

    measureMomentTag?: string;

    amount?: number;

    journalElement1RK: string;
    journalElement1Code: string;
    journalElement1Name: string;
    journalElement2RK: string;
    journalElement2Code: string;
    journalElement2Name: string;
    journalElement3RK?: string;
    journalElement3Code?: string;
    journalElement3Name?: string;
    journalElement4RK?: string;
    journalElement4Code?: string;
    journalElement4Name?: string;
    journalElement5RK?: string;
    journalElement5Code?: string;
    journalElement5Name?: string;
    journalElement6RK?: string;
    journalElement6Code?: string;
    journalElement6Name?: string;
    journalElement7RK?: string;
    journalElement7Code?: string;
    journalElement7Name?: string;
    journalElement8RK?: string;
    journalElement8Code?: string;
    journalElement8Name?: string;
    isBenefit: boolean;
    isReserve: boolean;
    isStructural: boolean;
}

export { BudgetAmount };
