import styled from 'styled-components';
import { Button, palette, px } from '@liasincontrol/ui-basics';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

interface IIconProps {
    readonly disabled?: boolean;
    readonly expanded?: boolean;
    readonly height?: number;
}

const SideMenuList = styled.ul`
    list-style: none;
    max-width: 28rem;
    padding: 0; 
    background-color: ${palette.white};
    border-radius: ${px(5)};
`;

const Li = styled.li<IIconProps>`
    position: relative;
    padding: ${px(6)} ${px(4)};
    margin-bottom: ${px(2)};
    text-align: left;
    white-space: nowrap; 
    text-overflow: ellipsis;
    list-style-type: none;
    transition: background-color 0.4s, padding-right 0.4s ease;
    border-radius: ${px(8)};
    color: ${(p: IIconProps) => p.disabled ? palette.grey3 : palette.grey2};
    background-color: ${(p: IIconProps) => p.expanded ? palette.primary3 : palette.white};    
    cursor: ${(p: IIconProps) => (p.disabled ? 'default' : 'pointer')};
    ${({ height }: IIconProps) => height && `
        height: ${px(height)};
        line-height: ${px(height)};
    `}

    &:hover {
        color: ${(p: IIconProps) => p.disabled ? palette.grey3 : palette.grey2};
        background-color: ${palette.primary4};
    };
`;

const MainLineWrap = styled.div`
    height: ${px(40)};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
`;

const DragWrap = styled.span`
    float: left; 
    padding: ${px(8)} 0 0 0;
`;

const StyledIconDrag = styled(DragIndicatorIcon) <IIconProps>`
    color: ${(p: IIconProps) => p.disabled ? '#6E6E77' : '#4b55f6'};
    fill: currentColor;
    cursor: ${(p: IIconProps) => p.disabled ? 'default' : 'move'};
`;

const ActionsWrap = styled.div`
    float: right;    
    > button { margin-left: ${px(4)}; }
`;
const ExtraLineWrap = styled.div`
    padding: ${px(6)};
`;

const PanelSection = styled.div`
    padding-bottom: ${px(16)};
    & > p {
        min-height: 0;
    }
`;

const InputContainer = styled.div`
    position: relative;
`;

const RemoveImageButtom = styled(Button)`
    position: absolute;
    bottom: 0;
    right: 0;
`;

const StyledInput = styled.input`
    width:100%;
    max-width:100%;
    padding:${px(12)} ${px(24)};
    margin:0;
    color:${palette.grey2};
    text-overflow:ellipsis;
    border-radius:${px(4)};
    background:0 none;
    outline:0;
    transition:border-color 0.3s ease;
    background: ${palette.white};
    white-space: nowrap;
    border:${px(2)} solid ${palette.grey4};
    &:hover { border:${px(2)} solid rgba(75, 85, 246, 0.15); }
    &:focus { border:${px(2)} solid rgba(75, 85, 246, 1); } /* dit is #4b55f6 */
`;

const LabelWrap = styled.label`
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: ${px(255)};
    flex-grow: 1;
`;

const PieChartFormatEditorWrapper = styled.div`
    display: flex;
    gap: 0.5rem;
    > div {
        flex-grow: 1;
    }
`
export { SideMenuList, Li, MainLineWrap, DragWrap, ActionsWrap, ExtraLineWrap, PanelSection, InputContainer, StyledInput, LabelWrap, StyledIconDrag, RemoveImageButtom, PieChartFormatEditorWrapper };