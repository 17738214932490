import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';
import { oDataResponseStructured } from '../../Shared';

/**
 * Represents a data accessor of budget overview data.
 */
export class BudgetOverview {

    /**
     * Gets the Budget overview results V2.
     * 
     * @param baseYear Year on which to filter the budget results.
     * @param structureRK Structure rk on which to filter the budget results.
     * @param structureNodeRK Structure Node rk on which to filter the budget results.
     */
    public static getV2 = async (baseYear: number, structureRK: string, structureNodeRK: string): Promise<AxiosResponse<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>(`/api/finance/budget/overview2`, {
            baseYear,
            structureRK,
            structureNodeRK
        }, config);
    };

    /**
     * Gets the Budget drilldown overview results by journalElementKind.
     * 
     * @param journalElementKindRK Kirnal element kins rk on which to filter the budget results.
     * @param transactionKind Name of the transactionKinf (Lasten/Baten).
     * @param journalElementRKs Array of journalelementsRk's already visited.
     */
    public static getJournalElementKindDrilldown = async (journalElementKindRK: string, transactionKind: string, journalElementRKs: string[]): Promise<AxiosResponse<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>(`/api/finance/budget/overview2/drilldown/journalelementkind`, {
            journalElementKindRK,
            transactionKind,
            journalElementRKs
        }, config);
    };

    /**
     * Gets the the budget overview drill down to the JournalCombination and transaction kind (cost/benefit).
     * 
     * @param transactionKind Name of the transactionKinf (Lasten/Baten).
     * @param journalElementRKs Array of journalelementsRk's already visited.
     */
    public static getJournalCombinationDrilldown = async (transactionKind: string, journalElementRKs: string[]): Promise<AxiosResponse<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<oDataResponseStructured<Domain.Finance.BudgetOverviewValue>>(`/api/finance/budget/overview2/drilldown/journalcombination`, {
            transactionKind,
            journalElementRKs
        }, config);
    };


}

export default BudgetOverview;