import React, { useState, useEffect } from 'react';
import { Dictionary } from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { FieldsHelper, OperationsHelper } from '@liasincontrol/core-service';
import AuthService, { UserIdentity } from '@liasincontrol/auth-service';
import { Placeholder, getPlaceholderContent, placeholderCategories } from '../../_shared/Placeholder';

enum SectionContainerKind {
    Logo = 0,
    DocumentTitle = 1,
    PrimaryMenu = 2,
    Quicknav = 3,
    Search = 4,
    Breadcrumbs = 5,
    SecondaryMenu = 6,
    Banner = 7,
    Main = 8,
    Footer = 9,
    Bg1 = 10,
    Bg2 = 11,
    Bg3 = 12,
    Bg4 = 13,
    Custom1 = 14,
    Custom2 = 15,
    Custom3 = 16,
    Custom4 = 17,
    Backbutton = 18,
    GotoTopButton = 19,
    Sidebar = 20,
}

type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Dictionary<Domain.Publisher.Element>,
    readonly: boolean,
    onTemplateChanged?: (operations: Domain.Publisher.Operation[]) => void,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    publicationElement: Domain.Publisher.PublicationElement,
    children: React.ReactNode
};

/**
 * Represents a UI component that renders a section container.
 */
const SectionContainer: React.FC<Props> = (props) => {
    const [currentElement, setCurrentElement] = useState<Domain.Publisher.SectionContainerElement>();
    const { element, elementList, readonly, publicationElement, onTemplateChanged, getElementDefinition } = props;
    const [userIdentity, setUserIdentity] = useState<UserIdentity>();

    useEffect(() => {
        AuthService.getInstance()
            .then(async (auth) => await auth.getUser())
            .then((identity) => {
                if (identity) {
                    setUserIdentity(identity);
                }
            });
    }, []);

    useEffect(() => {
        if (elementList[element.elementId]) {
            const definition = getElementDefinition(elementList[element.elementId].elementDefinitionSystemId, elementList[element.elementId].elementDefinitionId);
            const elementSettings = new Domain.Publisher.SectionContainerElement();

            FieldsHelper.mapObject<Domain.Publisher.SectionContainerElement>(elementSettings, definition.fields, elementList[element.elementId].fields);
            setCurrentElement(elementSettings);
        }
    }, [element.elementId, elementList, getElementDefinition]);

    if (!currentElement) {
        return (<>{props.children}</>);
    }

    const getOtherAndKind = (elementNode: Domain.Publisher.ElementNode): { other: string, kind: Domain.Publisher.OperationKind } => {
        const placeholder = elementNode.children.find(item => elementList[item.elementId].elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.ContainerItemPlaceHolder);
        const placeholderChildrenPlaceholder = placeholder.children.find(item => elementList[item.elementId].elementDefinitionSystemId === Domain.SystemElementDefinitions.Pub.ContainerItemPlaceHolder);

        if (placeholderChildrenPlaceholder) {
            return getOtherAndKind(placeholder);
        }

        if (placeholder.children.length === 0) {
            return { other: placeholder.elementId, kind: Domain.Publisher.OperationKind.ReplaceWith };
        }

        return { other: placeholder.children[placeholder.children.length - 1].elementId, kind: Domain.Publisher.OperationKind.InsertAfter };
    };

    const getPlaceholderElement = (readonly: boolean
        , element: Domain.Publisher.ElementNode
        , previousSiblingName: string
        , publicationElement: Domain.Publisher.PublicationElement
        , getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition
        , onTemplateChanged?: (operations: Domain.Publisher.Operation[]
        ) => void) => {
        if (readonly || !onTemplateChanged) return null;

        return (
            <Placeholder
                wrapperId={element.elementId}
                direction={Domain.Publisher.Direction.Vertical}
                items={getPlaceholderContent(userIdentity)}
                groups={placeholderCategories}
                title={`Open element verticaal toevoegen aan ${previousSiblingName}`}
                publicationElement={publicationElement}
                onCreateOperation={(elementSystemId: Domain.SystemElementDefinitions.Pub) => {
                    const { other, kind } = getOtherAndKind(element);
                    const operations = OperationsHelper.createElement(elementSystemId, other, kind, getElementDefinition);
                    onTemplateChanged(operations);
                }} />
        );
    };

    switch (currentElement.kind) {
        case SectionContainerKind.Main:
            return (
                <main className="site-main has-background">
                    {props.children}
                    {getPlaceholderElement(readonly, element, '-', publicationElement, getElementDefinition, onTemplateChanged)}
                </main>
            );
        case SectionContainerKind.Bg1:
            return (<div className="site-bg-1"></div>);
        case SectionContainerKind.Bg2:
            return (<div className="site-bg-2"></div>);
        case SectionContainerKind.Bg3:
            return (<div className="site-bg-3"></div>);
        case SectionContainerKind.Bg4:
            return (<div className="site-bg-4"></div>);
        case SectionContainerKind.Sidebar:
            return (
                <aside className="site-aside1">
                    {props.children}
                    {getPlaceholderElement(readonly, element, '-', publicationElement, getElementDefinition, onTemplateChanged)}
                </aside>
            );
        default:
            return (<>{props.children}</>);
    }
};

export default SectionContainer;
