/**
 * Helper class that offers strinf/text manipulation methods.
 */
export class StringUtils {

    /**
     * Maps a html plain text to raw markup.
     *
     * @param htmlPlainText Defines the html plain text.
     * @returns the raw markup.
     */
    public static toRawMarkup = (htmlPlainText: string): { __html: string } => {
        const rawMarkup = htmlPlainText?.replace(/(<? *script)/gi, 'illegalscript');
        return { __html: rawMarkup };
    };

}
