import { createAsyncThunk } from '@reduxjs/toolkit';
import { Shared as SharedDataAccess } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';

const action = createAsyncThunk<Domain.Shared.SvgIcon[], void, {}>('/icons/set', async () => {
    return SharedDataAccess.SvgIcon.getAll()
        .then((response) => response.data)
});

export default action;
