import React, { useState, useEffect, useMemo, useContext } from 'react';
import _ from 'lodash';
import { FieldsHelper, JsonUtils } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ThumbnailImg } from '@liasincontrol/ui-basics';
import { CreateCustomDataSource, ILsColumnProps, ILsContentReadyEvent, LsDataTable } from '@liasincontrol/ui-devextreme';
import { DataSourceControlsUtils, PublicationContext } from '../../../../../../helpers';
import { AppSettingsService } from '@liasincontrol/config-service';
import Styled from './index.styled';
import SharedStyled from '../../SharedControlElements/index.styled';
import { getIconSize } from '../../SharedControlElements/helper';

type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Record<string, Domain.Publisher.Element>,
    publicationId: string,
    publicationElement: Domain.Publisher.PublicationElement,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onLoadAttachment: (id: string) => Promise<Blob>,
    onContentReady?: (event: ILsContentReadyEvent) => void,
    hasErrorInSettings?: (elementId: string, hasError: boolean, message?: string, keepError?: boolean) => void,
    readonly: boolean,
    icons?: Record<string, Domain.Shared.SvgIcon>,
    variables?: Domain.Shared.ComplexFieldItem[]
}

type DataSourceElement = Domain.Publisher.DataSourceElement;

/**
 * Represents a UI component that renders a datatable control.
*/
const DataTableControl: React.FC<Props> = (props) => {
    const { element, elementList, onLoadAttachment, getElementDefinition } = props;
    const [dataSource, setDataSource] = useState<DataSourceElement>();
    const [dataSourceData, setDataSourceData] = useState<{ data: [], fields: ILsColumnProps[] }>({ data: [], fields: [] });
    const pubContext = useContext(PublicationContext);
    const dataTableElement = elementList?.[element.elementId];

    const charWidth = useMemo(() => measureTextLength('O', `${props.publicationElement.bodyFontSize}px`, "Roboto", "normal"), [props.publicationElement.bodyFontSize]);

    const controlSettings = useMemo(() => {
        if (!dataTableElement) return;
        const definition = getElementDefinition(dataTableElement.elementDefinitionSystemId, dataTableElement.elementDefinitionId);
        const controlSettings = new Domain.Publisher.DataTableControl();
        FieldsHelper.mapObject<Domain.Publisher.DataTableControl>(controlSettings, definition.fields, dataTableElement.fields);
        return controlSettings;
    }, [dataTableElement]);

    useEffect(() => {
        const loadDataSourceAsync = async () => {
            if (controlSettings?.dataSourceId) {
                const dsElement = await pubContext.loadDataSourceElement(controlSettings.dataSourceId);
                setDataSource(dsElement);
            } else {
                setDataSource(undefined);
            }
        };
        loadDataSourceAsync();
    }, [controlSettings?.dataSourceId]);

    useEffect(() => {
        props.hasErrorInSettings?.(element.elementId, false);
        if (!dataSource?.dataFileId || !dataSource?.schemaFileId || dataSource?.failedRefresh) {
            if (dataSource?.failedRefresh) props.hasErrorInSettings?.(element.elementId, true, 'Fout opgetreden bij het verversen.', true);
            setDataSourceData({ data: [], fields: [] });
        } else {
            const loadBlobsAsync = async () => {
                try {
                    const responses = await Promise.all([
                        onLoadAttachment(dataSource.dataFileId),
                        onLoadAttachment(dataSource.schemaFileId)
                    ]);
                    const blobs = await Promise.all([
                        responses[0].text(),
                        responses[1].text()
                    ]);
                    setDataSourceData({
                        data: JSON.parse(blobs[0]),
                        fields: DataSourceControlsUtils.mapsDataSourceColumnFields(
                            JSON.parse(blobs[1]),
                            AppSettingsService.getAppSettings().Publisher.Controls.DataTable.NumberOfVisibleColumns
                        )
                    });
                } catch (e) {
                    props.hasErrorInSettings?.(element.elementId, true, 'Databron fout.');
                }
            };

            loadBlobsAsync();

        }
    }, [element.elementId, dataSource?.id, dataSource?.dataFileId, dataSource?.schemaFileId, dataSource?.failedRefresh, onLoadAttachment]);

    if (!(controlSettings && dataSourceData && dataSource?.id && dataSource?.dataFileId && dataSourceData.data)) {
        return <SharedStyled.ThumbnailWrapper><ThumbnailImg variant='undrawSpreadsheets' /></SharedStyled.ThumbnailWrapper>;
    }

    const columnSettings = JsonUtils.toJson(controlSettings.columnSettings, []);
    const columnData = DataSourceControlsUtils.getColumnData(columnSettings);
    // Display an empty grid if you have data but the schema is not refreshed yet.
    const showData = dataSourceData.data?.length >= 0 && columnData.columns?.length > 0;
    const iconSize = getIconSize(+props.publicationElement.bodyFontSize);
    const filter = DataSourceControlsUtils.getFilter(controlSettings.filter, props.variables);

    const customSource = CreateCustomDataSource(showData ? dataSourceData?.data : [], undefined, pagingData, filter, undefined, false);

    return (
        <>
            {controlSettings.title && <SharedStyled.Title h3FontSize={props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}>{controlSettings.title}</SharedStyled.Title>}
            {controlSettings.description && <SharedStyled.Description textFontColor={props.publicationElement?.bodyFontColor} textFontSize={props.publicationElement.bodyFontSize}>{controlSettings.description} </SharedStyled.Description>}

            <Styled.DataTableWrapper
                primaryColor={props.publicationElement.primaryColor}
                primaryTextColor={props.publicationElement.primaryContrastColor}
                textColor={props.publicationElement.bodyFontColor} textFontSize={props.publicationElement.bodyFontSize}
                h1FontSize={+props.publicationElement?.h1FontSize} h1FontColor={props.publicationElement?.h1FontColor}
                h2FontSize={+props.publicationElement?.h2FontSize} h2FontColor={props.publicationElement?.h2FontColor}
                h3FontSize={+props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}
                editMode={false}>
                <LsDataTable
                    className='data-table-control'
                    dataSource={customSource}
                    columns={columnData.columns}
                    groupItems={columnData.groups}
                    summaryItems={columnData.summaryItems}
                    publicationElement={props.publicationElement}
                    title={controlSettings.title}
                    description={controlSettings.description}
                    paging={pagingData}
                    panelsVisible={controlSettings.gridPanelIsVisible}
                    icons={props.icons}
                    iconSize={iconSize}
                    totalSummaryText={controlSettings.grandSummaryText}
                    characterWidth={charWidth}
                    columnHidingEnabled={controlSettings.columnsAutoHide}
                />
            </Styled.DataTableWrapper>
        </>
    );
}

const pagingData = { enabled: false, currentPage: 0, pageSize: AppSettingsService.getAppSettings().Publisher.Controls.DataTable.PageSize, totalCount: 0 };

const measureTextLength = (text, fontSize, fontFamily, fontWeight) => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = `${fontWeight} ${fontSize} ${fontFamily}`;
    return Math.ceil(+context.measureText(text).width);
};

export default React.memo(DataTableControl, (prevProps, nextProps) => {
    return _.isEqual(prevProps.elementList[prevProps.element.elementId], nextProps.elementList[nextProps.element.elementId])
        && prevProps.readonly === nextProps.readonly && prevProps.icons === nextProps.icons && prevProps.variables === nextProps.variables;
});
