import * as Domain from '@liasincontrol/domain';

/**
 * Defines the service action types used for filtering the store's state data.
 */
export enum ActionSource {

    Publication = 1,

    Performance = 2,

    Financial = 3,

    Workflow = 4,

    Studio = 5,

}

const actionSourceToEndpoint = (source: ActionSource): Domain.Shared.ApiEndpoint => {
    switch (source) {
        case ActionSource.Publication:
        case ActionSource.Performance:
        case ActionSource.Workflow:
        case ActionSource.Studio:
            return Domain.Shared.ApiEndpoint.Pub;

        default:
            return undefined;
    }
};

export { actionSourceToEndpoint };
export default ActionSource;