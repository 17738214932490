import React from "react";
import { useState } from "react";
import { useBaseYears } from "../../shared/hooks";
import { LayoutField } from '@liasincontrol/ui-basics';
import { SelectElement, TextElement } from '@liasincontrol/ui-elements';


export type FilterModel = {
    baseYear?: number;
    journalElementCombinationRK?: string;
    code?: string;
    measureMomentTag?: string;
}

type Props = {
    onFilter: (filter: FilterModel) => void;
}

export const ObligationsFilter: React.FC<Props> = (props) => {
    const [filter, setFilter] = useState<FilterModel>({});
    const baseYears = useBaseYears();

    const onFilterChanged = (key: keyof FilterModel, value: number | string) => {
        setFilter((prev) => {
            const newValue = { ...prev, [key]: value };
            props.onFilter(newValue);
            return newValue;
        });
    }

    return <>
        <LayoutField left={1} top={1} width={2} height={1}>
            <SelectElement<number>
                id='filter-base-year'
                label='Basisjaar'
                optionItems={baseYears.items || []}
                value={filter.baseYear}
                clearable={true}
                searchable={false}
                editorSettings={{
                    disabled: false,
                    restrictions: { required: false },
                    validationErrors: [],
                    onChange: (item) => onFilterChanged('baseYear', item),
                }}
            />
        </LayoutField>
        <LayoutField left={3} top={1} width={2} height={1}>
            <TextElement id='filter-journal-combination'
                value={filter.journalElementCombinationRK}
                label='Combinatie'
                editorSettings={{
                    disabled: false,
                    restrictions: { required: false },
                    validationErrors: [],
                    onChange: (value) => onFilterChanged('journalElementCombinationRK', value),
                }}
            />
        </LayoutField>
        <LayoutField left={5} top={1} width={2} height={1}>
            <TextElement id='filter-code'
                value={filter.code}
                label='Code'
                editorSettings={{
                    disabled: false,
                    restrictions: { required: false },
                    validationErrors: [],
                    onChange: (value) => onFilterChanged('code', value),
                }}
            />
        </LayoutField>
        <LayoutField left={7} top={1} width={2} height={1}>
            <TextElement id='filter-measure-moment-tag'
                value={filter.measureMomentTag}
                label='Moment label'
                editorSettings={{
                    disabled: false,
                    restrictions: { required: false },
                    validationErrors: [],
                    onChange: (value) => onFilterChanged('measureMomentTag', value),
                }}
            />
        </LayoutField>
    </>
};