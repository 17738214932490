import React from 'react';
import styled from 'styled-components';
import { px } from '../style';
import { palette } from '../styleguide';

import { BasicText } from '../primitives/typography';
import { NumberBox } from 'devextreme-react/number-box';

export const InputNumber = React.memo((props: IInputNumberProps) => {
    return (
        <StyledInput
            id={`input-number-${props.id}`}
            min={props.minimumValue}
            max={props.maximumValue}
            defaultValue={props.startValue}
            showSpinButtons={false}
            disabled={props.disabled}
            error={props.error}
            focused={props.focused}
            onFocusOut={props.onBlur}
            onValueChange={props.onChange}
            onFocusIn={props.onFocus}
            success={props.success}
            value={Number(props.value)}
            placeholder={props.placeholder}
            valueChangeEvent='keyup'
            format={props.format}
            prefix={props.prefix}
            postfix={props.postfix}
        >
        </StyledInput>
    );
});

export interface IInputNumberProps {
    readonly id?: string;
    readonly value: number;
    readonly focused?: boolean;
    readonly startValue?: number;
    readonly minimumValue?: number;
    readonly maximumValue?: number;
    readonly error?: boolean;
    readonly success?: boolean;
    readonly onFocus?: () => void;
    readonly onBlur?: () => void;
    readonly onChange?: (value: number) => void;
    readonly disabled?: boolean;
    readonly placeholder?: string;
    readonly format?: string;
    readonly prefix?: string;
    readonly postfix?: string;
}

/* tslint:disable:no-magic-numbers */
const BasicTextInput = BasicText.withComponent(NumberBox);
const StyledInput = styled(BasicTextInput) <IInputNumberProps>`
    width: 100%;
    max-width: 100%;
    margin: 0;
    color: ${palette.grey2};
    text-overflow: ellipsis;
    border-radius: 4px;
    background: 0 none;
    outline: 0;
    transition: border-color 0.3s ease;
    background: ${palette.white};
    white-space: nowrap;
    ::-ms-clear {
        display: none;
    }

    ${(p) => !!p.prefix && `
        ::before {
          content: '${p.prefix}';
          color: ${palette.primary2};
          font-size: 1.1rem;
          float: right;
          margin: 0 6px 0 0;
        }`};

    ${(p) => !!p.postfix && `
        ::after {
          content: '${p.postfix}';
          color: ${palette.primary2};
          font-size: 1.1rem;
          float: right;
          margin: 0 0 0 6px;
        }`};

    //Override the DevExtreme default properties
    &.dx-editor-outlined {
        background: #fff;
        border: 2px solid ${palette.grey4} !important;
        border-radius: 4px;
        display: flex;
    }

    &.dx-state-hover {
        border: 2px solid  rgba(75, 85, 246, 0.15) !important;
    }

    &.dx-state-focused {
        border: 2px solid rgba(75, 85, 246, 1) !important;
    }

    .dx-texteditor-input {
        padding: ${px(12)} ${px(16)};
        min-height: ${px(20)};
    }
`;
