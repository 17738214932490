import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class PivotTableControl extends BaseControlElement {
    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Filter)
    filter: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSource)
    dataSourceId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Title)
    title: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PivotTableDescription)
    description: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PivotGridFields)
    fields: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ShowColumnGrandTotals)
    showColumnGrandTotals = true;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ShowColumnTotals)
    showColumnTotals = true;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ShowRowGrandTotals)
    showRowGrandTotals = true;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ShowRowTotals)
    showRowTotals = true;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PivotTableGrandTotalCaption)
    grandTotalCaption: string;

}

export default PivotTableControl;