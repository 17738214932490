import { useSelector, useDispatch } from "react-redux";
import { State, AjaxRequestStatus, StructuresActionCreator } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';

type StrucuresState = {
    items: Domain.Finance.Structure[],
    status: AjaxRequestStatus,
};

export const useStructures = () => {
    const structures = useSelector<State, StrucuresState>((state) => state.finance.structures);
    const dispatch = useDispatch();

    if (structures.status === AjaxRequestStatus.NotSet) {
        dispatch(StructuresActionCreator.set());
    }

    return structures;
}
