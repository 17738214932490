import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { IDataItemProps } from '@liasincontrol/ui-basics';
import { ActionType, Actions } from '@liasincontrol/userrights-service';
import { EdgeToolbarContent } from '../index.styled';
import { PageNameSettings } from './PageName';
import { PageIconSettings } from './PageIcon';
import { PagePublicationProfilesSettings } from './PagePublicationProfiles';
import { PageTemplateSettings } from './PageTemplate';
import { PageImageSettings } from './PageImage';
import { PageDescriptionSettings } from './PageDescription';
import { PageTitleSettings } from './PageTitle';

type Props = {
    readonly rootId: string,
    readonly sitemapNode: Domain.Publisher.SitemapNode,
    readonly selectedTemplateElement: Domain.Publisher.TemplateElement,
    readonly selectedPageElement: any,
    readonly selectedPublishProfiles: IDataItemProps<any>[],
    readonly availablePublishProfiles: IDataItemProps<any>[],
    readonly publicationId: string,
    readonly pageDesigns: Domain.Publisher.PageDesign[],
    readonly elementdefinitions: Record<string, Domain.Shared.ElementDefinition>,
    readonly hasAvailableTemplates: boolean,
    readonly attachment: { [x: string]: Domain.Shared.Attachment },
    readonly icons: Record<string, Domain.Shared.SvgIcon>,
    onChangeTemplate: (newTemplateId: string, newTemplateName: string, pageIds: string[], parentId?: string) => void,
    onChangePagePublish: (publishProfiles: IDataItemProps<any>[]) => void,
    canPerformAction?: (action: Actions, actionType: ActionType) => boolean,
    onRefresh: () => void,
    onLoadAttachment: (id: string) => Promise<Blob>,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onRemoveAttachment: (id: string) => void,
};

enum Sections {
    PageDescriptionSettings = "PageDescriptionSettings",
    PageNameSettings = "PageNameSettings",
    PageTitleSettings = "PageTitleSettings",
    PageTemplateSettings = "PageTemplateSettings",
    PageIconSettings = "PageIconSettings",
    PageImageSettings = "PageImageSettings",
    PagePublicationProfilesSettings = "PagePublicationProfilesSettings",
}
/**
 * Represents a UI component that renders the current page general information.
 */
export const Information: React.FC<Props> = (props) => {
    const [edited, setEdited] = useState<Sections>();
    const currentImageAttachment = { attachments: props.sitemapNode.image ? [props.attachment[props.sitemapNode.image]] : null, image: props.sitemapNode.image }
    const pageChanges = {
        name: props.sitemapNode.elementName,
        description: props.sitemapNode.description,
        iconId: props.sitemapNode.iconId,
        attachments: currentImageAttachment.attachments,
        image: currentImageAttachment.image,
        title: props.selectedPageElement.title
    }

    const onUpdatePageIcon = (iconId: string) => {
        const _pageChanges = { ...pageChanges, iconId };

        return DataAccess.SiteMap
            .updatePage(props.publicationId, props.sitemapNode.elementId, _pageChanges)
            .then(props.onRefresh);
    };

    const onUpdatePageName = (name: string) => {
        const _pageChanges = { ...pageChanges, name };

        return DataAccess.SiteMap
            .updatePage(props.publicationId, props.sitemapNode.elementId, _pageChanges)
            .then(() => {
                props.onRefresh();
                setEdited(undefined);
            });
    };

    const onUpdatePageTitle = (title: string) => {
        const _pageChanges = { ...pageChanges, title };

        return DataAccess.SiteMap
            .updatePage(props.publicationId, props.sitemapNode.elementId, _pageChanges)
            .then(() => {
                props.onRefresh();
                setEdited(undefined);
            });
    };

    const onUpdateDescription = (description: string) => {
        const _pageChanges = { ...pageChanges, description };

        return DataAccess.SiteMap
            .updatePage(props.publicationId, props.sitemapNode.elementId, _pageChanges)
            .then(() => {
                props.onRefresh();
                setEdited(undefined);
            });
    };

    const onSaveImage = (imageChanges) => {
        const _pageChanges = { ...pageChanges, ...imageChanges };

        return DataAccess.SiteMap
            .updatePage(props.publicationId, props.sitemapNode.elementId, _pageChanges)
            .then(() => {
                props.onRefresh();
                setEdited(undefined);
            });
    };

    return (
        <EdgeToolbarContent>
            <PageNameSettings
                sitemapNode={props.sitemapNode}
                updateTitle={onUpdatePageName}
                isEditing={() => setEdited(Sections.PageNameSettings)}
                disabled={!props.hasAvailableTemplates || (!!edited && edited !== Sections.PageNameSettings)}
            />
            <PageTitleSettings
                pageElement={props.selectedPageElement}
                updateTitle={onUpdatePageTitle}
                isEditing={() => setEdited(Sections.PageTitleSettings)}
                disabled={!props.hasAvailableTemplates || (!!edited && edited !== Sections.PageTitleSettings)}
            />
            <PageTemplateSettings
                disabled={!props.hasAvailableTemplates || (!!edited && edited !== Sections.PageTemplateSettings)}
                elementdefinitions={props.elementdefinitions}
                onChangeTemplate={props.onChangeTemplate}
                pageDesigns={props.pageDesigns}
                publicationId={props.publicationId}
                rootId={props.rootId}
                selectedTemplateName={props.selectedTemplateElement?.name}
                sitemapNode={props.sitemapNode}
            />
            <PageDescriptionSettings
                sitemapNode={props.sitemapNode}
                updateDescription={onUpdateDescription}
                isEditing={() => setEdited(Sections.PageDescriptionSettings)}
                disabled={!props.hasAvailableTemplates || (!!edited && edited !== Sections.PageDescriptionSettings)}
            />
            <PageIconSettings
                sitemapNode={props.sitemapNode}
                icons={props.icons}
                disabled={!props.hasAvailableTemplates || (!!edited && edited !== Sections.PageIconSettings)}
                onUpdateIcon={onUpdatePageIcon}
            />
            <PageImageSettings
                image={props.attachment[props.sitemapNode.image]}
                disabled={!props.hasAvailableTemplates || (!!edited && edited !== Sections.PageImageSettings)}
                sitemapNode={props.sitemapNode}
                onLoadAttachment={props.onLoadAttachment}
                onRemoveAttachment={props.onRemoveAttachment}
                onUploadAttachment={props.onUploadAttachment}
                onSaveImageSettings={onSaveImage}
                isEditing={() => setEdited(Sections.PageImageSettings)}
            />
            <PagePublicationProfilesSettings
                availablePublishProfiles={props.availablePublishProfiles}
                selectedPublishProfiles={props.selectedPublishProfiles}
                onChangePagePublish={props.onChangePagePublish}
                canPerformAction={props.canPerformAction}
                disabled={!props.hasAvailableTemplates || (!!edited && edited !== Sections.PagePublicationProfilesSettings)}
            />
        </EdgeToolbarContent>
    );
};
