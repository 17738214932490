import styled, { css } from 'styled-components';
import { palette } from '../styleguide';
import { cssBasicText } from '../primitives';
import { px } from '../style';

const AnnouncementContainer = styled.div<{ renderAs?: 'widget' | 'box' }>`
    ${cssBasicText}
    display: flex;
    flex-direction: column;    
    ${(p) => p.renderAs && p.renderAs === 'widget'
        ? css`height: 100%;`
        : css`position: fixed; bottom: 0; left: 0; justify-content: end;`} 
    width: inherit;   
`;

const Announcement = styled.div<{ renderAs?: 'widget' | 'box' }>`
    ${(p) => p.renderAs && p.renderAs === 'widget'
        ? css`background-color: ${palette.white}; padding: ${px(10)}; margin-top: 10px;`
        : css`background-color: ${palette.grey3c}; padding: ${px(20)}; margin: 10px;`} 
    border-radius: 10px;
    max-height: ${px(210)};
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const AnnouncementTitle = styled.div`
    display: flex;
    padding-bottom: 10px;
`;

const styledComponents = { AnnouncementTitle, Announcement, AnnouncementContainer };
export default styledComponents;
