import { px } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

const StaticAnchor = styled.a`
    display: block;
    min-height: ${px(48)};
    padding: ${px(12)} 0;
    border-bottom: ${px(2)} solid #f4f4f8;
    white-space: pre-wrap;
`;

export { StaticAnchor };