import React from "react";
import { useCallback } from "react";
import { ValueChangedEvent } from "devextreme/ui/text_area";
import { withField1, WithFieldBaseType } from "@liasincontrol/ui-basics";
import Styled from './index.styled';

export type TextAreaFieldProps = WithFieldBaseType<string> & {
    autoExpand?: boolean;
    withTextAssistant?: boolean,
    postfix?: string;
    prefix?: string;
    rows?: number;
    maxLength?: number;
    placeholder?: string;
}

export const TextAreaField = withField1<string, TextAreaFieldProps>(
    (props) => {
        const { rows, onChange, label, ...rest } = props;
        const onValueChanged = useCallback((e: ValueChangedEvent) => {
            onChange?.(e.value);
        }, [onChange]);

        return <Styled.StyledTextArea
            {...rest}
            inputAttr={{
                rows: props.rows,
                'aria-label': label,
            }}
            onValueChanged={onValueChanged}
        />
    }
);