import { useState } from 'react';

export const useUniqueId = (base = ``, prefer?: string) => {
    const [uniqueId] = useState(() => `${base}:${next()}`);
    return prefer || uniqueId;
};

const next = (() => {
    let gid = Date.now();
    return () => (++gid).toString(36);
})();
