import React, { useEffect, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { AutoFocus, PageTitle, EditingToolbar, ModalDialog, Heading1 } from '@liasincontrol/ui-basics';
import { FormMode } from '@liasincontrol/core-service';
import { MeasureMomentForm } from '../../MeasureMomentForm';

type Props = {
    viewMode: FormMode,
    measureMoment: Domain.Shared.MeasureMoment,
    fieldDefinitions: Record<string, Domain.Shared.FieldDefinition>,
    externalError?: { id: string, message: string },
    onSave: (measureMoment: Domain.Shared.MeasureMoment) => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders the measure moment dialog page.
 */
export const MeasureMomentFormDialog: React.FC<Props> = (props) => {
    const [formInfo, setFormInfo] = useState<{ value: Domain.Shared.MeasureMoment, isValid: boolean }>({ value: undefined, isValid: true });
    const [selectedMeasureMoment, setSelectedMeasureMoment] = useState<Domain.Shared.MeasureMoment>(undefined);

    useEffect(() => {
        if (!props.measureMoment) {
            return;
        }
        setSelectedMeasureMoment(props.measureMoment)
    }, [props.measureMoment]);

    return (
        <ModalDialog
            customPadding
            modalDialogStyle="custom"
            toolbars={<EditingToolbar
                id="popup-new-measure-moment"
                look="default"
                isVisible={true}
                isValid={formInfo.isValid}
                onSave={() => props.onSave(formInfo.value)}
                onCancel={props.onCancel}
            />}>
            <PageTitle>
                <Heading1>Moment {props.viewMode === FormMode.AddNew ? 'aanmaken' : 'bewerken'}</Heading1>
            </PageTitle>
            <AutoFocus>
                <MeasureMomentForm
                    measureMoment={props.viewMode !== FormMode.AddNew && selectedMeasureMoment}
                    mode={props.viewMode}
                    onFormDataChanged={(formData) => {
                        setFormInfo({ value: { ...selectedMeasureMoment, ...formData.value }, isValid: formData.isValid });
                    }}
                    externalError={props.externalError}
                />
            </AutoFocus>
        </ModalDialog>
    );
};