import { useEffect, useState } from 'react';

type Bounds = { left: number; top: number; width: number; height: number; right: number; bottom: number };
const emptyBounds = { left: 0, top: 0, width: 0, height: 0, right: 0, bottom: 0 };

const boundsOfElement = (el: HTMLElement | null) => {
    return cloneBounds(el ? el.getBoundingClientRect() : emptyBounds);
};
const cloneBounds = ({ left, top, width, height, right, bottom }: Bounds) => {
    return { left, top, width, height, right, bottom };
};

const merge = (updated: Bounds) => (old: Bounds) => {
    const changed =
        updated.left !== old.left ||
        updated.top !== old.top ||
        updated.width !== old.width ||
        updated.height !== old.height ||
        updated.right !== old.right ||
        updated.bottom !== old.bottom;
    return changed ? updated : old;
};

export const useComponentBounds = (ref: React.RefObject<HTMLElement>) => {
    const [bounds, setBounds] = useState(emptyBounds);

    useResizeObserver(ref, (entries) => {
        if (entries.length) {
            setBounds(merge(cloneBounds(entries[0].contentRect)));
        } else {
            setBounds(merge(boundsOfElement(ref.current)));
        }
    });

    return bounds;
};

// Reduced functionality and definiton of ResizeObserver
//

const useResizeObserver = (ref: React.RefObject<Element>, onResize: ResizeObserverCallback) => {
    useEffect(() => {
        if (typeof window !== `undefined`) {
            if (typeof window.ResizeObserver === `function`) {
                if (ref.current) {
                    const resizeObserver = new window.ResizeObserver(onResize);
                    resizeObserver.observe(ref.current);
                    return () => {
                        resizeObserver.disconnect();
                    };
                } else {
                    onResize([]);
                }
            } else {
                if (ref.current) {
                    const fallback = () => onResize([]);
                    window.addEventListener(`resize`, fallback);
                    fallback();
                    return () => {
                        window.removeEventListener(`resize`, fallback);
                    };
                } else {
                    onResize([]);
                }
            }
        }
    }, [ref, onResize]);
};

declare global {
    interface Window {
        ResizeObserver: ResizeObserver;
    }
}

interface ResizeObserver {
    new (callback: ResizeObserverCallback): ResizeObserver;
    observe: (target: Element) => void;
    disconnect: () => void;
}

interface ResizeObserverCallback {
    (entries: ResizeObserverEntry[]): void;
}

interface ResizeObserverEntry {
    readonly contentRect: DOMRectReadOnly;
}

interface DOMRectReadOnly {
    readonly width: number;
    readonly height: number;
    readonly top: number;
    readonly right: number;
    readonly bottom: number;
    readonly left: number;
}
