import React, { useState } from 'react';
import { ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';
import { SelectElement } from '@liasincontrol/ui-elements';
import { WorkflowStateEntry } from '..';

type Props = {
    currentState: string,
    workflowTemplateStates: WorkflowStateEntry[],
    onConfirm: (targetState: string) => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders the workflow state delete form.
 */
export const DeleteWorkflowStateDialog: React.FC<Props> = (props) => {
    const [target, setTarget] = useState<WorkflowStateEntry>();

    const optionItems = props.workflowTemplateStates?.filter(state => !!!state.replaceWithStateName && state.id !== props.currentState) || []

    return (<ModalDialog
        id='dialog-delete-workflow-status'
        settings={{
            look: 'message',
            title: 'Verwijder status',
            footer: <ModalDialogFooter
                leftButtonText='Annuleren'
                onLeftButtonClick={props.onCancel}
                rightButtonText='Verwijder status'
                rightButtonDisabled={!target}
                onRightButtonClick={() => props.onConfirm(target.id)} />
        }}
    >
        <Text danger={true} value='Weet u zeker dat u de status wilt verwijderen? U dient een andere status op te geven zodat openstaande taken verplaatst kunnen worden.' />
        <SelectElement<WorkflowStateEntry>
            id='select-target-status-field'
            label='Verplaatsen naar'
            displayExpr='name'
            optionItems={optionItems}
            value={target}
            clearable={true}
            searchable={false}
            editorSettings={{
                disabled: false,
                restrictions: { required: true },
                validationErrors: [],
                onChange: setTarget,
            }}
        />
    </ModalDialog>
    );
};
