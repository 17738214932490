import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents the lease data accessor.
 */
export class Leases {
    /**
     * Get the lease information of an element.
     *
     * @param subjectId An identifier for the object for which a lease must be acquired or renewed
     */
    public static acquireLease = async (subjectId: string): Promise<AxiosResponse<Domain.Shared.AcquireLease>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        const body = {};
        return HttpClient.post<Domain.Shared.AcquireLease>(`/api/lease/${subjectId}`, body, config);
    };

    /**
     * Deletes a lease.
     *
     * @param subjectId An identifier for the object for which a lease must be deleted
     */
    public static deleteLease = async (subjectId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.delete<void>(`/api/lease/${subjectId}`, config);
    };
}
