import React, { useState, useEffect, useContext } from 'react';
import format from 'number-format.js';
import Circle from '@mui/icons-material/Circle';
import { Dictionary } from 'lodash';
import { DateUtils, FieldsHelper, IconHelper, JsonUtils, StringUtils } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { LsAccordion } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { ActionSource } from '@liasincontrol/redux-service';
import Styled from './index.styled';
import SharedStyled from '../../SharedControlElements/index.styled';
import { getIconSize } from '../../SharedControlElements/helper';
import { PublicationContext } from '../../../../../../helpers';

type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Dictionary<Domain.Publisher.Element>,
    publicationElement: Domain.Publisher.PublicationElement,
    hierarchies: Domain.Shared.HierarchyMap,
    readonly: boolean,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    getDefinition: (id: string, source: ActionSource) => Domain.Shared.ElementDefinition,
    loadPerformanceHierarchy?: (measureMomentId: string) => void,
    loadStudioHierarchy?: (hierarchyId: string, hierarchyDefinitionId: string, measureMomentId: string) => void,
};

const availableStatuses: string[] = [
    Domain.SystemFieldDefinitions.Performance.GoalStatus,
    Domain.SystemFieldDefinitions.Performance.AchievementStatus,
    Domain.SystemFieldDefinitions.Performance.ActivityStatus
] as string[];

const dummyData = Array(5).fill('').map((_, i) => ({ i: i, title: `Titel ${i + 1}` }));

type PerformanceHierarchyItemElement = Domain.Performance.HierarchyItem & { progress?: string, type: string, title?: string | Element };

type StudioHierarchyItemElement = Domain.Studio.HierarchyItem & { type: string, title?: string | Element };

type HierarchyItemElement = PerformanceHierarchyItemElement | StudioHierarchyItemElement;

/**
 * Represents a UI component that renders a hierarchy data accordion control.
 */
const AccordionDataControl: React.FC<Props> = (props) => {
    const [controlSettings, setControlSettings] = useState<Domain.Publisher.AccordionDataControl>();
    const [controlHierarchyItems, setControlHierarchyItems] = useState<{
        filteredHierarchyItems: HierarchyItemElement[],
        definition: Domain.Shared.ElementDefinition,
        selected: HierarchyItemElement[],
    }>({ filteredHierarchyItems: [], definition: undefined, selected: [] });
    const pubContext = useContext(PublicationContext);

    useEffect(() => {
        const element = props.elementList[props.element.elementId];
        if (element) {
            const definition = props.getElementDefinition(element.elementDefinitionSystemId, element.elementDefinitionId);
            const data = new Domain.Publisher.AccordionDataControl();
            FieldsHelper.mapObject<Domain.Publisher.AccordionDataControl>(data, definition.fields, element.fields);

            setControlSettings(data);
            if (!!!data.isStudioControl && !!data.measureMomentId && props.loadPerformanceHierarchy) {
                props.loadPerformanceHierarchy(data.measureMomentId);
            }
            if (!!data.isStudioControl && !!data.measureMomentId && !!data.hierarchyDefinitionId && props.loadStudioHierarchy) {
                const hierarchy = pubContext.studioHierarchies?.find(h => h.momentId === data.measureMomentId && h.definitionId === data.hierarchyDefinitionId);
                if (!!hierarchy && props.loadStudioHierarchy) {
                    props.loadStudioHierarchy(hierarchy.id, data.hierarchyDefinitionId, data.measureMomentId);
                }
            }
        }
    }, [props.elementList[props.element.elementId]]);

    useEffect(() => {
        if (!controlSettings || !controlSettings.entityTypeId || !props.hierarchies) {
            setControlHierarchyItems({ filteredHierarchyItems: [], definition: undefined, selected: [] });
            return;
        }

        const source = controlSettings.isStudioControl ? ActionSource.Studio : ActionSource.Performance;
        const hierarchyDefinitionId = controlSettings.isStudioControl ? controlSettings.hierarchyDefinitionId : 'performance';

        if (!controlSettings.measureMomentId || !props.hierarchies[source] || !props.hierarchies[source][controlSettings.measureMomentId] || !props.hierarchies[source][controlSettings.measureMomentId][hierarchyDefinitionId]) {
            setControlHierarchyItems({ filteredHierarchyItems: [], definition: undefined, selected: [] });
            return;
        }

        const isChildOf = (ancensor: string, child: Domain.Performance.HierarchyItem | Domain.Studio.HierarchyItem, collection: Domain.Performance.HierarchyItem[] | Domain.Studio.HierarchyItem[]) => {
            const currentItem = child;
            while (currentItem && currentItem.parentHierarchyItemId) {
                if (currentItem.parentHierarchyItemId === ancensor) return true;
                if (currentItem.parentHierarchyItemId === '0') return false;

                const parentItem = collection.find((item) => item.element.elementId === currentItem.parentHierarchyItemId);
                return isChildOf(ancensor, parentItem, collection);
            }
            return false;
        };

        const definition = props.getDefinition(controlSettings.entityTypeId, source);
        const currrentHierarchy = props.hierarchies[source][controlSettings.measureMomentId][hierarchyDefinitionId];

        const filteredHierarchyItems: HierarchyItemElement[] = currrentHierarchy
            ?.filter(
                (item: Domain.Performance.HierarchyItem | Domain.Studio.HierarchyItem) =>
                    controlSettings?.entityTypeId === item.element.elementDefinitionId &&
                    (!controlSettings?.parentEntityId || isChildOf(controlSettings?.parentEntityId, item, currrentHierarchy))
            )
            ?.map((e: Domain.Performance.HierarchyItem | Domain.Studio.HierarchyItem) => {
                const getTitleValue = () => {
                    if (!controlSettings.headerFieldId) {
                        return 'Titel';
                    }

                    const headerFieldDefinition = definition?.fields?.find((item) => item.id === controlSettings.headerFieldId);
                    if (!headerFieldDefinition || !e.element.fields[headerFieldDefinition.id]) {
                        return 'Titel';
                    }

                    switch (headerFieldDefinition.systemId) {
                        case Domain.SystemFieldDefinitions.Performance.Amount:
                            return `${AppSettingsService.getAppSettings().General.CurrencySymbol} ${Number(e.element.fields[headerFieldDefinition.id])}`;
                        case Domain.SystemFieldDefinitions.Studio.Dynamic:
                            return renderField(headerFieldDefinition, e.element.fields[headerFieldDefinition.id]);
                        default:
                            return e.element.fields[headerFieldDefinition.id];
                    }
                };

                return {
                    ...e,
                    type: e.element.elementDefinitionId,
                    title: getTitleValue()
                } as HierarchyItemElement;
            });

        setControlHierarchyItems({
            filteredHierarchyItems,
            definition,
            selected: [filteredHierarchyItems[0]]
        });

    }, [controlSettings, props.hierarchies]);

    useEffect(() => {
        if (!controlHierarchyItems?.filteredHierarchyItems) {
            setControlHierarchyItems((prev) => ({ ...prev, selected: [] }));
        } else {
            setControlHierarchyItems((prev) => ({ ...prev, selected: [controlHierarchyItems?.filteredHierarchyItems[0]] }));
        }
    }, [controlHierarchyItems?.filteredHierarchyItems]);

    const iconSize = getIconSize(+props.publicationElement.bodyFontSize);

    const getProgressValueElement = (value: Domain.Shared.FieldDefinitionOptionItem) => {
        if (!value) {
            return null;
        }

        return (
            <Styled.SingleValueWrapper>
                {IconHelper.getPerformanceProgressIcon(value.name, iconSize)}
                <Styled.SingleValueLabel color={props.publicationElement?.bodyFontColor} fontSize={props.publicationElement?.bodyFontSize}>{value.name}</Styled.SingleValueLabel>
            </Styled.SingleValueWrapper>
        );
    };

    const customTitleTemplate = (data: HierarchyItemElement) => {
        return (
            <Styled.Title
                textColor={props.publicationElement?.bodyFontColor}
                textFontSize={props.publicationElement?.bodyFontSize}
                blockTitle>
                {data.title}
            </Styled.Title>
        );
    };

    const getFormattedText = (editorSettings: Domain.Studio.FieldEditorControlSettings, text: string) => {
        switch (editorSettings?.stringDisplayFormat) {
            case Domain.Studio.StringDisplayType.MultiLine:
                return (<span style={{ whiteSpace: 'pre-wrap' }}>{text}</span>);
            case Domain.Studio.StringDisplayType.HTML:
                return (<div dangerouslySetInnerHTML={StringUtils.toRawMarkup(text)} style={{ overflowY: 'hidden', minHeight: '48px', boxSizing: 'border-box' }}></div>);
            case Domain.Studio.StringDisplayType.HyperLink:
                return (<a href={text} target="_blank" rel="noopener noreferrer">{text}</a>);
            default:
                return (<span>{text}</span>);
        }
    };

    const getFormattedDecimal = (editorSettings: Domain.Studio.FieldEditorControlSettings, text: string) => {
        if (text && editorSettings?.numericFormatString) {
            return (<span>{format(editorSettings.numericFormatString, Number(text))}</span>);
        }
        return (<span>{text}</span>);
    };

    const renderField = (fieldDefinition: Domain.Shared.FieldDefinition, fieldValue: string, isStudio = false) => {
        switch (fieldDefinition?.dataType) {
            case Domain.Shared.FieldDataType.String.toString(): {
                const editorSettings: Domain.Studio.FieldEditorControlSettings = getFieldEditorSettings(fieldDefinition);
                return getFormattedText(editorSettings, fieldValue);
            }
            case Domain.Shared.FieldDataType.Option.toString():
                const optionItem = fieldDefinition?.optionItems.find((item) => item.id === fieldValue);
                return optionItem ? (
                    <Styled.SingleValueWrapper>
                        <Circle sx={{ color: `#${optionItem.color?.toString(16)}`, fontSize: iconSize }} />
                        <Styled.SingleValueLabel color={props.publicationElement?.bodyFontColor} fontSize={props.publicationElement?.bodyFontSize}>
                            {optionItem?.name}
                        </Styled.SingleValueLabel>
                    </Styled.SingleValueWrapper>
                ) : null;
            case Domain.Shared.FieldDataType.Decimal.toString(): {
                const editorSettings: Domain.Studio.FieldEditorControlSettings = getFieldEditorSettings(fieldDefinition);
                return getFormattedDecimal(editorSettings, fieldValue);
            }
            case Domain.Shared.FieldDataType.Integer.toString():
                return fieldValue;
            case Domain.Shared.FieldDataType.DateTimeOffset.toString():
                return fieldValue ? DateUtils.formatDate(new Date(fieldValue)) : "";
            case Domain.Shared.FieldDataType.Boolean.toString():
                return fieldValue && fieldValue.toLowerCase() === "true" ? fieldDefinition.booleanTrueLabel || "Ja" : fieldDefinition.booleanFalseLabel || "Nee";
            default:
                return fieldValue;
        }
    };

    const customItemTemplate = (data: HierarchyItemElement) => {
        const hierarchyEntityFields = controlHierarchyItems.definition.fields?.map((e) => ({ fieldId: e.id, label: e.name, visible: true, index: -1 })) || [];
        const shownFields = (controlSettings.fieldsListJson ? JSON.parse(controlSettings.fieldsListJson) : hierarchyEntityFields)?.filter((e) => e.fieldId !== controlSettings.headerFieldId && !!e.visible)?.sort((a, b) => a.index - b.index);

        if (controlSettings.isStudioControl) {
            return (
                <Styled.Container>
                    {shownFields?.map((field) => {
                        const fieldDef = controlHierarchyItems.definition.fields?.find((item) => item.id === field.fieldId);
                        const fieldValue = fieldDef ? data.element.fields[fieldDef.id] : undefined;
                        return (
                            <Styled.Item>
                                {field.showFieldName &&
                                    <Styled.Title
                                        textColor={props.publicationElement?.bodyFontColor}
                                        textFontSize={props.publicationElement?.bodyFontSize} margin>
                                        {field.label}
                                    </Styled.Title>}
                                <Styled.Text
                                    textColor={props.publicationElement?.bodyFontColor}
                                    textFontSize={props.publicationElement?.bodyFontSize}
                                    headerColor={props.publicationElement?.primaryColor}
                                    headerBackgroundColor={props.publicationElement?.primaryContrastColor}>
                                    {renderField(fieldDef, fieldValue, controlSettings.isStudioControl)}
                                </Styled.Text>
                            </Styled.Item>
                        );
                    })
                    }
                </Styled.Container >);
        }

        // Custom display for specific controls
        const statusFieldDefinition = controlHierarchyItems.definition.fields.find((item) => availableStatuses.indexOf(item.systemId) >= 0);
        const budgetFieldDefinition = controlHierarchyItems.definition.fields.find((item) => item.systemId === Domain.SystemFieldDefinitions.Performance.Amount);

        return (
            <Styled.Container>
                {shownFields?.map((field) => {
                    const fieldDef = controlHierarchyItems.definition.fields?.find((item) => item.id === field.fieldId);
                    const fieldValue = fieldDef ? data.element.fields[fieldDef.id] : undefined;
                    return (
                        <Styled.Item>
                            {field.showFieldName &&
                                <Styled.Title
                                    textColor={props.publicationElement?.bodyFontColor}
                                    textFontSize={props.publicationElement?.bodyFontSize}
                                    margin>
                                    {field.label}
                                </Styled.Title>}
                            {fieldValue && (budgetFieldDefinition && budgetFieldDefinition.id === field.fieldId ? (
                                <Styled.Text textColor={props.publicationElement?.bodyFontColor}
                                    textFontSize={props.publicationElement?.bodyFontSize}
                                    headerColor={props.publicationElement?.primaryColor}
                                    headerBackgroundColor={props.publicationElement?.primaryContrastColor}>
                                    <span>
                                        {AppSettingsService.getAppSettings().General.CurrencySymbol} {Number(fieldValue)}
                                    </span>
                                </Styled.Text>
                            ) : statusFieldDefinition && statusFieldDefinition.id === field.fieldId ? (
                                getProgressValueElement(statusFieldDefinition.optionItems.find((oi) => oi.id === fieldValue))
                            ) : (
                                <Styled.Text textColor={props.publicationElement?.bodyFontColor}
                                    textFontSize={props.publicationElement?.bodyFontSize}
                                    headerColor={props.publicationElement?.primaryColor}
                                    headerBackgroundColor={props.publicationElement?.primaryContrastColor}>
                                    {fieldValue}
                                </Styled.Text>
                            ))}
                        </Styled.Item>
                    );
                })}
            </Styled.Container>
        );
    };

    const selectionChanged = (e) => {
        let newItems = [...controlHierarchyItems.selected];
        e.removedItems.forEach((item) => {
            const index = newItems.indexOf(item);
            if (index >= 0) {
                newItems.splice(index, 1);
            }
        });
        if (e.addedItems.length) {
            newItems = [...newItems, ...e.addedItems];
        }
        setControlHierarchyItems((prev) => ({ ...prev, selected: newItems }));
    };

    if (!controlSettings) {
        return null;
    }

    if (!controlHierarchyItems || !controlHierarchyItems.filteredHierarchyItems || controlHierarchyItems.filteredHierarchyItems.length === 0 || !controlHierarchyItems.definition) {
        const customBlankItemTemplate = () => {
            return (
                <Styled.Container>
                    <Styled.Item centered>
                        <Styled.Text textColor={props.publicationElement?.bodyFontColor}
                            textFontSize={props.publicationElement?.bodyFontSize}
                            headerColor={props.publicationElement?.primaryColor}
                            headerBackgroundColor={props.publicationElement?.primaryContrastColor}>
                            Geen velden geselecteerd
                        </Styled.Text>
                    </Styled.Item>
                </Styled.Container>
            );
        };
        return (
            <>
                <SharedStyled.Title h3FontSize={props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}>{controlSettings.title}</SharedStyled.Title>
                <LsAccordion
                    id={props.element.elementId}
                    data={dummyData}
                    collapsible={true}
                    multiple={true}
                    selected={[dummyData[0]]}
                    customTitleTemplate={customTitleTemplate}
                    customItemTemplate={customBlankItemTemplate}
                    onSelectionChanged={selectionChanged}
                />
            </>
        );
    }

    return (
        <>
            <SharedStyled.Title h3FontSize={props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}>{controlSettings.title}</SharedStyled.Title>
            <Styled.AccordionWrapper
                primaryColor={props.publicationElement.primaryColor}
                primaryTextColor={props.publicationElement.primaryContrastColor}
                textColor={props.publicationElement.bodyFontColor} textFontSize={props.publicationElement.bodyFontSize}
                h1FontSize={+props.publicationElement?.h1FontSize} h1FontColor={props.publicationElement?.h1FontColor}
                h2FontSize={+props.publicationElement?.h2FontSize} h2FontColor={props.publicationElement?.h2FontColor}
                h3FontSize={+props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}
                editMode={false}
            >
                <LsAccordion
                    id={props.element.elementId}
                    data={controlHierarchyItems.filteredHierarchyItems}
                    collapsible={true}
                    multiple={true}
                    selected={controlHierarchyItems.selected}
                    customTitleTemplate={customTitleTemplate}
                    customItemTemplate={customItemTemplate}
                    onSelectionChanged={selectionChanged}
                />
            </Styled.AccordionWrapper>
        </>
    );
};

const getFieldEditorSettings = (fieldDefinition: Domain.Shared.FieldDefinition): Domain.Studio.FieldEditorControlSettings => {
    return JsonUtils.toJson<Domain.Studio.FieldEditorControlSettings>(fieldDefinition?.editorSettings, undefined);
};

export default AccordionDataControl;
