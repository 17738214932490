import React from 'react';
import styled from 'styled-components';
import { px } from '../style';

// tslint:disable:no-magic-numbers

interface IBarProps {
    readonly start?: boolean;
    readonly end?: boolean;
    readonly children: React.ReactNode;
    readonly look?: 'system' | 'breadcrumb' | 'toolbar' | 'custom';
}

const Wrap = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    padding: ${(p: IBarProps) =>
        (p.look === 'toolbar' ? '0 0 16px 0' : (p.look === 'custom' ? '12px 40px' : ''))
    };
`;

const BarStart = styled.div`
    grid-column: 1;
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    justify-items: start;
    grid-gap: ${px(12)};
`;

const BarEnd = styled.div`
    grid-column: -1;
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    justify-items: end;
    grid-gap: ${px(12)};
`;

const BarMid = styled.div`
    grid-column: 2;
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: ${px(12)};
    justify-self: center;
`;

interface IProps {
    readonly start?: boolean;
    readonly end?: boolean;
    readonly middle?: boolean;
    readonly children: React.ReactNode;
    readonly look?: 'system' | 'breadcrumb' | 'toolbar' | 'custom';
}

export const Bar = ({ children, start, end, middle, look }: IProps) => {
    if (start) { return (<BarStart className="bar-start">{children}</BarStart>); }
    if (end) { return (<BarEnd className="bar-end">{children}</BarEnd>); }
    if (middle) { return (<BarMid className="bar-middle">{children}</BarMid>); }
    return (
        <Wrap look={look} className="bar-control">{children}</Wrap>
    );
};
