import { FieldDataType, FieldDefinition } from "../../aggregates/Shared";

export const iconColorFieldDefinition: FieldDefinition = {
    dataType: FieldDataType.String,
    id: "93279f84-4f56-49e5-8452-1cd9ce660f9f",
    name: "Icoon kleur",
    required: false,
    systemId: "93279f84-4f56-49e5-8452-1cd9ce660f9f",
};

export const iconFieldDefinition: FieldDefinition = {
    dataType: FieldDataType.String,
    id: "c61c422f-c582-4fe7-b580-27250ef97d67",
    name: "Icoon",
    required: false,
    systemId: "c61c422f-c582-4fe7-b580-27250ef97d67",
};
