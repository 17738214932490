import styled from 'styled-components';
import { px } from '../../style';
import { palette } from '../../styleguide';


export enum IconSize {
    extrasmall = 12,
    small = 14,
    medium = 20,
    default = 24,
    large = 32,
    extralarge = 48
}

const IconBg = styled.div<{ size: IconSize, color?: string, displayMode?: 'inline' | 'block' }>`
    border-radius: 4px;
    display: ${({ displayMode }) => displayMode === 'inline' ? 'inline-flex' : 'flex'};
    justify-content: center;
    align-items: center;
    width: ${({ size }) => size ? `${px(size + 8)}` : `0`};
    height: ${({ size }) => size ? `${px(size + 8)}` : `0`};
    > svg { 
        height: auto;
        fill: ${({ color }) => color ? color : palette.grey2};
        stroke: ${({ color }) => color ? color : palette.grey2};
        ${({ size }) => `width: ${px(size)};`
    }
`;

const ThumbnailBg = styled.div<{
    width?: number,
    primaryColor?: string,
    primaryContrastColor?: string,
    textColor?: string,
    h1Color?: string,
    h2Color?: string,
    h3Color?: string,
}>`
    display: inline;
    justify-content: center;
    align-items: center;
    height: 95%;
    width: 95%;

    > svg { 
        #pub-primaryColor { 
            path, rect, circle {
                fill: ${({ primaryColor }) => primaryColor ? primaryColor : palette.primary1};
                stroke: ${({ primaryColor }) => primaryColor ? primaryColor : palette.primary1};
            }
        }
        #pub-primaryContrastColor {
            path, rect, circle {
                fill: ${({ primaryContrastColor }) => primaryContrastColor ? primaryContrastColor : palette.white};
                stroke: ${({ primaryContrastColor }) => primaryContrastColor ? primaryContrastColor : palette.white};
            }
        }
        #pub-textColor {
            path, rect {
                fill: ${({ textColor }) => textColor ? textColor : palette.grey2};
                stroke: ${({ textColor }) => textColor ? textColor : palette.grey2};
            }
        }
        #pub-titleColor-titel {
            path {
                fill: ${({ h1Color }) => h1Color ? h1Color : palette.primary1};
                stroke: ${({ h1Color }) => h1Color ? h1Color : palette.primary1};
            }
        }
        #pub-titleColor-subtitelGroot{
            path {
                fill: ${({ h2Color }) => h2Color ? h2Color : palette.primary2};
                stroke: ${({ h2Color }) => h2Color ? h2Color : palette.primary2};
            }
        }
        #pub-titleColor-subtitelKlein{
            path {
                fill: ${({ h3Color }) => h3Color ? h3Color : palette.primary3};
                stroke: ${({ h3Color }) => h3Color ? h3Color : palette.primary3};
            }
        }
    } 
`;

const styledComponents = { IconBg, ThumbnailBg };
export default styledComponents;
