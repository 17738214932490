import PQueue from 'p-queue';

class QueueHelper {
    private readonly queue = new PQueue({ concurrency: 1 });

    private readonly promises = new Map<string, Promise<any>>();

    /**
     * Add a job to the queue. If a job with that id is already running it is returned instead, as a promise.
     * @param fn the job to run
     * @param key the id of the job
     * @returns Returns the promise of the result
     */
    public addOrGetJob<TResult>(fn: () => Promise<TResult>, key: string): Promise<TResult> {
        if (this.promises.has(key)) {
            return this.promises.get(key);
        }

        const promise = this.queue.add<TResult>(fn);
        this.promises.set(key, promise);

        promise.finally(() => {
            this.promises.delete(key);
        });

        return promise;
    }
}

export const queueHelper = new QueueHelper();