import React, { useState } from 'react';
import { AutoFocus, ModalDialog, ModalDialogFooter, Text, WarningLook, WarningWrapper } from '@liasincontrol/ui-basics';
import Styled from './index.styled';

type Props = {
    readonly versionId: string,
    readonly versionName: string,
    readonly disableSaveButton: boolean,
    readonly onDelete: (versionId: string) => void,
    readonly onCancel: () => void,
}

/**
 * Represents an UI component that renders a dialog asking the user if he wants to delete a publication version.
 */
export const VersionDelete: React.FC<Props> = (props) => {
    const [valueOptions, setValueOptions] = useState<{ value: string, confirmed: boolean, touched: boolean }>({ value: '', confirmed: false, touched: false });

    const footer = (
        <ModalDialogFooter
            leftButtonText='Annuleren' onLeftButtonClick={props.onCancel}
            rightButtonText='Verwijderen' onRightButtonClick={() => props.onDelete(props.versionId)}
            rightButtonDisabled={!valueOptions.confirmed || props.disableSaveButton}
        />
    );
    return (
        <ModalDialog id='dialog' settings={{ look: 'message', title: 'Versie verwijderen', footer: footer }}>
            <Text value='U staat op het punt om een versie te verwijderen. Wanneer een versie wordt verwijderd zullen gepubliceerde websites niet meer toegankelijk zijn.' />
            <WarningWrapper
                look={WarningLook.danger}
                className='mb-100'
                messageText='LET OP: U kunt deze stap niet ongedaan maken.' />

            <AutoFocus>
                <Styled.InputFieldCustom
                    id='inputRemoveVersion'
                    key='inputRemoveVersion'
                    label={`Voer de versie naam  "${props.versionName}" in om het verwijderen te bevestigen (hoofdletter gevoelig)`}
                    placeholder='Type hier…'
                    value={valueOptions.value}
                    onChange={(value: string) => {
                        setValueOptions({ value, confirmed: value === props.versionName, touched: true })
                    }}
                    success={valueOptions.confirmed}
                    error={valueOptions.touched && !valueOptions.confirmed && 'De naam komt niet overeen'}
                />
            </AutoFocus>
        </ModalDialog>
    );
};
