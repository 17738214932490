import React from 'react';
import Ink from 'react-ink';
import Styled from './index.styled';
import { InputField } from '../_shared';

/**
 * Represents a low-level UI component that renders a HyperLink control.
 */
export const InputHyperLink = (props) => {
    const isValidUrl = !!props.value && props.value.length > 0;
    return (
      <Styled.Container>
        <Styled.Center>
          <InputField {...props} />
        </Styled.Center>
        <Styled.Button id="btn-visit" disabled={props.disabled || !isValidUrl} onClick={() => window.open(props.value, '_blank')}>
          <Styled.ButtonInner tabIndex={-1}>
            <Styled.OpenIcon />
            {!props.disabled && <Ink duration={600} radius={230} />}
          </Styled.ButtonInner>
        </Styled.Button>
      </Styled.Container>
    );
  };