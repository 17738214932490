import { createReducer } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { TenantActionCreator, SvgIconActionCreator } from '../../../../actions/creators';
import { AjaxRequestStatus } from '../../../state';

/**
 * Represents the svg icon reducer.
 */
const reducer = createReducer<{ items: { [id: string]: Domain.Shared.SvgIcon }, status: AjaxRequestStatus }>({ items: {}, status: AjaxRequestStatus.NotSet }, (builder) => {
    return (
        builder.addCase(SvgIconActionCreator.set.fulfilled, (state, action) => {
            state = {
                items: [...action.payload].reduce((acc, icon) => ({ ...acc, [icon.id]: icon }), {}) ?? {},
                status: AjaxRequestStatus.Done,
            };
            return state;
        }),
        builder.addCase(SvgIconActionCreator.set.pending, (state, action) => {
            // Loading in progress;
            state = {
                items: {},
                status: AjaxRequestStatus.Pending,
            };
            return state;
        }),
        builder.addCase(SvgIconActionCreator.set.rejected, (state, action) => {
            state = {
                items: {},
                status: AjaxRequestStatus.Failed,
            };
            return state;
        }),
        builder.addCase(TenantActionCreator.initTenant, (state, action) => {
            state = {
                items: {},
                status: AjaxRequestStatus.NotSet,
            };
            return state;
        })
    );
});

export default reducer;