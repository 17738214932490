import React from 'react';
import AuthService from '@liasincontrol/auth-service';
import { connect } from 'react-redux';
import { TenantActionCreator } from '@liasincontrol/redux-service';

type Props = ReturnType<typeof mapDispatchToProps>;

const Index: React.FC<Props> = (props) => {
    AuthService.getInstance()
        .then((auth) => auth.signinSilentCallback())
        .then((user) => {
            props.setTenant(user?.profile?.tenant_name as string);
        }).catch(error => {
            throw error;
        });

    return <></>;
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTenant: (tenant: string | undefined) => {
            dispatch(TenantActionCreator.initTenant(tenant));
        },
    };
};

const Component = connect(null, mapDispatchToProps)(Index);
export default Component;
