import { JournalCombination } from '../JournalCombination';

class BudgetLineListItem extends JournalCombination {
    id: string;

    accountClassCaption?: string;
    isStructural?: boolean;
    isReserve?: boolean;
    shortDescription?: string;
    
    element1?: string;
    element2?: string;
    element3?: string;
    element4?: string;
    element5?: string;
    element6?: string;
    element7?: string;
    element8?: string;

    year00?: number;
    year01?: number;
    year02?: number;
    year03?: number;
    year04?: number;
    year05?: number;
    year06?: number;
    year07?: number;
    year08?: number;
    year09?: number;
    year10?: number;
    year11?: number;
    year12?: number;
    year13?: number;
    year14?: number;
    year15?: number;
}

class BudgetLine {

    id: string;

    elementRKs: string[];

    shortDescription?: string;

    longDescription: string;

    isStructural: boolean;

    amounts?: BudgetLineAmmount[];

}

class BudgetLineAmmount {

    transactionYear: number;

    amount: number;
}

export { BudgetLine, BudgetLineAmmount, BudgetLineListItem };
