import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Domain from '@liasincontrol/domain';
import { UserIdentity } from '@liasincontrol/auth-service';
import { ActionSource, AjaxRequestStatus, ElementDefinitionsActionCreator, ModulesActionCreator, State, UsersActionCreator } from '@liasincontrol/redux-service';
import { Section, ErrorOverlay, ResetZIndex } from '@liasincontrol/ui-basics';
import { TreeList } from './TreeList';
import { usePublicationSettings, usePublicationWorkflowStates } from '../../../../../helpers/PublicationContext';

import Helper from '../../../../_shared/PublicationItem/PublicationInformation/index.helper';


type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the overview page for publicationTasks.
 */
const Index: React.FC<Props> = (props) => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const publication = usePublicationSettings();
    const workflowStates = usePublicationWorkflowStates();

    useEffect(() => {
        if (!publication) return;
        const elemDef = Object.values(props.elementDefinitions.items).find(ed => ed.systemId === Domain.SystemElementDefinitions.Pub.Publication);
        const isClosed = Helper.getPublicationIsClosed(publication, elemDef);
        if (isClosed) {
            setError({ message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed] });
        }
    }, [props.elementDefinitions, publication]);

    //will be needed
    if (props.users.status === AjaxRequestStatus.NotSet) {
        props.fetchUsers();
        return null;
    }

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(ActionSource.Publication, props.modules[Domain.SystemModuleDefinitions.Publisher]);
        return null;
    }

    const defaultWorkflowState = workflowStates?.find(state => state.category === Domain.Shared.WorkflowCategoryType.New);
    return (
        <>
            <Section look='white'>
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    {publication && <ResetZIndex>
                        <TreeList
                            {...props}
                            //homepage near its children
                            elementDefinitions={props.elementDefinitions.items}
                            extractHomePage={true}
                            publication={publication}
                            setError={setError}
                            defaultWorkflowState={defaultWorkflowState}
                            workflowStates={workflowStates}
                        />
                    </ResetZIndex>}
                </ErrorOverlay>
            </Section>
        </>
    );
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Publication],
        users: state.users,
        elementDefinitions: state.elementdefinitions[ActionSource.Publication],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchUsers: () => {
            dispatch(UsersActionCreator.set());
        },
        fetchElementDefinitions: (actionSource: ActionSource, module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: actionSource, data: { moduleId: module?.id } }));
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
