import React from 'react';
import { Button } from '../button';
import { withField } from '../field';

export const ButtonField = withField<{name: string}>(({ id, disabled, value: { name }, onChange }) => (
    <div>
        <Button
            id={`button-${id}`}
            btnbase="textbuttons"
            btntype="medium_icon"
            aria-label={name}
            onClick={onChange}
            disabled={disabled}>
            {name}
        </Button>
    </div>
));
