import React from 'react';
import styled from 'styled-components';
import { px } from '../../style';
import { BasicText } from '../typography';
import { IPropsToogle } from './Props';

interface ICheckDotProps {
    readonly isSelected: boolean;
    readonly isDisabled: boolean;
}
interface IToggleProps {
    readonly isSelected: boolean;
    readonly isDisabled: boolean;
}

// tslint:disable:no-magic-numbers
const defaultLook = {
    defaultColor: 'rgba(75, 85, 246, 0.1)',
    defaultColorDarker: 'rgba(75, 85, 246, 0.3)',
    primaryColor: '#4b55f6;',
    primaryColorDarker: '#2934CC',
    disabledLight: '#F4F4F8',
    disabledDark: '#8A8991',
    textColor: '#414141',
};

const ToggleField = styled(BasicText.withComponent('div'))`
    position: relative;
    padding-left:${px(76)};
    height:32px;
    line-height: 32px;
    &:focus {
        outline: none;
    }
`;

const CheckDot = styled.span`
    color: ${(p: ICheckDotProps) =>
        (p.isDisabled && defaultLook.disabledDark)
        || (p.isSelected && '#000')
        || defaultLook.textColor
    };
    font-weight: ${(p: ICheckDotProps) =>
        (p.isDisabled && '300')
        || (p.isSelected && '700')
        || '400'
    };
    line-height: 18px;
  &::before {
    content: '';
    position: absolute;
    top: 0; bottom: 0; left: 0;
    margin:auto;
    width: 60px; height: 32px;
    background-color: ${(p: ICheckDotProps) =>
        (p.isDisabled && p.isSelected && defaultLook.disabledDark)
        || (p.isDisabled && defaultLook.disabledLight)
        || (p.isSelected && defaultLook.primaryColor)
        || defaultLook.defaultColor
    };
    border:2px solid;
    border-color: ${(p: ICheckDotProps) =>
        (p.isDisabled && p.isSelected && defaultLook.disabledDark)
        || (p.isDisabled && defaultLook.disabledLight)
        || 'transparent'};
    border-radius: 24px;
    transition:border-color .2s, background-color .2s;
  }
  &::after {
    content: '';
    position: absolute;
    top: 0; bottom: 0;
    margin:auto;
    width: 24px; height: 24px;
    border-radius: 12px;

    left: ${(p: ICheckDotProps) =>
        (p.isSelected && '32px')
        || '4px'
    };
    background-color: ${(p: ICheckDotProps) =>
        (p.isDisabled && p.isSelected && defaultLook.disabledLight)
        || (p.isDisabled && defaultLook.disabledDark)
        || '#fff'
    };
    box-shadow: ${(p: ICheckDotProps) =>
        (p.isDisabled && '0')
        || '0 0 6px 0' + defaultLook.defaultColor};
    transition:left .2s;
  }
  ${ToggleField}:hover & {
    cursor: ${(p: ICheckDotProps) =>
        (p.isDisabled && 'default')
        || 'pointer'
    };
  }
  ${ToggleField}:focus & ::before {
    box-shadow: ${(p: IToggleProps) =>
        (p.isDisabled && 'none')
        || (p.isSelected && '0 0 0 2px #fff, 0 0 0 4px ' + defaultLook.primaryColor)
        || '0 0 0 2px ' + defaultLook.primaryColor
    };
  }
`;

const ToggleFieldSmall = styled(ToggleField)`
    padding-left:${px(53)};
    height: ${px(24)};
    line-height: ${px(24)};
    width: ${px(45)};
`;

const CheckDotSmall = styled(CheckDot)`
  &::before {
    width: ${px(45)}; height: ${px(24)};
  }
  &::after {
    width: ${px(18)}; height: ${px(18)};
    left: ${(p: ICheckDotProps) =>
        (p.isSelected && '24px')
        || '4px'
    };
  }
`;

export class InputToggle extends React.PureComponent<IPropsToogle> {
    public render() {
        const { id, selected, textOn, textOff, disabled, isSmall } = this.props;
        return isSmall ? (
            <ToggleFieldSmall
                className={this.props.className}
                aria-checked={selected}
                id={id}
                onClick={this.onClick}
                onKeyDown={this.onKeyPressed}
                role="checkbox"
                tabIndex={0}>
                <CheckDotSmall isSelected={selected} isDisabled={disabled}>
                    {selected ? textOn : textOff}
                </CheckDotSmall>
            </ToggleFieldSmall>
        ) : (
                <ToggleField
                    className={this.props.className}
                    aria-checked={selected}
                    id={id}
                    onClick={this.onClick}
                    onKeyDown={this.onKeyPressed}
                    role="checkbox"
                    tabIndex={0}>
                    <CheckDot isSelected={selected} isDisabled={disabled}>
                        {selected ? textOn : textOff}
                    </CheckDot>
                </ToggleField>
        );
    }

    private onKeyPressed = (event: React.KeyboardEvent) => {
        if (event.keyCode === 32) {
            this.onClick();
        }
    }

    private onClick = () => {
        if (!this.props.disabled && this.props.onChange) {
            this.props.onChange(!this.props.selected);
        }
    }
}
