import { createAsyncThunk } from '@reduxjs/toolkit';
import { Shared as SharedDataAccess } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';

type WorkflowTemplateActionResponseType = {
    workflows: Domain.Dto.Shared.WorkflowTemplate[],
    workflowStates: Domain.Dto.Shared.WorkflowTemplateState[]
};

const action = createAsyncThunk<WorkflowTemplateActionResponseType>('/workflowtemplate/set', async () => {
    return SharedDataAccess.WorkflowTemplate.getWorkflowsAndStates()
        .then(response => response.data);
});

export default action;