import React from 'react';
import Ink from 'react-ink';
import * as Internals from './internals';
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
export { Internals };

export type IButtonProps<T extends keyof Internals.IStyleGuideButtons> = {
    readonly icon?: React.ReactNode;
    readonly isDropdown?: boolean;
    readonly dropdownVisible?: boolean;

    readonly className?: string;
    readonly classNameContent?: string;

    readonly layout?: keyof typeof Internals.layouts;

    readonly children?: React.ReactNode;
    readonly disabled?: boolean;

    readonly btnbase: T;
    readonly btntype: keyof Internals.IStyleGuideButtons[T];
};

export const Button = <T extends keyof Internals.IStyleGuideButtons, ComponentType extends AsComponentType>({
    as,
    children,
    className,
    classNameContent,
    icon,
    isDropdown = false,
    dropdownVisible = false,
    layout = 'iconbefore',
    btnbase,
    btntype,
    ...propsAs
}: As<ComponentType, IButtonProps<T>>) => {
    const Button = resolveComponents(btnbase, btntype);
    return (
        <Button.Container {...propsAs} as={as} className={className}>
            <Internals.InternalButtonContent tabIndex={-1} className={classNameContent}>
                {layout !== `iconafter` && icon}
                {children && <Button.Text>{children}</Button.Text>}
                {layout === `iconafter` && icon}
                {isDropdown && (dropdownVisible ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />)}
                {!propsAs.disabled && <Ink recenter={true} />}
            </Internals.InternalButtonContent>
        </Button.Container>
    );
};

// tslint:disable-next-line:max-line-length
const resolveComponents = <T extends keyof Internals.IStyleGuideButtons>(btnbase: T, btntype: keyof Internals.IStyleGuideButtons[T]) => {
    const Text = resolveTextComponent(btnbase, btntype) || resolveTextComponent(`textbuttons`, `medium_icon`);
    const Container = resolveContainerComponent(btnbase, btntype) || resolveContainerComponent(`textbuttons`, `medium_icon`);
    return { Text, Container };
};

type TextComponentType = React.ComponentType<any> /* TypeScript 3.7, `as` prop defined as never in styled components */;
const cacheTextComponent = new Map<string, TextComponentType>();
const resolveTextComponent = <T extends keyof Internals.IStyleGuideButtons>(btnbase: T, btntype: keyof Internals.IStyleGuideButtons[T]) => {
    const name = `${btnbase}/${String(btntype)}`;
    if (cacheTextComponent.has(name)) {
        return cacheTextComponent.get(name);
    } else {
        const source = Internals.StyleGuideButtons;
        const { text = null } = (source[btnbase] && (source[btnbase][btntype] as any)) || {};
        const component = styled.span`
            ${text}
        ` as TextComponentType;
        cacheTextComponent.set(name, component);
        return component;
    }
};
type ButtonComponentType = React.ComponentType<any> /* TypeScript 3.7, `as` prop defined as never in styled components */;
const cacheContainerComponent = new Map<string, ButtonComponentType>();
const resolveContainerComponent = <T extends keyof Internals.IStyleGuideButtons>(btnbase: T, btntype: keyof Internals.IStyleGuideButtons[T]) => {
    const name = `${btnbase}/${String(btntype)}`;
    if (cacheContainerComponent.has(name)) {
        return cacheContainerComponent.get(name);
    } else {
        const source = Internals.StyleGuideButtons;
        const { container = null } = (source[btnbase] && (source[btnbase][btntype] as any)) || {};
        const component = styled.button`
            ${Internals.base.button}
            ${container}
        ` as ButtonComponentType;
        cacheContainerComponent.set(name, component);
        return component;
    }
};

// As: https://gist.github.com/Zaibot/bb6b3193c57f5fdbc2f3fc495ea1425f
type HtmlComponentTypes = keyof JSX.IntrinsicElements;
type AsComponentType = HtmlComponentTypes | React.ComponentType<any>;

/** if one of the JSX.IntrinsicElements React Component type for that HTML element -else- the specific React Component type */
type ComponentTypeOrElement<T extends AsComponentType> = T extends HtmlComponentTypes
    ? React.ComponentType<React.ComponentProps<T>>
    : T extends React.ComponentType<any>
    ? T
    : never;

/** props of the `as` component */
type PropsOfComponentType<T> = T extends React.ComponentType<infer P> ? P : never;

/** props to be forwarded to the `as` component */
type AsPassthroughProps<T extends AsComponentType, Props> = Omit<PropsOfComponentType<ComponentTypeOrElement<T>>, keyof Props | 'as'>;
type As<T extends AsComponentType, Props> = { as?: T } & Props & AsPassthroughProps<T, Props>;
