import { useLayoutEffect, useState } from 'react';
import { debounce } from 'lodash';

export const useWindowSize = () => {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        const updateSize = debounce(() => {
            setSize([window.innerWidth, window.innerHeight]);
        }, 300);
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return size;
}

export const useElementSize = (htmlElement: HTMLElement) => {
    const [size, setSize] = useState([null, null]);
    
    useLayoutEffect(() => {
        if (!htmlElement) return;

        const updateSize = debounce(() => {
            setSize([htmlElement?.clientWidth, htmlElement?.clientHeight]);
        }, 300);

        const resizeObserver = new ResizeObserver(updateSize);
        resizeObserver.observe(htmlElement);
        
        updateSize();
        return () => resizeObserver.disconnect();

    }, [htmlElement]);

    return size;
}