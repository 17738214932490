import styled from 'styled-components';
import { px } from '@liasincontrol/ui-basics';

const JournalCombinationWrapper = styled.div`
    margin-bottom: ${px(20)};
`;

const CombinationKindLabel = styled.label` 
    font-weight: 700;
`;

const CombinationRow = styled.p`
    font-size: ${px(14)};
`;

const styledComponents = { CombinationKindLabel, JournalCombinationWrapper, CombinationRow };
export default styledComponents;