import React from 'react';
import { ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';

type Props = { onCloseDialog: () => void };

/**
 * Represents a UI component that renders timeout message modal dialog.
 */
export const TimeoutMessageDialog: React.FC<Props> = (props) => {
    return (
        <ModalDialog settings={{
            look: 'interactive',
            title: 'Kopie maken',
            footer: <ModalDialogFooter rightButtonText='Sluiten' onRightButtonClick={props.onCloseDialog} />
        }}>
            <Text value='Kopieren duurt wat langer dan verwacht.' />
        </ModalDialog >
    );
};
