import styled from 'styled-components';
import { palette, px } from '@liasincontrol/ui-basics';

const PlaceholderContainer = styled.div<{ direction: number }>`
    ${(p) => (p.direction === 1 ? `
        justify-self: end;
        align-self: center;
        order: 2;
        height: 100%;
    ` : `
        width: 100%;
    `)}
`;

const PlaceholderAddContainer = styled.div<{
    readonly direction: number;
}>`
    position: relative;
    display: grid;
    align-items: center;
    justify-items: center;
    margin: ${(p) => (p.direction === 1 ? '0 2rem' : '2rem')};
    height: ${(p) => (p.direction === 1 ? '100%' : 'auto')};
`;

const OutlineHorizontal = styled.div`
    position: absolute;
    top: 50%;
    left: -1rem;
    transform: translateY(-50%) scaleY(0);
    height: 100%;
    width: 1px;

    background-color: ${palette.grey3};
    transition: transform 0.2s 0.1s;

    ${PlaceholderAddContainer}:hover > & {
        transform: translateY(-50%) scaleY(1);
    }
`;

const OutlineVertical = styled.div`
    position: absolute;
    top: -1rem;
    left: 50%;
    transform: translateX(-50%) scaleX(0);
    height: 1px;
    width: 100%;
    background-color: ${palette.grey3};
    transition: transform 0.2s 0.1s;

    ${PlaceholderAddContainer}:hover > & {
        transform: translateX(-50%) scaleX(1);
    }
`;

const ToolboxContent = styled.div`
    padding: ${px(24)} ${px(24)} 0;
`;

const Card = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;

    .tile-image {
        flex: 1;
    }
`;

const styledComponents = { PlaceholderContainer, PlaceholderAddContainer, OutlineVertical, OutlineHorizontal, ToolboxContent, Card };
export default styledComponents;