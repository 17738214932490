import React, { useEffect, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { connect } from 'react-redux';
import { WrapperContent, PageTitle, Heading1, Section, ErrorOverlay } from '@liasincontrol/ui-basics';
import { SystemModuleDefinitions } from '@liasincontrol/domain';
import { AppSettingsService } from '@liasincontrol/config-service';
import { ActionSource, AjaxRequestStatus, ElementDefinitionsActionCreator, ModulesActionCreator, State } from '@liasincontrol/redux-service';
import { LsGrid, GridColumn, ContextMenu } from '@liasincontrol/ui-devextreme';
import { Performance } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { UpdateDefinitionNameDialog } from './UpdateDefinitionNameDialog';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

export type RowType = {
    elementDefinitionId: string,
    value: Domain.Performance.HierarchyItemElementType,
    label: string
}

/**
 * Represents a UI component that renders the list of performance element definitions.
 */
const Index: React.FC<Props> = (props) => {
    const [performanceElementDefinitions, setPerformanceElementDefinitions] = useState<{
        items: { elementDefinitionId: string, value: Domain.Performance.HierarchyItemElementType, label: string }[];
        totalCount: number;
    }>({ items: [], totalCount: 0 });

    // #region Grid state...
    const availableColumns = getColumnConfiguration((item: RowType) => {
        setEditDefinitionNameDialogControls({ showDialog: true, definitionId: item.elementDefinitionId });
    });

    // #endregion...
    const [editDefinitionNameDialogControls, setEditDefinitionNameDialogControls] = useState<{ showDialog: boolean, definitionId: string }>
        ({ showDialog: false, definitionId: undefined });
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [externalError, setExternalError] = useState<{ id: string, message: string }>();

    useEffect(() => {
        if (!props.elementDefinitions || !props.elementDefinitions.items) {
            return;
        }

        const availableTypes = Object.keys(props.elementDefinitions.items)
            .filter((elementDefinitionId) => {
                return props.elementDefinitions.items[elementDefinitionId].systemId === Domain.SystemElementDefinitions.Performance.Policy
                    || props.elementDefinitions.items[elementDefinitionId].systemId === Domain.SystemElementDefinitions.Performance.Goal
                    || props.elementDefinitions.items[elementDefinitionId].systemId === Domain.SystemElementDefinitions.Performance.Activity
                    || props.elementDefinitions.items[elementDefinitionId].systemId === Domain.SystemElementDefinitions.Performance.Achievement
                    || props.elementDefinitions.items[elementDefinitionId].systemId === Domain.SystemElementDefinitions.Performance.Indicator
            })
            .map((elementDefinitionId) => {
                return {
                    elementDefinitionId: props.elementDefinitions.items[elementDefinitionId].id,
                    value: props.elementDefinitions.items[elementDefinitionId].systemId as Domain.Performance.HierarchyItemElementType,
                    label: props.elementDefinitions.items[elementDefinitionId].name
                };
            });

        setPerformanceElementDefinitions({
            items: availableTypes,
            totalCount: availableTypes.length
        });
    }, [props.elementDefinitions]);

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Performance]);
        return null;
    }

    const saveDefinitionName = (definitionId: string, definitionName: string) => {
        if (!definitionId || !definitionName) {
            return;
        }

        let keepOpen = false;
        Performance.HierarchyDataAccessor.updatePerformanceElementDefinitionName(definitionId, definitionName)
            .then(() => {
                props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Performance]);
            }).catch((err) => {

                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err);
                if (errorInfo.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceElementDefinitionNameNotUnique)) {
                    const message = Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PerformanceElementDefinitionNameNotUnique];
                    keepOpen = true;
                    setExternalError({
                        id: Domain.FieldDefinitions.Shared.nameFieldDefinition.id,
                        message: message,
                    });
                } else {
                    setError(errorInfo);
                }
            })
            .finally(() => setEditDefinitionNameDialogControls(prev => ({ showDialog: keepOpen, definitionId: keepOpen ? prev.definitionId : undefined })))
    };

    return (
        <>
            <WrapperContent>
                <PageTitle>
                    <Heading1>Entiteiten</Heading1>
                </PageTitle>

                <Section look='white'>
                    <ErrorOverlay
                        error={error?.message}
                        errorDetails={error?.details}
                        onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        {availableColumns && (
                            <LsGrid
                                keyExpr='elementDefinitionId'
                                dataSource={performanceElementDefinitions.items}
                                enableColumnChooser={false}
                                columns={availableColumns}
                                showRowLines={true}
                                paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                            />
                        )}
                    </ErrorOverlay>
                </Section>
            </WrapperContent>

            {editDefinitionNameDialogControls.showDialog && (
                <UpdateDefinitionNameDialog
                    onClose={() => setEditDefinitionNameDialogControls({ ...editDefinitionNameDialogControls, showDialog: false })}
                    definitionId={editDefinitionNameDialogControls.definitionId}
                    definitionName={performanceElementDefinitions.items?.find(i => i.elementDefinitionId === editDefinitionNameDialogControls.definitionId)?.label}
                    onSave={(definitionId, definitionName) => saveDefinitionName(definitionId, definitionName)}
                    externalError={externalError}
                />
            )}
        </>
    );
};

const getColumnConfiguration = (onChangeName: (item: RowType) => void): GridColumn<RowType>[] => {
    return [
        {
            name: 'label',
            title: 'Naam',
            allowSorting: false,
        },
        {
            name: 'elementDefinitionId',
            title: '',
            type: 'buttons',
            width: '5%',
            align: 'right',
            hideInColumnChooser: true,
            renderCustom: ({ data }) => (
                <ContextMenu<RowType>
                    keyExpr='elementDefinitionId'
                    item={data}
                    actions={[
                        {
                            action: onChangeName,
                            ariaLabel: `Bewerken ${data.label}`,
                            displayName: 'Bewerken',
                            actionName: `edit-${data.elementDefinitionId}`
                        }
                    ]}
                />)
        }];
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Performance],
        elementDefinitions: state.elementdefinitions[ActionSource.Performance],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Performance, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Performance, data: { moduleId: module?.id } }));
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
