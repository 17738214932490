import * as Domain from '@liasincontrol/domain';

/**
 * Represents a helper that is responsible for managing errors reported by the backend services.
 */
export class ApiErrorReportingHelper {
    /**
     * Represents generic error messages that can be show to the user.
     */
    public static readonly GenericMessages = {
        Default: 'Er is een probleem opgetreden.',
        Loading: 'Probleem bij het laden van gegevens.',
        Adding: 'Probleem bij het toevoegen van de gegevens.',
        Saving: 'Probleem bij het opslaan van gegevens.',
        Deleting: 'Data kan niet worden verwijderen.',
    }

    /**
     * Can be used to generate information about the error in a format that can be displayed in the user interface.
     * @param mainMessage Main message to display to the user.
     * @param error Error object received by the operation. Can be either an Api server error or generic ts Error object.
     * @param canRetry True if the user is allowed to retry the operation.
     */
    public static generateErrorInfo = (mainMessage: string, error: any, canRetry = false, canGoBack?: boolean): Domain.Shared.ErrorInfo => {
        return {
            message: mainMessage || ApiErrorReportingHelper.extractMainMessage(error),
            details: ApiErrorReportingHelper.interpretApiError(error),
            canRetry: canRetry,
            canGoBack: canGoBack !== undefined ? canGoBack : !canRetry
        };
    };

    /**
     * Attempts to extract any custom Api error from the server into a format that can be displayed in the UI.
     * @param error the Error obejct, as returned by the Api.
     */
    private static interpretApiError = (error: any): Domain.Shared.ApiErrorDetails => {
        if (!error) {
            return undefined;
        }
        const backendErrorDetails = error.response?.data;
        if (!backendErrorDetails) {
            // Atempt to extract info from standard Error.
            if (error instanceof Error) {
                const err = (error as Error);
                let errorType = Domain.Shared.ApiKnownErrorTypes.ClientSideError;

                if (error['isAxiosError']) {
                    // in case we want to treat Axios error differently, we can do so here.
                    if (error['code'] === 'ECONNABORTED') {
                        errorType = Domain.Shared.ApiKnownErrorTypes.AxiosTimeout;
                    }
                }

                return {
                    title: err.name,
                    detail: err.message,
                    instance: undefined,
                    status: undefined,
                    type: errorType,
                };
            }

            return undefined;
        }

        return error.response?.data;
    }

    /**
     * Attempts to extract a human-readable message to display in the main error body
     * @param error the Error obejct, as returned by the Api.
     */
    private static extractMainMessage = (error: Error): string => {
        //TODO: return specific texts for well-known backend error types?
        return ApiErrorReportingHelper.GenericMessages.Default;
    }

    /**
     * generates custom catch
     */
    public static generateCustomErrorMessage = (err, setError, errorType, defaultErrorType) => {
        const errorInfo = ApiErrorReportingHelper.generateErrorInfo(defaultErrorType, err);
        if (errorInfo?.details?.type?.includes(errorType)) {
            setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[errorType] });
        } else {
            setError(ApiErrorReportingHelper.generateErrorInfo(defaultErrorType, err, true));
        }
    }
}
