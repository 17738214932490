import React, { useMemo } from 'react';
import { createSource, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { Finance } from '@liasincontrol/data-service';
import { FilterModel } from './ObligationsFilter';
import * as Domain from '@liasincontrol/domain';
import { ValidationUtils } from '@liasincontrol/core-service';

type Props = {
    lastRefresh: number;
    filter: FilterModel;
    onDataError: (error: any) => void;
}

type FilterKey = keyof FilterModel;
//https://bobbyhadz.com/blog/typescript-index-signature-parameter-cannot-be-union-type
type FilterExpr = {
    [key in FilterKey]?: any;
}

export const ObligationsGrid: React.FC<Props> = (props) => {

    const customDataSource = useMemo(() => {
        const cleanedFilter = Object.keys(props.filter).filter((column) => !ValidationUtils.isEmpty(props.filter[column]));
        const filter = cleanedFilter.map((column) => getColumnFilter(column as FilterKey, props.filter[column]));

        return createSource({
            keyExpr: "code",
            paginate: true,
            filter: filter,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            dataSourcePromise: Finance.ObligationsDataAccessor.getAll
        });
    }, [props.lastRefresh, props.filter]);

    return <LsGrid
        dataSource={customDataSource}
        columns={columns}
        showRowLines={true}
        onDataError={props.onDataError}
        paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
        gridOffset={10}
    />;
};

const columns: GridColumn<Domain.Finance.Obligation>[] =
    [{
        name: 'baseYear',
        title: 'Basisjaar',
        allowSorting: false,
    }, {
        name: 'journalElementCombinationRK',
        title: 'Combinatie',
        allowSorting: false,
    }, {
        name: 'measureMomentTag',
        title: 'Moment label',
        allowSorting: false,
    }, {
        name: 'description',
        title: 'Omschrijving',
        allowSorting: false,
    }, {
        name: 'amount',
        title: 'Bedrag',
        allowSorting: false,
    }, {
        name: 'code',
        title: 'Code',
        allowSorting: false,
    }, {
        name: 'accountName',
        title: 'Accountnaam',
        allowSorting: false,
    }, {
        name: 'accountNumber',
        title: 'Accountnummer',
        allowSorting: false,
    }, {
        name: 'period',
        title: 'Periode',
        allowSorting: false,
    },
    ];

const getColumnFilter = (column: FilterKey, value: any): FilterExpr => {
    switch (column) {
        case 'baseYear':
            return { baseYear: value };
        default:
            return { [column]: { contains: value } };
    }
};