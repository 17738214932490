import React from 'react';
import { Dictionary } from 'lodash';
import { TemplateSelectElement, SelectElement, TextElement } from '@liasincontrol/ui-elements';
import { AutoFocus, LayoutForm, LayoutField } from '@liasincontrol/ui-basics';
import { FormData, FormMode, ValidationUtils, ValidatorsDictionary, ValueType } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import { measureMomentDefinitionId, workflowTemplateFieldDefinitionId } from '../utils';

type Props = {
    form: FormData<ValueType>,
    formMode: FormMode,
    validators: ValidatorsDictionary,
    measureMoments: Domain.Shared.FieldDefinitionOptionItem[],
    workflowTemplates: Domain.Shared.FieldDefinitionOptionItem[],
    fieldDefinitions: Domain.Shared.FieldDefinition[],
    siteDesigns?: Domain.Publisher.SiteDesign[],
    onFormValueChanged: (value: string, systemId: string) => void,
};

/**
 * Represents a UI component that renders the publication setup form.
 */
export const PublicationForm: React.FC<Props> = (props) => {
    const fieldDefinitions = props.fieldDefinitions.reduce((collection, item) => ({ ...collection, [item.systemId]: item }), {}) as Dictionary<Domain.Shared.FieldDefinition>;
    const nameDefinition = fieldDefinitions[SystemFieldDefinitions.Pub.Name];
    const titleDefinition = fieldDefinitions[SystemFieldDefinitions.Pub.Title];
    const siteDesignDefinition = fieldDefinitions[SystemFieldDefinitions.Pub.SiteDesignId];

    const workflowTemplateDefinition = { systemId: workflowTemplateFieldDefinitionId, id: workflowTemplateFieldDefinitionId, name: 'Workflow', optionItems: props.workflowTemplates } as Domain.Shared.FieldDefinition;
    const measureMomentDefinition = { systemId: measureMomentDefinitionId, id: measureMomentDefinitionId, name: 'Moment', optionItems: props.measureMoments } as Domain.Shared.FieldDefinition;

    return (
        <LayoutForm>
            <LayoutField key={nameDefinition.id} left={1} top={1} width={6} height={1}>
                <AutoFocus>
                    <TextElement
                        id={nameDefinition.id}
                        label={nameDefinition.label ? nameDefinition.label : nameDefinition.name}
                        helpText={{ title: nameDefinition.helpTextTitle, text: nameDefinition.helpText }}
                        editorSettings={ValidationUtils.getEditorSettings(true, false, props.validators, props.form, (val: string) => { props.onFormValueChanged(val, SystemFieldDefinitions.Pub.Name) }, SystemFieldDefinitions.Pub.Name)}
                        value={props.form.values[SystemFieldDefinitions.Pub.Name] as string}
                    />
                </AutoFocus>
            </LayoutField>
            <LayoutField key={titleDefinition.id} left={1} top={2} width={6} height={1}>
                <TextElement
                    id={titleDefinition.id}
                    label={titleDefinition.label ? titleDefinition.label : titleDefinition.name}
                    helpText={{ title: titleDefinition.helpTextTitle, text: titleDefinition.helpText }}
                    editorSettings={ValidationUtils.getEditorSettings(true, false, props.validators, props.form, (val: string) => { props.onFormValueChanged(val, SystemFieldDefinitions.Pub.Title) }, SystemFieldDefinitions.Pub.Title)}
                    value={props.form.values[SystemFieldDefinitions.Pub.Title] as string}
                />
            </LayoutField>
            <LayoutField left={1} top={3} width={6} height={1}>
                <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                    id={`pubform-select-moment-${measureMomentDefinition.id}`}
                    displayExpr='name'
                    label={measureMomentDefinition.label || measureMomentDefinition.name}
                    searchable={false}
                    clearable={true}
                    editorSettings={ValidationUtils.getEditorSettings(true, false, props.validators, props.form, 
                        (item: Domain.Shared.FieldDefinitionOptionItem) => props.onFormValueChanged(item?.id, measureMomentDefinitionId), 
                        measureMomentDefinitionId)}
                    optionItems={measureMomentDefinition.optionItems}
                    value={measureMomentDefinition.optionItems.find(o => o.id === props.form.values[measureMomentDefinitionId])}
                />
            </LayoutField>
            {props.formMode === FormMode.AddNew && <LayoutField left={1} top={4} width={6} height={1}>
                <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                    id={`pubform-select-template-${workflowTemplateDefinition.id}`}
                    displayExpr='name'
                    label={workflowTemplateDefinition.label ? workflowTemplateDefinition.label : workflowTemplateDefinition.name}
                    searchable={false}
                    clearable={true}
                    editorSettings={ValidationUtils.getEditorSettings(true, false, props.validators, props.form, 
                        (item: Domain.Shared.FieldDefinitionOptionItem) => {  props.onFormValueChanged(item?.id, workflowTemplateFieldDefinitionId) }, 
                        workflowTemplateFieldDefinitionId)}
                    optionItems={workflowTemplateDefinition.optionItems}
                    value={workflowTemplateDefinition.optionItems.find(o => o.id === props.form.values[workflowTemplateFieldDefinitionId])}
                />
            </LayoutField>
            }
            {props.siteDesigns &&
                <LayoutField key={siteDesignDefinition.id} left={1} top={6} width={6} height={1}>
                    <TemplateSelectElement
                        id={siteDesignDefinition.id}
                        label={siteDesignDefinition.label ? siteDesignDefinition.label : siteDesignDefinition.name}
                        editorSettings={ValidationUtils.getEditorSettings(true, false, props.validators, props.form, (val: string) => { props.onFormValueChanged(val, SystemFieldDefinitions.Pub.SiteDesignId) }, SystemFieldDefinitions.Pub.SiteDesignId)}
                        items={props.siteDesigns}
                        value={props.form.values[SystemFieldDefinitions.Pub.SiteDesignId] as string}
                    />
                </LayoutField>}
        </LayoutForm>);
};
