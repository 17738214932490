import styled from 'styled-components';
import { palette, px } from '@liasincontrol/ui-basics';

const GridOverflow = styled.div`
    margin-top: 20px;
    overflow-y: auto;
    padding-right: 20px;    
    max-height: calc(100% - 50px);

    td {
        border-bottom: none;
    }
`;

const SingleValueWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const SingleValueLabel = styled.div`
    margin-left: 3px;
`;

const RightBox = styled.div`
    float: right;
    width: ${px(250)};
`;

const TitleContainer = styled.span`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Title = styled.span`
    margin-bottom: ${px(8)};
    color: ${palette.grey1};
    border-bottom: 3px solid ${palette.grey1};
    font-weight: 600;
    font-size: 1.1rem;
    padding: ${px(8)} ${px(16)};
    text-align: center;
`;

const styledComponents = { GridOverflow, SingleValueWrapper, SingleValueLabel, RightBox, TitleContainer, Title };
export default styledComponents;