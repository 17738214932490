import React from 'react';
import { Routes as StudioRoutes } from '@liasincontrol/studio';
import { SideMenuLayout } from '../Layouts';

/**
 * Represents an UI element that renders all Studio module sub-routes, in their specific Layouts.
 */
const StudioModuleRoutes: React.FC = () => {
    return (
        <SideMenuLayout
            look="normal"
            sideMenu={StudioRoutes.StudioMenu}
            components={StudioRoutes.Contents}
        />
    );
}

export default StudioModuleRoutes;
