import React, { useCallback, useState, useMemo } from 'react';
import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { HelpText, Label, MandatoryIcon } from '@liasincontrol/ui-basics';
import TextBox, { TextBoxTypes } from 'devextreme-react/text-box';
import { IconSelectProps } from './index.props';
import Styled from './index.styled';

const CreateDataSource = (items: any, searchExpr: any, searchValue: string): DataSource => {
    const source = new DataSource({
        paginate: false,
        store: new CustomStore({
            load: () => items,
            loadMode: 'raw'
        }),
        searchExpr: searchExpr,
    });

    if (searchValue && searchValue !== null) {
        source.searchValue(searchValue);
    }
    return source;
};

/**
 * Represents a UI component that renders a scrollable icon-list editor.
 */
const IconSelectEditor: React.FC<IconSelectProps> = (props) => {

    const [searchValue, setSearchValue] = useState('');

    const customDataSource = useMemo(() => {
        return CreateDataSource(props.items, props.searchExpr, searchValue);
    }, [props.items, props.searchExpr, searchValue]);

    const valueChanged = useCallback((data: TextBoxTypes.ValueChangedEvent) => {
        setSearchValue(`${data.value.replace(/\s/g, '').toLowerCase()}`);
    }, []);

    return (
        <>
            {props.label && <Label id={`${props.id}-label`} htmlFor={props.id} text={props.label} />}
            {props.label && props.helpText && props.helpText.text && (
                <HelpText title={props.helpText.title}>{props.helpText.text}</HelpText>
            )}
            <span>{!props.editorSettings?.disabled && props.editorSettings?.restrictions?.required && <MandatoryIcon />}</span>
            <TextBox
                mode='search'
                placeholder='Zoeken...'
                elementAttr={{
                    id: `input-search-${props.id}`,
                }}
                valueChangeEvent="keyup"
                onValueChanged={valueChanged}
                value={searchValue}
            >
            </TextBox>
            <Styled.StyledTileView
                dataSource={customDataSource}
                itemRender={props.itemRender}
                width='100%'
                itemMargin={10}
                direction='vertical'
                baseItemHeight={props.baseWidth ? props.baseWidth + 20 : undefined}
                baseItemWidth={props.baseHeight ? props.baseHeight + 20 : undefined}
                height={300}
                noDataText='Er komen geen iconen overeen met de zoekcriteria'
                showScrollbar='always'
                onItemClick={(item) => {
                    props.editorSettings?.onChange(item.itemData);
                }}
                onItemRendered={(item) => {
                    if (item.itemData[props.keyExpr] === props.value) {
                        item.itemElement.classList.add('dx-state-disabled');
                    }
                }}
            />
        </>
    );
};

export default IconSelectEditor;