import styled from 'styled-components';

const GridContainer = styled.div`
  grid-column: 2 / span 12;
  grid-row: 7 / -1;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
`;

const Column = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 10px;
`;

const ModalFooter = styled.div`
  margin-top: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const styledComponents = { GridContainer, Column, ModalFooter };
export default styledComponents;