import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { FocusField } from '@liasincontrol/ui-basics';
import { ImageFocusPointProps } from './index.props';

const DEFAULT_FOCUS_VALUE = '0.5, 0.5, 1';

/**
 * Represents a UI component that renders an image focus point editor.
 */
export const ImageFocusPointEditor: React.FC<ImageFocusPointProps> = (props) => {
    const [imageUrl, setImageUrl] = useState<string>();

    const [focusValue, setFocusValue] = useState<string>(props.value ? props.value : DEFAULT_FOCUS_VALUE);
    const onChangedDebounced = useMemo(() => _.debounce(props.editorSettings?.onChange, 300), [props.editorSettings?.onChange]);

    useEffect(() => {
        if ((props.value || '') === focusValue) {
            return onChangedDebounced.cancel;
        }

        onChangedDebounced(focusValue);
        return onChangedDebounced.cancel;
    }, [focusValue, onChangedDebounced, props.value]);

    useEffect(() => setFocusValue(props.value ? props.value : DEFAULT_FOCUS_VALUE), [props.value]);

    useEffect(() => {
        if (props.attachmentId) {
            props.onLoadAttachment(props.attachmentId).then((response) => {
                setImageUrl(URL.createObjectURL(response));
            });
        } else {
            setImageUrl('');
        }
    }, [props.attachmentId]);

    if (!imageUrl) {
        return null;
    }

    return (
        <FocusField
            id="image-focus-point-field"
            label={props.label}
            helpText={{ text: props?.helpText?.text ? props.helpText.text : 'Zet het focus punt op het belangrijkste deel van de foto.' }}
            value={{ imageUrl: imageUrl, focus: focusValue }}
            onChange={(value) => setFocusValue(value.focus)}
        />
    );
};