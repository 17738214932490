import _ from 'lodash';
import { PossibleValidatorsDataType, ValidatorsDictionary, ValueType, AnyFormData, ValidationUtils, ValidationErrorData } from '../validators';

/**
 * Helper class that exposes common form-related operations.
 */
export class FormHelper {
    /**
     * Runs all form validators on the form data supplied.
     * @param validators Map of systemFieldIs to validator for the associated field.
     * @param formValues Map of systemFieldIds to form field values.
     * @param validationErrors Map of the current form validation error. Required only when the form handles custom errors.
     * @param resetExternalErrorFieldSystemIds Array of field system ids for which external errors should be removed. Used only when the form handles custom errors.
     * @returns
     */
    public static validateForm = (
        validators: ValidatorsDictionary,
        formValues: Record<string, ValueType>,
        validationErrors: Record<string, ValidationErrorData[]>,
        resetExternalErrorFieldSystemIds: string[] = []
    ): { errors: Record<string, ValidationErrorData[]>, hasErrors: boolean } => {
        const errors: Record<string, ValidationErrorData[]> = {};
        let hasErrors = false;

        // Validate all form fields on each re-render.
        for (const fieldSystemId of Object.keys(validators)) {
            const externalFieldErrors = validationErrors[fieldSystemId]?.filter((error) => error.isExternal && !resetExternalErrorFieldSystemIds.includes(fieldSystemId)) ?? [];
            const fieldErrors = validators[fieldSystemId].validate(formValues[fieldSystemId] as PossibleValidatorsDataType, formValues);
            errors[fieldSystemId] = [];

            if (fieldErrors.length > 0 || externalFieldErrors.length > 0) {
                errors[fieldSystemId] = [...fieldErrors, ...externalFieldErrors];
                hasErrors = true;
            }
        }

        return {
            errors,
            hasErrors,
        };
    };

    /**
     * Runs all the form validations and sets an external error on a given form field.
     * @param error Defines the error message to be set on the field.
     * @param fieldSystemId SystemId of the field on which the custom error will be set.
     * @param validators Map of systemFieldIs to validator for the associated field.
     * @param form Form data with previous values & touched state for all fields.
     * @param fieldValue The current value of the field.
     */
    public static setExternalFieldError = (error: string, fieldSystemId: string, validators: ValidatorsDictionary, form: AnyFormData, fieldValue?: string): AnyFormData => {
        const clonedForm = _.cloneDeep(form);
        clonedForm.touched[fieldSystemId] = true;
        if (fieldValue) {
            clonedForm.values[fieldSystemId] = fieldValue;
        }

        const { errors } = FormHelper.validateForm(validators, clonedForm.values, clonedForm.validationErrors);

        if (!errors[fieldSystemId]?.some(e => e.error === error)) {
            errors[fieldSystemId] = [...errors[fieldSystemId], { error, isExternal: true }];
        }

        clonedForm.validationErrors = errors;
        clonedForm.isValid = false;

        return clonedForm;
    };

    /**
     * Applies the value of a field change to the form data object and handles any touched state evaluation.
     *
     * @param fieldValue Value being applied to the field.
     * @param fieldSystemId SystemId of the field being changed.
     * @param form Form data with previous values & touched state for all fields.
     * @param touchedCallback Optional callback to invoke when a field is found as 'touched' for the first time.
     */
    public static handleFormValueChanged = (fieldValue: ValueType, fieldSystemId: string, form: AnyFormData, touchedCallback?: (newFormData: AnyFormData) => void): AnyFormData => {
        const clonedForm = _.cloneDeep(form);
        clonedForm.values[fieldSystemId] = fieldValue;

        if (!clonedForm.touched[fieldSystemId] && FormHelper.isTouched(fieldValue, fieldSystemId, form.values)) {
            clonedForm.touched[fieldSystemId] = true;
            if (touchedCallback) {
                touchedCallback(clonedForm);
            }
        }

        return clonedForm;
    };

    /**
     * Applies the values of a collection of field changes to the form data object and handles any touched state evaluation.
     *
     * @param fields Collection of field pairs(value and system id).
     * @param form Form data with previous values & touched state for all fields.
     * @param touchedCallback Optional callback to invoke when a field is found as 'touched' for the first time.
     */
    public static handleFormValuesChanged = (fields: Record<string, ValueType>, form: AnyFormData, touchedCallback?: (newFormData: AnyFormData) => void): AnyFormData => {
        const clonedForm = _.cloneDeep(form);
        let fieldTouched = false;

        for (const field of Object.keys(fields)) {
            clonedForm.values[field] = fields[field];

            if (!clonedForm.touched[field] && FormHelper.isTouched(fields[field], field, form.values)) {
                clonedForm.touched[field] = true;
                fieldTouched = true;
            }
        }

        if (fieldTouched && touchedCallback) {
            touchedCallback(clonedForm);
        }

        return clonedForm;
    };

    /**
     * Checks if a new value applied to a form field should mark that field as 'touched'.
     * @param fieldValue Value being applied to the field.
     * @param fieldSystemId SystemId of the field being changed.
     * @param originalFormValues Map of systemFieldIds to form field values.
     */
    public static isTouched = (fieldValue: ValueType, fieldSystemId: string, originalFormValues: Record<string, ValueType>): boolean => {
        if (!ValidationUtils.isEmpty(fieldValue)) return true;

        // There's also the case when an untouched field is reset from it's original, non-empty value to a new empty value.
        // This should also constitute a 'touched' state.
        if (ValidationUtils.isEmpty(fieldValue) && !ValidationUtils.isEmpty(originalFormValues[fieldSystemId])) return true;

        return false;
    };

    /**
     * Validate and store form changes (values and errors).
     * @param form Form data with previous values.
     * @param value Value being applied to one of the form's field.
     * @param validators Map of systemFieldIs to validator for the associated field.
     * @param systemId SystemId of the field being changed.
     * @param resetExternalErrorFieldSystemIds Array of field system ids for which custom errors should be removed. Used only when the form handles custom errors.
     * @returns A new form object.
     */
    public static validateAndStoreFormValue = <T extends AnyFormData>(
        form: T,
        value: ValueType,
        validators: ValidatorsDictionary,
        systemId: string,
        resetExternalErrorFieldSystemIds: string[] = [],
    ): T => {
        const clonedForm = FormHelper.handleFormValueChanged(value, systemId, form) as T;
        const { errors, hasErrors } = FormHelper.validateForm(validators, clonedForm.values, clonedForm.validationErrors, resetExternalErrorFieldSystemIds);
        clonedForm.validationErrors = errors;
        clonedForm.isValid = !hasErrors;
        return clonedForm;
    };

    /**
     * Reset external validation errors on a form
     * @param error Defines the error message to be set on the field.
     * @param fieldSystemId SystemId of the field on which the custom error will be set.
     * @param validators Map of systemFieldIs to validator for the associated field.
     * @param form Form data with previous values & touched state for all fields.
     * @param fieldValue The current value of the field.
     */
    public static resetExternalFieldError = (form: AnyFormData, resetExternalErrorFieldSystemIds: string[] = []): AnyFormData => {
        const clonedForm = _.cloneDeep(form);
        if (resetExternalErrorFieldSystemIds.length === 0) return clonedForm;
        const errors: Record<string, ValidationErrorData[]> = {};
        let hasErrors = false;

        //Clean external errors for specific systemIds
        for (const fieldSystemId of Object.keys(clonedForm.values)) {
            errors[fieldSystemId] = clonedForm.validationErrors[fieldSystemId]?.filter((error) => error.isExternal && !resetExternalErrorFieldSystemIds.includes(fieldSystemId)) ?? [];
            hasErrors = hasErrors && errors[fieldSystemId]?.length > 0;
        }

        clonedForm.validationErrors = errors;
        clonedForm.isValid = !hasErrors;

        return clonedForm;
    };

}
