import { useSelector, useDispatch } from 'react-redux';
import { State, AjaxRequestStatus, UsersActionCreator } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';

type UsersType = {
    items: Domain.Shared.User[],
    status: AjaxRequestStatus,
}

export const useUsers = () => {
    const templates = useSelector<State, UsersType>((state) => state.users);
    const dispatch = useDispatch();

    if (templates.status === AjaxRequestStatus.NotSet) {
        dispatch(UsersActionCreator.set());
    }

    return templates;
}