import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';
import { oDataResponse } from '../../Shared/oData';

/**
 * Represents a data accessor of journal elements.
 */
export class JournalElementDataAccessor {
  /**
    * Gets a list of journal elements.
    * @param query Defines an odata query string.
    * @returns
    */
  public static get = async (query = ''): Promise<AxiosResponse<oDataResponse<Domain.Finance.JournalElement[]>>> => {
    const config = await DataAccessHostUtils.getPubRequestConfig();

    return HttpClient.get<oDataResponse<Domain.Finance.JournalElement[]>>(`/api/finance/journalelement${query}`, config);
  };

}
