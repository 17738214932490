import React from 'react';
import styled from 'styled-components';
import { cssFontBold, cssFontNormal, px } from '../style';
import { IHelpTextProps } from './Props';
import { Tooltip } from '../tooltip/container';
import { Button } from '../button';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
// tslint:disable:no-magic-numbers

export interface IPopoverProps {
    readonly isVisible: boolean;
}

export interface IHelpTextState {
    readonly popOver: boolean;
}

export class HelpText extends React.Component<IHelpTextProps, IHelpTextState> {
    public state = { popOver: false };

    public render() {
        const { children, title, type } = this.props;
        return (
            <Tooltip isTooltipVisible={this.state.popOver} content={<Wrap>{title ? <Title>{title}</Title> : null}{children ? <Content>{children}</Content> : null} </Wrap>}>
                {type === 'info' ?
                    <AllignedButton
                        id={`btn-show-info-${title}`}
                        btnbase="iconbuttons"
                        btntype="small_transparent"
                        onClick={this.show}
                        icon={<InfoOutlinedIcon />}
                    />
                    : <IconBtn onClick={this.show}>?</IconBtn>}
            </Tooltip>
        );
    }

    public show = () => {
        this.setState({ popOver: !this.state.popOver });
    }
}

/* styling */

const Wrap = styled.div`
    padding: 12px;
    max-width: 350px;
`;

const AllignedButton = styled(Button)`
    vertical-align: middle;
`;

const IconBtn = styled.button`
    padding: 0;
    width: ${px(24)}; 
    height: ${px(24)};
    border-radius: 2rem;
    font-weight:800; 
    font-size:${px(12)}; 
    letter-spacing:0em;
    color: #4b55f6;
    vertical-align:middle;
    background-color: #F8F9FE;
    border:none 0; 
    outline: 0;
    margin-left: 5px;
    cursor:pointer;

    &:hover {
        background-color: #F4F4F8;
    }
`;

const Title = styled.h6`
    margin:0; padding:0 0 3px 0;
    ${cssFontBold(14, 20, 40)}
`;

const Content = styled.p`
    white-space: pre-wrap;
    margin:0; 
    padding:0 0 3px 0;
    word-wrap: break-word;
    ${cssFontNormal(14, 20, 0)}
`;
