import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class TileMenuControl extends BaseControlElement {

    constructor(title?: string) {
        super();
        this.title = title;
    }

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Title)
    title = '';

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TilesComplex)
    tilesComplex: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TileImageKind)
    tileImageKind: string;
}

export default TileMenuControl;