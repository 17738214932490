import React from "react";
import { LoadPanel as DxLoadPanel } from 'devextreme-react/load-panel';
import { PositionConfig } from "devextreme/animation/position";

export type LoadPanelProps = {
    visible: boolean;
    position?: PositionConfig;
    container?: string;
    shading?: boolean;
    shadingColor?: string;
    showPane?: boolean;
    text?: string;
}

export const LoadPanel: React.FC<LoadPanelProps> = (props) => {
    return (
        <DxLoadPanel
            position={props.position}
            container={props.container}
            visible={props.visible}
            shading={props.shading}
            shadingColor={props.shadingColor}
            message={props.text}
            showPane={props.showPane}
        />
    )
};

LoadPanel.defaultProps = {
    shading: true,
    showPane: true,
    shadingColor: "rgba(0,0,0,0.4)",
    text: "Bezig met verwerken...",
};
