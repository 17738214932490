import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class PieChartControl extends BaseControlElement {

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Title)
    title: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PieChartCustomLabel)
    customLabel: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PieChartValue)
    value: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PieChartArgument)
    argument: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Filter)
    filter: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSource)
    dataSourceId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PieChartValueFormat)
    valueFormat: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PieChartType)
    type: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ChartLegend)
    legend: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ChartShowLabels)
    showLabelsId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PieChartMaximumSlices)
    maxSlices?: number;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.PieChartCombinedSliceLabel)
    combinedSlicesLabel: string;
}

export default PieChartControl;