import React, { useState } from 'react';
import { ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import { JournalCombinationsGrid } from '../../../components/JournalCombinationsGrid';
import { FilterModel, JournalCombinationFilters } from '../../../components/JournalCombinationsGrid/Filters';

type Props = {
  baseYear: number,
  journalElementKinds: Domain.Finance.JournalElementKind[],
  onCancel: () => void,
  onError: (exception: any) => void,
  onCombinationSelected: (combination: Domain.Finance.JournalCombination) => void
};

export const SelectJournalCombination: React.FC<Props> = (props) => {
  const [filter, setFilter] = useState<FilterModel>(props.baseYear ? { baseYear: props.baseYear } : {});

  const filterCombinations = (filter: FilterModel) => {
    setFilter(filter);
  };

  return (
    <ModalDialog
      settings={{
        width: 1300,
        look: 'interactive',
        title: 'Kies een boekingscombinatie',
        footer: <ModalDialogFooter leftButtonText='Sluiten' onLeftButtonClick={props.onCancel} />,
      }}
    >
      {props.journalElementKinds &&
        <>
          <JournalCombinationFilters filter={filter} onFilterChanged={filterCombinations} />
          <JournalCombinationsGrid
            journalElementKinds={props.journalElementKinds}
            filter={filter}
            pageSize={7}
            onError={props.onError}
            onRowClick={props.onCombinationSelected} />
        </>}
    </ModalDialog>
  );
};
