/**
 * Represents the fields that must be specified to create a new detail card for an element definition.
 */
 export class CreateDetailCard {
    
    elementDefinitionId: string;

    name: string;

    layout: string;

    isDefault: boolean;
}