import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBack from '@mui/icons-material/ArrowBack';
import * as Domain from '@liasincontrol/domain';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { ValueType, FormMode, FormInfo, ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { State, ActionSource, ModulesActionCreator } from '@liasincontrol/redux-service';
import { IndicatorSize, LoadIndicator } from '@liasincontrol/ui-devextreme';
import { WrapperContent, PageTitle, Heading1, Bar, Button, EditingToolbar, ErrorOverlay, ModalDialog } from '@liasincontrol/ui-basics';
import { UserIdentity } from '@liasincontrol/auth-service';
import { RoleForm } from '../RoleForm';
import { ConfirmDialog } from '../../_shared/ConfirmDialog';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the role details page.
 */
const Index: React.FC<Props> = (props) => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [role, setRole] = useState<Domain.Shared.UserRole>(undefined);
    const [userRoles, setUserRoles] = useState<{ id: string, name: string }[]>([]);
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [viewMode, setViewMode] = useState<FormMode>(FormMode.View);
    const [formInfo, setFormInfo] = useState<FormInfo<ValueType>>({ values: undefined, isValid: true, isTouched: false });
    const [confirmDialogVisible, setConfirmDialogVisible] = useState<boolean>(false);
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);

    useEffect(() => {
        DataAccess.RolesDataAccess.get(id)
            .then((response) => {
                setRole(response.data);
                setError(undefined);
            }).catch((err) => {
                setRole(undefined);
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
            });
    }, [id, lastRefresh]);

    useEffect(() => {
        DataAccess.RolesDataAccess.getAll()
            .then((response) => {
                setUserRoles(response.data);
            }).catch();
    }, [lastRefresh]);

    const otherRoleNames = useMemo(
        () => userRoles.filter(r => r.id !== role?.id).map(role => role.name)
        , [userRoles, role]);


    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    const getRoleControl = (): JSX.Element => {
        if (!role) {
            return null;
        }

        return (
            <RoleForm
                role={role}
                mode={viewMode}
                roleNames={otherRoleNames}
                licenses={props.userIdentity?.profile?.licenses || []}
                onFormDataChanged={setFormInfo}
            />
        );
    };

    /**
     * Represents an event handler that triggers when saving the role changes.
     */
    const saveChanges = () => {
        if (!formInfo.values) {
            setViewMode(FormMode.View);
            return;
        }

        const roleDto: Domain.Dto.Shared.UserRole = {
            name: formInfo.values['name'],
            permissions: formInfo.values['permissions']
        };

        DataAccess.RolesDataAccess.save(id, roleDto)
            .then(() => {
                setViewMode(FormMode.View);
                setLastRefresh(Date.now());
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.RoleNameNotUnique)) {
                    setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.RoleNameNotUnique] });
                } else {
                    setError(errorInfo);
                }
                setViewMode(FormMode.View);
            });
    };

    /**
     * Represents an event handler that triggers when discarding the role changes.
     */
    const cancelChanges = () => {
        setViewMode(FormMode.View);
    };

    /**
     * Represents an event handler that triggers when deletingd a role.
     */
    const deleteRole = () => {
        setIsBusy(true);
        DataAccess.RolesDataAccess.delete(id)
            .then(() => {
                navigate(`/admin/role/list`);
                setIsBusy(false);
                setConfirmDialogVisible(false);
            }).catch((err) => {
                setIsBusy(false);
                setConfirmDialogVisible(false);
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, err));
            });
    };

    return (
        <WrapperContent>
            <PageTitle>
                <Heading1>
                    <Button
                        btnbase="iconbuttons"
                        btntype="medium_transparentmain"
                        icon={<ArrowBack />}
                        onClick={() => navigate('/admin/role/list')}
                    />
                    {role ? role.name : !error ? <LoadIndicator variant={IndicatorSize.default} /> : `Rol`}
                </Heading1>
            </PageTitle>
            <Bar look="toolbar">
                <Bar start>
                    {!error && (<>
                        <Button id={`btn-edit-${id}`} btnbase="textbuttons" btntype="medium_icon" onClick={() => setViewMode(FormMode.Edit)}>
                            Bewerken
                        </Button>
                        <Button id={`btn-delete-${id}`} btnbase="textbuttons" btntype="medium_icon" onClick={() => setConfirmDialogVisible(true)}>
                            Verwijderen
                        </Button>
                    </>)}
                </Bar>
            </Bar>
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                {viewMode === FormMode.Edit ? (
                    <ModalDialog customPadding modalDialogStyle="custom"
                        toolbars={<EditingToolbar id={role.id} look="default" isVisible={viewMode === FormMode.Edit}
                            isValid={formInfo?.isValid}
                            disabled={!formInfo?.isTouched} onSave={saveChanges} onCancel={cancelChanges} />}>
                        {getRoleControl()}</ModalDialog>
                ) : (
                    getRoleControl()
                )}
            </ErrorOverlay>
            {confirmDialogVisible ? (
                <ConfirmDialog
                    look="message"
                    title="Verwijder rol"
                    text="Weet u zeker dat u de rol wilt verwijderen?"
                    confirmButtonText="Verwijderen"
                    disableConfirmation={isBusy}
                    onCancel={() => setConfirmDialogVisible(false)}
                    onConfirm={deleteRole} />
            ) : null}
        </WrapperContent>
    );
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Publication],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
