import styled from 'styled-components';
import { px, palette } from '@liasincontrol/ui-basics';

interface IPresentation {
    readonly selected: boolean;
    readonly hover?: boolean;
    readonly disabled?: boolean;
}

const Wrapper = styled.div`
    transition: outline 0.15s ease;
    padding: ${px(12)};
    margin-bottom: ${px(12)};
    background: ${palette.primary2semitransparent};    
    border: ${(p: IPresentation) => (p.selected ? `${px(1)} solid ${palette.primary1}` : `${px(1)} solid ${palette.grey3b}`)};    
    border-radius: ${px(5)};
    position: relative;
    cursor: pointer;
`;

const InnerWrapper = styled.div`
    pointer-events: none;
`;

const styledComponents = { Wrapper, InnerWrapper };

export default styledComponents;