import React, { useRef } from 'react';
import TabPanel, { Item, TabPanelRef } from 'devextreme-react/tab-panel';
import { IconSize, useElementSize } from '@liasincontrol/ui-basics';
import { useTabPanelNavButtonsStopPropagation } from '../../helpers/hooks';
import { DxTabsIconPosition } from '../..';

type Props<T> = {
    readonly id: string;

    readonly data: T[];

    readonly selectedTab?: number;

    readonly renderTitle?: (data: T, index: number) => React.ReactNode;

    readonly getContainerItemContent?: () => JSX.Element;

    readonly tabsPosition?: TabPositions;

    readonly tabIconsPosition?: DxTabsIconPosition;

    readonly iconSize?: IconSize,

}

export type TabPositions = 'top' | 'left' | 'bottom' | 'right';

export const LsTabPanel = React.memo(<T extends { title: string, text?: string, icon?: string }>(props: Props<T>) => {
    const tabRef = useRef<TabPanelRef>(null);
    const [width] = useElementSize(tabRef.current?.instance()?.element());
    const calculatedTabsPosition = !!width && (width < 320) ? 'top' : props.tabsPosition;

    useTabPanelNavButtonsStopPropagation(tabRef);

    return (
        <TabPanel
            ref={tabRef}
            id={`tabpanel-${props.id}`}
            selectedIndex={props.selectedTab}
            repaintChangesOnly={false} //this is needed for tabs to be rendered correctly when moving them left or right
            deferRendering={true} //this is needed to improve performance when moving tabs left or right
            loop={true}
            animationEnabled={false}
            swipeEnabled={false}
            showNavButtons={true}
            itemTitleRender={props.renderTitle}
            tabsPosition={calculatedTabsPosition}
            iconPosition={props.tabIconsPosition}
        >
            {props.data.map((d, index) => {
                    return (<Item
                        key={index}
                        title={d.title}
                        icon={d.icon}
                        text={String(index)}
                        render={() => props.getContainerItemContent()}
                    />)
                })
            }
        </TabPanel>
    );
});