import styled, { css, keyframes } from 'styled-components';
import { px, Button } from '@liasincontrol/ui-basics';

// Animations for slide- and fade-in from the top or from the right
const aniSlideInRight = keyframes`
    0% { transform: translate(200%, ${px(-8)}); opacity: 0; }
    100% { transform: translate(100%, ${px(-8)}); opacity: 1; }
`;

const slideAnimationRight = css`
    animation: ${aniSlideInRight} 0.2s ease forwards;
`;

const ButtonContainerTopRight = styled.div`
    position: absolute;
    display: grid;
    grid-auto-flow: 'row';
    grid-gap: ${px(8)};
    right: ${`-${px(10)}`};
    top: ${`${px(10)}`}; 
    z-index: 999;
    ${slideAnimationRight};
`;

const ButtonIcon = (styled(Button)`
        > span {
            padding: 0;
            height: ${px(40)};
            width: ${px(40)};
        }` ) as typeof Button;

const styledComponents = { ButtonContainerTopRight, ButtonIcon };

export default styledComponents;