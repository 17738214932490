import React from 'react';
import { LookupField } from '@liasincontrol/ui-devextreme';

import { LookupProps } from './index.props';
import { getValidationErrorsNode } from '../../shared/validationHelper';

export const LookupEditor: React.FC<LookupProps> = (props) => {

    return (
        <LookupField
            id={props.id}
            key={props.id}
            label={props.label}
            helpText={props.helpText}
            mandatory={props.editorSettings?.restrictions?.required}
            error={getValidationErrorsNode(props.editorSettings?.validationErrors)}
            value={{
                value: props.value,
                displayExpr: props.displayExpr,
                items: props.items,
                placeholder: props.placeholder ?? "Kies...",
                dataSource: props.dataSource,
                isDisabled: props.editorSettings?.disabled,
                isClearable: props.editorSettings?.clearable,
                noDataText: props.noDataText ?? "Geen gegevens beschikbaar",
                valueExpr: props.valueExpr,
                itemRender: props.itemRender,
            }}
            onChange={(val) => {
                props.editorSettings?.onChange(val?.value?.value);
            }}
        />
    );
}
