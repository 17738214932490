import { AxiosResponse } from 'axios';
import buildQuery from 'odata-query';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DefinitionsHelper } from '@liasincontrol/core-service';
import { AppSettingsService } from '@liasincontrol/config-service';
import { oDataResponse } from '../../Shared/oData';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents the dataSource data accessor.
 */
export class DataSources {
    /**
     * Get the list of datastores.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param query Defines the query that can be used for filtering the data on the server side.
     */
    public static getDataSources = async (publicationId: string, query = ''): Promise<AxiosResponse<oDataResponse<Domain.Publisher.Element[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<oDataResponse<Domain.Publisher.Element[]>>(`/api/publication/${publicationId}/datasource${query}`, config);
    };

    public static getDataSourcesV2 = async (publicationId: string, query = ''): Promise<AxiosResponse<oDataResponse<Domain.Publisher.DataSource[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<oDataResponse<Domain.Publisher.DataSource[]>>(`/api/publication/${publicationId}/datasource/v2${query}`, config);
    };

    /**
     * Gets the default oData selector to retrieve all data sources for a publication.
     */
    public static getDefaultOdataQuery = (elementDefinition: Domain.Shared.ElementDefinition, count = false, skip?: number): string => {
        const selectableColumnNames = DefinitionsHelper.getSelectableFieldsForAPI(
            new Domain.Publisher.DataSourceElement(),
            ['name', 'dataFileId', 'schemaFileId', 'lastRefreshDate', 'dataStoreId', 'columns'],
            elementDefinition
        );
        const query = buildQuery({
            select: selectableColumnNames.join(','),
            top: AppSettingsService.getAppSettings().Api.Odata.DefaultBackendLimitOverride,
            count: count,
            skip: skip
        });
        return query;
    };

    /**
     * Get a specific dataSource related to a publication.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param dataSourceId Defines the unique identifier of the dataSource.
     */
    public static getDataSource = async (publicationId: string, dataSourceId: string): Promise<AxiosResponse<Domain.Publisher.Element>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Publisher.Element>(`/api/publication/${publicationId}/datasource/${dataSourceId}`, config);
    };

    /**
     * Delete a specific dataSource.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param dataSourceId Defines the unique identifier of the dataSource.
     */
    public static deleteDataSource = async (publicationId: string, dataSourceId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.delete<void>(`/api/publication/${publicationId}/datasource/${dataSourceId}`, config);
    };

    /**
     * Regenerate files for a specific dataSource.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param dataSourceId Defines the unique identifier of the dataSource.
     */
    public static refreshDataSource = async (publicationId: string, dataSourceId: string, forced = false): Promise<AxiosResponse<Domain.Publisher.Element>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<Domain.Publisher.Element>(`/api/publication/${publicationId}/datasource/${dataSourceId}/refresh?forced=${forced}`, null, config);
    };

    /**
     * Creates a new data source.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param createElement Defines the datasource element that needs to be added.
     */
    public static createDataSource = async (publicationId: string, createElement: Domain.Publisher.Element): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>(`/api/publication/${publicationId}/datasource`, createElement, config);
    };

    /**
     * Updates a specific data source.
     *
     * @param publicationId Defines the unique identifier of the publication.
     * @param dataSourceId Defines the unique identifier of the dataSource.
     * @param updateElement Defines the datasource element that needs to be updated.
     */
    public static updateDataSource = async (publicationId: string, dataSourceId: string, updateElement: Domain.Publisher.Element): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`/api/publication/${publicationId}/datasource/${dataSourceId}`, updateElement, config);
    };

    /**
     * Validates if the filter for a datasource is valid.
     * @param filterSettings Defines the value of the filter.
     * @param dataStoreId Defines the unique identifier of the selected data store.
     * @param dataSourceId Defines the unique identifier of the data source.
     */
    public static validateDataSourceFilter = async (filterSettings: string, dataStoreId: string, dataSourceId?: string): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<string>(`/api/datasource/dataplatform/validate`, { filterSettings, dataStoreId, dataSourceId }, config);
    };
}
