import React, { useEffect, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Label, LayoutField, LayoutForm, Section } from '@liasincontrol/ui-basics';
import { AnyFormData, TextValidator, ValidatorsDictionary, ValueType, FormMode, FormInfo, FormHelper, ValidationErrorData } from '@liasincontrol/core-service';
import { ConnectingDialog, TextElement } from '@liasincontrol/ui-elements';
import Styled from './index.styled';

type Props = {
    userGroup?: Domain.Shared.UserGroup,
    userGroupNames?: string[],
    users: Domain.Shared.User[],
    mode: FormMode,
    onFormDataChanged: (formInfo: FormInfo<ValueType>) => void,
};

/**
 * Represents a UI component that renders the user group form.
 */
export const UserGroupForm: React.FC<Props> = (props) => {
    const validators = getValidators(props.userGroupNames);
    const [form, setForm] = useState<AnyFormData>(initFormData(undefined));
    const [addUser, setAddUser] = useState<boolean>(false);

    useEffect(() => {
        if (!props.userGroup) {
            return;
        }
        setForm(initFormData(props.userGroup));
    }, [props.userGroup]);

    const storeFormValue = (value: ValueType, systemId: keyof typeof validators) => {
        const newForm = FormHelper.validateAndStoreFormValue<AnyFormData>(form, value, validators, systemId);
        setForm(newForm);
        props.onFormDataChanged({ values: newForm.values, isValid: newForm.isValid, isTouched: Object.keys(newForm.touched).length > 0 });
    };

    const getAssignmentDialogElement = (userGroup: Domain.Shared.UserGroup) => {
        const listItems = props.users
            .filter((user) => !form.values['userIds'].includes(user.id))
            .map((user) => { return { id: user.id, label: user.name, value: false } });

        return (
            <ConnectingDialog
                disableSaveButton={false}
                mandatory={true}
                title={`Gebruikers koppelen naar ${userGroup.name}`}
                listItems={listItems}
                onCancelled={() => setAddUser(false)}
                onConfirmed={(users) => {
                    const selected = users.map(user => user.id);
                    storeFormValue(form.values['userIds'].concat(selected), 'userIds');
                    setAddUser(false);
                }
                }
            />
        );
    };

    return (
        <Section look='white'>
            <LayoutForm>
                <LayoutField key='userGroup-name' left={1} top={1} width={6} height={1}>
                    <TextElement
                        id='userGroup-nameField'
                        label='Naam'
                        editorSettings={{
                            disabled: props.mode === FormMode.View,
                            restrictions: { required: true, minLength: 2, maxLength: 200 },
                            validationErrors: form.validationErrors['name'],
                            onChange: (value: string) => storeFormValue(value, 'name'),
                        }}
                        value={form.values['name'] as string}
                    />
                </LayoutField>
                {props.mode !== FormMode.AddNew && <>
                    <LayoutField key='userGroup-users' left={1} top={2} width={6} height={1}>
                        <Label>Leden</Label>
                        <Styled.ListWrap id='userGroup-member-list'>
                            {form.values['userIds'].map((userId, index) => {
                                const userName = props.users.find(user => user.id === userId)?.name || 'N/A';
                                return (<Styled.Li key={index}>
                                    <Styled.StyledInput>{userName}</Styled.StyledInput>
                                    {props.mode === FormMode.Edit && <Button id="usergroup-removeuser-btn"
                                        btnbase="iconbuttons"
                                        btntype="medium_fabprimary"
                                        icon={<DeleteIcon />}
                                        aria-label={`Verwijder ${userName}`}
                                        onClick={() => {
                                            const users = form.values['userIds'].filter((id) => id !== userId);
                                            storeFormValue(users, 'userIds');
                                        }} />
                                    }
                                </Styled.Li>);
                            })}
                        </Styled.ListWrap>
                    </LayoutField>
                    {props.mode === FormMode.Edit && <Button id='usergroup-adduser-btn' btnbase='textbuttons' btntype='medium_icon' onClick={() => setAddUser(true)}>
                        Voeg toe
                    </Button>}
                </>}
                {addUser && getAssignmentDialogElement(props.userGroup)}
            </LayoutForm>
        </Section >
    );
};

/**
 * Initialises the validators for the form.
 */
const getValidators = (userGroupNames: string[] = []): ValidatorsDictionary => {
    return {
        'name': new TextValidator({
            required: true,
            stringMaxLength: 200,
            stringMinLength: 2,
            stringType: Domain.Shared.StringType.SingleLine,
        }, (value: string): ValidationErrorData[] => {
            const lowerCaseNamelist = userGroupNames.map(name => name.toLocaleLowerCase());
            if (lowerCaseNamelist.includes(value.toLocaleLowerCase())) {
                return [{ error: 'Naam is niet uniek.' }];
            }
            return [];
        }),
    };
};

/**
 * Initialises the form data with user group data.
 */
const initFormData = (userGroup: Domain.Shared.UserGroup): AnyFormData => {
    return {
        values: {
            'name': userGroup?.name || '',
            'userIds': userGroup?.userIds || [],
        },
        touched: {},
        validationErrors: {},
        isValid: false,
    };
};