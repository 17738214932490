/**
 * Represents a measure moment.
 */
export class MeasureMoment {
    id?: string;
    name: string;
    startDate: Date;
    endDate: Date;
    baseYear: number;
    order: number;
    status?: MeasureMomentStatus;
    hasActiveHierarchy?: boolean;
    tags?: string[];
}

export type MomentItem = { value: string, label: string, closed: boolean };

export enum MeasureMomentStatus {
    Open = 1,
    Closed = 2,
    Deleted = 3
}