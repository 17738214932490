import React from 'react';
import { connect } from 'react-redux';
import { Heading2, PageTitle, PanelGrid, TextImageWidget, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { State } from '@liasincontrol/redux-service';

/**
 * Defines the props of the Cockpit view component.
 */
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

/**
 * Represents a UI component that renders the Studio cockpit view.
 */
const Cockpit: React.FC<Props> = (props) => {
    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Cockpit Studio</Heading2>
                </PageTitle>
                <PanelGrid>
                    <TextImageWidget 
                        title='LIAS Studio' 
                        text='Organiseer en bouw eigen informatiestromen. Structureer iedere gewenste soort van data, gebruik workflow, monitor de voortgang en presenteer gemakkelijk in Publisher.' 
                    />
                </PanelGrid>
            </WrapperContent>
        </Wrapper>
    );
};

const mapStateToProps = (state: State) => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Cockpit);
export { Component as index };
