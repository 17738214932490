import styled from 'styled-components';
import { palette, px } from '@liasincontrol/ui-basics';

const SingleValueWrapper = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: clip;
`;

const HierarchyTypeWrapper = styled(SingleValueWrapper)`
    justify-content: space-between;
    width: 100%;
    > div > svg {
        min-width: ${px(24)};
    }
`;

const SingleValueContent = styled.div`
    display: flex;
    width: ${px(175)};
`;

const SingleValueLabel = styled.div`
    margin-left: ${px(3)};
    text-overflow: ellipsis;
    max-width: 90%;
`;

const IconContainer = styled.div<{ color?: string }>`
    svg {
        fill: ${({ color }) => color ? color : palette.grey2};
    }
`;
const MandatoryLabel = styled.span`
    margin-left: ${px(3)};
    color: ${palette.red};
`;

const styledComponents = { SingleValueLabel, SingleValueWrapper, SingleValueContent, IconContainer, HierarchyTypeWrapper, MandatoryLabel };

export default styledComponents;