import styled from 'styled-components';
import { px } from '@liasincontrol/ui-basics';

const PublishContent = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: ${px(32)};
`;

const PublishWrap = styled.div`
    width: 50%;
    > p {
        margin: 0 0 ${px(8)} 0;
        line-height: ${px(24)};
    }
`;

const FormWrap = styled.div`
    margin-top: ${px(20)};
`;

const ThumbWrap = styled.div`
    width: 33%;
    margin-right: ${px(32)};
    margin: auto;
`;

const styledComponents = { PublishContent, PublishWrap, FormWrap, ThumbWrap };
export default styledComponents;
