import { createAsyncThunk } from '@reduxjs/toolkit';
import { Finance as FinanceDataAccess } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import Payload from '../../../../Payload';

const action = createAsyncThunk<Domain.Finance.StructureNode[], Payload<{ structureRK: string }>, {}>('/finance/structureNodes/set', async (payload) => {
    return FinanceDataAccess.StructuresDataAccessor.getNodes(payload.data.structureRK)
        .then(response => response.data);
});

export default action;