import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class MapControl extends BaseControlElement {

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Title)
    title: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.MapDescription)
    description: string;
    
    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSource)
    dataSourceId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Filter)
    filter: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Latitude)
    latitudeColumn: string;
    
    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Longitude)
    longitudeColumn: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.LabelColumnName)
    labelColumnName: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.MapSize)
    size: string;
}

export default MapControl;
