/* tslint:disable:no-magic-numbers */
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { opacify, rgba } from 'polished';
import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { ListItem } from '../primitives';
import { BasicText } from '../primitives';
import { cssFontNormal, px } from '../style';
import { IInputUnitPresentationProps } from './Props';
import { palette } from '../styleguide';

interface IElementStateProps {
    readonly hasOpened: boolean;
    readonly hasFocus: boolean;
    readonly hasDisabled: boolean;
    readonly hasError: boolean;
    readonly hasSuccess: boolean;
}

const colorIdle = rgba(244, 244, 248, 0);
const colorFocus = rgba(75, 85, 246, 0);
const colorSuccess = rgba(139, 199, 11, 0);
const colorError = rgba(255, 51, 102, 0);
const opacifyNormal = opacify(0.15);
const opacifyHover = opacify(0.3);
const opacifyFocus = opacify(1);

const makeCssBorderIdle = (color: string, colorHover?: string) => css`
    & { border:2px solid ${opacify(1, color)}; }
    &:hover { border:2px solid ${opacifyHover(colorHover || color)}; }
`;
const makeCssBorderNormal = (color: string, colorHover?: string) => css`
    & { border:2px solid ${opacifyNormal(color)}; }
    &:hover { border:2px solid ${opacifyHover(colorHover || color)}; }
`;
const makeCssBorderFocus = (color: string, colorHover?: string) => css`
    & { border:2px solid ${opacifyFocus(color)}; }
    &:hover { border:2px solid ${opacifyFocus(colorHover || color)}; }
`;

const cssBorderNormal = makeCssBorderIdle(colorIdle, colorFocus);
const cssBorderFocus = makeCssBorderFocus(colorFocus);
const cssBorderSuccessFocus = makeCssBorderFocus(colorSuccess);
const cssBorderSuccess = makeCssBorderNormal(colorSuccess);
const cssBorderErrorFocus = makeCssBorderFocus(colorError);
const cssBorderError = makeCssBorderNormal(colorError);
const cssBorder = ({ hasError, hasSuccess, hasFocus }: IElementStateProps) =>
    (hasFocus && hasError && cssBorderErrorFocus)
    || (hasFocus && hasSuccess && cssBorderSuccessFocus)
    || (hasFocus && cssBorderFocus)
    || (hasError && cssBorderError)
    || (hasSuccess && cssBorderSuccess)
    || cssBorderNormal;

const Container = styled.div`
    background: #fff;
    border-radius:4px;
    border:2px solid #f4f4f8;
    display: grid;
    grid-template: "input dropdown" 1fr / minmax(8em, 3fr) minmax(8em, 1fr);
    transition:border-color 0.3s ease;
    ${cssBorder}
`;

const ContainerInput = styled.input`
    ${cssFontNormal(14, 20, 30)}
    background:0 none;
    border: 0;
    color:${palette.grey2};
    margin:0;
    max-width: 100%;
    outline:0;
    padding:${px(12)} ${px(16)};
    text-overflow:ellipsis;
    white-space: nowrap;
    width: 100%;
`;

const makeCssBorderLeftIdle = (color: string, colorHover?: string) => css`
    & { border-left:2px solid ${opacify(1, color)}; }
    ${Container}:hover & { border-left:2px solid ${opacifyHover(colorHover || color)}; }
`;
const makeCssBorderLeftNormal = (color: string, colorHover?: string) => css`
    & { border-left:2px solid ${opacifyNormal(color)}; }
    ${Container}:hover & { border-left:2px solid ${opacifyHover(colorHover || color)}; }
`;
const makeCssBorderLeftFocus = (color: string, colorHover?: string) => css`
    & { border-left:2px solid ${opacifyFocus(color)}; }
    ${Container}:hover & { border-left:2px solid ${opacifyFocus(colorHover || color)}; }
`;

const cssBorderLeftNormal = makeCssBorderLeftIdle(colorIdle, colorFocus);
const cssBorderLeftFocus = makeCssBorderLeftFocus(colorFocus);
const cssBorderLeftSuccessFocus = makeCssBorderLeftFocus(colorSuccess);
const cssBorderLeftSuccess = makeCssBorderLeftNormal(colorSuccess);
const cssBorderLeftErrorFocus = makeCssBorderLeftFocus(colorError);
const cssBorderLeftError = makeCssBorderLeftNormal(colorError);
const cssBorderLeft = ({ hasError, hasSuccess, hasFocus }: IElementStateProps) =>
    (hasFocus && hasError && cssBorderLeftErrorFocus)
    || (hasFocus && hasSuccess && cssBorderLeftSuccessFocus)
    || (hasFocus && cssBorderLeftFocus)
    || (hasError && cssBorderLeftError)
    || (hasSuccess && cssBorderLeftSuccess)
    || cssBorderLeftNormal;

const ContainerDropDown = styled.div`
    ${cssFontNormal(14, 20, 30)}
    background:0 none;
    color:${palette.grey2};
    cursor: pointer;
    margin:0;
    outline:0;
    padding:${px(12)} ${px(32)} ${px(12)} ${px(16)};
    position: relative;
    text-overflow:ellipsis;
    transition:border-color 0.3s ease;
    user-select: none;
    white-space: nowrap;
    ${cssBorderLeft}
`;

const aniExpand = keyframes`
    0% {
        max-height: ${px(48)};
        opacity: 0;
        visibility: collapse;
    }
    99% {
        max-height: ${px(500)};
        opacity: 1;
        visibility: initial;
    }
    100% {
        max-height: auto;
    }
`;

const aniCollapse = keyframes`
    0% {
        max-height: ${px(500)};
        opacity: 1;
    }
    100% {
        max-height: ${px(44)};
        opacity: 0;
        visibility: collapse;
    }
`;

const DropBox = styled.ul`
    user-select: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: 0 4px 4px 4px;
    background: #fff;
    padding: 0;
    margin: -2px;
    list-style: none;
    box-shadow: 0 0 20px rgba(75, 85, 246, .08);
    overflow: hidden;

    ${({ hasOpened }: IElementStateProps) => (
        hasOpened
            ? css`animation: ${aniExpand} 0.3s ease forwards;`
            : css`animation: ${aniCollapse} 0.3s ease forwards;`
    )}
`;

const ListItemSpan = styled.span`
    line-height: 1;
    display: block;
`;

const PullDownIcon = styled(KeyboardArrowDownIcon as any /* children: never bug */)`
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: rgb(75, 85, 246);

    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
`;
const PushUpIcon = styled(KeyboardArrowUpIcon as any /* children: never bug */)`
    display: inline-block;
    width: 1em;
    height: 1em;
    fill: rgb(75, 85, 246);

    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
`;
const Unit = styled(BasicText)`
    cursor: pointer;
    display: block;
`;

export const InputUnit = ({
    error,
    success,
    disabled,
    focused,
    id,
    onBlur,
    units,
    onChangeUnit,
    onChangeValue,
    onFocusUnit,
    onFocusValue,
    onKeyPress,
    onToggle,
    unit,
    value,
    opened,
    openedOnce,
}: IInputUnitPresentationProps) => (
    <Container
        hasOpened={opened}
        hasError={error}
        hasSuccess={success}
        hasFocus={focused}
        hasDisabled={disabled}>
        <ContainerInput
            id={id}
            onFocus={onFocusValue}
            onBlur={onBlur}
            onChange={(e) => onChangeValue(e.target.value)}
            value={value} />
        <ContainerDropDown
            hasOpened={opened}
            hasError={error}
            hasSuccess={success}
            hasFocus={focused}
            hasDisabled={disabled}
            tabIndex={0}
            onFocus={onFocusUnit}
            onMouseDown={onFocusUnit}
            onTouchStart={onFocusUnit}
            onBlur={onBlur}
            onKeyDown={onKeyPress}>
            {units.reduce<React.ReactNode>((s, item) => (
                item.key !== unit ? s : (
                    <Unit
                        aria-label="Selectie lijst openen"
                        aria-expanded={opened}
                        aria-hidden={opened}
                        aria-haspopup="listbox">
                        <span>{item.label}</span> <PullDownIcon />
                    </Unit>
                )
            ), (
                <Unit
                    aria-label="Selectie lijst openen"
                    aria-expanded={opened}
                    aria-hidden={opened}
                    aria-haspopup="listbox">
                    <span>&mdash;</span> <PullDownIcon />
                </Unit>
            ))}

            {!openedOnce ? null : (
                <DropBox
                    hasOpened={opened}
                    hasError={error}
                    hasSuccess={success}
                    hasFocus={focused}
                    hasDisabled={disabled}
                    onClick={(e) => e.stopPropagation()}
                    role="listbox">
                    {units.reduce<React.ReactNode>((s, item) => (
                        item.key !== unit ? s : (
                            <ListItem
                                key={item.key}
                                height={50}
                                onClick={onToggle}
                                aria-label="Selectie lijst sluiten"
                                aria-expanded={opened}>
                                <ListItemSpan>{item.label}</ListItemSpan> <PushUpIcon />
                            </ListItem>
                        )
                    ), (
                        <ListItem
                            key={-1}
                            height={50}
                            onClick={onToggle}
                            aria-label="Selectie lijst sluiten"
                            aria-expanded={opened}>
                            <ListItemSpan>&mdash;</ListItemSpan> <PushUpIcon />
                        </ListItem>
                    ))}
                    {units.map((item) => (
                        <ListItem
                            key={item.key}
                            height={50}
                            active={unit === item.key}
                            onClick={() => onChangeUnit(item.key)}
                            aria-selected={unit === item.key}>
                            <ListItemSpan>{item.label}</ListItemSpan>
                        </ListItem>
                    ))}
                </DropBox>
            )}
        </ContainerDropDown>
    </Container>
);
