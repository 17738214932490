import { px } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

const Wrapper = styled.div`
    max-height: 40vh;
    overflow-y: auto;
    &::-webkit-scrollbar {
        width: ${px(10)};
    }
    &::-webkit-scrollbar-track {
        width: ${px(10)};
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        width: ${px(10)};
        border-radius: ${px(10)};
        background-color: #ececf3;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #e3e3ec;
    }
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-corner {
        width: ${px(10)};
        height: ${px(1)};
        background-color: transparent;
    }
`;

const styledComponents = { Wrapper };
export default styledComponents;
