import React, { useState } from 'react';
import _ from 'lodash';
import { BreadcrumbBar, ErrorOverlay, Heading2, PageTitle, ResetZIndex, Section, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { Finance as DataAccess } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper, useUserSettings } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ContextMenu, Button as DxButton, generateSafeId, GridColumn, LoadPanel, LsGrid } from '@liasincontrol/ui-devextreme';
import { AjaxRequestStatus } from '@liasincontrol/redux-service';
import { Filter } from './Filter';
import { useJournalElementKinds } from '../../shared/hooks';

type BreadCrumbDataType = { kind?: Domain.Finance.JournalElementKind, value?: Domain.Finance.BudgetOverviewValue };
/**
 * Represents a UI component that renders the overview page of the budgets.
 */

export const Overview: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const { baseYear, structureRK, structureNodeRK } = useUserSettings();
    const [data, setData] = useState<Domain.Finance.BudgetOverviewValue[]>([]);
    const [availableColumns, setAvailableColumns] = useState<GridColumn<Domain.Finance.BudgetOverviewValue>[]>([]);
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const journalElementKinds = useJournalElementKinds();
    const [breadCrumb, setBreadCrumb] = useState<BreadCrumbDataType[]>([]);
    const [combinationVisible, setCombinationVisible] = useState(false);

    const mapColumnExtraProperties = (availableKinds: Domain.Finance.JournalElementKind[] = [],
        onContextClick?: (selectedData: Domain.Finance.BudgetOverviewValue, selectedKind: Domain.Finance.JournalElementKind) => void) => {
        if (!availableKinds.length) return;
        return {
            'renderCustom': ({ data }) => (
                <ContextMenu<Domain.Finance.BudgetOverviewValue>
                    item={data}
                    keyExpr='journalElementRK'
                    actions={availableKinds.map(
                        (kind) => {
                            return {
                                action: (data) => { return onContextClick(data, kind); },
                                ariaLabel: `Drilldown journaal ${kind.rk}`,
                                actionName: `drilldown-budgetJournal-${kind.rk}`,
                                displayName: `${kind.name}`
                            };
                        })}
                />)
        }
    };

    //because kinds are mandatory to be fetched, wait until the redux is initiated correctly
    if (journalElementKinds.status !== AjaxRequestStatus.Done) return <></>;

    const fetchDrillDown = (
        selectedData: Domain.Finance.BudgetOverviewValue,
        selectedKind: Domain.Finance.JournalElementKind,
        selectedBreadCrumb: BreadCrumbDataType[],
        type: "elements" | "combinations") => {
        setLoading(true);
        setCombinationVisible(type === "combinations");
        const updatedBreadCrumb = [...selectedBreadCrumb, { kind: selectedKind, value: selectedData }];
        const contextMenuAvailableKinds = journalElementKinds.items.filter(obj1 => !updatedBreadCrumb.flatMap(o => o.kind).some(obj2 => obj1.rk === obj2.rk));
        const lastKnownTransactionKind = updatedBreadCrumb.flatMap(bc => bc.value)?.find(bcv => bcv?.transactionKind);
        const journalElementRoute = updatedBreadCrumb.map(bc => bc.value?.journalElementRK).filter(journalElementRK => journalElementRK !== undefined);

        const worker = type === "elements"
            ? DataAccess.BudgetOverview.getJournalElementKindDrilldown(selectedKind.rk, lastKnownTransactionKind.transactionKind, journalElementRoute)
            : DataAccess.BudgetOverview.getJournalCombinationDrilldown(lastKnownTransactionKind.transactionKind, journalElementRoute);

        worker.then((response) => {
            const columns = response.data.layout
                ?.map((c) => {
                    return {
                        title: c.caption,
                        columns: c.columns.map(c => {
                            const aux = mapColumnProperties(c);
                            return {
                                name: changeCaseFirstLetter(c.dataField),
                                title: c.caption,
                                hideInColumnChooser: !c.caption,
                                ...aux
                            };
                        }),
                    }
                });

            const updatedColumns = _.cloneDeep(columns);
            if (type === "elements") {
                _.set(
                    updatedColumns,
                    '[0].columns[0]',
                    {
                        ...updatedColumns[0].columns[0],
                        ...mapColumnExtraProperties(contextMenuAvailableKinds, (data, kind) => fetchDrillDown(data, kind, updatedBreadCrumb, "elements"))
                    }
                );
            }
            setAvailableColumns(updatedColumns);
            setData(response.data.values);
            setBreadCrumb(updatedBreadCrumb);

        }).catch((error) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))
        }).finally(() => {
            setLoading(false);
        });
    };

    //Main
    const filterChanged = () => {
        if (!baseYear || !structureRK || !structureNodeRK) {
            return;
        }
        setLoading(true);
        setBreadCrumb([]);
        setCombinationVisible(false);
        DataAccess.BudgetOverview.getV2(baseYear, structureRK, structureNodeRK)
            .then((response) => {
                const columns = response.data.layout
                    ?.map((c) => {
                        return {
                            title: c.caption,
                            columns: c.columns.map(c => {
                                const aux = mapColumnProperties(c);
                                return {
                                    name: changeCaseFirstLetter(c.dataField),
                                    title: c.caption,
                                    hideInColumnChooser: !c.caption,
                                    ...aux
                                };
                            }),
                        }
                    });
                const currentKind = journalElementKinds.items.find(kind => kind.name === columns[0]?.title);
                const updatedBreadCrumb: BreadCrumbDataType[] = [{ kind: currentKind }];
                const contextMenuAvailableKinds = journalElementKinds.items.filter(i => i.rk !== currentKind.rk);
                const updatedColumns = _.cloneDeep(columns);

                _.set(
                    updatedColumns,
                    '[0].columns[0]',
                    {
                        ...updatedColumns[0].columns[0],
                        ...mapColumnExtraProperties(contextMenuAvailableKinds, (data, kind) => fetchDrillDown(data, kind, updatedBreadCrumb, "elements"))
                    }
                );
                setAvailableColumns(updatedColumns);
                setData(response.data.values);
                setBreadCrumb(updatedBreadCrumb);
            }).catch((error) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))
            }).finally(() => {
                setLoading(false);
            });
    };

    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Budgetoverzicht</Heading2>
                </PageTitle>
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <Section look='white'>
                        <Filter onFilter={filterChanged} disabled={loading} />
                    </Section>
                    <Section look="white" fixedWidth={true}>
                        <ResetZIndex>
                            <BreadcrumbBar>
                                {breadCrumb.length > 1 && breadCrumb.map((bv, index) => {
                                    const hinttext = index > 0 ?
                                        `${bv.value.journalElementCode} - ${bv.value.journalElementName} \n ${breadCrumb[index - 1]?.kind.rk} - ${breadCrumb[index - 1]?.kind.name}`
                                        : `Overzicht`;
                                    return <DxButton
                                        id={`breadcrumb-btn-${index > 0 ? generateSafeId(bv.value.journalElementCode) : 'overzicht'}`}
                                        className='lias-button'
                                        type="default"
                                        stylingMode="text"
                                        icon={index === 0 ? "home" : undefined}
                                        text={index > 0 ? `${bv.value.journalElementCode}` : undefined}
                                        disabled={index === breadCrumb.length - 1}
                                        hint={hinttext}
                                        onClick={() => {
                                            if (index === 0) {
                                                filterChanged();
                                            } else {
                                                fetchDrillDown(bv.value, bv.kind, breadCrumb.slice(0, index), "elements");
                                            }
                                        }}
                                    />
                                })}

                            </BreadcrumbBar>
                            {loading ?
                                <LoadPanel visible={loading} /> :
                                data?.length ? <LsGrid
                                    searching={true}
                                    showRowLines={true}
                                    showColumnLines={true}
                                    showBorders={true}
                                    allowColumnResizing={true}
                                    columnAutoWidth={false}
                                    enableColumnChooser={true}
                                    columns={availableColumns}
                                    dataSource={data}
                                    onDataError={(exception) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception))}
                                    onClickRow={!combinationVisible ? (item, value, column) => {
                                        //Internally is a cell click on data rows and non interactive column
                                        const lastBreadcrumbItem = breadCrumb[breadCrumb.length - 1];
                                        fetchDrillDown(item, lastBreadcrumbItem?.kind, breadCrumb, "combinations");
                                    } : undefined}
                                    showCellHintText={true}
                                    getHintText={(columnField: string, value: string) => {
                                        if (!columnField) return;
                                        if (!combinationVisible && stringColumnRegex.test(String(columnField))) {
                                            return `${value} - Boekingscombinatie`;
                                        }
                                        return value;
                                    }}
                                /> : <>Voer een zoekopdracht uit om het budgetoverzicht te raadplegen.</>}
                        </ResetZIndex>
                    </Section>
                </ErrorOverlay>
            </WrapperContent>
        </Wrapper >
    );
};

const stringColumnRegex = /^(journalelement|transactionkind)/i;

const mapColumnProperties = (column) => {
    const colProps = {
        align: `left` as `left` | `right` | `center`,
        dataType: "string" as "string" | "number" | "boolean" | "object" | "date" | "datetime",
    };

    if (!stringColumnRegex.test(String(column.dataField))) {
        colProps['align'] = `right` as `left` | `right` | `center`;
        colProps['dataType'] = "number" as "string" | "number" | "boolean" | "object" | "date" | "datetime";
        colProps['formatter'] = "decimal" as "date" | "datetime" | "integer" | "decimal";
    }

    if (!/(name)$/i.test(column.dataField)) {
        colProps['width'] = "auto";
    } else {
        colProps['minWidth'] = 150;
    }

    if (!column.caption) {
        colProps['align'] = 'center' as `left` | `right` | `center`;
    }

    return colProps;
};

const changeCaseFirstLetter = (sourceText: string, toLower = true) => {
    if (!sourceText) return;
    if (toLower) return sourceText.charAt(0).toLowerCase() + sourceText.slice(1);
    return sourceText.charAt(0).toUpperCase() + sourceText.slice(1);
};

export { Overview as index };
