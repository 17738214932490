import { ChartLabels } from "../shared";
import { ILsPieChartProps } from "./";

export const defaultDoughnutChartSettings: ILsPieChartProps = {
    data: [],
    type: 'doughnut',
    innerRadius: 0.7,
    redrawOnResize: true,
    pieChartData: {
        valueField: '',
        argumentField: '',
        label: {
            visible: false,
            connector: {
                visible: false,
            },
        },
        // showInLegend: false,
    },
    labels: ChartLabels.None,
    legendData: {
        visible: false,
    },
    tooltipData: {
        enabled: false,
    },
    animationData: {
        enabled: false,
    },
    adaptiveLayout: {
        height: 80,
        width: 80
    },
};