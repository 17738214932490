import ArrowBack from '@mui/icons-material/ArrowBack';
import React from 'react';
import styled, { css } from 'styled-components';
import { cssFontExtraBold, px } from '../style';

// tslint:disable:no-magic-numbers

export interface IToolbarNavProps {
    readonly className?: string;
    readonly children?: React.ReactNode;
    readonly icon?: React.ReactNode;
}

export interface IToolbarNavRenderProps {
    readonly className?: string;
    readonly role?: string;
    readonly onMouseUpCapture?: React.MouseEventHandler;
}

export const ToolbarNavCustom = <P extends IToolbarNavRenderProps>(component: React.ComponentType<P>) => {
    const Component = styled(component)`${ContainerStyle}`;

    return ({ className, children, icon = <ToolbarNavBackIcon />, ...rest }: IToolbarNavProps & P) => {
        return (
            <Component className={className} role="heading" aria-level="1" {...rest as any}><Grid>{icon}<span>{children}</span></Grid></Component>
        );
    };
};

const ContainerStyle = css`
    ${cssFontExtraBold(14, 19, 100)}
    background: transparent;
    border: 0;
    padding: 0;
    display: block;
    color: #4B55F6;
    cursor: pointer;
    margin: 0 0 ${px(24)} 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: color 0.2s ease;
    &:hover {
        color: #0a16d1;
    }
    &:disabled, &[disabled] {
        color: rgba(75, 85, 246, 0.5);
        cursor: default;
    }
    &:active {
        outline: 0;
    }
    &:focus {
        outline: 0;
    }
`;

const Grid = styled.div`
    align-items: center;
    display: grid;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    grid-gap: ${px(12)};
    justify-items: start;
`;

export const ToolbarNavBackIcon = styled(ArrowBack as any /* children: never bug */)`
    color: inherit;
    fill: currentColor;
    margin-left: ${px(-3)};
    stroke-width: ${px(0.5)};
    stroke: currentColor;
    width: ${px(16 * 1.5)};
    height: ${px(16 * 1.5)};
`;

export const ToolbarNav = ToolbarNavCustom(styled.button``);
