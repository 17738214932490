import React from 'react';
import * as Domain from '@liasincontrol/domain';
import { AttachmentElement, ColorPickerElement, SelectElement } from '@liasincontrol/ui-elements';
import { Label, HelpText, LayoutField, LayoutForm, palette, IDataItemProps } from '@liasincontrol/ui-basics';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import _, { Dictionary } from 'lodash';
import { AnyFormData, ValidatorsDictionary, ValidationUtils } from '@liasincontrol/core-service';
import { DEFAULT_PALETTE, getLiasPalette } from '@liasincontrol/ui-devextreme';
import Styled from './index.styled';
import { LeftSide, RightSide } from '..';

type Props = {
    fieldDefinitions: Dictionary<Domain.Shared.FieldDefinition>,
    isEditing: boolean;
    form: AnyFormData,
    validators: ValidatorsDictionary,
    onLoadAttachment: (id: string) => Promise<Blob>,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    onChange: (value: string, fieldDefinitionId: string, resetExternalErrorFieldSystemIds?: string[]) => void,
    onError?: (error: string, fieldSystemId: string, fieldValue?: string) => void,
};

/**
 * Represents a UI component that renders the Identity styles section form of the Publication information page.
 */
export const IdentitySection: React.FC<Props> = (props) => {
    if (_.isEmpty(props.form) || _.isEmpty(props.form.values)) {
        return null;
    }

    const onAttachmentChanged = (value: string, fieldDefinitionId: string) => {
        props.onChange(value, fieldDefinitionId, [fieldDefinitionId]);
    };

    const logoDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.Logo];
    const faviconDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.Favicon];
    const primaryColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.PrimaryColor];
    const primaryContrastColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.PrimaryContrastColor];
    const secondaryColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.SecondaryColor];
    const secondaryContrastColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.SecondaryContrastColor];
    const textSizeDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.TextSize];
    const textColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.TextColor];
    const h1TextSizeDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.H1TextSize];
    const h1TextColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.H1TextColor];
    const h2TextSizeDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.H2TextSize];
    const h2TextColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.H2TextColor];
    const h3TextSizeDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.H3TextSize];
    const h3TextColorDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.H3TextColor];
    const colorPaletteDefinition = props.fieldDefinitions[SystemFieldDefinitions.Pub.ColorPalette];

    const defaultColorPalette = colorPaletteDefinition?.optionItems?.find(cp => cp.name === DEFAULT_PALETTE);

    const renderColorPalettePreview = (paletteId: string) => {
        const paletteName = colorPaletteDefinition?.optionItems?.find(cp => cp.id === paletteId)?.name || defaultColorPalette?.name;
        const colorSet = getLiasPalette(paletteName, props.form.values[SystemFieldDefinitions.Pub.PrimaryColor] as string).simpleSet;
        const colorTiles = colorSet.map((color) => (
            <Styled.PaletteItemWrapper key={`${paletteId}-${color}`} color={color} />
        ));

        return <div style={{ width: '100%', maxWidth: '28rem' }}>{colorTiles}</div>
    };

    const commonProps = {
        isEditing: props.isEditing,
        validators: props.validators,
        form: props.form,
        onChange: props.onChange,
    };

    return (
        <LayoutForm noMinHeight>
            <LeftSide key={logoDefinition.id} top={1}>
                <AttachmentElement
                    id='pub-logo'
                    label={logoDefinition.label ? logoDefinition.label : logoDefinition.name}
                    editorSettings={ValidationUtils.getEditorSettings(true, !props.isEditing, props.validators, props.form, onAttachmentChanged, SystemFieldDefinitions.Pub.Logo)}
                    maxPreviewHeight={256}
                    value={props.form.values[SystemFieldDefinitions.Pub.Logo] as string}
                    onLoadAttachment={props.onLoadAttachment}
                    onUploadAttachment={props.onUploadAttachment}
                    onAddFileError={(error) => {
                        props.onError(error, SystemFieldDefinitions.Pub.Logo);
                    }}
                />
            </LeftSide>
            <RightSide key={faviconDefinition.id} top={1}>
                <AttachmentElement
                    id='pub-favicon'
                    label={faviconDefinition.label ? faviconDefinition.label : faviconDefinition.name}
                    maxImageSize={{ height: 16, width: 16 }}
                    editorSettings={ValidationUtils.getEditorSettings(true, !props.isEditing, props.validators, props.form, onAttachmentChanged, SystemFieldDefinitions.Pub.Favicon)}
                    onLoadAttachment={props.onLoadAttachment}
                    onUploadAttachment={props.onUploadAttachment}
                    value={props.form.values[SystemFieldDefinitions.Pub.Favicon] as string}
                    onAddFileError={(error) => {
                        props.onError(error, SystemFieldDefinitions.Pub.Favicon);
                    }}
                />
            </RightSide>
            <PrimaryColor {...commonProps} top={2} primaryColorDefinition={primaryColorDefinition} />
            <SecondaryColor {...commonProps} top={3} secondaryColorDefinition={secondaryColorDefinition} />
            <StandardTextColor {...commonProps} top={4} textColorDefinition={textColorDefinition} textSizeDefinition={textSizeDefinition} />
            <H2TextSize {...commonProps} top={5} h2TextColorDefinition={h2TextColorDefinition} h2TextSizeDefinition={h2TextSizeDefinition} />
            <PrimaryContrastColor {...commonProps} top={2} primaryContrastColorDefinition={primaryContrastColorDefinition} />
            <SecondaryContrastColor {...commonProps} top={3} secondaryContrastColorDefinition={secondaryContrastColorDefinition} />
            <H1TextColor {...commonProps} top={4} h1TextColorDefinition={h1TextColorDefinition} h1TextSizeDefinition={h1TextSizeDefinition} />
            <H3TextColor {...commonProps} top={5} h3TextColorDefinition={h3TextColorDefinition} h3TextSizeDefinition={h3TextSizeDefinition} />
            {/* charts colors */}
            <LayoutField key={colorPaletteDefinition.id} left={1} top={6} width={6} height={1} >
                <LayoutForm>
                    <LayoutField left={1} top={1} width={2} height={1}>
                        <Label text={colorPaletteDefinition.label} />
                    </LayoutField>
                    <LayoutField key={`${colorPaletteDefinition.id}-input`} left={1} top={2} width={2} height={1}>
                        <SelectElement<Domain.Shared.FieldDefinitionOptionItem>
                            displayExpr='name'
                            id='pub-colorPalettes'
                            editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form,
                                (e: Domain.Shared.FieldDefinitionOptionItem) => props.onChange(e?.id, SystemFieldDefinitions.Pub.ColorPalette),
                                SystemFieldDefinitions.Pub.ColorPalette)}
                            searchable={false}
                            clearable={false}
                            optionItems={colorPaletteDefinition.optionItems}
                            value={props.form.values[SystemFieldDefinitions.Pub.ColorPalette]
                                ? colorPaletteDefinition.optionItems.find(cp => cp.id === props.form.values[SystemFieldDefinitions.Pub.ColorPalette])
                                : defaultColorPalette}
                        />
                    </LayoutField>
                    <LayoutField key={`${colorPaletteDefinition.id}-preview`} left={3} top={2} width={4} height={1} >
                        {renderColorPalettePreview(props.form.values[SystemFieldDefinitions.Pub.ColorPalette] as string)}
                    </LayoutField>
                </LayoutForm>



            </LayoutField>
            {/* cover image*/}

        </LayoutForm>
    );
};

const PrimaryColor = (props) => (
    <LeftSide key={props.primaryColorDefinition.id} top={props.top} borderBottom compactBorderBottom>
        <LayoutField left={1} top={1} width={2} height={1}>
            <Label text='Primaire achtergrondkleur' />
        </LayoutField>
        <LayoutField left={1} top={2} width={1} height={1}>
            <ColorPickerElement
                id='pub-primaryColor'
                editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, SystemFieldDefinitions.Pub.PrimaryColor)}
                ariaLabel='Primaire achtergrondkleur'
                size='small'
                defaultColor={palette.grey4}
                value={props.form.values[SystemFieldDefinitions.Pub.PrimaryColor] as string}
            />
        </LayoutField>
    </LeftSide>
);

const SecondaryColor = (props) => (
    <LeftSide key={props.secondaryColorDefinition.id} top={props.top} borderBottom compactBorderBottom>
        <LayoutField left={1} top={1} width={2} height={1}>
            <Label text='Secundaire achtergrondkleur' />
        </LayoutField>
        <LayoutField left={4} top={1} width={1} height={1} >
            <ColorPickerElement
                id='pub-secondaryColor'
                editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, SystemFieldDefinitions.Pub.SecondaryColor)}
                ariaLabel='Secundaire achtergrondkleur'
                size='small'
                defaultColor={palette.grey4}
                value={props.form.values[SystemFieldDefinitions.Pub.SecondaryColor] as string}
            />
        </LayoutField>
    </LeftSide>
);

const H2TextSize = (props) => (
    <LeftSide top={props.top}>
        <LayoutForm noMinHeight>
            <LayoutField left={1} top={1} width={3} height={1}>
                <Label text='Subtitel groot' />
            </LayoutField>
            <LayoutField key={`${props.h2TextColorDefinition.id}-color`} left={1} top={2} width={1} height={1}>
                <ColorPickerElement
                    id='pub-h2TextColor'
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, SystemFieldDefinitions.Pub.H2TextColor)}
                    ariaLabel='Subtitel groot kleur'
                    size='small'
                    defaultColor={palette.grey2}
                    value={props.form.values[SystemFieldDefinitions.Pub.H2TextColor] as string}
                />
            </LayoutField>
            <LayoutField key={`${props.h2TextSizeDefinition.id}-input`} left={2} top={2} width={3} height={1}>
                <SelectElement<IDataItemProps<string>>
                    displayExpr='label'
                    id='pub-h2TextSize'
                    editorSettings={ValidationUtils.getEditorSettings(
                        props.isEditing, false,
                        props.validators, props.form,
                        (e) => props.onChange(e?.value,
                            SystemFieldDefinitions.Pub.H2TextSize), SystemFieldDefinitions.Pub.H2TextSize)}
                    searchable={false}
                    clearable={false}
                    optionItems={fontsizes}
                    value={props.form.values[SystemFieldDefinitions.Pub.H2TextSize]
                        ? fontsizes.find(fs => fs.value === props.form.values[SystemFieldDefinitions.Pub.H2TextSize])
                        : fontsizes.find(fs => fs.value === props.h2TextSizeDefinition.integerStartValue.toString())}
                />
            </LayoutField>
        </LayoutForm>
    </LeftSide>
);

const StandardTextColor = (props) => (
    <LeftSide top={props.top}>
        <LayoutForm noMinHeight>
            <LayoutField left={1} top={1} width={6} height={1}>
                <Label text='Standaard tekst' />
            </LayoutField>
            <LayoutField key={`${props.textColorDefinition.id}-color`} left={1} top={2} width={1} height={1}>
                <ColorPickerElement
                    id='pub-textColor'
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, SystemFieldDefinitions.Pub.TextColor)}
                    ariaLabel='Standaard tekstkleur'
                    size='small'
                    defaultColor={palette.grey2}
                    value={props.form.values[SystemFieldDefinitions.Pub.TextColor] as string}
                />
            </LayoutField>
            <LayoutField key={`${props.textSizeDefinition.id}-input`} left={2} top={2} width={3} height={1}>
                <SelectElement<IDataItemProps<string>>
                    displayExpr='label'
                    id='pub-textSize'
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, (e: IDataItemProps<string>) => props.onChange(e?.value, SystemFieldDefinitions.Pub.TextSize), SystemFieldDefinitions.Pub.TextSize)}
                    searchable={false}
                    clearable={false}
                    optionItems={fontsizes}
                    value={props.form.values[SystemFieldDefinitions.Pub.TextSize]
                        ? fontsizes.find(fs => fs.value === props.form.values[SystemFieldDefinitions.Pub.TextSize])
                        : fontsizes.find(fs => fs.value === props.textSizeDefinition.integerStartValue.toString())
                    }
                />
            </LayoutField>
        </LayoutForm>
    </LeftSide>
);

const PrimaryContrastColor = (props) => (
    <RightSide key={props.primaryContrastColorDefinition.id} top={props.top} borderBottom compactBorderBottom>
        <LayoutField left={1} top={1} width={2} height={1} verticalAlign='bottom'>
            <Label text='Primaire contrastkleur' />
            <HelpText title={props.primaryContrastColorDefinition.helpTextTitle}>{props.primaryContrastColorDefinition.helpText}</HelpText>
        </LayoutField>
        <LayoutField left={4} top={1} width={1} height={1}>
            <ColorPickerElement
                id='pub-primaryContrastColor'
                editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, SystemFieldDefinitions.Pub.PrimaryContrastColor)}
                ariaLabel='Kleur'
                size='small'
                defaultColor={palette.grey1}
                colors={[palette.grey1, palette.white]}
                value={props.form.values[SystemFieldDefinitions.Pub.PrimaryContrastColor] as string}
            />
        </LayoutField>
    </RightSide>
);

const SecondaryContrastColor = (props) => (
    <RightSide key={props.secondaryContrastColorDefinition.id} top={props.top} borderBottom compactBorderBottom>
        <LayoutField left={1} top={1} width={2} height={1} verticalAlign='bottom'>
            <Label text='Secundaire contrastkleur' />
            <HelpText title={props.secondaryContrastColorDefinition.helpTextTitle}>{props.secondaryContrastColorDefinition.helpText}</HelpText>
        </LayoutField>
        <LayoutField left={4} top={1} width={1} height={1}>
            <ColorPickerElement
                id='pub-secondaryContrastColor'
                editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, SystemFieldDefinitions.Pub.SecondaryContrastColor)}
                ariaLabel='Secundaire achtergrondkleur'
                size='small'
                defaultColor={palette.grey1}
                colors={[palette.grey1, palette.white]}
                value={props.form.values[SystemFieldDefinitions.Pub.SecondaryContrastColor] as string}
            />
        </LayoutField>
    </RightSide>
);

const H1TextColor = (props) => (
    <RightSide top={props.top}>
        <LayoutForm noMinHeight>
            <LayoutField left={1} top={1} width={6} height={1}>
                <Label text='Titel' />
            </LayoutField>
            <LayoutField key={`${props.h1TextColorDefinition.id}-color`} left={1} top={2} width={1} height={1}>
                <ColorPickerElement
                    id='pub-h1TextColor'
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, SystemFieldDefinitions.Pub.H1TextColor)}
                    ariaLabel='Titel kleur'
                    size='small'
                    defaultColor={palette.grey2}
                    value={props.form.values[SystemFieldDefinitions.Pub.H1TextColor] as string}
                />
            </LayoutField>
            <LayoutField key={`${props.h1TextSizeDefinition.id}-input`} left={2} top={2} width={3} height={1}>
                <SelectElement<IDataItemProps<string>>
                    displayExpr='label'
                    id='pub-h1TextSize'
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, (e) => props.onChange(e?.value, SystemFieldDefinitions.Pub.H1TextSize), SystemFieldDefinitions.Pub.H1TextSize)}
                    searchable={false}
                    clearable={false}
                    optionItems={fontsizes}
                    value={props.form.values[SystemFieldDefinitions.Pub.H1TextSize]
                        ? fontsizes.find(fs => fs.value === props.form.values[SystemFieldDefinitions.Pub.H1TextSize])
                        : fontsizes.find(fs => fs.value === props.h1TextSizeDefinition.integerStartValue.toString())}
                />
            </LayoutField>
        </LayoutForm>
    </RightSide>
);

const H3TextColor = (props) => (
    <RightSide top={props.top}>
        <LayoutForm noMinHeight>
            <LayoutField left={1} top={1} width={6} height={1}>
                <Label text='Subtitel klein' />
            </LayoutField>
            <LayoutField key={`${props.h3TextColorDefinition.id}-color`} left={1} top={2} width={1} height={1}>
                <ColorPickerElement
                    id='pub-h3TextColor'
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form, props.onChange, SystemFieldDefinitions.Pub.H3TextColor)}
                    ariaLabel='Subtitel klein kleur'
                    size='small'
                    defaultColor={palette.grey2}
                    value={props.form.values[SystemFieldDefinitions.Pub.H3TextColor] as string}
                />
            </LayoutField>
            <LayoutField key={`${props.h3TextSizeDefinition.id}-input`} left={2} top={2} width={3} height={1}>
                <SelectElement<IDataItemProps<string>>
                    displayExpr='label'
                    id='pub-h3TextSize'
                    editorSettings={ValidationUtils.getEditorSettings(props.isEditing, false, props.validators, props.form,
                        (e) => props.onChange(e?.value, SystemFieldDefinitions.Pub.H3TextSize), SystemFieldDefinitions.Pub.H3TextSize)}
                    searchable={false}
                    clearable={false}
                    optionItems={fontsizes}
                    value={props.form.values[SystemFieldDefinitions.Pub.H3TextSize]
                        ? fontsizes.find(fs => fs.value === props.form.values[SystemFieldDefinitions.Pub.H3TextSize])
                        : fontsizes.find(fs => fs.value === props.h3TextSizeDefinition.integerStartValue.toString())}

                />
            </LayoutField>
        </LayoutForm>
    </RightSide >
);

const fontsizes = [
    { value: '14', label: '14 px' },
    { value: '16', label: '16 px' },
    { value: '18', label: '18 px' },
    { value: '20', label: '20 px' },
    { value: '22', label: '22 px' },
    { value: '24', label: '24 px' },
    { value: '26', label: '26 px' },
    { value: '28', label: '28 px' },
    { value: '30', label: '30 px' },
    { value: '32', label: '32 px' },
    { value: '34', label: '34 px' },
    { value: '36', label: '36 px' },
];
