import * as Domain from '@liasincontrol/domain';
import { SystemHierarchyDefinitions } from '@liasincontrol/domain';
import { ActionSource } from '@liasincontrol/redux-service';

/**
 * Represents a class that offer hierarchy manipulation methods.
 */
class HierarchyUtils {

    /**
     * Map the hierarchy's items with the correct element definition id, based on element definition system id.
     * 
     * @param hierarchyDefinition Defines the hierarchyDefinition.
     * @param elementDefinitions Defines a dictionary with the element definitions.
     * @returns A hierarchy definition with correct element Definition ids in the hierarchyDefinitionLinkd.
     */
    public static mapHierarchyDefinitionItems = (hierarchyDefinition: Domain.Shared.HierarchyDefinition, elementDefinitions: { [id: string]: Domain.Shared.ElementDefinition }): Domain.Shared.HierarchyDefinition => {
        const getElementDefinition = (systemId: string) => {
            if (!elementDefinitions) {
                return null;
            }
            return Object.values(elementDefinitions).find((definition) => definition.systemId === systemId);
        };

        return {
            ...hierarchyDefinition,
            items: hierarchyDefinition.items?.map((hierarchyDefinitionlink) => ({
                ...hierarchyDefinitionlink,
                toElementDefinitionId: hierarchyDefinitionlink.toElementDefinitionId ? hierarchyDefinitionlink.toElementDefinitionId : getElementDefinition(hierarchyDefinitionlink.toElementDefinitionSystemId)?.id,
                fromElementDefinitionId: hierarchyDefinitionlink.fromElementDefinitionId ? hierarchyDefinitionlink.fromElementDefinitionId : getElementDefinition(hierarchyDefinitionlink.fromElementDefinitionSystemId)?.id,
            })),
        };
    };

    /**
     * Get the Action source related to the hierarchy definition system id.
     * 
     * @param systemId Defines the hierarchyDefinition system id.
     * @returns The correct actionSource.
     */
    public static getSourceByHierarchySystemId = (systemId: string) => {
        switch (systemId) {
            case SystemHierarchyDefinitions.Performance: return ActionSource.Performance;
            case SystemHierarchyDefinitions.Dynamic: return ActionSource.Studio;
            default: return;
        }
    };

}

export { HierarchyUtils };