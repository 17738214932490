import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';
import { oDataResponse } from '../../Shared';

/**
 * Represents a data accessor of imported structures.
 */
export class StructuresDataAccessor {
    /**
     * Gets the collection of imported structures.
     */
    public static getAll = async (query = ''): Promise<AxiosResponse<oDataResponse<Domain.Finance.Structure[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<oDataResponse<Domain.Finance.Structure[]>>(`/api/finance/structure${query}`, config);
    };

    /**
     * Gets structure by rk
     */
    public static getById = async (rk: string): Promise<AxiosResponse<Domain.Finance.Structure>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<Domain.Finance.Structure>(`/api/finance/structure/${rk}`, config);
    };

    /**
     * Gets nodes of a structure by rk
     */
    public static getNodes = async (rk: string): Promise<AxiosResponse<Domain.Finance.StructureNode[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<Domain.Finance.StructureNode[]>(`/api/finance/structure/${rk}/nodes`, config);
    };

    /**
     * Get structure node by structure id and node id
     */
    public static getNodeById = async (structureId: string, nodeId: string): Promise<AxiosResponse<Domain.Finance.StructureNode>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<Domain.Finance.StructureNode>(`/api/finance/structure/${structureId}/nodes/${nodeId}`, config);
    };

    /**
     * Get journal elements of a structure node by structure id and node id
     */
    public static getJournalElements = async (query: string, structureId: string, nodeId: string): Promise<AxiosResponse<oDataResponse<Domain.Finance.StructureJournalElement[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<oDataResponse<Domain.Finance.StructureJournalElement[]>>(`/api/finance/structure/${structureId}/nodes/${nodeId}/journalelements${query}`, config);
    };

    /**
     * Get unlinked journal elements of a structure by structure id
     */
    public static getJournalElementsUnlinked = async (query: string, structureId: string): Promise<AxiosResponse<oDataResponse<Domain.Finance.StructureJournalElement[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<oDataResponse<Domain.Finance.StructureJournalElement[]>>(`/api/finance/structure/${structureId}/journalelements/unlinked${query}`, config);
    };

}
