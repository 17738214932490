import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents a data accessor of journal element kinds.
 */
export class JournalElementKindDataAccessor {
    /**
     * Gets the collection of the existing journal element kinds.
     */
    public static getAll = async (): Promise<AxiosResponse<Domain.Finance.JournalElementKind[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<Domain.Finance.JournalElementKind[]>(`/api/finance/journalelementkind`, config);
    };
}
