import styled from 'styled-components';
import * as Domain from '@liasincontrol/domain';

const StackContainerWrapper = styled.div<{ direction: number }>`
    display: grid;
    grid-auto-flow: ${(p) => (p.direction === 1 ? 'column' : 'row')};
    align-items: center;
    ${(p) => (p.direction === 1 ? `
        grid-template-columns: 1fr auto;
    ` : `
        grid-template-rows: 1fr auto;
    `)};
`;

const StackContainerContent = styled.div<{ direction: number }>`
    min-width: 1px;
    display: grid;
    grid-auto-flow: ${(p) => (p.direction === Domain.Publisher.Direction.Horizontal ? 'column' : 'row')};
    grid-auto-columns: ${(p) => (p.direction === Domain.Publisher.Direction.Horizontal ? '1fr' : 'auto')};
    padding: ${(p) => (p.direction === Domain.Publisher.Direction.Horizontal ? '0 0 16px 0' : '0')};
    grid-template-columns: ${(p) => (p.direction === Domain.Publisher.Direction.Horizontal ? `repeat(auto-fit, minmax(100px, 1fr))` : `none`)}; 
`;

const styledComponents = { StackContainerWrapper, StackContainerContent };
export default styledComponents;