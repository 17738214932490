import React, { useState, useEffect } from 'react';
import Styled from './index.styled';
import * as Domain from '@liasincontrol/domain';

type Props = {
    publicationElement: Domain.Publisher.PublicationElement,
    onLoadAttachment: (id: string) => Promise<Blob>,
};

/**
 * Represents a UI component that renders the logo into the publication.
 */
const LogoControl: React.FC<Props> = (props) => {
    const [imageUrl, setImageUrl] = useState<string>();

    useEffect(() => {
        if (props.publicationElement?.logoAttachmentId) {
            props.onLoadAttachment(props.publicationElement.logoAttachmentId).then((response) => {
                setImageUrl(URL.createObjectURL(response));
            });
        }
    }, [props.publicationElement?.logoAttachmentId]);

    return (
        <div className='site-logo'>
            <Styled.LogoImage className='logo-icon' title='naar LIAS startpagina' aria-label='naar LIAS startpagina' src={imageUrl || defaultLogo} />
        </div>
    );
};

const defaultLogo =
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MCIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDQwIDMyIj4KICA8ZyBpZD0ibG9nbyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ1OC4yIC0zMjcuOSkiPgogICAgPGcgaWQ9Imljb25fLV9sb2dvIiBkYXRhLW5hbWU9Imljb24gLSBsb2dvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0NTguMiAzMjcuOSkiPgogICAgICA8cGF0aCBpZD0iUGF0aF84MjQiIGRhdGEtbmFtZT0iUGF0aCA4MjQiIGQ9Ik00NzguNzg3LDMyMy45Yy43OTQsMS4zNDcsOC4yNTQsMTUuMDM1LDguODI1LDE0Ljg4Mi0xNi42NjcsNC44NjktMjAuMDY0LDcuNjU1LTMwLjMxNywxNy4xMTgtLjAzMiwwLS4wNjQtLjAzMS0uMS0uMDMxQTEzNy42LDEzNy42LDAsMCwxLDQ3OC43ODcsMzIzLjkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC00NTcuMiAtMzIzLjkpIiBmaWxsPSIjNDE0MTQxIi8+CiAgICAgIDxwYXRoIGlkPSJQYXRoXzgyNSIgZGF0YS1uYW1lPSJQYXRoIDgyNSIgZD0iTTUyNi40ODksMzc4LjJjMy4wMTYsNS4wNTMsNiwxMC4xMDUsOS4wMTYsMTUuMTU4SDUxNC43MTFDNTA5Ljk0OSwzODcuODc2LDUxOC4zLDM4Miw1MjYuNDg5LDM3OC4yIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNDk1LjUwNSAtMzYxLjU3MikiIGZpbGw9IiM4YTg5OTEiLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPgo=';

export default LogoControl;
