import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import * as Domain from '@liasincontrol/domain';
import { Button, Label, LayoutField, LayoutForm, Section } from '@liasincontrol/ui-basics';
import { AnyFormData, ValueType, FormInfo, FormHelper } from '@liasincontrol/core-service';
import { TextViewer, ConnectingDialog } from '@liasincontrol/ui-elements';
import { AppSettingsService } from '@liasincontrol/config-service';
import { LsGrid } from '@liasincontrol/ui-devextreme';

type Props = {
    userGroupRoles: Domain.Shared.UserGroupRoles,
    roles: Domain.Shared.UserRole[],
    onFormDataChanged: (formInfo: FormInfo<ValueType>) => void,
};

/**
 * Represents a UI component that renders the group with roles form.
 */
export const GroupRolesForm: React.FC<Props> = (props) => {
    const [form, setForm] = useState<AnyFormData>(initFormData(props.userGroupRoles));
    const [addRole, setAddRole] = useState<boolean>(false);
    const [paging, setPaging] = useState<{ currentPage: number; pageSize: number }>({ currentPage: 0, pageSize: AppSettingsService.getAppSettings().General.PageSize });

    useEffect(() => {
        if (form.values['roles'].length < paging.pageSize * paging.currentPage) {
            setPaging({ currentPage: 0, pageSize: paging.pageSize });
        }

    }, [form.values['roles'].length, paging.currentPage, paging.pageSize]);

    const storeFormValue = (value: ValueType, systemId: string) => {
        const newForm = FormHelper.handleFormValueChanged(value, systemId, form) as AnyFormData;
        setForm(newForm);
        props.onFormDataChanged({ values: newForm.values, isValid: newForm.isValid, isTouched: Object.keys(newForm.touched).length > 0 });
    };

    const getAssignmentDialogElement = (userGroupRoles: Domain.Shared.UserGroupRoles) => {
        const listItems = props.roles
            .filter((role) => !form.values['roles'].includes(role.id))
            .map((role) => { return { id: role.id, label: role.name, value: false } });

        return (
            <ConnectingDialog
                disableSaveButton={false}
                mandatory={true}
                title={`Rollen koppelen naar ${userGroupRoles.name}`}
                listItems={listItems}
                onCancelled={() => setAddRole(false)}
                onConfirmed={(roles) => {
                    const selected = roles.map(role => role.id);
                    storeFormValue(form.values['roles'].concat(selected), 'roles');
                    setAddRole(false);
                }}
            />
        );
    };

    const mapRoleIds = (roleIds: string[], roles: any[]) => {
        return roleIds.map((roleId) => ({ id: roleId, name: roles.find(r => r.id === roleId)?.name || 'N/A' }));
    };

    const getDeleteButton = (item) => {
        return <Button id="usergroup-removerole-btn"
            btnbase="iconbuttons"
            btntype="medium_fabprimary"
            icon={<DeleteIcon />}
            aria-label={`Verwijder ${item.name}`}
            onClick={() => {
                const roles = form.values['roles'].filter((id) => id !== item.id);
                storeFormValue(roles, 'roles');
            }} />;
    };

    return (
        <Section look='white'>
            <LayoutForm>
                <LayoutField key='userGroup-name' left={1} top={1} width={6} height={1}>
                    <TextViewer
                        id='userGroup-nameField'
                        label='Naam'
                        value={props.userGroupRoles.name}
                    />
                </LayoutField>
                <LayoutField key='userGroup-roles' left={1} top={2} width={6} height={1}>
                    <Label>Rollen</Label>
                    <LsGrid
                        dataSource={mapRoleIds(form.values['roles'], props.roles)}
                        columns={[{
                            name: 'name',
                            title: 'Naam',
                            allowSorting: false,
                        },
                        {
                            name: 'id',
                            title: ' ',
                            type: 'buttons',
                            width: '5%',
                            align: 'right',
                            renderCustom: (item) => getDeleteButton(item.data),
                        },
                        ]}
                        enableColumnChooser={false}
                        searching={false}
                        paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                        showRowLines={true}
                    />
                </LayoutField>
                <Button id='usergroup-addrole-btn' btnbase='textbuttons' btntype='medium_icon' onClick={() => setAddRole(true)}>
                    Voeg toe
                </Button>
                {addRole && getAssignmentDialogElement(props.userGroupRoles)}
            </LayoutForm>
        </Section >
    );
};

/**
 * Initialises the form data with user group roles data.
 */
const initFormData = (userGroupRoles: Domain.Shared.UserGroupRoles): AnyFormData => {
    return {
        values: {
            'roles': userGroupRoles.roleIds || [],
        },
        touched: {},
        validationErrors: {},
        isValid: true,
    };
};


