import React from 'react';
import { ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';

type Props = {
    selectedMeasureMoment: Domain.Shared.MeasureMoment,
    onConfirm: (measureMomentId: string) => void,
    onCancel: () => void,
};

/**
 * Represents an UI component that opens a confirmation dialog to delete a measure moment.
 */
const MeasureMomentDeleteDialog: React.FC<Props> = (props) => {

    return (
        <ModalDialog
            id='delete-measure-moment-dialog'
            settings={{
                look: 'message',
                title: 'Verwijder Moment',
                footer: <ModalDialogFooter leftButtonText='Annuleren' onLeftButtonClick={props.onCancel} rightButtonText='Verwijder Moment' onRightButtonClick={() => props.onConfirm(props.selectedMeasureMoment.id)} />
            }}
        >
            <Text danger={true} value='Weet u zeker dat u het moment wilt verwijderen? Het verwijderen van een moment is permanent.' />
        </ModalDialog>
    );
};

export { MeasureMomentDeleteDialog };