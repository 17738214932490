import React, { useMemo, useState } from 'react';
import { useNavigate, useLocation, matchPath } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import Add from '@mui/icons-material/Add';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import * as Domain from '@liasincontrol/domain';
import { SystemElementDefinitions, SystemFieldDefinitions, SystemModuleDefinitions } from '@liasincontrol/domain';
import { DefinitionsHelper, ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { AppSettingsService } from '@liasincontrol/config-service';
import { ActionSource, ElementDefinitionsActionCreator, ModulesActionCreator, SiteDesignsActionCreator, WorkflowTemplateActionCreator, State, AjaxRequestStatus, MeasureMomentsActionCreator } from '@liasincontrol/redux-service';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { Bar, Button, Heading1, PageTitle, Section, ErrorOverlay, InputToggle, Label } from '@liasincontrol/ui-basics';
import { ContextMenu, GridColumn, LsGrid, createSource } from '@liasincontrol/ui-devextreme';
import { PublicationAdd } from './PublicationAdd';
import { PublicationDelete } from './PublicationDelete';
import { copyDataSourcesFieldDefinitonId, copyPublicationSettingsFieldDefinitionId, copySitemapFieldDefinitionId, copyTemplatesFieldDefinitionId, copyContentFieldDefinitionId, measureMomentDefinitionId, workflowTemplateFieldDefinitionId, copyUsersFieldDefinitionId } from './utils';
import { PublicationClone } from './PublicationClone';
import { TimeoutMessageDialog } from './TimeoutMessageDialog';
import { PublicationCloseDialog } from './PublicationClose';
import { StatusFilter } from './index.styled'

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

type PublicationAction = {
    showDialog: boolean,
    actionInProgress: boolean,
    id?: string,
    name?: string,
    title?: string,
    isClosed?: boolean,
};

/**
 * Represents a UI component that renders the list of publications page.
 */
const Index: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isAdminPath = !!matchPath(pathname, '/publisher/admin/');

    // #region Grid state...
    const availableColumns: GridColumn<Domain.Publisher.PublicationV2>[] = getColumnConfiguration(
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Publications, ActionType.Delete)
            ? (item: Domain.Publisher.PublicationV2) => setDeletingInformation({ showDialog: true, actionInProgress: false, id: item.publicationId, name: item.name })
            : null,
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Publications, ActionType.Create)
            ? (item: Domain.Publisher.PublicationV2) => setPublicationCloneInformation({ showDialog: true, actionInProgress: false, id: item.publicationId, name: item.name, title: item.title })
            : null,
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Publications)
            ? ((item: Domain.Publisher.PublicationV2) => item.isClosed
                ? setPublicationClosedStatus(item.publicationId, !item.isClosed)
                : setClosePublicationInfo({ showDialog: true, actionInProgress: false, id: item.publicationId }))
            : null
    );

    // #endregion...
    const [lastRefresh, setLastRefresh] = useState<number>();
    const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
    const [deletingInformation, setDeletingInformation] = useState<PublicationAction>({ showDialog: false, actionInProgress: false });
    const [publicationCloneInformation, setPublicationCloneInformation] = useState<PublicationAction>({ showDialog: false, actionInProgress: false });
    const [closePublicationInfo, setClosePublicationInfo] = useState<PublicationAction>({ showDialog: false, actionInProgress: false });

    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [showTimeoutMessage, setShowTimeoutMessage] = useState<boolean>(false);
    const [showClosed, setShowClosed] = useState<boolean>(false);

    const customDataSource = useMemo(() => {

        return createSource({
            keyExpr: "publicationId",
            filter: showClosed ? null : { isClosed: false },
            paginate: true,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            selectedColumns: Object.keys(new Domain.Publisher.PublicationV2()),
            dataSourcePromise: DataAccess.Publications.getPublicationsV2
        });
    }, [lastRefresh, showClosed, props.elementDefinition]);

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinition || props.elementDefinition.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Publisher]);
        return null;
    }

    if (props.measureMoments.status === AjaxRequestStatus.NotSet) {
        props.fetchMeasureMoments();
    }

    if (!props.siteDesigns) {
        props.fetchSiteDesigns();
    }

    if (props.workflowTemplates.status === AjaxRequestStatus.NotSet) {
        props.fetchWorkflowTemplates();
    }

    /**
     * Represents an event handler that triggers the publication creation.
     * 
     * @param formFields Defines a dictionary of form fields.
     */
    const addPublication = (formFields: Record<string, string>): void => {
        const workflowId = formFields[workflowTemplateFieldDefinitionId];
        const momentId = formFields[measureMomentDefinitionId];

        const newPublication: Domain.Dto.Publisher.CreatePublication = {
            name: formFields[SystemFieldDefinitions.Pub.Name],
            title: formFields[SystemFieldDefinitions.Pub.Title],
            siteDesignId: formFields[SystemFieldDefinitions.Pub.SiteDesignId],
            workflowId: workflowId,
            measureMomentId: momentId,
        };

        DataAccess.Publications.createPublication(newPublication)
            .then((response) => {
                const newPublicationId = response.data;
                navigate(`/publisher/admin/publication/${newPublicationId}/information`)
            })
            .catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.AxiosTimeout)) {
                    setShowTimeoutMessage(true);
                } else if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PublicationNameDublicate)) {
                    setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PublicationNameDublicate] });
                } else {
                    setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err));
                }

                setShowAddDialog(false);
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err));
            });
    };

    /**
     * Represents an event handler that triggers when discarding the publication creation changes.
     */
    const cancelAddPublication = (): void => {
        setShowAddDialog(false);
    };

    /**
     * Represents an event handler that triggers the publication deletion.
     * 
     * @param publicationId Defines the unique identifier of the publication.
     */
    const deletePublication = (publicationId: string): void => {
        setDeletingInformation(state => ({ ...state, actionInProgress: true }));

        DataAccess.Publications.deletePublication(publicationId)
            .then(() => {
                setLastRefresh(Date.now());
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, err));
            }).finally(() => setDeletingInformation({ showDialog: false, actionInProgress: false }));
    };

    /**
     * Represents an event handler that triggers when discarding the publication deletion changes.
     */
    const cancelDeletePublication = (): void => {
        setDeletingInformation({ showDialog: false, actionInProgress: false });
    };

    /**
     * Represents an event handler that triggers the cloning of a publication.
     * @param publicationId Defines the id of the publication that will be cloned.
     * @param formFields Defines a dictionary of form fields.
     */
    const clonePublication = (publicationId: string, formFields: Record<string, string | boolean>): void => {
        setPublicationCloneInformation(prev => ({ ...prev, actionInProgress: true }));

        const momentId = formFields[measureMomentDefinitionId] as string;
        const includeSettings = formFields[copyPublicationSettingsFieldDefinitionId] as boolean;
        const includeDataSources = formFields[copyDataSourcesFieldDefinitonId] as boolean;
        const includeContent = formFields[copyContentFieldDefinitionId] as boolean;
        const includeLinkedUsers = formFields[copyUsersFieldDefinitionId] as boolean;
        const includePageTemplates = includeLinkedUsers || includeContent || formFields[copyTemplatesFieldDefinitionId] as boolean;
        const includeSiteMap = includeLinkedUsers || includeContent || formFields[copySitemapFieldDefinitionId] as boolean;

        const clonedPublication: Domain.Dto.Publisher.ClonePublication = {
            name: formFields[SystemFieldDefinitions.Pub.Name] as string,
            title: formFields[SystemFieldDefinitions.Pub.Title] as string,
            measureMomentId: momentId,
            includeSettings: includeSettings,
            includeDataSources: includeDataSources,
            includePageTemplates: includePageTemplates,
            includeSiteMap: includeSiteMap,
            includeContent: includeContent,
            includeLinkedUsers: includeLinkedUsers,
        };

        DataAccess.Publications.clonePublication(publicationId, clonedPublication).then((response) => {
            navigate(`/publisher/admin/publication/${response.data}/information`)
        }).catch((err) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err);
            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.AxiosTimeout)) {
                setShowTimeoutMessage(true);
            } else if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PublicationNameDublicate)) {
                setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PublicationNameDublicate] });
            } else {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err));
            }
        }).finally(() => {
            setPublicationCloneInformation({ showDialog: false, actionInProgress: false });
        });
    };

    const setPublicationClosedStatus = (publicationId: string, isClosed: boolean): void => {
        setClosePublicationInfo(state => ({ ...state, actionInProgress: true }));

        DataAccess.Publications.closePublication(publicationId, isClosed)
            .then(() => {
                setLastRefresh(Date.now());
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err));
            }).finally(() => setClosePublicationInfo({ id: null, showDialog: false, actionInProgress: false }));
    };

    /**
     * Represents an event handler that handles the cancellation of the publication clone action.
     */
    const cancelClonePublication = (): void => {
        setPublicationCloneInformation({ showDialog: false, actionInProgress: false });
    };

    return (
        <>
            <PageTitle>
                <Heading1>Alle publicaties</Heading1>
            </PageTitle>
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                <Bar look='toolbar'>
                    <Bar start>
                        <Button
                            id='btn-create'
                            btnbase='textbuttons'
                            btntype='medium_icon'
                            icon={<Add />}
                            disabled={!UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Publications, ActionType.Create)}
                            onClick={() => setShowAddDialog(true)}>
                            Nieuw
                        </Button>
                        <Button
                            id='btn-refresh'
                            btnbase='textbuttons'
                            btntype='medium_icon'
                            icon={<RefreshOutlinedIcon />}
                            onClick={() => setLastRefresh(Date.now())}>
                            Verversen
                        </Button>
                    </Bar>
                    <Bar end>
                        <StatusFilter>
                            <Label className='isclosed-label' id='label-isclosed' htmlFor='toggle-isclosed' text='Gesloten publicaties weergeven' />
                            <InputToggle
                                className='isclosed-toggle'
                                id='isclosed-filter-toggle'
                                selected={showClosed}
                                textOff='Nee'
                                textOn='Ja'
                                isSmall={true}
                                onChange={setShowClosed}
                            />
                        </StatusFilter>
                    </Bar>
                </Bar>
                <Section look='white' >
                    <LsGrid
                        buttonColumnId='publicationId'
                        dataSource={customDataSource}
                        columns={availableColumns}
                        paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                        enableColumnChooser={true}
                        showRowLines={true}
                        onClickRow={(item: Domain.Publisher.PublicationV2) => {
                            isAdminPath
                                ? navigate(`/publisher/admin/publication/${item.publicationId}/information`)
                                : navigate(`/publisher/publication/${item.publicationId}/writer/page/`);
                        }}
                        onDataError={(error) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))}
                    />
                </Section>
            </ErrorOverlay>
            {showAddDialog &&
                <PublicationAdd
                    measureMoments={props.measureMoments.items}
                    workflowTemplates={props.workflowTemplates.items}
                    siteDesigns={props.siteDesigns.items}
                    fieldDefinitions={props.elementDefinition.item.fields}
                    onSave={addPublication}
                    onCancel={cancelAddPublication} />}
            {deletingInformation.showDialog &&
                <PublicationDelete
                    publicationId={deletingInformation.id}
                    publicationName={deletingInformation.name}
                    disableSaveButton={deletingInformation.actionInProgress}
                    onDelete={deletePublication}
                    onCancel={cancelDeletePublication} />}
            {publicationCloneInformation.showDialog &&
                <PublicationClone
                    publicationId={publicationCloneInformation.id}
                    publicationName={`${publicationCloneInformation.name} - kopie`}
                    publicationTitle={`${publicationCloneInformation.title} - kopie`}
                    measureMoments={props.measureMoments.items}
                    fieldDefinitions={props.elementDefinition.item.fields}
                    saveButtonDisabled={publicationCloneInformation.actionInProgress}
                    onSave={clonePublication}
                    onCancel={cancelClonePublication}
                />}
            {closePublicationInfo.showDialog &&
                <PublicationCloseDialog
                    onRightButtonClick={() => { setPublicationClosedStatus(closePublicationInfo.id, !closePublicationInfo.isClosed) }}
                    onLeftButtonClick={() => { setClosePublicationInfo((prev) => ({ ...prev, showDialog: false })) }}
                    disabled={closePublicationInfo.actionInProgress}
                />
            }
            {showTimeoutMessage &&
                <TimeoutMessageDialog onCloseDialog={() => setShowTimeoutMessage(false)} />}
        </>
    );
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    const hasElementDefinitions = !_.isEmpty(state.elementdefinitions?.[ActionSource.Publication]);

    return {
        modules: state.modules[ActionSource.Publication],
        elementDefinition: {
            item: hasElementDefinitions ? DefinitionsHelper.findElementDefinition(state.elementdefinitions[ActionSource.Publication].items, SystemElementDefinitions.Pub.Publication) : undefined,
            status: hasElementDefinitions ? state.elementdefinitions[ActionSource.Publication].status : AjaxRequestStatus.NotSet,
        },
        siteDesigns: state.publisher.siteDesigns[ActionSource.Publication],
        workflowTemplates: {
            items: state.workflowtemplates.items?.filter(workflow => workflow.isActive)?.map((workflow, index) => ({
                id: workflow.id,
                name: workflow.name,
                order: index
            }) as Domain.Shared.FieldDefinitionOptionItem),
            status: state.workflowtemplates.status,
        },
        measureMoments: {
            items: state.measuremoments.items?.map((moment) => ({
                id: moment.id,
                name: moment.name,
                order: moment.order
            }) as Domain.Shared.FieldDefinitionOptionItem),
            status: state.measuremoments.status,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Publication, data: { moduleId: module?.id } }));
        },
        fetchSiteDesigns: () => {
            dispatch(SiteDesignsActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchWorkflowTemplates: () => {
            dispatch(WorkflowTemplateActionCreator.set());
        },
        fetchMeasureMoments: () => {
            dispatch(MeasureMomentsActionCreator.set());
        },
    };
};

const getColumnConfiguration = (
    onDeletePublication: (item: Domain.Publisher.PublicationV2) => void,
    onCopyPublication: (item: Domain.Publisher.PublicationV2) => void,
    onClosePublication: (item: Domain.Publisher.PublicationV2) => void): GridColumn<Domain.Publisher.PublicationV2>[] => {
    return [{
        name: 'name',
        title: 'Naam'
    },
    {
        name: 'title',
        title: 'Titel'
    },
    {
        name: 'isClosed',
        dataType: 'string',
        title: 'Status publicatie',
        renderCustom: (item) => <>{item.data.isClosed ? 'Gesloten' : 'Open'}</>,
    },
    {
        name: 'publicationId',
        align: 'right',
        title: '',
        type: 'buttons',
        width: '5%',
        hideInColumnChooser: true,
        renderCustom: ({ data }) =>
            (onDeletePublication || onCopyPublication || onClosePublication) &&
            <ContextMenu<Domain.Publisher.PublicationV2>
                keyExpr='publicationId'
                item={data}
                actions={[
                    {
                        action: onCopyPublication,
                        ariaLabel: "Kopie maken",
                        actionName: `copy-${data.publicationId}`,
                        displayName: "Kopie maken"
                    },
                    {
                        action: onClosePublication,
                        ariaLabel: "Afsluiten publicatie",
                        actionName: `close-${data.publicationId}`,
                        displayName: data.isClosed ? 'Openen' : 'Afsluiten'
                    },
                    {
                        action: onDeletePublication,
                        ariaLabel: "Verwijder publicatie",
                        actionName: `delete-${data.publicationId}`,
                        displayName: "Verwijderen"
                    }
                ]}
            />
    }];
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
