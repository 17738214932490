import styled from 'styled-components';
import { Label, palette, px } from '@liasincontrol/ui-basics';

const Grid = styled.div`
    display: grid;
    grid-template-columns: ${px(20)} repeat(12, [col-start] 1fr) ${px(20)};
    grid-template-rows: ${px(20)} repeat(7, [row-start] auto);
    grid-auto-flow: row;
    grid-gap: ${px(8)} ${px(20)};
`;

const HeaderForm = styled.div`
    grid-column: col-start / span 12;
    grid-row: 2 / span 1;
    display: flex;
    align-items: center;
    min-height: ${px(32)};
`;

const WarningBar = styled.div`
    grid-column: col-start / span 12;
    grid-row: 3 / span 1;
`;

const WarningForm = styled.div`
    grid-column: col-start / span 12;
    grid-row: 3 / span 1;
    display: flex;
    align-items: center;
    min-height: ${px(32)};
`;

const StyledLabel = styled(Label)` 
    min-height: 0;
    margin-left: ${px(3)};
`;

const HeaderNumber = styled.div`
    grid-column: col-start / span 2;
    grid-row: 4 / span 1;
`;

const HeaderName = styled.div`
    grid-column: col-start 3 / span 10;
    grid-row: 4 / span 1;
`;

const ContentHeaderBar = styled.div`
    grid-column: 1 / span 14;
    grid-row: 5 / span 2;
    background-color: ${palette.grey4};
    border-bottom: ${px(2)} solid ${palette.grey3b};
    z-index: -1;
    padding: ${px(70)} 0 0 0;
`;

const ContentHeader = styled.div`
    grid-column: 2 / span 12;
    grid-row: 6 / span 1;
    min-height: ${px(32)};
    display: flex;
    align-items: flex-end;
`;

const IconContainer = styled.div<{ color?: string }>`
    svg {
        fill: ${({ color }) => color ? color : palette.grey2};
    }
`;

const HeaderWorkflowState = styled.div`
    grid-column: col-start / span 2;
    grid-row: 5 / span 1;
    padding: ${px(20)} 0 0 0;
`;

const HeaderAssignedUser = styled.div`
    grid-column: col-start 3 / span 2;
    grid-row: 5 / span 1;
    padding: ${px(20)} 0 0 0;
`;

const HeaderStructure = styled.div`
    grid-column: 6 / span 2;
    grid-row: 5 / span 1;
    padding: ${px(20)} 0 0 0;
`;

const HeaderStructureNode = styled.div`
    grid-column: 8 / span 2;
    grid-row: 5 / span 1;
    padding: ${px(20)} 0 0 0;
`;

const styledComponents = {
    Grid, HeaderForm, StyledLabel, HeaderNumber, HeaderName, ContentHeaderBar, ContentHeader, IconContainer,
    HeaderWorkflowState, HeaderAssignedUser, WarningForm, WarningBar, HeaderStructure, HeaderStructureNode
};

export default styledComponents;
