import { fieldDefinitionDecorator } from '../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../Shared/FieldDefinition';

class DataSourceElement {

    id: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Name)
    name: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSourceDataFile)
    dataFileId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSourceDataSchemaFile)
    schemaFileId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSourceLastRefreshDate)
    lastRefreshDate: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Datastore, true)
    dataStoreId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSourceColumns, true)
    columns: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Filter, true)
    filter: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSourceMeasureMomentId, true)
    measureMomentId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSourceMeasureMomentIds, true)
    measureMomentIds: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSourceElementDefinitionId, true)
    elementDefinitionId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSourceAutoRefresh)
    autoRefresh = true;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSourceFailedRefresh)
    failedRefresh = false;
    
    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSourceStructure, true)
    structureRk: string;

}

export default DataSourceElement;