import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LoopIcon from '@mui/icons-material/Loop';
import { Button, IconSize, Text } from '@liasincontrol/ui-basics';
import Styled from './index.styled';

type Props = {
    steps: number;
    activeStep: number;
    onPrev: () => void,
    onNext: () => void,
    onReSend?: () => void,
};

/**
 * Represents a UI component that renders a tabular numbers pad.
 */
export const TabularNumbers: React.FC<Props> = (props) => {
    return (<Styled.Wrap>
        {props.steps > 0 && <>
            <Button id="btn-left"
                btnbase='iconbuttons'
                btntype='small_transparent'
                icon={<ChevronLeftIcon sx={{ fontSize: IconSize.small }} />}
                disabled={props.activeStep <= 0}
                onClick={props.onPrev}
                title="Vorig resultaat"
            />
            <Text active bold value={`${props.activeStep + 1} / ${props.steps}`} />
            <Button
                id="btn-right"
                btnbase='iconbuttons'
                btntype='small_transparent'
                icon={<ChevronRightIcon sx={{ fontSize: IconSize.small }} />}
                disabled={props.activeStep >= props.steps - 1}
                onClick={props.onNext}
                title="Volgende resultaat"
            />
        </>}
        {props.onReSend && <Button
            id="btn-regenerate"
            btnbase='iconbuttons'
            btntype='small_transparent'
            icon={<LoopIcon sx={{ fontSize: IconSize.small }} />}
            onClick={props.onReSend}
            title='Opnieuw genereren'
        />}
    </Styled.Wrap>);
};