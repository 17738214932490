import React, { useEffect, useState } from 'react';
import Styled from './index.styled';
import { Button } from '../button';
import { IProps } from './Props';

/**
 * Expects multiple `<li><button /></li>`
 */
export const DropdownButton = ({ text, disabled, icon, children }: IProps) => {
    const [visible, setVisible] = useState(false);
    
    const handleOnclick = (event) => {
        event.currentTarget.blur();
        event.stopPropagation();
        setVisible((prev) => !prev);
    };
    const hide = () => setVisible(false);
    
    useEffect(() => {
        if (visible && typeof window !== 'undefined') {
            window.addEventListener('click', hide);

            return () => window.removeEventListener('click', hide);
        }
    }, [visible]);

    return (<Styled.Wrap>
            <Button
                id='btn-add-new-item'
                btnbase='textbuttons'
                btntype='medium_icon'
                icon={icon}
                isDropdown={true}
                dropdownVisible={visible}
                disabled={disabled}
                onClick={handleOnclick}
            >
                {text}
            </Button>
            {children && (
                <>
                {visible && (
                    <Styled.Dropdown>
                        <ul>{children}</ul>
                    </Styled.Dropdown>
                )}
                </>
            )}
        </Styled.Wrap>
    );
};
