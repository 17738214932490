import React from 'react';
import { palette } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import Styled from './index.styled';

type Props = {
    publicationElement: Domain.Publisher.PublicationElement,
    children?: React.ReactNode;
};

/**
 * Represents a UI component that renders the body container.
 */
const BodyContainer: React.FC<Props> = (props) => {
    const secondaryBgColor = props.publicationElement && props.publicationElement.secondaryColor
        ? props.publicationElement.secondaryColor : palette.grey4;

    return (
        <Styled.CustomBody secondaryBgColor={secondaryBgColor}>
            <Styled.Body id="zion-body" class="body no-searchbox no-banner">
                {props.children}
                <div className="site-bg-1" />
            </Styled.Body>
        </Styled.CustomBody>
    );
};

export default BodyContainer;