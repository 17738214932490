import { useEffect } from 'react';
import { ConfigService } from '@liasincontrol/config-service';

type UserBack = {
    access_token: string,
};

const useUserBackScript = () => {
    useEffect(() => {
        const script = document.createElement('script');

        ConfigService.getConfigurations().then((config) => {
            if (config.PLUGINS.USERBACK_KEY) {
                window.Userback.access_token = config.PLUGINS.USERBACK_KEY;
                script.src = config.PLUGINS.USERBACK_SRC;
                script.async = true;
            } else {
                console.warn('The Userback plugin was not initialized, missing access token.');
            }
            document.body.appendChild(script);
        }).finally(() => {
            document.body.removeChild(script);
        });
    }, []);
};

export type { UserBack };
export default useUserBackScript;