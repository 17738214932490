import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { ApiErrorReportingHelper, FormMode } from '@liasincontrol/core-service';
import { Shared } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import { AppSettingsService } from '@liasincontrol/config-service';
import { ActionSource, AjaxRequestStatus, ElementDefinitionsActionCreator, ModulesActionCreator, State, SvgIconActionCreator } from '@liasincontrol/redux-service';
import { Bar, Button, ErrorOverlay, Heading2, IconSize, InputToggle, Label, PageTitle, Section, SVGIcon, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { ContextMenu, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { UserIdentity } from '@liasincontrol/auth-service';
import { ElementDefinitionModal } from './ElementDefinitionModal';
import Styled from './index.styled';
import { DeactivateElementDefinitionDialog } from './DeactivateElementDefinitionDialog';
import { getDefaultDetailCard } from '../_shared/Renderer/ElementDetailsForm/utils';

/**
 * Defines the props of the element definitions overview component.
 */
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity,
};

type CellProps = {
    data: Domain.Shared.ElementDefinition
};

/**
 * Represents a UI component that renders a list of element definitions.
 */
export const ElementDefinitions: React.FC<Props> = (props) => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [showClosed, setShowClosed] = useState<boolean>(false);
    const [elementDefinitions, setElementDefinitions] = useState<Domain.Shared.ElementDefinition[]>([]);
    const [elementDefinitionNames, setElementDefinitionNames] = useState<string[]>([]);
    const [elementDefinitionFormDialog, setElementDefinitionFormDialog] = useState<{ showDialog: boolean, isSaving: boolean }>({ showDialog: false, isSaving: false });
    const [deactivateElementDefinitionDialog, setDeactivateElementDefinitionDialog] = useState<{ showDialog: boolean, elementDefinition: Domain.Shared.ElementDefinition, isSaving: boolean }>({ showDialog: false, elementDefinition: null, isSaving: false });
    const navigate = useNavigate();

    const [externalError, setExternalError] = useState<{ id: string, message: string }>();

    useEffect(() => {
        if (!props.elementDefinitions?.items) return;
        const elementDefintions = Object.values(props.elementDefinitions.items).filter(ed => !showClosed ? !ed.deleted : true);
        setElementDefinitions(elementDefintions);
        setElementDefinitionNames(elementDefintions.map((ed) => ed.name.trim()));
    }, [showClosed, props.elementDefinitions])

    const onSaveElementDefinition = (elementDefinition: Domain.Dto.Shared.CreateElementDefinition) => {
        setElementDefinitionFormDialog(state => ({ ...state, isSaving: true }));
        let createdId: string = null;
        let keepOpen = false;
        Shared.ElementDefinitions.create(elementDefinition).then((response) => {
            createdId = response.data;
            const defaultCard = createNewDetailCard("defaultCard", true, createdId);
            return Shared.ElementDefinitions.createDetailCard(defaultCard);
        }).then(() => {
            return props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
        }).then(() => {
            navigate(`/studio/admin/elementdefinition/${createdId}/settings/information`);
        }).catch((exception) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, exception);

            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.ElementDefinitionNameNotUniqueAdd)) {
                const message = Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.ElementDefinitionNameNotUniqueAdd];
                keepOpen = true;
                setExternalError({
                    id: Domain.FieldDefinitions.Shared.nameFieldDefinition.id,
                    message: message,
                });
            } else {
                setError(errorInfo);
            }
        }).finally(() => {
            setElementDefinitionFormDialog({ showDialog: keepOpen, isSaving: false });
        });
    };

    const onDeactivateElementDefinition = (elementDefinitionId: string) => {
        setDeactivateElementDefinitionDialog(state => ({ ...state, isSaving: true }));

        Shared.ElementDefinitions.deactivate(elementDefinitionId).then(() => {
            props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, exception));
        }).finally(() => {
            setDeactivateElementDefinitionDialog({ showDialog: false, elementDefinition: null, isSaving: false });
        });
    };

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
        return null;
    }

    //refetch if no deleted
    if (props.elementDefinitions.status === AjaxRequestStatus.Done && !props.elementDefinitions.includeDeleted) {
        props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
        return null;
    }

    if (!props.icons || props.icons.status === AjaxRequestStatus.NotSet) {
        props.fetchIcons();
    }

    // #region Cell templates...
    const IconCellTemplate: React.FC<CellProps> = (cellProps) => (
        <SVGIcon value={props.icons.items[cellProps.data.icon]?.svg} size={IconSize.medium} color={cellProps.data.color} />
    );

    const onSetActiveStatus = (item: Domain.Shared.ElementDefinition) => {
        if (!item.deleted)
            setDeactivateElementDefinitionDialog({ showDialog: true, elementDefinition: item, isSaving: false })
        else {
            Shared.ElementDefinitions.activate(item.id).then(() => {
                props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
            }).catch((exception) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, exception));
            });
        }
    }

    const ContextualMenuCellTemplate: React.FC<CellProps> = ({ data }) => (
        <ContextMenu<Domain.Shared.ElementDefinition>
            item={data}
            keyExpr='id'
            actions={[
                {
                    action: (item) => navigate(`/studio/admin/elementdefinition/${item.id}/settings/information`),
                    actionName: 'edit-element-definition',
                    ariaLabel: `Bewerk ${data.name}`,
                    displayName: 'Bewerken'
                },
                {
                    action: (item) => onSetActiveStatus(item),
                    actionName: 'deactivate-element-definition',
                    ariaLabel: data.deleted ? `Inschakelen ${data.name}` : `Uitschakelen ${data.name}`,
                    displayName: data.deleted ? 'Inschakelen' : 'Uitschakelen',
                },
            ]}
        />);

    // #endregion

    const columnConfiguration: GridColumn<Domain.Shared.ElementDefinition>[] = [
        {
            name: 'icon',
            title: '',
            width: `${IconSize.medium + 22}px`,
            hideInColumnChooser: true,
            renderCustom: IconCellTemplate,
        },
        {
            name: 'name',
            title: 'Naam',
            width: '30%',
        },
        {
            name: 'description',
            title: 'Beschrijving',
        },
        {
            name: 'deleted',
            title: 'Actief',
            dataType: 'string',
            width: '10%',
            calculateDisplayValue: (item) => item.deleted ? 'Inactief' : 'Actief'
        },
        {
            name: 'id',
            title: '',
            type: 'buttons',
            width: '5%',
            align: 'right',
            hideInColumnChooser: true,
            renderCustom: ContextualMenuCellTemplate,
        }
    ];

    return (
        <>
            <Wrapper>
                <WrapperContent>
                    <PageTitle>
                        <Heading2>Elementdefinities</Heading2>
                    </PageTitle>

                    <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        <Bar look='toolbar'>
                            <Bar start>
                                <Button id='btn-add-new-elementkind' btnbase='textbuttons' btntype='medium_icon' icon={<Add />} disabled={!props.modules} onClick={() => setElementDefinitionFormDialog({ showDialog: true, isSaving: false })}>
                                    Nieuw
                                </Button>

                                <Button id='btn-refresh-element-definition-list' btnbase='textbuttons' btntype='medium_icon' icon={<RefreshIcon />} onClick={() => props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio])}>
                                    Verversen
                                </Button>
                            </Bar>
                            <Bar end>
                                <Styled.StatusFilter>
                                    <Label className='isclosed-label' id='label-isclosed' htmlFor='toggle-isclosed' text='Toon alles' />
                                    <InputToggle
                                        className='isclosed-toggle'
                                        id='isclosed-filter-toggle'
                                        selected={showClosed}
                                        textOff='Nee'
                                        textOn='Ja'
                                        isSmall={true}
                                        onChange={setShowClosed}
                                    />
                                </Styled.StatusFilter>
                            </Bar>
                        </Bar>

                        <Section look='white'>
                            <LsGrid
                                dataSource={elementDefinitions}
                                columns={columnConfiguration}
                                enableColumnChooser={false}
                                searching={false}
                                paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                                showRowLines={true}
                                keyExpr='id'
                                onClickRow={(item: Domain.Shared.ElementDefinition) => {
                                    navigate(`/studio/admin/elementdefinition/${item.id}/settings/information`);
                                }}
                            />
                        </Section>
                    </ErrorOverlay>
                </WrapperContent>
            </Wrapper>
            {elementDefinitionFormDialog.showDialog && (
                <ElementDefinitionModal
                    elementDefinition={undefined}
                    onCancel={() => setElementDefinitionFormDialog({ showDialog: false, isSaving: false })}
                    onSave={onSaveElementDefinition}
                    disableSaveButton={elementDefinitionFormDialog.isSaving}
                    elementDefinitionNames={elementDefinitionNames}
                    moduleId={props.modules[Domain.SystemModuleDefinitions.Studio].id}
                    mode={FormMode.AddNew}
                    externalError={externalError}
                    icons={props.icons.items}
                />
            )}
            {deactivateElementDefinitionDialog.showDialog && deactivateElementDefinitionDialog.elementDefinition && (
                <DeactivateElementDefinitionDialog
                    elementDefinition={deactivateElementDefinitionDialog.elementDefinition}
                    onCancel={() => setDeactivateElementDefinitionDialog({ showDialog: false, elementDefinition: null, isSaving: false })}
                    onConfirm={onDeactivateElementDefinition}
                />
            )}
        </>
    );
};

export const createNewDetailCard = (name: string, isDefault: boolean, elementDefinitionId: string) => {
    const detailCard = new Domain.Dto.Shared.CreateDetailCard();
    detailCard.layout = getDefaultDetailCard().layout;
    detailCard.name = name;
    detailCard.isDefault = isDefault;
    detailCard.elementDefinitionId = elementDefinitionId;
    return detailCard;
};

const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Studio],
        icons: state.icons,
        elementDefinitions: state.elementdefinitions[ActionSource.Studio],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Studio, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            return dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Studio, data: { moduleId: module.id, includeDetailCards: true, includeDeleted: true } }));
        },
        fetchIcons: () => {
            dispatch(SvgIconActionCreator.set());
        }
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(ElementDefinitions);
export { Component as index };
