import styled, { keyframes } from 'styled-components';

interface ILoaderProps {
    readonly variant?: 'icon' | 'default' | 'small' | 'inline' | 'large';
}
const Rotate = keyframes`
        from { transform: rotate(0deg); }
        to { transform: rotate(360deg); }
    `;
const LoaderIcon = styled.div`
    display: ${(p: ILoaderProps) => p.variant === 'inline' && `inline`};
    width: ${(p: ILoaderProps) =>
        (p.variant === 'icon' && `24px`) ||
        (p.variant === 'inline' && `24px`) ||
        (p.variant === 'small' && `24px`) ||
        (p.variant === 'large' && `80px`) ||
        `48px`};
    margin: ${(p: ILoaderProps) => (p.variant === 'icon' && `0`) || (p.variant === 'inline' && `0`) || (p.variant === 'small' && `16px 0`) || `32px auto`};
    > .MuiSvgIcon-root {
        width: ${(p: ILoaderProps) =>
        (p.variant === 'icon' && `24px`) ||
        (p.variant === 'inline' && `24px`) ||
        (p.variant === 'small' && `24px`) ||
        (p.variant === 'large' && `80px`) ||
        `48px`};
        height: ${(p: ILoaderProps) =>
        (p.variant === 'icon' && `24px`) ||
        (p.variant === 'inline' && `24px`) ||
        (p.variant === 'small' && `24px`) ||
        (p.variant === 'large' && `80px`) ||
        `48px`};
        color: ${(p: ILoaderProps) =>
        (p.variant === 'icon' && `rgba(75,85,246,1);`) ||
        (p.variant === 'inline' && `rgba(75,85,246,1);`) ||
        (p.variant === 'small' && `rgba(75,85,246,1);`) ||
        (p.variant === 'large' && `rgba(75,85,246,1);`) ||
        `rgba(75,239,246,1);`};
        fill: currentColor;
        animation: ${Rotate} 1s infinite linear;
    }
`;

const styledComponents = { LoaderIcon };
export default styledComponents;