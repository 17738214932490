import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { Bar, BasicText, Button, ErrorOverlay, Section, SectionHeader } from '@liasincontrol/ui-basics';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { Actions, ActionType, UserRightsService } from '@liasincontrol/userrights-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { VersionConfiguration } from './VersionConfiguration';
import { VersionList } from './VersionList';
import { VersionDelete } from './VersionDelete';
import { VersionSiteAccessConfiguration } from './VersionSiteAccessConfiguration';
import Styled from './index.styled';
import { TimeoutMessageDialog } from '../TimeoutMessageDialog';

type Props = {
    publicationId: string,
    userIdentity: UserIdentity,
    versionElementDefinition: Domain.Shared.ElementDefinition,
    users: Domain.Shared.User[],
    setErrors: (errors: Domain.Publisher.BuildError[]) => void,
};

type VersionActionInfo = {
    showDialog: boolean,
    operationInProgress: boolean,
    versionId?: string,
    versionName?: string,
};

type VersionChangeSiteAccessInfo = VersionActionInfo & {
    isSitePublic?: boolean,
};

export type PublicationWebsiteVersion = Domain.Publisher.PublicationWebsiteVersion & {
    userName: string;
};

/**
 * Represents a UI component that renders the publish website section.
 */
export const WebsiteSection = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [publishButtonDisabled, setPublishButtonDisabled] = useState<boolean>(false);
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(null);
    const [showVersionConfiguration, setShowVersionConfiguration] = useState<boolean>(false);
    const [showTimeoutMessage, setShowTimeoutMessage] = useState<boolean>(false);
    const [versionErrors, setVersionErrors] = useState<Record<string, string>>();
    const [publicationVersions, setPublicationVersions] = useState<PublicationWebsiteVersion[]>();
    const [versionDeleteInfo, setVersionDeleteInfo] = useState<VersionActionInfo>({ showDialog: false, operationInProgress: false });
    const [changeSiteAccessInfo, setChangeSiteAccessInfo] = useState<VersionChangeSiteAccessInfo>({ showDialog: false, operationInProgress: false });

    useEffect(() => {
        if (!props.users) return;

        DataAccess.Builds.getPublicationWebsiteVersions(props.publicationId)
            .then(response => {
                const versions: PublicationWebsiteVersion[] = response.data.map(version => {
                    const userName = version.lastUpdatedBy ? props.users.find(u => u.id.toLowerCase() === version.lastUpdatedBy.toLowerCase())?.name : '';
                    return {
                        ...version,
                        userName: userName,
                        versionNumber: getNormalizedVersionNumber(version.versionNumber),
                    } as PublicationWebsiteVersion;
                });
                setPublicationVersions(versions);
            }).catch(err => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
            });
    }, [props.publicationId, props.users, lastRefresh]);

    const generateWebsite = (versionName: string, isPublicWebsite: boolean, publishProfileId: string): void => {
        setPublishButtonDisabled(true);

        DataAccess.Builds.generatePublicationWebsite(props.publicationId, versionName, isPublicWebsite, publishProfileId ? publishProfileId : null)
            .then(() => {
                setLastRefresh(Date.now());
                setShowVersionConfiguration(false);
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.InvalidVersion)) {
                    setVersionErrors({ [Domain.SystemFieldDefinitions.Pub.VersionName]: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.InvalidVersion] });
                } else if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.AxiosTimeout)) {
                    setShowTimeoutMessage(true);
                    setShowVersionConfiguration(false);
                } else if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed)) {
                    setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed] });
                    setShowVersionConfiguration(false);
                } else {
                    setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
                    setShowVersionConfiguration(false);
                }
            }).finally(() => {
                setPublishButtonDisabled(false);
            });
    };

    const openVersionDeleteModal = (item: PublicationWebsiteVersion) => {
        setVersionDeleteInfo({ showDialog: true, operationInProgress: false, versionId: item.versionId, versionName: item.versionName });
    };

    const openChangeSiteAccessModal = (item: PublicationWebsiteVersion) => {
        setChangeSiteAccessInfo({ showDialog: true, versionId: item.versionId, operationInProgress: false, isSitePublic: item.publishedSiteIsPublic });
    };

    const openVersionConfigModal = (): void => {
        setVersionErrors(null);
        setShowVersionConfiguration(true);
    };

    const deleteVersion = () => {
        setVersionDeleteInfo(prev => ({ ...prev, operationInProgress: true }));

        DataAccess.Publications.deleteVersion(props.publicationId, versionDeleteInfo.versionId).then(() => {
            setLastRefresh(Date.now());
        }).catch(err => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
        }).finally(() => {
            setVersionDeleteInfo({ showDialog: false, operationInProgress: false });
        });
    };

    const changeSiteAccess = (isPublic: boolean) => {
        setChangeSiteAccessInfo(prev => ({ ...prev, operationInProgress: true }));

        DataAccess.Publications.setPublicationSiteAccess(props.publicationId, changeSiteAccessInfo.versionId, isPublic).then(() => {
            setLastRefresh(Date.now());
        }).catch(err => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
        }).finally(() => {
            setChangeSiteAccessInfo({ showDialog: false, operationInProgress: false });
        });
    };

    const showErrors = (buildErrors?: Domain.Publisher.BuildError[]) => {
        props.setErrors(buildErrors);
    };

    const versionFieldDefinitions = useMemo(() => props.versionElementDefinition?.fields?.reduce(
        (collection, item) => ({ ...collection, [item.systemId]: item }),
        {}
    ) as Record<string, Domain.Shared.FieldDefinition>, [props.versionElementDefinition]);

    const canPublish = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_PublishPublication, ActionType.Create);
    const latestVersion = publicationVersions && publicationVersions.length > 0 ? publicationVersions[0] : null;
    const latestVersionName = latestVersion?.versionName ?? '';
    const latestPublishProfileId = latestVersion?.publishProfileId ?? '';
    const latestVersionVisibility = latestVersion?.publishedSiteIsPublic?.toString() ?? '';

    return (
        <>
            <Section look='white'>
                <ErrorOverlay error={error?.message} errorDetails={error?.details}
                    onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(null) : null}>
                    <SectionHeader ref={ref} anchor='Website'>Website</SectionHeader>
                    <Styled.PublishContent>
                        <BasicText>Publiceer de website naar een interne of de live omgeving</BasicText>
                        <Bar look='toolbar'>
                            <Bar start>
                                <Button
                                    btnbase='primarybuttons'
                                    btntype='medium_noicon'
                                    aria-label='Publiceer de website'
                                    onClick={openVersionConfigModal}
                                    style={{ display: 'flex' }}
                                    disabled={!canPublish || publishButtonDisabled}>
                                    Website publiceren
                                </Button>
                            </Bar>
                        </Bar>
                        <VersionList
                            userIdentity={props.userIdentity}
                            versions={publicationVersions}
                            publicationId={props.publicationId}
                            onDeleteVersion={openVersionDeleteModal}
                            onChangeSiteAccess={openChangeSiteAccessModal}
                            onShowErrors={showErrors}
                        />
                    </Styled.PublishContent>
                </ErrorOverlay>
            </Section>
            {showVersionConfiguration &&
                <VersionConfiguration
                    publishButtonDisabled={publishButtonDisabled}
                    versionFieldDefinitions={versionFieldDefinitions}
                    currentVersionName={latestVersionName}
                    currentPublishProfileId={latestPublishProfileId}
                    currentVersionVisibility={latestVersionVisibility}
                    onPublishWebsite={generateWebsite}
                    onCloseDialog={() => setShowVersionConfiguration(false)}
                    errors={versionErrors}
                />}
            {versionDeleteInfo.showDialog &&
                <VersionDelete disableSaveButton={versionDeleteInfo.operationInProgress}
                    versionId={versionDeleteInfo.versionId}
                    versionName={versionDeleteInfo.versionName}
                    onCancel={() => setVersionDeleteInfo({ operationInProgress: false, showDialog: false })}
                    onDelete={deleteVersion}
                />}
            {changeSiteAccessInfo.showDialog &&
                <VersionSiteAccessConfiguration
                    currentSiteAccess={changeSiteAccessInfo.isSitePublic}
                    onCloseDialog={() => setChangeSiteAccessInfo({ operationInProgress: false, showDialog: false })}
                    onChangeSiteAccess={changeSiteAccess}
                    saveButtonDisabled={changeSiteAccessInfo.operationInProgress} />}
            {showTimeoutMessage &&
                <TimeoutMessageDialog title='Website publiceren' text='Het publiceren neemt wat meer tijd in beslag, kom later terug om te kijken of het proces afgerond is.' onCloseDialog={() => setShowTimeoutMessage(false)} />}
        </>
    );
});

const getNormalizedVersionNumber = (versionNumber: string) => {
    return versionNumber && _.trimStart(versionNumber, '0')?.length <= 3 ? versionNumber.substring(versionNumber.length - 3) : _.trimStart(versionNumber, '0');
};
