import React from 'react';
import { useNavigate, useParams, useLocation, matchPath } from 'react-router-dom';
import _ from 'lodash';
import { CardHeaderTab, MenuItem } from '@liasincontrol/ui-basics';
import * as Styled from './index.styled';


/**
 * Represents a UI component that renders the horizontal navigation bar of the field definition layout.
 */
const Index: React.FC = () => {
    const { id: elementDefinitionId } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const navItemsAdmin: MenuItem[] = [
        {
            key: 'elementdefinition-information',
            title: 'Instellingen',
            active: !_.isNull(matchPath(pathname, `/studio/admin/elementdefinition/${elementDefinitionId}/settings/information`)),
            to: `/studio/admin/elementdefinition/${elementDefinitionId}/settings/information`,
            alt: 'Instellingen',
        },
        {
            key: 'elementdefinition-fields',
            title: 'Velden',
            active: !_.isNull(matchPath(pathname, `/studio/admin/elementdefinition/${elementDefinitionId}/settings/fields`)),
            to: `/studio/admin/elementdefinition/${elementDefinitionId}/settings/fields`,
            alt: 'Velden',
        },
        {
            key: 'elementdefinition-details-layout',
            title: 'Kaart inrichten',
            active: !_.isNull(matchPath(pathname, `/studio/admin/elementdefinition/${elementDefinitionId}/settings/layout`)),
            to: `/studio/admin/elementdefinition/${elementDefinitionId}/settings/layout`,
            alt: 'Kaart inrichten',
        },

    ];

    const navtabs = navItemsAdmin.map((navItem) =>
        <CardHeaderTab id={`btn-nav-${navItem.key}`} key={navItem.key} active={navItem.active} title={navItem.title} onClick={() => navigate(navItem.to)} />
    );

    return (
        <Styled.Grid>
            <Styled.ContentHeaderBarRight />
            <Styled.ContentHeaderRight>
                {navtabs}
            </Styled.ContentHeaderRight>
        </Styled.Grid>
    );
};

export default Index;
