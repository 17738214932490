export interface IPointInfo {
    pointColor: string;
    pointIndex: number;
    pointName: any;
}

export interface IBorder {
    color?: string;
    cornerRadius?: number;
    dashStyle?: string;
    opacity?: number;
    visible?: boolean;
    width?: number;
}

export interface IFont {
    color?: string;
    family?: string;
    opacity?: number;
    size?: string | number;
    weight?: number;
}

export interface IMargin {
    bottom?: number;
    left?: number;
    right?: number;
    top?: number;
}

export interface ISubtitle {
    font?: IFont;
    offset?: number;
    text?: string;
    textOverflow?: 'ellipsis' | 'hide' | 'none';
    wordWrap?: 'normal' | 'breakWord' | 'none';
}

export type LsTooltipRef = {
    argument: string,
    argumentText: string,
    originalArgument: string,
    originalValue: number,
    percent: number,
    percentText: string,
    seriesName: string,
    value: number,
    valueText: string,
    point: any,
};

export const getRealHeight = (width: number, maxAllowedHeight: number) => {
    return (width > maxAllowedHeight ? maxAllowedHeight : width);
};
