import React, { useState, useEffect, useMemo } from 'react';
import { Dictionary } from 'lodash';
import { loremIpsum } from 'lorem-ipsum';
import { FieldsHelper } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { SystemFieldDefinitions } from '@liasincontrol/domain';
import { ActionSource } from '@liasincontrol/redux-service';
import SharedStyled from '../../SharedControlElements/index.styled';

type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Dictionary<Domain.Publisher.Element>,
    publicationElement: Domain.Publisher.PublicationElement,
    hierarchies: Domain.Shared.HierarchyMap,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    loadPerformanceHierarchy?: (measureMomentId: string) => void,
    getDefinition: (id: string, source: ActionSource) => Domain.Shared.ElementDefinition,
};

const PLACEHOLDER_TITLE = 'Veld naam';
const PLACEHOLDER_FOOTER = 'Schrijvers kunnen een specifiek item selecteren';

/**
 * Represents a UI component that renders a performance information control.
 */
const PerformanceInformationControl: React.FC<Props> = (props) => {
    const [controlSettings, setControlSettings] = useState<Domain.Publisher.PerformanceInformationControl>();
    const [relatedValues, setRelatedValues] = useState<{ title: string, text: string }>();

    const defaultText = useMemo(() => loremIpsum({ count: 30, units: 'words' }), []);

    useEffect(() => {
        const element = props.elementList[props.element.elementId];
        if (element) {
            const definition = props.getElementDefinition(element.elementDefinitionSystemId, element.elementDefinitionId);
            const data = new Domain.Publisher.PerformanceInformationControl();
            FieldsHelper.mapObject<Domain.Publisher.PerformanceInformationControl>(data, definition.fields, element.fields);

            setControlSettings(data);
            if (!!data.measureMomentId) props.loadPerformanceHierarchy(data.measureMomentId);
        }
    }, [props.elementList[props.element.elementId]]);


    useEffect(() => {
        if (!controlSettings || !props.hierarchies[ActionSource.Performance][controlSettings?.measureMomentId]) {
            return;
        }

        const source = ActionSource.Performance;
        const hierarchyDefinitionId = 'performance';

        const entity = props.hierarchies[source][controlSettings.measureMomentId][hierarchyDefinitionId].find(e => e.element.elementId === controlSettings.entityId);
        const definition = props.getDefinition(entity?.element.elementDefinitionId, source);

        const values = {
            title: entity?.element.fields[definition?.fields?.find((item) => item.systemId === SystemFieldDefinitions.Pub.Name)?.id],
            text: entity?.element.fields[definition?.fields?.find((item) => item.id === controlSettings.fieldId)?.id]
        };

        setRelatedValues(values);
    }, [controlSettings, props.hierarchies]);

    if (!controlSettings) {
        return null;
    }

    return (<>
        <SharedStyled.Title h3FontColor={props.publicationElement?.h3FontColor} h3FontSize={props.publicationElement?.h3FontSize}>{controlSettings.entityId ? relatedValues?.title : PLACEHOLDER_TITLE}</SharedStyled.Title>
        <SharedStyled.GridWrapper
            primaryColor={props.publicationElement.primaryColor}
            primaryTextColor={props.publicationElement.primaryContrastColor}
            textColor={props.publicationElement.bodyFontColor}
            textFontSize={props.publicationElement.bodyFontSize}
            editMode={false}
        >{controlSettings.entityId ? relatedValues?.text : defaultText}</SharedStyled.GridWrapper>

        {!controlSettings.entityId && <SharedStyled.Footer>{PLACEHOLDER_FOOTER}</SharedStyled.Footer>}
    </>);
};

export default PerformanceInformationControl;
