import React from 'react';
import { HyperlinkViewer } from './index.viewer';
import { HyperlinkEditor } from './index.editor';
import { ElementProps } from '../..';

/**
 * Represents a UI component that renders a hyperlink element.
 */
export const HyperlinkElement: React.FC<ElementProps<string>> = (props) => {
    return props.editorSettings && !props.editorSettings.disabled ? <HyperlinkEditor {...props} /> : <HyperlinkViewer id={props.id} label={props.label} helpText={props.helpText} value={props.value} />;
};