import React from 'react';
import { InputField } from '../_shared'
import Styled from './index.styled';
import { SearchFieldProps } from './index.props';

export const SearchField = (props: SearchFieldProps) => (
  <>
    {(props.totalResults || props.totalResults === 0) && (
      <Styled.SectionAction>
        <span>({props.totalResults})</span>
      </Styled.SectionAction>)}
    <InputField {...props}>
      <Styled.SearchIconWrap
        color={props.magnifyIconColor}
        name='search-icon'
        location='after'
        options={{
          icon: "search",
          type: "default",
          hoverStateEnabled: false,
          stylingMode: 'text',
        }}
      />
    </InputField>
  </>
);
