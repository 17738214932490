import styled from 'styled-components';
import Accordion from 'devextreme-react/accordion';

const BorderedAccordion = styled(Accordion)`
    border: 1px solid #F4F4F8;

    .dx-accordion-item-closed .dx-accordion-item-body {
        visibility: visible;
    }
`;


export { BorderedAccordion };
