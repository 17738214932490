import { Overlay, Tooltip } from '@liasincontrol/ui-basics';
import React, { useState } from 'react';
import Styled from './index.styled';
import ContextComponents from './contextmenu.styled';

type Props = {
    readonly look: 'inverted' | 'default',
    readonly menuAction: MenuAction,
    readonly contextItem: string,
    readonly children?: React.ReactNode,
    readonly icon?: React.ReactNode,
    readonly highlightPopup?: boolean,
    readonly position: 'bottom' | 'left' | 'default' | 'dynamic',
    readonly overlayOnclick?: () => void,
}

enum MenuAction {
    Context = "context",
    Main = "hoofd"
}

/**
 * Represents a UI component that renders a context menu wrapper.
 */
const ContextMenu: React.FC<Props> = (props) => {
    const [popOver, setPopOver] = useState<boolean>(false);

    const Popover =
        props.position === 'bottom' ? Styled.BottomPopover :
            (props.position === 'left' ? Styled.LeftPopover : Styled.SidePopover);

    const onPopOver = (event: React.MouseEvent, showPopOver: boolean) => {
        setPopOver(showPopOver);
        if (props.overlayOnclick && !showPopOver) props.overlayOnclick();
        event.stopPropagation();
    };

    return (<>
        {props.position !== 'dynamic' &&
            <Styled.Wrap position={props.position} withPadding={props.menuAction === MenuAction.Context}>
                <Styled.ButtonPlaceholder look={props.look} aria-label={`${props.menuAction === MenuAction.Context ? `Open ${props.menuAction}-menu voor ${props.contextItem}` : `Open ${props.menuAction}-menu`}`} aria-expanded={popOver} onClick={(event: React.MouseEvent) => {
                    onPopOver(event, true);
                }}>
                    {props.icon}
                </Styled.ButtonPlaceholder>
                <Overlay onClick={(event: React.MouseEvent) => onPopOver(event, false)} relative visible={popOver} opacity={props.highlightPopup ? 0 : 0.1}>
                    <Popover onClick={(event: React.MouseEvent) => onPopOver(event, false)} >
                        <>
                            {props.contextItem === 'menu' &&
                                <Styled.ButtonPlaceholderWithMargin onClick={(event: React.MouseEvent) => onPopOver(event, false)} >
                                    {props.icon}
                                </Styled.ButtonPlaceholderWithMargin>
                            }
                            {props.children}
                        </>
                    </Popover>
                </Overlay>
            </Styled.Wrap>
        }
        {props.position === 'dynamic' &&
            <Tooltip isTooltipVisible={popOver}
                content={props.children}
                displayOverlay={true}>
                <Styled.ButtonPlaceholder look={props.look}
                    aria-label={`${props.menuAction === MenuAction.Context ? `Open ${props.menuAction}-menu voor ${props.contextItem}` : `Open ${props.menuAction}-menu`}`}
                    aria-expanded={popOver}
                    onClick={() => { setPopOver(true); }}>
                    {props.icon}
                </Styled.ButtonPlaceholder>
            </Tooltip>
        }</>
    );
}

export { ContextMenu, MenuAction, ContextComponents };