import React from 'react';
import { ColorPickerEditor } from './index.editor';
import { ColorPickerProps } from './index.props';
import { ColorPickerViewer } from './index.viewer';

/**
 * Represents a UI component that renders an color picker element.
 */
export const ColorPickerElement: React.FC<ColorPickerProps> = (props) => {
    return props.editorSettings ? <ColorPickerEditor {...props} /> : <ColorPickerViewer {...props} />;
};