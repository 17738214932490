import { createReducer } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { TenantActionCreator, WorkflowTemplateActionCreator } from '../../../../actions/creators';
import { AjaxRequestStatus } from '../../../state';

/**
 * Represents the workflow templates reducer.
 */
const reducer = createReducer<{ items: Domain.Shared.WorkflowTemplateWithStates[], status: AjaxRequestStatus }>({ items: [], status: AjaxRequestStatus.NotSet }, (builder) => {
  return (
    builder.addCase(WorkflowTemplateActionCreator.set.fulfilled, (state, action) => {
      state = {
        items: action.payload?.workflows.map(workflow => {
          const workflowStates = action.payload.workflowStates.filter((workflowState) => workflowState.workflowDefinitionId === workflow.id).sort((a, b) => a.order - b.order);

          return { ...workflow, workflowStates: workflowStates } as Domain.Shared.WorkflowTemplateWithStates;
        }),
        status: AjaxRequestStatus.Done,
      };
      return state;
    }),
    builder.addCase(WorkflowTemplateActionCreator.set.pending, (state, action) => {
      // Loading in progress;
      state = {
        items: [],
        status: AjaxRequestStatus.Pending,
      };
      return state;
    }),
    builder.addCase(WorkflowTemplateActionCreator.set.rejected, (state, action) => {
      state = {
        items: [],
        status: AjaxRequestStatus.Failed,
      };
    }),
    builder.addCase(TenantActionCreator.initTenant, (state, action) => {
      state = {
        items: [],
        status: AjaxRequestStatus.NotSet,
      };
      return state;
    })
  );
});

export default reducer;
