
import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../DataAccessHostUtils';

/**
 * Class that allows interaction with the user-specific backend APIs.
 */
export class TextAssistant {
    /**
     * Gets a list available skills for textAssistant
     */
    public static getSkills = async (): Promise<AxiosResponse<Domain.Shared.TextAssistantSkills>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig(true);
        return HttpClient.get<Domain.Shared.TextAssistantSkills>('api/ai/text-assist/skills', config);
    };

    /**
     * Create a request for textAssistant
     * @param workflowWithStates The workflow definition object.
     */
    public static postText = async (skillId: string, inputText: string): Promise<AxiosResponse<Domain.Shared.TextAssistantResponse>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig(true);
        return HttpClient.post<Domain.Shared.TextAssistantResponse>('/api/ai/text-assist/run-skill', { skillId: skillId, inputText: inputText }, config);
    };
}

export default TextAssistant;
