import { createReducer } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { StructureNodeActionCreator, TenantActionCreator } from '../../../../actions/creators';
import { AjaxRequestStatus } from '../../../state';

/**
 * Represents the Finance structureNodes reducer.
 */
const reducer = createReducer<{ [structureRK: string]: { items: Domain.Finance.StructureNode[], status: AjaxRequestStatus } }>
    ({}, (builder) => {
        return (
            builder.addCase(StructureNodeActionCreator.set.fulfilled, (state, action) => {
                const structureRK = action.meta.arg.data.structureRK;
                state[structureRK] = {
                    items: action.payload,
                    status: AjaxRequestStatus.Done,
                };
                return state;
            }),
            builder.addCase(StructureNodeActionCreator.set.pending, (state, action) => {
                // Loading in progress;
                const structureRK = action.meta.arg.data.structureRK;
                state[structureRK] = {
                    items: [],
                    status: AjaxRequestStatus.Pending,
                };
                return state;
            }),
            builder.addCase(StructureNodeActionCreator.set.rejected, (state, action) => {
                const structureRK = action.meta.arg.data.structureRK;
                state[structureRK] = {
                    items: [],
                    status: AjaxRequestStatus.Failed,
                };
            }),
            builder.addCase(TenantActionCreator.initTenant, (state, action) => {
                state = {};
                return state;
            })
        );
    });

export default reducer;
