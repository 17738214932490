
/* localisation: messages for pivot-grid */

export const Dictionary = {
    "nl": {
        "dxPivotGrid-grandTotal": "Eindtotaal",
        "dxPivotGrid-total": "{0} Totaal",
        "dxPivotGrid-fieldChooserTitle": "Veldenkiezer",
        "dxPivotGrid-showFieldChooser": "Toon Veldenkiezer",
        "dxPivotGrid-expandAll": "Alle Uitklappen",
        "dxPivotGrid-collapseAll": "Alle Inklappen",
        "dxPivotGrid-sortColumnBySummary": "Sorteer \"{0}\" op Deze Kolom",
        "dxPivotGrid-sortRowBySummary": "Sorteer \"{0}\" op Deze Rij",
        "dxPivotGrid-removeAllSorting": "Verwijderen Alle Sorteringen",
        "dxPivotGrid-dataNotAvailable": "N/B",
        "dxPivotGrid-rowFields": "Rijvelden",
        "dxPivotGrid-columnFields": "Kolomvelden",
        "dxPivotGrid-dataFields": "Gegevensvelden",
        "dxPivotGrid-filterFields": "Filtervelden",
        "dxPivotGrid-allFields": "Alle Velden",
        "dxPivotGrid-columnFieldArea": "Sleep Kolomvelden Hier",
        "dxPivotGrid-dataFieldArea": "Sleep Gegevensvelden Hier",
        "dxPivotGrid-rowFieldArea": "Sleep Rijvelden Hier",
        "dxPivotGrid-filterFieldArea": "Sleep Filtervelden Hier",
    }
};