import React, { useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
import * as Domain from '@liasincontrol/domain';
import { ApiErrorReportingHelper, AttachmentsHelper, DateUtils } from '@liasincontrol/core-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { Actions, UserRightsService } from '@liasincontrol/userrights-service';
import { Publisher as DataAccess } from '@liasincontrol/data-service';
import { BasicText, Button, ErrorOverlay, LayoutField, LayoutForm, Section, SectionHeader, ThumbnailImg } from '@liasincontrol/ui-basics';
import { TextElement } from '@liasincontrol/ui-elements';
import { buildStatusTranslation } from './../index';
import { PublishConfiguration } from './PublishConfiguration';
import Styled from './../index.styled';
import { TimeoutMessageDialog } from '../TimeoutMessageDialog';

type Props = {
    publicationId: string,
    userIdentity: UserIdentity,
    attachments: { [attachmentId: string]: File; },
    setAttachment: (attachmentId: string, attachment: File) => void,
    setErrors: (errors: Domain.Publisher.BuildError[]) => void,
};

/**
 * Represents a UI component that renders the publish document section.
 */
export const DocumentSection = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [build, setBuild] = useState<Domain.Publisher.DocumentBuild>();
    const [showPublishConfiguration, setShowPublishConfiguration] = useState<boolean>(false);
    const [publishButtonDisabled, setPublishButtonDisabled] = useState<boolean>(false);
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [showTimeoutMessage, setShowTimeoutMessage] = useState(false);

    useEffect(() => {
        DataAccess.Builds.getPublicationDocumentBuild(props.publicationId)
            .then((response) => {
                setError(undefined);
                setBuild({
                    lastRun: response.data.lastRun,
                    lastRunStatus: response.data.lastRunStatus,
                    lastSuccessfulRun: response.data.lastSuccessfulRun,
                    documentFile: response.data.documentFile,
                    buildErrors: response.data.buildErrors,
                });
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true));
            });
    }, [props.publicationId, lastRefresh]);

    const generateDocument = (publishProfileId: string) => {
        setPublishButtonDisabled(true);

        DataAccess.Builds.generatePublicationDocument(props.publicationId, publishProfileId)
            .then((response) => {
                setBuild(response.data);
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err);

                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.AxiosTimeout)) {
                    setShowTimeoutMessage(true);
                } else if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed)) {
                    setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed] });
                }
                else {
                    setError(errorInfo);
                }
            }).finally(() => {
                setShowPublishConfiguration(false);
                setPublishButtonDisabled(false);
            });
    };

    const downloadDocument = (): void => {
        AttachmentsHelper.loadExistingAttachment(build.documentFile.id, props.attachments, props.setAttachment).then(file => {
            saveAs(file, build.documentFile.name);
        });
    };

    const canGenerate = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_PublishPublication);
    return (
        <>
            <Section look='white'>
                <ErrorOverlay error={error?.message} errorDetails={error?.details}
                    onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <SectionHeader ref={ref} anchor='Document'>Document</SectionHeader>
                    {build && <Styled.PublishContent>
                        <Styled.PublishWrap>
                            <BasicText>Genereer een tekst document van de huidige publicatie</BasicText>
                            <Button
                                btnbase='primarybuttons'
                                btntype='medium_noicon'
                                aria-label='Document genereren'
                                onClick={() => setShowPublishConfiguration(true)}
                                style={{ marginRight: '16px' }}
                                disabled={!canGenerate || publishButtonDisabled}>
                                Document genereren
                            </Button>
                            <Button
                                btnbase='textbuttons'
                                btntype='medium_noicon'
                                aria-label='Open de gepubliceerde website in een nieuw tabblad'
                                onClick={downloadDocument}
                                disabled={!build?.documentFile}>
                                Download document
                            </Button>
                            <Styled.FormWrap>
                                <LayoutForm>
                                    <LayoutField key={lastRunId} left={1} top={1} width={6} height={1}>
                                        <TextElement
                                            id={lastRunId}
                                            label='Datum gepubliceerd'
                                            value={DateUtils.formatDateTime(build.lastRun)} />
                                    </LayoutField>
                                    <LayoutField key={lastRunStatusId} left={1} top={2} width={6} height={1}>
                                        <TextElement
                                            id={lastRunStatusId}
                                            label='Huidige status'
                                            value={buildStatusTranslation[build.lastRunStatus]} />
                                    </LayoutField>
                                </LayoutForm>
                                {!!build?.buildErrors?.length &&
                                    <Button
                                        btnbase='primarybuttons'
                                        btntype='small_noicon'
                                        aria-label='Foutenlog'
                                        onClick={() => props.setErrors(build.buildErrors)}
                                    >
                                        Foutenlog
                                    </Button>}
                            </Styled.FormWrap>
                        </Styled.PublishWrap>
                        <Styled.ThumbWrap>
                            <ThumbnailImg variant='attachedFile' />
                        </Styled.ThumbWrap>
                    </Styled.PublishContent>}
                </ErrorOverlay>
            </Section>
            {showPublishConfiguration &&
                <PublishConfiguration
                    publishButtonDisabled={publishButtonDisabled}
                    onPublishDocument={generateDocument}
                    onCloseDialog={() => setShowPublishConfiguration(false)}
                />}
            {showTimeoutMessage &&
                <TimeoutMessageDialog title='Document genereren' text='Het genereren neemt wat meer tijd in beslag, kom later terug om te kijken of het proces afgerond is.' onCloseDialog={() => setShowTimeoutMessage(false)} />}
        </>
    );
});

const lastRunId = 'docVersion-lastRun';
const lastRunStatusId = 'docVersion-lastRunStatus';
