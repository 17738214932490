import { fieldDefinitionDecorator } from '../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../Shared/FieldDefinition';

/**
 * Represents a datastore element.
 */
class DataStoreElement {

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Name)
    name: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Type)
    typeId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ConnectionInformation)
    connectionInformation: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.SourceFile)
    sourceFileId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataPlatformView)
    dataPlatformView: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.HierarchyDefinition)
    hierarchyDefinition: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.FinanceTable)
    financeTable: string;
}

export default DataStoreElement;