import React, { useRef, useState } from 'react';
import { Dictionary } from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { PageTitle, Heading1 } from '@liasincontrol/ui-basics';
import { AnyFormData, ValidatorsDictionary } from '@liasincontrol/core-service';
import { PublicationSection } from './PublicationSection';
import { GeneralSection } from './PublicationSection/GeneralSection';
import { IdentitySection } from './PublicationSection/IdentitySection';
import { SettingsSection } from './PublicationSection/SettingsSection';
import { PublicationSettingsWrapper } from '../../../SectionMenu';
import { WebinfoSection } from './PublicationSection/WebinfoSection';

export enum PubSections {
    General = 1,
    Identity = 2,
    Settings = 3,
    Webinfo = 4,
}

type Props = {
    activeRichtextEditor: string,
    elementDefinition: Domain.Shared.ElementDefinition,
    workflowTemplates: Domain.Shared.WorkflowTemplateWithStates[],
    measureMoments: Domain.Shared.MeasureMoment[],
    siteDesigns: Domain.Publisher.SiteDesign[],
    isEditing: boolean,
    form: AnyFormData,
    validators: ValidatorsDictionary,
    onLoadAttachment: (id: string) => Promise<Blob>,
    onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    setActiveRichtextEditor: (toolbarId: string) => void,
    onChange: (value: string, fieldDefinitionId: string, resetExternalErrorFieldSystemIds?: string[]) => void,
    onError?: (error: string, fieldSystemId: string, fieldValue?: string) => void,
};

/**
 * Represents a UI component that renders the publication sections with a sidemenu on the left side and collapsable section on the right side.
 */
export const PublicationSettings: React.FC<Props> = (props) => {
    const [collapsedSections, setCollapsedSections] = useState<PubSections[]>([]);
    const sectionRefs = {
        [PubSections.General]: useRef<HTMLDivElement>(null),
        [PubSections.Identity]: useRef<HTMLDivElement>(null),
        [PubSections.Settings]: useRef<HTMLDivElement>(null),
        [PubSections.Webinfo]: useRef<HTMLDivElement>(null),
    };

    const onToggleSection = (section: PubSections): void => {
        const newCollapsedSections = [...collapsedSections];
        if (newCollapsedSections.includes(section)) {
            newCollapsedSections.splice(newCollapsedSections.indexOf(section), 1);
        } else {
            newCollapsedSections.push(section);
        }
        setCollapsedSections(newCollapsedSections);
    };

    const fieldDefinitions: Dictionary<Domain.Shared.FieldDefinition> = props.elementDefinition.fields.reduce(
        (collection, item) => ({ ...collection, [item.systemId]: item }),
        {}
    );

    return (
        <>
            {props.isEditing && (
                <PageTitle >
                    <Heading1>Wijzig algemene instellingen</Heading1>
                </PageTitle>
            )}
            <PublicationSettingsWrapper isEditing={props.isEditing}>
                <PublicationSection
                    ref={sectionRefs[PubSections.General]}
                    section={PubSections.General}
                    sectionName="Algemeen"
                    collapsedSections={collapsedSections}
                    onToggleSection={onToggleSection}>
                    <GeneralSection
                        fieldDefinitions={fieldDefinitions}
                        workflowTemplates={props.workflowTemplates}
                        siteDesigns={props.siteDesigns}
                        measureMoments={props.measureMoments}
                        isEditing={props.isEditing}
                        form={props.form}
                        validators={props.validators}
                        onChange={(value, fieldDefinition) => {
                            const noEmptyStringValue = value === "" ? undefined : value;
                            props.onChange(noEmptyStringValue, fieldDefinition);
                        }}
                    />
                </PublicationSection>
                <PublicationSection
                    ref={sectionRefs[PubSections.Identity]}
                    section={PubSections.Identity}
                    sectionName="Huisstijl - web"
                    collapsedSections={collapsedSections}
                    onToggleSection={onToggleSection}>
                    <IdentitySection
                        fieldDefinitions={fieldDefinitions}
                        isEditing={props.isEditing}
                        form={props.form}
                        validators={props.validators}
                        onLoadAttachment={props.onLoadAttachment}
                        onUploadAttachment={props.onUploadAttachment}
                        onError={props.onError}
                        onChange={props.onChange}
                    />
                </PublicationSection>
                <PublicationSection
                    ref={sectionRefs[PubSections.Settings]}
                    section={PubSections.Settings}
                    sectionName="Huisstijl - print document"
                    collapsedSections={collapsedSections}
                    onToggleSection={onToggleSection}>
                    <SettingsSection
                        fieldDefinitions={fieldDefinitions}
                        isEditing={props.isEditing}
                        form={props.form}
                        validators={props.validators}
                        onLoadAttachment={props.onLoadAttachment}
                        onUploadAttachment={props.onUploadAttachment}
                        onError={props.onError}
                        onChange={props.onChange}
                    />
                </PublicationSection>
                <PublicationSection
                    ref={sectionRefs[PubSections.Webinfo]}
                    section={PubSections.Webinfo}
                    sectionName="Web informatie"
                    collapsedSections={collapsedSections}
                    onToggleSection={onToggleSection}>
                    <WebinfoSection
                        activeRichtextEditor={props.activeRichtextEditor}
                        fieldDefinitions={fieldDefinitions}
                        isEditing={props.isEditing}
                        form={props.form}
                        validators={props.validators}
                        setActiveRichtextEditor={props.setActiveRichtextEditor}
                        onChange={props.onChange}
                    />
                </PublicationSection>
            </PublicationSettingsWrapper>
        </>
    );
};
