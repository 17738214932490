import styled from 'styled-components';
import SharedStyled from '../../SharedControlElements/index.styled';

const Text = styled.div<{
    headerColor: string,
    headerBackgroundColor: string,
    textColor: string,
    textFontSize: string,
    h1FontColor: string,
    h1FontSize: number,
    h2FontColor: string,
    h2FontSize: number,
    h3FontColor: string,
    h3FontSize: number
}>`
    overflow-x: auto;

    && p, ol li, ul li {
        color: ${(p) => (p.textColor && p.textColor)};
        font-size: ${(p) => (p.textFontSize && `${p.textFontSize}px`)};
    }

    table {
        width: 100 %;
        border-collapse: collapse;

        thead > tr {
            background-color: ${(p) => (p.headerColor && p.headerColor)};
            font-weight: 700;
            border-bottom: 2px solid #cccccc;

                p {
                color: ${(p) => (p.headerBackgroundColor && p.headerBackgroundColor)};
            }
        }

        td, th {
            border: 1px solid #cccccc;
            padding: 5px;

                p {
                font-size: 14px!important;
                margin-bottom: 0!important;
                padding: 3px;
            }
        }
    }
`;


const TextControlWrapper = styled(SharedStyled.GridWrapper)`
    .dx-item-content, .dx-data-row {   
        table {
        table-layout: auto;
    }
}`;

const styledComponents = { Text, TextControlWrapper };
export default styledComponents;