import { useSelector } from 'react-redux';
import { AjaxRequestStatus, AnnouncementActionCreator, State, useAppDispatch } from '../ReduxService';

export const useAnnouncement = () => {

    const dispatch = useAppDispatch();
    const announcement = useSelector((state: State) => state.announcements);

    if (!announcement?.status || announcement?.status === AjaxRequestStatus.NotSet) {
        //this warning will be fixed in another PBI (the refactor redux PBI)
        dispatch(AnnouncementActionCreator.set() as any);
    }

    return announcement;
};