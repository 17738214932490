
import React from 'react';
import { IListItemProps } from './Props';

import styled, { css } from 'styled-components';

import ClearIcon from '@mui/icons-material/Clear';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { cssFontNormal, px } from '../../style';

interface IItemPresentationProps {
    readonly separator: 'auto' | true | false;
    readonly isActive: boolean;
    readonly height?: number;
    readonly isDisabled: boolean;
    readonly canAdd?: boolean;
    readonly canDrag?: boolean;
    readonly canDelete?: boolean;
    readonly iconColor?: { default: string, active?: string; hover?: string; disabled?: string; };
    readonly noBackground?: boolean;
}

const cssSeparatorAuto = css`
    &:nth-child(n+2) {
        border-top: 1px solid #f8f9fe;
    }
`;

const cssSeparatorOn = css`
    border-top: 1px solid #f4f4f8;
`;

const defaultIconColors = {
    default: '#414141',
    active: '#4b55f6',
    hover: '#30379D',
    disabled: '#8A8991'
};

/* declared first because referenced in Li */
const IconWrap = styled.span`
    float: left;
    padding: 0; 
    margin-right: ${px(8)};
    vertical-align: baseline;
    line-height: 1;
    height: 18px;
    > .MuiSvgIcon-root {
        margin-top:-4px;
        fill: ${(p: IListItemProps) =>
        ((p.disabled) && (p.iconColor?.disabled || defaultIconColors.disabled))
        || ((p.canAdd) && (p.iconColor?.active || defaultIconColors.active))
        || ((p.canDrag) && (p.iconColor?.active || defaultIconColors.active))
        || (p.iconColor?.default || defaultIconColors.default)
    };
    }
    &:hover {}
`;

const DeleteWrap = styled.span`
    position:absolute;
    top: 0;
    display: inline-block;
    padding:0 14px 0 14px;
    line-height: ${px(65)};
    ${({ height }: IListItemProps) => (
        !height ? null : css`
            line-height: ${px(height)};
    `)};
    background-color:transparent;
    box-shadow: inset 3px 0 0 0 #fff;
    transition:right 0.3s ease;

    > .MuiSvgIcon-root { fill: #4b55f6; }
    &:hover {
        > .MuiSvgIcon-root { fill: #30379D; }
    }
`;

const TxtLabel = styled.span`
    display: inline-block;
`;

const Li = styled.li`
    position:relative;
    padding: ${px(16)} ${px(24)};
    ${cssFontNormal(14, 18, 30)}
    text-align:left;
    white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
    transition:background-color 0.4s, padding-right 0.4s ease;

    font-weight: ${(p: IItemPresentationProps) => (p.isActive ? '700' : '400')};
    box-shadow: inset 4px 0 0 0 ${(p: IItemPresentationProps) => (p.isActive ? '#4b55f6' : 'transparent')};
    cursor: ${(p: IItemPresentationProps) => (p.isDisabled ? 'default' : 'pointer')};

    ${({ height }: IListItemProps) => (
        !height ? null : css`
            height: ${px(height)};
            line-height: ${px(height)};
    `)};
    color: ${(p: IItemPresentationProps) => ((p.isDisabled) && '#8A8991') || ((p.canAdd) && '#4b55f6') || '#414141'};
    
    background-color: ${(p: IItemPresentationProps) => (p.noBackground ? 'inherit' : ((p.isDisabled || p.isActive) && '#f8f9fe') || '#fff')};

    ${({ separator }: IListItemProps) => {
        switch (separator) {
            case false:
                return null;
            case true:
                return cssSeparatorOn;
            default:
                return cssSeparatorAuto;
        }
    }}

    /* ani delete icon */
    > ${DeleteWrap} { right:-60px; }

    &:hover {
        color: ${(p: IItemPresentationProps) =>
        ((p.isDisabled) && '#8A8991')
        || ((p.canAdd) && '#30379D')
        || '#414141'
    };
        background-color: ${(p: IItemPresentationProps) =>
        ((p.isDisabled || p.isActive) && '#f8f9fe')
        || ((p.canAdd) && '#fff')
        || '#f8f9fe'
    };
        /* ani delete icon and overflow long items */
        padding-right: ${({ canDelete }: IItemPresentationProps) => (canDelete ? '66px' : '16px')};
        > ${DeleteWrap} {
            right:0;
        }
        > ${IconWrap} > .MuiSvgIcon-root {
            fill: ${(p: IItemPresentationProps) =>
        ((p.isDisabled) && (p.iconColor?.disabled || defaultIconColors.disabled))
        || ((p.canAdd || p.canDrag) && (p.iconColor?.hover || defaultIconColors.hover))
        || (p.iconColor?.default || defaultIconColors.default)

    };
        }
    };
`;

export const ListItem = ({ id, separator, active, label, height, icon, iconColor, children, disabled, noBackground, canAdd, canDrag, canDelete, onClick, onDrag, onDelete }: IListItemProps) => {
    let iconWrap = null;
    switch (canDrag) {
        case true:
            iconWrap = <IconWrap id={`btn-drag-${id}`} disabled={disabled} canDrag={true} onDrag={onDrag} iconColor={iconColor}><DragIndicatorIcon /></IconWrap>;
            break;
        default:
            iconWrap = icon ? <IconWrap id={`btn-add-${id}`} disabled={disabled} canAdd={canAdd} iconColor={iconColor}>{icon}</IconWrap> : null;
            break;
    }
    const txtLabel = label ? <TxtLabel>{label}</TxtLabel> : null;
    const deleteWrap = canDelete ? <DeleteWrap id={`btn-delete-${id}`} onClick={onDelete}><ClearIcon /></DeleteWrap> : null;

    return (
        <Li id={`listitem-${id}`} isActive={active} height={height} separator={separator} isDisabled={disabled} noBackground={noBackground} canAdd={canAdd} canDelete={canDelete} onClick={onClick} role="button" iconColor={iconColor}>
            {iconWrap}
            {txtLabel}
            {children}
            {deleteWrap}
        </Li>
    );
};
