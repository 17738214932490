import React from 'react';
import { ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';

type Props = {
    elementDefinition: Domain.Shared.ElementDefinition,
    onConfirm: (elementDefinitionId: string) => void,
    onCancel: () => void,
};

/**
 * Represents an UI component that opens a confirmation dialog to deactivate an element definition.
 */
const DeactivateElementDefinitionDialog: React.FC<Props> = (props) => {

    return (
        <ModalDialog
            id='deactivate-element-definition-dialog'
            settings={{
                look: 'message',
                title: 'Uitschakelen elementdefinities',
                footer: <ModalDialogFooter leftButtonText='Annuleren' onLeftButtonClick={props.onCancel} rightButtonText='Uitschakelen' onRightButtonClick={() => props.onConfirm(props.elementDefinition.id)} />
            }}
        >
            <Text danger={true} value={`Weet u zeker dat u de definitie voor ${props.elementDefinition.name} wilt uitschakelen?`} />
        </ModalDialog>
    );
};

export { DeactivateElementDefinitionDialog };