import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class DataSourceTextControl extends BaseControlElement {

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSourceTitleColumn)
    title: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSourceTextColumn)
    column: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Filter)
    filter: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSource)
    dataSourceId: string;

}

export default DataSourceTextControl;
