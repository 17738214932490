/* tslint:disable:max-line-length */
import React from 'react';

import styled from 'styled-components';
import liasLogo from './imgs/logo.png';
import liasLogoInverted from './imgs/logoInverse.png';
import { palette } from '../styleguide';

interface ILogoProps {
    readonly look?: 'default' | 'inverted';
}

const LogoWrap = styled.button`
    display: inline-grid;
    grid-template: "icon title" "icon subtitle" / 64px auto;
    background-color:${(p: ILogoProps) => p.look === 'inverted' ? '#4b55f6' : '#fff'};
    border: none;
    &:hover {
        cursor:pointer;
    }
    &:active {
        border: none;
        outline: 0;
    }
`;

const LogoIcon = styled.i`
    grid-area: icon;
    display: block;
    width: 40px;
    height: 32px;
    background: ${(p: ILogoProps) => (p.look === 'inverted' ? `transparent url(${liasLogoInverted}) no-repeat` : `transparent url(${liasLogo}) no-repeat`)};
    align-self: center;
    justify-self: left;
`;
const Title = styled.div`
    grid-area: title;
    color: ${(p: ILogoProps) => p.look === 'inverted' ? palette.white : palette.grey2};
    font-size:1rem;
    font-weight: 600;
    font-family: Nunito;
    line-height:1rem;
    padding-top:5px;
`;

const SubTitle = styled.div`
    text-align:left;
    grid-area: subtitle;
    color: ${(p: ILogoProps) => p.look === 'inverted' ? palette.white : palette.grey3};
    font-size: 0.75rem;
    font-weight: 400;
    font-family: Nunito;
    line-height: 0.875rem;
`;

interface IProps {
    readonly children?: React.ReactNode;
    readonly look?: 'default' | 'inverted';
    readonly customer: string;
    readonly goHome: () => void;
}

export const Logo: React.FC<IProps> = (props) => {

    const { look, customer, goHome } = props;

    const handleOnclick = (event) => {
        event.currentTarget.blur();
        goHome();
    }

    return (
        <LogoWrap look={look} className="logo-wrap" onClick={handleOnclick} title="Naar LIAS startpagina" aria-label="Naar LIAS startpagina">
            <LogoIcon look={look} className="logo-icon" ></LogoIcon>
            <Title look={look} className="logo-title">LIAS InControl</Title>
            <SubTitle look={look} className="logo-subtitle">{customer}</SubTitle>
        </LogoWrap>
    );
}
