import { Button, Heading1, px } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

interface ILeftSideBarPanel {
    readonly hasPadding: boolean;
}

const LeftSideBarPanel = styled.div`
    height: calc(100% - 64px);
    overflow-y: auto;
    width: ${px(500)};
    padding: ${(p: ILeftSideBarPanel) => p.hasPadding ? `${px(32)} ${px(40)} ${px(32)} ${px(40)}` : `0`};

    ::-webkit-scrollbar {
        width: ${px(15)};
    }
    ::-webkit-scrollbar-track {
        background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #ececf3;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #e3e3ec;
    }
    ::-webkit-scrollbar-button,
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
`;

const EdgeToolbarContent = styled.div`
    margin: ${px(24)};
`;

const ToolbarTitle = styled(Heading1)`
    display: block;
    margin: 0 0 ${px(24)} 0;
`;

const TabsPlaceholder = styled.div`
    background-color: #FFF;
    grid-column: 2;
    grid-row: 1 / -1;
`;

const EdgeToolbarButton = styled(Button)`
    display: block !important;
    margin-bottom: ${px(16)} !important;
`;

const SingleValueWrapper = styled.div`
    display: flex;
    align-items: center;
`;

const RowWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const SingleValueLabel = styled.div`
    margin-left: ${px(3)};
`;

const StaticFieldValue = styled.div`
    min-height: ${px(48)};
    padding: ${px(12)} 0;
    white-space: pre-wrap;
`;

const OptionWrapper = styled.div`
  padding-top: ${px(16)};
`;

const CustomWrapper = styled.div`
    max-height: 30vh;
    overflow-y: auto;
    padding-left: ${px(8)};
    &::-webkit-scrollbar {
        width: ${px(10)};
    }
    &::-webkit-scrollbar-track {
        width: ${px(10)};
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        width: ${px(10)};
        border-radius: ${px(10)};
        background-color: #ececf3;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #e3e3ec;
    }
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-corner {
        width: ${px(10)};
        height: ${px(1)};
        background-color: transparent;
    }
`;
export {
    LeftSideBarPanel, EdgeToolbarContent, ToolbarTitle, EdgeToolbarButton, TabsPlaceholder, SingleValueWrapper, SingleValueLabel, RowWrapper, StaticFieldValue, OptionWrapper, CustomWrapper
};