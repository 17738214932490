import { createAsyncThunk } from '@reduxjs/toolkit';
import { Shared as SharedDataAccess } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';

const action = createAsyncThunk<Domain.Shared.MeasureMoment[], void, {}>('/measuremoments/set', async () =>
    SharedDataAccess.MeasureMomentsDataAccess.getAll().then((response) => {
        return response;
    })
);

export default action;