import React from 'react';
import { Text, ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';

type Props = {
    title?: string,
    text?: string,
    allowOperation?: boolean,
    disableConfirmation: boolean,
    look: 'interactive' | 'message',
    confirmButtonText: string,
    cancelButtonText?: string;
    onConfirm: () => void,
    onCancel: () => void,
};

/**
 * Represents a UI component that renders a Confirm modal window.
 */
export const ConfirmDialog: React.FC<Props> = (props) => {
    return (
        <ModalDialog
            settings={{
                look: props.look,
                title: props.title,
                footer:
                    props.allowOperation ?
                        <ModalDialogFooter
                            leftButtonText={props.cancelButtonText || "Annuleren"}
                            rightButtonText={props.confirmButtonText}
                            onLeftButtonClick={props.onCancel}
                            onRightButtonClick={props.onConfirm}
                            rightButtonDisabled={props.disableConfirmation} /> :
                        <ModalDialogFooter
                            rightButtonText="Sluiten"
                            onRightButtonClick={props.onCancel} />
            }}
        >
            <Text value={props.text} />
        </ModalDialog>
    );
};

ConfirmDialog.defaultProps = {
    allowOperation: true,
}