import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Dictionary } from 'lodash';
import { Map, MapMarkers } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import { FieldsHelper } from '@liasincontrol/core-service';
import Styled from './index.styled';
import SharedStyled from '../../SharedControlElements/index.styled';
import { DataSourceControlsUtils, PublicationContext } from '../../../../../../helpers';
import { AppSettingsService } from '@liasincontrol/config-service';
import { CreateCustomDataSource, ILsColumnProps } from '@liasincontrol/ui-devextreme';
import _ from 'lodash';

type Props = {
    element: Domain.Publisher.ElementNode,
    selectedElementId: string,
    elementList: Dictionary<Domain.Publisher.Element>,
    publicationElement: Domain.Publisher.PublicationElement,
    readonly: boolean,
    variables?: Domain.Shared.ComplexFieldItem[],
    hasErrorInSettings?: (elementId: string, hasError: boolean, message?: string, keepError?: boolean) => void,
    onLoadAttachment: (id: string) => Promise<Blob>,
    getElementDefinition: (systemElementId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
}

class MapSettings extends Domain.Publisher.MapControl {
    sizeValue: number;
}

const MapControl: React.FC<Props> = (props) => {
    const { element, elementList, getElementDefinition, hasErrorInSettings, onLoadAttachment } = props;
    const mapElement = elementList?.[element.elementId];
    const [dataSource, setDataSource] = useState<Domain.Publisher.DataSourceElement>();
    const [dataSourceData, setDataSourceData] = useState<{ data: [], fields: ILsColumnProps[] }>();
    const [markers, setMarkers] = useState<MapMarkers[]>();

    const pubContext = useContext(PublicationContext);

    const controlSettings: MapSettings = useMemo(() => {
        if (!mapElement) return;

        const definition = getElementDefinition(mapElement.elementDefinitionSystemId, mapElement.elementDefinitionId);
        const controlSettings: MapSettings = new MapSettings();

        FieldsHelper.mapObject<Domain.Publisher.MapControl>(controlSettings, definition.fields, mapElement.fields);
        const mapSizeOption = FieldsHelper.mapFieldOption<Domain.Publisher.MapControl>(controlSettings, 'size', definition);
        controlSettings.sizeValue = mapSizeOption?.value === undefined ? 1 : mapSizeOption?.value; //0 is falsy but 1 is default
        return controlSettings;
    }, [mapElement]);

    useEffect(() => {
        const loadDataSourceAsync = async () => {
            if (controlSettings?.dataSourceId) {
                const dsElement = await pubContext.loadDataSourceElement(controlSettings.dataSourceId);
                setDataSource(dsElement);
            } else {
                setDataSource(undefined);
            }
        };
        loadDataSourceAsync();
    }, [controlSettings?.dataSourceId]);

    useEffect(() => {
        hasErrorInSettings?.(element.elementId, false);
        if (!dataSource?.dataFileId || !dataSource?.schemaFileId || dataSource?.failedRefresh) {
            if (dataSource?.failedRefresh) hasErrorInSettings?.(element.elementId, true, 'Fout opgetreden bij het verversen.', true);
            setDataSourceData(undefined);
        } else {
            const loadBlobsAsync = async () => {
                try {
                    const responses = await Promise.all([
                        onLoadAttachment(dataSource.dataFileId),
                        onLoadAttachment(dataSource.schemaFileId)
                    ]);
                    const blobs = await Promise.all([
                        responses[0].text(),
                        responses[1].text()
                    ]);
                    const data = JSON.parse(blobs[0]);
                    const fields = DataSourceControlsUtils.mapsDataSourceColumnFields(
                        JSON.parse(blobs[1]),
                        AppSettingsService.getAppSettings().Publisher.Controls.DataTable.NumberOfVisibleColumns
                    );

                    setDataSourceData({ data, fields });
                } catch (e) {
                    props.hasErrorInSettings?.(element.elementId, true, 'Databron fout.');
                }
            };

            loadBlobsAsync();

        }
    }, [element.elementId, dataSource?.id, dataSource?.dataFileId, dataSource?.schemaFileId, dataSource?.failedRefresh, onLoadAttachment]);

    useEffect(() => {

        if (!controlSettings || !controlSettings?.filter || !controlSettings.latitudeColumn || !controlSettings.longitudeColumn || !dataSourceData?.data) {
            return;
        }

        const latitudeColumn = dataSourceData.fields.find(item => item.dataField === controlSettings.latitudeColumn) || {};
        const longitudeColumn = dataSourceData.fields.find(item => item.dataField === controlSettings.longitudeColumn) || {};
        const pinLabelColumn = dataSourceData.fields.find(item => item.dataField === controlSettings.labelColumnName) || {};

        const filter = DataSourceControlsUtils.getFilter(controlSettings.filter, props.variables);
        const dSource = CreateCustomDataSource(dataSourceData?.data, undefined, undefined, filter, undefined, true);

        dSource.load().then((res) => {
            const tmpMarkers = res.reduce((coordinates, row) => {
                const latitudeData = row[latitudeColumn.dataField];
                const longitudeData = row[longitudeColumn.dataField];
                const pinContent = row[pinLabelColumn.dataField];

                // the case for 0 is forbidden also, because neither lat = 0 nor lon = 0 go through the Netherlands. 
                if (!latitudeData || !longitudeData) {
                    return coordinates;
                }

                return [...coordinates, { coordinates: [latitudeData, longitudeData], pinContent }];
            }, []);

            setMarkers(tmpMarkers);
        });

    }, [dataSourceData?.data, controlSettings.filter, controlSettings.labelColumnName, controlSettings.latitudeColumn, controlSettings.longitudeColumn]);

    return (
        <SharedStyled.GridWrapper
            primaryColor={props.publicationElement.primaryColor}
            primaryTextColor={props.publicationElement.primaryContrastColor}
            textColor={props.publicationElement.bodyFontColor}
            textFontSize={props.publicationElement.bodyFontSize}
            editMode={!props.readonly}
            className='dx-widget'
        >
            {controlSettings.title && <SharedStyled.Title h3FontSize={props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}>{controlSettings.title}</SharedStyled.Title>}
            {controlSettings.description && <SharedStyled.Description textFontColor={props.publicationElement?.bodyFontColor} textFontSize={props.publicationElement.bodyFontSize}>{controlSettings.description} </SharedStyled.Description>}
            <Styled.Content>
                <Map id={element.elementId} markers={markers} interactive={props.readonly} size={controlSettings.sizeValue} />
            </Styled.Content>
        </SharedStyled.GridWrapper>
    );
};

export default React.memo(MapControl, (prevProps, nextProps) => {
    return _.isEqual(prevProps.elementList[prevProps.element.elementId], nextProps.elementList[nextProps.element.elementId]) && prevProps.variables === nextProps.variables;
});
