import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import * as Domain from '@liasincontrol/domain';
import { Icon, IconValue, IconSize, IconSet, Button, MultiSelectItem, FlexBox } from '@liasincontrol/ui-basics';
import { GridColumn, LsGrid, createSource } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { Finance, oDataResponseStructured } from '@liasincontrol/data-service';
import { ConnectingDialog } from '@liasincontrol/ui-elements';
import Styled from './index.styled';

type Props = {
    budgetJournalKindId: string,
    workflowDefinitionId: string,
    users: Domain.Shared.User[],
    pageSize?: number,
    externalRefresh?: any, //external refresh source, intentionally any
    onError: (err: any) => void,
};

const standardColumns: {
    name: string,
    title: string,
    hidden: boolean,
    width: string,
}[] = [
        {
            name: 'budgetElementGroupName',
            title: 'Budgetelementgroep',
            hidden: false,
            width: '30%',
        },
        {
            name: 'budgetElementGroupCode',
            title: 'Code',
            hidden: false,
            width: '10%',
        }
    ];

/**
 * Represents a UI component that renders a component with the list of BudgetElementGroups of a BudgetJournalKind.
 */
export const BudgetJournalKindGroups: React.FC<Props> = (props) => {
    const [availableColumns, setAvailableColumns] = useState<GridColumn<any>[]>([]);
    const [assigned, setAssigned] = useState<{ loaded: boolean; userIds: string[] }>({ loaded: true, userIds: [] });
    const [assignDialog, setAssignDialog] = useState<{ isVisible: boolean; groupId?: string; statusId?: string }>({ isVisible: false, groupId: undefined, statusId: undefined });
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());

    const possibleContributors: MultiSelectItem[] = useMemo(() => {
        return _.orderBy(props.users.filter(user => user.isInGroup),
            [user => _.toLower(user.name)])?.map((user) => ({ label: user.name || user.email, value: false, id: user.id }))
    }, [props.users]);

    const assignedContributors: MultiSelectItem[] = useMemo(() => {
        return _.orderBy(props.users.filter(user => user.isInTenant),
            [user => _.toLower(user.name)])?.map((user) => ({ label: user.name || user.email, value: false, id: user.id, disabled: !user.isInGroup }))
    }, [props.users]);

    const customDataSource = useMemo(() => {
        if (!props.budgetJournalKindId) {
            return;
        }
        const thenCallBack = (data: oDataResponseStructured<Domain.Finance.BudgetJournalKindItemGroups>) => {
            const columns: GridColumn<any>[] = data.columns
                .map((c: Domain.Finance.BudgetJournalColumnMetadata) => {
                    const standard = standardColumns.find(col => col.name === c.name);
                    if (standard || c.name === 'budgetElementGroupId') return null;
                    return {
                        name: c.name,
                        title: c.title,
                        align: `left` as `left` | `right` | `center`,
                        renderCustom: (item) => renderCustomCellTemplate(item.data, c),
                    }
                })
                ?.filter(x => !_.isEmpty(x));

            setAvailableColumns([...standardColumns, ...columns]);

            return data.values;
        };
        return createSource<Domain.Finance.BudgetJournalKindItemGroups>({
            keyExpr: 'budgetElementGroupId',
            paginate: true,
            dataSourceMode: 'custom',
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            dataSourcePromise: (query) => Finance.BudgetJournalKindDataAccessor.getGroups(props.budgetJournalKindId, query),
            thenCallBack,
        });
    }, [props.budgetJournalKindId, props.workflowDefinitionId, lastRefresh, props.externalRefresh])

    useEffect(() => {
        setAssigned({ loaded: false, userIds: [] });
        if (assignDialog.isVisible && assignDialog.groupId) {
            Finance.BudgetJournalKindDataAccessor.getAssignedUsers(props.budgetJournalKindId, assignDialog.groupId, assignDialog.statusId)
                .then((response) => {
                    const userIds = response.data;
                    setAssigned({ loaded: true, userIds: userIds });
                })
                .catch((exception) => {
                    setAssigned({ loaded: true, userIds: [] });
                    props.onError(exception);
                });
        }
    }, [possibleContributors, assignDialog, lastRefresh]);

    const renderCustomCellTemplate = (item, column) => {
        return (<FlexBox>
            <Icon value={IconValue.Customer} size={IconSize.small} iconSet={IconSet.default} />
            <div>{item[column.name] || 0} </div>
            <Button
                id={`btn-add-contributor-${item.id}`}
                btnbase="iconbuttons"
                btntype="small_transparent"
                aria-label={`Gebruikers koppelen ${column.name}`}
                onClick={() => {
                    toogleModal(item.budgetElementGroupId, column.id);
                }}
                icon={<CreateOutlinedIcon />}
            />
        </FlexBox>);
    };

    const isAlreadyAssigned = (userId: string, userIds: string[]): boolean => {
        return (userIds.find(assigned => assigned === userId) ? true : false);
    };

    const toogleModal = (groupId, statusId) =>
        setAssignDialog({
            isVisible: true,
            groupId: groupId,
            statusId: statusId,
        });

    const assignUsers = (users: MultiSelectItem[], groupId, stateId) => {
        const postedData: Domain.Dto.Finance.UpdateBudgetJournalKindTask = {
            budgetElementGroupId: groupId,
            workflowStateId: stateId,
            assignments: users.map((e: MultiSelectItem) => e.id),
        };

        Finance.BudgetJournalKindDataAccessor.setAssignedUsers(props.budgetJournalKindId, postedData).then(() => {
            setLastRefresh(Date.now());
        }).catch((exception) => {
            props.onError(exception);
        });
        setAssignDialog({ isVisible: false });
    };

    return (
        <>
            <Styled.Label>Workflow per budgetelementgroep</Styled.Label>
            <LsGrid
                dataSource={customDataSource}
                showRowLines={true}
                columns={availableColumns}
                paging={{ totalCount: customDataSource.items().length, pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                onDataError={props.onError}
            />
            {assignDialog.isVisible && assigned.loaded && <ConnectingDialog
                title={`Gebruikers koppelen`}
                disableSaveButton={false}
                listItems={possibleContributors?.filter((user) => !isAlreadyAssigned(user.id, assigned.userIds)) || []}
                selectedItems={assignedContributors?.filter((user) => isAlreadyAssigned(user.id, assigned.userIds)) || []}
                onCancelled={() => setAssignDialog({ isVisible: false })}
                onConfirmed={(users) => {
                    assignUsers(users, assignDialog.groupId, assignDialog.statusId);
                }}
            />}
        </>
    );
};
