import styled from 'styled-components';
import { px, palette, cssHeading1, cssBasicText } from '@liasincontrol/ui-basics';
import CloseIcon from '@mui/icons-material/Close';

const Content = styled.div`
    grid-column: 2 / span 12;
    grid-row: 7 / -1;
    display: grid;
    grid-template-rows: ${px(20)} minmax(0, 60vh) ${px(20)};
    grid-gap: ${px(8)} ${px(20)};
    position: relative;
`;

const Title = styled.h2`
    ${cssHeading1}
    margin-bottom: 20px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: ${px(4)};
    height: 100%;
`;

const ButtonWrapper = styled.div`
    width: ${px(200)};
    padding: ${px(10)} 0;
`;

const RemoveContributorIcon = styled(CloseIcon)`
    fill: ${palette.validationNeg};
    width: ${px(16)};
    height: ${px(16)};
    margin: ${px(3)} ${px(5)} 0 ${px(5)};
    cursor: pointer;
`;

const ContributorItem = styled.div`
    ${cssBasicText}
    display: flex;
    justify-content: space-between;
    width: ${px(400)};
    padding: ${px(2)};
    border-bottom: ${px(2)} solid ${palette.grey4};
`;

const ContributorList = styled.div`
    display: flex;
    width: fit-content;
`;

const InputCheckboxWrapper = styled.div`
    padding: ${px(20)} 0;
`;

const styledComponents = { Content, Title, Wrapper, ButtonWrapper, ContributorItem, ContributorList, RemoveContributorIcon, InputCheckboxWrapper };
export default styledComponents;