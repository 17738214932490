import { opacify } from 'polished';
import { css } from 'styled-components';
import { cssSmallText, cssNormalText, cssLargeText } from '../../primitives/typography';
import { px } from '../../style';
import { palette } from '../../styleguide';
import { base, whenDisabled } from './base';
import { textbuttons } from './styleguide';

// tslint:disable:no-magic-numbers

export const TextButtons: textbuttons = {
    small_icon: {
        text: cssSmallText,
        container: css`
            border-radius: ${px(100)};
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 12px;
                height: 12px;
            }
            > span {
                padding: ${px(4)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.primary2, palette.transparent)}
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.primary1b, palette.transparent, palette.primary1b)}
                border-width: 1px;
            }
            :hover > span  {
                ${base.colors(palette.primary1b, opacify(-.95, palette.primary2))}
                border-width: 1px;
            }
            :active > span  {
                ${base.colors(palette.primary1b, opacify(-.90, palette.primary2))}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
        `,
    },
    small_noicon: {
        text: cssSmallText,
        container: css`
            border-radius: ${px(100)};
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 12px;
                height: 12px;
            }
            > span {
                padding: ${px(4)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.primary2, palette.transparent)}
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.primary1b, palette.transparent, palette.primary1b)}
                border-width: 1px;
            }
            :hover > span  {
                ${base.colors(palette.primary1b, opacify(-.95, palette.primary2))}
                border-width: 1px;
            }
            :active > span  {
                ${base.colors(palette.primary1b, opacify(-.90, palette.primary2))}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
        `,
    },
    small_admin: {
        text: cssSmallText,
        container: css`
            border-radius: ${px(100)};
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 12px;
                height: 12px;
            }
            > span {
                padding: ${px(4)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.white, palette.transparent)}
                border-width: 1px;
            }
            :focus > span {
                ${base.colors(palette.white, palette.transparent, palette.white)}
                border-width: 1px;
            }
            :hover > span  {
                ${base.colors(palette.white, opacify(-.95, palette.primary2))}
                border-width: 1px;
            }
            :active > span  {
                ${base.colors(palette.white, opacify(-.90, palette.primary2))}
                border-width: 1px;
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
        `,
    },
    medium_icon: {
        text: cssNormalText,
        container: css`
            border-radius: ${px(100)};
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 14px;
                height: 14px;
            }
            > span {
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.primary2, palette.transparent)}
            }
            :focus > span {
                ${base.colors(palette.primary1b, palette.transparent, palette.primary1b)}
            }
            :hover > span  {
                ${base.colors(palette.primary1b, opacify(-.95, palette.primary2))}
            }
            :active > span  {
                ${base.colors(palette.primary1b, opacify(-.90, palette.primary2))}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
        `,
    },
    medium_noicon: {
        text: cssNormalText,
        container: css`
            border-radius: ${px(100)};
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 14px;
                height: 14px;
            }
            > span {
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.primary2, palette.transparent)}
            }
            :focus > span {
                ${base.colors(palette.primary1b, palette.transparent, palette.primary1b)}
            }
            :hover > span  {
                ${base.colors(palette.primary1b, opacify(-.95, palette.primary2))}
            }
            :active > span  {
                ${base.colors(palette.primary1b, opacify(-.90, palette.primary2))}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
        `,
    },
    medium_expand_collapse: {
        text: cssNormalText,
        container: css`
            border-radius: 0;
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 14px;
                height: 14px;
            }
            > span {
                padding: ${px(5)} ${px(12)};
                border-radius: 0;
                ${base.colors('#333', 'transparent')}
            }
            :focus > span {
                ${base.colors('#333', '#ddd', '#333')}
            }
            :hover > span  {
                ${base.colors('#333', '#ddd')}
            }
            :active > span  {
                ${base.colors('#333', '#ddd')}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
        `,
    },
    medium_admin: {
        text: cssNormalText,
        container: css`
            border-radius: ${px(100)};
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 14px;
                height: 14px;
            }
            > span {
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.white, palette.transparent)}
            }
            :focus > span {
                ${base.colors(palette.white, palette.transparent, palette.white)}
            }
            :hover > span  {
                ${base.colors(palette.white, opacify(-.95, palette.primary2))}
            }
            :active > span  {
                ${base.colors(palette.white, opacify(-.90, palette.primary2))}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
        `,
    },
    alt_icon: {
        text: cssNormalText,
        container: css`
            border-radius: ${px(100)};
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 14px;
                height: 14px;
            }
            > span {
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.grey2, palette.transparent)}
            }
            :focus > span {
                ${base.colors(palette.grey2, palette.transparent, palette.primary1b)}
            }
            :hover > span  {
                ${base.colors(palette.grey2, opacify(-.90, palette.grey3b))}
            }
            :active > span  {
                ${base.colors(palette.grey2, opacify(-.90, palette.grey3b))}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
        `,
    },
    alt_noicon: {
        text: cssNormalText,
        container: css`
            border-radius: ${px(100)};
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 14px;
                height: 14px;
            }
            > span {
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.grey2, palette.transparent)}
            }
            :focus > span {
                ${base.colors(palette.grey2, palette.transparent, palette.primary1b)}
            }
            :hover > span  {
                ${base.colors(palette.grey2, opacify(-.90, palette.grey3b))}
            }
            :active > span  {
                ${base.colors(palette.grey2, opacify(-.90, palette.grey3b))}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
        `,
    },
    alt_admin: {
        text: cssNormalText,
        container: css`
            border-radius: ${px(100)};
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 14px;
                height: 14px;
            }
            > span {
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.white, palette.transparent)}
            }
            :focus > span {
                ${base.colors(palette.grey2, palette.transparent, palette.white)}
            }
            :hover > span  {
                ${base.colors(palette.grey2, opacify(-.90, palette.grey3b))}
            }
            :active > span  {
                ${base.colors(palette.grey2, opacify(-.90, palette.grey3b))}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
        `,
    },
    large_icon: {
        text: cssLargeText,
        container: css`
            border-radius: ${px(100)};
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 18px;
                height: 18px;
            }
            > span {
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.primary2, palette.transparent)}
            }
            :focus > span {
                ${base.colors(palette.primary1b, palette.transparent, palette.primary1b)}
            }
            :hover > span  {
                ${base.colors(palette.primary1b, opacify(-.95, palette.primary2))}
            }
            :active > span  {
                ${base.colors(palette.primary1b, opacify(-.90, palette.primary2))}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
        `,
    },
    large_noicon: {
        text: cssLargeText,
        container: css`
            border-radius: ${px(100)};
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 18px;
                height: 18px;
            }
            > span {
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.primary2, palette.transparent)}
            }
            :focus > span {
                ${base.colors(palette.primary1b, palette.transparent, palette.primary1b)}
            }
            :hover > span  {
                ${base.colors(palette.primary1b, opacify(-.95, palette.primary2))}
            }
            :active > span  {
                ${base.colors(palette.primary1b, opacify(-.90, palette.primary2))}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
        `,
    },
    large_admin: {
        text: cssLargeText,
        container: css`
            border-radius: ${px(100)};
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 18px;
                height: 18px;
            }
            > span {
                padding: ${px(9)} ${px(16)};
                border-radius: ${px(100)};
                ${base.colors(palette.white, palette.transparent)}
            }
            :focus > span {
                ${base.colors(palette.primary1b, palette.transparent, palette.primary1b)}
            }
            :hover > span  {
                ${base.colors(palette.primary1b, opacify(-.95, palette.primary2))}
            }
            :active > span  {
                ${base.colors(palette.primary1b, opacify(-.90, palette.primary2))}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
        `,
    },
    flat_noicon: {
        text: cssNormalText,       
        container: css`            
            > span {
                padding: ${px(9)} ${px(0)};
                ${base.colors(palette.primary2, palette.transparent)}
            }
            :focus > span {
                ${base.colors(palette.primary1b, palette.transparent)}
            }
            :hover > span  {
                ${base.colors(palette.primary1b, palette.transparent)}
            }
            :active > span  {
                ${base.colors(palette.primary1b, palette.transparent)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
        `,
    },
};
