import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class TextElement extends BaseControlElement {

    constructor(text?: string) {
        super();
        this.text = text;
    }

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TextHtmlContent)
    text = '';

}

export default TextElement;