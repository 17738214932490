import React, { useEffect, useState } from 'react';
import { Button, ElementLabel, LayoutField, LayoutForm } from '@liasincontrol/ui-basics';
import { AttachmentElement, ImageFocusPointElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { AttachmentsHelper } from '@liasincontrol/core-service';
import DeleteIcon from '@mui/icons-material/Delete';

type PageImageSettingsProps = {
    readonly sitemapNode: Domain.Publisher.SitemapNode,
    readonly disabled: boolean,
    readonly image: Domain.Shared.Attachment,
    readonly onSaveImageSettings: (settings: ImageAttachmentState) => Promise<void>,
    readonly onLoadAttachment: (id: string) => Promise<Blob>,
    readonly onUploadAttachment: (file: File, abortSignal: AbortSignal) => Promise<string>,
    readonly onRemoveAttachment: (id: string) => void,
    readonly isEditing: () => void,
}

export type ImageAttachmentState = {
    image: string,
    focalPoint: string,
    attachments: Domain.Shared.Attachment[]
}

export const PageImageSettings: React.FC<PageImageSettingsProps> = (props) => {
    const [imageAttachment, setImageAttachment] = useState<ImageAttachmentState>(initImageAttachment());
    const [isEditing, setIsEditing] = useState<boolean>(false);

    useEffect(() => {
        setImageAttachment({
            focalPoint: props.sitemapNode.focalPoint,
            image: props.sitemapNode.image,
            attachments: props.image ? [props.image] : null,
        })
    }, [props.sitemapNode, props.image]);

    const onSave = () => {
        props.onSaveImageSettings(imageAttachment).then(() => setIsEditing(prev => !prev));
    };

    const onDeleteImage = () => {
        // props.onRemoveAttachment(imageAttachment.image);
        setImageAttachment(prev => ({ ...prev, attachments: null, image: null }));
    };

    return (
        <LayoutForm align='center'>
            <LayoutField left={1} top={1} width={4} height={1}>
                <ElementLabel>Pagina banner</ElementLabel>
            </LayoutField>
            {isEditing && imageAttachment.image && <LayoutField left={5} top={1} width={1} height={1}>
                <Button
                    btnbase="iconbuttons"
                    btntype="small_background"
                    icon={<DeleteIcon />}
                    aria-label="Verwijder"
                    onClick={onDeleteImage}
                >
                    Verwijder
                </Button>
            </LayoutField>}
            <LayoutField left={6} top={1} width={1} height={1}>
                <Button
                    id="btn-page-metadata"
                    btnbase="ghostbuttons"
                    btntype="small_noicon"
                    onClick={() => {
                        if (isEditing)
                            onSave();
                        else {
                            setIsEditing(prev => !prev);
                            props.isEditing?.();
                        }
                    }}
                    aria-label="Selecteren"
                    disabled={props.disabled}
                >
                    {isEditing ? 'Opslaan' : 'Bewerken'}
                </Button>
            </LayoutField>

            <LayoutField left={1} top={2} width={6} height={1}>
                {isEditing && imageAttachment.image
                    ? <ImageFocusPointElement
                        id={props.sitemapNode.image}
                        label='Focuspunt'
                        attachmentId={imageAttachment.image}
                        value={imageAttachment.focalPoint}
                        onLoadAttachment={(id) =>
                            props.onLoadAttachment(id).then(blob => {
                                setImageAttachment(prev => ({ ...prev, image: id, focalPoint: props.sitemapNode.focalPoint }));
                                return blob;
                            })
                        }
                        editorSettings={{
                            disabled: props.disabled,
                            restrictions: { required: false },
                            validationErrors: [],
                            onChange: (focusPoint) => setImageAttachment(prev => ({ ...prev, focalPoint: focusPoint })),
                        }}
                    />
                    : <AttachmentElement
                        key={`${props.sitemapNode.elementId}-image`}
                        id={imageAttachment.image}
                        editorSettings={{
                            disabled: props.disabled || !isEditing,
                            restrictions: { required: false, maxFileSize: attachmentMaxFileSize, allowedFileTypes: attachmentAllowedFileTypes },
                            validationErrors: [],
                            onChange: () => { /* do nothing */ },
                        }}
                        allowMultiple={false}
                        value={imageAttachment.image}
                        onLoadAttachment={props.onLoadAttachment} // won't fire
                        onUploadAttachment={(file, abort) =>
                            props.onUploadAttachment(file, abort)
                                .then(blobId => {
                                    // model is required to be an array of attachments
                                    const attachment = [AttachmentsHelper.mapFileToAttachment(file, blobId)];
                                    setImageAttachment(prev => ({ ...prev, attachments: attachment, image: blobId }));
                                    return blobId;
                                })
                        }
                    />
                }
            </LayoutField>
        </LayoutForm >
    );
};

const initImageAttachment = (): ImageAttachmentState => {
    return ({
        attachments: null,
        focalPoint: null,
        image: null,
    });
};

const attachmentMaxFileSize = 10000000;

const attachmentAllowedFileTypes = [
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/gif',
];
