import styled from 'styled-components';
import { Heading4, MultiSelectList, Section, px } from '@liasincontrol/ui-basics';

const CustomHeading4 = styled(Heading4)`
    margin-top: ${px(20)};
`;

const CustomMultiSelectList = styled(MultiSelectList)`
    div {
        border: 0;
        ul > li{
            padding-left: ${px(5)};
        }
    }
`;

const CustomSection = styled(Section)`
    height: min-content;
    max-height: 80vh;
    overflow: auto;
`;

const CustomSearchField = styled.div`
    width: 100%;
    display: block;
    div {
        float: right;
    }
`;

const styledComponents = { CustomHeading4, CustomMultiSelectList, CustomSection, CustomSearchField };

export default styledComponents;