import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Represents a data accessor of performance hierarchy items.
 */
export class HierarchyItemDataAccessor {

    /**
     * Creates a new hierarchy item.
     * 
     * @param measureMomentId Defines the measure moment unique identifier.
     * @param hierarchyItem Defines the hierarchy item that needs to be added.
     */
    public static create = async (measureMomentId: string, hierarchyItem: Domain.Performance.HierarchyItem): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>(`/api/performance/${measureMomentId}`, {
            element: hierarchyItem.element,
            parentHierarchyItemId: hierarchyItem.parentHierarchyItemId
        }, config);
    };

    /**
     * Deletes an existing hierarchy item.
     * 
     * @param measureMomentId Defines the measure moment unique identifier.
     * @param hierarchyItemId Defines the hierarchy item unique identifier.
     */
    public static delete = async (measureMomentId: string, hierarchyItemId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.delete<void>(`/api/performance/${measureMomentId}/${hierarchyItemId}`, config);
    };

    /**
     * Updates an existing hierarchy item.
     * 
     * @param measureMomentId Defines the measure moment unique identifier.
     * @param hierarchyItem Defines the hierarchy item that has to be changed.
     */
    public static update = async (measureMomentId: string, hierarchyItem: Domain.Performance.HierarchyItem): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        const workflowStatus = {
            workflowStatus: hierarchyItem.workflowStateId,
            assignedUserId: hierarchyItem.assignedUserId || null
        };

        return HttpClient.put<string>(`/api/performance/${measureMomentId}/${hierarchyItem.element.elementId}`, {
            element: hierarchyItem.element,
            workflowStatus: workflowStatus
        }, config);
    };

    /**
     * Links a performance hierarchy item to a specific parent.
     * 
     * @param measureMomentId Defines the measure moment unique identifier.
     * @param hierarchyItemId Defines the unique identifier of the performance hierarchy item that has to be updated.
     * @param parentHierarchyItemId Defines the unique identifier of the parent that has to be linked.
     */
    public static reparent = async (measureMomentId: string, hierarchyItemId: string, parentHierarchyItemId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<void>(`/api/performance/${measureMomentId}/link`, {
            itemId: hierarchyItemId,
            parentId: parentHierarchyItemId
        }, config);
    };

    /**
     * Updates the order of an item relative to its siblings.
     * 
     * @param measureMomentId Defines the measure moment unique identifier.
     * @param moveAfterItemId Defines the ID of the nearest sibling from the left/above of the moved item.
     * @param movedItemId Defines the ID of the moved item.
     */
    public static reorderChildren = async (measureMomentId: string, moveAfterItemId: string | null, movedItemId: string): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<string>(`/api/performance/${measureMomentId}/updateorder`, { moveAfterItemId: moveAfterItemId, movedItemId: movedItemId }, config);
    };

    /**
     * Gets the audit information for a hierarchy item page.
     *
     * @param measureMomentId Defines the measure moment unique identifier.
     * @param hierarchyItemId Defines the unique identifier of the performance hierarchy item for which to load the audit data.
     */
    public static getAuditData = async (measureMomentId: string, hierarchyItemId: string): Promise<AxiosResponse<Domain.Dto.Shared.AuditEvent[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<Domain.Dto.Shared.AuditEvent[]>(`/api/performance/${measureMomentId}/${hierarchyItemId}/audit`, config);
    };

    /**
    * Get hierarchy item based on Id.
    * 
    * @param elementId Defines the unique identifier.
    */
    public static getHierarchyItem = async (elementId: string): Promise<AxiosResponse<Domain.Publisher.Element>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<Domain.Publisher.Element>(`/api/element/${elementId}`, config);
    };

}
