import React from 'react';
import { SectionHeader, Collapser, LayoutField } from '@liasincontrol/ui-basics';
import { PubSections } from '../';
import Styled from './index.styled';

type Props = {
    section: PubSections,
    sectionName: string,
    collapsedSections: PubSections[],
    onToggleSection: (section: PubSections) => void,
};

/**
 * Represents a UI component that renders a publication section.
 */
export const PublicationSection = React.forwardRef<HTMLDivElement, React.PropsWithChildren<Props>>((props, ref) => {
    return (
        <Styled.PublicationSettingsSection look="white" grid>
            <SectionHeader>
                {props.sectionName}
            </SectionHeader>
            <Collapser open>
                {props.children}
            </Collapser>
        </Styled.PublicationSettingsSection>
    );
});

export const LeftSide = (props) => (
    <LayoutField {...props} left={1} width={3} height={1} compactBorderBottom />
);

export const RightSide = (props) => (
    <LayoutField {...props} left={4} width={3} height={1} compactBorderBottom />
);
