export class SvgIcon {

    readonly id: string;

    readonly name: string;

    readonly keyWords: string[];

    readonly unicodePoint: number;

    readonly svg: string;

}

export const DefaultIconId = 'b0f416e1-f87b-4e00-afe9-8fcba97472d8';