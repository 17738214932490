import { css } from 'styled-components';
import { cssSmallText } from '../../primitives/typography';
import { px } from '../../style';
import { palette } from '../../styleguide';
import { base, whenDisabled } from './base';
import { bluebackgroundbuttons } from './styleguide';

// tslint:disable:no-magic-numbers

export const BlueBackgroundButtons: bluebackgroundbuttons = {
    medium_icon: {
        text: cssSmallText,
        container: css`
            border-radius: ${px(100)};
            outline: 0;
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 18px;
                height: 18px;
            }
            > span {
                padding: ${px(7)} ${px(16)};
                border-radius: ${px(100)};
                border-width: 2px;
                ${base.colors(palette.white, `#15195F`)};
            }
            :focus > span {
                ${base.colors(palette.white, `#15195F`, palette.white)}
            }
            :hover > span {
                ${base.colors(palette.white, '#484d88')}
            }
            :active > span {
                ${base.colors(palette.white, '#484d88')}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
        `,
    },
};
