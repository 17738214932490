import React, { useState } from 'react';
import EditOutlined from '@mui/icons-material/EditOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import LockIcon from '@mui/icons-material/Lock';
import * as Domain from '@liasincontrol/domain';
import { IconSize } from '@liasincontrol/ui-basics';
import { DefinitionsHelper, FieldsHelper } from '@liasincontrol/core-service';
import { Caption, CaptionWrapper, LockedText, Outline, Wrapper } from './index.styled';
import { ElementActions } from '../ElementActions';
import _ from 'lodash';

type Props = {
    isDesigner: boolean,
    isValid: boolean,
    isLocked: boolean,
    lockedBy?: string,
    element: Domain.Publisher.Element,
    parentElement: Domain.Publisher.Element,
    selectedElementId: string,
    canEdit: boolean,
    canMoveUp: boolean,
    canMoveDown: boolean,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onSelect: () => void,
    onRemoveElement?: (elementId: string, isControl: boolean) => void,
    onMoveElement?: (elementId: string, isControl: boolean, moveUp: boolean, steps?: number) => void,

    children?: React.ReactNode;
};

/**
 * Represents a UI component that renders the wrapper over a publication element.
 */
const ElementWrapper: React.FC<Props> = (props) => {
    const [hover, setHover] = useState<boolean>(false);

    const elementDefinition = props.getElementDefinition(props.element.elementDefinitionSystemId, props.element.elementDefinitionId);
    const elementSettings = getBaseElementSettings(props.element, elementDefinition);
    const isEditable = props.isDesigner || (elementSettings.allowPatchContent && props.canEdit);
    const isSelected = props.element.elementId === props.selectedElementId;
    const parentElementDefinition = props.getElementDefinition(props.parentElement.elementDefinitionSystemId, props.parentElement.elementDefinitionId);
    const isPanelContainer = (parentElementDefinition.systemId === Domain.SystemElementDefinitions.Pub.TabContainer || parentElementDefinition.systemId === Domain.SystemElementDefinitions.Pub.AccordionContainer)
        && elementDefinition.systemId === Domain.SystemElementDefinitions.Pub.StackContainer;

    const parentDirection = DefinitionsHelper.getElementDirection(props.parentElement, parentElementDefinition);

    const getCaptionText = () => {
        return `${elementDefinition.label ? elementDefinition.label : elementDefinition.name} ${elementSettings.name ? '- ' + elementSettings.name : ''}`
    }

    return (
        <Wrapper
            role="button"
            key={`liasElementWrapper-${props.element.elementId}`}
            aria-label={getCaptionText()}
            selected={isSelected}
            background={!props.isDesigner}
            padding={true}
            onClick={(event) => {
                event.stopPropagation();
                props.onSelect();
            }}
            onPointerEnter={() => setHover(true)}
            onPointerLeave={() => setHover(false)}>

            {props.isDesigner && isSelected && !isPanelContainer && (props.onMoveElement || props.onRemoveElement) &&
                <ElementActions
                    elementId={props.element.elementId}
                    elementName={elementSettings.name}
                    parentDirection={parentDirection}
                    rotateMoveIcons={false}
                    canMoveUp={props.canMoveUp}
                    canMoveDown={props.canMoveDown}
                    onMoveElement={(moveUp: boolean) => props.onMoveElement(props.element.elementId, true, moveUp, 1)}
                    onRemoveElement={() => props.onRemoveElement(props.element.elementId, true)}
                />}

            {!(props.isDesigner && !(hover || isSelected)) &&
                <Outline
                    isDesigner={props.isDesigner}
                    isValid={props.isValid}
                    selected={isSelected}
                    hover={hover}
                    background={true}
                    padding={true}
                    disabled={!isEditable}
                    isLocked={props.isLocked}>
                    <CaptionWrapper>
                        {!props.isDesigner &&
                            <Caption isDesigner={props.isDesigner} isValid={props.isValid} selected={isSelected} editable={isEditable} isLocked={props.isLocked} icon={true} marginLeft={false}>
                                {!props.isLocked && elementSettings.allowPatchContent ? (props.canEdit ? <EditOutlined sx={{ fontSize: IconSize.extrasmall }} /> : <EditOffOutlinedIcon sx={{ fontSize: IconSize.extrasmall }} />) : <LockIcon sx={{ fontSize: IconSize.extrasmall }} />}
                            </Caption>
                        }
                        <Caption isDesigner={props.isDesigner} isValid={props.isValid} selected={isSelected} editable={isEditable} isLocked={!props.isDesigner && props.isLocked} icon={false} marginLeft={!props.isDesigner}>
                            {getCaptionText()}
                        </Caption>
                        {!props.isDesigner && props.isLocked && !!props.lockedBy && <LockedText> wordt bewerkt door {props.lockedBy} </LockedText>}
                    </CaptionWrapper>
                </Outline>
            }
            {props.isDesigner &&
                <Outline
                    isDesigner={props.isDesigner}
                    isValid={_.isUndefined(props.isValid) || props.isValid}
                    selected={isSelected}
                    hover={hover}
                    background={true}
                    padding={true}
                    disabled={!isEditable}
                    isLocked={props.isLocked}
                />}
            {props.children}
        </Wrapper>
    );
};

/**
 * Gets the base settings of an element.
 * 
 * @param element Defines the element.
 * @param elementDefinition Defines the element definition.
 */
const getBaseElementSettings = (element: Domain.Publisher.Element, elementDefinition: Domain.Shared.ElementDefinition): Domain.Publisher.BaseControlElement => {
    const elementSettings = new Domain.Publisher.BaseControlElement();
    FieldsHelper.mapObject<Domain.Publisher.BaseControlElement>(elementSettings, elementDefinition.fields, element.fields);

    return elementSettings;
};

export default ElementWrapper;