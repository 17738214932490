import styled from 'styled-components';
import { px, palette } from '@liasincontrol/ui-basics';

const TocItem = styled.div<{ hasTasks: boolean; isActive: boolean }>`
    display: flex;
    align-items: center;
    background-color: ${(props) => (props.isActive ? palette.grey4 : '#fafafa')};
    border: ${px(1)} solid;
    border-color: ${(props) => (props.isActive ? palette.primary1 : '#ebebeb')};
    border-radius: ${px(4)};
    padding: ${px(8)} ${px(16)};
    flex-grow: 1;
    &:hover {
        background-color: ${palette.grey4};
    }
    position: relative;
    font-weight: ${(props: { hasTasks: boolean }) => (props.hasTasks ? 600 : null)};
`;

const TocParentItem = styled(TocItem)<{ itemsExpanded: boolean; hasTasks: boolean; isActive: boolean }>`
    z-index: 1;
    box-shadow: ${(props) => (props.itemsExpanded ? null : `0 ${px(3)} 0px 0px rgba(0, 0, 0, 0.15), 0 ${px(5)} ${px(5)} 0 rgba(0, 0, 0, 0.15)`)};
`;

const TocChildItem = styled(TocItem)``;

const TocItemLabel = styled.label`
    padding-left: ${px(8)};
    overflow: hidden;
    text-overflow: ellipsis;
`;

const TocItemTaskCount = styled.label`
    color: ${palette.primary2};
    padding-left: ${px(8)};
    font-weight: 600;
`;

const OpenTasksIndicator = styled.label`
    width: ${px(10)};
    height: ${px(10)};
    border-radius: 50%;
    margin-top: ${px(2)};
    background-color: ${palette.primary2};
`;

const TocLabelsWrapper = styled.div`
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    width: ${px(32)};
`;

const TasksWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
`;
export { TocParentItem, TocChildItem, TocItemLabel, TocItemTaskCount, TocLabelsWrapper, OpenTasksIndicator, TasksWrapper };
