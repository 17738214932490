import styled from 'styled-components';

const SideMenuList = styled.ul`
    list-style: none;
    max-width: 28rem;
    padding: 0; 
    margin: 0 24px 0 0;
    background-color: #fff;
    border-radius: 5px;
`;

export { SideMenuList };