import React from 'react';
import posed from 'react-pose';
import styled from 'styled-components';

export interface IOverlayProps {
  /**
   * default 10%
   */
  readonly visible?: boolean;
  /**
   * default 10%
   */
  readonly opacity?: number;
  /**
   * default 1
   */
  readonly zIndex?: number;
  /**
   * Position children using position: absolute
   */
  readonly relative?: boolean;
  readonly onClick?: React.MouseEventHandler;
  readonly children: React.ReactNode;
}

export const Overlay = ({
  children,
  onClick,
  opacity = 0.1,
  relative = false,
  visible = true,
  zIndex = 1,
}: IOverlayProps) =>
  relative ? (
    <>
      <Underlay
        pose={visible ? `visible` : `hidden`}
        initialPose="hidden"
        customOpacity={opacity}
        customZIndex={zIndex}
        onClick={onClick}
        role="presentation"
      />
      <Relative
        pose={visible ? `visible` : `hidden`}
        initialPose="hidden"
        customZIndex={zIndex}
        role="dialog"
      >
        {children}
      </Relative>
    </>
  ) : (
    <>
      <Underlay
        pose={visible ? `visible` : `hidden`}
        initialPose="hidden"
        customOpacity={opacity}
        customZIndex={zIndex}
        onClick={onClick}
        role="dialog"
      >
        {children}
      </Underlay>
    </>
  );

interface UnderlayProps {
  customZIndex: number;
  customOpacity: number;
}
const Underlay = styled(
  posed.div({
    visible: {
      delay: 0,
      transition: 200,
      opacity: 1,
      applyAtStart: { pointerEvents: `initial`, display: `block` },
    },
    hidden: {
      delay: 150,
      transition: 200,
      opacity: 0,
      applyAtStart: { pointerEvents: `none` },
      applyAtEnd: { display: `none` },
    },
  }),
)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${(p: UnderlayProps) => p.customZIndex};
  background-color: ${(p: UnderlayProps) =>
    `rgba(0, 0, 0, ${p.customOpacity})`};
  min-width: 100vw;
  min-height: 100vh;
`;

interface RelativeProps {
  customZIndex: number;
}
const Relative = styled(
  posed.div({
    visible: {
      delay: 150,
      transition: 100,
      opacity: 1,
      applyAtStart: { display: `block` },
    },
    hidden: {
      delay: 0,
      transition: 100,
      opacity: 0,
      applyAtEnd: { display: `none` },
    },
  }),
)`
  position: relative;
  z-index: ${(p: RelativeProps) => p.customZIndex};
`;
