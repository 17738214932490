import React from 'react';
import { ElementProps } from '../../shared/ElementProps';
import { ColumnBuilderEditor } from './index.editor';

/**
 * Represents a UI component that renders an column builder element.
 */
export type Props = ElementProps<string> & { allowedActions?: Record<string, boolean> };

export const ColumnBuilderElement: React.FC<Props> = (props) => {
    return props.editorSettings ? <ColumnBuilderEditor {...props} /> : null;
};

export const defaultAllowedAction: Record<string, boolean> = {
    canHide: true,
    canAlign: true,
    canCaption: true,
    canDivide: true,
    canFormat: true,
    canSummarize: true,
    canSort: true,
    canHighLight: true,
    canGroup: true,
    canExpandGroup: true,
    canDrag: true,
    canHideCaption: false,
    canSetColumnWidth: false,
};