/**
 * Represents the fields that must be specified to update an existing detail card for an element definition.
 */
 export class UpdateDetailCard {

    name: string;

    layout: string;

    isDefault: boolean;
}