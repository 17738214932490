import styled from 'styled-components';
import { ColorUtils } from '@liasincontrol/core-service';
import Styled, { lineHeightCoefficient } from "../../SharedControlElements/index.styled";

const HierarchyWrapper = styled(Styled.GridWrapper) <{
    primaryColor: string,
    primaryTextColor: string,
    textColor: string,
    textFontSize?: string,
    editMode: boolean
}>`
    .dx-treelist, .dx-treelist-container {
        color: inherit;
        font-size: inherit;
        line-height: inherit; 
       
        .dx-row > td {
            font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
            line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px!important` : 'inherit')};
        }

        .dx-treelist-headers .dx-treelist-table .dx-row > td {
            font-size: ${(p) => (p.textFontSize ? `${p.textFontSize}px!important` : 'inherit')};
            line-height: ${(p) => (p.textFontSize ? `${+p.textFontSize * lineHeightCoefficient}px!important` : 'inherit')};
            color:inherit;

            .dx-sort,  .dx-sort-indicator, :hover .dx-treelist-text-content {
                color: inherit;
            } 
        }
        
        .dx-treelist-rowsview .dx-treelist-empty-space {
            font: ${(p) => (p.textFontSize ? `${p.textFontSize}px/1 DXIcons` : '14px/1 DXIcons')};           
            width: ${(p) => (p.textFontSize ? `${+p.textFontSize + 2}px` : '14px')};
        } 

        .dx-header-row {
            background-color: ${({ primaryColor }) => primaryColor};
            color: ${({ primaryTextColor }) => primaryTextColor};
            font-size: inherit;
            font-weight: 700;
            border: 1px solid #cccccc;

            .cell-highlighted {
                background-color: ${({ primaryColor }) => ColorUtils.shadeColor(primaryColor, -8)};          
            }
        }
        
        .dx-data-row {
            border-color: #cccccc;   

            .cell-highlighted {
                background-color: #eaeaea;
            }       
        }

        .dx-treelist-collapsed span, .dx-treelist-expanded span {
            font: ${(p) => (p.textFontSize ? `${p.textFontSize}px/${p.textFontSize}px DXIcons` : '18px/18px DXIcons')};
            text-align: center;
            cursor: pointer;

            &::before {
                width: ${(p) => (p.textFontSize ? `${+p.textFontSize + 2}px` : '20px')};
            }
        }
    }

    .dx-treelist .dx-treelist-table .dx-row.dx-data-row td {
        ol li {
            overflow: visible;
        }

        ul li {
            overflow: visible;
        }
    }
`;

const styledComponents = { HierarchyWrapper };
export default styledComponents;

