import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { Button, ThumbnailImg } from '@liasincontrol/ui-basics';
import { TemplateContainer, TemplateContent, TemplateSvg } from './index.styled';
import { PageTemplateDialog } from '../SitemapEditor/Settings/PageTemplateDialog';


type Props = {
    /**
     * Defines the publication's rootId.
     */
    readonly publicationId: string,
    /**
     * Determines if the back button should be displayed.
     */
    readonly hasBackButton: boolean,
    /**
     * Defines an event handler triggered when template is set.
     */
    onChangeTemplate?: (newTemplateId: string, newTemplateName: string) => void,
    /**
    * Defines an event handler triggered when back button is clicked.
    */
    onBack: () => void,
    /**
    * Defines the publication's pagedesigns.
    */
    readonly pageDesigns: Domain.Publisher.PageDesign[],
    /**
    * Defines the publication's element definitions..
    */
    readonly elementDefinitions?: Record<string, Domain.Shared.ElementDefinition>,
    /**
    * Determines if the publication has any sabloon avbailable.
    */
    readonly hasAvailableTemplates?: boolean,
};
/**
 * Represents a UI component that renders an empty page template.
 */
export const BlankPublication: React.FC<Props> = (props) => {
    const [chosingTemplate, setChosingTemplate] = useState<boolean>(false);
    const isEditable = props.hasAvailableTemplates && props.onChangeTemplate;

    return (
        <>
            <TemplateContainer>
                <TemplateSvg>
                    <ThumbnailImg variant="notes" />
                </TemplateSvg>
                <TemplateContent role="presentation">{isEditable ? 'Kies een sjabloon en koppel deze aan de pagina.' : 'Er is nog geen sjabloon aan de pagina gekoppeld.'}
                </TemplateContent>
                {isEditable && (
                    <Button id="btn-add-template" btnbase="primarybuttons" btntype="medium_noicon" onClick={() => setChosingTemplate(true)}>
                        Koppel sjabloon
                    </Button>
                )}
                {!props.hasBackButton && (
                    <>
                        <br />
                        <Button id="btn-back" btnbase="textbuttons" btntype="medium_noicon" onClick={props.onBack}>
                            Terug
                        </Button>
                    </>
                )}
            </TemplateContainer>
            {chosingTemplate && (
                <PageTemplateDialog
                    id={props.publicationId}
                    publicationId={props.publicationId}
                    pageDesigns={props.pageDesigns}
                    elementDefinitions={props.elementDefinitions}
                    onChange={(templateId: string, templateName: string) => {
                        setChosingTemplate(false);
                        props.onChangeTemplate(templateId, templateName);
                    }}

                    onCancel={() => setChosingTemplate(false)}
                />
            )}
        </>
    );
};
