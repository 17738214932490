import styled from 'styled-components';
import { LayoutField } from '@liasincontrol/ui-basics';

const ButtonLayoutField = styled(LayoutField)`
    align-self: end;
`;

const styledComponents = {
    ButtonLayoutField
};

export default styledComponents;
