import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import Add from '@mui/icons-material/Add';
import { ApiErrorReportingHelper, FormMode } from '@liasincontrol/core-service';
import { Finance } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import { Bar, Button, ErrorOverlay, Heading2, PageTitle, Section, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { ContextMenu, createSource, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { State, BudgetJournalGroupActionCreator } from '@liasincontrol/redux-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, Actions } from '@liasincontrol/userrights-service';
import { BudgetJournalGroupForm } from './BudgetJournalGroupForm';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the page with the list of budget journal groups.
 */
const BudgetJournalGroups: React.FC<Props> = (props) => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [formDialog, setFormDialog] = useState<{
        isVisible: boolean,
        isSaving: boolean,
        formMode: FormMode,
        entity: Domain.Finance.BudgetJournalGroup
    }>({ isVisible: false, isSaving: false, formMode: FormMode.View, entity: undefined });

    // #region Grid state...

    const getBudgetJournalGroup = (id: string, formMode: FormMode) => {
        Finance.BudgetJournalGroupDataAccessor.get(id)
            .then((response) => {
                setFormDialog({
                    ...formDialog,
                    isVisible: true,
                    formMode: formMode || FormMode.View,
                    entity: {
                        ...response.data,
                    }
                })
            });
    };

    const availableColumns: GridColumn<Domain.Finance.BudgetJournalGroup>[] = getColumnConfiguration(
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_ManageFinance)
            ? (budgetJournalGroup: Domain.Finance.BudgetJournalGroup) => getBudgetJournalGroup(budgetJournalGroup.id, FormMode.Edit)
            : undefined,
    );

    const customDataSource = useMemo(() => {
        return createSource({
            keyExpr: "id",
            paginate: true,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,

            dataSourcePromise: Finance.BudgetJournalGroupDataAccessor.getAll //props.budgetJournalGroups.items//
        });
    }, [lastRefresh]);

    const onSave = (budgetJournalGroup: Domain.Finance.BudgetJournalGroup, mode: FormMode) => {
        setFormDialog({ ...formDialog, isSaving: true });
        const getPromise = (mode: FormMode) => {
            switch (mode) {
                case FormMode.AddNew:
                    return Finance.BudgetJournalGroupDataAccessor.create(budgetJournalGroup);
                case FormMode.Edit:
                    return Finance.BudgetJournalGroupDataAccessor.update(budgetJournalGroup)
                default:
                    return;
            }
        };

        getPromise(mode).then(() => {
            setLastRefresh(Date.now());
            props.fetchBudgetJournalGroups();

        }).catch((exception) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(budgetJournalGroup.id ? ApiErrorReportingHelper.GenericMessages.Saving : ApiErrorReportingHelper.GenericMessages.Adding, exception);
            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.SourceGroupNotFound)) {
                setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.SourceGroupNotFound] });
            } else {
                setError(errorInfo);
            }
        }).finally(() => {
            setFormDialog({ ...formDialog, isSaving: false, formMode: FormMode.View, isVisible: false });
        });
    };

    return (
        <>
            <Wrapper>
                <WrapperContent>
                    <PageTitle>
                        <Heading2>Journaalgroepen</Heading2>
                    </PageTitle>
                    <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        <Bar look='toolbar'>
                            <Bar start>
                                <Button
                                    id='btn-add-new-budgetJournalGroup'
                                    btnbase='textbuttons'
                                    btntype='medium_icon'
                                    icon={<Add />}
                                    onClick={() => setFormDialog({
                                        ...formDialog,
                                        isVisible: true,
                                        formMode: FormMode.AddNew,
                                        entity: {
                                            ...new Domain.Finance.BudgetJournalGroup(),
                                            name: '',
                                        }
                                    })}
                                >
                                    Nieuw
                                </Button>
                            </Bar>
                        </Bar>
                        <Section look='white'>
                            <LsGrid
                                allowColumnResizing={true}
                                showRowLines={true}
                                paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                                dataSource={customDataSource}
                                columns={availableColumns}
                                onDataError={(e) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, e))}
                                onClickRow={(item) => getBudgetJournalGroup(item.id, FormMode.Edit)}
                            />
                        </Section>
                    </ErrorOverlay>
                </WrapperContent>
            </Wrapper>
            {formDialog.isVisible && <BudgetJournalGroupForm
                budgetJournalGroup={formDialog.entity}
                isBusy={formDialog.isSaving}
                formMode={formDialog.formMode}
                onSave={onSave}
                onCancel={() => setFormDialog({ entity: undefined, isVisible: false, formMode: FormMode.View, isSaving: false })}
                onError={setError}
            />
            }

        </>
    );
};

const getColumnConfiguration = (
    onEdit?: (item: Domain.Finance.BudgetJournalGroup) => void
): GridColumn<Domain.Finance.BudgetJournalGroup>[] => {
    return [{
        name: 'name',
        title: 'Naam',
        width: '40%',
        align: 'left',
    }, {
        name: 'id',
        title: '',
        type: 'buttons',
        width: '5%',
        align: 'right',
        hideInColumnChooser: true,
        renderCustom: ({ data }) => (
            onEdit &&
            <ContextMenu<Domain.Finance.BudgetJournalGroup>
                item={data}
                keyExpr='id'
                actions={[
                    {
                        action: onEdit,
                        actionName: `edit-journalgroup-${data.id}`,
                        displayName: 'Bewerken',
                        ariaLabel: `Bewerk ${data.name}`,
                    }
                ]}
            />
        )
    }];
};

const mapStateToProps = (state: State) => {
    return {
        budgetJournalGroups: {
            items: state.finance.budgetJournalGroups,
        },
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBudgetJournalGroups: () => {
            dispatch(BudgetJournalGroupActionCreator.set());
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(BudgetJournalGroups);
export { Component as index };
