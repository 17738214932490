import React, { useState } from 'react';
import _ from 'lodash';

import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import { ScrollView } from 'devextreme-react/scroll-view';
import * as Domain from '@liasincontrol/domain';
import { Popover } from '@liasincontrol/ui-devextreme';
import { Button, palette, UserRightsControl } from '@liasincontrol/ui-basics';

type Props = {
    controlId: string,
    pageId: string;
    publicationId: string,
    componentName: string,
    workflowStates: Domain.Publisher.TaskManagerWorkflowState[],
    currentStateId?: string,
    fetchTaskList: (publicationId: string, pageId: string, controlId: string) => Promise<Domain.Publisher.WorkflowTask[]>
};

/**
 * Represents a UI component that renders the information popover used in the task management.
 */
type StepType = {
    name: string,
    disabled: boolean,
    isCurrentStep: boolean,
    isLastStep: boolean,
    users: Domain.Shared.RightUser[]
}

const AssignedPopover: React.FC<Props> = (props) => {
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [componentWorkflowTasks, setComponentWorkflowTasks] = useState<StepType[]>([]);

    const mapTasks = (tasks: Domain.Publisher.WorkflowTask[]): StepType[] => {
        const orderedSteps = props.workflowStates.sort((s1, s2) => s1.order - s2.order);
        const currentState = props.workflowStates.find((s) => s.id === props.currentStateId);

        const result: StepType[] = orderedSteps.map((s, i) => {
            const stepTasks = tasks.filter(t => t.taskStateId === s.id);
            const stepEnabled = (
                s.order === 0 || //first step
                (stepTasks.length && (stepTasks.every(t => t.taskIsCompleted) || stepTasks.some(t => t.taskIsActive))) || // intermediate steps                
                (s.order === currentState?.order && tasks.every(t => t.taskIsCompleted)) //last step
            );

            return {
                disabled: _.isEmpty(currentState) || (!stepEnabled && s.order > currentState.order), //if is not in workflow steps are disabled
                name: s.name,
                isCurrentStep: s.id === currentState?.id,
                isLastStep: currentState?.category === Domain.Shared.WorkflowCategoryType.Done,
                users: tasks.filter(t => t.taskStateId === s.id).map(t => ({
                    email: t.userEmail,
                    enabled: true,
                    id: t.userId,
                    name: t.userName,
                    frontIcon: {
                        icon: t.taskIsCompleted ? <TaskAltIcon sx={{ color: palette.green }} /> : <CircleOutlinedIcon sx={{ color: palette.grey2 }} />,
                        color: t.taskIsCompleted ? palette.green : palette.grey2
                    },
                }))
            }
        });
        return result;
    }

    const fetchList = () => {
        props.fetchTaskList(props.publicationId, props.pageId, props.controlId)
            .then((data) => {
                setComponentWorkflowTasks(mapTasks(data));
                setPopoverVisible(true);
            });
    }

    return (<>
        <Button
            id={`btn-show-contributor-${props.pageId}-${props.controlId}`}
            btnbase="iconbuttons"
            btntype="small_transparent"
            aria-label={`Voortgang ${props.componentName}`}
            onClick={() => {
                fetchList();
            }}
            icon={<InfoOutlinedIcon />}
        />
        {!!componentWorkflowTasks.length ?
            <Popover
                target={`#btn-show-contributor-${props.pageId}-${props.controlId}`}
                position="left"
                width={320}
                maxHeight={500}
                visible={popoverVisible}
                onHiding={() => setPopoverVisible(false)}
                shading={true}
                shadingColor="rgba(0, 0, 0, 0.2)"
            >
                <ScrollView
                    id={`assingment-popover-scrollview-${props.pageId}-${props.controlId}`}
                    bounceEnabled={false}
                    showScrollbar="always"
                    scrollByThumb={true}>
                    <div>
                        {componentWorkflowTasks.map((step: StepType) => (<UserRightsControl
                            key={`assingment-popover-${props.pageId}-${props.controlId}-${step.name}`}
                            users={step.users}
                            disabled={step.disabled}
                            workflowTemplateStateName={step.name}
                            actionsEnabled={false}
                            showListHeading={false}
                            isCurrentStep={step.isCurrentStep}
                            isLastStep={step.isLastStep}
                        />))}
                    </div>
                </ScrollView>
            </Popover>
            : null
        }
    </>);
}

export { AssignedPopover };