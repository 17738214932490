import { AxiosResponse } from "axios";
import * as Domain from '@liasincontrol/domain';
import HttpClient from '@liasincontrol/http-service';
import { DataAccessHostUtils } from '../DataAccessHostUtils';

export class SvgIcon {
    /**
     * Gets a list of svg icons.
     */
    public static getAll = async (): Promise<AxiosResponse<Domain.Shared.SvgIcon[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Shared.SvgIcon[]>("/api/icons", config);
    };
}
