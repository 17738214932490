import styled from 'styled-components';
import { palette, cssFontNormal, px } from '@liasincontrol/ui-basics';

const ListWrap = styled.ul`
    list-style: none;
    display: block;
    width: auto;
    padding: 0;
    margin: ${px(8)} 0 ${px(12)} 0;
`;

const Li = styled.li`
    display: inline-block;
    width: 100%;
    height: auto;
    padding:0 0 ${px(8)} 0;
    ${cssFontNormal(16, 28, 30)}
    text-align:left;
    padding:${px(12)} ${px(16)};
    margin: 0;
    margin-bottom: ${px(12)};    
    color: ${palette.grey2};
    text-overflow: ellipsis;
    border-radius: 4px;
    outline: 0;
    transition:border-color 0.3s ease;    
    &:hover { border: 2px solid rgba(75, 85, 246, 0.15); }
    &:focus { border: 2px solid rgba(75, 85, 246, 1); } /* dit is #4b55f6 */
    white-space: nowrap;
    ::-ms-clear {
        display: none;
    }
    border: 2px solid ${palette.grey4};   
`;

const StyledInput = styled.div`
    display:inline-block;
    width: calc(100% - 42px);
    margin: 0;
    color: ${palette.grey2};
    text-overflow: ellipsis;
    outline: 0;
    background: ${palette.white};
    white-space: nowrap;    
`;

const styledComponents = { ListWrap, Li, StyledInput };
export default styledComponents;