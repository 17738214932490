import React, { useCallback } from 'react';
import { withField } from '@liasincontrol/ui-basics';
import { RichTextProps } from './index.props';
import Styled from './index.styled';
import { getValidationErrorsNode } from '../../shared/validationHelper';

/**
 * Represents a UI component that renders a rich text editor.
 */
export const RichTextEditor: React.FC<RichTextProps> = (props) => {
    return (
        <RichTextField
            id={props.id}
            key={props.id}
            label={props.label}
            helpText={props.helpText}
            disabled={props.editorSettings.disabled}
            error={getValidationErrorsNode(props.editorSettings?.validationErrors)}
            mandatory={props.editorSettings?.restrictions?.required}
            value={{
                value: props.value,
                height: props.height,
                isToolbarHidden: props.isToolbarHidden,              
                onFocusIn: props.onFocusIn,
                onFocusOut: props.onFocusOut,
            }}
            onChange={(field) => props.editorSettings?.onChange(field.value)}
            canMaximize={props.canMaximize}
            maximized={props.maximized}
            onMaximize={props.onMaximize}
            withTextAssistant={props.withTextAssistant}
            onTextAssistant={props.onTextAssistant}
            theme={props.theme}
        />
    );
};

const RichTextField = withField<{
    value: string;  height?: string | number; isToolbarHidden?: boolean;
    onFocusIn?: ((e: {}) => any); onFocusOut?: ((e: {}) => any);
}>(({ onChange, value: { value, height, isToolbarHidden, onFocusIn, onFocusOut }, ...rest }) => (
    <Styled.RichEditor
        value={value}
        onChange={onChange && useCallback((value: string) => onChange({ value, height }), [onChange, height])}
        onFocusIn={onFocusIn}
        onFocusOut={onFocusOut}
        height={height}
        isToolbarHidden={isToolbarHidden}
        {...rest}
    />
));
