import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { PrivateRoute } from '@liasincontrol/ui-basics';
import { Writer } from '../..';

const Contents = (
    <>
        <Route path="writer/page">
            <Route index element={<PrivateRoute component={Writer.PublicationItem.PublicationContent.index} />} />
            <Route path=":pageid" element={<PrivateRoute component={Writer.PublicationItem.PublicationContent.index} />} />
            <Route path=":pageid/control/:controlid" element={<PrivateRoute component={Writer.PublicationItem.PublicationContent.index} />} />
        </Route>
        <Route path='*' element={<Navigate to='/notfound' />} />
    </>
);

export { Contents };