// generated using `node gen`
import React from 'react';
import styled from 'styled-components';

export type ElementIconProps = React.SVGAttributes<SVGElement> & { size?: number | string };

const Svg = styled.svg<ElementIconProps>`
    fill: currentColor;
    height: ${({ size = 24 }) => typeof size === 'number' ? `${size}px` : size};
    width: ${({ size = 24 }) => typeof size === 'number' ? `${size}px` : size};
`;

export const ElementAttachIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><g id="Attach"><path d="M41.67,7.49A13.92,13.92,0,0,0,22,7.49L5.17,24.34a9.92,9.92,0,0,0,14,14L34.65,22.93a6,6,0,1,0-8.43-8.42L16.13,24.61a2,2,0,1,0,2.8,2.81L29,17.32a2,2,0,0,1,3.39,1.4,2,2,0,0,1-.58,1.41L16.4,35.57A6,6,0,0,1,8,27.14L24.82,10.3a9.93,9.93,0,1,1,14,14L22,41.18a2,2,0,0,0,1.41,3.39,2,2,0,0,0,1.4-.58L41.67,27.14a13.89,13.89,0,0,0,0-19.65Z" /></g></Svg>);
export const ElementCalendarIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M40 46H28a2 2 0 010-4h12a2 2 0 002-2V12a2 2 0 00-2-2H8a2 2 0 00-2 2v8a2 2 0 01-4 0v-8a6 6 0 016-6h32a6 6 0 016 6v28a6 6 0 01-6 6z" /><path d="M12 10a2 2 0 01-2-2V4a2 2 0 014 0v4a2 2 0 01-2 2zM36 10a2 2 0 01-2-2V4a2 2 0 014 0v4a2 2 0 01-2 2zM17 37h-3a2 2 0 01-2-2v-5a2 2 0 014 0v3h1a2 2 0 010 4z" /><path d="M14 46a12 12 0 1112-12 12 12 0 01-12 12zm0-20a8 8 0 108 8 8 8 0 00-8-8z" /><path d="M44 22a2 2 0 01-2-2 2 2 0 00-2-2H8a2 2 0 00-2 2 2 2 0 01-4 0 6 6 0 016-6h32a6 6 0 016 6 2 2 0 01-2 2z" /></Svg>);
export const ElementCheckBoxIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M40 46H8a6 6 0 01-6-6V8a6 6 0 016-6h32a6 6 0 016 6v32a6 6 0 01-6 6zM8 6a2 2 0 00-2 2v32a2 2 0 002 2h32a2 2 0 002-2V8a2 2 0 00-2-2z" /><path d="M21 31a2 2 0 01-1.4-.6l-4-4a2 2 0 012.8-2.8l2.6 2.6 8.6-8.6a2 2 0 012.8 2.8l-10 10a2 2 0 01-1.4.6z" /></Svg>);
export const ElementDatasourceIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><g id="ic_text_block" data-name="ic text block"><path d="M40,46H8a6,6,0,0,1-6-6V32a2,2,0,0,1,4,0v8a2,2,0,0,0,2,2H40a2,2,0,0,0,2-2V32a2,2,0,0,1,4,0v8A6,6,0,0,1,40,46Z" /><path d="M44,18a2,2,0,0,1-2-2V8a2,2,0,0,0-2-2H8A2,2,0,0,0,6,8v8a2,2,0,0,1-4,0V8A6,6,0,0,1,8,2H40a6,6,0,0,1,6,6v8A2,2,0,0,1,44,18Z" /><circle cx="4" cy="24" r="2" /><circle cx="44" cy="24" r="2" /><g><path d="M24,22.28c-5.47,0-11-2.06-11-6s5.53-6,11-6,11,2.06,11,6S29.5,22.28,24,22.28Zm0-8c-4.4,0-6.83,1.48-7,2,.18.48,2.61,2,7,2s6.7-1.41,7-2C30.74,15.69,28.33,14.28,24,14.28Zm7,2h0Z" /><path d="M24,30.28c-5.47,0-11-2.06-11-6v-8a2,2,0,1,1,4,0v8c.17.52,2.6,2,7,2s6.82-1.48,7-2v-8a2,2,0,0,1,4,0v8C35,28.22,29.5,30.28,24,30.28Z" /><path d="M24,38.28c-5.47,0-11-2.06-11-6v-8a2,2,0,0,1,4,0v8c.17.52,2.6,2,7,2s6.82-1.48,7-2v-8a2,2,0,0,1,4,0v8C35,36.22,29.5,38.28,24,38.28Z" /></g></g></Svg>);
export const ElementDiagramIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M20 46a18 18 0 010-36 2 2 0 012 2v14h14a2 2 0 012 2 18.1 18.1 0 01-18 18zm-2-31.9A14 14 0 1033.9 30H20a2 2 0 01-2-2z" /><path d="M44 22H28a2 2 0 01-2-2V4a2 2 0 012-2 18.1 18.1 0 0118 18 2 2 0 01-2 2zm-14-4h11.9A14.2 14.2 0 0030 6.1z" /></Svg>);
export const ElementDropdownIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M31 10h6a.4.4 0 01.3.7l-3 3a.4.4 0 01-.6 0l-3-3a.4.4 0 01.3-.7zM16 30h-4a2 2 0 010-4h4a2 2 0 010 4zM16 38h-4a2 2 0 010-4h4a2 2 0 010 4z" /><path d="M20 46H8a6 6 0 01-6-6V8a6 6 0 016-6h32a6 6 0 016 6v12a2 2 0 01-2 2H26v18a6 6 0 01-6 6zM6 22v18a2 2 0 002 2h12a2 2 0 002-2V22zm20-4h16V8a2 2 0 00-2-2H26zM6 18h16V6H8a2 2 0 00-2 2z" /></Svg>);
export const ElementGraphBarIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M12 46H4a2 2 0 01-2-2V22a2 2 0 012-2h8a2 2 0 012 2v22a2 2 0 01-2 2zm-6-4h4V24H6zM28 46h-8a2 2 0 01-2-2V4a2 2 0 012-2h8a2 2 0 012 2v40a2 2 0 01-2 2zm-6-4h4V6h-4zM44 46h-8a2 2 0 01-2-2V32a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2zm-6-4h4v-8h-4z" /></Svg>);
export const ElementHeaderIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M44 22H28a2 2 0 010-4h16a2 2 0 010 4zM35 30h-7a2 2 0 010-4h7a2 2 0 010 4zM4 37a2 2 0 01-2-2V13a2 2 0 014 0v22a2 2 0 01-2 2zM16 37a2 2 0 01-2-2V13a2 2 0 014 0v22a2 2 0 01-2 2z" /><path d="M16 26H4a2 2 0 010-4h12a2 2 0 010 4z" /></Svg>);
export const ElementHtmlIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M4 26a2 2 0 01-1.53-3.29l11-13a2 2 0 113.06 2.58l-11 13A2 2 0 014 26z" /><path d="M15 39a2 2 0 01-1.53-.71l-11-13a2 2 0 113.06-2.58l11 13A2 2 0 0115 39zM44 26a2 2 0 01-1.53-.71l-11-13a2 2 0 113.06-2.58l11 13A2 2 0 0144 26z" /><path d="M33 39a2 2 0 01-1.53-3.29l11-13a2 2 0 113.06 2.58l-11 13A2 2 0 0133 39zM19 44a2 2 0 01-1.93-2.54l10-36a2 2 0 113.86 1.08l-10 36A2 2 0 0119 44z" /></Svg>);
export const ElementImageIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M40 46H8a6 6 0 01-6-6V8a6 6 0 016-6h32a6 6 0 016 6v32a6 6 0 01-6 6zM8 6a2 2 0 00-2 2v32a2 2 0 002 2h32a2 2 0 002-2V8a2 2 0 00-2-2z" /><path d="M4 32a2 2 0 01-1.4-.6 1.9 1.9 0 010-2.8l12-12a1.9 1.9 0 012.8 0l11 11a2 2 0 01-2.8 2.8L16 20.8 5.4 31.4A2 2 0 014 32z" /><path d="M13 46a2 2 0 01-1.4-.6 1.9 1.9 0 010-2.8l19-19a1.9 1.9 0 012.8 0l12 12a2 2 0 01-2.8 2.8L32 27.8 14.4 45.4a2 2 0 01-1.4.6z" /></Svg>);
export const ElementInputFieldIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M10 37H8a6 6 0 01-6-6V17a6 6 0 016-6h2a2 2 0 010 4H8a2 2 0 00-2 2v14a2 2 0 002 2h2a2 2 0 010 4zM40 37H26a2 2 0 010-4h14a2 2 0 002-2V17a2 2 0 00-2-2H26a2 2 0 010-4h14a6 6 0 016 6v14a6 6 0 01-6 6zM18 46a2 2 0 01-2-2V4a2 2 0 014 0v40a2 2 0 01-2 2z" /><g><path d="M22 6h-8a2 2 0 010-4h8a2 2 0 010 4zM22 46h-8a2 2 0 010-4h8a2 2 0 010 4z" /></g></Svg>);
export const ElementListClickThroughIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M32 14H4a2 2 0 010-4h28a2 2 0 010 4zM32 26H4a2 2 0 010-4h28a2 2 0 010 4zM32 38H4a2 2 0 010-4h28a2 2 0 010 4zM40.1 15V8.9a.4.4 0 01.7-.3l3 3a.4.4 0 010 .6l-3 3a.4.4 0 01-.7-.2zM40.1 27v-6.1a.4.4 0 01.7-.3l3 3a.4.4 0 010 .6l-3 3a.4.4 0 01-.7-.2zM40.1 39v-6.1a.4.4 0 01.7-.3l3 3a.4.4 0 010 .6l-3 3a.4.4 0 01-.7-.2z" /></Svg>);
export const ElementListDropdownIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M32 16H4a2 2 0 010-4h28a2 2 0 010 4zM32 26H4a2 2 0 010-4h28a2 2 0 010 4zM32 36H4a2 2 0 010-4h28a2 2 0 010 4zM39 12h6a.4.4 0 01.3.7l-3 3a.4.4 0 01-.6 0l-3-3a.4.4 0 01.3-.7zM39 22h6a.4.4 0 01.3.7l-3 3a.4.4 0 01-.6 0l-3-3a.4.4 0 01.3-.7zM39 32h6a.4.4 0 01.3.7l-3 3a.4.4 0 01-.6 0l-3-3a.4.4 0 01.3-.7z" /></Svg>);
export const ElementNumberIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M44 6H4a2 2 0 010-4h40a2 2 0 010 4zM44 46H4a2 2 0 010-4h40a2 2 0 010 4zM24 34a2 2 0 01-2-2V20.8l-.6.6a2 2 0 01-2.8-2.8l4-4a1.8 1.8 0 012.2-.4A2 2 0 0126 16v16a2 2 0 01-2 2z" /><path d="M28 34h-8a2 2 0 010-4h8a2 2 0 010 4z" /></Svg>);
export const ElementNumberDecimalIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M44 6H4a2 2 0 010-4h40a2 2 0 010 4zM44 46H4a2 2 0 010-4h40a2 2 0 010 4z" /><circle cx="24" cy="32" r="2" /><path d="M12 34a2 2 0 01-2-2V20.8l-.6.6a2 2 0 01-2.8-2.8l4-4a1.8 1.8 0 012.2-.4A2 2 0 0114 16v16a2 2 0 01-2 2z" /><path d="M16 34H8a2 2 0 010-4h8a2 2 0 010 4zM36 34a2 2 0 01-2-2V20.8l-.6.6a2 2 0 01-2.8-2.8l4-4a1.8 1.8 0 012.2-.4A2 2 0 0138 16v16a2 2 0 01-2 2z" /><path d="M40 34h-8a2 2 0 010-4h8a2 2 0 010 4z" /></Svg>);
export const ElementPerformanceIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><g data-name="ic text block"><path d="M40 46H8a6 6 0 01-6-6v-8a2 2 0 014 0v8a2 2 0 002 2h32a2 2 0 002-2v-8a2 2 0 014 0v8a6 6 0 01-6 6zM44 18a2 2 0 01-2-2V8a2 2 0 00-2-2H8a2 2 0 00-2 2v8a2 2 0 01-4 0V8a6 6 0 016-6h32a6 6 0 016 6v8a2 2 0 01-2 2z" /><circle cx="4" cy="24" r="2" /><circle cx="44" cy="24" r="2" /><path d="M24 22.28c-5.47 0-11-2.06-11-6s5.53-6 11-6 11 2.06 11 6-5.5 6-11 6zm0-8c-4.4 0-6.83 1.48-7 2 .18.48 2.61 2 7 2s6.7-1.41 7-2c-.26-.59-2.67-2-7-2zm7 2z" /><path d="M24 30.28c-5.47 0-11-2.06-11-6v-8a2 2 0 114 0v8c.17.52 2.6 2 7 2s6.82-1.48 7-2v-8a2 2 0 014 0v8c0 3.94-5.5 6-11 6z" /><path d="M24 38.28c-5.47 0-11-2.06-11-6v-8a2 2 0 014 0v8c.17.52 2.6 2 7 2s6.82-1.48 7-2v-8a2 2 0 014 0v8c0 3.94-5.5 6-11 6z" /></g></Svg>);
export const ElementPlaceholderIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M38 2H10c-4.4 0-8 3.6-8 8v28c0 4.4 3.6 8 8 8h28c4.4 0 8-3.6 8-8V10c0-4.4-3.6-8-8-8zM26.8 24l15-15c.1.3.1.7.1 1v28c0 .4-.1.7-.1 1l-15-15zM38 6c.4 0 .7.1 1 .1l-15 15-15-15c.3 0 .6-.1 1-.1h28zM6.1 39c0-.3-.1-.6-.1-1V10c0-.4.1-.7.1-1l15 15-15 15zm3.9 3c-.4 0-.7-.1-1-.1l15-15 15 15c-.3.1-.7.1-1 .1H10z" /></Svg>);
export const ElementRadioButtonIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M24 46A22 22 0 018.4 8.5 21.9 21.9 0 0124 2a22 22 0 0115.6 37.5A21.9 21.9 0 0124 46zm0-40a18 18 0 00-12.7 30.8A18.2 18.2 0 0024 42a18 18 0 0012.7-30.8A18.2 18.2 0 0024 6z" /><circle cx="24" cy="24" r="7" /></Svg>);
export const ElementRelationsIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M8 14a6 6 0 116-6 6 6 0 01-6 6zm0-8a2 2 0 102 2 2 2 0 00-2-2zM40 18a6 6 0 116-6 6 6 0 01-6 6zm0-8a2 2 0 102 2 2 2 0 00-2-2zM40 42a6 6 0 116-6 6 6 0 01-6 6zm0-8a2 2 0 102 2 2 2 0 00-2-2zM16 46a6 6 0 116-6 6 6 0 01-6 6zm0-8a2 2 0 102 2 2 2 0 00-2-2zM24 30a6 6 0 116-6 6 6 0 01-6 6zm0-8a2 2 0 102 2 2 2 0 00-2-2z" /><path d="M21 23a2 2 0 01-1.4-.6l-10-10a2 2 0 012.8-2.8l10 10a1.9 1.9 0 010 2.8 2 2 0 01-1.4.6zM18 38l-.9-.2a2 2 0 01-.9-2.7l4-8a2 2 0 113.6 1.8l-4 8A2.1 2.1 0 0118 38zM36 35a1.9 1.9 0 01-1.2-.4l-8-6a2 2 0 012.4-3.2l8 6a2 2 0 01.4 2.8 2.1 2.1 0 01-1.6.8zM28 23a2.1 2.1 0 01-1.6-.8 2 2 0 01.4-2.8l8-6a2 2 0 112.4 3.2l-8 6a1.9 1.9 0 01-1.2.4z" /></Svg>);
export const ElementSectieIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M38 2H10c-4.4 0-8 3.6-8 8v28c0 4.4 3.6 8 8 8h28c4.4 0 8-3.6 8-8V10c0-4.4-3.6-8-8-8zm4 36c0 2.2-1.8 4-4 4H10c-2.2 0-4-1.8-4-4V10c0-2.2 1.8-4 4-4h28c2.2 0 4 1.8 4 4v28z" /><path d="M32 22h-6v-6c0-1.1-.9-2-2-2s-2 .9-2 2v6h-6c-1.1 0-2 .9-2 2s.9 2 2 2h6v6c0 1.1.9 2 2 2s2-.9 2-2v-6h6c1.1 0 2-.9 2-2s-.9-2-2-2z" /></Svg>);
export const ElementSubNavigationIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M40 46H20a6 6 0 010-12h20a6 6 0 010 12zm-20-8a2 2 0 000 4h20a2 2 0 000-4zM40 30H20a6 6 0 010-12h20a6 6 0 010 12zm-20-8a2 2 0 000 4h20a2 2 0 000-4zM40 14H8A6 6 0 018 2h32a6 6 0 010 12zM8 6a2 2 0 000 4h32a2 2 0 000-4z" /><path d="M16 26H8a2 2 0 01-2-2V12a2 2 0 014 0v10h6a2 2 0 010 4z" /><path d="M16 42H8a2 2 0 01-2-2V24a2 2 0 014 0v14h6a2 2 0 010 4z" /></Svg>);
export const ElementTabbladIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M38 2H10c-4.4 0-8 3.6-8 8v28c0 4.4 3.6 8 8 8h28c4.4 0 8-3.6 8-8V10c0-4.4-3.6-8-8-8zm4 8v5H24v-5c0-1.5-.4-2.8-1.1-4H38c2.2 0 4 1.8 4 4zm0 28c0 2.2-1.8 4-4 4H10c-2.2 0-4-1.8-4-4V10c0-2.2 1.8-4 4-4h6c2.2 0 4 1.8 4 4v7c0 1.1.9 2 2 2h20v19z" /></Svg>);
export const ElementTableIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M40 41H8a6 6 0 01-6-6V13a6 6 0 016-6h32a6 6 0 016 6v22a6 6 0 01-6 6zM8 11a2 2 0 00-2 2v22a2 2 0 002 2h32a2 2 0 002-2V13a2 2 0 00-2-2z" /><path d="M44 21H4a2 2 0 010-4h40a2 2 0 010 4zM44 31H4a2 2 0 010-4h40a2 2 0 010 4z" /><path d="M14 41a2 2 0 01-2-2V9a2 2 0 014 0v30a2 2 0 01-2 2z" /></Svg>);
export const ElementTableDatabaseIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><g data-name="Tabel Database"><path d="M35 14c-5.47 0-11-2.06-11-6s5.53-6 11-6 11 2.06 11 6-5.53 6-11 6zm0-8c-4.39 0-6.82 1.48-7 2 .18.48 2.61 2 7 2s6.71-1.41 7-2c-.29-.59-2.71-2-7-2zm7 2z" /><path d="M35 22c-5.47 0-11-2.06-11-6V8a2 2 0 014 0v8c.18.52 2.61 2 7 2s6.82-1.48 7-2V8a2 2 0 014 0v8c0 3.94-5.53 6-11 6z" /><path d="M35 30c-5.47 0-11-2.06-11-6v-8a2 2 0 014 0v8c.18.52 2.61 2 7 2s6.82-1.48 7-2v-8a2 2 0 014 0v8c0 3.94-5.53 6-11 6zM14 44a2 2 0 01-2-2V18a2 2 0 014 0v24a2 2 0 01-2 2z" /><path d="M40 44H8a6 6 0 01-6-6V22a6 6 0 016-6h11.94a2 2 0 010 4H8a2 2 0 00-2 2v10h38a2 2 0 012 2v4a6 6 0 01-6 6zM6 36v2a2 2 0 002 2h32a2 2 0 002-2v-2z" /><path d="M20 28H4a2 2 0 010-4h16a2 2 0 010 4z" /></g></Svg>);
export const ElementTextBlockIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M40 46H8a6 6 0 01-6-6v-8a2 2 0 014 0v8a2 2 0 002 2h32a2 2 0 002-2v-8a2 2 0 014 0v8a6 6 0 01-6 6zM44 18a2 2 0 01-2-2V8a2 2 0 00-2-2H8a2 2 0 00-2 2v8a2 2 0 01-4 0V8a6 6 0 016-6h32a6 6 0 016 6v8a2 2 0 01-2 2z" /><path d="M24 33a2 2 0 01-2-2V17a2 2 0 014 0v14a2 2 0 01-2 2z" /><path d="M31 19H17a2 2 0 010-4h14a2 2 0 010 4z" /><circle cx="4" cy="24" r="2" /><circle cx="44" cy="24" r="2" /></Svg>);
export const ElementTextLayoutIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M13 38a1.5 1.5 0 01-1.1-.4 1.8 1.8 0 01-.5-2.7l22-32a1.8 1.8 0 012.7-.5 1.8 1.8 0 01.5 2.7l-22 32a1.8 1.8 0 01-1.6.9z" /><path d="M35 38a2 2 0 01-2-2V4a2 2 0 014 0v32a2 2 0 01-2 2z" /><path d="M35 28H20a2 2 0 010-4h15a2 2 0 010 4zM35 46H13a2 2 0 010-4h22a2 2 0 010 4z" /></Svg>);
export const ElementTileMenuIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><g id="Tile_menu" data-name="Tile menu"><path d="M20,22H4a2,2,0,0,1-2-2V4A2,2,0,0,1,4,2H20a2,2,0,0,1,2,2V20A2,2,0,0,1,20,22ZM6,18H18V6H6Z" /><path d="M44,22H28a2,2,0,0,1-2-2V4a2,2,0,0,1,2-2H44a2,2,0,0,1,2,2V20A2,2,0,0,1,44,22ZM30,18H42V6H30Z" /><path d="M20,46H4a2,2,0,0,1-2-2V28a2,2,0,0,1,2-2H20a2,2,0,0,1,2,2V44A2,2,0,0,1,20,46ZM6,42H18V30H6Z" /><path d="M44,46H28a2,2,0,0,1-2-2V28a2,2,0,0,1,2-2H44a2,2,0,0,1,2,2V44A2,2,0,0,1,44,46ZM30,42H42V30H30Z" /></g></Svg>);
export const ElementToggleIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><circle cx="31" cy="24" r="7" /><path d="M32 38H16a14 14 0 010-28h16a14 14 0 010 28zM16 14a10 10 0 000 20h16a10 10 0 000-20z" /></Svg>);
export const ElementUitlijnenHIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M44 22h-6V4c0-1.1-.9-2-2-2h-8c-1.1 0-2 .9-2 2v18h-4V4c0-1.1-.9-2-2-2h-8c-1.1 0-2 .9-2 2v18H4c-1.1 0-2 .9-2 2s.9 2 2 2h6v18c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V26h4v18c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V26h6c1.1 0 2-.9 2-2s-.9-2-2-2zM18 42h-4V6h4v36zm16 0h-4V6h4v36z" /></Svg>);
export const ElementUitlijnenVIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><path d="M44 22c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2H26V4c0-1.1-.9-2-2-2s-2 .9-2 2v6H4c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h18v4H4c-1.1 0-2 .9-2 2v8c0 1.1.9 2 2 2h18v6c0 1.1.9 2 2 2s2-.9 2-2v-6h18c1.1 0 2-.9 2-2v-8c0-1.1-.9-2-2-2H26v-4h18zm-2 12H6v-4h36v4zM6 14h36v4H6v-4z" /></Svg>);
export const ElementMapIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 1 511.99995 511" {...props}><path d="m510.769531 491.511719-90.347656-210.789063c-2.375-5.535156-7.820313-9.125-13.839844-9.125h-57.378906c23.222656-40.570312 42.320313-85.472656 42.320313-123.464844 0-195.648437-271.046876-198.035156-271.046876 0 0 37.992188 19.097657 82.894532 42.320313 123.464844h-57.378906c-6.023438 0-11.46875 3.589844-13.839844 9.125l-90.347656 210.789063c-4.25 9.914062 3.03125 20.988281 13.839843 20.988281h481.859376c10.785156 0 18.097656-11.050781 13.839843-20.988281zm-114.121093-189.800781 44.582031 104.007812c-1.394531.726562-146.496094 76.074219-147.535157 76.667969h-31.519531c-.796875-.890625 5.976563 5.917969-92.164062-92.210938l54.511719-27.898437c11.113281 14.183594 18.898437 23.195312 20.113281 24.59375 6 6.90625 16.730469 6.894531 22.722656 0 2.519531-2.898438 33.214844-38.464844 63.453125-85.160156zm-140.648438-271.019532c50.765625 0 105.40625 36.753906 105.40625 117.441406 0 67.84375-76.050781 168.808594-105.40625 205.246094-29.355469-36.441406-105.40625-137.402344-105.40625-205.246094 0-81.125 52.9375-117.441406 105.40625-117.441406zm-140.652344 271.019532c72.945313 0 64.65625.011718 65.824219-.023438 8.480469 13.097656 16.996094 25.320312 24.917969 36.195312l-136.101563 69.65625zm-63.933594 149.164062 90.429688-46.28125 77.804688 77.792969h-181.742188zm307.546876 31.511719 94.167968-48.914063 20.964844 48.914063zm0 0" /><path d="m301.171875 136.089844c0-24.90625-20.265625-45.171875-45.171875-45.171875-24.910156 0-45.175781 20.265625-45.175781 45.171875s20.265625 45.167968 45.175781 45.167968c24.90625 0 45.171875-20.261718 45.171875-45.167968zm-60.230469 0c0-8.304688 6.753906-15.058594 15.058594-15.058594 8.300781 0 15.058594 6.753906 15.058594 15.058594 0 8.300781-6.757813 15.054687-15.058594 15.054687-8.304688 0-15.058594-6.753906-15.058594-15.054687zm0 0" /></Svg>);
export const ElementPivotTableIcon = (props: ElementIconProps) => (<Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" {...props}><g id="ic_dropdown"><path d="M31,10h6a.4.4,0,0,1,.3.7l-3,3a.4.4,0,0,1-.6,0l-3-3A.4.4,0,0,1,31,10Z" /><path d="M16,30H12a2,2,0,0,1,0-4h4a2,2,0,0,1,0,4Z" /><path d="M16,38H12a2,2,0,0,1,0-4h4a2,2,0,0,1,0,4Z" /><path d="M20,46H8a6,6,0,0,1-6-6V8A6,6,0,0,1,8,2H40a6,6,0,0,1,6,6V20a2,2,0,0,1-2,2H26V40A6,6,0,0,1,20,46ZM6,22V40a2,2,0,0,0,2,2H20a2,2,0,0,0,2-2V22Zm20-4H42V8a2,2,0,0,0-2-2H26ZM6,18H22V6H8A2,2,0,0,0,6,8Z" /></g></Svg>);
