import React from 'react';
import { Heading1, PageTitle, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { TextAssistantAI } from '@liasincontrol/ui-elements';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, License } from '@liasincontrol/userrights-service';
import { useTextAssistantSkills } from "@liasincontrol/redux-service";

/**
 * Defines the props of the Cockpit view component.
 */
type Props = {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the TextAssistant cockpit view.
 */
const Cockpit: React.FC<Props> = (props) => {
    const hasArtificialIntelligenceAvailable = UserRightsService.getInstance().userHasLicence(props.userIdentity, License.AITextAssistant);
    const textAssistantSkills = useTextAssistantSkills(hasArtificialIntelligenceAvailable);

    return (<Wrapper>
        <WrapperContent maxAvailable={true}>
            <PageTitle>
                <Heading1>LIAS Assistant</Heading1>
            </PageTitle>
            {hasArtificialIntelligenceAvailable &&
                <TextAssistantAI
                    textAssistantSkills={textAssistantSkills?.items}
                    standAlone={true}
                />}
        </WrapperContent>
    </Wrapper>
    );
};

export { Cockpit as index };
