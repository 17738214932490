import styled from 'styled-components';
import { DropDownBox } from 'devextreme-react/drop-down-box';
import { palette } from '@liasincontrol/ui-basics';

const StyledDropDownBox = styled(DropDownBox)`
    background-color: ${palette.white} !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
`;

const styledComponents = { StyledDropDownBox };

export default styledComponents;
