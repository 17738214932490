import styled from 'styled-components';
import { ColorUtils } from '@liasincontrol/core-service';
import { palette } from '@liasincontrol/ui-basics';

export const ChartWrapper = styled.div <{ primaryColor?: string, primaryTextColor?: string }>`
    border: 1px solid ${palette.grey4};

    .lias-toolbar-button.dx-button-mode-contained {
        border-radius: 2px;
        border: 1px solid #949494;

        &.dx-button-primary {
            border-color: ${({ primaryTextColor }) => primaryTextColor ? primaryTextColor : palette.grey2}; 
            color: ${({ primaryTextColor }) => primaryTextColor ? primaryTextColor : palette.grey2};  
            background-color: ${({ primaryColor }) => primaryColor ? primaryColor : palette.white};  
       
            .dx-icon {
                font-size: 1.2rem;
                color: ${({ primaryTextColor }) => primaryTextColor ? primaryTextColor : palette.grey2};  
            }

            &:hover, &:focus {
                background-color: ${({ primaryColor }) => primaryColor ? ColorUtils.shadeColor(primaryColor, -8) : '#ccc'};       
            }
        }
  }
`;