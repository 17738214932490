import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class TitleElement extends BaseControlElement {

    constructor(text?: string, styling?: string) {
        super();
        this.text = text;
        this.styling = styling;
    }

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TextualContent)
    text = '';

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TitleStyling)
    styling: string;

}

export default TitleElement;