import React from 'react';
import { ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';

type Props = { 
    title: string,
    text: string,
    onCloseDialog: () => void 
};

/**
 * Represents a UI component that renders timeout message modal dialog.
 */
export const TimeoutMessageDialog: React.FC<Props> = (props) => {
    return (
        <ModalDialog settings={{
            look: 'interactive',
            title: props.title,
            footer: <ModalDialogFooter rightButtonText='Sluiten' onRightButtonClick={props.onCloseDialog} />
        }}>
            <Text value={props.text} />
        </ModalDialog >
    );
};
