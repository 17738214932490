import React, { useEffect, useState } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { CardHeaderTab, EditingToolbar, IconSize, ModalDialog, WarningWrapper, WarningLook, SVGIcon } from '@liasincontrol/ui-basics';
import { TextElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import Styled from './index.styled';
import FieldPickerModal from './FieldPickerModal';
import { buildDetailsCardLayoutString, DetailCardLayout, isTouched } from '../../../../_shared/Renderer/ElementDetailsForm/utils';
import { ElementDetailsForm, initFormaData, StudioFormMode } from '../../../../_shared/Renderer/ElementDetailsForm';
import { SideToolbar } from './SideToolbar';

type DesignModalProps = {
    isOpen: boolean,
    elementDefinition: Domain.Shared.ElementDefinition,
    detailsCard: Domain.Shared.DetailCard;
    allRequiredFieldsUsed: boolean;
    icons?: Record<string, Domain.Shared.SvgIcon>;
    onCancel: () => void,
    onSave: (detailCard: Domain.Shared.DetailCard) => void,
};

enum CardTabs {
    Details = "Details",
}

/**
 * Represents a UI component that renders the element definitions layout design form .
 */
const DesignModal: React.FC<DesignModalProps> = (props) => {
    const [selectedCardTab, setSelectedCardTab] = useState<CardTabs>(CardTabs.Details);
    const [edgeToolbarSettings, setEdgeToolbarSettings] = useState<{ isToolbarExpanded: boolean }>({ isToolbarExpanded: true });
    const [openFieldPickerModal, setOpenFieldPickerModal] = useState<{ currentColumnIndex: number, isOpen: boolean }>({ currentColumnIndex: -1, isOpen: false });
    const [fieldDefinitions, setFieldControls] = useState<{ unusedFieldDefinitions: Domain.Shared.FieldDefinition[], selectedFieldDefinition: Domain.Shared.FieldDefinition, usedAllRequired: boolean }>({ unusedFieldDefinitions: [], selectedFieldDefinition: undefined, usedAllRequired: false });
    const [layout, setLayout] = useState<DetailCardLayout>(JSON.parse(props.detailsCard.layout));

    useEffect(() => {
        if (!props.elementDefinition || !props.detailsCard)
            return;
        const propsLayout = JSON.parse(props.detailsCard.layout) as DetailCardLayout;
        const unusedFieldDefinitions = getUnusedFieldDefinitions(props.elementDefinition.fields, propsLayout);
        setLayout(propsLayout);
        setFieldControls({ unusedFieldDefinitions: unusedFieldDefinitions, selectedFieldDefinition: undefined, usedAllRequired: props.allRequiredFieldsUsed });
    }, [props.elementDefinition, props.detailsCard, props.allRequiredFieldsUsed]);

    //#region handlers
    const getUnusedFieldDefinitions = (fields: Domain.Shared.FieldDefinition[], layout: DetailCardLayout) => {
        const fieldDefinitionsInLayout = layout.columns.flatMap((column) => props.elementDefinition.fields.filter((field) => column.fieldIds?.find((fieldId) => fieldId === field.id)));
        const unusedFieldDefinitions = fields.filter((field) => field.systemId === Domain.SystemFieldDefinitions.Studio.Dynamic && fieldDefinitionsInLayout.indexOf(field) < 0);

        return unusedFieldDefinitions;
    };

    const addFieldToLayout = (fieldId: string, columnIndex: number) => {
        setLayout((oldState) => {
            const fieldAlreadyExistsInLayout = oldState.columns.find((column) => column.fieldIds.find((id) => id === fieldId));
            if (!oldState.columns[columnIndex].fieldIds || fieldAlreadyExistsInLayout)
                return oldState;
            oldState.columns[columnIndex].fieldIds = [...oldState.columns[columnIndex].fieldIds, fieldId];
            return oldState;
        });
        const addedFieldDefinition = fieldDefinitions.unusedFieldDefinitions.find((item) => item.id === fieldId);
        setFieldControls((oldState) => {
            const unusedFieldDefinitions = oldState.unusedFieldDefinitions.filter((item) => item.id !== addedFieldDefinition.id);
            return { unusedFieldDefinitions: unusedFieldDefinitions, selectedFieldDefinition: addedFieldDefinition, usedAllRequired: !unusedFieldDefinitions.some(fieldDefinition => fieldDefinition.required) };
        });
    };

    const moveField = (columnIndex: number, fieldIndex: number, moveUp: boolean, field: Domain.Shared.FieldDefinition) => {
        setLayout((oldState) => {
            const buffer = oldState.columns[columnIndex].fieldIds[fieldIndex];
            if (moveUp) {
                oldState.columns[columnIndex].fieldIds[fieldIndex] = oldState.columns[columnIndex].fieldIds[fieldIndex - 1];
                oldState.columns[columnIndex].fieldIds[fieldIndex - 1] = buffer;
            }
            else {
                oldState.columns[columnIndex].fieldIds[fieldIndex] = oldState.columns[columnIndex].fieldIds[fieldIndex + 1];
                oldState.columns[columnIndex].fieldIds[fieldIndex + 1] = buffer;
            }

            return oldState;
        });
        setFieldControls((oldState) => ({ ...oldState, selectedFieldDefinition: field }));
    };

    const deleteField = (columnIndex: number, fieldIndex: number) => {
        setLayout((oldState) => {
            oldState.columns[columnIndex].fieldIds.splice(fieldIndex, 1);
            return oldState;
        });
        const unusedFieldDefinitions = getUnusedFieldDefinitions(props.elementDefinition.fields, layout);
        setFieldControls({
            unusedFieldDefinitions: unusedFieldDefinitions,
            selectedFieldDefinition: undefined,
            usedAllRequired: !unusedFieldDefinitions.some(fieldDefinition => fieldDefinition.required),
        });
    };
    //#endregion handlers

    const cardHeaderTabElements: JSX.Element[] = Object.keys(CardTabs).map((cardTab) => {
        return (
            <CardHeaderTab
                id={`btn-nav-${cardTab}`}
                key={cardTab}
                active={selectedCardTab === CardTabs[cardTab]}
                onClick={() => setSelectedCardTab(CardTabs[cardTab])}
                title={CardTabs[cardTab]} />
        );
    });

    const numberFieldDefinition = props.elementDefinition.fields.find((fd) => fd.systemId === Domain.SystemFieldDefinitions.Studio.Number);
    const nameFieldDefinition = props.elementDefinition.fields.find((fd) => fd.systemId === Domain.SystemFieldDefinitions.Studio.Name);

    return (
        <ModalDialog
            customPadding
            edgeToolbar={
                <SideToolbar
                    isOpen={edgeToolbarSettings.isToolbarExpanded}
                    title='Kaart inrichten'
                    selectedFieldDefinition={fieldDefinitions.selectedFieldDefinition}
                    onToggle={() => setEdgeToolbarSettings((oldState) => ({ ...oldState, isToolbarExpanded: !oldState.isToolbarExpanded }))}
                />
            }
            toolbars={
                <EditingToolbar
                    isValid={fieldDefinitions.usedAllRequired && isTouched(props.detailsCard.layout, layout)}
                    disabled={!fieldDefinitions.usedAllRequired && !isTouched(props.detailsCard.layout, layout)}
                    showSaveButton={true}
                    isVisible={true}
                    onSave={() => {
                        const widths = layout.columns.map((column) => column.width);
                        const stringifiedLayout = buildDetailsCardLayoutString(layout.columns.length, layout.columns, widths, layout.version);
                        const detailCard = { ...props.detailsCard, layout: stringifiedLayout } as Domain.Shared.DetailCard;
                        props.onSave(detailCard);
                    }}
                    onCancel={props.onCancel}
                    readonly={false}
                />
            }
        >
            <Styled.CustomSection isToolbarExpanded={edgeToolbarSettings.isToolbarExpanded} look='white' padding={false}>
                <Styled.Grid>
                    <Styled.HeaderForm toolbarOpen={edgeToolbarSettings.isToolbarExpanded}>
                        <SVGIcon value={props.icons[props.elementDefinition.icon]?.svg} size={IconSize.medium} color={props.elementDefinition.color} />
                        <Styled.StyledLabel>{props.elementDefinition.name}</Styled.StyledLabel>
                    </Styled.HeaderForm>
                    {!fieldDefinitions.usedAllRequired && (
                        <Styled.Warning>
                            <WarningWrapper
                                look={WarningLook.dangerInverted}
                                icon={<WarningAmberIcon />}
                                className='mb-100 p-025'
                                messageText='Let op: Niet alle verplichte velden staan op de kaart.' />
                        </Styled.Warning>
                    )}
                    <Styled.HeaderNumber>
                        <TextElement
                            id={`input-nummer-${numberFieldDefinition.id}`}
                            label={numberFieldDefinition.label ? numberFieldDefinition.label : numberFieldDefinition.name}
                            editorSettings={{
                                disabled: true,
                                validationErrors: undefined,
                                restrictions: { required: true },
                                onChange: noOp,
                            }}
                            value='123...'
                        />
                    </Styled.HeaderNumber>
                    <Styled.HeaderName>
                        <TextElement
                            id={`input-nummer-${nameFieldDefinition.id}`}
                            label={nameFieldDefinition.label ? nameFieldDefinition.label : nameFieldDefinition.name}
                            editorSettings={{
                                disabled: true,
                                validationErrors: undefined,
                                restrictions: { required: true },
                                onChange: noOp,
                            }}
                            value='abc...'
                        />
                    </Styled.HeaderName>
                    <Styled.ContentHeaderBar />
                    <Styled.ContentHeader>{cardHeaderTabElements}</Styled.ContentHeader>
                    {selectedCardTab === CardTabs.Details && (
                        <ElementDetailsForm
                            elementDefinition={props.elementDefinition}
                            detailLayout={layout}
                            mode={StudioFormMode.Design}
                            designData={{
                                form: initFormaData(fieldDefinitions.unusedFieldDefinitions.map((item) => item)),
                                selectedControl: fieldDefinitions.selectedFieldDefinition?.id,
                                onSelect: (columnIndex: number, fieldIndex: number) => {
                                    const field = props.elementDefinition.fields.find((item) => item.id === layout.columns[columnIndex].fieldIds[fieldIndex]);
                                    setFieldControls((oldState) => ({ ...oldState, selectedFieldDefinition: field }));
                                },
                                onAddElement: (columnIndex: number) => setOpenFieldPickerModal({ currentColumnIndex: columnIndex, isOpen: true }),
                                onMoveElement: (columnIndex: number, fieldIndex: number, moveUp: boolean) => {
                                    const field = props.elementDefinition.fields.find((item) => item.id === layout.columns[columnIndex].fieldIds[fieldIndex]);
                                    moveField(columnIndex, fieldIndex, moveUp, field);
                                },
                                onRemoveElement: (columnIndex: number, fieldIndex: number) => deleteField(columnIndex, fieldIndex),
                            }}
                        />
                    )}
                </Styled.Grid>
            </Styled.CustomSection>
            {openFieldPickerModal.isOpen && (
                <FieldPickerModal
                    columnIndex={openFieldPickerModal.currentColumnIndex}
                    isVisible={openFieldPickerModal.isOpen}
                    fieldDefinitions={fieldDefinitions.unusedFieldDefinitions}
                    closeModal={() => setOpenFieldPickerModal({ currentColumnIndex: -1, isOpen: false })}
                    onAddingExistingField={addFieldToLayout}
                />
            )}
        </ModalDialog>
    );
}

const noOp = () => { return; };

export default DesignModal;