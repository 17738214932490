import styled from 'styled-components';
import List from 'devextreme-react/list';
import { palette, cssFontNormal, cssFontSemiBold, px } from '@liasincontrol/ui-basics';

const StyledList = styled(List)`
    ${cssFontNormal(14, 20, 30)};
    
    .dx-scrollable-wrapper {
        border: 1px solid ${palette.grey4};
    }

    .dx-list-item {
        border-top: none;
        color: #333;
    }

    .dx-texteditor.dx-editor-outlined {
        border: 1px solid ${palette.grey4};
    }
`;

const StyledSelected = styled.div`
    border: 1px solid ${palette.grey4};
    background: ${palette.grey4};
    padding: ${px(12)} ${px(16)};
    margin-bottom: ${px(12)};
    .caption {
        ${cssFontSemiBold(14, 19, 30)}
    }
`;

const StyledHeader = styled.div`
    margin: ${px(8)} 0px;
`;

const styledComponents = { StyledList, StyledSelected, StyledHeader };

export default styledComponents;
