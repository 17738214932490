import styled from 'styled-components';

const StatusFilter = styled.div`
    width: 320px;

    .isclosed-toggle {
        display: inline-block;
    }
    
    .isclosed-label {
        margin-right: 1rem;
    }
`;

export { StatusFilter };