import React, { useState } from 'react';
import * as _ from 'lodash';
import { FormHelper, FormMode, TextValidator, ValidationErrorData, ValueType } from '@liasincontrol/core-service';
import { TextElement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { LayoutField, LayoutForm, ModalDialog, ModalDialogFooter } from '@liasincontrol/ui-basics';

type Props = {
    budgetJournalGroup?: Domain.Finance.BudgetJournalGroup,
    formMode: FormMode;
    isBusy: boolean;
    onSave: (budgetJournalKind: Domain.Finance.BudgetJournalGroup, formMode?: FormMode) => void,
    onCancel: () => void,
    onError: (err: any) => void,
};

/**
 * Represents a UI component that renders the modal for managing a budget journal group item.
 */
export const BudgetJournalGroupForm: React.FC<Props> = (props) => {
    const [form, setForm] = useState<{ formData: Domain.Finance.BudgetJournalGroup, isTouched: boolean }>(initForm(props.budgetJournalGroup));
    const [validationErrors, setValidationErrors] = useState<{
        errors: Record<string, ValidationErrorData[]>,
        hasErrors: boolean,
    }>({ errors: {}, hasErrors: false });


    const onChange = (value: string | number | string[], fieldName: string) => {
        const data: Domain.Finance.BudgetJournalGroup = { ...form.formData };
        if (data[fieldName] === value) {
            return;
        }

        data[fieldName] = value;
        setForm({ formData: data, isTouched: true });

        const temporaryValidationError = _.cloneDeep(validationErrors);
        const validationResult = validate(data, validationErrors.errors);
        temporaryValidationError.errors[fieldName] = validationResult.errors[fieldName];
        temporaryValidationError.hasErrors = validationResult.hasErrors;
        setValidationErrors(temporaryValidationError);
    };

    const footerElement = (
        <ModalDialogFooter
            leftButtonText='Annuleren'
            onLeftButtonClick={props.onCancel}
            rightButtonText='Opslaan'
            onRightButtonClick={() => props.onSave(form.formData, props.formMode)}
            rightButtonDisabled={props.isBusy || props.formMode === FormMode.View || validationErrors.hasErrors || !form.isTouched}
        />
    );

    const getTitle = (mode: FormMode) => {
        switch (mode) {
            case FormMode.AddNew:
                return 'Nieuwe Journaalgroep';
            case FormMode.Edit:
                return 'Journaalgroep bewerken';
            default:
                return 'Journaalgroep';
        }
    };

    return (
        <ModalDialog
            modalDialogStyle="custom"
            settings={{
                look: "interactive",
                title: getTitle(props.formMode),
                footer: footerElement,
            }}
        >
            <LayoutForm>
                <LayoutField left={1} top={2} width={6} height={1}>
                    <TextElement
                        id='name-field'
                        label='Naam'
                        editorSettings={{
                            disabled: props.formMode === FormMode.View,
                            validationErrors: validationErrors.errors['name'],
                            restrictions: { required: true, minLength: 2, maxLength: 100 },
                            onChange: (value: string) => onChange(value, 'name'),
                        }}
                        value={form?.formData?.name}
                    />
                </LayoutField>

            </LayoutForm>
        </ModalDialog>
    );
};

const initForm = (form: Domain.Finance.BudgetJournalGroup) => {
    return { formData: form || new Domain.Finance.BudgetJournalGroup(), isTouched: false };
};

const validate = (form: Domain.Finance.BudgetJournalGroup, errors: Record<string, ValidationErrorData[]>) => {
    const dictionary: Record<string, ValueType> = Object.keys(form).reduce((a, x) => ({ ...a, [x]: form[x] }), {});
    return FormHelper.validateForm(validators, dictionary, errors);
};

const validators = {
    'name': new TextValidator({ required: true, stringMaxLength: 100, stringType: Domain.Shared.StringType.SingleLine }),
};
