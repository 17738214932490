import styled from 'styled-components';
import { cssFontNormal, cssFontSemiBold, px } from '@liasincontrol/ui-basics';

const Wrapper = styled.div`
    margin-top: ${px(16)};
`;

const ItemList = styled.div`
    margin-top: ${px(16)};
    height: 100%;
    overflow-y: auto;
`;

const Item = styled.div`
    display: grid;
    grid-template-columns: auto min-content;
    grid-template-rows: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const Title = styled.div`
    grid-row: 1;
    grid-column: 1;
   
    display: flex;
    align-items: center;
    ${cssFontSemiBold(14, 16, 30)}
   
    span {
        ${cssFontNormal(14, 16, 30)}
        padding-left: ${px(8)}
    }
`;

const Actions = styled.div`
    grid-row: 1;
    grid-column: 2;
    cursor: pointer;
`;
const Detail = styled.div`
    ${cssFontNormal(14, 16, 30)}
    white-space: normal;
    grid-row: 2 / span 2;
    margin-bottom: ${px(8)};
`;

export { Item, ItemList, Title, Detail, Wrapper, Actions };