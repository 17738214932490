import React, { useMemo, useRef, useState } from 'react';
import {
    TreeList,
    Column,
    ITreeListOptions,
    IColumnProps,
    Toolbar,
    Item,
} from 'devextreme-react/tree-list';
import * as Domain from '@liasincontrol/domain';
import { IconSize } from '@liasincontrol/ui-basics';
import { groupCellRender, GroupRenderModeProps } from '../../helpers/renderModes';
import { CustomProps } from '@liasincontrol/ui-devextreme';
import { formatNumber } from 'devextreme/localization';
import _ from 'lodash';
import he from 'he';

export type LsTreeListProps = ITreeListOptions & {
    readonly icons?: Record<string, Domain.Shared.SvgIcon>,
    readonly iconSize?: IconSize,
    readonly showExpandCollapse?: boolean,
    readonly characterWidth?: number,
}

export type LsTreeColumnProps = IColumnProps & GroupRenderModeProps & CustomProps;

export const LsTreeList: React.FC<LsTreeListProps> = (props) => {
    const { columns, ...restProps } = props;
    const [expanded, setExpanded] = useState(false);
    const treeRef = useRef(null);

    const buttonOptions = useMemo(() => {
        return {
            text: (expanded ? 'ALLES INKLAPPEN' : 'ALLES UITKLAPPEN'),
            stylingMode: 'text',
            onClick: (e) => {
                e.event?.stopPropagation();
                setExpanded(prev => !prev);

                treeRef.current.instance().option("expandedRowKeys", []);
                treeRef.current.instance().option("autoExpandAll", !expanded);
            },
        };
    }, [expanded]);

    const mappedColumns = columns?.map((column) => {
        const mappedColumn = column as LsTreeColumnProps;

        if (mappedColumn.columnWidth && restProps.characterWidth) {
            //approximated width of a character * length + adjustments
            mappedColumn['width'] = `${(restProps.characterWidth * mappedColumn.columnWidth) + 24}px`;
            mappedColumn['autoFill'] = false;
        } else {
            mappedColumn['width'] = undefined;
            mappedColumn['autoFill'] = true;
        }
        return mappedColumn;
    }).filter(x => !_.isEmpty(x) || !x.visible);

    if (mappedColumns?.length && mappedColumns.length > 0) {
        const hasAutoColumn = mappedColumns.some((column) => column.visible && (!column.width || column.width === 'auto'));
        if (!hasAutoColumn) {
            const _i = mappedColumns.findIndex(col => col.visible);
            const index = _i > 0 ? _i : 0;
            mappedColumns[index]['width'] = undefined;
            mappedColumns[index]['columnWidth'] = undefined;
            mappedColumns[index]['autoFill'] = true;
        }
    }

    return <>
        <TreeList {...restProps}
            ref={treeRef}
            searchPanel={{
                visible: true,
                highlightCaseSensitive: false,
                searchVisibleColumnsOnly: true,
                placeholder: 'Zoeken...',
            }}
        >
            <Toolbar visible={true}>
                <Item visible={props.showExpandCollapse} locateInMenu="auto" widget="dxButton" options={buttonOptions} />
                <Item name="searchPanel" locateInMenu="auto" location="after" />
            </Toolbar>
            {mappedColumns?.map((column) => {
                const mappedColumn = column as LsTreeColumnProps;
                return (
                    <Column
                        {...mappedColumn}
                        cellRender={mappedColumn.groupRenderMode ? ((params) => groupCellRender(params.data, column, props.icons, props.iconSize)) : undefined}
                        key={mappedColumn.dataField}
                        minWidth={mappedColumn.minWidth || 64}
                        encodeHtml={!mappedColumn.groupRenderMode && mappedColumn.format !== "html"}
                        calculateSortValue={mappedColumn.groupRenderMode ? (rowData) => {
                            const sortValue = rowData[`${mappedColumn.propertyGroupName}Name`];
                            return sortValue;
                        } : undefined}
                        // DevExtreme: Do not use this (aka calculateDisplayValue) property to format text in cells. 
                        // Instead, use the format, customizeText, or cellTemplate property.
                        customizeText={mappedColumn.divide && mappedColumn.divide > 1 ? (cellInfo) => {
                            if (!cellInfo.value || !mappedColumn?.divide) {
                                return cellInfo.value?.toString() || '';
                            }

                            if (mappedColumn.divide > 1) {
                                const newValue = Math.round(cellInfo.value / mappedColumn.divide);
                                return (mappedColumn.format ? formatNumber(newValue, mappedColumn.format) : newValue.toString());
                            }
                            return cellInfo.valueText.toString();
                        } : undefined}
                        calculateCellValue={mappedColumn.groupRenderMode || mappedColumn.dataType === "string"
                            ? (rowData) => {
                                const cellValue = mappedColumn.groupRenderMode ? rowData[`${mappedColumn.propertyGroupName}Name`] : rowData[mappedColumn.dataField];
                                return he.decode(String(cellValue ?? ''));
                            } : undefined
                        }
                    />);
            })}
        </TreeList>
    </>
};
