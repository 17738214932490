import 'reflect-metadata';
import { SystemFieldDefinitions } from '.';

/**
 * Defines the field definition metadata key.
 */
const FieldDefinitionMetadataKey = Symbol("fieldDefinition");
/**
 * Defines the metadata key for normal or complex fields.
 */
const IsComplexFieldMetadataKey = Symbol("fieldDefinition");

/**
 * Defines a decorator that allows to set over a property a field definition unique identifier.
 * 
 * @param fieldDefinitionId The field definition unique identifier that will be set as metadata.
 */
function fieldDefinitionDecorator<T>(fieldDefinitionId: SystemFieldDefinitions.Pub | SystemFieldDefinitions.Core | SystemFieldDefinitions.Performance | SystemFieldDefinitions.Studio, complexField = false) {
    return function (target: T, propertyKey: string) {
        Reflect.defineMetadata(FieldDefinitionMetadataKey, fieldDefinitionId, target, propertyKey);
        Reflect.defineMetadata(IsComplexFieldMetadataKey, complexField, target, propertyKey);
    }
}

export { FieldDefinitionMetadataKey, IsComplexFieldMetadataKey, fieldDefinitionDecorator }