import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import * as Domain from '@liasincontrol/domain';
import { DxTemplate, DxTreeList, DxColumn } from '@liasincontrol/ui-devextreme';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import * as Styled from './index.styled';
import './index.style.less';

type Props = {
    sitemap: Domain.Publisher.Sitemap,
    allItemsExpanded: boolean,
    tasks: Domain.Publisher.WorkflowTask[],
};

type TableOFContentsItem = {
    elementId: string,
    elementName: string,
    parentId: string,
    taskCount?: number,
    isHomepage?: boolean,
    isParent?: boolean,
    hasChildrenWithOpenTasks?: boolean,
};

type TreeListEvent = {
    currentTarget: Element,
    data: any,
    delegateTarget: Element,
    target: Element,
    isDefaultPrevented(): boolean,
    isImmediatePropagationStopped(): boolean,
    isPropagationStopped(): boolean,
    preventDefault(): void,
    stopImmediatePropagation(): void,
    stopPropagation(): void,
};

/**
 * Represents a UI component that renders the table of contents of a publication.
 */
export const TableOfContents: React.FC<Props> = (props) => {
    const [tocItems, setTocItems] = useState<TableOFContentsItem[]>();
    const [openItemId, setOpenItemId] = useState<string>();
    const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
    const navigate = useNavigate();
    const { id, pageid } = useParams<{ id: string, pageid: string }>();

    useEffect(() => {
        if (!props.sitemap)
            return;

        let items: TableOFContentsItem[] = [{ elementId: props.sitemap.node.elementId, elementName: props.sitemap.node.elementName, parentId: defaultParentId, isHomepage: true, }];
        items = getTocItems(props.sitemap.node.children, items);

        if (props.tasks) {
            const pagesWithTasks = props.tasks.map(task => task.pageId);
            items = items.map((item) => {
                const taskCount = props.tasks.filter(task => task.pageId === item.elementId)?.length;
                if (item.isParent) {
                    const childrenIds = items.filter(childItem => childItem.parentId === item.elementId).map(childItem => childItem.elementId);
                    return { ...item, taskCount: taskCount, hasChildrenWithOpenTasks: pagesWithTasks.some(page => childrenIds.includes(page)) };
                }
                return { ...item, taskCount: taskCount };
            });
        }

        setTocItems(items);
    }, [props.sitemap, props.tasks]);

    const getItemParents = useCallback((childElement: TableOFContentsItem): string[] => {
        let parentId = childElement.parentId;
        const parents = [];
        while (parentId && parentId !== defaultParentId) {
            parents.push(parentId);
            const currentParentId = parentId;
            parentId = tocItems.find(item => item.elementId === currentParentId)?.parentId;
        }
        return parents;
    }, [tocItems]);

    useEffect(() => {
        let activeItems = [pageid];
        if (tocItems) {
            const selectedTocItem = tocItems.find(tocItem => tocItem.elementId === pageid);
            if (selectedTocItem.parentId && selectedTocItem.parentId !== defaultParentId) {
                activeItems = activeItems.concat(getItemParents(selectedTocItem));
            }
        }

        setExpandedRowKeys(activeItems);
        setOpenItemId(pageid);
    }, [getItemParents, pageid, tocItems]);

    useEffect(() => {
        if (!tocItems)
            return;

        if (props.allItemsExpanded) {
            setExpandedRowKeys(tocItems.map((tocItem) => (tocItem.elementId)));
        } else if (tocItems.length === expandedRowKeys.length) {
            setExpandedRowKeys([]);
        }
    }, [props.allItemsExpanded, tocItems, expandedRowKeys.length]);


    // #region handlers 

    const onTocItemClicked = ({ data, event: { target: { tagName } } }: { data: TableOFContentsItem, event: TreeListEvent }) => {
        /** The cell clicked event is also triggered when the expand/collapsed buttons are clicked.
         * The following condition makes a distinction between cell clicks and expand/collapse button clicks. */
        if (tagName.toLowerCase() !== 'span') {
            navigate(`/publisher/publication/${id}/writer/page/${data.elementId}`)
            setOpenItemId(data.elementId);
        }
    };

    const onRowCollapsing = ({ key }: { key: string }) => {
        setExpandedRowKeys((currentKeys) => currentKeys.filter((expandedKey) => expandedKey !== key));
    };

    const onRowExpanding = ({ key }: { key: string }) => {
        setExpandedRowKeys((currentKeys) => currentKeys.concat(key));
    };

    // #endregion handlers

    return (
        <DxTreeList
            id="toc-item-list"
            dataSource={tocItems}
            keyExpr='elementId'
            parentIdExpr='parentId'
            columnAutoWidth={false}
            wordWrapEnabled={false}
            autoExpandAll={false}
            onCellClick={onTocItemClicked}
            onRowExpanding={onRowExpanding}
            onRowCollapsing={onRowCollapsing}
            expandedRowKeys={expandedRowKeys}
            scrolling={{ mode: 'standard' }}
        >
            <DxColumn
                dataField='elementName'
                alignment='left'
                allowSorting={false}
                cellTemplate='tocItemTemplate'
                caption=''
            />

            <DxTemplate name='tocItemTemplate' render={({ data, row: { isExpanded } }) => getTocItemTemplate({ openItemId, data, row: { isExpanded } })} />
        </DxTreeList>
    );
};

const getTocItemTemplate: React.FC<{ openItemId: string, data: TableOFContentsItem, row: { isExpanded: boolean } }> = ({ openItemId, data, row: { isExpanded } }) => {
    let icon: JSX.Element = (<InsertDriveFileOutlinedIcon />);

    if (data.isHomepage) {
        icon = (<HomeOutlinedIcon />);
    } else if (data.isParent) {
        icon = (<FolderOutlinedIcon />);
        return (
            <Styled.TocParentItem
                hasTasks={data.hasChildrenWithOpenTasks}
                itemsExpanded={isExpanded}
                isActive={data.elementId === openItemId}>
                {icon}
                <Styled.TocLabelsWrapper>
                    <Styled.TocItemLabel title={data.elementName}>{data.elementName}</Styled.TocItemLabel>
                    <Styled.TasksWrapper>
                        {data.hasChildrenWithOpenTasks && <Styled.OpenTasksIndicator />}
                        {data.taskCount > 0 && <Styled.TocItemTaskCount>{data.taskCount}</Styled.TocItemTaskCount>}
                    </Styled.TasksWrapper>
                </Styled.TocLabelsWrapper>
            </Styled.TocParentItem>
        );
    }

    return (
        <Styled.TocChildItem
            hasTasks={data.taskCount > 0}
            isActive={data.elementId === openItemId}>
            <div>
                {icon}
            </div>
            <Styled.TocLabelsWrapper>
                <Styled.TocItemLabel title={data.elementName}>{data.elementName}</Styled.TocItemLabel>
                {data.taskCount > 0 && <Styled.TocItemTaskCount>{data.taskCount}</Styled.TocItemTaskCount>}
            </Styled.TocLabelsWrapper>
        </Styled.TocChildItem>);
};

const getTocItems = (pages: Domain.Publisher.SitemapNode[], tableOfContentsItems: TableOFContentsItem[], parentId?: string): TableOFContentsItem[] => {
    pages.forEach(page => {
        let isParent = false;
        if (page.children && page.children.length > 0) {
            isParent = true;
            getTocItems(page.children, tableOfContentsItems, page.elementId);
        }
        tableOfContentsItems.push({
            elementId: page.elementId, elementName: page.elementName, parentId: parentId || defaultParentId, isParent: isParent
        });
    });
    return tableOfContentsItems;
};

const defaultParentId = '0';