import React, { useCallback } from 'react';
import { withField } from '../field';
import { ImageFocusEditor } from '../image-focus';
import { ImageFocus, ImageFocusX, ImageFocusY, ImageFocusScale } from '../image-focus/editor';

export const FocusField = withField<{ imageUrl: string; focus: string }>(({ value: { focus, imageUrl }, onChange }) => {
    const initialPoint: ImageFocus = [Number(focus.split(',')[ImageFocusX]), Number(focus.split(',')[ImageFocusY]), Number(focus.split(',')[ImageFocusScale])];

    const onChangeCallBack = useCallback(({ point }) => onChange({
        focus: `${point[ImageFocusX]},${point[ImageFocusY]},${point[ImageFocusScale]}`,
        imageUrl: imageUrl
    }), [onChange, imageUrl]);

    return (
        <ImageFocusEditor
            contain={true}
            src={imageUrl}
            initialPoint={initialPoint}
            onChange={({ point }) => { onChange && onChangeCallBack({ point }) }}
            style={{ width: `100%`, maxHeight: 600 }}
        />
    );
});
