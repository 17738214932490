import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Add from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';

import * as Domain from '@liasincontrol/domain';
import { SystemElementDefinitions, SystemModuleDefinitions } from '@liasincontrol/domain';
import { WrapperContent, PageTitle, Heading1, Bar, Button, Section, ErrorOverlay } from '@liasincontrol/ui-basics';
import { ActionSource, ElementDefinitionsActionCreator, ModulesActionCreator, WorkflowTemplateActionCreator, State, AjaxRequestStatus } from '@liasincontrol/redux-service';
import { AppSettingsService } from '@liasincontrol/config-service';
import { guidToODataFieldId, oDataResponse, Shared as SharedDataAccess } from '@liasincontrol/data-service';
import { GridColumn, createSource, LsGrid, ContextMenu, FieldNameOf } from '@liasincontrol/ui-devextreme';
import { FieldsHelper, DefinitionsHelper, ApiErrorReportingHelper, ValueType } from '@liasincontrol/core-service';
import { WorkflowTemplateAdd } from './WorkflowTemplateAdd';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

type WorkflowTemplate = Domain.Shared.WorkflowTemplateElement & { id: string };

enum WorkflowStatusMode {
    New = "New",
    Changed = "Changed",
}
/**
 * Represents a UI component that renders the list of workflow definitions.
 */
const Index: React.FC<Props> = (props) => {
    const navigate = useNavigate();

    const [lastRefresh, setLastRefresh] = useState<number>();
    const [workflowFormDialog, setWorkFlowFormDialog] = useState<{ showDialog: boolean, isSaving: boolean }>({ showDialog: false, isSaving: false });
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const workflowNames = useMemo(() => props.workflowTemplates?.items.map(item => item.name), [props.workflowTemplates]);
    const workflowTemplateDefinition = useMemo(() => DefinitionsHelper.findElementDefinition(props.elementDefinitions?.items, SystemElementDefinitions.Pub.WorkflowTemplate), [props.elementDefinitions?.items]);

    const availableColumns: GridColumn<WorkflowTemplate>[] = getColumnConfiguration((item) => navigate(`/admin/workflow/${item.id}/information`));

    const [workflowsCount, setWorkflowsCount] = useState<number>(0);

    const customDataSource = useMemo(() => {
        if (!availableColumns || !workflowTemplateDefinition) {
            return;
        }

        const fieldsToDisplay: FieldNameOf<Domain.Shared.WorkflowTemplateElement>[] = availableColumns.map((column: GridColumn<Domain.Shared.WorkflowTemplateElement>) => column.name);
        const selectableColumnNames = DefinitionsHelper.getSelectableFieldsForAPI(new Domain.Shared.WorkflowTemplateElement(), fieldsToDisplay, workflowTemplateDefinition);

        const thenCallBack = (data: oDataResponse<Domain.Publisher.Element[]>) => {
            const items = data.value.map((workflow) => {
                const workflowObj = new Domain.Shared.WorkflowTemplateElement();
                // fill the settings with the data from the list of element fields based on field definitions:
                FieldsHelper.mapObject<Domain.Shared.WorkflowTemplateElement>(workflowObj, workflowTemplateDefinition.fields, workflow.fields);
                return { ...workflowObj, id: workflow.elementId };
            });

            setWorkflowsCount(items.length);
            return items;
        };

        const mapToField = (columnName: keyof Domain.Shared.WorkflowTemplateElement & string) => {
            const fieldId = DefinitionsHelper.fieldNameToFieldId(new Domain.Shared.WorkflowTemplateElement(), columnName, workflowTemplateDefinition);
            return guidToODataFieldId(fieldId || '');
        }

        const source = createSource<Domain.Publisher.Element>({
            paginate: true,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            selectedColumns: selectableColumnNames,
            dataSourcePromise: SharedDataAccess.WorkflowTemplate.get,
            thenCallBack,
            columnMapper: mapToField,
        });
        return source;
    }, [lastRefresh, workflowTemplateDefinition]);


    const addWorkflowTemplate = (workflow: Domain.Dto.Shared.CreateWorkflowWithState) => {
        SharedDataAccess.WorkflowTemplate.create(workflow)
            .then(() => {
                setLastRefresh(Date.now());
                props.fetchWorkflowTemplates();
            }).catch((err) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err));
            }).finally(() => {
                setWorkFlowFormDialog({ showDialog: false, isSaving: false })
            });
    };

    const addDummyWorkflow = () => {
        const defaultWorkFlow: Domain.Dto.Shared.CreateWorkflowWithState = {
            workflowName: 'LIAS InControl workflow',
            states: [
                {
                    name: 'Nieuw',
                    category: Domain.Shared.WorkflowCategoryOptionValues.New,
                },
                {
                    name: 'In uitvoering',
                    category: Domain.Shared.WorkflowCategoryOptionValues.InProgress,
                },
                {
                    name: 'Revisie',
                    category: Domain.Shared.WorkflowCategoryOptionValues.InProgress,
                },
                {
                    name: 'Gereed',
                    category: Domain.Shared.WorkflowCategoryOptionValues.Done,
                }
            ],
        }
        addWorkflowTemplate(defaultWorkFlow);
    };

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Shared]);
        return null;
    }

    if (props.workflowTemplates.status === AjaxRequestStatus.NotSet) {
        props.fetchWorkflowTemplates();
        return null;
    }

    return (
        <>
            <WrapperContent>
                <PageTitle>
                    <Heading1>Workflow processen</Heading1>
                </PageTitle>
                <Bar look="toolbar">
                    <Bar start>
                        {workflowsCount <= 0 &&
                            <Button id="btn-create" btnbase='textbuttons' btntype='medium_icon' icon={<Add />} onClick={() => addDummyWorkflow()}>
                                Standaard
                            </Button>
                        }
                        <Button id='btn-add-new-workflow' btnbase='textbuttons' btntype='medium_icon' icon={<Add />} disabled={!props.modules} onClick={() => setWorkFlowFormDialog({ showDialog: true, isSaving: false })}>
                            Nieuw
                        </Button>
                        <Button
                            id="btn-refresh"
                            btnbase="textbuttons"
                            btntype="medium_icon"
                            icon={<RefreshIcon />}
                            onClick={() => {
                                setLastRefresh(Date.now());
                            }}
                        >
                            Verversen
                        </Button>
                    </Bar>
                </Bar>

                <Section look="white">
                    <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        <LsGrid
                            dataSource={customDataSource}
                            columns={availableColumns}
                            enableColumnChooser={false}
                            searching={false}
                            paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                            showRowLines={true}
                            onClickRow={(item: WorkflowTemplate) => {
                                navigate(`/admin/workflow/${item.id}/information`);
                            }}
                            onDataError={(error) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))}
                        />
                    </ErrorOverlay>
                </Section>
            </WrapperContent>
            {workflowFormDialog.showDialog && (
                <WorkflowTemplateAdd
                    workflowNames={workflowNames}
                    onCancelChanges={() => setWorkFlowFormDialog({ showDialog: false, isSaving: false })}
                    onSaveChanges={(formValues: Record<string, ValueType>) => {
                        const states = formValues[workflowStatusListGuid]
                            .map(state => ({ name: state.name, category: state.category }));
                        const createdWorkflow: Domain.Dto.Shared.CreateWorkflowWithState = {
                            workflowName: formValues[Domain.FieldDefinitions.Shared.nameFieldDefinition.systemId],
                            states: states
                        };
                        addWorkflowTemplate(createdWorkflow);
                    }}
                />
            )}
        </>
    );
};

const getColumnConfiguration = (onEdit: (item: WorkflowTemplate) => void): GridColumn<WorkflowTemplate>[] => {
    return [
        {
            name: 'name',
            title: 'Naam',
        },
        {
            name: 'isActive',
            title: 'Status',
            align: 'left',
            renderCustom: ({ data }) => <>{data.isActive ? 'Actief' : 'Inactief'}</>,
        },
        {
            name: 'id',
            title: '',
            type: 'buttons',
            width: '5%',
            align: 'right',
            hideInColumnChooser: true,
            renderCustom: ({ data }) => (
                <ContextMenu<WorkflowTemplate>
                    keyExpr='id'
                    item={data}
                    actions={[
                        {
                            action: onEdit,
                            actionName: `edit-workflow-item-${data.id}`,
                            displayName: 'Bewerken',
                            ariaLabel: `Bewerk ${data.name}`,
                            hidden: !onEdit
                        }
                    ]}
                />),
        },
    ];
};

const workflowStatusListGuid = 'statuslist';

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Publication],
        elementDefinitions: state.elementdefinitions[ActionSource.Workflow],
        workflowTemplates: state.workflowtemplates,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Workflow, data: { moduleId: module?.id } }));
        },
        fetchWorkflowTemplates: () => {
            dispatch(WorkflowTemplateActionCreator.set());
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index, workflowStatusListGuid, WorkflowStatusMode };
