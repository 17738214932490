import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';

class SectionElement {

    // -1 represents the default value of it that is not a known kind.
    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.SectionContainerKind)
    kind = -1;

}

export default SectionElement;
