import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styled from 'styled-components';
import { px } from '../style';

const Container = styled.nav`
    user-select: none;
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    grid-gap: 0 ${px(1)};
`;

const SeperatorIcon = styled(ChevronRightIcon)`
    fill: #dedede;
    width: ${px(24)};
    height: ${px(24)};
`;

const styledComponents = { Container, SeperatorIcon };
export default styledComponents;