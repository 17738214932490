import React from 'react';
import { ToggleEditor } from './index.editor';
import { ToggleProps } from './index.props';
import { TextViewer } from '../../shared/TextViewer';

/**
 * Represents a UI component that renders an toggle element.
 */
export const ToggleElement: React.FC<ToggleProps> = (props) => {
    const value = props.value ? `Ja` : `Nee`;
    return props.editorSettings && !props.editorSettings.disabled ? <ToggleEditor {...props} /> : <TextViewer id={props.id} label={props.label} helpText={props.helpText} value={value} />;
};