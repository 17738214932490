import React from 'react';
import Styled, { IconSize } from './index.styled'
import SVG from "react-inlinesvg";

// General icons:
import { ReactComponent as Risk } from './imgs/alert.svg';
import { ReactComponent as Customer } from './imgs/customer.svg';
import { ReactComponent as Database } from './imgs/database.svg';
import { ReactComponent as ExternalApp } from './imgs/External_LIAS-01.svg';
import { ReactComponent as Performance } from './imgs/goal.svg';
import { ReactComponent as HierarchyEmpty } from './imgs/hierarchical-structure-3.svg';
import { ReactComponent as HierarchyFilled } from './imgs/hierarchical-structure-filled.svg';
import { ReactComponent as Datawarehouse } from './imgs/histogram.svg';
import { ReactComponent as Finance } from './imgs/money-bag.svg';
import { ReactComponent as Studio } from './imgs/tree-structure.svg';
import { ReactComponent as Publisher } from './imgs/pencil.svg';
import { ReactComponent as Settings } from './imgs/settings.svg';
import { ReactComponent as Timetable } from './imgs/timetable.svg';
import { ReactComponent as User } from './imgs/user.svg';
import { ReactComponent as Website } from './imgs/website.svg';
import { ReactComponent as Www } from './imgs/world-wide-web-2.svg';
import { ReactComponent as File } from './imgs/icon-file.svg';
import { ReactComponent as Home } from './imgs/icon-home.svg';
import { ReactComponent as Growth } from './imgs/growth.svg';
import { ReactComponent as Coin } from './imgs/coin.svg';
import { ReactComponent as Coin2 } from './imgs/coin2.svg';
import { ReactComponent as Computer } from './imgs/computer.svg';
import { ReactComponent as Roles } from './imgs/role.svg';
import { ReactComponent as Monitor } from './imgs/data-analytics.svg';
import { ReactComponent as Moment } from './imgs/timeline.svg';
import { ReactComponent as Lock } from './imgs/lock.svg';
import { ReactComponent as OpenLock } from './imgs/lock-open.svg';
import { ReactComponent as Layers } from './imgs/layers.svg';
import { ReactComponent as PerformanceEntities } from './imgs/book.svg';
import { ReactComponent as Book } from './imgs/book.svg';
import { ReactComponent as Context } from './imgs/context.svg';
import { ReactComponent as Abacus } from './imgs/abacus.svg';
import { ReactComponent as CheckList } from './imgs/check-list.svg';
import { ReactComponent as CheckList2 } from './imgs/check-list-2.svg';
import { ReactComponent as Planning } from './imgs/planning.svg';
import { ReactComponent as JournalElement } from './imgs/journal-element.svg';
import { ReactComponent as JournalCombination } from './imgs/journal-combination.svg';
import { ReactComponent as ArrowDown } from './imgs/arrow-down.svg';
import { ReactComponent as EuroTag } from './imgs/euro-tag.svg';
import { ReactComponent as Pin } from './imgs/pin-48.svg';
import { ReactComponent as Obligation } from './imgs/value-chain-obligation.svg';
import { ReactComponent as Realisation } from './imgs/price-tag-realisation.svg';
import { ReactComponent as Cost } from './imgs/cost.svg';
import { ReactComponent as Budget } from './imgs/money-bag-budget.svg';
import { ReactComponent as BudgetJournalGroup } from './imgs/budget-journal-group.svg';
import { ReactComponent as AI } from './imgs/artificial-intelligence.svg';

// Element definition icons:
import { ReactComponent as EDClock } from './elementDefinitionsIcons/clock.svg';
import { ReactComponent as EDComment } from './elementDefinitionsIcons/comment.svg';
import { ReactComponent as EDCustomerReview } from './elementDefinitionsIcons/customer-review.svg';
import { ReactComponent as EDCustomer } from './elementDefinitionsIcons/customer.svg';
import { ReactComponent as EDEuro } from './elementDefinitionsIcons/euro.svg';
import { ReactComponent as EDExperiment } from './elementDefinitionsIcons/experiment.svg';
import { ReactComponent as EDFlag } from './elementDefinitionsIcons/flag.svg';
import { ReactComponent as EDFolder } from './elementDefinitionsIcons/folder.svg';
import { ReactComponent as EDGear } from './elementDefinitionsIcons/gear.svg';
import { ReactComponent as EDGraduationCap } from './elementDefinitionsIcons/graduation-cap.svg';
import { ReactComponent as EDHandshake } from './elementDefinitionsIcons/handshake.svg';
import { ReactComponent as EDHouse } from './elementDefinitionsIcons/house.svg';
import { ReactComponent as EDInnovation } from './elementDefinitionsIcons/innovation.svg';
import { ReactComponent as EDInsurance } from './elementDefinitionsIcons/insurance.svg';
import { ReactComponent as EDKey } from './elementDefinitionsIcons/key.svg';
import { ReactComponent as EDLaw } from './elementDefinitionsIcons/law.svg';
import { ReactComponent as EDLocation } from './elementDefinitionsIcons/location.svg';
import { ReactComponent as EDMagnifyingGlass } from './elementDefinitionsIcons/magnifying-glass.svg';
import { ReactComponent as EDMegaphone } from './elementDefinitionsIcons/megaphone.svg';
import { ReactComponent as EDNotification } from './elementDefinitionsIcons/notification.svg';
import { ReactComponent as EDOfficeBuilding } from './elementDefinitionsIcons/office-building.svg';
import { ReactComponent as EDPriority } from './elementDefinitionsIcons/priority.svg';
import { ReactComponent as EDProctection } from './elementDefinitionsIcons/protection.svg';
import { ReactComponent as EDQuestion } from './elementDefinitionsIcons/question.svg';
import { ReactComponent as EDServers } from './elementDefinitionsIcons/servers.svg';
import { ReactComponent as EDSpeedometer } from './elementDefinitionsIcons/speedometer.svg';
import { ReactComponent as EDStrategy } from './elementDefinitionsIcons/strategy.svg';
import { ReactComponent as EDTarget } from './elementDefinitionsIcons/target.svg';
import { ReactComponent as EDTrafficLight } from './elementDefinitionsIcons/traffic-light.svg';
import { ReactComponent as EDTree } from './elementDefinitionsIcons/tree.svg';
import { ReactComponent as EDTrophy } from './elementDefinitionsIcons/trophy.svg';
import { ReactComponent as EDVaccine } from './elementDefinitionsIcons/vaccine.svg';

//Page components
import { ReactComponent as PcAccorderon } from './pageComponents/accordeon_export.svg';
import { ReactComponent as PcAttachment } from './pageComponents/attachment_export.svg';
import { ReactComponent as PcBarChart } from './pageComponents/graph_bar_export.svg';
import { ReactComponent as PcGroup } from './pageComponents/group_export.svg';
import { ReactComponent as PcHierarchy } from './pageComponents/hierarchie_export.svg';
import { ReactComponent as PcImage } from './pageComponents/image_export.svg';
import { ReactComponent as PcMap } from './pageComponents/map_export.svg';
import { ReactComponent as PcMenu } from './pageComponents/menu_export.svg';
import { ReactComponent as PcPieChart } from './pageComponents/piechart_export.svg';
import { ReactComponent as PcPivot } from './pageComponents/pivot_export.svg';
import { ReactComponent as PcRichText } from './pageComponents/richtext_export.svg';
import { ReactComponent as PcTab } from './pageComponents/tab_export.svg';
import { ReactComponent as PcTable } from './pageComponents/table_regular_export.svg';
import { ReactComponent as PcTextDataSet } from './pageComponents/textdataset_export.svg';
import { ReactComponent as PcTitle } from './pageComponents/title_export.svg';
import { ReactComponent as PcTile } from './pageComponents/tile_export.svg';

type Props = {
    size: IconSize,
    value: IconValue,
    iconSet?: IconSet,
    color?: string,
};

enum IconSet {
    default,
    elementDefinitions
}

enum IconValue {
    // General icons:
    Risk,
    Customer,
    Database,
    External_app,
    Performance,
    Hierarchy_empty,
    Hierarchy_filled,
    Datawarehouse,
    Finance,
    Studio,
    Publisher,
    Settings,
    Timetable,
    User,
    Website,
    Www,
    File,
    Home,
    Growth,
    Coin,
    Coin2,
    Computer,
    Roles,
    Monitor,
    Moment,
    OpenLock,
    Lock,
    Layers,
    PerformanceEntities,
    Book,
    Context,
    Abacus,
    CheckList,
    CheckList2,
    Planning,
    JournalElement,
    JournalCombination,
    BudgetJournalGroup,
    ArrowDown,
    EuroTag,
    Pin,
    Budget,
    Obligation,
    Realisation,
    Cost,
    AI,
    // Element definitions icons:
    ED_Clock,
    ED_Comment,
    ED_CustomerReview,
    ED_Customer,
    ED_Euro,
    ED_Experiment,
    ED_Flag,
    ED_Folder,
    ED_Gear,
    ED_GraduationCap,
    ED_Handshake,
    ED_House,
    ED_Innovation,
    ED_Insurance,
    ED_Key,
    ED_Law,
    ED_Location,
    ED_MagnifyingGlass,
    ED_Megaphone,
    ED_Notification,
    ED_OfficeBuilding,
    ED_Priority,
    ED_Proctection,
    ED_Question,
    ED_Servers,
    ED_Speedometer,
    ED_Strategy,
    ED_Target,
    ED_TrafficLight,
    ED_Tree,
    ED_Trophy,
    ED_Vaccine,

    //Page Component
    PcAccorderon,
    PcAttachment,
    PcBarChart,
    PcGroup,
    PcHierarchy,
    PcImage,
    PcMap,
    PcMenu,
    PcPieChart,
    PcPivot,
    PcRichText,
    PcTab,
    PcTable,
    PcTextDataSet,
    PcTitle,
    PcTile
}

/**
 * Represents a UI component that renders an icon.
 */
const Icon: React.FC<Props> = ({ value, size, iconSet = IconSet.default, color }) => {
    return <Styled.IconBg size={size} color={color}>
        {iconSet === IconSet.default ? iconElement(value) : getElementDefinitionIconElement(value)}
    </Styled.IconBg>;
};

const iconElement = (value: IconValue) => {
    switch (value) {
        case IconValue.Customer: return <Customer />;
        case IconValue.Database: return <Database />;
        case IconValue.Datawarehouse: return <Datawarehouse />;
        case IconValue.External_app: return <ExternalApp />;
        case IconValue.Finance: return <Finance />;
        case IconValue.Studio: return <Studio />;
        case IconValue.Hierarchy_empty: return <HierarchyEmpty />;
        case IconValue.Hierarchy_filled: return <HierarchyFilled />;
        case IconValue.Performance: return <Performance />;
        case IconValue.Publisher: return <Publisher />;
        case IconValue.Risk: return <Risk />;
        case IconValue.Settings: return <Settings />;
        case IconValue.Timetable: return <Timetable />;
        case IconValue.User: return <User />;
        case IconValue.Website: return <Website />;
        case IconValue.Www: return <Www />;
        case IconValue.Home: return <Home />;
        case IconValue.Growth: return <Growth />;
        case IconValue.Coin: return <Coin />;
        case IconValue.Coin2: return <Coin2 />;
        case IconValue.Computer: return <Computer />;
        case IconValue.Roles: return <Roles />;
        case IconValue.Monitor: return <Monitor />;
        case IconValue.Moment: return <Moment />;
        case IconValue.OpenLock: return <OpenLock />;
        case IconValue.Lock: return <Lock />;
        case IconValue.Layers: return <Layers />;
        case IconValue.PerformanceEntities: return <PerformanceEntities />;
        case IconValue.Book: return <Book />;
        case IconValue.Context: return <Context />;
        case IconValue.Abacus: return <Abacus />;
        case IconValue.CheckList: return <CheckList />;
        case IconValue.CheckList2: return <CheckList2 />;
        case IconValue.Planning: return <Planning />;
        case IconValue.JournalElement: return <JournalElement />;
        case IconValue.JournalCombination: return <JournalCombination />;
        case IconValue.BudgetJournalGroup: return <BudgetJournalGroup />;
        case IconValue.ArrowDown: return <ArrowDown />;
        case IconValue.EuroTag: return <EuroTag />;
        case IconValue.Pin: return <Pin />;
        case IconValue.Budget: return <Budget />;
        case IconValue.Realisation: return <Realisation />;
        case IconValue.Obligation: return <Obligation />;
        case IconValue.Cost: return <Cost />;
        case IconValue.AI: return <AI />;
        default: return <File />;
    }
};

/**
 * Get icons for studio element definitions.
 */
const getElementDefinitionIconElement = (value: IconValue) => {
    switch (value) {
        case IconValue.ED_Clock: return <EDClock />;
        case IconValue.ED_Comment: return <EDComment />;
        case IconValue.ED_CustomerReview: return <EDCustomerReview />;
        case IconValue.ED_Customer: return <EDCustomer />;
        case IconValue.ED_Euro: return <EDEuro />;
        case IconValue.ED_Experiment: return <EDExperiment />;
        case IconValue.ED_Flag: return <EDFlag />;
        case IconValue.ED_Folder: return <EDFolder />;
        case IconValue.ED_Gear: return <EDGear />;
        case IconValue.ED_GraduationCap: return <EDGraduationCap />;
        case IconValue.ED_Handshake: return <EDHandshake />;
        case IconValue.ED_House: return <EDHouse />;
        case IconValue.ED_Innovation: return <EDInnovation />;
        case IconValue.ED_Insurance: return <EDInsurance />;
        case IconValue.ED_Key: return <EDKey />;
        case IconValue.ED_Law: return <EDLaw />;
        case IconValue.ED_Location: return <EDLocation />;
        case IconValue.ED_MagnifyingGlass: return <EDMagnifyingGlass />;
        case IconValue.ED_Megaphone: return <EDMegaphone />;
        case IconValue.ED_Notification: return <EDNotification />;
        case IconValue.ED_OfficeBuilding: return <EDOfficeBuilding />;
        case IconValue.ED_Priority: return <EDPriority />;
        case IconValue.ED_Proctection: return <EDProctection />;
        case IconValue.ED_Question: return <EDQuestion />;
        case IconValue.ED_Servers: return <EDServers />;
        case IconValue.ED_Speedometer: return <EDSpeedometer />;
        case IconValue.ED_Strategy: return <EDStrategy />;
        case IconValue.ED_Target: return <EDTarget />;
        case IconValue.ED_TrafficLight: return <EDTrafficLight />;
        case IconValue.ED_Tree: return <EDTree />;
        case IconValue.ED_Trophy: return <EDTrophy />;
        case IconValue.ED_Vaccine: return <EDVaccine />;
        default: return <File />;
    }
};

const iconPageComponent = (value: IconValue) => {
    switch (value) {
        case IconValue.PcAccorderon: return <PcAccorderon />;
        case IconValue.PcAttachment: return <PcAttachment />;
        case IconValue.PcBarChart: return <PcBarChart />;
        case IconValue.PcGroup: return <PcGroup />;
        case IconValue.PcHierarchy: return <PcHierarchy />;
        case IconValue.PcImage: return <PcImage />;
        case IconValue.PcMap: return <PcMap />;
        case IconValue.PcMenu: return <PcMenu />;
        case IconValue.PcPieChart: return <PcPieChart />;
        case IconValue.PcPivot: return <PcPivot />;
        case IconValue.PcRichText: return <PcRichText />;
        case IconValue.PcTab: return <PcTab />;
        case IconValue.PcTable: return <PcTable />;
        case IconValue.PcTextDataSet: return <PcTextDataSet />;
        case IconValue.PcTitle: return <PcTitle />;
        case IconValue.PcTile: return <PcTile />;

        default: return <PcTable />;
    }
};

/*new inline icons */
type SvgIconProps = {
    size: IconSize,
    value: string,
    color?: string,
    displayMode?: 'inline' | 'block';
};

const SVGIcon: React.FC<SvgIconProps> = ({ value, size, color, displayMode }) => {
    return <Styled.IconBg size={size} color={color} displayMode={displayMode}>
        <SVG src={value || fallbackSvg} />
    </Styled.IconBg>;
};

/*new icons as thumbnail */
type SvgThumbnailProps = {
    variant: IconValue;
    primaryColor?: string;
    primaryContrastColor?: string;
    textColor?: string;
    h1Color?: string;
    h2Color?: string;
    h3Color?: string;
};

const SVGThumbnail: React.FC<SvgThumbnailProps> = (props) => {
    return <Styled.ThumbnailBg {...props}>
        {iconPageComponent(props.variant)}
    </Styled.ThumbnailBg>;
};

const fallbackSvg = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<g>
		<path d="M256,0C115.39,0,0,115.39,0,256s115.39,256,256,256s256-115.39,256-256S396.61,0,256,0z"/>
	</g>
</g>
</svg>
`;

export { Icon, SVGIcon, SVGThumbnail, IconSize, IconSet, IconValue };
