import React from 'react';
import { Routes as AnalyticsRoutes } from '@liasincontrol/analytics';
import { SideMenuLayout } from '../Layouts';

/**
 * Represents an UI element that renders all Analytics module sub-routes, in their specific Layouts.
 */
const AnalyticsModuleRoutes: React.FC = () => {
    return (
        <SideMenuLayout
            look='normal'
            sideMenu={AnalyticsRoutes.AnalyticsMenu}
            components={AnalyticsRoutes.Contents}
        />
    );
}

export default AnalyticsModuleRoutes;
