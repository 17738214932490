import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from '@liasincontrol/auth-service';
import { connect } from 'react-redux';
import { TenantActionCreator } from '@liasincontrol/redux-service';

type Props = ReturnType<typeof mapDispatchToProps>;

const Index: React.FC<Props> = (props) => {
    const [redirectUri, setRedirectUri] = useState<string>();
    AuthService.getInstance()
        .then((auth) => auth.signinRedirectCallback())
        .then((user) => {
            const tenant_name: string = user?.profile?.tenant_name;
            props.setTenant(tenant_name);
            const redirectUri: string = user.state['redirectUri'];
            setRedirectUri(redirectUri);
        })
        .catch((error) => {
            // Do nothing
        });

    if (redirectUri && redirectUri.length !== 0) {
        return (
            <Navigate to={redirectUri} />
        );
    }

    return <></>;
};

const mapDispatchToProps = (dispatch) => {
    return {
        setTenant: (tenant: string | undefined) => {
            dispatch(TenantActionCreator.initTenant(tenant));
        },
    };
};

const Component = connect(null, mapDispatchToProps)(Index);
export default Component;
