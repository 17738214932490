import React from 'react';
import { TextViewer } from '../../shared/TextViewer';
import { DecimalEditor } from './index.editor';
import { DecimalProps } from './index.props';

/**
 * Represents a UI component that renders a decimal element.
 */
export const DecimalElement: React.FC<DecimalProps> = (props) => {
    return props.editorSettings && !props.editorSettings.disabled ? <DecimalEditor {...props} /> : <TextViewer id={props.id} label={props.label} helpText={props.helpText} value={props.value} />;
};
