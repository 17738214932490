import styled, { AnyStyledComponent } from 'styled-components';

const Body = styled(`zion-body` as AnyStyledComponent)`
    display: contents;
`;

const CustomBody = styled.div<{ secondaryBgColor: string }>`
  && .body::before {
    background-color: ${(p) => p.secondaryBgColor}!important;
  }
`;

const styledComponents = { Body, CustomBody };
export default styledComponents;