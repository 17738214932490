import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { Finance as FinanceDataAccess, oDataResponse } from '@liasincontrol/data-service';

const action = createAsyncThunk<oDataResponse<Domain.Finance.Structure[]>, void, {}>('/finance/structures', async () => {
    return FinanceDataAccess.StructuresDataAccessor.getAll()
    .then((response) => ({
        data: response.data,
    }));
});

export default action;