import React, { useState } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Popover } from '@liasincontrol/ui-devextreme';
import { palette } from '@liasincontrol/ui-basics';
import * as Styled from './index.styled';

type Props = {
    componentId: string,
    message?: React.ReactNode,
    position?: 'left' | 'bottom',
};

/**
 * Represents a UI component that renders an icon with a popover on click event.
 */
export const IconPopover: React.FC<Props> = (props) => {
    const [popoverVisible, setPopoverVisible] = useState(false);

    return (<>
        <Styled.WarningContainer onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setPopoverVisible(true);
        }} className='ml-050 mr-050'>
            <WarningAmberIcon id={`icon-popover-${props.componentId}`} sx={{ height: '16px' }} htmlColor={palette.red} />

            {popoverVisible &&
                <Popover
                    target={`#icon-popover-${props.componentId}`}
                    position={props.position || 'bottom'}
                    maxWidth={320}
                    visible={popoverVisible}
                    onHiding={() => setPopoverVisible(false)}
                    shading={true}
                    shadingColor="rgba(0, 0, 0, 0.2)"
                >
                    <div>
                        {props.message}
                    </div>
                </Popover>
            }
        </Styled.WarningContainer>
    </>);
}