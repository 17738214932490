import { createReducer } from '@reduxjs/toolkit';
import { AttachmentsActionCreator, TenantActionCreator } from '../../../../actions/creators';

/**
 * Represents the attachments reducer.
 */
const reducer = createReducer<{
    [attachmentId: string]: File
}>({}, (builder) => {
    return (
        builder.addCase(AttachmentsActionCreator.set, (state, action) => {
            state[action.payload.data.attachmentId] = action.payload.data.attachment;
            return state;
        }),
        builder.addCase(AttachmentsActionCreator.remove, (state, action) => {
            delete state[action.payload.data.attachmentId];
            return state;
        }),
        builder.addCase(TenantActionCreator.initTenant, (state, action) => {
            state = {};
            return state;
        })
    );
});

export default reducer;
