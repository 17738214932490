import styled from 'styled-components';
import { palette, px } from '@liasincontrol/ui-basics';

const ScrollbarWrapper = styled.div`
    height: calc(100% - ${px(28)});
    overflow-y: auto;
    word-break: break-word;

    &::-webkit-scrollbar {
        width: ${px(10)};
    }
    &::-webkit-scrollbar-track {
        width: ${px(10)};
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
        width: ${px(10)};
        border-radius: ${px(10)};
        background-color: #ececf3;
    }
    &::-webkit-scrollbar-thumb:hover {
        background-color: #e3e3ec;
    }
    &::-webkit-scrollbar-button,
    &::-webkit-scrollbar-corner {
        width: ${px(10)};
        height: ${px(1)};
        background-color: transparent;
    }
`;

const HtmlContentColumn = styled.div`
    height: 50vh;
`;

const HtmlContent = styled(ScrollbarWrapper)`
    ins.diffmod, ins.diffins {
        background-color: ${palette.lightGreen};
        border-radius: 5px;
        padding: 0 3px;
        margin: 0 3px;
        text-decoration: unset;
    }

    del.diffmod, del.diffdel {
        border-radius: 5px;
        padding: 0 3px;
        margin: 0 3px;
        background-color: ${palette.lightPink};
    }
`;

const SplitContainer = styled.div`
    display: grid;
    column-gap: ${px(20)};
    padding-top: 0;
    grid-template-columns: 1fr 20px 1fr;
`;


const RemovedContentColumn = styled.div`
    grid-column: 1;
    grid-row: 1;
    height: 50vh;
`;

const InsertedContentColumn = styled.div`
    grid-column: 3;
    grid-row: 1;
    height: 50vh;
`;

const InsertedContent = styled(ScrollbarWrapper)`
    del.diffmod, del.diffdel {
        display: none;
    }

    ins.diffmod, ins.diffins {
        background-color: ${palette.lightGreen};
        text-decoration: unset;
    }
`;

const RemovedContent = styled(ScrollbarWrapper)`
    ins.diffmod, ins.diffins {
        display: none;
    }

    del.diffmod, del.diffdel {
        background-color: ${palette.lightPink};
    }
`;

const VerticalDelimiterLine = styled.div`
    grid-column: 2;
    grid-row: 1;
    border-left: 2px solid ${palette.grey5};
`;

export { HtmlContentColumn, HtmlContent, SplitContainer, RemovedContentColumn, 
    InsertedContentColumn, InsertedContent, VerticalDelimiterLine, RemovedContent };
