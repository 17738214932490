import styled from 'styled-components';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { px, NormalText, palette } from '../../index';

const Wrap = styled.div<{ position?: string }>`
    position: ${({ position }) => (position ? position : 'absolute')};
`;

const ListWrap = styled.ul<{ withPadding?: boolean }>`
    display: block;
    list-style: none;
    top: 0;
    cursor: pointer;
    margin: 0;
    padding: ${( { withPadding } ) =>  withPadding ? '1rem;' : '0;'}
`;

const LiBtn = styled.li`
    color: ${palette.primary2};
    text-align: left;
    vertical-align: middle;
    height: ${px(35)};
    white-space: nowrap;
    &:hover {
        background-color: ${palette.primary4};
    }
`;

const ListActionButton = styled(NormalText)`
    color: ${palette.primary2};
    line-height: ${px(47)};
    padding-left: 0;
    overflow-x: clip;
    text-overflow: ellipsis;
`;

const ContextMenuIcon = styled(MoreVertIcon)`
    color: ${palette.primary2};
    height: ${px(18)};
    margin-top: ${px(2)};
`;

const styledComponents = { Wrap, ListWrap, LiBtn, ListActionButton, ContextMenuIcon };
export default styledComponents;
