import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { AppSettingsService } from '@liasincontrol/config-service';
import { AnyFormData, ApiErrorReportingHelper, FormMode } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ActionSource, AjaxRequestStatus, ElementDefinitionsActionCreator, HierarchyDefinitionsActionCreator, ModulesActionCreator, State } from '@liasincontrol/redux-service';
import { Bar, Button, ErrorOverlay, Heading2, InputToggle, Label, PageTitle, Section, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { Shared } from '@liasincontrol/data-service';
import { LsGrid, GridColumn, ContextMenu } from '@liasincontrol/ui-devextreme';
import { HierarchyDefinitionForm } from './HierarchyDefinitionForm';
import Styled from './index.styled';

/**
 * Defines the props of the HierarchyDefinitions overview component.
 */
type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {};

/**
 * Represents a UI component that renders a list of HierarchyDefinitions.
 */
const HierarchyDefinitions: React.FC<Props> = (props) => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [hierarchyDefinitionForm, setHierarchyDefinitionForm] = useState<{ showDialog: boolean; isSaving: boolean; hierarchyDefinition: Domain.Shared.HierarchyDefinition }>({
        showDialog: false,
        isSaving: false,
        hierarchyDefinition: undefined,
    });

    const [showDeleted, setShowDeleted] = useState<boolean>(false);

    const fetchHierarchyDefinitions = () => {
        props.fetchHierarchyDefinitions(ActionSource.Studio, props.modules[Domain.SystemModuleDefinitions.Studio], true);
    };

    useEffect(() => {
        if (!props.modules) return;
        fetchHierarchyDefinitions();
    }, [props.modules]);

    const filteredHierarchyDefs =
        useMemo(() => props.hierarchyDefinitions.filter((hd) => showDeleted || !hd.deleted), [props.hierarchyDefinitions, showDeleted]);

    const hierarchyNames = useMemo(() => filteredHierarchyDefs.map((hd) => hd.name), [filteredHierarchyDefs]);

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementDefinitions || props.elementDefinitions.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[Domain.SystemModuleDefinitions.Studio]);
        return null;
    }

    const onSave = (changedData: { data: AnyFormData, links: Domain.Shared.HierarchyLinkDefinition[] }, formmode: FormMode) => {
        if (formmode === FormMode.Edit) {
            onUpdateHierarchyDefinition(changedData);
        } else {
            onSaveHierarchyDefinition(changedData);
        }
    };

    const onSaveHierarchyDefinition = (changedData: { data: AnyFormData, links: Domain.Shared.HierarchyLinkDefinition[] }) => {
        const dto: Domain.Dto.Shared.CreateHierarchyDefinition = {
            name: changedData.data.values[Domain.FieldDefinitions.Shared.nameFieldDefinition.id] as string,
            moduleId: props.modules[Domain.SystemModuleDefinitions.Studio].id,
            linkDefinitions: changedData.links,
        };

        Shared.HierarchyDefinitions.create(dto)
            .then(() => {
                fetchHierarchyDefinitions();
            })
            .catch((exception) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, exception));
            })
            .finally(() => {
                setHierarchyDefinitionForm({ showDialog: false, isSaving: false, hierarchyDefinition: undefined });
            });
    };

    const onUpdateHierarchyDefinition = (changedData: { data: AnyFormData, links: Domain.Shared.HierarchyLinkDefinition[] }) => {
        const dto: Domain.Dto.Shared.UpdateHierarchyDefinition = {
            name: changedData.data.values[Domain.FieldDefinitions.Shared.nameFieldDefinition.id] as string,
            hierarchyDefinitionItems: changedData.links,
        };

        Shared.HierarchyDefinitions.update(hierarchyDefinitionForm.hierarchyDefinition.hierarchyDefinitionId, dto)
            .then(() => {
                fetchHierarchyDefinitions();
            })
            .catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.DefinitionUsed)) {
                    setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.DefinitionUsed] });
                } else {
                    setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception));
                }
            })
            .finally(() => {
                setHierarchyDefinitionForm({ showDialog: false, isSaving: false, hierarchyDefinition: undefined });
            });
    };

    const disableHierarchyDefinition = (item: Domain.Shared.HierarchyDefinition): void => {
        if (item.deleted) {
            Shared.HierarchyDefinitions.activate(item.hierarchyDefinitionId)
                .then(fetchHierarchyDefinitions)
                .catch((error) => {
                    setError(
                        ApiErrorReportingHelper.generateErrorInfo(
                            ApiErrorReportingHelper.GenericMessages.Deleting,
                            error
                        )
                    );
                })

            return;
        }
        Shared.HierarchyDefinitions.deactivate(item.hierarchyDefinitionId)
            .then(fetchHierarchyDefinitions)
            .catch((error) => {
                setError(
                    ApiErrorReportingHelper.generateErrorInfo(
                        ApiErrorReportingHelper.GenericMessages.Deleting,
                        error
                    )
                );
            })

    };

    const columnConfiguration: GridColumn<Domain.Shared.HierarchyDefinition>[] = [
        {
            name: 'name',
            title: 'Naam',
        },
        {
            name: 'deleted',
            title: 'Status',
            align: 'center',
            width: '15%',
            renderCustom: (item) => <>{item.data.deleted ? 'Uitgeschakeld' : 'Ingeschakeld'}</>,
        },
        {
            name: 'hierarchyDefinitionId',
            title: '',
            type: 'buttons',
            width: '5%',
            align: 'right',
            hideInColumnChooser: true,
            renderCustom: ({ data }) =>
            (<div className="contextual-menu-cell">
                <ContextMenu<Domain.Shared.HierarchyDefinition>
                    item={data}
                    keyExpr='hierarchyDefinitionId'
                    actions={[
                        {
                            action: disableHierarchyDefinition,
                            actionName: 'deactivate',
                            displayName: data.deleted ? 'Inschakelen' : 'Uitschakelen',
                            ariaLabel: `${data.deleted ? 'Inschakelen' : 'Uitschakelen'} ${data.name}`
                        }
                    ]}
                />
            </div>),
        }
    ];

    return (
        <>
            <Wrapper>
                <WrapperContent>
                    <PageTitle>
                        <Heading2>Hiërarchiedefinities</Heading2>
                    </PageTitle>
                    <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        <Bar look='toolbar'>
                            <Bar start>
                                <Button
                                    id='btn-add-new-hierarchydefinition'
                                    btnbase='textbuttons'
                                    btntype='medium_icon'
                                    icon={<AddIcon />}
                                    onClick={() => setHierarchyDefinitionForm({ showDialog: true, isSaving: false, hierarchyDefinition: undefined })}>
                                    Nieuw
                                </Button>
                                <Button id='btn-refresh-hierarchydefinition-definition-list' btnbase='textbuttons' btntype='medium_icon' icon={<RefreshIcon />} onClick={() => fetchHierarchyDefinitions()}>
                                    Verversen
                                </Button>
                            </Bar>

                            <Bar end>
                                <Styled.StatusFilter>
                                    <Label className='showdeleted-label' id='showdeleted-label' htmlFor='showdeleted-toggle' text='Toon alles' />
                                    <InputToggle
                                        className='showdeleted-toggle'
                                        id='showdeleted-toggle'
                                        selected={showDeleted}
                                        textOff='Nee'
                                        textOn='Ja'
                                        isSmall={true}
                                        onChange={setShowDeleted}
                                    />
                                </Styled.StatusFilter>
                            </Bar>
                        </Bar>

                        <Section look='white'>
                            <LsGrid
                                dataSource={filteredHierarchyDefs}
                                columns={columnConfiguration}
                                enableColumnChooser={true}
                                searching={false}
                                paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                                showRowLines={true}
                                keyExpr='hierarchyDefinitionId'
                                buttonColumnId='hierarchyDefinitionId'
                                scrolling={{
                                    mode: 'standard',
                                }}
                                onClickRow={(item: Domain.Shared.HierarchyDefinition) => {
                                    setHierarchyDefinitionForm({ showDialog: true, isSaving: false, hierarchyDefinition: item })
                                }}
                            />
                        </Section>
                    </ErrorOverlay>
                </WrapperContent>
            </Wrapper>
            {hierarchyDefinitionForm.showDialog && (
                <HierarchyDefinitionForm
                    moduleId={props.modules[Domain.SystemModuleDefinitions.Studio].id}
                    hierarchyDefinition={hierarchyDefinitionForm.hierarchyDefinition}
                    disableSaveButton={hierarchyDefinitionForm.isSaving}
                    elementDefinitions={props.elementDefinitions.items}
                    formMode={hierarchyDefinitionForm.hierarchyDefinition ? FormMode.Edit : FormMode.AddNew}
                    hierarchyNames={hierarchyNames.filter(name => name !== hierarchyDefinitionForm.hierarchyDefinition?.name)}
                    onCancel={() => setHierarchyDefinitionForm({ showDialog: false, isSaving: false, hierarchyDefinition: undefined })}
                    onSave={onSave}
                />
            )}
        </>
    );
};

const mapStateToProps = (state: State) => {
    return {
        modules: state.modules[ActionSource.Studio],
        elementDefinitions: state.elementdefinitions[ActionSource.Studio],
        hierarchyDefinitions: Object.values(state.hierarchydefinitions[ActionSource.Studio]?.items ?? {}),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Studio, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Studio, data: { moduleId: module?.id, includeDetailCards: true, includeDeleted: true } }));
        },
        fetchHierarchyDefinitions: (source: ActionSource, module: Domain.Shared.Module, includeLinkDefinitions: boolean) => {
            dispatch(HierarchyDefinitionsActionCreator.set({ source: source, data: { moduleId: module.id, includeLinkDefinitions: includeLinkDefinitions } }));
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(HierarchyDefinitions);
export { Component as index };
