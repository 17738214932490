import React, { useState, useEffect } from 'react';
import _, { Dictionary } from 'lodash';
import { FieldsHelper, StringUtils } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { ThumbnailImg, HtmlViewer } from '@liasincontrol/ui-basics';
import SharedStyled from '../../SharedControlElements/index.styled';
import Styled from './index.styled';

type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Dictionary<Domain.Publisher.Element>,
    publicationElement: Domain.Publisher.PublicationElement,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
}

/**
 * Represents a UI component that renders a html control.
 */
const HtmlElementControl: React.FC<Props> = (props) => {
    const [controlSettings, setControlSettings] = useState<Domain.Publisher.HtmlElementControlElement>();
    const { element, elementList, getElementDefinition } = props;

    useEffect(() => {
        if (elementList[element.elementId]) {
            const definition = getElementDefinition(elementList[element.elementId].elementDefinitionSystemId, elementList[element.elementId].elementDefinitionId);
            const elementSettings = new Domain.Publisher.HtmlElementControlElement();
            FieldsHelper.mapObject<Domain.Publisher.HtmlElementControlElement>(elementSettings, definition.fields, elementList[element.elementId].fields);

            setControlSettings(elementSettings);
        }
    }, [element.elementId, elementList, getElementDefinition]);

    if (!controlSettings) {
        return null;
    }

    if (!controlSettings.htmlPlainText) {
        return <SharedStyled.ThumbnailWrapper><ThumbnailImg variant="spreadsheet" /></SharedStyled.ThumbnailWrapper>;
    }

    const text = StringUtils.toRawMarkup(controlSettings.htmlPlainText);

    return <Styled.HtmlElementWrapper
        primaryColor={props.publicationElement.primaryColor}
        primaryTextColor={props.publicationElement.primaryContrastColor}
        textColor={props.publicationElement.bodyFontColor}
        textFontSize={props.publicationElement.bodyFontSize}
        editMode={true}>
        <HtmlViewer id={element.elementId} className='dx-item-content' value={text.__html} />
    </Styled.HtmlElementWrapper>;
};

export default React.memo(HtmlElementControl, (prevProps, nextProps) => {
    return _.isEqual(prevProps.elementList[prevProps.element.elementId], nextProps.elementList[nextProps.element.elementId]);
});
