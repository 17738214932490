import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { SortableContainer, SortableElement, SortableElementProps } from 'react-sortable-hoc';
import Add from '@mui/icons-material/Add';
import LaunchIcon from '@mui/icons-material/Launch';
import * as Domain from '@liasincontrol/domain';
import { DefinitionsHelper } from '@liasincontrol/core-service';
import { Button, ElementLabel, Heading2 } from '@liasincontrol/ui-basics';
import { ConfirmDialog } from '../../../../../../_shared/ConfirmDialog';
import { HierarchyChanges, SitemapNode } from '../../..';
import { PageTemplateDialog } from '../PageTemplateDialog';
import { SitemapElement } from './HierarchyItem';
import { AddPageForm } from './AddPageForm';
import { EdgeToolbarContent, ToolbarTitle, SectionHeader, TemplateContainer, TemplateIcon, TemplateName, TemplateNameContainer } from '../index.styled';
import { SideMenuList, StyledAdd, StyledCaption } from './index.styled';

type Props = {
    readonly sitemapNode: Domain.Publisher.SitemapNode,
    readonly elementdefinitions: Record<string, Domain.Shared.ElementDefinition>,
    readonly childTemplate?: { id: string, name: string },
    readonly rootId: string,
    readonly onChangeRequest: (changes: HierarchyChanges) => void,
    readonly onNavigate: (to: string) => void,
    readonly onChangeTemplate: (newTemplateId: string, newTemplateName: string, pageIds: string[], parentId?: string) => void,
    readonly onCreate?: (ownerId: string, name: string) => void,
    readonly onMove?: (ownerId: string, movedPageId: string, beforePageId?: string) => void,

    readonly publicationId: string,
    readonly pageDesigns: Domain.Publisher.PageDesign[],
    readonly hasAvailableTemplates: boolean,
};

/**
 * Represents a UI component that renders the current page sitemap hierarchy.
 */
export const Hierarchy: React.FC<Props> = (props) => {
    const [adding, setAdding] = useState<boolean>(false);
    const [editing, setEditing] = useState<boolean>(false);
    const [removing, setRemoving] = useState<string>(null);
    const [choosingTemplate, setChoosingTemplate] = useState<boolean>(false);
    const [items, setItems] = useState<SitemapNode[]>([]);
    const [changes, setChanges] = useState<HierarchyChanges>({ editing: {}, cloning: [], deleting: [], });
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [pageNameMaxLength, setPageNameMaxLength] = useState<number>(undefined);

    useEffect(() => {
        const sitemapList = props.sitemapNode.children.map((c) => ({ ...c, markDelete: false, markCloned: false }));
        setItems(sitemapList);
        setChanges({ editing: {}, cloning: [], deleting: [], });
        setIsBusy(false);
        setEditing(false);
        setRemoving(null);
    }, [props.sitemapNode.children]);

    const sortEnd = ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
        props.onMove(
            props.sitemapNode.elementId,
            props.sitemapNode.children[oldIndex].elementId,
            newIndex === props.sitemapNode.children.length - 1 ? undefined : props.sitemapNode.children[newIndex].elementId
        );
    };

    //TODO: missing validation which is active on add new page!
    const nameChanged = (pageId: string, changedValue: string) => {
        const newstate = changes;
        newstate.editing[pageId] = changedValue;
        setChanges(newstate);
        setItems(items?.map((a) => (a.elementId === pageId ? { ...a, elementName: changedValue } : a)));
    };

    const remove = (id: string) => {
        const newstate = changes;
        newstate.deleting.push(id);
        setChanges(newstate);
        setRemoving(null);
        setItems(items?.map((a) => (a.elementId === id ? { ...a, markDelete: true } : a)));
    };

    const clone = (id: string) => {
        const newstate = changes;
        newstate.cloning.push(id);
        setChanges(newstate);
        const clonedItems = _.cloneDeep(items);
        const clonedItem = items.find((a) => a.elementId === id);
        clonedItems.push({
            ...clonedItem,
            elementId: items.length.toString(),
            elementName: clonedItem.elementName + ' [kopie]',
            order: items.length,
            markCloned: true,
        });
        setItems(clonedItems);
    };

    const restore = (id: string) => {
        const newstate = changes;
        newstate.deleting = newstate.deleting.filter(item => item !== id);
        setChanges(newstate);
        setItems(items?.map((a) => (a.elementId === id ? { ...a, markDelete: false } : a)));
    };

    const renderHomePageNode = () => {
        return (
            <SitemapElement
                index={0}
                isBusy={isBusy}
                disabled={true}
                rootId={props.rootId}
                value={{ ...props.sitemapNode, markDelete: false, markCloned: false }}
                editing={editing}
                onNameChanged={nameChanged}
                onNavigate={(to: string) => { props.onNavigate(to); }}
                onDelete={remove}
                onClone={clone}
                onRestore={restore}
            />
        );
    }

    useEffect(() => {
        if (!props.elementdefinitions) {
            return;
        }
        const pageElementDefinition = DefinitionsHelper.findElementDefinition(props.elementdefinitions, Domain.SystemElementDefinitions.Pub.Page);
        const fieldDefinition = pageElementDefinition.fields.find(item => item.systemId === Domain.SystemFieldDefinitions.Pub.Name);
        if (fieldDefinition.stringMaxLength) {
            setPageNameMaxLength(fieldDefinition.stringMaxLength);
        }
    }, [props.elementdefinitions]);

    const onChangeHierarchyTemplate = (templateId: string, templateName: string) => {
        setChoosingTemplate(false);
        setIsBusy(true);
        const pageIds = props.sitemapNode.children.filter((child) => child.pageTemplateId !== templateId).flatMap((page) => page.elementId);
        props.onChangeTemplate(templateId, templateName, pageIds, props.sitemapNode.elementId);
    };

    return (
        <EdgeToolbarContent>
            {props.sitemapNode.elementId === props.rootId
                ? <ToolbarTitle>Publicatieindeling</ToolbarTitle>
                : <SectionHeader>Pagina's toevoegen</SectionHeader>
            }
            {props.sitemapNode.elementId !== props.rootId && (
                <TemplateContainer>
                    {/* move all to a component? */}
                    <ElementLabel>Gekoppeld sjabloon voor alle onderliggende pagina’s</ElementLabel>
                    {/* in old app this setting is stored in local storage how to deal with this? */}
                    {props.childTemplate ? (
                        <TemplateName className='grid'>
                            {props.childTemplate && (
                                <TemplateIcon>
                                    <Button
                                        icon={<LaunchIcon />}
                                        btnbase='iconbuttons'
                                        btntype='medium_transparentmain'
                                        onClick={() => setChoosingTemplate(true)}
                                        disabled={!props.hasAvailableTemplates}
                                    />
                                </TemplateIcon>
                            )}
                            <TemplateNameContainer>
                                {props.childTemplate?.name}
                                <Button
                                    btnbase='ghostbuttons'
                                    btntype='small_noicon'
                                    onClick={() => setChoosingTemplate(true)}
                                    aria-label='Sjabloon wijzigen'
                                    disabled={!props.hasAvailableTemplates}
                                >
                                    Wijzigen
                                </Button>
                            </TemplateNameContainer>
                        </TemplateName>
                    ) : (
                        <TemplateName>
                            <Button
                                btnbase='primarybuttons'
                                btntype='small_noicon'
                                onClick={() => setChoosingTemplate(true)}
                                aria-label='Sjabloon selecteren'
                                disabled={!props.hasAvailableTemplates}
                            >
                                Selecteren
                            </Button>
                        </TemplateName>
                    )}
                    {choosingTemplate && (
                        <PageTemplateDialog
                            id={props.rootId}
                            currentTemplateId={props.childTemplate?.id}
                            onChange={onChangeHierarchyTemplate}
                            onCancel={() => setChoosingTemplate(false)}
                            publicationId={props.publicationId}
                            pageDesigns={props.pageDesigns}
                            elementDefinitions={props.elementdefinitions}
                        />
                    )}
                </TemplateContainer>
            )}
            <StyledCaption>
                <Heading2>{props.sitemapNode.elementId !== props.rootId ? `Onderliggende pagina's` : `Hoofdmenu`}</Heading2>
                {/* //TODO:  need bewerk and save button id NO child pages exists? */}
                {editing ? (
                    <Button
                        id={`btn-save-node-${props.sitemapNode.elementId}`}
                        btnbase='primarybuttons'
                        btntype='small_noicon'
                        onClick={() => {
                            setEditing(false);
                            setIsBusy(true);
                            props.onChangeRequest(changes);
                        }}>
                        Opslaan
                    </Button>
                ) : (
                    <Button
                        id={`btn-edit-node-${props.sitemapNode.elementId}`}
                        btnbase='ghostbuttons'
                        btntype='small_noicon'
                        onClick={() => setEditing(true)}>
                        Bewerk
                    </Button>
                )}
            </StyledCaption>
            <SortableList onSortEnd={sortEnd} useDragHandle>
                {props.sitemapNode.elementId === props.rootId && renderHomePageNode()}
                {items.map((value, index) => (
                    <SortableItem
                        key={`item-${value.elementId}`}
                        isBusy={isBusy}
                        index={index}
                        value={value}
                        editing={editing}
                        rootId={props.rootId}
                        props={{ index: index, disabled: false }}
                        onNameChanged={nameChanged}
                        onDelete={setRemoving}
                        onClone={clone}
                        onNavigate={(to: string) => { setAdding(false); props.onNavigate(to); }}
                        onRestore={restore}
                        pageNameMaxLength={pageNameMaxLength}
                    />
                ))}
            </SortableList>
            {!adding && !editing ? (
                <StyledAdd>
                    <Button id={`btn-add-page-${props.sitemapNode.elementId}`} btnbase='textbuttons' btntype='small_icon' icon={<Add />} onClick={() => setAdding(true)}>
                        &nbsp;Voeg toe&nbsp;
                    </Button>
                </StyledAdd>
            ) : null}
            {adding && !editing && pageNameMaxLength && (
                <AddPageForm
                    maxValueLength={pageNameMaxLength}
                    onChange={(newPage: string) => {
                        props.onCreate(props.sitemapNode.elementId, newPage);
                        setAdding(true);
                    }}
                    onCancel={() => setAdding(false)}
                />
            )}
            {removing && (
                <ConfirmDialog
                    look='interactive'
                    title='Verwijderen menu-item'
                    text={`U staat op het punt een item uit het menu te verwijderen. Bij het verwijderen hiervan verwijdert u ook de onderliggende pagina’s. Weet u zeker dat u dit wilt doen? U kunt deze actie niet meer ongedaan maken.`}
                    confirmButtonText='Verwijderen'
                    disableConfirmation={false}
                    onCancel={() => setRemoving(null)}
                    onConfirm={() => remove(removing)}
                />
            )}
        </EdgeToolbarContent>
    );
};

const SortableList = SortableContainer(({ children }: { children: React.ReactNode }) => <SideMenuList>{children}</SideMenuList>);
const SortableItem = SortableElement(
    ({
        value,
        onNameChanged,
        props,
        editing,
        rootId,
        onNavigate,
        onDelete,
        onClone,
        onRestore,
        isBusy,
        pageNameMaxLength
    }: {
        value: SitemapNode;
        onNameChanged: (id: string, changedName: string) => void;
        props: SortableElementProps;
        editing: boolean;
        rootId: string;
        onNavigate: (to: string) => void;
        onDelete: (id: string) => void;
        onClone: (id: string) => void;
        onRestore: (id: string) => void;
        isBusy: boolean;
        pageNameMaxLength: number;
    }) => {
        return (
            <SitemapElement
                index={props.index}
                isBusy={isBusy}
                disabled={props.disabled}
                rootId={rootId}
                value={value}
                editing={editing}
                maxValueLength={pageNameMaxLength}
                onNameChanged={onNameChanged}
                onNavigate={onNavigate}
                onDelete={onDelete}
                onClone={onClone}
                onRestore={onRestore}
            />
        );
    }
);
