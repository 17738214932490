import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class MenuControl extends BaseControlElement {

    constructor(title?: string, menuType?: string) {
        super();
        this.title = title;
        this.menuType = menuType;
    }

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Title)
    title = '';

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.MenuType)
    menuType: string;
}

export default MenuControl;