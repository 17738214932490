import React from 'react';
import { TextEditor } from './index.editor';
import { TextProps } from './index.props';
import { TextViewer } from '../../shared/TextViewer';

/**
 * Represents a UI component that renders an text element.
 */
const TextElement: React.FC<TextProps> = (props) => {
    return props.editorSettings && !props.editorSettings.disabled
        ? <TextEditor {...props} />
        : <TextViewer id={props.id} label={props.label} helpText={props.helpText} value={props.value} readMore={props.editorSettings?.readMore} withoutFeedback={props.editorSettings?.withoutFeedback} />;
};

export { TextEditor, TextElement }