import styled, { css, keyframes } from 'styled-components';
import { px, palette, cssFontLight, cssFontSemiBold, cssBreakpoint } from '@liasincontrol/ui-basics';

const horizontalGrid = (colNr: number) => {
    return css`
        grid-gap: ${px(15)};
        grid-template-rows: auto;
        grid-template-columns: minmax(auto, 50%) repeat(${colNr}, auto) minmax(0px, auto);
    `
};

const verticalGrid = (rowNr: number) => {
    return css`
    grid-gap: ${px(15)};
    grid-template-columns: auto;
    grid-template-rows: minmax(auto, 50%) repeat(${rowNr}, auto) minmax(0px, auto);
`};

const horizontalItem = css`
    padding-right: ${px(16)};
    justify-items: center ;
    align-items: center ;
    text-align: center;
    display: block;
`;

const verticalItem = css`
    padding-bottom: ${px(16)};
    display: flex;
    text-align: left;
    > :first-child {
        float: left;
    }
`;

const Card = styled.div<{ direction: 'horizontal' | 'vertical', clickable?: boolean, itemCount: number, withMargin?: boolean }>`
    color: ${(p) => (p.clickable ? palette.grey3 : `none`)};
    margin: ${(p) => (p.withMargin ? `${px(8)} 0` : `${px(2)} 0`)};
    user-select: ${(p) => (p.clickable ? `inherit` : `none`)};
    border: ${px(2)} solid ${palette.grey4};
    border-radius: 5px;
    padding: ${px(16)};
    display: grid;
    ${cssFontSemiBold(16, 19, 30)}
    ${(p) => p.direction && p.direction === 'horizontal' ? horizontalGrid(p.itemCount) : verticalGrid(p.itemCount)}
`;

const CardHeader = styled.div``;

const CardFooter = styled.div`
    display: flex;
    align-items: center;
`;

const Item = styled.div<{ direction: 'horizontal' | 'vertical' }>`
    ${(p) => p.direction && p.direction === 'horizontal' ? horizontalItem : verticalItem}
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    ${cssFontSemiBold(14, 19, 30)}
    > :first-child {
        ${cssFontLight(14, 19, 30)}
        text-transform: uppercase;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: ${px(8)} ;
    }
`;

const Circle = styled.svg`
    display: block;
    margin: ${px(4)} auto ${px(12)};
    width: 37%;
    ${cssBreakpoint('xs', 's')`
        max-width: ${px(40)};
    `}
    ${cssBreakpoint('m', 'l', 'xl')`
        max-width: ${px(100)};
    `}
`;

const CircleBG = styled.path`
    fill: none;
    stroke: ${palette.primary3};
    stroke-width: 3.5;
`;

const CirclePath = styled(CircleBG) <{ percentage: number, color: string }>`
    stroke: ${(props: { color: string }) => props.color};
    stroke-linecap: butt;
    animation: ${(props: { percentage: number }) => progress(props.percentage)} 1.5s ease 1s forwards;
`;

const progress = (percentage: number) => keyframes`
    0% {
        stroke-dasharray: 0 100;
    }
    100% {
        stroke-dasharray: ${percentage} 100;
    }
`;

const ArrowIcon = styled.span`
  height: ${px(32)};
  width: ${px(32)};
  right: ${px(24)};
  bottom: ${px(-16)};
  border-radius: 100%;
  align-content: center;
  background-color: ${palette.white};
  color: ${palette.primary2};
  border: ${px(2)} solid ${palette.grey4};
  box-shadow: 0px 1px 2px ${palette.grey3b};
  display: flex;
  justify-content: center;
  align-items: center;

  ::after {
    content: 'arrow_forward';
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: ${px(20)};
  }
`;

const styledComponents = { Card, CardHeader, CardFooter, Item, Circle, CircleBG, CirclePath, ArrowIcon };
export default styledComponents;