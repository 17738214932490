import { fieldDefinitionDecorator } from '../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../Shared/FieldDefinition';

export class ContainerItemElement {

    constructor(title?: string, helpText?: string) {
        this.title = title;
        this.helpText = helpText;
    }

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Title)
    title = '';

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.HelpText)
    helpText = '';

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ShowBackground)
    showBackground = false;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ShowShadow)
    showShadow = false;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ShowWhitespaceTop)
    showWhitespaceTop = true;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ShowWhitespaceBottom)
    showWhitespaceBottom = true;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ShowWhitespaceLeft)
    showWhitespaceLeft = true;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ShowWhitespaceRight)
    showWhitespaceRight = true;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TabIcon)
    tabIcon: string;

}
