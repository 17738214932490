import { useSelector, useDispatch } from "react-redux";
import { State, AjaxRequestStatus, WorkflowTemplateActionCreator } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';

type WorkflowTemplatesType = {
    items: Domain.Shared.WorkflowTemplateWithStates[],
    status: AjaxRequestStatus,
}

export const useWorkflowTemplates = () => {
    const templates = useSelector<State, WorkflowTemplatesType>((state) => state.workflowtemplates);
    const dispatch = useDispatch();

    if (templates.status === AjaxRequestStatus.NotSet) {
        dispatch(WorkflowTemplateActionCreator.set());
    }

    return templates;
}
