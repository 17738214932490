import React from 'react';
import { Dictionary } from 'lodash';
import * as Domain from '@liasincontrol/domain';

type Props = {
    element: Domain.Publisher.ElementNode;
    elementList: Dictionary<Domain.Publisher.Element>;
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition;
    children?: React.ReactNode;
};

/**
 * Represents a UI component that renders a container item placeholder.
 */
const ContainerItemPlaceHolder: React.FC<Props> = (props) => {
    return (<>
        {props.children}
    </>);
};

export default ContainerItemPlaceHolder;
