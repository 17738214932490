
import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { Config, ConfigService } from '@liasincontrol/config-service';
import { DataAccessHostUtils } from '../DataAccessHostUtils';

/**
 * Represents the element definitions data access.
 */
export class ElementDefinitions {

    private _configuration: Config;

    /**
     * Constructs and registers an instance of this class.
     */
    private constructor(configuration) {
        this._configuration = configuration;
    }

    /**
     * Creates a new element definition.
     * 
     * @param elementDefinition Defines the element definition that needs to be added.
     */
    public static create = async (elementDefinition: Domain.Dto.Shared.CreateElementDefinition): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post(`/api/elementdefinition`, elementDefinition, config);
    };

    /**
     * Creates field definitions of a specific element definition.
     * 
     * @param fields Defines the fields that have to be added.
     * @param elementDefinitionId Defines the element definition id.
     */
    public static createFields = async (fields: Domain.Shared.FieldDefinition[], elementDefinitionId: string): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put(`/api/elementdefinition/${elementDefinitionId}/fielddefinition`, {
            complexFields: [],
            fields: fields
        }, config);
    };

    /**
     * Update a element definition.
     * 
     * @param elementDefinitionId Defines the ID of the element definition being updated.
     * @param elementDefinition Defines the element definition that needs to be updated.
     */
    public static update = async (elementDefinitionId: string, elementDefinition: Domain.Dto.Shared.CreateElementDefinition): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put(`/api/elementdefinition/${elementDefinitionId}`, elementDefinition, config);
    };

    /**
     * activates an element definition.
     * 
     * @param elementDefinitionId The id of the element definition that needs to be activated.
     */
        public static activate = async (elementDefinitionId: string): Promise<AxiosResponse<void>> => {
            const config = await DataAccessHostUtils.getPubRequestConfig();
            return HttpClient.put(`/api/elementdefinition/${elementDefinitionId}/activate`, null,  config);
        };

    /**
     * Deactivates an element definition.
     * 
     * @param elementDefinitionId Defines the id of the element definition that needs to be deactivated.
     */
     public static deactivate = async (elementDefinitionId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.put(`/api/elementdefinition/${elementDefinitionId}/deactivate`, null, config);
    };

    /**
     * Gets the list of element definitions.
     * 
     * @param endpoint Endpoit to retrieve the information from.
     * @param moduleId Module identifier to retrieve definitions for.
     * @param includeFieldDefinitions True if the fields definition array should be included.
     * @param includeDetailCards True if the detail cards should be included.
     * @param includeDeleted True if the deleted element definitions should also be returned.
     */
    public get = (endpoint: Domain.Shared.ApiEndpoint, moduleId: string, includeFieldDefinitions = true, includeDetailCards = false, includeDeleted = false): Promise<AxiosResponse<Domain.Shared.ElementDefinition[]>> => {
        const config = DataAccessHostUtils.getModuleRequestConfig(endpoint, this._configuration);

        return HttpClient.get<Domain.Shared.ElementDefinition[]>
            (`/api/module/${moduleId}/elementdefinition/extended?includeFieldDefinitions=${includeFieldDefinitions}&includeDetailCards=${includeDetailCards}&includeDeleted=${includeDeleted}`, config);
    };

    /**
     * Gets all the existing detail cards of an element definition.
     * 
     * @param elementDefinitionId Defines the elementdefinition id of the detail cards that needs to be retirieved.
     */
     public static getDetailCards = async (elementDefinitionId: string): Promise<AxiosResponse<Domain.Shared.DetailCard[]>> => {  
        const config = await DataAccessHostUtils.getPubRequestConfig();     

        return HttpClient.get(`/api/elementdefinition/${elementDefinitionId}/detailcard`, config);
    };

    /**
     * Creates a new detail card for an element definition.
     * 
     * @param detailCard Defines the detail card that needs to be added.
     */
     public static createDetailCard = async (detailCard: Domain.Dto.Shared.CreateDetailCard): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post(`/api/detailcard`, detailCard, config);
    };

    /**
     * Updates an existing detail card for an element definition.
     * 
     * @param detailCard Defines the detail card that needs to be updated.
     * @param detailCardId Defines the id of the detail card to update.
     */
     public static updateDetailCard = async (detailCardId: string, detailCard: Domain.Dto.Shared.UpdateDetailCard): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put(`/api/detailcard/${detailCardId}`, detailCard, config);
    };

    /**
     * Gets an instance of the ElementDefinitionsDataAccess class.
     *
     * @returns An instance of the ElementDefinitionsDataAccess class.
     */
    public static async getInstance(): Promise<ElementDefinitions> {
        if (ElementDefinitions.instance === null || ElementDefinitions.instance === undefined) {
            const config = await ConfigService.getConfigurations();
            ElementDefinitions.instance = new ElementDefinitions(config);
        }

        return ElementDefinitions.instance;
    }

    private static instance: ElementDefinitions;
}
