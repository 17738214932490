import React from 'react';
import { BasicText, Heading2, PageTitle, PanelGrid, TextImageWidget, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';

/**
 * Represents a UI component that renders the Analytics cockpit view.
 */
const Cockpit: React.FC = () => {
    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Cockpit LIAS Analytics</Heading2>
                </PageTitle>
                <PanelGrid>
                    <TextImageWidget 
                        title='LIAS Analytics' 
                        text='Een grafiek zegt meer dan 100 getallen en maakt het mogelijk om in één opslag de huidige stand 
                        van zaken op te halen. Met LIAS Analytics heeft u direct inzicht in stuurinformatie zonder hier zelf dashboards voor te genereren.' 
                        footer={<>
                            <BasicText>Wilt u zelf aan de slag met onze dashboards? Deze dashboards staan voor u klaar in de <a href="https://app.powerbi.com/groups/me/apps" target="_blank" rel="noopener noreferrer">LIAS Analytics</a> app.</BasicText>
                            <BasicText>Lees meer over de volledige <a href="https://inergy.nl/voor-overheden/" target="_blank" rel="noopener noreferrer">LIAS product portfolio</a>,
                            of schrijf je in voor onze <a href="https://inergy.nl/evenementen/" target="_blank" rel="noopener noreferrer">webinars</a>.</BasicText>
                        </>}
                    />
                </PanelGrid>
            </WrapperContent>
        </Wrapper>
    );
};

export { Cockpit as index };
