import React from 'react';
import Styled from './index.styled';
import { Text } from '../primitives/text';
import { HelpText } from '../helptext';

export const Announcement = ({ title, announcement, renderAs }: { readonly title?: string; readonly announcement?: string; readonly renderAs?: 'widget' | 'box' }) =>
    announcement ? (
        <Styled.AnnouncementContainer id='announcement' aria-label='mededeling' renderAs={renderAs}>
            <Styled.Announcement renderAs={renderAs}>
                <Styled.AnnouncementTitle>
                    {title && <>
                        <Text value={title} active bold />
                        {announcement.length > 200 && <HelpText title="Mededeling" type='info'>{announcement}</HelpText>}
                    </>
                    }
                </Styled.AnnouncementTitle>
                <Text value={announcement.slice(0, 215)} />
            </Styled.Announcement>
        </Styled.AnnouncementContainer>
    ) : null;

export default Announcement;
