import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class TabDsControl extends BaseControlElement {

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TableColumnSettings)
    columnSettings: string;
    
    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.DataSource)
    dataSourceId: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Filter)
    filter: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.HeaderField)
    headerField: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TitleColumn)
    titleColumn: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.Title)
    title: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TabPosition)
    tabPosition: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TabIconPosition)
    tabIconPosition: string;
}

export default TabDsControl;
