import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Look, IconValue, NavGroup, SideNavigation } from '@liasincontrol/ui-basics';
import { useAnnouncement } from '@liasincontrol/redux-service';

/**
 * Represents a UI component that renders the Analytics menu.
 */
const Menu: React.FC = () => {
    const { pathname } = useLocation();

    const announcement = useAnnouncement();

    const menuItems: NavGroup[] = [
        {
            key: 'menu-ai',
            title: 'LIAS Assistant',
            active: !!matchPath('/ai', pathname),
            icon: IconValue.AI,
            to: '/ai',
            items: [],
        }
    ];

    return (
        <SideNavigation look={Look.ai} alt='' menuItems={menuItems} announcement={announcement.items?.message} />
    );
};

export { Menu };
