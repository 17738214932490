import styled from 'styled-components';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { px, palette } from '@liasincontrol/ui-basics';
import { FormMode } from '@liasincontrol/core-service';

const StyledAddOptions = styled.div``;

const StyledInput = styled.input`
    border: 0;
    padding: 0;
    outline: 0;
`;

const StyledAdd = styled.div`
    margin: 0 ${px(-8)};
`;

const MiddleWrapper = styled.div`
    margin-top: -1rem;
`;

const StyledItem = styled.div<{ mode: FormMode, disabled: boolean, isTargeted?: boolean }>`
    align-items: center;
    white-space: nowrap;
    text-overflow: ellipses;
    margin: ${px(8)} 0 ${px(16)} 0;
    padding-left: ${(p) => (p.disabled || p.isTargeted ? `${px(8)}` : `unset`)};
    background-color: ${(p) => (p.disabled ? palette.grey4 : palette.transparent)};
`;

const StyledIconDrag = styled(DragIndicatorIcon) <{ disabled: boolean }>`
    color: ${(p) => p.disabled ? '#6E6E77' : palette.primary2};
    fill: currentColor;
    cursor: ${(p) => p.disabled ? 'default' : 'move'};
`;


const ListContainer = styled.ul`
    list-style: none;
    padding: 0; 
`;

const Li = styled.li<{ disabled?: boolean }>`
    display: flex;
    position: relative;
    padding: ${px(6)} ${px(4)};
    margin-bottom: ${px(2)};
    text-align: left;
    white-space: nowrap; 
    text-overflow: ellipsis;
    list-style-type: none;
    transition: background-color 0.4s, padding-right 0.4s ease;
    border-radius: ${px(8)};
    color: ${(p: { disabled?: boolean }) => p.disabled ? palette.grey3 : palette.grey2};
    background-color: ${(p: { disabled?: boolean }) => p.disabled ? palette.primary3 : palette.white};    
    cursor: ${(p: { disabled?: boolean }) => (p.disabled ? 'default' : 'pointer')};
    
    &:hover {
        color: ${(p: { disabled?: boolean }) => p.disabled ? palette.grey3 : palette.grey2};
        background-color: ${palette.primary4};
    };
`;

const DragWrap = styled.span`
    float: left; 
    display: flex;
    align-items: center;
`;
const ActionsWrap = styled.div`
    float: right;
    display: flex;
    align-items: center;    
    > button { margin-left: ${px(4)}; }
`;
const MiddleWrap = styled.div`
    flex: 1;
    padding: 0;
`;

const AddNewWorkflowButton = styled.div`
    margin-bottom: ${px(15)};
`;

const styledComponents = { ActionsWrap, DragWrap, MiddleWrap, ListContainer, Li, StyledAdd, StyledAddOptions, StyledItem, StyledIconDrag, StyledInput, MiddleWrapper, AddNewWorkflowButton };

export default styledComponents;
