import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Wrapper } from '@liasincontrol/ui-basics';
import Header from '../_shared/Header';
import NotFound from '../_shared/NotFound';
import Styled from './index.styled';

type Props = {
    components?: React.ReactElement
};

/**
 * Represents an UI element that renders a basic layout of the application.
 * Examples of places where this is used: Cockpit and module homepages.
 */
const Layout: React.FC<Props> = (props) => {
    return (
        <Wrapper navigation>
            <Styled.Main>
                <Styled.Component>
                    <Routes>
                        <Route index element={props.components} />
                        <Route path='/error/:cause' element={<NotFound />} />
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </Styled.Component>
            </Styled.Main>
            <Header look="normal" />
        </Wrapper>
    );
}

export default Layout;
