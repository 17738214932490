import React from "react";
import { LsPivotTable, PivotGridDataSource } from "@liasincontrol/ui-devextreme";
import { Section } from '@liasincontrol/ui-basics';

export type BudgetDevGridProps = {
    dataSource: PivotGridDataSource;
    onCellClick?: (e) => void;
}

export const BudgetDevelopmentGrid: React.FC<BudgetDevGridProps> = (props: BudgetDevGridProps) => {

    return (<Section look='white'>
        <LsPivotTable
            dataSource={props.dataSource}
            showColumnGrandTotals={false}
            showColumnTotals={true}
            scrolling={true}
            onCellClick={props.onCellClick}
        />
    </Section>);
};
