import { BlueBackgroundButtons } from './base_bluebackgroundbuttons';
import { Ghost } from './base_ghost';
import { IconButtons } from './base_iconbuttons';
import { PrimaryButtons } from './base_primarybuttons';
import { TextButtons } from './base_textbuttons';
import { IStyleGuideButtons } from './styleguide';

export * from './base';
export * from './base_bluebackgroundbuttons';
export * from './base_ghost';
export * from './base_iconbuttons';
export * from './base_primarybuttons';
export * from './base_textbuttons';
export * from './content';
export * from './layouts';
export * from './styleguide';

export const StyleGuideButtons: IStyleGuideButtons = {
    bluebackgroundbuttons: BlueBackgroundButtons,
    ghostbuttons: Ghost,
    iconbuttons: IconButtons,
    primarybuttons: PrimaryButtons,
    textbuttons: TextButtons,
};
