import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { MultiLineTextElement, TextElement } from '@liasincontrol/ui-elements';
import { FormData, FormHelper, TextValidator, ValidationUtils, ValidatorsDictionary } from '@liasincontrol/core-service';
import { AutoFocus, LayoutField, LayoutForm, ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';

type Props = {
    saveButtonDisabled: boolean,
    onCloseDialog: () => void,
    onSave: (subject: string, message: string) => void
};

/**
 * Represents a UI component that renders the dialog for sending a reminder to all users with opened tasks in the publication.
 */
export const SendOpenTasksReminder: React.FC<Props> = (props) => {
    const [sendReminderForm, setSendReminderForm] = useState<FormData<string>>(initForm());

    const validators = getValidators();

    const storeFormValue = (value: string, systemId: keyof typeof validators) => {
        setSendReminderForm((prevForm) => FormHelper.validateAndStoreFormValue<FormData<string>>(prevForm, value, validators, systemId));
    };

    return (
        <ModalDialog settings={{
            look: 'interactive',
            title: 'Herinnering versturen',
            footer:
                <ModalDialogFooter
                    leftButtonText='Annuleren'
                    onLeftButtonClick={props.onCloseDialog}
                    rightButtonText='Versturen'
                    onRightButtonClick={() => props.onSave(sendReminderForm.values[subjectDefinitionId], sendReminderForm.values[messageDefinitionId])}
                    rightButtonDisabled={!sendReminderForm.isValid || props.saveButtonDisabled}
                />
        }}>
            <Text value='Alle schrijvers zullen per e-mail een herinnering ontvangen over hun openstaande taken binnen deze publicatie.' />
            <LayoutForm>
                <LayoutField left={1} top={1} width={6} height={1} key='sendReminder_subject'>
                    <AutoFocus>
                        <TextElement
                            id='sendReminder_subject'
                            label='Onderwerp'
                            editorSettings={ValidationUtils.getEditorSettings(true, false, validators, sendReminderForm, (value: string) => storeFormValue(value, subjectDefinitionId), subjectDefinitionId)}
                            value={sendReminderForm.values[subjectDefinitionId]}
                        />
                    </AutoFocus>
                </LayoutField>
                <LayoutField left={1} top={2} width={6} height={1} key='sendReminder_message'>
                    <MultiLineTextElement
                        id='sendReminder_message'
                        label='Bericht'
                        rows={5}
                        editorSettings={ValidationUtils.getEditorSettings(true, false, validators, sendReminderForm, (value: string) => storeFormValue(value, messageDefinitionId), messageDefinitionId)}
                        value={sendReminderForm.values[messageDefinitionId]}
                    />
                </LayoutField>
            </LayoutForm>
        </ModalDialog>
    );
}

const initForm = (): FormData<string> => {
    return {
        values: {
            [subjectDefinitionId]: 'Bijvoorbeeld: Herinnering openstaande taken',
            [messageDefinitionId]: '',
        },
        touched: {},
        validationErrors: {},
        isValid: false,
    };
};

const getValidators = (): ValidatorsDictionary => {
    return {
        [subjectDefinitionId]: new TextValidator({ required: true, stringMaxLength: 50, stringMinLength: 2, stringType: Domain.Shared.StringType.SingleLine }),
        [messageDefinitionId]: new TextValidator({ required: true, stringMaxLength: 500, stringMinLength: 2, stringType: Domain.Shared.StringType.MultiLine }),
    };
};

const subjectDefinitionId = 'reminder-subject-id';
const messageDefinitionId = 'reminder-message-id';