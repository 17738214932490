import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';
import { oDataResponse } from '../../Shared';

/**
 * Represents a data accessor of budget journal groups.
 */
export class BudgetJournalGroupDataAccessor {
    /**
     * Gets the collection of the existing groups.
     */
    public static getAll = async (query = ''): Promise<AxiosResponse<oDataResponse<Domain.Finance.BudgetJournalGroup[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<oDataResponse<Domain.Finance.BudgetJournalGroup[]>>(`/api/finance/budgetjournalgroup${query}`, config);
    };

    /**
     * Gets a budget journal group based on id.
     */
    public static get = async (budgetJournalGroupId: string): Promise<AxiosResponse<Domain.Finance.BudgetJournalGroup>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Finance.BudgetJournalGroup>(`/api/finance/budgetjournalgroup/${budgetJournalGroupId}`, config);
    };

    /**
     * Creates a budget journal group.
     * @param budgetJournalGroup Defines the budget journal group that has to be created.
     */
    public static create = async (budgetJournalGroup: Domain.Finance.BudgetJournalGroup): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<void>('/api/finance/budgetjournalgroup', budgetJournalGroup, config);
    };

    /**
     * Updates a budget journal group.
     * @param budgetJournalGroup Defines the budget journal group that has to be updated.
     */
    public static update = async (budgetJournalGroup: Domain.Finance.BudgetJournalGroup): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const data = {
            name: budgetJournalGroup.name
        };

        return HttpClient.put<string>(`/api/finance/budgetjournalgroup/${budgetJournalGroup.id}`,data , config);
    };

}
