import React, { useState } from 'react';
import { Button, LayoutField, LayoutForm } from '@liasincontrol/ui-basics';
import { TextValidator, ValidationErrorData } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { MultiLineTextElement } from '@liasincontrol/ui-elements';

type Props = {
    disabled: boolean,
    onSave: (commentText: string) => void,
}

/**
 * Represents a UI component that renders the add comment form.
 */
const AddComment: React.FC<Props> = (props) => {
    const [comment, setComment] = useState<string>('');
    const validator = new TextValidator({ required: false, stringMaxLength: 4000, stringType: Domain.Shared.StringType.MultiLine });
    const [error, setError] = useState<ValidationErrorData[]>([]);
    return (
        <LayoutForm columns={2}>
            <LayoutField left={1} top={1} width={2} height={1}>
                <MultiLineTextElement
                    id='comment-text-field'
                    label='Reageer'
                    key='comment-key'
                    editorSettings={{
                        disabled: props.disabled,
                        restrictions: validator.getRestrictions(),
                        validationErrors: error,
                        onChange: (value: string) => {
                            setComment(value);
                            setError(validator.validate(value));
                        },
                    }}
                    value={comment} />

            </LayoutField>
            <LayoutField left={2} top={2} width={1} height={1} textAlign='right'>
                <Button id={`btn-add-comment`} disabled={props.disabled || comment.length <= 0 || error.length > 0} btnbase="primarybuttons" btntype="small_icon" onClick={() => {
                    props.onSave(comment);
                    setComment('');
                }}>
                    Plaatsen
                </Button>
            </LayoutField>
        </LayoutForm>
    );
}
export { AddComment };