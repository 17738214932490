import React, { useMemo, useState } from 'react';
import { ApiErrorReportingHelper, ValidationUtils } from '@liasincontrol/core-service';
import { Finance } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import { ErrorOverlay, Heading2, PageTitle, Section, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { GridColumn, LsGrid, createSource } from '@liasincontrol/ui-devextreme';
import { AppSettingsService } from '@liasincontrol/config-service';
import { BudgetAmountsFilters, FilterModel } from './Filter';
import { useBaseYears, useFinanceSettings } from '../../shared/hooks';

/**
 * Represents a UI component that renders the page with the list of BudgetAmounts.
 */
const BudgetAmounts: React.FC = () => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [filter, setFilter] = useState<FilterModel>({});
    const baseYears = useBaseYears();
    const settings = useFinanceSettings();

    const customDataSource = useMemo(() => {
        const filterBy: { [x in keyof FilterModel]: any; }[] = Object.keys(filter)
            ?.filter((column: keyof FilterModel) => !ValidationUtils.isEmpty(filter[column]))
            ?.map((column: keyof FilterModel) => getColumnFilter(column, filter[column]));

        return createSource({
            keyExpr: 'journalElementCombinationRK',
            paginate: true,
            filter: filterBy,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            selectedColumns: Object.keys(new Domain.Finance.BudgetAmount()),
            dataSourcePromise: Finance.BudgetAmountDataAccessor.getAll
        });
    }, [filter]);

    return (
        <>
            <Wrapper>
                <WrapperContent>
                    <PageTitle>
                        <Heading2>Budgetten</Heading2>
                    </PageTitle>
                    <ErrorOverlay error={error?.message} errorDetails={error?.details} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        <Section look='white'>
                            <BudgetAmountsFilters
                                baseYears={baseYears.items}
                                yearsBehind={settings.item?.yearsBack}
                                yearsAhead={settings.item?.yearsAhead}
                                filter={filter}
                                onFilterChanged={setFilter}
                            />
                            <LsGrid
                                showRowLines={true}
                                columns={availableColumns}
                                dataSource={customDataSource}
                                paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                                onDataError={(exception) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception))}
                            />
                        </Section>
                    </ErrorOverlay>
                </WrapperContent>
            </Wrapper>
        </>
    );
};

const availableColumns: GridColumn<Domain.Finance.BudgetAmount>[] =
    [{
        name: 'baseYear',
        title: 'Basisjaar',
        align: 'left',
        allowSorting: false,
    },
    {
        name: 'budgetYear',
        title: 'Jaarschijf',
        align: 'left',
        allowSorting: false,
    },
    {
        name: 'journalElementCombinationRK',
        title: 'Combinatie',
        allowSorting: false,
    },
    {
        name: 'measureMomentTag',
        title: 'Moment label',
        allowSorting: false,
    },
    {
        name: 'amount',
        title: 'Bedrag',
        align: 'right',
        formatter: 'decimal',
        allowSorting: false,
    }];

const getColumnFilter = (column: keyof FilterModel, value: any) => {
    switch (column) {
        case 'journalElementCombinationRK':
        case 'measureMomentTag':
            return { [column]: { contains: value } };
        default:
            return { [column]: value };
    }
};

export { BudgetAmounts as index };
