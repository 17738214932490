import styled from 'styled-components';
import { cssBreakpoint, palette, px } from '@liasincontrol/ui-basics';

const MainNavigationWrapper = styled.div`
    position: relative;
    grid-column: 3 / 6;
    grid-row: 2;
    justify-self: start;
    align-self: center;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const MainNavigation = styled.nav<{
    primaryColor?: string,
    primaryTextColor?: string
}>`
    font-weight: 400;
    font-size: ${px(14)};    
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    letter-spacing: 25;
    ${cssBreakpoint('xs', 's')`
        position: relative;
        padding: ${px(4)} ${px(20)} ${px(4)} ${px(20)};
        width: 80vw;
        z-index: 1;
    `}

    li:not(:hover):has( div > a.menu-item-link_active) {
        background: ${(p) => p.primaryColor};
        color: ${(p) => p.primaryTextColor};
    }

    .menu, .submenu {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .menu {
        display: flex;
        flex-wrap: wrap;

        ${cssBreakpoint('xs', 's')`
            display: none;
            position: absolute;
            top: 50px;
            left: 0;
            width: 100%;
            background: #fff;
            flex-direction: column;
            min-width: fit-content;
            overflow: scroll;
            max-height: 80vh;
            padding: ${px(4)} ${px(20)} ${px(4)} ${px(20)};
            border: 1px solid #cccccc;
        `}
    }

    .menu > li {
        background: #fff;
        position: relative;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        min-height: 40px;
        padding-right: ${px(4)};
        ${cssBreakpoint('xs', 's')`
            display: list-item;
            justify-content: space-between;
            width: 100%;
            padding-right: 0;
        `}
    }

    .menu > li > div.menu-item-wrapper,
    .submenu > li > div.menu-item-wrapper {
        justify-content: space-between;
        align-items: center;
        display: flex;
        width: 100%;

        ${cssBreakpoint('xs', 's')`
            width: 100%;
            justify-content: space-between;
        `}
    }

    .menu > li > div > a,
    .menu > li > div > button {
        background: transparent;
        display: inline-block;
        text-decoration: none;
        border: none;
        color: ${palette.grey1};
        cursor: pointer;
        background: transparent;
        ${cssBreakpoint('xs', 's')`
            box-sizing: border-box;
            display: inline-block;
        `}
    }

    .menu > li:hover,
    .menu > li:hover > div > a,
    .menu > li:hover > div > button,
    .menu > li > div > a:hover,
    .menu > li > div > button:hover  {
        background: #767676!important;
        color: ${palette.white}!important;
    }

    .menu > li > div > a {
        text-decoration: none!important;
        padding: ${px(4)} ${px(20)} ${px(4)} ${px(20)};
        ${cssBreakpoint('xs', 's')`
            padding: 0;
            width: 100%;
            height: 100%;
        `}
    }

    .menu > li > div > button::after {
        content: 'arrow_drop_down';
    }

    .menu > li > div > button[aria-expanded="true"]::after,
    .menu > li > div > a:hover,
    .menu > li > div > button:hover {
        content: 'arrow_drop_up';
    }

    .submenu {
        min-width: 100px;
        width: max-content;
        display: none;
        position: absolute;
        left: 0;
        top: 100%;
        background: #fff;
        z-index: 1;
        box-shadow: 0 ${px(3)} ${px(6)} rgba(0,0,0,0.5);

        ${cssBreakpoint('xs', 's')`
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            box-shadow: none;
            min-width: fit-content;
        `}
    }

    .submenu li {
        position: relative;
        background: #fff;
        display: flex;
        justify-content: start;
        align-items: center;
        min-height: 40px;
        padding-left: ${px(4)};
        padding-right: ${px(4)};
        ${cssBreakpoint('xs', 's')`
            display: list-item;
            justify-content: space-between;
            align-content: center;
            width: 100%;
            padding-left: 0;
            padding-right: 0;
        `}
    }

    .submenu a,
    .submenu button {
        display: inline-block;
        text-decoration: none;
        color: ${palette.grey1};
        border: none;
        cursor: pointer;
        max-height: 40px;
        background: transparent;
        ${cssBreakpoint('xs', 's')`
            display: inline-block;
            text-decoration: none;
            color: ${palette.grey1};
            border: none;
            cursor: pointer;
            min-height: 40px;
        `}
    }
        
    .submenu > li:not(.menu-item-link_active) > div > a {
        color: ${palette.grey1}!important;
    }

    .submenu > li:hover,
    .submenu > li:hover > div > a,
    .submenu > li:hover > div > button {
        background: #767676!important;
        color: ${palette.white}!important;
    }

    .submenu > li > div > a[aria-current="true"] {
        text-decoration: none!important;
        background: ${(p) => p.primaryColor};
        color: ${(p) => p.primaryTextColor};
    }
    
    .submenu a {
        padding: ${px(4)} ${px(20)} ${px(4)} ${px(20)};
        text-decoration: none!important;
        ${cssBreakpoint('xs', 's')`
            padding: 0;
            width: 100%;
            height: 100%;
            align-content: center;
        `}
    }

    .submenu button::after {
        content: 'arrow_right';
    }

    .submenu button[aria-expanded="true"]::after,
    .submenu a:hover,
    .submenu button:hover {
        content: 'arrow_right';
    }

    .submenu .submenu {
        left: 100%;
        top: 0;
        ${cssBreakpoint('xs', 's')`
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            box-shadow: none;
            min-width: fit-content;
        `}
    }

    button[aria-expanded="true"] + .submenu,
    .menu > li:hover > .submenu,
    .submenu li:hover > .submenu {
        display: block;
    }

    li > div > button::after {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 1.5rem;
    }
        
    /* Homepage menuitem styling */
    .primary-menu-item-homepage > div > a > span {
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 1.5rem;
    }

    /* Hamburger button styling */
    .site-primary-menu-hamburger {
        display: none;
        background: #fff;
        color: ${palette.grey1};
        padding: 10px 15px;
        border: none;
        cursor: pointer;
        ${cssBreakpoint('xs', 's')`
            display: block;
            width: 100%;
            text-align: left;
        `}
    }

    .site-primary-menu-hamburger::after {
        content: 'menu';
        font-family: 'Material Icons';
        font-weight: normal;
        font-style: normal;
        font-size: 1.5rem;
    }

    .site-primary-menu-hamburger[aria-expanded="true"] + .menu {
        display: block;
    }

    .menu-item-link_active {
        background: ${(p) => p.primaryColor}!important;
        color: ${(p) => p.primaryTextColor}!important;
        text-decoration: none!important;

        div > a {
            color: ${(p) => p.primaryTextColor}!important;
        }
    }

    a:focus, button:focus {
        /* inner indicator */
        outline: 2px ${palette.grey4} solid !important ;;
        outline-offset: 0;
        /* outer indicator */
        box-shadow: 0 0 0 4px ${palette.grey1}!important ;
    }

    //every LI which has a div with an active item as descendant (any level) get this style
    li:has( div > a.menu-item-link_active) {
        background: ${(p) => p.primaryColor}!important;
        color: ${(p) => p.primaryTextColor}!important;
        text-decoration: none!important;
    }
  
`;

const styledComponents = { MainNavigationWrapper, MainNavigation };
export default styledComponents;