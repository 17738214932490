import styled from 'styled-components';

const PaletteItemWrapper = styled.div<{ color: string }>`
    float: left;
    height: 2rem;
    width: 2rem;
    background-color: ${({ color }) => color};
`;

const styledComponents = { PaletteItemWrapper };

export default styledComponents;
