import { BaseValidationRestriction, BaseValidator, CustomContextValidator } from './BaseValidator';
import { ValidationErrorData, ValueType } from './Types';
import _ from 'lodash';

/**
 * Defines the restrictions that can be placed on a numeric validator.
 */
export interface NumberValidationRestriction extends BaseValidationRestriction {
    minValue?: number,
    maxValue?: number
}

type Restrictions = { required: boolean, minValue?: number, maxValue?: number };

/**
 *  Defines a helper class that can be used to validate a numeric field.
 */
export class NumberValidator extends BaseValidator<number> {
    public constructor(restrictions: Restrictions, contextValidator?: CustomContextValidator<number>) {
        super(restrictions.required);

        this.restrictions = {
            minValue: restrictions.minValue,
            maxValue: restrictions.maxValue,
        };

        this.contextValidator = contextValidator;
    }

    protected restrictions: NumberValidationRestriction = {};
    private contextValidator: CustomContextValidator<number>;
    public validate(value: number, formData?: Record<string, ValueType>): ValidationErrorData[] {
        const errors: ValidationErrorData[] = super.validate(value);
        if (errors.length > 0) {
            return errors;
        }

        if (_.isNumber(value) && !_.isNull(this.restrictions.minValue) && value < this.restrictions.minValue) {
            errors.push({ error: `Minimaal ${this.restrictions.minValue}.` });
        } else if (_.isNumber(value) && !_.isNull(this.restrictions.maxValue) && value > this.restrictions.maxValue) {
            errors.push({ error: `Maximaal ${this.restrictions.maxValue}.` });
        }

        if (this.contextValidator) {
            errors.push(...this.contextValidator(value, formData));
        }

        return errors;
    }

    /**
     * Gets the number restrictions.
     */
    public getRestrictions = (): NumberValidationRestriction => {
        return Object.assign({}, this.restrictions, this.baseRestrictions);
    };
}
