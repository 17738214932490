import { forwardRef } from 'react';
import React from 'react';
import posed from 'react-pose';

export interface ICollapserProps {
    readonly children?: React.ReactNode;
    readonly initiallyClosed?: boolean;
    readonly open: boolean;
    readonly ref?: Exclude<string, React.Ref<HTMLDivElement>>;
}

export const Collapser = forwardRef<HTMLDivElement, ICollapserProps>(({ open, initiallyClosed = !open, children }, ref) => (
    <Container initialPose={initiallyClosed ? `closed` : `opened`} pose={open ? `opened` : `closed`} withParent={false} ref={ref}>
        {children}
    </Container>
));

const Container = posed.div({
    opened: { height: `auto`, applyAtEnd: { overflow: ` visible` } },
    closed: { height: 0, applyAtStart: { overflow: ` hidden` } },
});
