import React from 'react';
import Styled from './index.styled';

type Props =  {
    variant: string;
    size: 'large' | 'small';
};


export const TemplateImg: React.FC<Props> = (props) => {
    return (<Styled.SvgWrap {...props} />);
};