import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import Add from '@mui/icons-material/Add';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { connect } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { Publisher as DataAccess } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';
import { UserIdentity } from '@liasincontrol/auth-service';
import { SystemElementDefinitions, SystemFieldDefinitions, SystemModuleDefinitions } from '@liasincontrol/domain';
import { ActionSource, AjaxRequestStatus, AttachmentsActionCreator, ElementDefinitionsActionCreator, HierarchyDefinitionsActionCreator, ModulesActionCreator, State } from '@liasincontrol/redux-service';
import { WrapperContent, PageTitle, Heading1, Bar, Button, Section, ErrorOverlay } from '@liasincontrol/ui-basics';
import { AppSettingsService } from '@liasincontrol/config-service';
import { DefinitionsHelper, ValidationUtils, ApiErrorReportingHelper, AttachmentsHelper } from '@liasincontrol/core-service';
import { LsGrid, createSource, GridColumn } from '@liasincontrol/ui-devextreme';

import { DataStoreAdd } from './DataStoreAdd';
import CsvFeedbackModal from './CsvFeedbackModal';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders a list of data stores page.
 */
const Index: React.FC<Props> = (props) => {
    const [lastRefresh, setLastRefresh] = useState<number>();
    const [csvErrorFeedback, setCsvErrorFeedback] = useState<{ title: string, detail: string }>();
    const navigate = useNavigate();
    const [showAddDialog, setShowAddDialog] = useState<boolean>(false);
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);

    const customDataSource = useMemo(() => {
        return createSource({
            paginate: true,
            pageSize: AppSettingsService.getAppSettings().General.PageSize,
            selectedColumns: Object.keys(new Domain.Publisher.DataStoreListItem()),
            dataSourcePromise: DataAccess.DataStores.getDataStores
        });
    }, [lastRefresh, props.elementdefinition]);

    if (!props.modules) {
        props.fetchModules();
        return null;
    }

    if (!props.elementdefinition || props.elementdefinition.status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(props.modules[SystemModuleDefinitions.Publisher]);
        return null;
    }

    if ((!props.hierarchyDefinitions || props.hierarchyDefinitions.status === AjaxRequestStatus.NotSet)) {
        props.fetchHierarchyDefinitions(ActionSource.Studio, props.modules[Domain.SystemModuleDefinitions.Studio], true);
        return null;
    }

    const loadAttachment = async (attachmentId: string): Promise<Blob> => {
        return AttachmentsHelper.loadExistingAttachment(attachmentId, props.attachments, props.setAttachment);
    };

    const uploadAttachment = async (file: File, abortSignal: AbortSignal) => {
        return AttachmentsHelper.uploadAttachment(file, abortSignal, props.setAttachment);
    };

    const saveNewDataStore = (formValues: Record<string, string>) => {
        try {
            const elementFieldValues = Object.keys(formValues).reduce((collection, systemId) => ({ ...collection, [props.fieldDefinitions[systemId].id]: formValues[systemId] }), {}) as Record<string, string>;
            const isTypeCsvSelected = props.fieldDefinitions && !ValidationUtils.isEmpty(formValues[SystemFieldDefinitions.Pub.Type]) && (formValues[SystemFieldDefinitions.Pub.Type] === props.fieldDefinitions[SystemFieldDefinitions.Pub.Type].optionItems[0].id);

            const attachments = [] as Domain.Shared.Attachment[];
            const newSourceFileId = formValues[SystemFieldDefinitions.Pub.SourceFile];

            if (isTypeCsvSelected && newSourceFileId) {
                // replace the attachment's ID with a new GUID.
                const newAttachmentId = uuid();
                const newAttachment = { ...AttachmentsHelper.mapFileToAttachment(props.attachments[newSourceFileId], newSourceFileId, true), id: newAttachmentId };

                // the new attachment will contain a newly generated UUID. Set that as the SourceFileId instead of the current blobId.
                elementFieldValues[props.fieldDefinitions[SystemFieldDefinitions.Pub.SourceFile].id] = newAttachment.id;
                attachments.push(newAttachment);
            }

            DataAccess.DataStores.createDataStore(props.elementdefinition.item, attachments, elementFieldValues)
                .then((response) => {
                    const newDataStoreId = response.data;
                    navigate(`/admin/datastore/${newDataStoreId}/information`);
                    setShowAddDialog(false);
                })
                .catch(({ response }) => {
                    setCsvErrorFeedback({ title: response.data.title, detail: response.data.detail });
                });

        } catch (err) {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err));
        }
    };

    return (
        <WrapperContent>
            <PageTitle>
                <Heading1>Gegevensverbindingen</Heading1>
            </PageTitle>
            <Bar look="toolbar">
                <Bar start>
                    <Button
                        id="btn-create"
                        btnbase="textbuttons"
                        btntype="medium_icon"
                        icon={<Add />}
                        onClick={() => setShowAddDialog(true)}>
                        Nieuw
                    </Button>
                    <Button
                        id="btn-refresh"
                        btnbase="textbuttons"
                        btntype="medium_icon"
                        icon={<RefreshOutlinedIcon />}
                        onClick={() => setLastRefresh(Date.now())}>
                        Verversen
                    </Button>
                </Bar>
            </Bar>

            <Section look="white">
                <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <LsGrid
                        dataSource={customDataSource}
                        columns={columnConfiguration}
                        enableColumnChooser={true}
                        searching={true}
                        paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                        showRowLines={true}
                        onClickRow={(item: Domain.Publisher.DataStoreListItem) => {
                            navigate(`/admin/datastore/${item.id}/information`);
                        }}
                        onDataError={(error) => setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, error))}
                    />
                </ErrorOverlay>
            </Section>

            {showAddDialog &&
                <DataStoreAdd
                    fieldDefinitions={props.fieldDefinitions}
                    hierarchyDefinitions={Object.values(props.hierarchyDefinitions.items)?.filter(hd => !hd.deleted)}
                    onLoadAttachment={loadAttachment}
                    onUploadAttachment={uploadAttachment}
                    onSaveChanges={saveNewDataStore}
                    onCancelChanges={() => setShowAddDialog(false)} />}
            {csvErrorFeedback &&
                <CsvFeedbackModal
                    error={csvErrorFeedback}
                    onCancel={() => setCsvErrorFeedback(undefined)}
                />}
        </WrapperContent>
    );
};

const columnConfiguration: GridColumn<Domain.Publisher.DataStoreListItem & { source: string }>[] = [
    {
        name: 'name',
        title: 'Naam'
    },
    {
        name: 'kind',
        title: 'Type',
        width: '15%'
    },
    {
        name: 'source',
        title: 'Bron',
        allowSorting: false,
        renderCustom: (item) => {
            const cellContent = item.data['hierarchyDefinitionName'] || item.data['financeTable'] || item.data['dataPlatformView'] || item.data['sourceFileName'];
            return (
                <>{cellContent}</>
            );
        }
    },
    {
        name: 'sourceFileUploadedOn',
        title: 'Datum upload',
        dataType: 'date',
        formatter: 'datetime',
        width: '20%'
    },
];

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    const hasElementDefinitions = !_.isEmpty(state.elementdefinitions?.[ActionSource.Publication]);
    const item = hasElementDefinitions ?
        DefinitionsHelper.findElementDefinition(
            state.elementdefinitions[ActionSource.Publication]?.items,
            SystemElementDefinitions.Pub.DataStore
        )
        : undefined;

    return {
        modules: state.modules[ActionSource.Publication],
        elementdefinition: {
            item: item,
            status: hasElementDefinitions ? state.elementdefinitions[ActionSource.Publication].status : AjaxRequestStatus.NotSet,
        },
        fieldDefinitions: item?.fields?.reduce((collection, item) => ({ ...collection, [item.systemId]: item }), {}) as Record<string, Domain.Shared.FieldDefinition>,
        attachments: state.attachments,
        hierarchyDefinitions: state.hierarchydefinitions[ActionSource.Studio],
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: () => {
            dispatch(ModulesActionCreator.set({ source: ActionSource.Publication, data: {} }));
        },
        fetchElementDefinitions: (module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: ActionSource.Publication, data: { moduleId: module?.id } }));
        },
        setAttachment: (attachmentId: string, attachment: File) => {
            dispatch(AttachmentsActionCreator.set({ source: ActionSource.Publication, data: { attachmentId, attachment } }))
        },
        fetchHierarchyDefinitions: (source: ActionSource, module: Domain.Shared.Module, includeLinkDefinitions: boolean) => {
            dispatch(HierarchyDefinitionsActionCreator.set({ source: source, data: { moduleId: module.id, includeLinkDefinitions: includeLinkDefinitions } }));
        },
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(Index);
export { Component as index };
