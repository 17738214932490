import styled from 'styled-components';
import { palette, px } from '@liasincontrol/ui-basics';


const Grid = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-auto-flow: row;
    grid-gap: ${px(8)} ${px(5)};
    padding-bottom: 1rem;
`;

const ContentHeaderBarLeft = styled.div`
    grid-column: 2 / 2;
    grid-row: 1 / span 1;
    border-bottom: 2px solid ${palette.grey3b};
`;

const ContentHeaderLeft = styled.div`
    grid-column: 2 / -1;
    grid-row: 1 / span 1;
    min-height: ${px(16)};
    display: flex;
    align-items: flex-end;
`;
const ContentHeaderBarRight = styled.div`
    grid-column: 1 / 6;
    grid-row: 1 / span 1;
    border-bottom: 2px solid ${palette.grey3b};
`;
const ContentHeaderRight = styled.div`
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    min-height: ${px(16)};
    display: flex;
    align-items: flex-end;
    > * {    
      &:not(:only-child):not(:last-child)  {
        margin-right: ${px(25)};
      }
    }
`;

export { ContentHeaderRight, ContentHeaderBarRight, ContentHeaderLeft, ContentHeaderBarLeft, Grid };