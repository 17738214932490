/**
 * Represents a row of budget element group.
 */
class BudgetElementGroup {

    id: string;

    name: string;

    baseYear: number;

    code: string;

    selectedTags: string[];

}

class BudgetElementGroupTag {
    tag: string;

    groupCount: number;
}

export { BudgetElementGroupTag, BudgetElementGroup };
