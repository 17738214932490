import React from 'react';
import { ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';

type Props = {
    onConfirm: (elementDefinitionId: string) => void,
    onCancel: () => void,
};

/**
 * Represents an UI component that opens a confirmation dialog to deactivate an element definition.
 */
const DeleteElementDialog: React.FC<Props> = (props) => {

    return (
        <ModalDialog
            id='dialog-delete-element'
            settings={{
                look: 'message',
                title: 'Entiteit verwijderen',
                footer: <ModalDialogFooter leftButtonText='Annuleren' onLeftButtonClick={props.onCancel} rightButtonText='Verwijderen' onRightButtonClick={() => props.onConfirm()} />
            }}
        >
            <Text danger={true} value='Weet u zeker dat u dit element wilt verwijderen? Deze actie kan niet meer ongedaan worden gemaakt.' />
        </ModalDialog>
    );
};

export { DeleteElementDialog };