import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AppsIcon from '@mui/icons-material/Apps';
import SettingsIcon from '@mui/icons-material/Settings';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import AuthService, { License, UserIdentity } from '@liasincontrol/auth-service';
import { Bar, Button, WrapperHeader, Logo, AccountButton, MainMenu, ContextMenu, IconSize, MenuAction } from '@liasincontrol/ui-basics';

type Props = {
    look: 'normal' | 'admin'
};

/**
 * Represents a UI component that renders the application header.
 */
const Header: React.FC<Props> = (props) => {
    const [userIdentity, setUserIdentity] = useState<UserIdentity>();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        AuthService.getInstance()
            .then(async (auth) => {
                const user = await auth.getUser();
                if (user) {
                    setUserIdentity(user);
                } else {
                    const redirectPath = location.pathname; // rest.location?.pathname;
                    auth.attemptSilentLogin(redirectPath as string)
                        .then((identity) => {
                            if (identity) {
                                setUserIdentity(identity);
                            }
                        });
                }
            });
    }, []);

    const isAdminLook = props.look && props.look === 'admin';
    const logoLook = isAdminLook ? 'inverted' : 'default';
    const accountButtonLook = isAdminLook ? 'inverted' : 'default';
    const openAdminButtonBtnType = isAdminLook ? 'medium_admin' : 'medium_transparentmain';

    const accountInitials = userIdentity ? (userIdentity.profile?.name?.match(/\b[A-Za-zÀ-ÿ0-9]/g) || []).slice(0, 3).join('').toUpperCase() : 'N/A';
    const userLicenses = userIdentity?.profile?.licenses || [];
    let adminUrl: string | undefined = undefined;

    if (userIdentity) {
        if (!adminUrl && UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_UsersAndGroups, ActionType.Read)) {
            adminUrl = "/admin/user/list";
        }
        if (!adminUrl && UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_Roles, ActionType.Read)) {
            adminUrl = "admin/role/list";
        }
        if (!adminUrl && UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_DataStores, ActionType.Read)) {
            adminUrl = "/admin/datastore/list";
        }
        if (!adminUrl && UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_Workflows, ActionType.Read)) {
            adminUrl = "/admin/workflow/list";
        }
        if (!adminUrl && UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_MeasureMoments, ActionType.Read)) {
            adminUrl = "/admin/measuremoment/list";
        }
    }

    const hasOnlyTextAssistant = userIdentity?.profile?.licenses.length === 1 && UserRightsService.getInstance().userHasLicence(userIdentity, License.AITextAssistant);
    const homeUrl = hasOnlyTextAssistant ? '/ai' : '/';

    const logoutUser = () => {
        return AuthService.getInstance().then((auth) => auth.signoutRedirect());
    }

    return (
        <WrapperHeader look={props.look}>
            <Bar look='system'>
                <Bar start>
                    <ContextMenu look={accountButtonLook} icon={<AppsIcon sx={{ fontSize: IconSize.large }} />} contextItem="menu" menuAction={MenuAction.Main} position="default" highlightPopup={false}>
                        <MainMenu
                            licenses={userLicenses}
                            withExternalApp={!!!hasOnlyTextAssistant}
                            withUserManagement={true}
                        />
                    </ContextMenu>
                    <Logo
                        look={logoLook}
                        customer={userIdentity?.profile?.tenant_name || 'N/A'}
                        goHome={() => {
                            navigate(homeUrl);
                        }}
                    />
                </Bar>
                <Bar end>
                    {!!!hasOnlyTextAssistant && !!adminUrl && (
                        <Button id="btn-admin" as={Link} btnbase='iconbuttons' btntype={openAdminButtonBtnType}
                            icon={<SettingsIcon sx={{ fontSize: IconSize.default }} />}
                            aria-label='Open beheer' to={adminUrl}>
                            Open beheer
                        </Button>
                    )}
                    <AccountButton look={accountButtonLook} accountName={accountInitials}>
                        <li>
                            <Button
                                id='btn-logout'
                                btnbase='textbuttons'
                                btntype='flat_noicon'
                                onClick={() => logoutUser()}
                            >
                                Uitloggen
                            </Button>
                        </li>
                    </AccountButton>
                </Bar>
            </Bar>
        </WrapperHeader >
    );
};

export default Header;
