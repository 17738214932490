import { BaseContainerElement } from '../../BaseContainerElement';
import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';

class TabContainerItemElement extends BaseContainerElement {
    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TabPosition)
    tabPosition: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.TabIconPosition)
    tabIconPosition: string;
}

export default TabContainerItemElement;