import { useState } from "react";

/**
 * Loading indicator that uses a counter internally to handle multiple asynchronous calls
 * It is important that every start call has a corresponding stop call.
 */
export const useLoading = () => {
    const [loading, setLoading] = useState(0);

    const reset = () => {
        setLoading(0);
    };

    const start = () => {
        setLoading((loading:number) => loading + 1);
    };
    const stop = () => {
        setLoading((loading:number) => loading - 1);
    };
    const isLoading = () => {
        return loading > 0;
    };

    return {
       start,
       stop,
       isLoading,
       reset,
    }
};