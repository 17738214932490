import React, { useEffect, useState } from 'react';
import { LayoutForm, LayoutField } from '@liasincontrol/ui-basics';
import { SelectElement, TextElement } from '@liasincontrol/ui-elements';
import { AccountClass } from '../../../shared/constants';

export type FilterModel = {
    baseYear?: number,
    benefitCaption?: string,
    elementCode?: string;
    elementName?: string;
}

type Props = {
    baseYears?: number[],
    benefitCaptions?: AccountClass[],
    filter: FilterModel;
    onFilterChanged: (filter: FilterModel) => void,
}

export const JournalCombinationFilters: React.FC<Props> = (props) => {
    const [filter, setFilter] = useState<FilterModel>(props.filter);

    useEffect(() => {
        props.onFilterChanged(filter);
    }, [filter]);

    return (
        <LayoutForm columns={8}>
            {props.baseYears &&
                <LayoutField left={1} top={1} width={1} height={1}>
                    <SelectElement<number>
                        id='select-base-year'
                        label='Basisjaar'
                        optionItems={props.baseYears}
                        value={filter.baseYear}
                        clearable={false}
                        searchable={false}
                        editorSettings={{
                            disabled: false,
                            restrictions: { required: false },
                            validationErrors: [],
                            onChange: (item) => setFilter(prev => ({ ...prev, baseYear: item })),
                        }}
                    />
                </LayoutField>
            }
            {props.benefitCaptions &&
                <LayoutField left={2} top={1} width={1} height={1}>
                    <SelectElement<AccountClass>
                        id='select-isBenefitCaption'
                        label='Rubriek'
                        displayExpr='label'
                        //valueExpr='value'
                        optionItems={props.benefitCaptions}
                        value={props.benefitCaptions.find(bc => bc.value === filter.benefitCaption)}
                        clearable={true}
                        searchable={false}
                        editorSettings={{
                            disabled: !filter.baseYear,
                            restrictions: { required: false },
                            validationErrors: [],
                            onChange: (item: AccountClass) => setFilter(prev => ({ ...prev, benefitCaption: item.value })),
                        }}
                    />
                </LayoutField>
            }
            <LayoutField left={props.baseYears && props.benefitCaptions ? 3 : 1} top={1} width={2} height={1}>
                <TextElement id='search-externalCode'
                    value={filter.elementCode}
                    label='Externe code'
                    editorSettings={{
                        disabled: !filter.baseYear,
                        restrictions: { required: false },
                        validationErrors: [],
                        onChange: (value: string) => setFilter(prev => ({ ...prev, elementCode: value })),
                    }}
                />
            </LayoutField>
            <LayoutField left={props.baseYears && props.benefitCaptions ? 5 : 3} top={1} width={2} height={1}>
                <TextElement id='search-name' value={filter.elementName}
                    label='Naam'
                    editorSettings={{
                        disabled: !filter.baseYear,
                        restrictions: { required: false },
                        validationErrors: [],
                        onChange: (value: string) => setFilter(prev => ({ ...prev, elementName: value })),
                    }}
                />
            </LayoutField>
        </LayoutForm>
    );
}