import { fieldDefinitionDecorator } from '../../../../Shared/FieldDefinition/FieldDefinitionDecorator';
import { SystemFieldDefinitions } from '../../../../Shared/FieldDefinition';
import { BaseControlElement } from '../../BaseControlElement';

class ImageElement extends BaseControlElement {

    constructor(format?: string) {
        super();
        this.format = format;
    }
    
    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ShowCompleteImage)
    fill = false;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ImageFocusPoint)
    focusPoint: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ImageCaption)
    caption: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ImageSize)
    format: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.AltText)
    altText: string;

    @fieldDefinitionDecorator(SystemFieldDefinitions.Pub.ImageSource)
    image: string;

}

export default ImageElement;