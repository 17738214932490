import styled from 'styled-components';
import { palette, px } from '@liasincontrol/ui-basics';

const ButtonContainer = styled.div`
    margin-top: ${px(16)};
`;

const SideMenuList = styled.ul`
    list-style: none;
    padding:0; 
    margin:0 24px 0 0;
    background-color: inherit;
    border-radius:5px;
    overflow: auto;
`;

const TitleItemContainer = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    padding: 10px;
    align-items: center;    
`;

const TitleItem = styled.div<{ disabled?: boolean }>`
    color: ${(p) => (p.disabled ? `${palette.grey3b}` : 'inherit')};
    text-align: left;
    &:nth-child(2n) {
        font-weight: bold;
        padding-left: ${px(5)};
    }
`;

const Wrapper = styled.div<{ isCurrentStep?: boolean }>`
    background-color: ${(p) => (p.isCurrentStep ? `${palette.grey4}` : `${palette.white}`)};
`;

export { ButtonContainer, SideMenuList, TitleItemContainer, TitleItem, Wrapper };
