import React from 'react';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import { Heading2 } from '@liasincontrol/ui-basics';
import { ContextMenu, GridColumn, LsGrid } from '@liasincontrol/ui-devextreme';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import * as Domain from '@liasincontrol/domain';
import Styled from './index.styled';
import { PublicationWebsiteVersion } from './../';
import { buildStatusTranslation } from '../..';

type Props = {
    publicationId: string,
    versions: PublicationWebsiteVersion[],
    userIdentity: UserIdentity,
    onDeleteVersion: (item: PublicationWebsiteVersion) => void,
    onChangeSiteAccess: (item: PublicationWebsiteVersion) => void,
    onShowErrors?: (buildErrors?: Domain.Publisher.BuildError[]) => void,
};

/** 
 * Represents a UI component that renders the list of publication versions. 
 */
export const VersionList: React.FC<Props> = (props) => {
    const availableColumns: GridColumn<PublicationWebsiteVersion>[] = getColumnConfiguration(
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_PublishPublication, ActionType.Delete)
            ? props.onDeleteVersion
            : null,
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_PublishPublication, ActionType.Update)
            ? props.onChangeSiteAccess
            : null,
        props.onShowErrors
    );

    return (
        <Styled.VersionListWrapper>
            <Heading2>Versies</Heading2>
            <LsGrid
                keyExpr="versionId"
                dataSource={props.versions}
                columns={availableColumns}
                enableColumnChooser={false}
                showRowLines={true}
                onClickRow={(item: PublicationWebsiteVersion) => {
                    if (item.publishedSiteUrl) {
                        window.open(item.publishedSiteUrl, '_blank');
                    }
                }}
            />
        </Styled.VersionListWrapper>
    );
};

const getColumnConfiguration = (
    onDelete?: (item: PublicationWebsiteVersion) => void,
    onChangeSiteAccess?: (item: PublicationWebsiteVersion) => void,
    onShowError?: (buildErrors?: Domain.Publisher.BuildError[]) => void
): GridColumn<PublicationWebsiteVersion>[] => {
    return [
        {
            name: 'versionNumber',
            title: 'Versie nr',
            width: '5%',
            align: 'left',
        },
        {
            name: 'versionName',
            title: 'Versienaam',
            align: 'left',
        },
        {
            name: 'publishProfileName',
            title: 'Profiel',
            width: '10%',
            align: 'left',
        },
        {
            name: 'userName',
            title: 'Gepubliceerd door',
            width: '20%',
            align: 'left',
        },
        {
            name: 'lastRun',
            title: 'Datum gepubliceerd',
            dataType: 'date',
            formatter: 'datetime',
            width: '20%',
            align: 'left',
        },
        {
            name: 'lastRunStatus',
            title: 'Huidige status',
            calculateDisplayValue: (site) => buildStatusTranslation[site.lastRunStatus],
            width: '10%',
            align: 'left',
        },
        {
            name: 'publishedSiteIsPublic',
            title: 'Toegang tot de publicatie',
            renderCustom: (site) => <>{site.data.publishedSiteIsPublic ? 'Openbaar' : 'Afgeschermd'}</>,
            width: '12%',
            align: 'left',
        },
        {
            name: 'publishedSiteUrl',
            title: 'Link',
            width: '5%',
            align: 'left',
            renderCustom: () =>
                <Styled.AccessSiteButton
                    icon={<LaunchOutlinedIcon />}
                    title='Open'
                    btnbase='iconbuttons'
                    btntype='small_transparent'
                />
        },
        {
            align: 'right',
            name: 'versionId',
            title: '',
            type: 'buttons',
            width: '5%',
            hideInColumnChooser: true,
            renderCustom: ({ data }) => (onDelete || onChangeSiteAccess || onShowError) &&
                <ContextMenu<PublicationWebsiteVersion>
                    keyExpr='versionId'
                    item={data}
                    actions={[
                        {
                            action: onChangeSiteAccess,
                            displayName: 'Toegang wijzigen',
                            ariaLabel: `Versie access`,
                            actionName: `change-access-${data.versionId}`
                        },
                        {
                            action: onDelete,
                            displayName: "Verwijderen",
                            ariaLabel: `Verwijder ${data.dataSourceName}`,
                            actionName: `delete-datasource-${data.dataSourceId}`
                        },
                        {
                            action: (item: PublicationWebsiteVersion) => onShowError(item.buildErrors),
                            displayName: "Foutenlog",
                            ariaLabel: `Versie foutenlog`,
                            actionName: `show-errors-${data.versionId}`,
                            hidden: !!!data.buildErrors || data.buildErrors.length === 0
                        }
                    ]}
                />
        }
    ];
};
