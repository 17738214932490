import React, { useEffect, useMemo, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { px } from '../style';

const stopPropagation: React.ReactEventHandler<HTMLElement> = (e) => e.stopPropagation();

enum Direction {
    left = 'left',
    top = 'top',
    bottom = 'bottom',
    right = 'right',
}

export const TooltipBox = React.forwardRef<HTMLDivElement, { children: React.ReactNode, visible: boolean; onClick: () => void; style: React.CSSProperties }>(
    ({ children, visible, onClick, style }, scrollContainerRef) => {
        const [keepVisible, setKeepVisible] = useState(visible);
        useEffect(() => {
            if (visible) {
                setKeepVisible(true);
            } else {
                const timer = setTimeout(() => setKeepVisible(false), 300);
                return () => clearTimeout(timer);
            }
        }, [visible]);
        const direction = useMemo<Direction>(() => {
            if (typeof document !== `undefined`) {
                const bounds = document.documentElement.getBoundingClientRect();
                const farLeft = Number(style.left) < bounds.width / 4;
                const farRight = Number(style.left) > (bounds.width / 4) * 3;
                const lowerH = Number(style.top) < bounds.height / 2;
                if (farLeft) {
                    return Direction.right;
                } else if (farRight) {
                    return Direction.left;
                } else if (lowerH) {
                    return Direction.bottom;
                } else {
                    return Direction.top;
                }
            }
            return Direction.left;
        }, [style.left, style.top]);
        return (
            (keepVisible && (
                <Background animateIn={visible} style={style} direction={direction} onClick={onClick}>
                    <ScrollContainer onScroll={stopPropagation} ref={scrollContainerRef}>
                        {children}
                    </ScrollContainer>
                </Background>
            )) ||
            null
        );
    },
);

const FadeIn = {
    [Direction.left]: keyframes`
            0% {
                opacity: 0.5;
                border-radius: 50%;
                transform: translateX(-100%) translateY(-50%) translateX(-32px) scale(0.0);
                overflow: hidden;
            }
            100% {
                transform: translateX(-100%) translateY(-50%) translateX(-32px) scale(1.0);
                border-radius: ${px(4)};
            }
            overflow: visible;
        `,
    [Direction.top]: keyframes`
            0% {
                opacity: 0.5;
                border-radius: 50%;
                transform: translateX(-50%) translateY(-100%) translateY(-32px) scale(0.0);
                overflow: hidden;
            }
            100% {
                transform: translateX(-50%) translateY(-100%) translateY(-32px) scale(1.0);
                border-radius: ${px(4)};
            }
            overflow: visible;
        `,
    [Direction.right]: keyframes`
            0% {
                opacity: 0.5;
                border-radius: 50%;
                transform: translateY(-50%) translateX(32px) scale(0.0);
                overflow: hidden;
            }
            100% {
                transform: translateY(-50%) translateX(32px) scale(1.0);
                border-radius: ${px(4)};
            }
            overflow: visible;
        `,
    [Direction.bottom]: keyframes`
            0% {
                opacity: 0.5;
                border-radius: 50%;
                transform: translateX(-50%) translateY(+32px) scale(0.0);
                overflow: hidden;
            }
            100% {
                transform: translateX(-50%) translateY(+32px) scale(1.0);
                border-radius: ${px(4)};
            }
            overflow: visible;
        `,
};
const FadeOut = {
    [Direction.left]: keyframes`
            0% {
                transform: translateX(-100%) translateY(-50%) translateX(-32px) scale(1.0);
                border-radius: ${px(4)};
                overflow: hidden;
            }
            100% {
                opacity: 0.5;
                border-radius: 50%;
                transform: translateX(-100%) translateY(-50%) translateX(-32px) scale(0.0);
                overflow: visible;
            }
        `,
    [Direction.top]: keyframes`
            0% {
                transform: translateX(-50%) translateY(-100%) translateY(-32px) scale(1.0);
                border-radius: ${px(4)};
                overflow: hidden;
            }
            100% {
                opacity: 0.5;
                border-radius: 50%;
                transform: translateX(-50%) translateY(-100%) translateY(-32px) scale(0.0);
                overflow: visible;
            }
        `,
    [Direction.right]: keyframes`
            0% {
                transform: translateY(-50%) translateX(32px) scale(1.0);
                border-radius: ${px(4)};
                overflow: hidden;
            }
            100% {
                opacity: 0.5;
                border-radius: 50%;
                transform: translateY(-50%) translateX(32px) scale(0.0);
                overflow: visible;
            }
        `,
    [Direction.bottom]: keyframes`
            0% {
                transform: translateX(-50%) translateY(+32px) scale(1.0);
                border-radius: ${px(4)};
                overflow: hidden;
            }
            100% {
                opacity: 0.5;
                border-radius: 50%;
                transform: translateX(-50%) translateY(+32px) scale(0.0);
                overflow: visible;
            }
        `,
};

const CornerDirection = {
    [Direction.left]: css`
        transform-origin: 100% 50%;
        transform: translateX(-100%) translateY(-50%) translateX(-32px) scale(1);
        :before {
            transform: translate(+50%, -50%) rotate(135deg);
            top: 50%;
            right: 0;
        }
    `,
    [Direction.top]: css`
        transform-origin: 50% 100%;
        transform: translateX(-50%) translateY(-100%) translateY(-32px) scale(1);
        :before {
            transform: translate(-50%, +50%) rotate(225deg);
            bottom: 0;
            left: 50%;
        }
    `,
    [Direction.right]: css`
        transform-origin: 0% 50%;
        transform: translateY(-50%) translateX(32px) scale(1);
        :before {
            transform: translate(-50%, -50%) rotate(315deg);
            top: 50%;
            left: 0;
        }
    `,
    [Direction.bottom]: css`
        transform-origin: 50% 0;
        transform: translateX(-50%) translateY(+32px) scale(1);
        :before {
            transform: translate(-50%, -50%) rotate(45deg);
            top: 0;
            left: 50%;
        }
    `,
};

const Background = styled.div.attrs({ role: `dialog` })<{ animateIn: boolean; direction: Direction }>`
    background-color: #ffffff;
    border: 1px solid #ddd;
    box-shadow: 0 5px 20px rgba(75,85,246,.06);
    position: relative;

    :before {
        content: '';
        box-shadow: -2px -2px 4px rgba(75,85,246, 0.04);
        background-color: #fff;
        width: 16px;
        height: 16px;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        position: absolute;
    }

    ${(p) => CornerDirection[p.direction]}
    animation: ${(p) => (p.animateIn ? FadeIn[p.direction] : FadeOut[p.direction])} 0.15s forwards;
`;
const ScrollContainer = styled.div`
    overflow: hidden;
    overflow-y: auto;
    max-height: 50vh;
    position: relative;
`;