import React, { useState } from 'react';
import { ModalDialog, ModalDialogFooter, Text } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import { SelectElement } from '@liasincontrol/ui-elements';

type Props = {
    entityId: string,
    disableSubmitButton: boolean,
    onReparent: (parentEntityId: string) => void,
    onCancel: () => void,
    getAvailableParents: () => Domain.Performance.HierarchyItemElement[]
};

/**
 * Represents an UI component that opens a dialog that ask for changing the parent of an existing performance hierarchy item.
 */
export const HierarchyItemReparentDialog: React.FC<Props> = (props) => {

    const [selectedParent, setSelectedParent] = useState<Domain.Performance.HierarchyItemElement>();

    const footerElement = (
        <ModalDialogFooter
            leftButtonText='Annuleren' onLeftButtonClick={props.onCancel}
            rightButtonText='Verplaatsen' onRightButtonClick={() => props.onReparent(selectedParent.id)}
            rightButtonDisabled={props.disableSubmitButton || !selectedParent}
        />
    );

    const options = props.getAvailableParents();

    const selectedOption = selectedParent && options.find(item => item.id === selectedParent.id);

    return (
        <ModalDialog id="reparent-entity-dialog"
            settings={{
                look: "message",
                title: "Verplaatsen",
                footer: footerElement
            }}>
            <Text value="Verplaats het item naar een ander bovenliggend element." />
            <div style={{ width: `100%` }}>
                <SelectElement<Domain.Performance.HierarchyItemElement>
                    id='reparent-select-parent'
                    displayExpr='name'
                    optionItems={options}
                    placeholder='Kies...'
                    value={selectedOption}
                    editorSettings={{
                        onChange: setSelectedParent
                    }}
                />
            </div>
        </ModalDialog>
    );
};
