import styled from 'styled-components';
import { px, palette, cssFontSemiBold, cssFontNormal, cssHeading1 } from '@liasincontrol/ui-basics';

const Content = styled.div`
    grid-column: 2 / span 12;
    grid-row: 7 / -1;
    display: grid;
    grid-template-columns: repeat(12, [col-content-start] 1fr);
    grid-template-rows: ${px(20)} minmax(0, 60vh) ${px(20)};
    grid-gap: ${px(8)} ${px(20)};
    position: relative;
`;

const ColumnTitle = styled.h2`
    ${cssHeading1}
`;

const ContentColumn1 = styled.div`
    grid-column: col-content-start 1 / span 3;
    grid-row: 2 / -1;   
    height: 55vh;
    align-self: start;
`;

const ContentColumn2 = styled.div`
    grid-column: col-content-start 4 / span 8;
    grid-row: 2 / -1;
    display: grid;
    grid-template-columns: repeat(2, [col-content-start] 1fr);
    grid-auto-rows: min-content;
    align-self: start;
    row-gap: inherit;
`;

const Wrapper = styled.div`
    border: ${px(2)} solid ${palette.grey4};
    border-radius: ${px(4)};
    padding: ${px(4)};
    overflow: auto;
    height: 100%;
`;

const RowWrapper = styled.div<{ rowStart: number }>`
    grid-column: 1 / span 2;
    grid-row: ${({ rowStart }) => (`${rowStart} / ${rowStart + 1}`)};
    align-self: start;
    padding: ${({ rowStart }) => rowStart > 2 ? `${px(0)} ${px(8)} ${px(8)} ${px(0)}` : 'none'};  
    border-bottom: ${({ rowStart }) => rowStart > 2 ? `${px(1)} solid ${palette.grey4}` : 'none'};
    display: grid;
    grid-template-columns: 50%;
    column-gap: 1rem;
`;

const ItemWrapper = styled.div<{ colStart: number, colEnd?: number }>`
    grid-column: ${({ colStart, colEnd }) => (`${colStart} / ${colEnd ? colEnd : colStart + 1}`)};
    align-self: start;
`;

const Text = styled.div<{ color: string, withOverflow?: boolean }>`
    background-color: ${({ color }) => (color === 'success' ? '#d4edda' : '#e2e3e5')};
    border: ${px(1)} solid ${({ color }) => (color === 'success' ? '#c3e6cb' : '#d6d8db')};
    border-radius: ${px(4)};
    ${({ withOverflow }) => (withOverflow ? `overflow-y: auto; height: min-content; max-height: ${px(200)};` : '')};
    padding: ${px(8)};
`;

const AuditTrailItem = styled.button<{ isActive?: boolean }>`
    grid-column: 1 / span 2;
    grid-gap: 1rem;
    width: 100%;
    border: ${px(2)} solid ${palette.grey4};
    border-radius: ${px(4)};
    padding: ${px(8)} ${px(16)};
    margin: 0;
    margin-bottom: ${px(4)};
    text-align: left;
    text-decoration: none;
    align-items: center;
    display: grid;
    grid-template-columns: 2fr 1fr;
    cursor: pointer;
    background-color: ${({ isActive }) => (isActive ? `${palette.grey4}` : `transparent`)};
    &:active {
        outline: 0;
    }
    &:focus {
        outline: 0;
    }
    &:hover {
        background: ${palette.grey4};
    }
`;

const Item = styled.div`
    ${cssFontNormal(12, 14, 30)}
    grid-column: 1 / -1;
    :first-child {
        ${cssFontSemiBold(16, 19, 30)}
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const styledComponents = { AuditTrailItem, Content, ColumnTitle, ContentColumn1, ContentColumn2, Item, ItemWrapper, RowWrapper, Text, Wrapper };

export default styledComponents;