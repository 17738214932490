import React from 'react';
import Styled from './index.styled';

export interface ICardTabsHeaderProps {
    readonly id: string;
    readonly title: React.ReactNode;
    readonly active?: boolean;
    readonly onClick: () => void;
}

/**
 * Represents an UI component that displays a horizontal tab-menu-header for a card container.
 */
export const CardHeaderTab = ({ id, active, onClick: toggleTab, title }: ICardTabsHeaderProps) => {
    return (
        <Styled.CardHeaderTab id={id} isActive={active} className='card-header-tab' onClick={toggleTab}>{title}</Styled.CardHeaderTab>
    );
}
