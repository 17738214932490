import React, { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import * as Domain from '@liasincontrol/domain';
import { FieldsHelper, JsonUtils } from '@liasincontrol/core-service';
import { ThumbnailImg } from '@liasincontrol/ui-basics';
import Styled from './index.styled';
import SharedStyled from '../../SharedControlElements/index.styled';

type FileData = { name: string, size: string, blob: Blob };

type Props = {
    element: Domain.Publisher.ElementNode,
    elementList: Record<string, Domain.Publisher.Element>,
    publicationElement: Domain.Publisher.PublicationElement,
    readonly: boolean,
    getElementDefinition: (systemId: string, elementDefinitionId?: string) => Domain.Shared.ElementDefinition,
    onLoadAttachment: (id: string, names?: Record<string, string>) => Promise<Blob>,
    onTemplateChanged?: (operations: Domain.Publisher.Operation[]) => void,
};

/**
 * Represents a UI component that renders a reference attachments control.
 */
const ReferenceAttachmentsControl: React.FC<Props> = (props) => {
    const [control, setControl] = useState<Domain.Publisher.ReferenceAttachmentsControl>();
    const [attachments, setAttachments] = useState<FileData[]>();
    const { element, elementList, getElementDefinition, onLoadAttachment, onTemplateChanged } = props;
    const isDesigner = !!onTemplateChanged;

    useEffect(() => {
        async function fetchAttachments(complexFieldDefinitionId: string, fileSourceDefinitionId: string, fileNameDefinitionId: string, fileSizeDefinitionId: string): Promise<FileData[]> {
            const attachmentFiles = Object.values(controlElement.complexFields)
                .filter((complexField) => Object.keys(complexField.fields).length > 0 && complexField.complexFieldDefinitionId === complexFieldDefinitionId)
                .map(async (complexField) => {
                    const fileSource = complexField.fields[fileSourceDefinitionId];
                    const fileName = complexField.fields[fileNameDefinitionId];
                    const fileSize = complexField.fields[fileSizeDefinitionId];
                    const fileBlob = await onLoadAttachment(fileSource, { [fileSource]: fileName });
                    return ({ name: fileName, size: JsonUtils.formatBytes(+fileSize), blob: fileBlob });
                });

            return Promise.all(attachmentFiles);
        }

        const controlElement = elementList[element.elementId];
        if (controlElement) {
            const definition = getElementDefinition(elementList[element.elementId].elementDefinitionSystemId, elementList[element.elementId].elementDefinitionId);
            const settings = new Domain.Publisher.ReferenceAttachmentsControl();
            FieldsHelper.mapObject<Domain.Publisher.ReferenceAttachmentsControl>(settings, definition.fields, elementList[element.elementId].fields);
            setControl(settings);

            const complexFieldDefinition = definition.complexFields.find((complexField) => complexField.systemId === Domain.SystemFieldDefinitions.Pub.AttachmentsComplex);
            const fileSourceDefinitionId = complexFieldDefinition.fields.find((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.FileSource).id;
            const fileNameDefinitionId = complexFieldDefinition.fields.find((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.FileName).id;
            const fileSizeDefinitionId = complexFieldDefinition.fields.find((field) => field.systemId === Domain.SystemFieldDefinitions.Pub.FileSize).id;
            fetchAttachments(complexFieldDefinition.id, fileSourceDefinitionId, fileNameDefinitionId, fileSizeDefinitionId)
                .then((responses) => {
                    setAttachments(responses);
                });
        }
    }, [element.elementId, elementList, getElementDefinition, onLoadAttachment]);

    return (
        <>
            <SharedStyled.Title h3FontSize={props.publicationElement?.h3FontSize} h3FontColor={props.publicationElement?.h3FontColor}>{control?.title}</SharedStyled.Title>

            <Styled.AttachmentList
                primaryColor={props.publicationElement.primaryColor}
                primaryTextColor={props.publicationElement.primaryContrastColor}
                textColor={props.publicationElement.bodyFontColor}
                textFontSize={props.publicationElement.bodyFontSize}
                editMode={!props.readonly}
            >
                {attachments?.map((attachment, index) => (
                    <Styled.AttachmentItem>
                        <Styled.DownloadLink onClick={() => saveAs(attachment.blob, attachment.name)}>
                            <DownloadIcon sx={{ fontSize: 24 }} />
                            <Styled.AttachmentText nameIsALongWord={isStringALongWord(attachment.name)}>{attachment.name} | {attachment.size}</Styled.AttachmentText>
                        </Styled.DownloadLink>
                    </Styled.AttachmentItem>
                ))}
            </Styled.AttachmentList>

            {attachments?.length < 1 && <Styled.ThumbnailWrapper>
                <ThumbnailImg variant="addFiles" />
                <SharedStyled.Footer> {isDesigner ? 'Schrijvers kunnen bijlagen toevoegen' : 'Geen bijlagen geüpload'} </SharedStyled.Footer>
            </Styled.ThumbnailWrapper>}
        </>
    );
};

const isStringALongWord = (input: string) => {
    if (input.length <= 15) {
        return false;
    }

    const whitespaceChars = [' ', '\t', '\n', '\r'];
    for (let i = 0; i < input.length; i++) {
        if (whitespaceChars.indexOf(input[i]) >= 0) {
            return false;
        }
    }

    return true; 
};

// TODO: Investigate why lodash isEqual doesn't work on the complexFields of an Element.
export default React.memo(ReferenceAttachmentsControl, (prevProps, nextProps) => {
    return _.isEqual(prevProps.elementList[prevProps.element.elementId], nextProps.elementList[nextProps.element.elementId])
        && _.isEqual(Object.values(prevProps.elementList[prevProps.element.elementId].complexFields), Object.values(nextProps.elementList[nextProps.element.elementId].complexFields));
});
