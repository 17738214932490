import React from 'react';
import { PrivateRoute } from '@liasincontrol/ui-basics';
import { Shared } from '../..';
import { Navigate, Route } from 'react-router-dom';

const Contents = (
    <>
        <Route index element={<PrivateRoute component={Shared.PublicationList.index} />} />
        <Route path='*' element={<Navigate to='/notfound' />} />
    </>
);

export { Contents };