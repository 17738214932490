export type BreakpointKey = keyof Omit<{ [key in keyof typeof Breakpoint]: typeof Breakpoint[key] extends Breakpoint ? Breakpoint : never }, 'prototype' | 'all'>;
export class Breakpoint {
    /** phone */
    static xs = new Breakpoint(`XS`, undefined, 768);
    /** tablet */
    static s = new Breakpoint(`S`, 768, 1024);
    /** laptop */
    static m = new Breakpoint(`M`, 1024, 1366);
    /** desktop */
    static l = new Breakpoint(`L`, 1366, 2560);
    /** widescreen */
    static xl = new Breakpoint(`XL`, 2560, undefined);
    static all = [Breakpoint.xs, Breakpoint.s, Breakpoint.m, Breakpoint.l, Breakpoint.xl];

    constructor(public readonly name: string, public readonly minValue?: number, public readonly maxValue?: number) {}

    is(value: number) {
        if (this.minValue && this.maxValue) {
            return value > this.minValue && value <= this.maxValue;
        }
        if (this.minValue) {
            return value > this.minValue;
        }
        if (this.maxValue) {
            return value <= this.maxValue;
        }
        return null;
    }

    toMediaQuery() {
        return toMediaQuery({ minValue: this.minValue, maxValue: this.maxValue });
    }

    toString() {
        return `Media Query: ${this.name} (${this.toMediaQuery()})`;
    }
}

export const toMediaQuery = ({ minValue, maxValue }: { minValue?: number; maxValue?: number }) => {
    if (minValue && maxValue) {
        return `screen and (min-width: ${minValue + 1}px) and (max-width: ${maxValue}px)`;
    }
    if (minValue) {
        return `screen and (min-width: ${minValue + 1}px)`;
    }
    if (maxValue) {
        return `screen and (max-width: ${maxValue}px)`;
    }
    return null;
};
