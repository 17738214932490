import React, { useState } from 'react';
import * as Styled from './index.styled';
import { SortableContainer, SortableElement, SortableElementProps, SortableHandle } from 'react-sortable-hoc';
import { Button, InputToggle, Label } from '@liasincontrol/ui-basics';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

type ItemType = { fieldId: string, label: string, visible: boolean, index?: number, showFieldName: boolean };

type Props = {
    value: ItemType[],
    disabled?: boolean,
    key?: string,
    id?: string,
    label?: string,
    useDragHandle?: true,
    readonly onValuesChanged?: (string) => void
}

/**
 * Represents an UI component that renders a performance field list editor.
 */
const FieldListEditor: React.FC<Props> = (props) => {
    const onValueChanged = (changedItem: ItemType): void => {
        if (!changedItem) {
            return;
        }

        const values = props.value.map(item => {
            if (item.fieldId === changedItem.fieldId) return changedItem;
            else return item;
        });
        props.onValuesChanged(JSON.stringify(values));
    };

    const onSortEnd = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
        if (!props.disabled) {
            const items = props.value;
            items.splice(newIndex, 0, items.splice(oldIndex, 1)[0]);
            const values = fixColumnOrder(items);
            props.onValuesChanged(JSON.stringify(values));
        }
    };

    return (
        <>
            <Label id={`${props.id}-label`} text={props.label} />
            <Styled.SideMenuList>
                <SortableList onSortEnd={onSortEnd} useDragHandle>
                    {props.value?.map((value, index) => (
                        <SortableItem
                            key={`item-${value.fieldId}`}
                            index={index}
                            value={value}
                            props={{ index: value.index, disabled: props.disabled }}
                            onValueChanged={onValueChanged}
                        />
                    ))}
                </SortableList>
            </Styled.SideMenuList>
        </>
    );
};

const SortableList = SortableContainer(({ children }: { children: React.ReactNode }) => <Styled.SideMenuList>{children}</Styled.SideMenuList>);
const SortableItem = SortableElement(({ value, onValueChanged, props }: { value: ItemType, onValueChanged: (changedValue: ItemType) => void, props: SortableElementProps }) => {
    return (
        <FieldElement
            value={value}
            index={props.index}
            disabled={props.disabled}
            onValueChanged={(changedItem: ItemType): void => onValueChanged(changedItem)}
        />
    );
});

type ItemProps = {
    readonly value: ItemType,
    readonly disabled?: boolean,
    readonly index: number,
    readonly onValueChanged: (changedValue: ItemType) => void,
}

/**
 * Represents a UI component that renders a field from a performance field list.
 */
export const FieldElement: React.FC<ItemProps> = (props) => {
    const [expanded, setExpanded] = useState<boolean>(false);

    const onVisibilityClicked = (): void => {
        const value = props.value;
        value.visible = !value.visible;
        props.onValueChanged(value);
    };

    const onShowFieldNameChanged = (value: boolean): void => {
        if (props.disabled) {
            return;
        }
        const columnValue = props.value;
        columnValue.showFieldName = value;
        props.onValueChanged(columnValue);
    }

    const onAdvancedSettingsClicked = (): void => {
        if (!props.disabled) {
            setExpanded(!expanded);
        }
    }

    const DragHandle = SortableHandle(() => <Styled.StyledIconDrag disabled={props.disabled} />);
    const visibiltyIcon = !!props.value.visible ? <VisibilityIcon /> : <VisibilityOffIcon />;
    const visibleAction = <Button
        disabled={props.disabled}
        btnbase="iconbuttons"
        btntype="medium_background"
        aria-label="Zichtbaarheid"
        icon={visibiltyIcon}
        onClick={onVisibilityClicked} />;

    const advancedSettingsIcon = expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />;
    const advancedSettingsAction = <Button
        disabled={props.disabled}
        btnbase='iconbuttons'
        btntype='medium_background'
        aria-label='Geavanceerde instellingen'
        icon={advancedSettingsIcon}
        onClick={onAdvancedSettingsClicked} />;

    const ExtraLineElement = (): JSX.Element | null => {
        if (!expanded) {
            return null;
        }

        return (
            <Styled.ExtraLineWrap>
                <Styled.PanelSection>
                    <Label id={`${props.value.fieldId}-showFieldName-label`} htmlFor={`${props.value.fieldId}-showFieldName`} text='Toon veldnaam' />
                    <InputToggle
                        id={`${props.value.fieldId}-showFieldName`}
                        textOff='Uit'
                        textOn='Aan'
                        selected={props.value.showFieldName}
                        disabled={props.disabled}
                        onChange={onShowFieldNameChanged}
                    />
                </Styled.PanelSection>
            </Styled.ExtraLineWrap>
        );
    };

    return (
        <Styled.Li disabled={props.disabled} id={`btn-page-${props.value.fieldId}`} role="button">
            <Styled.MainLineWrap>
                <Styled.DragWrap id={`btn-drag-${props.value.fieldId}`} aria-label={`${props.value.label} verslepen`} title={`${props.value.label} verslepen`}>
                    <DragHandle />
                </Styled.DragWrap>
                <Styled.LabelWrap>{props.value.label}</Styled.LabelWrap>
                <Styled.ActionsWrap>
                    {visibleAction}
                    {advancedSettingsAction}
                </Styled.ActionsWrap>
            </Styled.MainLineWrap>
            <ExtraLineElement />
        </Styled.Li>
    );
};

const fixColumnOrder = (items: ItemType[]): ItemType[] => {
    let sortColumns = 0;
    items.forEach((item) => {
        item.index = sortColumns++;
    });
    return items;
}
export { FieldListEditor };
