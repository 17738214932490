import { useSelector, useDispatch } from "react-redux";
import * as Domain from '@liasincontrol/domain';
import { State, AjaxRequestStatus, FinanceSettingsActionCreator } from '@liasincontrol/redux-service';

type SettingsState = {
    item: Domain.Finance.Settings,
    status: AjaxRequestStatus,
};

export const useFinanceSettings = () => {
    const settings = useSelector<State, SettingsState>((state) => state.finance.settings);
    const dispatch = useDispatch();
    if (settings.status === AjaxRequestStatus.NotSet) {
        dispatch(FinanceSettingsActionCreator.set());
    }

    return settings;
}
