import React, { forwardRef } from 'react';
import { IconSize } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import PivotGrid, { FieldChooser, Scrolling } from 'devextreme-react/pivot-grid';
import PivotGridDataSource, { Field } from 'devextreme/ui/pivot_grid/data_source';
import { CellClickEvent, ContentReadyEvent } from "devextreme/ui/pivot_grid";

export interface ILsPivotTableProps {
    /**
    * Defines the Data source
    */
    readonly dataSource: PivotGridDataSource;
    /**
    * Filter array.
    */
    readonly filterValue?: string | Array<any> | Function;
    /**
     * Column chooser visible
     */
    readonly enableColumnChooser?: boolean;
    /**
     * Enables client side sorting & paging.
     */
    readonly clientSide?: boolean;
    /**
     * The title setting of datatable.
     */
    readonly title?: string;
    /**
     * The description setting of datatable.
     */
    readonly description?: string;

    /* set panel visibility*/
    readonly panelsVisible?: boolean,
    /**
     * The icons dictionary
     */
    readonly icons?: Record<string, Domain.Shared.SvgIcon>,

    readonly className?: string,

    readonly iconSize?: IconSize,

    /* set totals visibility*/
    readonly showColumnGrandTotals?: boolean,

    readonly showColumnTotals?: boolean,

    readonly showRowGrandTotals?: boolean,

    readonly showRowTotals?: boolean,

    readonly grandTotalCaption?: string,

    readonly onContentReady?: (e: ContentReadyEvent) => void,

    readonly onCellClick?: (e: CellClickEvent) => void;

    /**
    * The publication element needed for title styles.
    */
    readonly publicationElement?: Domain.Publisher.PublicationElement,

    scrolling?: boolean,
}

export type PivotGridFieldType = Field;

export const LsPivotTable = forwardRef<PivotGrid, ILsPivotTableProps>((props, ref) => {
    return (<PivotGrid
        ref={ref}
        id="pivot-table"
        dataSource={props.dataSource}
        allowSortingBySummary={true}
        allowSorting={false}
        allowFiltering={false}
        allowExpandAll={true}
        showBorders={true}
        wordWrapEnabled={true}
        width='100%'
        rowHeaderLayout="standard"
        showColumnGrandTotals={props.showColumnGrandTotals}
        showColumnTotals={props.showColumnTotals}
        showRowGrandTotals={props.showRowGrandTotals}
        showRowTotals={props.showRowTotals}
        encodeHtml={false}
        texts={{
            grandTotal: props.grandTotalCaption
        }}
        onContentReady={props.onContentReady}
        onCellClick={props.onCellClick}
    >
        {props.scrolling && 
            <Scrolling mode='standard' />
        }
        <FieldChooser enabled={false} />
    </PivotGrid>
    );
});
