import React, { useState, useEffect } from 'react';
import AuthService, { UserIdentity, License } from '@liasincontrol/auth-service';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { useLocation, useNavigate } from 'react-router-dom';

interface PrivateRouteProps {
    component: React.ComponentType<{ userIdentity: UserIdentity }>,
    permissions?: Actions | Actions[] | { action: Actions, actionType: ActionType },
    license?: License,
    fallback?: { url: string, license: License },
}

enum LoginState { Checking, LoggedIn, Expired, NotLoggedIn }

// https://bobbyhadz.com/blog/react-pass-component-as-prop
/**
 * Represents a private route.
 */
export const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, permissions, license, fallback }) => {
    //TODO: figure out why it complains
    const navigate = useNavigate();
    const location = useLocation();
    const [login, setLogin] = useState<{
        state: LoginState,
        identity?: UserIdentity
    }>({ state: LoginState.Checking, identity: undefined });

    useEffect(() => {
        AuthService.getInstance()
            .then(async (auth) => {
                const userIndentity = await auth.getUser();
                if (userIndentity) {
                    let hasPermission = true;
                    if (userIndentity?.profile?.error?.data?.type === 'http://omnia-error/internal-server-error-d3276e9c') {
                        navigate('/error/invalid-config');
                    } else if (!userIndentity.profile.isInControlUser) {
                        navigate('/error/omniauser');
                    }
                    else if (license && !userIndentity.profile.licenses?.includes(license)) {
                        navigate('/error/license'); //separate page to show the user doesn't have the required license?
                    }
                    else if (!!permissions) {
                        if (Object.values(Actions).includes(permissions as Actions)) {
                            // single permission, no action type check
                            hasPermission = UserRightsService.getInstance().canPerformAction(userIndentity, permissions as Actions);
                        }
                        else if (Array.isArray(permissions) && permissions.length > 0) {
                            // multiple permissions, no action type check
                            hasPermission = UserRightsService.getInstance().canPerformAnyAction(userIndentity, permissions);
                        }
                        else if ((permissions as any).action) {
                            // single permission with explicit action type check
                            hasPermission = UserRightsService.getInstance().canPerformAction(userIndentity, (permissions as any).action, (permissions as any).actionType);
                        }
                    }
                    if (!hasPermission) {
                        navigate('/error/permission');
                    }

                    if (fallback && userIndentity?.profile?.licenses.length === 1 && UserRightsService.getInstance().userHasLicence(userIndentity, fallback.license)) {
                        navigate(fallback.url);
                    }

                    setLogin({
                        state: !userIndentity.expired ? LoginState.LoggedIn : LoginState.Expired,
                        identity: !userIndentity.expired ? userIndentity : undefined
                    });
                } else {
                    setLogin({
                        identity: undefined,
                        state: LoginState.NotLoggedIn
                    });
                }
            })
            .catch((err) => {
                console.log(`Couldn't determine on route if user is logged in: ${err}`);
                // assume an error means we are not logged in => redirect to login.
                setLogin({
                    identity: undefined,
                    state: LoginState.NotLoggedIn
                });
            });
    }, [navigate, permissions]);

    useEffect(() => {
        if (login.state === LoginState.NotLoggedIn || login.state === LoginState.Expired) {
            AuthService.getInstance()
                .then((authInstance) => {
                    const redirectPath = location.pathname; // rest.location?.pathname;

                    const loginPromise: Promise<UserIdentity> =
                        login.state === LoginState.Expired
                            ? authInstance.attemptSilentLogin(redirectPath as string)
                            : authInstance.attemptSilentLogin(redirectPath as string);

                    loginPromise.then((userIdentity) => {
                        setLogin({
                            identity: userIdentity,
                            state: LoginState.LoggedIn
                        });
                    });
                });
        }
    }, [login.state, location]);

    if (login.state === LoginState.LoggedIn) {
        return <Component userIdentity={login.identity!} />
    }

    return null;
}