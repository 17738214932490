import styled from 'styled-components';
import { cssFontNormal, cssFontSemiBold, palette, px } from '@liasincontrol/ui-basics';

const Wrapper = styled.div`
    padding: 0;
    margin: 0;
    background-color: #fff;
    border-radius: ${px(5)};
    overflow: auto;
`;

const AuditTrailItem = styled.button<{ isActive?: boolean }>`
    grid-column: 1 / span 2;
    grid-gap: 1rem;
    width: 100%;
    background-color: transparent;
    border: none;
    border-top: 2px solid ${palette.grey4};
    padding: ${px(8)} ${px(16)};
    margin: 0;
    text-align: left;
    text-decoration: none;
    align-items: center;
    display: grid;
    grid-template-columns: 2fr 1fr;
    cursor: pointer;
    &:active {
        outline: 0;
    }
    &:focus {
        outline: 0;
    }
    &:hover {
        background: ${palette.grey4};
    }
`;

const Item = styled.div`
    ${cssFontNormal(12, 14, 30)}
    :first-child {
        ${cssFontSemiBold(16, 19, 30)}
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;

const Container = styled.div`
    display: grid;
    overflow: hidden;
    max-height: 60vh;
`;

const ContentHeader = styled.div`
    grid-column: 1;
    grid-row: 2 / span 1;
    min-height: ${px(32)};
    display: flex;
    align-items: flex-end;
`;

const ContentHeaderBar = styled.div`
    grid-column: 1;
    grid-row: 1 / span 2;
    border-bottom: ${px(2)} solid ${palette.grey3b};
`;

const Tab = styled.div`
    grid-row: 3 ;
    padding-top: ${px(25)};
`;
export { Wrapper, AuditTrailItem, Item, Container, ContentHeader, ContentHeaderBar, Tab };
