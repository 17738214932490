import React, { useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { MultiLineTextElement } from '@liasincontrol/ui-elements';
import { DateUtils } from '@liasincontrol/core-service';
import { Button, Bar, palette } from '@liasincontrol/ui-basics';
import { TextValidator, ValidationErrorData } from '@liasincontrol/core-service';
import { CommentsContextMenu } from './CommentsContextMenu';
import * as Styled from './CommentList.styled';

type HeaderItemProps = {
    comment: Domain.Publisher.Comment;
    isEditable: boolean;
    userHasTask: boolean;
    onEdit: (commentId) => void;
    onDelete?: (commentId) => void;
}

type DetailItemProps = {
    comment: Domain.Publisher.Comment;
    isEdited: boolean;
    onSave: (commentId: string, commentText: string) => void;
    onCancel: () => void;
}

/**
 * Represents the UI components that renders a comment title and detail.
 */
const CommentHeader: React.FC<HeaderItemProps> = (props) => {
    return (<>
        <Styled.Title>
            {props.userHasTask && <TaskAltIcon htmlColor={palette.green} />}
            {props.comment.authorName || 'N/A'}
            <span>{DateUtils.formatDateTime(props.comment.createdOn, 'DD-MM-YYYY | HH:mm')}</span>
        </Styled.Title>
        {
            !props.isEditable && <Styled.Actions>
                <CommentsContextMenu comment={props.comment} onEdit={props.onEdit} onDelete={props.onDelete} />
            </Styled.Actions>
        }
    </>
    );
}

const CommentItem: React.FC<DetailItemProps> = (props) => {
    const [text, setText] = useState(props.comment.content);
    const validator = new TextValidator({ required: false, stringMaxLength: 4000, stringType: Domain.Shared.StringType.MultiLine });
    const [error, setError] = useState<ValidationErrorData[]>([]);

    return (
        <Styled.Detail>
            <MultiLineTextElement
                id={`comment-description-field-${props.comment.id}`}
                label=''
                key={`comment-description-field-${props.comment.id}`}
                editorSettings={{
                    disabled: !props.isEdited,
                    restrictions: validator.getRestrictions(),
                    validationErrors: error,
                    readMore: {
                        active: true,
                        length: 450,
                    },
                    onChange: (value: string) => {
                        setText(value);
                        setError(validator.validate(value));
                    },
                }}
                value={text} />
            {props.isEdited && <Bar look='toolbar'>
                <Bar start>
                    <Button id={`btn-cancel-edit-comment`} btnbase="textbuttons" btntype="small_icon" onClick={() => { setText(props.comment.content); props.onCancel(); }}>
                        Annuleren
                    </Button>
                </Bar>
                <Bar end>
                    <Button id={`btn-update-comment`} disabled={text.length <= 0 || error.length > 0} btnbase="primarybuttons" btntype="small_icon" onClick={() => { props.onSave(props.comment.id, text); }}>
                        Opslaan
                    </Button>
                </Bar>
            </Bar>
            }
        </Styled.Detail>
    );
}

export { CommentHeader, CommentItem };