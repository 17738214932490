import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import _, { Dictionary } from 'lodash';
import RefreshIcon from '@mui/icons-material/Refresh';
import * as Domain from '@liasincontrol/domain';
import { SystemElementDefinitions, SystemModuleDefinitions } from '@liasincontrol/domain';
import { Publisher as DataAccess, Shared as SharedDataAccess, Performance as PerformanceDataAccess, Studio as StudioDataAccess } from '@liasincontrol/data-service';
import { FieldsHelper, ApiErrorReportingHelper, OperationsHelper, AttachmentsHelper, DefinitionsHelper } from '@liasincontrol/core-service';
import { License, UserIdentity } from '@liasincontrol/auth-service';
import { ActionType, Actions, UserRightsService, Features } from '@liasincontrol/userrights-service';
import { ActionSource, AjaxRequestStatus, State, ModulesActionCreator, ElementDefinitionsActionCreator, PatchesActionCreator, AttachmentsActionCreator, MeasureMomentsActionCreator, UsersActionCreator, HierarchyDefinitionsActionCreator, SvgIconActionCreator } from '@liasincontrol/redux-service';
import { Bar, Button, ErrorOverlay, ModalDialog, ModalDialogFooter, Text, LeaseWrapper, IDataItemProps, DeviceType } from '@liasincontrol/ui-basics';
import { IndicatorSize, LoadIndicator } from '@liasincontrol/ui-devextreme';
import { ContentEditor, ContentEditorState } from './ContentEditor';
import { Tasks } from './Tasks';
import Renderer from '../../../../../components/Renderer';
import Helper from '../../../../_shared/PublicationItem/PublicationInformation/index.helper';
import { BlankPublication } from '../../../../Administrator/Publications/PublicationItem/PublicationSitemap/BlankPublication';
import { usePublicationSettings, usePublicationWorkflowStates } from '../../../../../helpers/PublicationContext';
import { HierarchyUtils } from '../../../../../helpers';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the preview of a publication.
 */
const PublicationContent: React.FC<Props> = (props: Props) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { id, pageid: pageId, controlid: controlId } = useParams();

    const [sitemap, setSitemap] = useState<Domain.Publisher.Sitemap>();
    const [showNoLeaseDialog, setShowNoLeaseDialog] = useState<boolean>(false);
    const [lastRefresh, setLastRefresh] = useState<number>(Date.now());
    const [auditRefresh, setAuditRefresh] = useState<number>(Date.now());
    const [selectedElementId, setSelectedElementId] = useState<string>(controlId);
    const [editorState, setEditorState] = useState<ContentEditorState>(controlId ? ContentEditorState.Editing : ContentEditorState.None);
    const publication = usePublicationSettings();
    const [publicationElement, setPublicationElement] = useState<Domain.Publisher.PublicationElement>();
    const [publicationContent, setPublicationContent] = useState<{
        root: Domain.Publisher.ElementNode,
        elements: Dictionary<Domain.Publisher.Element>,
        pageId: string,
        pageDesignId: string,
        templateOperations: Domain.Publisher.Operation[]
    }>();
    const [validElement, setValidElement] = useState<{ message: string, isValid: boolean }>({ message: '', isValid: true });
    const [invalidElements, setInvalidElements] = useState<Record<string, { message: string, isValid: boolean, keep?: boolean }>>({});
    const [pageHasNoTemplate, setPageHasNoTemplate] = useState<boolean>(false);
    const [editableElementIds, setEditableElementIds] = useState<Record<string, { editable: boolean, isLockedByOther: boolean, lockedByUser?: any }>>({});
    const [allUsers, setAllUsers] = useState<Domain.Shared.User[]>([]);
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [editorError, setEditorError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [taskStatusChangeInProgress, setTaskStatusChangeInProgress] = useState<boolean>(false);
    const [controlTaskLastRefresh, setControlTaskLastRefresh] = useState<number>(Date.now());
    const [auditEvents, setAuditEvents] = useState<{ content: Domain.Shared.AuditEvent[] }>({ content: [] });
    const [availablePublishProfiles, setAvailablePublishProfiles] = useState<IDataItemProps<string>[]>();
    const [pageControlPublishProfiles, setPageControlPublishProfiles] = useState<Record<string, IDataItemProps<string>[]>>();
    const [selectedPublishProfiles, setSelectedPublishProfiles] = useState<IDataItemProps<string>[]>();
    const [hierarchies, setHierarchies] = useState<Domain.Shared.HierarchyMap>(
        {
            [ActionSource.Performance]: {},
            [ActionSource.Studio]: {},
        });

    //TODO: introduced by PBI: 12721. Need changes when publication will use the new workflow implementation.
    const publicationWorkflowStates = usePublicationWorkflowStates();
    const [componentWorkflowTasks, setComponentWorkflowTasks] = useState<Domain.Publisher.WorkflowTask[]>([]);
    const [currentUserActiveTasks, setCurrentUserTasks] = useState<Domain.Publisher.WorkflowTask[]>([]);
    const [statusAuditEvents, setStatusAuditEvents] = useState<Domain.Shared.WorkflowAuditEvent[]>([]);
    const [device, setDevice] = useState<DeviceType>('desktop');
    const [pageComponentInfo, setPageComponentInfo] = useState<Domain.Publisher.PageComponentInfo[]>([]);
    const [leaseInfo, setLeaseInfo] = useState<Domain.Shared.AcquireLease>();
    const [leaseModalVisible, setLeaseModalVisible] = useState<{ visible: boolean, user?: string }>({ visible: false });
    const [loading, setLoading] = useState(false);
    const [variables, setVariables] = useState<Domain.Shared.ComplexFieldItem[]>([]);

    /**
     * Represents the loading process of the tasks for the selected control by publication workflow states.
     * 
     * @param controlId Defines the selected control's id.
     */
    const getControlTasks = (controlId: string) => {
        if (controlId) {
            const params: DataAccess.WorkflowTaskQueryParam = {
                includePageDetails: false,
                includeComponentDetails: true,
                includeStateDetails: true,
                includeUserDetails: true,
                onlyActive: false,
                onlyNotCompleted: false,
                onlyCurrentUser: false,
            };
            DataAccess.WorkflowTask.getComponentWorkflowTask(id, pageId, controlId, params)
                .then((response) => {
                    setComponentWorkflowTasks(response.data || []);
                }).catch((err) => {
                    setComponentWorkflowTasks([]);
                    setEditorError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
                });
        }
    };

    /**
     * Represents the loading process of the status changes for the selected control by publication workflow states.
     * 
     * @param controlId Defines the selected control's id.
     */
    const getWorkflowTrail = (controlId: string) => {
        if (controlId) {
            DataAccess.Publications.getWorkflowItemAuditTrail(id, pageId, controlId)
                .then((response) => {
                    setStatusAuditEvents(response.data || []);
                }).catch((err) => {
                    setStatusAuditEvents([]);
                    setEditorError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
                });
        }
    }

    /**
     * Gets all the tasks for the user on publication level.
     * @param publicationId Defines the unique identifier of the publication.
     */
    const getUserPublicationTasks = (publicationId: string) => {
        if (!publicationId) {
            return;
        }
        DataAccess.WorkflowTask.getCurrentUserActiveTasks(publicationId).then((response) => {
            setCurrentUserTasks(response.data);
        }).catch((err) => {
            setCurrentUserTasks([]);
            setEditorError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
        });
    };

    /**
     * Gets editable element list handler.
     * 
     * @param publicationId Defines the unique identifier of the publication.
     * @param pageId Defines the selected page's id.
     */
    const getEditableElementList = (publicationId: string, pageId: string) => {
        if (publicationId && pageId) {
            DataAccess.Publications.getPageComponentInfo(publicationId, pageId)
                .then((response) => {
                    setPageComponentInfo(response.data || []);
                }).catch((err) => {
                    //Don't need error overlay, ifcomponentIfo fails, No editors for publication!
                    setPageComponentInfo([]);
                });
        }
    };

    /**
     * Get component's lease handler.
     * 
     * @param elementId The element id.
     */
    const getLease = (elementId: string) => {
        const componentInfoOnElement = pageComponentInfo?.find((pageComponentInfo) => pageComponentInfo.componentId === elementId);
        if (componentInfoOnElement) {
            SharedDataAccess.Leases.acquireLease(componentInfoOnElement.pageComponentId)
                .then((response) => {
                    const lease: Domain.Shared.AcquireLease = response.data;
                    if (lease.result === "Denied") {
                        setLeaseModalVisible({ visible: true, user: allUsers?.find(user => user.id === lease.ownerId)?.name });
                    }
                    setLeaseInfo(lease);
                }).catch((err) => {
                    setEditorError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
                });
        }
    };

    const deleteLease = useCallback(() => {
        //delete lease if exist a lease, and it's owned by logged in user
        if (leaseInfo && leaseInfo?.ownerId === props.userIdentity.profile.sub) {
            SharedDataAccess.Leases.deleteLease(leaseInfo.subjectId)
                .finally(() => {
                    setLeaseInfo(undefined);
                    setSelectedElementId(undefined);
                });
        }
    }, [leaseInfo]);

    useEffect(() => {
        if (!publication || !publication.publication || publication.publication.elementId !== id)
            return;
        if (!pageId) {
            navigate(`${pathname}${publication.rootPageId}`);
        }
    }, [publication, pageId, pathname, navigate]);

    useEffect(() => {
        if (!publication || !publication.publication || !props.elementdefinitions || _.isEmpty(props.elementdefinitions[ActionSource.Publication]) || _.isEmpty(props.elementdefinitions[ActionSource.Publication].items)) {
            return;
        }

        DataAccess.Publications.getPageSitemap(publication.publication.elementId, publication.rootPageId, 4)
            .then((response) => setSitemap(response.data));

        const publicationElementDefinition = Object.values(props.elementdefinitions[ActionSource.Publication].items).find((definition) => definition.systemId === SystemElementDefinitions.Pub.Publication);
        const settings = new Domain.Publisher.PublicationElement();
        FieldsHelper.mapObject<Domain.Publisher.PublicationElement>(settings, publicationElementDefinition.fields, publication.publication.fields);
        setPublicationElement(settings);

    }, [publication, props.elementdefinitions[ActionSource.Publication]]);

    useEffect(() => {
        if (!controlId) return;
        setSelectedElementId(controlId);
    }, [controlId]);

    useEffect(() => {
        if (!selectedElementId || !pageControlPublishProfiles) return;

        setSelectedPublishProfiles(pageControlPublishProfiles[selectedElementId]);
        getControlTasks(selectedElementId);
        getWorkflowTrail(selectedElementId);
    }, [selectedElementId, pageControlPublishProfiles, controlTaskLastRefresh]);

    useEffect(() => {
        if (!publication || !publication.publication || !pageId) {
            return;
        }

        setPublicationContent(undefined);

        DataAccess.Publications.getPageOperations(id, pageId)
            .then((response) => {
                const root = OperationsHelper.getElementStructure(response.data.layers);
                const elements = OperationsHelper.getElementList(_.cloneDeep(response.data.layers));
                const pageDesignId = response.data.layers.find((layer) => layer.kind === Domain.Publisher.LayerKind.PageDesign).originId;
                const templateOperations = response.data.layers.find((layer) => layer.kind === Domain.Publisher.LayerKind.PageTemplate)?.operations;
                setPublicationContent({
                    root,
                    elements,
                    pageId,
                    pageDesignId,
                    templateOperations
                });
                setPageHasNoTemplate(false);
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, err, true);

                if (errorInfo?.details?.type && errorInfo?.details?.type.includes(Domain.Shared.ApiKnownErrorTypes.PageNotLinkedToTemplate)) {
                    setPageHasNoTemplate(true);
                } else {
                    setError(errorInfo);
                }
            });
    }, [publication, pageId, lastRefresh, id]);


    useEffect(() => {
        if (!pageId || !id || !props.elementdefinitions[ActionSource.Publication]?.items) return; 
        DataAccess.SiteMap.getPageElement(id, pageId).then(pageElementResponse => {
            DataAccess.Publications.getTemplateDetails(id, pageElementResponse.data.pageTemplateId).then((templateElementResponse) => {
                const pageVariables = DefinitionsHelper.getVariablesDataForElement(
                    props.elementdefinitions[ActionSource.Publication].items, 
                    Domain.SystemElementDefinitions.Pub.Page, 
                    pageElementResponse.data.page.complexFields
                );

                const templateVariables = DefinitionsHelper.getVariablesDataForElement(
                    props.elementdefinitions[ActionSource.Publication].items, 
                    Domain.SystemElementDefinitions.Pub.PageTemplate, 
                    templateElementResponse.data.complexFields
                );
                const _variables = _.unionBy(pageVariables.variables, templateVariables.variables, 'name');
                setVariables(_variables);
            });
        })
    }, [props.elementdefinitions[ActionSource.Publication].items, id, pageId]);

    useEffect(() => {
        if (!id) {
            return;
        }

        getUserPublicationTasks(id);

    }, [id, controlTaskLastRefresh]);

    // editable element list for user based on task and pageComponentInfo
    useEffect(() => {
        if (!publicationContent?.elements) return;

        if (!pageId) return;

        if (_.isEmpty(currentUserActiveTasks) && _.isEmpty(pageComponentInfo)) {
            setEditableElementIds({});
            return;
        }

        if (publicationElement?.isClosed) {
            setEditableElementIds({});
            return;
        }

        // PageWorkflowTasks contains only active open tasks for the logged in user.
        const editableElements = Object.values(publicationContent.elements).reduce((collection, element) => {
            // A user can have only one open and active task on an element on a specific page.

            //Check if it's enough to test the page component info ;)
            const userTasksOnElement = currentUserActiveTasks?.find((task) => task.pageId === pageId && task.componentId === element.elementId && task.taskIsActive === true);
            //If user is Redactor on the publication (currentUserIsEditor === true), has allways right to edit the components.
            const componentInfoOnElement = pageComponentInfo?.find((pageCompInfo) => pageCompInfo.componentId === element.elementId);

            //Lock exists and its not equal with LoggedIn user
            const lockedByOther = componentInfoOnElement?.leaseOwnerId ? componentInfoOnElement?.leaseOwnerId !== props.userIdentity.profile.sub : false;

            const editableElementInfo = {
                editable: !lockedByOther && (!!userTasksOnElement || !!componentInfoOnElement?.currentUserIsEditor),
                isLockedByOther: lockedByOther,
                lockedByUser: componentInfoOnElement?.leaseOwnerId ? props.users?.items?.find(u => u.id === componentInfoOnElement?.leaseOwnerId)?.name : '-',
            };

            return { ...collection, [element.elementId]: editableElementInfo };
        }, {});

        setEditableElementIds(editableElements);
    }, [currentUserActiveTasks, publicationElement, publicationContent?.elements, controlTaskLastRefresh, pageId, pageComponentInfo, leaseInfo])

    //Ask for lease if user has edit right on the selected element .
    useEffect(() => {
        if (!selectedElementId) return;

        //Check if it's enough to test the page component info ;)
        const userTasksOnElement = currentUserActiveTasks?.find((task) => task.pageId === pageId && task.componentId === selectedElementId && task.taskIsActive === true);
        //If user is Redactor on the publication (currentUserIsEditor === true), has allways right to edit the components.
        const componentInfoOnElement = pageComponentInfo?.find((pageCompInfo) => pageCompInfo.componentId === selectedElementId);

        if (!!userTasksOnElement || !!componentInfoOnElement?.currentUserIsEditor) {
            getLease(selectedElementId);
        }
    }, [selectedElementId, currentUserActiveTasks, pageComponentInfo]);

    useEffect(() => {
        if (props.users?.status !== AjaxRequestStatus.Done) return;
        setAllUsers(props.users.items);
    }, [props.users]);

    useEffect(() => {
        if (!id || !pageId || !publication || !publication.publication || !publicationElement) {
            return;
        }

        DataAccess.Publications.getPageAuditData(id, pageId, !!publicationElement.workflowId)
            .then((response) => {
                const convertToDomainAuditEvent = (dto: Domain.Dto.Shared.AuditEvent) => {
                    return {
                        elementId: dto.elementId,
                        commandLog: dto.commandLog,
                        fieldId: dto.fieldId,
                        fieldValue: dto.fieldValue,
                        eventType: dto.eventTypeId as Domain.Shared.EventType,
                        timestamp: dto.timestamp,
                        user: allUsers?.find(user => user.id === dto.userId)
                    } as Domain.Shared.AuditEvent;
                };

                const newAuditEvents = {
                    content: response?.data?.contents?.map(convertToDomainAuditEvent),
                };

                setAuditEvents(newAuditEvents);
            })
            .catch(() => {
                setAuditEvents(undefined);
            });
    }, [id, pageId, publication, publicationElement, allUsers, lastRefresh, auditRefresh]);

    useEffect(() => {
        if (!id || !pageId || !selectedElementId) return;

        //patched control
        DataAccess.Publications.getControl(id, pageId, selectedElementId)
            .then((response) => {
                //set attachments to redux
                if (response.data.attachments) {
                    //load and set attachments
                    const getAttachment = async (attachment) => {
                        const newBlob = await loadAttachment(attachment.id);
                        const fileName = attachment.name;
                        const file = new File([newBlob], fileName, { type: newBlob.type });
                        props.setAttachment(attachment.id, file);
                    };

                    response.data.attachments.map(getAttachment);
                }

                setPublicationContent((prev) => {
                    const clonedElementList = _.cloneDeep(prev.elements);
                    clonedElementList[selectedElementId] = response.data;

                    return {
                        root: prev.root,
                        elements: clonedElementList,
                        pageId: prev.pageId,
                        pageDesignId: prev.pageDesignId,
                        templateOperations: prev.templateOperations
                    }
                });
            });
    }, [id, pageId, selectedElementId]);

    // Original patches, comes from content
    // Before rendering, apply the patches over the publication content:
    const patchedElements = useMemo(() => {
        return publicationContent
            ? Helper.getPatchedElements(publicationContent.elements, props.patches[publicationContent.pageId])
            : {};
    }, [publicationContent?.elements, props.patches]);

    useEffect(() => {
        DataAccess.SiteMap.getAvailablePublishProfiles().then((response) => {
            setAvailablePublishProfiles(response.data.map((item) => ({ value: item.profileId, label: item.profileName })));
        });
    }, []);

    useEffect(() => {
        if (!pageId || editorState === ContentEditorState.None) {
            return;
        }

        DataAccess.SiteMap.getSelectedPageControlPublishProfiles(pageId).then((response) => {
            const pageControlProfiles = {};
            response.data.forEach((item) => {
                if (!pageControlProfiles[item.controlId]) {
                    pageControlProfiles[item.controlId] = [];
                }
                pageControlProfiles[item.controlId].push({ value: item.profileId, label: item.profileName });
            });
            setPageControlPublishProfiles(pageControlProfiles);
        });

        getEditableElementList(id, pageId);
    }, [pageId, editorState]);

    //#region event handlers...

    /**
    * Represents an event handler that triggers the saving process of the publication changes.
    */
    const saveElementChanges = (navigateToPage = true) => {
        const pagePatches = props.patches[publicationContent.pageId];
        const controlPatches = pagePatches ? pagePatches[selectedElementId] : null;
        const publishProfilesChanged = getPublishProfilesChanged();

        if (navigateToPage) {
            handlePageNavigation();
        }

        if (!controlPatches && !publishProfilesChanged) {
            deleteLease();
            return Promise.resolve();
        }

        const savePromises: Promise<void>[] = [];

        if (editorError) setEditorError(undefined);

        if (publishProfilesChanged) {
            savePromises.push(
                DataAccess.SiteMap.setControlToPublish(selectedPublishProfiles.map(pp => pp.value.toString()), id, pageId, selectedElementId).then(() => {
                    pageControlPublishProfiles[selectedElementId] = selectedPublishProfiles;
                    setPageControlPublishProfiles(pageControlPublishProfiles);
                })
            );
        }

        if (controlPatches) {
            const attachments = Object.entries(props.attachments).map(([attachmentId, file]) => {
                const fieldPatch = Object.values(controlPatches.fields).includes(attachmentId);
                const complexFieldPatch = Object.values(controlPatches.complexFields)
                    .flatMap((complexField) => Object.values(complexField))
                    .some((complexField) => Object.values(complexField.fields).includes(attachmentId));

                if (fieldPatch || complexFieldPatch) {
                    return AttachmentsHelper.mapFileToAttachment(file, attachmentId, true);
                }

                return null;
            }).filter((attachment) => attachment);
            const complexFields = Object.values(controlPatches.complexFields).flatMap((complexField) => Object.values(complexField));

            savePromises.push(
                DataAccess.Publications.savePublicationElement(id, publicationContent.pageId, selectedElementId, controlPatches.fields, complexFields, attachments)
                    .then((response) => {
                        const clonedElementList = _.cloneDeep(publicationContent.elements);
                        OperationsHelper.applyPatchOperation(response.data, clonedElementList);
                        setPublicationContent((prev) => ({
                            ...prev,
                            elements: clonedElementList
                        }));

                    })
            );
        }

        return Promise.all(savePromises);
    };

    const saveChanges = (): void => {
        setLoading(true);
        saveElementChanges().then(() => {
            setSelectedElementId(undefined);
            setLeaseInfo(undefined);
        }).catch((err) => {
            const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err);
            if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed)) {
                setEditorError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PublicationIsClosed] });
            } else if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.NoValidLease)) {
                setShowNoLeaseDialog(true);
            } else {
                // TODO: update validation message depanding on the error code.
                setValidElement({ message: 'Ongeldige invoer', isValid: false });
                setInvalidElements((prev) => ({ ...prev, [selectedElementId]: { message: 'Ongeldige invoer', isValid: false } }));
                setEditorError(errorInfo);
            }
        }).finally(() => {
            setLoading(false);
        });
    };

    /**
     * Represents an event handler that discards the publication changes.
     */
    const cancelChanges = (): void => {
        const pagePatches = props.patches[publicationContent.pageId];
        const controlPatches = pagePatches ? pagePatches[selectedElementId] : null;
        handlePageNavigation();
        if (controlPatches) {
            props.clearPatches(publicationContent.pageId);
        }
        setValidElement({ message: '', isValid: true });

        if (!invalidElements[selectedElementId]?.keep) setInvalidElements((prev) => ({ ...prev, [selectedElementId]: { message: '', isValid: true } }));
        setSelectedPublishProfiles(undefined);
        setSelectedElementId(undefined);
        setLeaseInfo(undefined);
    };

    /**
     * Represents an event handler that creates a patch for the fields that have been changed.
     * @param changes Defines the element's fields and values that have been changed.
     */
    const fieldsChanged = (changes: Domain.Publisher.FieldPatch[]): void => {
        const patchAddChanges: { elementId: string, fieldId: string, value: string }[] = [];
        const patchRemoveChanges: { elementId: string, fieldId: string }[] = [];
        changes.forEach(fieldChange => {
            const element = Object.values(publicationContent.elements).find((element) => element.elementId === fieldChange.elementId);
            if (element.elementDefinitionSystemId === SystemElementDefinitions.Pub.TextControl) {
                const textControlElementDefinition = Object.values(props.elementdefinitions[ActionSource.Publication].items).find((definition) => definition.systemId === SystemElementDefinitions.Pub.TextControl);
                const fieldDefinition = textControlElementDefinition.fields.find(item => item.systemId === Domain.SystemFieldDefinitions.Pub.TextHtmlContent);
                if (fieldChange.value.length > fieldDefinition.stringMaxLength) {
                    setValidElement({ message: `Maximaal ${fieldDefinition.stringMaxLength} tekens`, isValid: false });
                    setInvalidElements((prev) => ({ ...prev, [fieldChange.elementId]: { message: `Maximaal ${fieldDefinition.stringMaxLength} tekens.`, isValid: false } }));
                } else {
                    setValidElement({ message: '', isValid: true });
                    setInvalidElements((prev) => ({ ...prev, [fieldChange.elementId]: { message: '', isValid: true } }));
                }
            }
            const rawValue = publicationContent.elements[fieldChange.elementId].fields[fieldChange.fieldId];

            if (rawValue && rawValue === fieldChange.value) {
                patchRemoveChanges.push({
                    elementId: fieldChange.elementId,
                    fieldId: fieldChange.fieldId
                });
            } else {
                patchAddChanges.push({
                    elementId: fieldChange.elementId,
                    fieldId: fieldChange.fieldId,
                    value: fieldChange.value
                });
            }
        });

        if (patchAddChanges.length > 0 || patchRemoveChanges.length > 0) {
            props.applyPatches(publicationContent.pageId, patchAddChanges, patchRemoveChanges);
        }
    };

    /**
     * Represents an event handler that creates a patch for the complex field that have been changed.
     * @param changes Defines the element's complex field that have been changed.
     */
    const complexfieldChanged = (change: Domain.Publisher.ComplexFieldPatch): void => {
        const patchAddChanges: { elementId: string, complexFieldDefinitionId: string, rowIndex: number, complexField: Domain.Shared.ComplexField }[] = [];
        const patchRemoveChanges: { elementId: string, complexFieldDefinitionId: string, rowIndex: number }[] = [];
        const patchRawValue = props.patches[publicationContent.pageId] && props.patches[publicationContent.pageId][change.elementId]?.complexFields[change.complexFieldDefinitionId];

        if (!patchRawValue && publicationContent.elements[change.elementId]?.complexFields != null) {
            publicationContent.elements[change.elementId].complexFields
                .forEach((complexField) => {
                    patchAddChanges.push({
                        elementId: change.elementId,
                        complexFieldDefinitionId: complexField.complexFieldDefinitionId,
                        rowIndex: complexField.rowIndex,
                        complexField: complexField,
                    });
                });
        }

        if (change.deleted === true) {
            patchRemoveChanges.push({
                elementId: change.elementId,
                complexFieldDefinitionId: change.complexFieldDefinitionId,
                rowIndex: change.rowIndex,
            });
        } else {
            patchAddChanges.push({
                elementId: change.elementId,
                complexFieldDefinitionId: change.complexFieldDefinitionId,
                rowIndex: change.rowIndex,
                complexField: change.complexField,
            });
        }

        if (patchAddChanges.length > 0 || patchRemoveChanges.length > 0) {
            props.applyComplexFieldPatch(publicationContent.pageId, patchAddChanges, patchRemoveChanges);
        }
    };

    /**
     * Represents an event handler that triggers the loading process of an attachment.
     * 
     * @param attachmentId Defines the attachment id.
     */
    const loadAttachment = useCallback(async (attachmentId: string, attachmentNames?: Record<string, string>): Promise<Blob> => {
        return AttachmentsHelper.loadExistingAttachment(attachmentId, props.attachments, props.setAttachment, attachmentNames);
    }, [props.attachments, props.setAttachment]);

    /**
     * Represents an event handler that triggers the removing process of an attachment.
     * 
     * @param attachmentId Defines the attachment id.
     */
    const removeAttachment = (attachmentId: string) => {
        props.removeAttachment(attachmentId);
    };

    /**
     * Represents an event handler that triggers the uploading process of an attachment.
     * 
     * @param file Defines the file.
     * @param abortSignal Defines the cancel token.
     */
    const uploadAttachment = async (file: File, abortSignal: AbortSignal): Promise<string> => {
        const { blobId } = await SharedDataAccess.Attachments.uploadAttachment(file, abortSignal);
        props.setAttachment(blobId, file);
        return blobId;
    };

    const handlePageNavigation = (): void => {
        pageHasNoTemplate ? navigate(`/publisher/publication/${id}/writer/page/${publication.rootPageId}`) :
            (!error && controlId && navigate(`/publisher/publication/${id}/writer/page/${pageId}`));
    };

    /**
     * Represents and event handler which marks a task as complete when the complete task button is clicked for the selected element. 
     */
    const toggleTaskStatus = (workflowTask: Domain.Publisher.WorkflowTask, completeTask: boolean): void => {
        setTaskStatusChangeInProgress(true);
        if (completeTask) {
            saveElementChanges(false).then(() => {
                DataAccess.WorkflowTask.CompleteWorkflowTask(id, workflowTask.taskId)
                    .then(() => {
                        setControlTaskLastRefresh(Date.now());
                    }).catch((err) => {
                        setEditorError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
                    }).finally(() => {
                        setTaskStatusChangeInProgress(false);
                    });
            }).catch((err) => {
                setTaskStatusChangeInProgress(false);
                // TODO: update validation message depanding on the error code.
                setValidElement({ message: 'Ongeldige invoer', isValid: false });
                setInvalidElements((prev) => ({ ...prev, [id]: { message: 'Ongeldige invoer', isValid: false } }));

                setEditorError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err));
            });
        } else {
            DataAccess.WorkflowTask.ReopenWorkflowTask(id, workflowTask.taskId)
                .then(() => {
                    setTaskStatusChangeInProgress(false);
                    setControlTaskLastRefresh(Date.now());
                }).catch((err) => {
                    setTaskStatusChangeInProgress(false)
                    setEditorError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
                });
        }
    };

    const changeElementState = (elementId: string, stateId: string, userIds: string[], remark: string) => {
        setTaskStatusChangeInProgress(true);
        return DataAccess.TasksManagement.setComponentWorkflowState(id, pageId, elementId, stateId, userIds, remark)
            .then(() => { return true; })
            .catch((err) => {
                setEditorError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
                return false;
            }).finally(() => {
                setTaskStatusChangeInProgress(false);
                setControlTaskLastRefresh(Date.now());
            });
    }

    /**
     * Represents an event handler that triggers the loading process of a complete performance hierarchy.
     * 
     * @param measureMomentId Defines the measure moment id.
     */
    const loadPerformanceHierarchy = (measureMomentId: string): void => {
        if (measureMomentId && !Object.keys(hierarchies[ActionSource.Performance]).includes(measureMomentId)) {
            PerformanceDataAccess.HierarchyDataAccessor.get(measureMomentId, true, true).then((result) => {
                setHierarchies((prevState) => ({
                    ...prevState,
                    [ActionSource.Performance]: {
                        [measureMomentId]: {
                            performance: result.data.hierarchy,
                        }
                    }
                }));
            });
        }
    }

    /**
     * Represents an event handler that triggers the loading process of a complete studio hierarchy.
     * @param hierarchyId Defines the hierarchy id.
     * @param measureMomentId Defines the measure moment id.
     * @param hierarchyDefinitionId Defines the hierarchy definition id.
     */
    const loadStudioHierarchy = (hierarchyId: string, hierarchyDefinitionId: string, measureMomentId: string): void => {
        if (hierarchyId && hierarchyDefinitionId && measureMomentId) {
            StudioDataAccess.HierarchyDataAccessor.get(hierarchyId, true, true)
                .then((result) => {
                    setHierarchies((prevState) => ({
                        ...prevState,
                        [ActionSource.Studio]: {
                            [measureMomentId]: {
                                ...prevState[measureMomentId],
                                [hierarchyDefinitionId]: result.data.hierarchy,
                            }
                        }
                    }) as Domain.Shared.HierarchyMap);
                });
        }
    }

    const getPublishProfilesChanged = (): boolean => {
        const existingProfiles = selectedElementId && pageControlPublishProfiles && pageControlPublishProfiles[selectedElementId] ? pageControlPublishProfiles[selectedElementId] : [];
        const updatedProfiles = selectedPublishProfiles ? selectedPublishProfiles : [];
        return !_.isEqual(existingProfiles, updatedProfiles);
    };

    const canPerformAction = (action: Actions, actionType: ActionType): boolean => {
        return UserRightsService.getInstance().canPerformAction(props.userIdentity, action, actionType);
    };

    const featureIsAvailable = (feature: Features): boolean => {
        return UserRightsService.getInstance().isAvailableFeature(props.userIdentity, feature);
    };

    const hasLicense = (license: License): boolean => {
        return UserRightsService.getInstance().userHasLicence(props.userIdentity, license);
    };

    //#endregion event handlers...

    //#region idle timer
    const onActive = () => {
        getLease(selectedElementId);
    };

    const onIdle = () => {
        saveChanges();
    };

    const handleStillHere = () => {
        getLease(selectedElementId);
    };
    //#endregion

    // initialize the dependent data...
    if (props.measureMoments.status === AjaxRequestStatus.NotSet) {
        props.fetchMeasureMoments();
        return null;
    }

    if (props.users.status === AjaxRequestStatus.NotSet) {
        props.fetchUsers();
        return null;
    }

    if (!props.modules || !props.modules[ActionSource.Publication]) {
        props.fetchModules(ActionSource.Publication);
        return null;
    }

    if (!props.elementdefinitions || !props.elementdefinitions[ActionSource.Publication] || props.elementdefinitions[ActionSource.Publication].status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(ActionSource.Publication, props.modules[ActionSource.Publication][SystemModuleDefinitions.Publisher]);
        return null;
    }

    if (!props.elementdefinitions || !props.elementdefinitions[ActionSource.Performance] || props.elementdefinitions[ActionSource.Performance].status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(ActionSource.Performance, props.modules[ActionSource.Publication][SystemModuleDefinitions.Performance]);
        return null;
    }

    if (!props.elementdefinitions || !props.elementdefinitions[ActionSource.Studio] || props.elementdefinitions[ActionSource.Studio].status === AjaxRequestStatus.NotSet) {
        props.fetchElementDefinitions(ActionSource.Studio, props.modules[ActionSource.Publication][SystemModuleDefinitions.Studio]);
        return null;
    }

    if (!props.hierarchyDefinitions || !props.hierarchyDefinitions[ActionSource.Performance] || props.hierarchyDefinitions[ActionSource.Performance].status === AjaxRequestStatus.NotSet) {
        props.fetchHierarchyDefinitions(ActionSource.Performance, props.modules[ActionSource.Publication][SystemModuleDefinitions.Performance], true);
        return null;
    }

    if (!props.hierarchyDefinitions || !props.hierarchyDefinitions[ActionSource.Studio] || props.hierarchyDefinitions[ActionSource.Studio].status === AjaxRequestStatus.NotSet) {
        props.fetchHierarchyDefinitions(ActionSource.Studio, props.modules[ActionSource.Publication][SystemModuleDefinitions.Studio], true);
        return null;
    }

    if (!props.icons || props.icons.status === AjaxRequestStatus.NotSet) {
        props.fetchIcons();
    }

    if (!publication || !publication.publication) {
        return null;
    }

    const mappedHierarchyDefinition =
        Object.keys(props.hierarchyDefinitions).reduce((collection, key) => {
            return {
                ...collection, [key]:
                    Object.values(props.hierarchyDefinitions[key].items)
                        ?.map((item: Domain.Shared.HierarchyDefinition) =>
                            HierarchyUtils.mapHierarchyDefinitionItems(item, props.elementdefinitions[HierarchyUtils.getSourceByHierarchySystemId(item.systemId)].items))
            };
        }, {});

    const mappedElementDefinition = Object.keys(props.elementdefinitions).reduce((collection, key) => {
        return {
            ...collection,
            [key]: props.elementdefinitions[key].items
        };
    }, {});

    const getPublicationRendererElement = (patchedElements: Dictionary<Domain.Publisher.Element>, readonly: boolean, isSelectedPage: boolean): JSX.Element => {
        if (!isSelectedPage) return <LoadIndicator variant={IndicatorSize.extralarge} align='center' />;

        return (<Renderer
            device={device}
            readonly={readonly}
            elementdefinitions={mappedElementDefinition}
            sitemap={sitemap}
            variables={variables}
            pageDesignId={publicationContent.pageDesignId}
            publication={publication}
            publicationRoot={publicationContent.root}
            publicationElements={patchedElements}
            editableElementIds={editableElementIds}
            selectedElementId={selectedElementId}
            validElement={validElement}
            onSelectElement={(elementId: string) => {
                if (!selectedElementId) {
                    setSelectedElementId(elementId);
                }
            }}
            onLoadAttachment={loadAttachment}
            onFieldInlineChanged={(elementId: string, fieldId: string, value: string) => fieldsChanged([{ elementId, fieldId, value }])}
            hierarchies={hierarchies}
            loadPerformanceHierarchy={loadPerformanceHierarchy}
            loadStudioHierarchy={loadStudioHierarchy}
            inValidElements={invalidElements}
            hasErrorInSettings={(elementId: string, hasError: boolean, message?: string, keepError?: boolean) => {
                const aux = { ...invalidElements };
                if (hasError) {
                    aux[elementId] = { message: message || '', isValid: false, keep: keepError };
                } else {
                    aux[elementId] = { message: '', isValid: true };
                }
                setInvalidElements(aux);
            }}
            featureIsAvailable={featureIsAvailable}
            hasLicense={hasLicense}
            icons={props.icons.items}
        />
        );
    };

    return (
        <>
            <Bar look="toolbar">
                <Bar start>
                    {!error && !pageHasNoTemplate &&
                        <Button id={`btn-edit-${id}`} btnbase="primarybuttons" btntype="medium_transparent" onClick={() => setEditorState(ContentEditorState.Editing)} >
                            {publicationElement?.isClosed ? 'Raadplegen' : 'Bewerken'}
                        </Button>
                    }
                    <Button id={`btn-refresh-${id}`} btnbase="textbuttons" btntype="medium_icon" icon={<RefreshIcon />} onClick={() => setControlTaskLastRefresh(Date.now())}>
                        Verversen
                    </Button>
                </Bar>
            </Bar>
            <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={error?.canRetry ? () => setLastRefresh(Date.now()) : null} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                {!publicationElement?.isClosed && currentUserActiveTasks && sitemap && (
                    <Tasks
                        error={undefined}
                        tasks={currentUserActiveTasks}
                        sitemap={sitemap}
                        publicationId={id}
                        elementDefinitions={props.elementdefinitions[ActionSource.Publication].items}
                        onRefresh={() => setLastRefresh(Date.now())}
                        onTaskSelected={(taskPageId: string, taskControlId: string) => {
                            navigate(`/publisher/publication/${id}/writer/page/${taskPageId}/control/${taskControlId}`);
                            setEditorState(ContentEditorState.Editing);
                        }}
                    />
                )}
            </ErrorOverlay>
            {editorState !== ContentEditorState.None && !error && (
                <LeaseWrapper
                    leaseInfo={leaseInfo}
                    handleStillHere={handleStillHere}
                    onActive={onActive}
                    onIdle={onIdle}>
                        <ContentEditor
                            // Identifiers
                            publicationId={publication.publication.elementId}
                            selectedElementId={selectedElementId}
                            selectedPageId={pageId}
                            currentUserId={props.userIdentity.profile.sub}

                            // States
                            device={device}
                            editingState={editorState}
                            isProcessing={taskStatusChangeInProgress}
                            publishProfilesChanged={getPublishProfilesChanged()}
                            disabled={loading}

                            // Elements and Definitions
                            elementdefinitions={mappedElementDefinition}
                            selectedElement={patchedElements[selectedElementId]}
                            validElement={validElement}
                            variables={variables}
                            editableElementIds={editableElementIds}

                            // Sitemap and Hierarchies
                            sitemap={sitemap}
                            hierarchies={hierarchies}
                            hierarchyDefinitions={mappedHierarchyDefinition}

                            // Events and Audits
                            auditEvents={auditEvents}
                            error={editorError}
                            measureMoments={props.measureMoments.items}
                            statusAuditEvents={statusAuditEvents}

                            // Workflow
                            userWorkflowTasks={currentUserActiveTasks}
                            publicationWorkflowStates={publicationWorkflowStates}
                            componentWorkflowTasks={componentWorkflowTasks}

                            // Profiles
                            availablePublishProfiles={availablePublishProfiles}
                            selectedPublishProfiles={selectedPublishProfiles}

                            // Callbacks and Event Handlers
                            onChangeDeviceType={setDevice}
                            onEditingStateChanged={(editingState: ContentEditorState) => setEditorState(editingState)}
                            onUploadAttachment={uploadAttachment}
                            onLoadAttachment={loadAttachment}
                            onRemoveAttachment={removeAttachment}
                            onFieldsChanged={fieldsChanged}
                            onComplexFieldChanged={complexfieldChanged}
                            onCancel={() => {
                                handlePageNavigation();
                                setEditorState(ContentEditorState.None);
                            }}
                            onRefresh={() => setLastRefresh(Date.now())}
                            onAuditRefresh={() => setAuditRefresh(Date.now())}
                            onCancelChanges={() => {
                                deleteLease();
                                cancelChanges();
                            }}
                            onSaveChanges={saveChanges}
                            onResetError={() => setEditorError(undefined)}
                            onToggleTaskStatus={toggleTaskStatus}
                            onChangeElementState={changeElementState}
                            loadPerformanceHierarchy={loadPerformanceHierarchy}
                            onUpdateControlPublishProfiles={setSelectedPublishProfiles}
                            canPerformAction={canPerformAction}
                            loadStudioHierarchy={loadStudioHierarchy}

                            // Additional Properties
                            icons={props.icons.items}
                        >
                        {pageHasNoTemplate ?
                            <BlankPublication
                                publicationId={id}
                                hasBackButton={pageId === publication.rootPageId}
                                pageDesigns={[]}
                                onBack={() => navigate(-1)}
                            /> :
                            error ? null : getPublicationRendererElement(patchedElements, editorState === ContentEditorState.Preview, publicationContent && publicationContent.pageId === pageId)
                        }
                    </ContentEditor>
                </LeaseWrapper>
            )}
            {showNoLeaseDialog &&
                <ModalDialog
                    id='no-lease-dialog'
                    settings={{
                        look: 'message',
                        title: '',
                        footer: <ModalDialogFooter
                            rightButtonText='Sluiten'
                            onRightButtonClick={() => setShowNoLeaseDialog(false)} />
                    }}
                >
                    <Text value='Wijzigingen kunnen niet worden opgeslagen omdat niet bepaald kan worden of u op de laatste versie werkt.' />
                </ModalDialog>
            }
            {selectedElementId && leaseModalVisible?.visible &&
                <ModalDialog
                    id='pagecomponent-blocked'
                    settings={{
                        look: 'message',
                        title: 'Element niet beschikbaar',
                        footer: <ModalDialogFooter rightButtonText='Sluiten' onRightButtonClick={() => setLeaseModalVisible({ visible: false })} />
                    }}
                >
                    <Text value={`Het element wordt momenteel al bewerkt door ${leaseModalVisible.user} .`} />
                </ModalDialog>
            }
        </>
    );
};

/**
 * Maps the application state to react component properties.
 * @param state Defines the application state.
 */
const mapStateToProps = (state: State) => {
    return {
        modules: state.modules,
        elementdefinitions: state.elementdefinitions,
        patches: state.publisher.patches,
        attachments: state.attachments,
        measureMoments: {
            items: state.measuremoments.items,
            status: state.measuremoments.status,
        },
        users: state.users,
        hierarchyDefinitions: state.hierarchydefinitions,
        icons: state.icons,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchModules: (actionSource: ActionSource) => {
            dispatch(ModulesActionCreator.set({ source: actionSource, data: {} }));
        },
        fetchElementDefinitions: (actionSource: ActionSource, module: Domain.Shared.Module) => {
            dispatch(ElementDefinitionsActionCreator.set({ source: actionSource, data: { moduleId: module?.id, includeDeleted: actionSource === ActionSource.Studio } }));
        },
        setAttachment: (attachmentId: string, attachment: File) => {
            dispatch(AttachmentsActionCreator.set({ source: ActionSource.Publication, data: { attachmentId, attachment } }));
        },
        removeAttachment: (attachmentId: string) => {
            dispatch(AttachmentsActionCreator.remove({ source: ActionSource.Publication, data: { attachmentId } }));
        },
        applyPatches: (pageId: string, set: { elementId: string, fieldId: string, value: string }[], remove: { elementId: string, fieldId: string }[]) => {
            dispatch(PatchesActionCreator.updateField({ source: ActionSource.Publication, data: { pageId, set, remove } }));
        },
        clearPatches: (pageId: string) => {
            dispatch(PatchesActionCreator.clear({ source: ActionSource.Publication, data: { pageId } }));
        },
        applyComplexFieldPatch: (pageId: string, set: { elementId: string, complexFieldDefinitionId: string, rowIndex: number, complexField: Domain.Shared.ComplexField }[], remove: { elementId: string, complexFieldDefinitionId: string, rowIndex: number }[]) => {
            dispatch(PatchesActionCreator.updateComplexField({ source: ActionSource.Publication, data: { pageId, set, remove } }));
        },
        fetchMeasureMoments: () => {
            dispatch(MeasureMomentsActionCreator.set());
        },
        fetchUsers: () => {
            dispatch(UsersActionCreator.set());
        },
        fetchHierarchyDefinitions: (source: ActionSource, module: Domain.Shared.Module, includeLinkDefinitions: boolean) => {
            dispatch(HierarchyDefinitionsActionCreator.set({ source: source, data: { moduleId: module.id, includeLinkDefinitions: includeLinkDefinitions } }));
        },
        fetchIcons: () => {
            dispatch(SvgIconActionCreator.set());
        }
    };
};

const Component = connect(mapStateToProps, mapDispatchToProps)(PublicationContent);
export { Component as index };
