import styled from 'styled-components';
import TextArea from 'devextreme-react/text-area';
import { palette } from '@liasincontrol/ui-basics';

const StyledTextArea = styled(TextArea)`
    background-color: ${palette.white} !important;
    padding-top: 2px !important;
    padding-bottom: 2px !important;
`;

const styledComponents = { StyledTextArea };

export default styledComponents;
